import React, { useState } from 'react';

import { Autocomplete, TextField, TextFieldProps } from '@mui/material';

import { CountryType } from 'common/modules/atoms/countries';
import countryList from 'common/services/countryCodes.json';

export interface CountryPickerProps extends Omit<TextFieldProps, 'onChange' | 'error'> {
	onChange: (value: string) => void;
	error?: string;
}

const AutoCompleteCountryPicker = (props: CountryPickerProps) => {
	const { value, onChange, error, ...textFieldProps } = props;
	const [searchValue, setSearchValue] = useState<string>('');
	const selectedValue = countryList.find((country) => country.code === value);

	const handleChange = (countryOption: CountryType | null) => {
		!!countryOption && onChange(countryOption.code);
	};
	return (
		<Autocomplete
			fullWidth
			value={selectedValue ?? null}
			getOptionLabel={(opt) => opt.name}
			options={countryList}
			inputValue={searchValue}
			onInputChange={(_, newInputValue) => setSearchValue(newInputValue)}
			onChange={(_: any, option) => handleChange(option)}
			renderInput={(params) => (
				<TextField {...params} {...textFieldProps} error={!!error} helperText={error} multiline />
			)}
		/>
	);
};

export default AutoCompleteCountryPicker;
