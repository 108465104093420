import { groupBy } from 'lodash';
import * as AnalyticsSelectors from 'redux/selectors/analytics';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import { ReduxThunkAction } from 'redux/types';

import { getTotalProductPricing } from 'common/modules/orders';
import { logEcomEvent } from 'common/services/analytics';
import { centsToMajorUnits } from 'common/utils/common';
import { getAnalyticsItemFromCartProduct } from 'utils/analytics';

import { EcomEventParams } from '..';
import { EcomEvents } from '../constants';

const eventName = EcomEvents.add_to_cart;

export const logAddToCart = (params: EcomEventParams[typeof eventName]): ReduxThunkAction => (
	_,
	getState,
) => {
	const { cartProducts } = params;
	const state = getState();
	const shopGoogleAnalytics = ShopSelectors.shopGoogleAnalytics(state);
	const categoriesById = StockSelectors.categoriesById(state);
	const locationsById = ShopSelectors.onlineLocationsById(state);
	const itemListSelectionById = AnalyticsSelectors.itemListSelectionById(state);
	const singleCartProduct = cartProducts[0];
	const startLocationId = singleCartProduct.startLocationId;
	const groupCartProductsByStockProductId = Object.values(groupBy(cartProducts, 'productApiId'));
	const pricing = getTotalProductPricing(cartProducts);
	logEcomEvent(
		{
			name: eventName,
			params: {
				currency: singleCartProduct.pricing.currency,
				value: centsToMajorUnits(pricing.subtotal),
				items: groupCartProductsByStockProductId.map((cartProductsGroup) =>
					getAnalyticsItemFromCartProduct(cartProductsGroup[0], cartProductsGroup.length, {
						categoriesById,
						location: locationsById[startLocationId],
						itemListSelectionById,
					}),
				),
			},
		},
		shopGoogleAnalytics,
	);
};
