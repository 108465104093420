import React, { useEffect } from 'react';

import {
	Box,
	Button,
	Drawer,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiCloseLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import * as ViewActions from 'redux/actions/view';
import { useDispatch, useSelector } from 'redux/hooks';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import AnchorMe from 'common/components/AnchorMe';
import Spinner from 'common/components/Spinner';
import AutoResize from 'common/components/layout/AutoResize';
import EmptyState from 'common/components/states/EmptyState';
import CenteredContent from 'components/CenteredContent';
import { useAnalytics } from 'hooks/useAnalytics';
import useSearchParams from 'hooks/useSearchParams';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';
import { ProductType } from 'services/types';
import { isValidProductType } from 'utils/products';

import Category from './components/Category';
import Header from './components/Header';
import ProductsByCategory from './components/ProductsByCategory';
import Sidebar, { ALL_CATEGORIES_ID } from './components/Sidebar';

const HomePage = () => {
	const { logEcomEvent } = useAnalytics();
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const { getTranslation, t } = useTranslation();
	const { replaceRoute, Routes, getPath } = useRoutes();
	const searchParams = useSearchParams();

	const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
	const filtersOpen = useSelector(ViewSelectors.filtersOpen);
	const filteredCategory = useSelector(StockSelectors.filteredCategory);
	const visibleCategoryIds = useSelector(StockSelectors.visibleCategoryIds);
	const categoriesLoading = useSelector(StockSelectors.stockCategories).loading;
	const productsLoading = useSelector(StockSelectors.stockProducts).loading;
	const productsFilteredCount = useSelector(StockSelectors.productsFilteredCount);

	const categoryId = searchParams.value.get('category');
	const productType = searchParams.value.get('productType') as ProductType | null;

	useEffect(() => {
		dispatch(ViewActions.setFilterCategory(categoryId === ALL_CATEGORIES_ID ? null : categoryId));
		dispatch(ViewActions.setFilterProductType(productType));
	}, [dispatch, categoryId, productType]);

	useEffect(() => {
		if (categoriesLoading || productsLoading) {
			return;
		}
		logEcomEvent('view_item_list', { categoryId });
	}, [logEcomEvent, categoryId, categoriesLoading, productsLoading]);

	useEffect(() => {
		if (!categoriesLoading && !!categoryId) {
			if (categoryId !== ALL_CATEGORIES_ID && !visibleCategoryIds.includes(categoryId)) {
				replaceRoute(Routes.browse);
			} else if (!isValidProductType(productType)) {
				replaceRoute(Routes.browse, { query: { category: categoryId } });
			}
		}
	}, [visibleCategoryIds, categoryId, Routes.browse, replaceRoute, categoriesLoading, productType]);

	const handleCloseDrawer = () => dispatch(ViewActions.setFiltersOpen(false));

	if (categoriesLoading || productsLoading) {
		return <Spinner renderDelayMs={1000} />;
	}

	return (
		<Box>
			<Header />
			<CenteredContent>
				<AutoResize>
					<Typography variant="body1" className={classes.categoryDescription}>
						<AnchorMe>
							{!!filteredCategory?.description ? getTranslation(filteredCategory.description) : ''}
						</AnchorMe>
					</Typography>
				</AutoResize>
			</CenteredContent>
			<CenteredContent
				paddingBottom={7}
				paddingTop={isSmallScreen ? 4 : 3}
				innerClassName={classes.main}
			>
				{!isSmallScreen && (
					<Box className={classes.sidebar}>
						<Sidebar />
					</Box>
				)}
				{!productsFilteredCount ? (
					<Box className={classes.content}>
						<EmptyState
							title={t('Browse.noProductsMatching', 'No products matching your filters')}
							cta={
								<Link to={getPath(Routes.browse)}>
									<Button variant="contained" color="primary">
										{t('common:actions.showAllProducts', 'Show all products')}
									</Button>
								</Link>
							}
						/>
					</Box>
				) : !!filteredCategory ? (
					<Box className={classes.content}>
						<Category categoryId={filteredCategory.id} hideLabel />
					</Box>
				) : (
					<Box className={classes.content}>
						<ProductsByCategory />
					</Box>
				)}
			</CenteredContent>
			<Drawer anchor="left" open={filtersOpen} onClose={handleCloseDrawer}>
				<Box className={classes.sidebarMobile}>
					<IconButton className={classes.closeDrawer} onClick={handleCloseDrawer} size="large">
						<RiCloseLine size={28} color="black" />
					</IconButton>
					<Typography variant="h6">{t('Browse.chooseCategory', 'Choose category')}</Typography>
					<Box mt={3} />
					<Sidebar />
				</Box>
			</Drawer>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		main: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-start',
		},
		sidebar: {
			width: 260,
			paddingRight: theme.spacing(4),
		},
		sidebarMobile: {
			width: 260,
			padding: theme.spacing(4, 3),
			[theme.breakpoints.down('sm')]: {
				width: '100vw',
				position: 'relative',
				paddingTop: theme.spacing(7),
			},
		},
		closeDrawer: {
			position: 'absolute',
			top: theme.spacing(1),
			right: theme.spacing(1),
		},
		content: {
			flex: 1,
		},
		categoryDescription: {
			whiteSpace: 'pre-line',
		},
	}),
);

export default HomePage;
