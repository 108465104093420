import React from 'react';

import CheckboxItem from 'common/components/CheckboxItem';
import { useTranslation } from 'services/localization';

interface Props {
	value: boolean;
	onChange: (newValue: boolean) => void;
}

const BusinessUser = (props: Props) => {
	const { t } = useTranslation();
	return (
		<CheckboxItem
			checked={props.value}
			onCheckedChange={props.onChange}
			label={t(
				'decathlon.b2bUser',
				'I am a business user, I want to register a business account on behalf of a school, company or sports club',
			)}
			checkboxSize="small"
			noPadding
		/>
	);
};

export default BusinessUser;
