import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import {
	Button,
	Container,
	Grid,
	InputAdornment,
	TextField,
	Theme,
	Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { difference, partition } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { addShopper, removeShopper, updateShopper } from 'redux/actions/confirm';
import { setActiveShopperIndex } from 'redux/actions/view';
import * as ConfirmSelectors from 'redux/selectors/confirm';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import LinkButton from 'common/components/LinkButton';
import { notNullish } from 'common/utils/common';
import { getEmptyShopper, newFirestoreId } from 'common/utils/newRentalUtils';
import { getSegmentTranslation } from 'common/utils/translations';
import { useTranslation } from 'services/localization/useTranslation';
import { useRoutes } from 'services/routing/useRoutes';

import SegmentsDropDown from './components/SegmentsDropDown';
import {
	areProductsForSegmentAvailable,
	getProductSegments,
	getUniqueSegments,
	segmentsByCount,
} from './utils/segmentUtils';
import {
	clearProductsFromShopper,
	lengthIsGreaterOrEqual,
	updateShopperProperty,
} from './utils/shopperUtils';

const ShoppersInfo = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { getPath, Routes } = useRoutes();
	const activeShopperIndex = useSelector(ViewSelectors.activeShopperIndex);
	const shopId = useSelector(ShopSelectors.shopId);
	const order = useSelector(ConfirmSelectors.orderData_FORCE);
	const { rentalInfo, products, shoppers } = order;
	const productsCount = products.length;
	const productSegments = getProductSegments(products);
	const uniqueSegments = getUniqueSegments(productSegments);

	useEffect(() => {
		const responsiblePersonWithoutProducts = clearProductsFromShopper(shoppers[0]);
		dispatch(
			updateShopper({
				...responsiblePersonWithoutProducts,
				...(uniqueSegments.length && { segment: uniqueSegments[0] }),
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (shoppers.length < uniqueSegments.length) {
			Array.from(Array(uniqueSegments.length - 1)).forEach((_, index) => {
				addNewShopper(uniqueSegments[index + 1]);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isFormValid = () => {
		const allShoppersHaveName = shoppers.every((shopper) => shopper.firstName !== '');
		return (
			allShoppersHaveName &&
			lengthIsGreaterOrEqual(shoppers, uniqueSegments) &&
			allSegmentsAssigned()
		);
	};

	const segmentDisabled = (segment: string) => {
		const [shoppersWithSegment, shoppersWithoutSegment] = partition(
			shoppers,
			(shopper) => !!shopper.segment,
		);
		const chosenUserSegments = shoppersWithSegment.map((s) => s.segment).filter(notNullish);
		const unChosenSegments = difference(uniqueSegments, chosenUserSegments);

		const noProductsForSegmentAvailable = areProductsForSegmentAvailable(
			productSegments,
			chosenUserSegments,
			segment,
		);

		return (
			noProductsForSegmentAvailable ||
			(!unChosenSegments.includes(segment) &&
				shoppersWithoutSegment.length <= unChosenSegments.length)
		);
	};

	const allSegmentsAssigned = () => {
		const chosenUserSegments = shoppers.filter((s) => !!s.segment).map((s) => s.segment);
		const unChosenSegments = difference(uniqueSegments, chosenUserSegments);
		return unChosenSegments.length === 0;
	};

	const addNewShopper = (segment?: string) => {
		const shopperId = newFirestoreId();
		const newShopper = getEmptyShopper(shopperId, rentalInfo.id, shopId, segment);
		dispatch(addShopper(newShopper));
	};

	const handleRemoveShopper = (shopperId: string) => {
		dispatch(removeShopper(shopperId));
		if (activeShopperIndex > shoppers.length - 1) {
			dispatch(setActiveShopperIndex(shoppers.length - 1));
		}
	};

	const neededSegmentsAndAmountsToString = (segments: string[]) => {
		const neededSegments = segmentsByCount(segments);
		const neededSegmentsAndAmounts = Object.keys(neededSegments).map(
			(segmentName) =>
				`${neededSegments[segmentName].length} ${getSegmentTranslation(segmentName, t)}`,
		);
		return neededSegmentsAndAmounts.join(', ');
	};

	const renderShopperInfoFields = () => {
		return (
			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
				className={classes.section}
				spacing={1}
			>
				{shoppers.map((shopper, index) => {
					return (
						<Grid item xs={12} key={index} className={classes.shopperInfoContainer}>
							<Typography variant="body2" className={classes.personSectionTitle}>
								{`${t('common:person.person')} ${index + 1}`}
							</Typography>
							<div className={classes.flex}>
								<TextField
									id="firstName"
									label={t('common:form.firstName')}
									variant="standard"
									name="firstName"
									value={shopper.firstName}
									onChange={(e) =>
										dispatch(updateShopper(updateShopperProperty(e, shoppers[index], 'firstName')))
									}
									type="text"
									key={index}
									className={classes.textField}
									required
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												{index > 0 && (
													<IconButton
														aria-label={t('common:actions.remove')}
														onClick={() => handleRemoveShopper(shopper.id)}
														size="large"
													>
														<ClearIcon />
													</IconButton>
												)}
											</InputAdornment>
										),
									}}
								/>
							</div>
							{uniqueSegments.length > 0 && (
								<SegmentsDropDown
									shopper={shopper}
									segments={uniqueSegments}
									updateShopper={(shopper) => dispatch(updateShopper(shopper))}
									segmentDisabled={segmentDisabled}
								/>
							)}
						</Grid>
					);
				})}
				{productsCount > 1 && (
					<div className={classes.addPersonBtnContainer}>
						<Button
							variant="outlined"
							color="primary"
							className={classes.addPersonBtn}
							onClick={() => addNewShopper()}
							disabled={shoppers.length === productsCount}
						>
							<AddIcon /> {t('shopperInfo.addPerson', 'Add person')}
						</Button>
					</div>
				)}
				<div className={classes.addPersonBtnErrorText}>
					{shoppers.length === productsCount && productsCount > 1 && (
						<Typography variant="body2" className={classes.info}>
							{t('shopperInfo.maxAmountOfPeople', {
								productsCount: productsCount,
								defaultValue:
									'You have booked {{productsCount}} products and thus the max. amount of people participating can be {{productsCount}}.',
							})}
						</Typography>
					)}
				</div>
			</Grid>
		);
	};

	return (
		<Container className={classes.section} maxWidth="sm">
			<Typography variant="h5" gutterBottom>
				{t('shopperInfo.addNamesOfPeople', 'Who’s participating?')}
			</Typography>
			{renderShopperInfoFields()}
			{!allSegmentsAssigned() && (
				<Typography variant="body2" gutterBottom>
					{t('shopperInfo.unassignedSegments', {
						segmentAmount: neededSegmentsAndAmountsToString(uniqueSegments),
						defaultValue:
							'Based on your product selection your group should have at least: {{segmentAmount}}.',
					})}
				</Typography>
			)}
			<div className={classes.fixedButtonContainer}>
				<LinkButton
					variant="contained"
					color="primary"
					className={classes.btn}
					to={getPath(Routes.confirmDetailsProducts)}
					disabled={!isFormValid()}
				>
					{t('common:actions.continue')}
				</LinkButton>
			</div>
		</Container>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		section: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(6),
		},
		addPersonBtnContainer: {
			display: 'flex',
			width: '100%',
			paddingRight: '4px',
		},
		addPersonBtnErrorText: {
			marginTop: theme.spacing(2),
			textAlign: 'center',
		},
		fixedButtonContainer: {
			width: '100%',
			bottom: 0,
			left: 0,
			padding: '4px 0',
			backgroundColor: '#fff',
			textAlign: 'center',
		},
		addPersonBtn: {
			marginLeft: 'auto',
			marginRight: 'auto',
			paddingTop: 12,
			paddingBottom: 12,
		},
		btn: {
			width: '100%',
			minWidth: 250,
			marginTop: theme.spacing(1),
			paddingTop: 12,
			paddingBottom: 12,
		},
		textField: {
			width: '100%',
			marginBottom: theme.spacing(2),
		},
		flex: {
			display: 'flex',
		},
		info: {
			color: theme.palette.colors.tundra.main,
		},
		shopperInfoContainer: {
			marginBottom: theme.spacing(4),
		},
		personSectionTitle: {
			marginBottom: theme.spacing(2),
		},
	}),
);

export default ShoppersInfo;
