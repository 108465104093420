import { createSelector } from '@reduxjs/toolkit';
import { chain, maxBy, minBy, range } from 'lodash';
import * as StockSelectors from 'redux/selectors/stock';
import { ReduxState } from 'redux/types';

import { getTotalItemPricing } from 'common/modules/atoms/pricing';
import {
	getTotalProductPricing,
	isRentalPurchaseType,
	isSalesPurchaseType,
	isSubscriptionPurchaseType,
} from 'common/modules/orders';
import { isAutoRenewSubscriptionOrderProduct } from 'common/modules/subscriptions';
import { CartProduct, Duration, ISOString, PurchaseTypes, UserDetail } from 'common/types';
import { notUndefined } from 'common/utils/common';
import {
	getKeycardTypeFromProducts,
	isKeycardProduct,
	isLiftTicketProduct,
} from 'common/utils/liftTicketUtils';
import { getRecommendedProductsForCart } from 'utils/cart';

import { shopCurrency } from './shop';

export const sessionId = (state: ReduxState) => state.cart.sessionId;
export const items = (state: ReduxState) => state.cart.items;
export const delivery = (state: ReduxState) => state.cart.delivery;
export const hasCartDelivery = (state: ReduxState) => !!state.cart.delivery;
export const deliveryAddress = (state: ReduxState) => delivery(state)?.to?.location?.address ?? {};
export const pickupAddress = (state: ReduxState) => delivery(state)?.from?.location?.address ?? {};

export const itemsWithoutKeycard = createSelector(items, (items) =>
	items.map((item) => ({
		...item,
		products: item.products.filter((p) => !isKeycardProduct(p.product)),
	})),
);

export const itemsWithoutLiftTickets = createSelector(items, (items) =>
	items.filter((item) => item.products.every((p) => !isLiftTicketProduct(p.product))),
);

export const rentalProducts = createSelector(items, (items) =>
	items.filter((i) => isRentalPurchaseType(i.purchaseType)),
);

export const salesProducts = createSelector(items, (items) =>
	items.filter((i) => isSalesPurchaseType(i.purchaseType)),
);

export const subscriptionProducts = createSelector(items, (items) =>
	items.filter((i) => isSubscriptionPurchaseType(i.purchaseType)),
);

export const hasSomeRentalProducts = createSelector(items, (items) =>
	items.some((i) => isRentalPurchaseType(i.purchaseType)),
);

export const hasSomeSubscriptionProducts = createSelector(items, (items) =>
	items.some((i) => isSubscriptionPurchaseType(i.purchaseType)),
);

export const hasProductsToReturnInCart = createSelector(items, (items) =>
	items.some(
		({ purchaseType }) =>
			isRentalPurchaseType(purchaseType) || isSubscriptionPurchaseType(purchaseType),
	),
);

export const hasOnlySalesProducts = createSelector(items, (items) =>
	items.every((i) => isSalesPurchaseType(i.purchaseType)),
);

export const includedRentalProductIds = createSelector(items, (_items) => {
	return _items.flatMap((row) => {
		if (row.purchaseType !== PurchaseTypes.rental) return [];
		return row.products.flatMap(({ product, quantity }) => {
			return range(quantity).map(() => product.productApiId);
		});
	});
});

export const startDate = createSelector(
	items,
	itemsWithoutLiftTickets,
	(allItems, itemsWithoutLiftTickets) => {
		// Lift tickets have the start time at midnight, so only normal products
		// are taken into account if there are.
		// If there are only lift tickets in the cart, the start date from lift tickets (allItems)
		// will be used but the start time would still be able to select.
		const items = itemsWithoutLiftTickets.length ? itemsWithoutLiftTickets : allItems;
		return minBy(items, (item) => item.startDate)?.startDate ?? null;
	},
);

export const latestEndDate = createSelector(
	rentalProducts,
	subscriptionProducts,
	(rentalItems, subscriptionItems) => {
		const items = [...rentalItems, ...subscriptionItems].filter(
			(i) => !i.products.some((p) => isAutoRenewSubscriptionOrderProduct(p.product)),
		);
		return maxBy(items, (item) => item.endDate)?.endDate ?? null;
	},
);

/**
 * If the cart has some products in it, only products with the same start date can be added
 */
export const forcedStartDate = createSelector(items, (items): ISOString | null => {
	const date = items[0]?.startDate;
	return date ?? null;
});

/**
 * If the cart has some products in it, and they have a duration (i.e. they are not lift tickets),
 * only products with the same start time can be added
 */
export const forcedStartDateAndTime = createSelector(items, (items): ISOString | null => {
	const date = items.find((item) => {
		const hasStartDate = !!item.startDate;
		const hasEndDate = !!item.endDate;
		const isSubscriptionProduct = item.purchaseType === PurchaseTypes.subscription; //Auto renew subscription products don't have an end date but they should still force the start date
		return hasStartDate && (hasEndDate || isSubscriptionProduct);
	})?.startDate;
	return date ?? null;
});

export const cartLocationId = createSelector(items, (items) => {
	return items.length > 0 ? items[0].products[0]?.product.startLocationId ?? null : null;
});

export const cartProducts = createSelector(items, (items): CartProduct[] => {
	return (
		items?.flatMap((item) =>
			item.products.flatMap(({ quantity, product }) => range(quantity).map(() => product)),
		) ?? []
	);
});

export const cartPricing = createSelector(
	cartProducts,
	delivery,
	shopCurrency,
	(products, delivery, shopCurrency) => {
		const productsPricing = getTotalProductPricing(products, shopCurrency.code);
		const deliveryPricing = delivery?.pricing;
		const totalCartPricing = getTotalItemPricing(
			[productsPricing, deliveryPricing].filter(notUndefined),
		);
		return totalCartPricing;
	},
);

export const cartProductsCount = createSelector(
	cartProducts,
	(cartProducts) => cartProducts.filter((p) => !isKeycardProduct(p)).length,
);

export const hasLiftTickets = createSelector(cartProducts, (products) => {
	return products.some(isLiftTicketProduct);
});

export const liftTicketCartProducts = createSelector(cartProducts, (products) => {
	return products.filter(isLiftTicketProduct);
});

export const liftTicketsStartDate = createSelector(
	liftTicketCartProducts,
	startDate,
	(liftTicketCartProducts, startDate) => {
		const date = liftTicketCartProducts[0]?.startDate ?? startDate;
		return date ?? null;
	},
);

export const keycardCartProducts = createSelector(liftTicketCartProducts, (products) => {
	return products.filter(isKeycardProduct);
});

export const keycardTypeFromCart = createSelector(liftTicketCartProducts, (products) => {
	return products.length ? getKeycardTypeFromProducts(products) : null;
});

export const shortestCartRentalDuration = createSelector(
	rentalProducts,
	subscriptionProducts,
	(rentalItems, subscriptionItems): Duration | null => {
		const allProducts = [...rentalItems, ...subscriptionItems].flatMap((i) => i.products);
		const minDurationProduct = minBy(allProducts, (p) => p.product.rentalDurationInSeconds)
			?.product;

		if (!minDurationProduct) return null;

		const { rentalDurationInSeconds, durationType, durationName } = minDurationProduct;
		return { durationInSeconds: rentalDurationInSeconds, durationType, durationName };
	},
);

export const reservationCreated = (state: ReduxState) => state.cart.reservationCreated;

export const recommendedProducts = createSelector(
	cartProducts,
	StockSelectors.onlineProductsInLocationById,
	(cartProducts, productsById) => {
		const cartProductIds = cartProducts.flatMap((p) => p.productApiIds);

		return getRecommendedProductsForCart(cartProductIds, productsById);
	},
);

export const recommendLiftTicket = createSelector(cartProducts, (cartProducts) => {
	return cartProducts.some((product) => product.offerLiftTickets);
});

export const requiredUserDetails = createSelector(cartProducts, (products): UserDetail[] => {
	return chain(products)
		.flatMap((product) => product.userDetails ?? [])
		.uniqBy((detail) => detail.name)
		.value();
});
