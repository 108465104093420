import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import AmountPicker from 'common/components/pickers/AmountPicker';
import { isSubscriptionPurchaseType } from 'common/modules/orders/products/utils';
import { CartProduct } from 'common/types';
import { getPricingString, getPricingStringPerDuration } from 'common/utils/common';
import { isLiftTicketProduct, productHasNewKeycard } from 'common/utils/liftTicketUtils';
import { getSkidataSegmentTranslation } from 'common/utils/segments';
import { getSegmentTranslation } from 'common/utils/translations';
import { useTranslation } from 'services/localization';
import { getProductImage, getProductVariantText } from 'utils/products';

interface Props {
	product: CartProduct;
	quantity: number;
	onQuantityIncrease: (product: CartProduct) => void;
	onQuantityDecrease: (product: CartProduct) => void;
	maxAvailable?: number;
	isReadOnly?: boolean;
	isStartDateExpired?: boolean;
}
const CartProductsRowItem = (props: Props) => {
	const classes = useStyles();
	const { t, getTranslation, language } = useTranslation();
	const {
		product,
		quantity,
		onQuantityIncrease,
		onQuantityDecrease,
		maxAvailable,
		isStartDateExpired,
		isReadOnly,
	} = props;

	const keycardProduct = useSelector(StockSelectors.keycardProduct);
	const currency = useSelector(ShopSelectors.shopCurrency);

	const isSelectionExceedingAvailability = maxAvailable !== undefined && quantity > maxAvailable;
	const exceedingAmount = quantity - (maxAvailable ?? 0);

	const getProductName = (product: CartProduct) =>
		isLiftTicketProduct(product)
			? `${getTranslation(product.name)} ${t('tickets.liftTicket', 'lift ticket')}`
			: getTranslation(product.name);

	const getSegmentText = (product: CartProduct) =>
		!!product.segment
			? getSegmentTranslation(product.segment, t)
			: isLiftTicketProduct(product)
			? getSkidataSegmentTranslation(product.externalSegment, language)
			: null;

	const segmentText = getSegmentText(product);
	const variantText = getProductVariantText(product, language);

	const unitListPrice =
		productHasNewKeycard(product) && !!keycardProduct?.rentals?.basePrice
			? product.pricing.listPrice + keycardProduct.rentals.basePrice
			: product.pricing.listPrice;
	const listPrice = quantity * unitListPrice;

	const isSubscription = isSubscriptionPurchaseType(product.purchaseType);

	const pricingString = getPricingString(listPrice, currency);

	return (
		<div>
			<Box className={classes.details}>
				<Box className={classes.productImageWrapper}>
					<img
						src={getProductImage(product)}
						className={classes.productImage}
						alt={getTranslation(product.name)}
					/>
				</Box>
				<Box className={classes.productName}>
					<Typography variant="body2">{getProductName(product)}</Typography>
					{productHasNewKeycard(product) && keycardProduct && (
						<Typography variant="body2">+ {getTranslation(keycardProduct?.name)}</Typography>
					)}
					{!!variantText && (
						<Typography variant="caption" className={classes.productDetail}>
							{variantText}
						</Typography>
					)}
					{!!segmentText && (
						<Typography variant="caption" className={classes.productDetail}>
							{segmentText}
						</Typography>
					)}
					<Box className={classes.bottom}>
						<Box className={classes.amount}>
							<AmountPicker
								value={quantity}
								onIncrease={() => onQuantityIncrease(product)}
								onDecrease={() => onQuantityDecrease(product)}
								size="small"
								showDeleteIcon
								readOnly={isReadOnly}
								disableIncrease={maxAvailable !== undefined && exceedingAmount + 1 > 0}
							/>
						</Box>
						<Box>
							<Typography variant="body2" className={classes.subtotal}>
								{isSubscription
									? getPricingStringPerDuration(pricingString, 'month', t)
									: pricingString}
							</Typography>
						</Box>
					</Box>
					{(isStartDateExpired || isSelectionExceedingAvailability) && (
						<Box>
							<Typography variant="body2" className={classes.notAvailableText}>
								{isStartDateExpired
									? t('Cart.startDateExpired', 'Start time expired')
									: maxAvailable !== undefined && maxAvailable <= 0
									? t('common:availability.outOfStockForPeriod', 'Out of stock for this period')
									: t('common:availability.onlyNAvailableForPeriod', {
											amount: maxAvailable,
											defaultValue: 'Only {{amount}} available',
									  })}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		details: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-start',
			margin: theme.spacing(3, 0),
		},
		productImageWrapper: {
			height: 60,
			width: 60,
			border: '1px solid #d4d4d4',
			borderRadius: 4,
			padding: theme.spacing(0.5),
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.up('md')]: {
				height: 76,
				width: 76,
			},
		},
		productImage: {
			maxWidth: '100%',
			maxHeight: '100%',
		},
		productName: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			paddingLeft: theme.spacing(2),
		},
		productDetail: {
			fontSize: '1.3rem',
			fontWeight: 400,
		},
		bottom: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-end',
			marginTop: theme.spacing(2),
		},
		amount: {
			flex: 1,
			marginRight: theme.spacing(2),
		},
		subtotal: {
			fontWeight: 500,
		},
		notAvailableText: {
			color: '#ce0000',
			marginTop: theme.spacing(1.5),
			fontWeight: 'normal',
			[theme.breakpoints.down('lg')]: {
				fontSize: '1.25rem',
			},
		},
	}),
);

export default CartProductsRowItem;
