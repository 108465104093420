import React from 'react';

import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import { useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import TimeChipSelector from 'common/components/TimeChipSelector';
import { timeslotToString } from 'common/modules/delivery/utils';
import { DeliveryTimeSlot } from 'common/types';
import useShopFormat from 'hooks/useShopFormat';
import { useTranslation } from 'services/localization';

interface Props {
	date: string | null;
	type: 'to' | 'from';
	handleSelect: (type: 'to' | 'from', timeslot: DeliveryTimeSlot) => void;
}

const DeliveryTimeslots = (props: Props) => {
	const { localFormat, shopDateFormat } = useShopFormat();
	const classes = useStyles();
	const { date, type } = props;
	const { t } = useTranslation();

	const shopTimezone = useSelector(ShopSelectors.shopTimezone);
	const deliveryService = useSelector(CartSelectors.delivery);
	const selectedSlot = deliveryService?.[type]?.timeslot;
	const timeslots = useSelector(ViewSelectors.availablePickupSlots);

	return (
		<>
			<Typography variant="body2" className={classes.pickupDate} gutterBottom>
				{t('delivery.availableTimes', {
					defaultValue: 'Available times {{date}}',
					date: localFormat(moment(date), 'ddd. MMM D'),
				})}
				:
			</Typography>
			<TimeChipSelector
				value={!!selectedSlot ? `${selectedSlot.startDate}_${selectedSlot.endDate}` : null}
				onChange={(value) => {
					const [startDate, endDate] = value.split('_');
					props.handleSelect(type, { startDate, endDate });
				}}
				options={
					timeslots?.map((timeslot) => {
						return {
							label: timeslotToString({
								timeslot,
								timezone: shopTimezone,
								shopDateFormat,
								hideDates: true,
							}),
							value: `${timeslot.startDate}_${timeslot.endDate}`,
						};
					}) ?? []
				}
				align="left"
				margin="dense"
				disablePagination
			/>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		chip: {
			marginRight: theme.spacing(1),
		},
		startTimes: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			flexWrap: 'wrap',
			flex: 1,
			margin: theme.spacing(1, 0),
		},
		startTime: {
			padding: theme.spacing(0.5, 1),
			borderRadius: 16,
			border: '1px solid #e5e7e7',
			minWidth: 60,
			margin: theme.spacing(0.5),
			'&:hover': {
				borderColor: theme.palette.common.black,
			},
		},
		startTimeSelected: {
			background: theme.palette.common.black,
			color: theme.palette.common.white,
			borderColor: theme.palette.common.black,
		},
		startTimeDisabled: {
			background: '#e5e7e7',
			color: '#716a80',
			pointerEvents: 'none',
		},
		smallText: {
			fontSize: '1rem',
			padding: theme.spacing(0.5),
		},
		deliverySlot: {
			marginTop: theme.spacing(1),
		},
		pickupDate: {
			fontWeight: 'normal',
			marginTop: theme.spacing(1),
		},
	}),
);

export default DeliveryTimeslots;
