import React from 'react';

import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import LocationDetail from 'common/components/LocationDetail';
import { DEFAULT_OPENING_HOURS } from 'common/modules/openingHours';
import { Location } from 'common/types';
import { locationWithContact } from 'common/utils/shopUtils';
import useShopFormat from 'hooks/useShopFormat';
import { useTranslation } from 'services/localization';

interface Props {
	location: Location;
	variant: 'location' | 'contact';
	hideContactInfo?: boolean;
	hideExceptions?: boolean;
	hideAdditionalInformation?: boolean;
}

const LocationDetailContainer = (props: Props) => {
	const { t } = useTranslation();
	const { localFormat } = useShopFormat();

	const {
		location: _location,
		hideContactInfo,
		variant,
		hideExceptions,
		hideAdditionalInformation,
	} = props;

	const shopPublicInfo = useSelector(ShopSelectors.shopPublicInfoData)!;
	const language = useSelector(ViewSelectors.language);
	const isLocationDeliveryOnly = useSelector(ShopSelectors.isDeliveryOnly);
	const location = locationWithContact(_location, shopPublicInfo);
	const openingHoursByStoreId = useSelector(ShopSelectors.openingHoursByStoreId);
	const openingHours = openingHoursByStoreId[location.id] ?? DEFAULT_OPENING_HOURS;

	const hideEmail = location.hideEmail || !location.email;
	const hidePhone = location.hidePhone || !location.phone;
	const hideContact = hideContactInfo || (hideEmail && hidePhone);

	return (
		<LocationDetail
			location={location}
			openingHours={openingHours}
			t={t}
			variant={variant}
			localFormat={localFormat}
			hideContact={hideContact}
			hideExceptions={hideExceptions}
			hideAdditionalInformation={hideAdditionalInformation}
			isLocationDeliveryOnly={isLocationDeliveryOnly}
			language={language}
		/>
	);
};

export default LocationDetailContainer;
