import React, { useMemo } from 'react';

import { Box, Collapse } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import Divider from 'common/components/Divider';
import { isRentalPurchaseType, isSubscriptionPurchaseType } from 'common/modules/orders';
import {
	ProductSubscription,
	getSubscriptionDurationStringFromProductSubscription,
	getSubscriptionPricingDescriptionString,
} from 'common/modules/subscriptions';
import { DurationWithPrice, PurchaseType, PurchaseTypes } from 'common/types';
import { getDurationString } from 'common/utils/dateCalculations';
import { getDurationFromDurationWithPriceOption } from 'common/utils/pricing';
import { getVariantName } from 'common/utils/productUtils';
import PriceSummary from 'components/PriceSummary';
import useShopFormat from 'hooks/useShopFormat';
import { useTranslation } from 'services/localization';
import { ProductSelection } from 'services/types';

interface Props {
	selection: ProductSelection | null;
	purchaseType: PurchaseType;
	quantity: number;
	charge: number;
	duration: DurationWithPrice | null;
	subscriptionOption?: ProductSubscription;
	startDate: string | null;
	hideDate?: boolean;
}

const SelectionSummary = React.memo((props: Props) => {
	const {
		selection,
		purchaseType,
		quantity,
		charge,
		startDate,
		duration,
		hideDate,
		subscriptionOption,
	} = props;

	const { localFormat } = useShopFormat();
	const { t, getTranslation, language } = useTranslation();
	const currency = useSelector(ShopSelectors.shopCurrency);
	const productsById = useSelector(StockSelectors.productsById);
	const taxExcluded = useSelector(ShopSelectors.taxExcluded);

	const isSubscription = purchaseType === PurchaseTypes.subscription;

	const getProductName = (productId: string, variantId: string) => {
		const product = productsById[productId];
		const variant = product?.variants.options.find((v) => v.id === variantId);

		return `${getTranslation(product.name)}${
			!isEmpty(variant?.values) ? ` (${getVariantName(variant, language)})` : ''
		}`;
	};

	const depositAmount =
		!!selection && (isRentalPurchaseType(purchaseType) || isSubscriptionPurchaseType(purchaseType))
			? productsById[selection.productId]?.terms?.deposit || 0
			: 0;

	const durationLabel = useMemo(() => {
		if (!!subscriptionOption) {
			return getSubscriptionDurationStringFromProductSubscription(subscriptionOption, t);
		}
		if (!duration || duration.timePeriod === 'fixed') return '';

		return (
			getDurationString(getDurationFromDurationWithPriceOption(duration), 'long', language, t) ||
			duration.timePeriod
		);
	}, [duration, language, subscriptionOption, t]);

	const getRows = () => {
		if (!selection) return [];
		return [
			{
				key: selection.productId,
				quantity,
				label: getProductName(selection.productId, selection.variantId),
				unitPrice: charge,
				children: selection.children?.map((child) =>
					getProductName(child.productId, child.variantId),
				),
			},
		];
	};

	const subscriptionDescription =
		isSubscription && !!subscriptionOption
			? getSubscriptionPricingDescriptionString(
					{
						subscription: subscriptionOption,
						quantity,
						charge,
						duration,
						durationLabel,
						currency,
					},
					t,
			  )
			: null;

	const headingSecondary = !!hideDate
		? null
		: startDate &&
		  `${localFormat(moment(startDate), 'ddd DD.MM. HH:mm')}${
				!!durationLabel && !isSubscription ? ', ' + durationLabel : ''
		  }`;

	return (
		<Collapse appear={false} in={!!selection}>
			<Divider />
			<Box mt={2} />
			<PriceSummary
				heading={t('common:booking.yourSelection', 'Your selection')}
				headingSecondary={headingSecondary}
				description={subscriptionDescription}
				tooltip={
					!!subscriptionDescription
						? t('ProductForm.subscriptionTooltip', {
								duration: durationLabel,
								defaultValue: `With this subscription, you commit to a subscription period of {{duration}}. For more information, don't hesitate to get in touch with us.`,
						  })
						: null
				}
				rows={getRows()}
				taxExcluded={taxExcluded}
				currency={currency}
				depositAmount={depositAmount * quantity}
				isSubscription={isSubscription}
			/>
		</Collapse>
	);
});

export default SelectionSummary;
