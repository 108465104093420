import { min, parseInt } from 'lodash';
import moment from 'moment-timezone';

import { Callable } from 'common/frontend/callable';
import { getItemPricingFromListPrice } from 'common/modules/atoms/pricing';
import { getTaxRateFromVatPercent } from 'common/modules/atoms/taxes';
import { ProductVariant } from 'common/modules/inventory';
import { getProductVariants, getVariantSkuOption } from 'common/modules/products/variants';
import { isLiftTicketPackageOrderProduct } from 'common/modules/skidata';
import {
	CartProduct,
	CurrencyObject,
	GetSkidataOrderPriceArgs,
	ISOString,
	ProductApi,
	SegmentMap,
} from 'common/types';
import { getPricingString, notUndefined } from 'common/utils/common';
import { momentToDateInShopTimezone } from 'common/utils/dateUtils';
import { initialStockFromVariant } from 'common/utils/inventoryUpdateUtils';
import { getLowestLiftTicketPrice } from 'common/utils/pricing';
import { getBaseProductFromStockProduct } from 'common/utils/productUtils';
import {
	doesTicketHaveSegment,
	getSkidataSegmentById,
	getTicketSegmentPricing,
} from 'common/utils/segments';
import i18n from 'services/localization';

export type EmptyTicketCartProduct = Omit<
	CartProduct,
	'selectedVariant' | 'variant' | 'includedVariantIds' | 'stock' | 'summary' | 'startTimes'
>;

export const cleanTicketFromOptionalFields = (product: CartProduct): CartProduct => {
	const {
		productCode,
		dinValues,
		customValues,
		offerLiftTickets,
		image,
		bookingBuffer,
		segmentFilters,
		collectProductCode,
		customValuesToCollect,
		collectDin,
		collectBootSoleLength,
		...cleanProduct
	} = product;
	return cleanProduct;
};

export const keycardProductToCartProduct = (
	ticketCartProduct: CartProduct,
	keycard: ProductApi,
): CartProduct => {
	const baseProduct = getBaseProductFromStockProduct(keycard);
	const keycardVariants = getProductVariants(keycard);
	const CartProduct: CartProduct = {
		...ticketCartProduct, // startDate, endDate etc...
		...baseProduct,
		categoryIds: isLiftTicketPackageOrderProduct(ticketCartProduct)
			? ticketCartProduct.categoryIds
			: baseProduct.categoryIds,
		created: moment().toISOString(),
		productApiId: keycard.id,
		productApiIds: [keycard.id],
		includedVariantIds: [],
		variant: keycardVariants[0],
		setProducts: [],
		set: false,
		charge: {
			paid: 0,
		},
		summary: {
			skuIds: [],
			variantIds: [keycardVariants[0].id],
			itemCodes: [],
		},
		pricing: getItemPricingFromListPrice(keycard.rentals?.basePrice ?? 0, {
			deposit: keycard.terms?.deposit || undefined,
			taxRate: getTaxRateFromVatPercent(keycard.vatPercent),
			currency: ticketCartProduct.pricing.currency,
			taxExcluded: ticketCartProduct.pricing.taxExcluded,
		}),
	};
	return cleanTicketFromOptionalFields(CartProduct);
};

export const ticketProductToCartProduct = (args: {
	cartProductFields: EmptyTicketCartProduct;
	selectedTicket: ProductApi;
	variant: ProductVariant;
	externalSegment: SegmentMap;
	selectedAsAdditional?: boolean;
	externalPackageId?: string;
	packageId?: string;
	packageCategoryIds?: string[];
	stockPackageId?: string;
	price?: number;
}): CartProduct => {
	const sku = getVariantSkuOption(args.variant);
	const amount =
		args.price ||
		args.selectedTicket.segmentPricings!.find((pricing) =>
			pricing.segments.includes(args.externalSegment.externalSegmentId),
		)!.fixedPrice;
	const baseProduct = getBaseProductFromStockProduct(args.selectedTicket);
	const categoryIds = !!args.packageCategoryIds ? args.packageCategoryIds : baseProduct.categoryIds;
	const cartProduct: CartProduct = {
		...args.cartProductFields,
		...baseProduct,
		categoryIds,
		endDate: null,
		created: moment().toISOString(),
		productApiId: args.selectedTicket.id,
		productApiIds: [args.selectedTicket.id],
		includedVariantIds: sku ? [sku.skuId] : [],
		variant: { ...args.variant, rentals: { enabled: true, priceIncrease: 0 } },
		setProducts: [],
		...(!!args.externalPackageId && { externalPackageId: args.externalPackageId }),
		...(!!args.packageId && { packageId: args.packageId }),
		...(!!args.stockPackageId && { stockPackageId: args.stockPackageId }),
		set: false,
		charge: {
			paid: 0,
		},
		pricing: getItemPricingFromListPrice(amount || 0, {
			deposit: args.selectedTicket.terms?.deposit || undefined,
			taxRate: getTaxRateFromVatPercent(args.selectedTicket.vatPercent),
			currency: args.cartProductFields.pricing.currency,
			taxExcluded: args.cartProductFields.pricing.taxExcluded,
		}),
		externalSegment: args.externalSegment,
		selectedAsAdditional: args.selectedAsAdditional ?? false,
		summary: {
			skuIds: sku ? [sku.skuId] : [],
			itemCodes: [],
			variantIds: [args.variant.id],
		},
		stock: !sku
			? {}
			: {
					[sku.skuId]: initialStockFromVariant(sku, args.selectedTicket, args.variant, null),
			  },
	};
	return cleanTicketFromOptionalFields(cartProduct);
};

export const getEmptyTicketCartProduct = (args: {
	startDate: ISOString;
	currencyCode: string;
	startLocationId: string;
	endLocationId: string;
	shopId: string;
	stockProduct: ProductApi;
	taxExcluded: boolean;
	price?: number;
}): EmptyTicketCartProduct => ({
	created: moment().toISOString(),
	deposit: null,
	durationName: null,
	durationType: '24h',
	endDate: null,
	endDateReturned: null,
	endLocationId: args.endLocationId,
	external: 'LIFT_TICKET',
	highlightProduct: false,
	name: { def: '' },
	productApiId: '',
	productApiIds: [],
	rentalDurationInSeconds: 0,
	segment: null,
	selectedAsAdditional: false,
	set: false,
	setProducts: [],
	shopId: args.shopId,
	startDate: args.startDate,
	startLocationId: args.startLocationId,
	vatPercent: args.stockProduct.vatPercent,
	unavailable: { from: null, until: null, details: {} },
	pricing: getItemPricingFromListPrice(args.price ?? 0, {
		deposit: args.stockProduct.terms?.deposit || undefined,
		taxRate: getTaxRateFromVatPercent(args.stockProduct.vatPercent),
		currency: args.currencyCode,
		taxExcluded: args.taxExcluded,
	}),
	charge: {
		paid: 0,
	},
	purchaseType: 'rental',
	fulfillmentDate: null,
});

export const getTicketSegmentOptions = (
	selectedTicket: ProductApi | null,
	ticketSegments: SegmentMap[],
) => {
	const productSegmentIdsWithPrice = (
		selectedTicket?.segmentPricings ?? []
	).flatMap(({ segments, fixedPrice }) => segments.map((segmentId) => ({ segmentId, fixedPrice })));

	const segmentMapsWithPrice = productSegmentIdsWithPrice
		.map(({ segmentId, fixedPrice }) => ({
			segment: getSkidataSegmentById(segmentId, ticketSegments),
			fixedPrice,
		}))
		.filter((s) => !!s.segment && s.fixedPrice !== '') as {
		segment: SegmentMap;
		fixedPrice: number;
	}[];

	return segmentMapsWithPrice;
};

export const getLiftTicketsFromPriceString = (
	products: ProductApi[],
	currency: CurrencyObject,
): string => {
	const lowestPrice =
		min(products.map((p) => getLowestLiftTicketPrice(p.segmentPricings ?? []))) ?? 0;

	return i18n.t('common:pricing.from', {
		price: getPricingString(lowestPrice, currency),
		defaultValue: 'From {{price}}',
	});
};

export const getVisibleTicketSegments = (ticketCategories: SegmentMap[]) =>
	ticketCategories.filter((t) => t.hidden === undefined || t.hidden === false);

const getTicketSegmentId = (ticketSegment: SegmentMap) => ticketSegment.externalSegmentId;

export const filterTicketProductsWithNoVisibleSegments = (visibleTicketSegments: SegmentMap[]) => (
	ticketProduct: ProductApi,
) => {
	const productSegmentIds = ticketProduct.segmentPricings?.flatMap((p) => p.segments);
	const visibleSegmentIds = visibleTicketSegments.map(getTicketSegmentId);
	return productSegmentIds?.some((s) => visibleSegmentIds.includes(s)) || false;
};

export const getTicketSegmentPrice = (
	ticketProduct: ProductApi,
	segmentId: string | null,
	currency: CurrencyObject,
) => {
	const segmentPricing = getTicketSegmentPricing(segmentId)(ticketProduct);
	const fixedPrice = parseInt(`${segmentPricing?.fixedPrice ?? 0}`);
	return getPricingString(fixedPrice, currency);
};

interface GetTicketsWithDynamicPricesArgs {
	ticketProducts: ProductApi[];
	segmentId: string;
	shopId: string;
	currencyCode: string;
	quantity: number;
	startDate: string;
}

export const getTicketsWithDynamicPrices = async (args: GetTicketsWithDynamicPricesArgs) => {
	const { ticketProducts, segmentId } = args;
	const ticketIds = ticketProducts
		.filter(doesTicketHaveSegment(segmentId))
		.map((t) => t.externalId)
		.filter(notUndefined);

	const dynamicPrices = await Callable.skidata.prices.getTicketPrice({
		...args,
		productIds: ticketIds,
	});

	return ticketProducts.map((ticketProduct) => {
		const newPrice = !!ticketProduct.externalId
			? dynamicPrices?.[ticketProduct.externalId]?.price
			: undefined;

		const newSegmentPricings = ticketProduct.segmentPricings?.map((s) =>
			s.segments[0] === segmentId && newPrice !== undefined ? { ...s, fixedPrice: newPrice } : s,
		);
		return {
			...ticketProduct,
			segmentPricings: newSegmentPricings,
		};
	});
};

export const fetchOrderPrice = async (args: GetSkidataOrderPriceArgs) => {
	return Callable.skidata.prices.getOrderPrice(args);
};

export const getTicketValidityAsMinMaxDateAndValidWeekdays = (ticket: ProductApi | null) => {
	const validity = ticket?.skidataProperties?.validity;
	if (!validity) return null;

	return {
		minDate: momentToDateInShopTimezone(moment(validity.from)).toISOString(),
		maxDate: momentToDateInShopTimezone(moment(validity.to)).toISOString(),
		validWeekdays: validity.validWeekdays,
	};
};

export const isDateOutsideOfValidity = (
	date: ISOString,
	validity: { minDate: ISOString; maxDate: ISOString },
) => {
	const mdate = date;
	const minDate = validity.minDate;
	const maxDate = validity.maxDate;
	return mdate < minDate || mdate > maxDate;
};
