import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiCheckLine } from 'react-icons/ri';

import { ResponsiblePersonDetails } from 'common/types';
import { useTranslation } from 'services/localization';

interface Props {
	responsiblePerson: ResponsiblePersonDetails;
}

const ThankYou = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const { responsiblePerson } = props;

	return (
		<Box>
			<Box className={classes.checkCircle}>
				<RiCheckLine size={30} color="inherit" />
			</Box>

			<Box mt={3} mb={5}>
				<Typography variant="h4">
					{t('completed.header', {
						firstName: responsiblePerson.firstName,
						defaultValue: 'Thank you {{firstName}}!',
					})}
				</Typography>
			</Box>
			<Typography variant="h5">
				{t('completed.confirmationInfo', 'Your order is confirmed')}
			</Typography>
			<Box mt={2} />
			<Typography variant="subtitle2">
				{t(
					'completed.confirmationSentInfo',
					'You will receive a confirmation email with your order details shortly',
				)}
				.
			</Typography>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		checkCircle: {
			width: 60,
			height: 60,
			border: '2px solid #008609',
			borderRadius: '50%',
			color: '#008609',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	}),
);

export default ThankYou;
