import { createReducer } from '@reduxjs/toolkit';
import * as ShopActions from 'redux/actions/shop';
import * as ViewActions from 'redux/actions/view';
import { defaultPhoneObject } from 'views/Shop/Location/Checkout/utils';

import {
	DeliveryOption,
	DeliveryTimeSlot,
	Languages,
	PhoneObject,
	UnitSystemType,
} from 'common/types';
import { CheckoutFormErrors } from 'hooks/useCheckoutForm';
import { getInitialOnlineLanguage } from 'services/localization';
import { ProductType } from 'services/types';

interface Filters {
	category: string | null;
	// Product type filer removed for now //Toomas
	productType: ProductType | null;
}

export type ViewState = {
	filters: Filters;
	filtersOpen: boolean;
	sidebarOpen: boolean;
	language: Languages;
	cartOpen: boolean;
	activeShopperIndex: number;
	activeUnitSystem: UnitSystemType | null;
	phoneObject: PhoneObject;
	checkoutFormErrors: CheckoutFormErrors;
	showReservationExpiredBanner: boolean;
	selectedDeliveryOption: DeliveryOption | null;
	pickupChecked: boolean;
	availablePickupSlots: DeliveryTimeSlot[] | null;
};

const INITIAL_STATE: ViewState = {
	filters: {
		category: null,
		productType: null,
	},
	filtersOpen: false,
	sidebarOpen: false,
	language: getInitialOnlineLanguage(),
	cartOpen: false,
	activeShopperIndex: 0,
	activeUnitSystem: null,
	phoneObject: defaultPhoneObject,
	checkoutFormErrors: {},
	showReservationExpiredBanner: false,
	selectedDeliveryOption: null,
	pickupChecked: false,
	availablePickupSlots: null,
};

const ViewReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(ViewActions.setLanguage, (state, action) => {
		state.language = action.payload;
	});

	builder.addCase(ViewActions.setCartOpen, (state, action) => {
		state.cartOpen = action.payload;
	});

	builder.addCase(ViewActions.toggleCartOpen, (state, action) => {
		state.cartOpen = !state.cartOpen;
	});

	builder.addCase(ViewActions.setFilterCategory, (state, action) => {
		state.filters.category = action.payload;
	});

	builder.addCase(ViewActions.setFilterProductType, (state, action) => {
		state.filters.productType = action.payload;
	});

	builder.addCase(ViewActions.setFiltersOpen, (state, action) => {
		state.filtersOpen = action.payload;
	});

	builder.addCase(ViewActions.setSidebarOpen, (state, action) => {
		state.sidebarOpen = action.payload;
	});

	builder.addCase(ViewActions.setActiveShopperIndex, (state, action) => {
		state.activeShopperIndex = action.payload;
	});

	builder.addCase(ViewActions.setActiveUnitSystem, (state, action) => {
		state.activeUnitSystem = action.payload;
	});

	builder.addCase(ViewActions.setCheckoutFormErrors, (state, action) => {
		state.checkoutFormErrors = action.payload;
	});

	builder.addCase(ViewActions.setPhoneObject, (state, action) => {
		state.phoneObject = action.payload;
	});

	builder.addCase(ViewActions.showReservationExpiredBanner, (state, action) => {
		state.showReservationExpiredBanner = action.payload;
	});

	builder.addCase(ViewActions.setDeliveryOption, (state, action) => {
		state.selectedDeliveryOption = action.payload;
	});

	builder.addCase(ViewActions.setAvailablePickupSlots, (state, action) => {
		state.availablePickupSlots = action.payload;
	});

	builder.addCase(ViewActions.setPickupChecked, (state, action) => {
		state.pickupChecked = action.payload;
	});

	/**
	 * If the shop changes, reset view state
	 */
	builder.addCase(ShopActions.updateShopListeners.pending, (state, action) => {
		state.filters = INITIAL_STATE.filters;
		state.cartOpen = INITIAL_STATE.cartOpen;
		state.filtersOpen = INITIAL_STATE.filtersOpen;
	});
});

export default ViewReducer;
