import { TFunction } from 'i18next';

import { getFunctionsBaseUrl } from 'common/api/configs';
import {
	CreateOnlineOrder,
	StripeClientPaymentResult,
	StripePaymentRequest,
	VerifyOnlinePayment,
} from 'common/api/db/functions/_callable/orders/online';
import { Callable } from 'common/frontend/callable';
import { OnlinePaymentMethod, StripePaymentMethod } from 'common/modules/payments/types';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { Environment, Languages, OrderObject, StripeAdditionalDetailRequest } from 'common/types';
import { StorageKeys, Storages, getBrowserInfo, getFromStorage } from 'common/utils/frontUtils';

import { getRedirectUrl } from '../../../utils';

const functionsBaseUrl = getFunctionsBaseUrl(process.env.REACT_APP_ENV as Environment);

export type StripePaymentResult =
	| {
			success: true;
			action?: StripeClientPaymentResult['next_action'];
			order?: OrderObject;
			intent?: {
				clientSecret: string | null;
				id: string;
			};
	  }
	| {
			success: false;
			error: { methodId: OnlinePaymentMethod | null; error: string };
	  };

interface StripePaymentArgs {
	paymentMethodId: string;
	paymentMethodName: StripePaymentMethod;
	order: OrderObject;
	shopId: string;
	transactionId: string;
	userLanguage: Languages;
	t: TFunction;
}

export const stripePayment = async ({
	paymentMethodId,
	paymentMethodName,
	order,
	shopId,
	transactionId,
	userLanguage,
	t,
}: StripePaymentArgs): Promise<StripePaymentResult> => {
	const orderId = order.rentalInfo.id;
	const clientPaymentData: StripePaymentRequest = {
		provider: 'STRIPE',
		method: paymentMethodName,
		paymentMethodId,
		returnUrl: `${functionsBaseUrl}/api/v1/transaction-redirect/${transactionId}?redirectUrl=${encodeURIComponent(
			getRedirectUrl({ orderId, transactionId }),
		)}`,
		origin: window.location.origin,
		browserInfo: getBrowserInfo(window),
		transactionId,
	};
	const data: CreateOnlineOrder = {
		order,
		shopId,
		clientPaymentData,
		userLanguage,
	};
	const paymentError = t('common:errors.paymentError', 'Error processing payment');
	try {
		const result = await Callable.orders.online.create(data);
		const providerResult = result.providerResult;
		if (result.status === 'SUCCESS') {
			return { success: true, order: result.order };
		}
		if (providerResult?.provider !== 'STRIPE') {
			throw new Error(paymentError);
		}
		if (result.status === 'REQUIRES_ACTION') {
			return {
				success: true,
				action: providerResult.next_action,
				order: undefined,
				intent: { id: providerResult.id, clientSecret: providerResult.clientSecret },
			};
		}
		return {
			success: false,
			error: {
				methodId: paymentMethodName,
				error: providerResult?.cancellation_reason ?? paymentError,
			},
			// transactionId,
		};
	} catch (e) {
		errorHandler.report(e);
		return {
			success: false,
			error: { methodId: paymentMethodName, error: paymentError },
		};
	}
};

interface AdditionalDetailsArgs {
	data: StripeAdditionalDetailRequest;
	t: TFunction;
}

export const stripePaymentWithAdditionalDetails = async ({
	data,
	t,
}: AdditionalDetailsArgs): Promise<StripePaymentResult> => {
	const sessionPaymentMethod = getFromStorage(
		Storages.SESSION,
		StorageKeys.PAYMENT_METHOD,
	) as OnlinePaymentMethod | null;
	const paymentError = t('common:errors.paymentError', 'Error processing payment');
	try {
		const request: VerifyOnlinePayment = {
			orderId: data.orderId,
			transactionId: data.transactionId,
			returnUrl: data.returnUrl,
			origin: data.origin,
			paymentDetails: {
				provider: 'STRIPE',
				intentId: data.intentId,
				stripeAccountId: data.stripeAccountId,
			},
			userLanguage: data.userLanguage,
		};
		const result = await Callable.orders.online.verify(request);
		if (result.status === 'SUCCESS') {
			return {
				success: true,
				order: result.order,
			};
		}
		if (result.providerResult?.provider !== 'STRIPE') {
			throw new Error(paymentError);
		}
		if (result.status === 'REQUIRES_ACTION') {
			return {
				success: true,
				action: result.providerResult.next_action,
				order: undefined,
				intent: { id: result.providerResult.id, clientSecret: result.providerResult.clientSecret },
			};
		}
		return {
			success: false,
			error: {
				methodId: sessionPaymentMethod,
				error: result.providerResult?.cancellation_reason ?? paymentError,
			},
		};
	} catch (e) {
		errorHandler.report(e);
		return {
			success: false,
			error: {
				methodId: sessionPaymentMethod,
				error: paymentError,
			},
		};
	}
};
