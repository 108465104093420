import React, { useEffect, useMemo, useState } from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { useLocation } from 'react-router';
import * as CartActions from 'redux/actions/cart';
import { useDispatch, useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import useScrollToError from 'common/hooks/useScrollToError';
import { deliveryOptionToCartDelivery } from 'common/modules/delivery/utils';
import { sendEventToParentPage } from 'common/modules/embed';
import { hasMultipleVariants } from 'common/modules/products/variants';
import { ProductApi, PurchaseTypes } from 'common/types';
import { isRunInIframe } from 'common/utils/browserUtils';
import { getLongestDurationOptionAsYears } from 'common/utils/duration';
import { getDurationFromDurationWithPriceOption } from 'common/utils/pricing';
import BoxButton from 'components/BoxButton';
import useOnlineAvailability from 'hooks/useOnlineAvailability';
import useProductForm from 'hooks/useProductForm';
import useScrollToCart from 'hooks/useScrollToCart';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';
import { isProductInStock, isSetProductInStock } from 'utils/products';
import { getMaxAvailabilityRangeYears } from 'utils/startTimes';

import AmountSelector from './components/AmountSelector';
import DurationSelector from './components/DurationSelector';
import SegmentSelector from './components/SegmentSelector';
import SelectionSummary from './components/SelectionSummary';
import StartDateTimeSelector from './components/StartDateTimeSelector';
import VariantSelector from './components/VariantSelector';
import { getTotalQuantityForSelection } from './utils';

interface Props {
	product: ProductApi;
	locationId: string;
}

const RentalProductForm = (props: Props) => {
	const urlLocation = useLocation();
	const dispatch = useDispatch();
	const { product, locationId } = props;
	const { t, getTranslation } = useTranslation();
	const classes = useStyles();
	const theme = useTheme();
	const [openCart, isCartOpen] = useScrollToCart();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const { scrollToFirstError } = useScrollToError({
		rootId: 'product-form',
		scrollOffset: -120,
		onScroll: (offset) => {
			if (isRunInIframe()) {
				sendEventToParentPage({
					origin: 'rentle',
					type: 'trigger-scroll',
					payload: {
						offset,
					},
				});
			}
		},
	});
	const form = useProductForm({
		productId: product.id,
		purchaseType: PurchaseTypes.rental,
		locationId,
	});

	const stockProducts = useSelector(StockSelectors.stockProductsData);
	const itemsInCart = useSelector(CartSelectors.cartProductsCount);
	const taxExcluded = useSelector(ShopSelectors.taxExcluded);
	const currency = useSelector(ShopSelectors.shopCurrency);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const timezone = useSelector(ShopSelectors.shopTimezone);
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const hasDelivery = useSelector(ViewSelectors.deliverySelected);
	const isDeliveryOnly = useSelector(ShopSelectors.isDeliveryOnly);
	const isEmbedded = isRunInIframe();
	const [initialProductSelectionIsAvailable, setInitialProductSelectionIsAvailable] = useState(
		false,
	);
	const { pushRoute, Routes } = useRoutes();

	useEffect(() => {
		if (isDeliveryOnly && !selectedDeliveryOption) {
			pushRoute(Routes.browse);
		}
	}, [selectedDeliveryOption, isDeliveryOnly, pushRoute, Routes.browse]);

	const variantId = form.values.selection?.variantId;
	const setProductsVariantIds = useMemo(
		() => (product.set ? form.values.selection?.children?.map((c) => c.variantId) : null),
		[form.values.selection?.children, product.set],
	);
	const durationOptions = form.meta.durationOptions.map((d) => d.option);

	const { quantity, availability, startTimeCounts } = useOnlineAvailability(product.id, {
		locationId,
		startDate: moment().toISOString(),
		endDate: moment()
			.add(getMaxAvailabilityRangeYears(getLongestDurationOptionAsYears(durationOptions)), 'years')
			.toISOString(),
		purchaseType: PurchaseTypes.rental,
	});

	const selectionTotalQuantity = useMemo(
		() =>
			getTotalQuantityForSelection({
				quantity,
				selection: product.set
					? { set: true, variantIds: setProductsVariantIds ?? [] }
					: { set: false, variantId },
			}),
		[product.set, quantity, setProductsVariantIds, variantId],
	);

	const handleAddToBag = async () => {
		const { valid, value } = form.actions.validate();

		if (!valid) {
			scrollToFirstError();
			return;
		}

		if (
			valid &&
			value &&
			value.purchaseType === PurchaseTypes.rental &&
			!!form.meta.selectedDuration
		) {
			if (itemsInCart === 0 && !!selectedDeliveryOption && !!value.deliveryTimeslot) {
				const delivery = deliveryOptionToCartDelivery(selectedDeliveryOption, {
					taxExcluded,
					currency: currency.code,
					deliveryTimeslot: value.deliveryTimeslot,
					openingHours,
					timezone,
					getTranslation,
					t,
				});
				dispatch(CartActions.setDelivery(delivery));
			}

			const selectedAsAdditional =
				new URLSearchParams(urlLocation.search).get('ref') === 'recommended';

			dispatch(
				CartActions.addSelectionToCart({
					selection: value.selection,
					startDate: value.startDate,
					startLocationId: value.locationId,
					duration: getDurationFromDurationWithPriceOption(form.meta.selectedDuration.option),
					endLocationId: value.locationId,
					quantity: form.values.quantity,
					selectedAsAdditional,
					segment: value.segment,
					purchaseType: PurchaseTypes.rental,
				}),
			);

			openCart();
		}
	};

	const inStock = product.set
		? isSetProductInStock(product, stockProducts, quantity)
		: isProductInStock(product, quantity);

	const isFixedDuration = form.meta.selectedDuration?.option.timePeriod === 'fixed';

	const hasDurationOptions = !!form.meta.durationOptions.length;
	const quantitiesLoading = quantity.loading;
	const availabilitiesLoading = quantity.loading || availability.loading || startTimeCounts.loading;
	const showSummary = !!form.values.startDate;
	const hasVariants = hasMultipleVariants(product);
	const bottomDisabled =
		!form.values.selection || selectionTotalQuantity === 0 || !hasDurationOptions;

	return (
		<Box id="product-form">
			<Box mt={4}>
				<VariantSelector
					product={product}
					value={form.values.selection}
					onChange={form.actions.setSelection}
					showError={!!form.errors.selection}
					variantQuantities={quantity}
					purchaseType={PurchaseTypes.rental}
				/>
			</Box>
			<Box mt={2} pt={hasVariants ? 3 : 0} className={hasVariants ? classes.divider : ''}>
				<AmountSelector
					product={product}
					selection={form.values.selection}
					quantity={selectionTotalQuantity}
					loading={quantitiesLoading}
					value={form.values.quantity}
					onChange={form.actions.setQuantity}
					error={form.errors.quantity ?? undefined}
					outOfStock={!inStock}
					isDeliveryDisabled={hasDelivery && !hasDurationOptions}
				/>
			</Box>

			{(!isFixedDuration || !form.meta.forcedStartDateAndTime) && (
				<Box mt={3} pt={3} className={classes.divider}>
					<Typography
						variant="body1"
						className={classNames(classes.bookingDetails, {
							[classes.textDisabled]: bottomDisabled,
						})}
					>
						{t('common:booking.bookingDetails', 'Booking details')}
					</Typography>
				</Box>
			)}
			{!!form.meta.segmentOptions?.length && (
				<Box mt={2}>
					<SegmentSelector
						options={form.meta.segmentOptions}
						value={form.values.segment}
						onChange={form.actions.setSegment}
						error={form.errors.segment ?? undefined}
						disabled={quantitiesLoading || bottomDisabled}
					/>
				</Box>
			)}
			{!isFixedDuration && (
				<Box mt={2}>
					<DurationSelector
						options={form.meta.durationOptions}
						value={form.values.durationId}
						onChange={form.actions.setDurationId}
						quantity={form.values.quantity}
						priceIncrease={form.meta.selectedVariant?.rentals?.priceIncrease ?? 0}
						error={form.errors.durationId ?? undefined}
						disabled={quantitiesLoading || !form.values.selection || bottomDisabled}
					/>
				</Box>
			)}
			{!isCartOpen && (
				<StartDateTimeSelector
					product={product}
					availabilitiesLoading={availabilitiesLoading}
					form={form}
					setInitialProductSelectionIsAvailable={setInitialProductSelectionIsAvailable}
					availability={availability}
					setProductsVariantIds={setProductsVariantIds}
					startTimeCounts={startTimeCounts}
					purchaseType="rental"
				/>
			)}
			{showSummary && (
				<Box mt={4}>
					{inStock && initialProductSelectionIsAvailable && (
						<SelectionSummary
							selection={form.values.selection}
							purchaseType={PurchaseTypes.rental}
							quantity={form.values.quantity}
							charge={form.meta.price ?? 0}
							startDate={form.values.startDate ?? null}
							duration={form.meta.selectedDuration?.option ?? null}
						/>
					)}
					<Box mt={8} />
					<Box
						className={classNames({
							[classes.mobileFixedBottomWrapper]: !isEmbedded,
							[classes.embeddedBottomWrapper]: isEmbedded,
						})}
					>
						<BoxButton
							text={t('common:actions.addToBag', 'Add to bag')}
							variant="filled"
							padding={isSmallScreen ? 'large' : 'normal'}
							onClick={handleAddToBag}
							disabled={
								availabilitiesLoading ||
								!inStock ||
								!initialProductSelectionIsAvailable ||
								(!!hasDelivery && !hasDurationOptions) ||
								selectionTotalQuantity === 0
							}
							blurOnClick
						/>
					</Box>
				</Box>
			)}
		</Box>
	);
};

const useStyles = makeStyles((theme) =>
	createStyles({
		mobileFixedBottomWrapper: {
			position: 'fixed',
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: 1200,
			[theme.breakpoints.down('md')]: {
				padding: theme.spacing(3),
			},
			[theme.breakpoints.up('md')]: {
				zIndex: 1,
				position: 'static',
				marginBottom: theme.spacing(4),
			},
		},
		embeddedBottomWrapper: {
			marginBottom: theme.spacing(4),
		},
		bookingDetails: {
			fontWeight: 500,
		},
		textDisabled: {
			opacity: 0.4,
		},
		divider: {
			borderTop: `1px solid #e7e7e7`,
		},
	}),
);

export default RentalProductForm;
