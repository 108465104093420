import Stripe from 'stripe';

import { httpsCallable } from 'common/frontend/firebase/functions';
import { WebhookApi } from 'common/models/Webhook/types';
import { WebhookEventDb } from 'common/models/_atoms/WebhookEvent/types';
import { RestrictedFeature } from 'common/modules/plans';
import errorHandler from 'common/services/errorHandling/errorHandler';
import {
	DiscountCodeApi,
	OnlineOrderRequest,
	OrderObject,
	RefundRequest,
	SimplePaymentResult,
} from 'common/types';
import {
	AdditionalAdminDetailRedirectRequest,
	AdditionalAdminDetailRequest,
	AdditionalDetailRedirectRequest,
	AdditionalDetailRequest,
	AdyenPaymentState,
	ClientCreateAccountHolderRequest,
	CreateAccountHolderResponse,
	DisablePaymentCardRequest,
	ExtendOrderPaymentRequest,
	GetPaymentMethods,
	OnlinePaymentRequest,
} from 'common/types/adyen';

import { CreateOrUpdateContact } from './types';

interface FeaturesUpdate {
	enabledFeatures?: RestrictedFeature[];
	hiddenFeatures?: RestrictedFeature[];
}

export interface CardAuthInput {
	shopId: string;
	paymentData: AdyenPaymentState['data'];
	browserData: { origin: string; href: string };
	primary?: boolean;
}

interface DiscountCodeResponse {
	discountCode: DiscountCodeApi | undefined;
	errorMessage: string;
}

type CallableFunctions = {
	['online-onlineOrderWithPayment']: [OnlinePaymentRequest, SimplePaymentResult];
	['online-onlineOrderWithPayment_newOnline']: [
		OnlinePaymentRequest,
		{ paymentResult: SimplePaymentResult; order: OrderObject },
	];
	['online-addOnlinePaymentDetails']: [
		AdditionalDetailRedirectRequest | AdditionalDetailRequest,
		SimplePaymentResult,
	];
	['online-addOnlinePaymentDetails_newOnline']: [
		AdditionalDetailRedirectRequest | AdditionalDetailRequest,
		{ paymentResult: SimplePaymentResult; order: OrderObject },
	];
	['online-setOnlineOrder']: [OnlineOrderRequest, 'OK'];
	['online-setOnlineOrder_newOnline']: [OnlineOrderRequest, OrderObject];
	['online-getPendingOrderObject']: [{ orderId: string; shopId: string }, OrderObject | null];
	updateShopFeatures: [{ shopId: string; data: FeaturesUpdate }, void];
	setupInitialReadOnlyData: [{ shopId: string }, void];
	['online-isLiteShop']: [{ shopId: string }, boolean];
	['http-getValidatedDiscountCode']: [
		{ shopId: string; discountCode: string },
		DiscountCodeResponse,
	];
	['http-getDoesShopHaveActiveDiscountCodes']: [{ shopId: string }, boolean];
	createAccountHolder: [
		{
			shopId: string;
			info: ClientCreateAccountHolderRequest;
		},
		CreateAccountHolderResponse,
	];
	['admin-newOrderNumber']: [{ orderId: string; shopId: string }, number];
	['http-extendOrderWithPayment']: [
		ExtendOrderPaymentRequest,
		{ order?: OrderObject; result: SimplePaymentResult },
	];
	['http-addExtendPaymentDetails']: [
		AdditionalDetailRedirectRequest | AdditionalDetailRequest,
		{ order?: OrderObject; result: SimplePaymentResult },
	];
	['http-addHubspotProperties']: [CreateOrUpdateContact, 'OK'];
	['http-addHubspotSession']: [{ email: string }, number | null];
	['http-hasFeature']: [
		{ feature: RestrictedFeature | RestrictedFeature[]; shopId: string },
		boolean,
	];
	['http-pingWebhook']: [{ webhook: Partial<WebhookApi> }, string | null];
	['http-getWebhookTestPayload']: [{ event: WebhookEventDb }, object];
	['http-emailOrderConfirmation']: [{ data: OnlineOrderRequest; email: string | undefined }, 'OK'];
	['http-validateApplePaySession']: [
		{
			validationURL: string;
			env: 'TEST' | 'PRODUCTION';
			merchantName: string;
			hostName: string;
			merchantId: string;
		},
		object,
	];
	['http-getOrderWithCode']: [{ orderId: string } | { linkId: string }, OrderObject | null];
	['http-confirmLinkOrder']: [{ order: OrderObject; emailData: OnlineOrderRequest }, 'OK'];
	['http-saveOrder']: [OrderObject, OrderObject];
	['admin-authorisePaymentCard']: [
		CardAuthInput,
		{ result: SimplePaymentResult; transactionId: string },
	];
	getPaymentMethods: [GetPaymentMethods, { data: null | ICheckout.PaymentMethodsResponse }];
	['admin-addAdditionalAuthData']: [
		{
			shopId: string;
			paymentData: AdditionalAdminDetailRedirectRequest | AdditionalAdminDetailRequest;
			primary?: boolean;
		},
		{ result: SimplePaymentResult; transactionId: string },
	];
	['admin-changePrimaryPaymentCard']: [
		{
			shopId: string;
			cardToken: string;
		},
		{
			result: string;
		},
	];
	['admin-disablePaymentCard']: [DisablePaymentCardRequest, IRecurring.DisableResult];
	['online-getBookedOnlineOrderObject']: [{ orderId: string; shopId: string }, null | OrderObject];
	['online-updateOrderObject']: [OnlineOrderRequest, 'OK'];
	refundTransaction: [
		RefundRequest,
		(
			| IPayments.ModificationResult
			| null
			| Stripe.Response<Stripe.PaymentIntent>
			| Stripe.Response<Stripe.Refund>
		),
	];
};

export type CallableFunction = keyof CallableFunctions;

export type CallableInput<T extends CallableFunction> = CallableFunctions[T][0];

export type CallableOutput<T extends CallableFunction> = CallableFunctions[T][1];

const createFunction = <T extends CallableFunction>(
	name: T,
): ((data: CallableInput<T>) => Promise<CallableOutput<T>>) => {
	const callable = httpsCallable<CallableInput<T>, CallableOutput<T>>(name);
	return async (data: CallableInput<T>) => {
		try {
			const result = await callable(data);
			return result.data;
		} catch (e) {
			errorHandler.report(e);
			throw e;
		}
	};
};

export const onlineOrderWithPayment = createFunction('online-onlineOrderWithPayment');
export const onlineOrderWithPayment_newOnline = createFunction(
	'online-onlineOrderWithPayment_newOnline',
);

export const addOnlinePaymentDetails = createFunction('online-addOnlinePaymentDetails');
export const addOnlinePaymentDetails_newOnline = createFunction(
	'online-addOnlinePaymentDetails_newOnline',
);

export const setOnlineOrder = createFunction('online-setOnlineOrder');
export const setOnlineOrder_newOnline = createFunction('online-setOnlineOrder_newOnline');

export const getPendingOrderObject = createFunction('online-getPendingOrderObject');

export const setupInitialReadOnlyData = createFunction('setupInitialReadOnlyData');

export const fetchIsLiteShop = createFunction('online-isLiteShop');

export const getValidatedDiscountCode = createFunction('http-getValidatedDiscountCode');

export const getDoesShopHaveActiveDiscountCodes = createFunction(
	'http-getDoesShopHaveActiveDiscountCodes',
);

export const createAccountHolder = createFunction('createAccountHolder');

export const getNewOrderNumber = createFunction('admin-newOrderNumber');

export const extendOrderWithPayment = createFunction('http-extendOrderWithPayment');

export const addExtendPaymentDetails = createFunction('http-addExtendPaymentDetails');

export const addHubspotProperties = createFunction('http-addHubspotProperties');

export const addHubspotSession = createFunction('http-addHubspotSession');

export const shopHasFeature = createFunction('http-hasFeature');

export const pingWebhook = createFunction('http-pingWebhook');

export const getWebhookTestPayload = createFunction('http-getWebhookTestPayload');

export const emailOrderConfirmation = createFunction('http-emailOrderConfirmation');

export const validateApplePaySession = createFunction('http-validateApplePaySession');

export const getOrderWithCode = createFunction('http-getOrderWithCode');

export const confirmLinkOrder = createFunction('http-confirmLinkOrder');

export const saveOrder = createFunction('http-saveOrder');

export const authorisePaymentCard = createFunction('admin-authorisePaymentCard');

export const getAdyenPaymentMethods = createFunction('getPaymentMethods');

export const addAdditionalAuthData = createFunction('admin-addAdditionalAuthData');

export const changePrimaryPaymentCard = createFunction('admin-changePrimaryPaymentCard');

export const disablePaymentCard = createFunction('admin-disablePaymentCard');

export const getBookedOnlineOrderObject = createFunction('online-getBookedOnlineOrderObject');

export const updateOrderObject = createFunction('online-updateOrderObject');
