import React, { useEffect } from 'react';

import { useParams } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as NavActions from 'redux/actions/nav';
import { useDispatch, useSelector } from 'redux/hooks';
import * as NavSelectors from 'redux/selectors/nav';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import { getShopUrlOrDomainValue } from 'shared-server/url';

import EmptyState from 'common/components/states/EmptyState';
import LoadingState from 'common/components/states/LoadingState';
import { areAllDeliveryOptionsHidden } from 'common/modules/delivery/utils';
import { setGaUserProperties } from 'common/services/analytics';
import CartModal from 'components/Cart/CartModal';
import useSearchParams from 'hooks/useSearchParams';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';
import { getRootPath } from 'services/routing/utils';

import AboutPage from './About';
import BrowsePage from './Browse';
import CategoriesPage from './Categories';
import Checkout from './Checkout';
import { Confirm } from './Confirm';
import HiddenLocation from './HiddenLocations';
import LocationChangeGuard from './LocationChangeGuard';
import LocationsPage from './Locations';
import PackageTicketsPage from './PackageTickets';
import ProductPage from './Product';
import TicketsPage from './Tickets';

const LocationPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { shopName, locationName } = useParams<{
		shopName?: string;
		locationName?: string;
	}>();
	const navSelector = useSelector(NavSelectors.nav);
	const { getPath, getRoute, Routes } = useRoutes({ shopName, locationName });
	const allShopUrls = useSelector(ShopSelectors.allShopLocationUrls);
	const shopMainLocation = useSelector(ShopSelectors.shopMainLocation);
	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const isDeliveryOnly = useSelector(ShopSelectors.isDeliveryOnly);
	const deliveryOptions = useSelector(ShopSelectors.activeLocationDeliveryOptions) ?? [];
	const noVisibleDeliveryOptions = areAllDeliveryOptionsHidden(deliveryOptions);

	const deliveryOptionsLoading = useSelector(ShopSelectors.shopDeliveryOptionsLoading);
	const productsLoading = useSelector(StockSelectors.stockProductsLoading);
	// Location name found from shop URLs, but it's not listed by id (where hidden locations are filtered out)
	const isHiddenShopLocation =
		!activeLocation && (locationName ? !!allShopUrls[locationName] : !!shopMainLocation);
	const unknownLocation = !activeLocation && !isHiddenShopLocation;

	const homePageLayout = useSelector(ShopSelectors.onlineLayout)?.defaultLayout;
	const searchParams = useSearchParams();
	useEffect(() => {
		if (!!navSelector.shopName) {
			setGaUserProperties(
				{
					shop_url: getShopUrlOrDomainValue({
						hostname: window.location.hostname,
						shopNamePath: navSelector.shopName,
					}),
				},
				{ sendToAllTags: false },
			);
		}
	}, [dispatch, navSelector.shopName]);

	useEffect(() => {
		dispatch(NavActions.setLocationName(locationName ?? null));
		if (!!locationName) {
			setGaUserProperties(
				{
					location_url: locationName,
				},
				{ sendToAllTags: false },
			);
		}
	}, [dispatch, locationName]);

	// We need make sure that the shopName and locationName have been added to redux state before going forward to the app
	// Without this explicit check, useRoutes hook might have incorrect locationName (because it's not yet in state) causing incorrect redirections
	// This trusts that locationName is always explicitly added as "null" in case it's not existing (in useEffect above)
	if (navSelector.shopName === undefined || navSelector.locationName === undefined) {
		return null;
	}

	const deliveryOnlyStoreLoading = isDeliveryOnly && (deliveryOptionsLoading || productsLoading);
	const deliveryOnlyStoreHidden =
		isDeliveryOnly && !deliveryOptionsLoading && !productsLoading && noVisibleDeliveryOptions;

	if (deliveryOnlyStoreLoading) return <LoadingState />;

	if (isHiddenShopLocation || deliveryOnlyStoreHidden) {
		const title = deliveryOnlyStoreHidden ? 'No delivery options available' : undefined;
		const description = deliveryOnlyStoreHidden
			? "This store doesn't offer any delivery options at the moment"
			: undefined;
		return (
			<>
				<LocationChangeGuard />
				<HiddenLocation
					title={title}
					description={description}
					locationId={locationName ? allShopUrls[locationName] : shopMainLocation!.id}
				/>
			</>
		);
	}

	if (isDeliveryOnly) {
		if (deliveryOptionsLoading || productsLoading) return null;
		if (noVisibleDeliveryOptions)
			return (
				<EmptyState
					title={t('Location.noDeliveryOptionsAvailable', 'No delivery options available')}
					description={t(
						'Location.noDeliveryOptionsAvailableDescription',
						"This store doesn't offer any delivery options at the moment.",
					)}
				/>
			);
	}

	if (unknownLocation) {
		return <Redirect to={getRootPath({ shopName })} />;
	}

	const shouldNavigateToBrowsePage = !!searchParams.value.get('category');
	const hasCategoryPage = homePageLayout === 'categories';
	const showCategoryPage = hasCategoryPage && !shouldNavigateToBrowsePage;

	return (
		<>
			<LocationChangeGuard />
			<CartModal />
			<Switch>
				<Route
					exact
					path={getRoute(Routes.browse)}
					component={showCategoryPage ? CategoriesPage : BrowsePage}
				/>
				<Route exact path={getRoute(Routes.locations)} component={LocationsPage} />
				<Route exact path={getRoute(Routes.about)} component={AboutPage} />
				<Route exact path={getRoute(Routes.product)} component={ProductPage} />
				<Route exact path={getRoute(Routes.tickets)} component={TicketsPage} />
				<Route exact path={getRoute(Routes.packageTicket)} component={PackageTicketsPage} />
				<Route exact path={getRoute(Routes.checkout)} component={Checkout} />
				<Route path={getRoute(Routes.confirm)} component={Confirm} />
				<Redirect to={getPath(Routes.browse)} />
			</Switch>
		</>
	);
};

export default LocationPage;
