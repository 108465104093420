import type { Api } from 'common/db/api/paths';
import { getIsRentleHostname } from 'common/utils/urls';

const getLocalhostCustomDomainShop = () => {
	const localCustomDomainShop = process.env.REACT_APP_LOCAL_CUSTOM_DOMAIN_SHOP;
	if (typeof window === 'undefined') return undefined;
	if (window?.location.host !== 'localhost:4002' || !localCustomDomainShop) return undefined;
	return localCustomDomainShop;
};

export const getShopUrlOrDomain = ({
	hostname,
	shopNamePath,
}: {
	hostname: string;
	shopNamePath: string | undefined;
}): { shopUrl: string | undefined } | { shopDomain: string } => {
	const localhostCustomDomainShop = getLocalhostCustomDomainShop();
	if (!!localhostCustomDomainShop) {
		return { shopUrl: localhostCustomDomainShop };
	}
	const isCustomDomain = !getIsRentleHostname(hostname);
	return isCustomDomain ? { shopDomain: hostname } : { shopUrl: shopNamePath };
};

export const getShopUrlOrDomainValue = ({
	hostname,
	shopNamePath,
}: {
	hostname: string;
	shopNamePath: string | undefined;
}): string | undefined => {
	const isShopUrlData = (
		data: { shopUrl: string | undefined } | { shopDomain: string },
	): data is { shopUrl: string | undefined } => !(data as { shopDomain: string }).shopDomain;

	const rentleDomainOrPath = getShopUrlOrDomain({ hostname, shopNamePath });
	return isShopUrlData(rentleDomainOrPath)
		? rentleDomainOrPath.shopUrl
		: rentleDomainOrPath.shopDomain;
};

export const getShopUrlDoc = async (
	api: Api,
	args: { shopUrl: string | undefined } | { shopDomain: string },
) => {
	const isShopUrlData = (
		data: { shopUrl: string | undefined } | { shopDomain: string },
	): data is { shopUrl: string | undefined } => !(data as { shopDomain: string }).shopDomain;

	if (isShopUrlData(args)) {
		return args.shopUrl ? api.shopUrls.doc(args.shopUrl).get({ withDocId: true }) : undefined;
	}
	const shopUrlDocs = await api.shopUrls.get
		.whereArray('customDomains', 'array-contains', args.shopDomain)
		.get({ withDocId: true });
	return shopUrlDocs[0] ?? undefined;
};
