import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { OrderObject } from 'common/types';
import {
	hasMissingKeycardNumbers,
	hasMissingKeycardsDTAOrderStatus,
	hasNewKeycards,
	hasSkidataConfirmationNumber,
	shoppersMissingDTAImages,
} from 'common/utils/liftTicketUtils';
import { useTranslation } from 'services/localization';

interface Props {
	order: OrderObject;
}

const SkidataInfo = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const { rentalInfo, products, shoppers } = props.order;
	const { skidataProps } = rentalInfo;

	const hasSkidataConfirmation = hasSkidataConfirmationNumber(rentalInfo);
	const includesNewKeycards = hasNewKeycards(products);
	const isMissingKeycardNumbers = hasMissingKeycardNumbers(products);
	const isMissingPhotos = shoppersMissingDTAImages(shoppers, products);

	const failedToCommitKeycardNumbers =
		!includesNewKeycards &&
		!isMissingKeycardNumbers &&
		hasMissingKeycardsDTAOrderStatus(rentalInfo);

	const renderInstructions = () => {
		if (!hasSkidataConfirmation) {
			return (
				<Typography variant="subtitle2">
					{t(
						'common:tickets.missingConfirmationInstruction',
						'Please visit the shop to pick up your tickets.',
					)}
				</Typography>
			);
		}
		if (isMissingKeycardNumbers && isMissingPhotos) {
			return (
				<Typography variant="subtitle2">
					{t(
						'common:tickets.missingKeycardNumbersAndPhotosWarning',
						'Your order includes lift tickets without a valid photo and keycards, please visit the rental shop.',
					)}
				</Typography>
			);
		}
		if (failedToCommitKeycardNumbers) {
			return (
				<Typography variant="subtitle2">
					{t(
						'common:tickets.failedToCommitKeycardNumbers',
						'Something went wrong when registering your lift tickets, please visit the rental shop.',
					)}
				</Typography>
			);
		}

		if (isMissingKeycardNumbers) {
			return (
				<Typography variant="subtitle2">
					{t(
						'common:tickets.ticketsNotAssignedInstruction',
						'The tickets in your order have not been assigned to keycards. Please visit the shop to assign your tickets to keycards.',
					)}
				</Typography>
			);
		}
		if (isMissingPhotos) {
			return (
				<Typography variant="subtitle2">
					{t(
						'common:tickets.missingPhotosWarning',
						'Your order includes lift tickets without a valid photo, please visit the rental shop.',
					)}
				</Typography>
			);
		}
		if (includesNewKeycards) {
			return (
				<Typography variant="subtitle2">
					{t(
						'common:tickets.newKeycardsInstruction',
						'Your tickets can be picked up from the shop, or from a SKIDATA self-service machine by entering the above booking number or by scanning the QR code which has been sent to your email. Please note that self-service machines may not be available at your location.',
					)}
				</Typography>
			);
		}

		return (
			<Typography variant="subtitle2">
				{t(
					'common:tickets.ticketsAssignedInstruction',
					'Your tickets have been assigned to existing keycards, and will be automatically activated when they are scanned at a gate for the first time.',
				)}
			</Typography>
		);
	};

	return (
		<Box>
			{hasSkidataConfirmation && (
				<Box mt={2}>
					<Typography variant="body1" gutterBottom>
						{t('completed.bookingNumber', 'Booking number')}
						<span className={classes.bookingNumber}>{skidataProps?.confirmationNumber}</span>
					</Typography>
				</Box>
			)}
			<Box mt={2}>{renderInstructions()}</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		bookingNumber: {
			border: `2px dashed #CBCBCB`,
			padding: theme.spacing(0.75, 1.5),
			borderRadius: 4,
			marginLeft: theme.spacing(1.5),
		},
	}),
);

export default SkidataInfo;
