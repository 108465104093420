import moment from 'moment-timezone';

import { executeWhenConsent } from 'common/frontend/helpers/CookieQueue';
import { getInitialLanguage, getMomentLocaleCode } from 'common/locales/utils';
import { CookieScriptCategories } from 'common/services/cookieConsent';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { Languages } from 'common/types';
import { saveToStorage } from 'common/utils/frontUtils';

import { updateI18nLanguage } from './i18n';

export const onlineLanguages: Languages[] = [
	'fi',
	'en',
	'de',
	'sv',
	'sk',
	'nl',
	'hu',
	'no',
	'fr',
	'es',
	'it',
	'cs',
	'da',
	'lt',
	'et',
	'ru',
	'pl',
	'sl',
];

export const updateMomentLanguage = async (lang: Languages) => {
	const momentLang = getMomentLocaleCode(lang);
	try {
		// en language bundled, no need to import
		if (lang !== 'en') {
			await import(`moment/locale/${momentLang}`);
		}
		moment.locale(momentLang);
	} catch (e) {
		errorHandler.report(e);
	}
};

export const setLanguage = (lang: Languages) => {
	executeWhenConsent(CookieScriptCategories.functionality, () =>
		saveToStorage('localStorage', 'lang', lang),
	);
	updateI18nLanguage(lang);
	updateMomentLanguage(lang);
	document.documentElement.lang = lang;
};

export const getPaymentComponentLocale = (lang: Languages) => {
	const defaultLocale = 'en-US';
	const adyenLocale = adyenComponentLocales.find((locale) => {
		const isoLangCode = locale.split('-')[0];
		return isoLangCode.toLocaleLowerCase() === lang.toLocaleLowerCase();
	});
	return adyenLocale || defaultLocale;
};

const adyenComponentLocales: string[] = [
	'zh-CN',
	'zh-TW',
	'da-DK',
	'nl-NL',
	'en-US',
	'fi-FI',
	'fr-FR',
	'de-DE',
	'it-IT',
	'ja-JP',
	'ko-KR',
	'no-NO',
	'pl-PL',
	'pt-BR',
	'ru-RU',
	'es-ES',
	'sv-SE',
];

export const getInitialOnlineLanguage = () => getInitialLanguage(onlineLanguages);
