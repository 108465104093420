import { ProductSubscription, SubscriptionPeriod } from 'common/modules/subscriptions';
import { Duration, ISOString } from 'common/types';

export type StartTimeSlotType = {
	type: 'startTime';
	duration: StartTimeSlotDuration;
};

export type StartTimeSlotDuration =
	| {
			type: 'booking';
			value: Duration | null;
	  }
	| {
			type: 'subscription';
			value: ProductSubscription;
	  };

// valid_times = times inside opening hours or inside fixed time slots
// valid_with_invalid_today_times = valid times and invalid times for today (to show expired and bookig buffer time slots for current day as disabled)
// all_times = all times for a day within given range, from 0:00 to 23:59
export type TimeSlotVariant = 'valid_times' | 'all_times' | 'valid_with_invalid_today_times';

type EndTimeSlotType = { type: 'endTime'; startTime: ISOString };

export interface AvailabilityByTime {
	// Format YYYY-MM-DD
	[date: string]: {
		// Format hh-mm
		[time: string]: AvailabilityWithReason;
	};
}

export type ValidSlotsByTime = { [date: string]: { [time: string]: ValidityWithReason } };

export type InvalidStartTimeReason =
	| 'expired'
	| 'availability'
	| 'opening_hours'
	| 'order_limit'
	| 'booking_buffer';

export type ValidityWithReason =
	| { valid: true; ignoreOpeningHours?: boolean }
	| { valid: false; reason: InvalidStartTimeReason };

export type AvailabilityWithReason =
	| { units: number; valid: true }
	| { units: number; valid: false; reason: InvalidStartTimeReason };

export type TimeSlotType = StartTimeSlotType | EndTimeSlotType;

export type TimeSlotDuration =
	| {
			type: 'booking';
			duration: Duration;
	  }
	| {
			type: 'subscription';
			period: SubscriptionPeriod;
			cycles: number;
	  };

export const isStartTimeSlotType = (type: TimeSlotType): type is StartTimeSlotType => {
	return (type as StartTimeSlotType).type === 'startTime';
};

export const isTimeSlotWithAvailability = (
	slot: AvailabilityWithReason | ValidityWithReason,
): slot is AvailabilityWithReason => {
	return (slot as AvailabilityWithReason).units != null;
};
