import Bowser from 'bowser';

/* https://stackoverflow.com/a/326076 */
export const isRunInIframe = () => {
	if (!hasWindow()) return false;
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
};

export const removeHttpProtocol = (url: string): string => {
	return url.replace(/^(https?:|)\/\//, '');
};

export const getIframeParentOrigin = (): string | undefined => {
	if (!isRunInIframe()) return undefined;
	try {
		const documentReferrer = document.referrer;
		const ancestorOrigin = document.location.ancestorOrigins[0];
		return documentReferrer || ancestorOrigin || undefined;
	} catch (e) {
		return undefined;
	}
};

export const runningJestTests = () => process.env.JEST_WORKER_ID !== undefined;

/* Iframed firestore has IndexedDB related cross origin security issues in mac & ios device browsers
https://github.com/firebase/firebase-js-sdk/issues/2393 */
export const browserSupportsIndexedDB = () => {
	const browser =
		typeof window !== 'undefined' && !!window?.navigator?.userAgent
			? Bowser?.getParser(window.navigator.userAgent)
			: undefined;
	const isSafari = browser?.getBrowserName() === 'Safari';
	const isIOS = browser?.getOS().name === 'iOS';
	const hasStorage = typeof Storage !== 'undefined';
	return !(isRunInIframe() && (isIOS || isSafari)) && hasStorage;
};

/**
 *
 * @param url url to append query string to
 * @param queryParameters object with key-value pairs for the query parameters
 */
export const addQueryParameter = (url: string, queryParameters: object) => {
	const queryStringArray: string[] = [];
	for (const [key, value] of Object.entries(queryParameters)) {
		url = removeQueryParameter(url, key);
		queryStringArray.push(`${key}=${value}`);
	}
	const firstSeperator = url.includes('?') ? '&' : '?';
	const queryString = queryStringArray.join('&');
	return url + firstSeperator + queryString;
};

export const removeQueryParameter = (url: string, paramToRemove: string) => {
	const urlParts = url.split('?');
	if (urlParts.length >= 2) {
		var paramPartToRemove = encodeURIComponent(paramToRemove) + '=';
		var parameters = urlParts[1].split(/[&;]/g).filter((param) => {
			return !param.includes(paramPartToRemove);
		});
		return urlParts[0] + (parameters.length > 0 ? '?' + parameters.join('&') : '');
	}
	return url;
};

export const stringQueryParam = (queryString: string | string[] | undefined | null) => {
	return !!queryString && typeof queryString === 'string' ? queryString : undefined;
};

// https://stackoverflow.com/a/442474
export const getElementOffset = (element: HTMLElement | Element) => {
	let _x = 0;
	let _y = 0;
	let el: HTMLElement | Element | null = element;
	while (el && !isNaN((el as HTMLElement).offsetLeft) && !isNaN((el as HTMLElement).offsetTop)) {
		_x += (el as HTMLElement).offsetLeft - el.scrollLeft;
		_y += (el as HTMLElement).offsetTop - el.scrollTop;
		el = (el as HTMLElement).offsetParent ?? null;
	}
	return { top: _y, left: _x };
};

export const isLocalhost = () => {
	if (!hasWindow()) return false;
	return window?.location?.hostname === 'localhost' || window?.location?.hostname === '127.0.0.1';
};

export const isBotUserAgent = (userAgent: string) => {
	var botPattern = /bot|crawler|spider|crawling/i;
	var lowerCaseUserAgent = userAgent.toLowerCase();
	return botPattern.test(lowerCaseUserAgent);
};

export const isScrollingStopped = () => {
	if (!hasWindow()) return true;
	let position: null | number = null;
	return new Promise<void>((resolve) => {
		const checkIfScrollIsStatic = setInterval(() => {
			if (position === window.scrollY) {
				clearInterval(checkIfScrollIsStatic);
				resolve();
			}
			position = window.scrollY;
		}, 25);
	});
};

export const hasWindow = () => typeof window !== 'undefined';

export const getIsMobileSafari = () => {
	return !!(
		navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)
	)?.length;
};
