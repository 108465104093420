import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';

export const StripeElementWrapper: React.FC<{}> = ({ children }) => {
	const stripeAccountIdData = useSelector(ShopSelectors.shopStripeAccountId);
	const stripeAccountId = stripeAccountIdData.data;
	const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

	const stripePromise =
		!stripeAccountId || !stripePublicKey
			? null
			: loadStripe(stripePublicKey, { stripeAccount: stripeAccountId });
	return <Elements stripe={stripePromise}>{children}</Elements>;
};
