import React, { useEffect, useRef } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { AboutPage } from 'common/modules/customization';
import { getTranslation } from 'common/modules/translations';
import { Languages } from 'common/types';

import AnchorMe from '../AnchorMe';

export interface Props {
	customContent: AboutPage['customContent'];
	sectionId?: string | null;
	setSectionId?: (id: string | null) => void;
	language: Languages;
}
const CustomContentContainer = (props: Props) => {
	const { customContent, sectionId, setSectionId, language } = props;

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!!ref.current) {
			ref.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
		}
	}, [sectionId]);
	return (
		<Stack spacing={4} mt={3} mb={8}>
			{customContent?.sections?.map((section, i) => {
				const isActiveId = section.id === sectionId;
				const isEmptySection = section.content?.length === 0;

				return (
					<Stack
						ref={isActiveId ? ref : null}
						key={i}
						direction={{ xs: 'column', md: 'row' }}
						spacing={4}
						sx={{
							border: isActiveId ? '1px solid #0047FF' : undefined,
							p: isActiveId ? 2 : 0,
							minHeight: isEmptySection ? '200px' : undefined,
							height: '100%',
						}}
						onClick={() => setSectionId?.(section.id)}
					>
						{section?.content?.map((content, j) => {
							return !!content && content.type === 'image' ? (
								<Stack key={content.id} sx={{ width: '100%' }} spacing={2}>
									{!!content?.src ? (
										<img
											style={{ width: '100%', maxHeight: '100%', objectFit: 'contain' }}
											src={content.src}
											alt="Custom content"
										/>
									) : (
										<Box sx={{ width: '100%', minHeight: '200px' }}></Box>
									)}
									<Typography variant="body1" sx={{ fontWeight: 500 }}>
										{getTranslation(content?.heading, language)}
									</Typography>
									<Typography>
										<AnchorMe>{getTranslation(content?.description, language)}</AnchorMe>
									</Typography>
								</Stack>
							) : !!content && content.type === 'text' ? (
								<Stack spacing={2} key={j} sx={{ width: '100%' }}>
									<Typography variant="h6">{getTranslation(content?.heading, language)}</Typography>
									<Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
										<AnchorMe>{getTranslation(content?.body, language)}</AnchorMe>
									</Typography>
								</Stack>
							) : null;
						})}
					</Stack>
				);
			})}
		</Stack>
	);
};

export default CustomContentContainer;
