import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
	src: string;
	alt: string;
	magnifier?: boolean;
}
const Image = (props: Props) => {
	return props.magnifier ? <ImageWithMagnifier {...props} /> : <StaticImage {...props} />;
};

const StaticImage = (props: Props) => {
	const classes = useStyles(props);
	const { src, alt } = props;
	return <img src={src} alt={alt} className={classes.image} />;
};

const ImageWithMagnifier = (props: Props) => {
	const classes = useStyles(props);
	const { src, alt } = props;
	return (
		<div className={classes.wrapper}>
			<img src={src} alt={alt} className={classes.image} />
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			position: 'relative',
		},
		image: {
			maxWidth: '100%',
			maxHeight: '100vw',
			objectFit: 'contain',
			[theme.breakpoints.up('md')]: {
				maxHeight: '50vw',
			},
		},
	}),
);

export default Image;
