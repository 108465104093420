import React, { useCallback } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { groupBy, partition } from 'lodash';
import moment from 'moment-timezone';
import * as CartActions from 'redux/actions/cart';
import { useDispatch, useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';

import { isRentalPurchaseType, isSubscriptionPurchaseType } from 'common/modules/orders';
import {
	getSubscriptionDurationString,
	isAutoRenewSubscriptionOrderProduct,
} from 'common/modules/subscriptions';
import { CartProduct, PurchaseType } from 'common/types';
import { getDurationString } from 'common/utils/dateCalculations';
import {
	isKeycardProduct,
	isLiftTicketProduct,
	productHasNewKeycard,
} from 'common/utils/liftTicketUtils';
import { useAnalytics } from 'hooks/useAnalytics';
import useShopFormat from 'hooks/useShopFormat';
import { useTranslation } from 'services/localization/useTranslation';
import { ProductWithQuantity } from 'services/types';
import { isLiftTicketStartDateExpired, isStartDateExpired } from 'utils/cart';

import CartProductsPackageTicketRowItem from './CartProductsPackageTicketRowItem';
import CartProductsRowItem from './CartProductsRowItem';

interface Props {
	products: ProductWithQuantity<CartProduct>[];
	purchaseType: PurchaseType;
	readOnly?: boolean;
	innerPadding?: number;
	hideStartDateExpired?: boolean;
	hideHeader?: boolean;
}

const CartProductsRow = (props: Props) => {
	const classes = useStyles(props);
	const { logEcomEvent } = useAnalytics();
	const dispatch = useDispatch();
	const { localFormat } = useShopFormat();
	const { language, t } = useTranslation();

	const { products, readOnly, hideStartDateExpired, purchaseType, hideHeader } = props;

	const keycardCartProducts = useSelector(CartSelectors.keycardCartProducts);

	const logDecreaseEvent = useCallback(
		(product: CartProduct) => {
			logEcomEvent('remove_from_cart', {
				cartProducts: [product],
			});
		},
		[logEcomEvent],
	);

	const logIncreaseEvent = useCallback(
		(product: CartProduct) => {
			logEcomEvent('add_to_cart', {
				cartProducts: [product],
			});
		},
		[logEcomEvent],
	);

	const handleIncrease = useCallback(
		(product: CartProduct) => {
			if (productHasNewKeycard(product) && keycardCartProducts.length) {
				dispatch(CartActions.increaseItemQuantity(keycardCartProducts[0]));
				logIncreaseEvent(keycardCartProducts[0]);
			}
			dispatch(CartActions.increaseItemQuantity(product));
			logIncreaseEvent(product);
			dispatch(CartActions.resetReservationCreated());
		},
		[dispatch, keycardCartProducts, logIncreaseEvent],
	);

	const handleDecrease = useCallback(
		(product: CartProduct) => {
			if (productHasNewKeycard(product) && keycardCartProducts.length) {
				dispatch(CartActions.decreaseItemQuantity(keycardCartProducts[0]));
				logDecreaseEvent(keycardCartProducts[0]);
			}
			dispatch(CartActions.decreaseItemQuantity(product));
			logDecreaseEvent(product);
			dispatch(CartActions.resetReservationCreated());
		},
		[dispatch, keycardCartProducts, logDecreaseEvent],
	);

	const renderDates = (product: CartProduct) => {
		const { startDate, endDate, rentalDurationInSeconds } = product;

		if (isLiftTicketProduct(product)) {
			return (
				<Typography variant="body2" className={classes.dateInterval}>
					{localFormat(moment(startDate), 'ddd. MMM D')}
				</Typography>
			);
		}
		if (!rentalDurationInSeconds || isAutoRenewSubscriptionOrderProduct(product)) {
			return (
				<Typography variant="body2" className={classes.dateInterval}>
					{localFormat(moment(startDate), 'ddd. MMM D, HH:mm')}
				</Typography>
			);
		}
		const isSameDate = moment(startDate).isSame(moment(endDate), 'date');
		if (isSameDate) {
			return (
				<Typography variant="body2" className={classes.dateInterval}>{`${localFormat(
					moment(startDate),
					'ddd. MMM D, HH:mm',
				)} - ${localFormat(moment(endDate), 'HH:mm')}`}</Typography>
			);
		}
		return (
			<Typography whiteSpace="break-spaces" flex={1}>
				<Typography variant="body2" noWrap component="span" className={classes.dateInterval}>
					{`${localFormat(moment(startDate), 'ddd. MMM D, HH:mm')} -`}
				</Typography>{' '}
				<Typography variant="body2" noWrap component="span" className={classes.dateInterval}>
					{localFormat(moment(endDate), 'ddd. MMM D, HH:mm')}
				</Typography>
			</Typography>
		);
	};

	const renderDuration = (product: CartProduct) => {
		const { rentalDurationInSeconds, durationName, durationType } = product;

		const durationString = (() => {
			if (!!product.subscription) {
				return getSubscriptionDurationString(product.subscription, t);
			}
			return getDurationString(
				{ durationInSeconds: rentalDurationInSeconds, durationName, durationType },
				'long',
				language,
				t,
			);
		})();

		return !isLiftTicketProduct(product) ? (
			<Typography
				variant="body2"
				align="right"
				className={classes.dateInterval}
				noWrap
				flexShrink={0}
			>
				{durationString}
			</Typography>
		) : null;
	};

	const renderProducts = (products: ProductWithQuantity<CartProduct>[]) => {
		const [liftTicketPackageProducts, otherProducts] = partition(
			products,
			(p) => !!p.product.packageId,
		);
		const liftTicketPackageProductsByPackage = groupBy(
			liftTicketPackageProducts,
			'product.packageId',
		);

		return [
			...Object.entries(liftTicketPackageProductsByPackage).map(([packageId, products]) => {
				const product = products[0]?.product;
				if (!product) return null;
				return (
					<CartProductsPackageTicketRowItem
						key={packageId}
						products={products.filter((p) => !isKeycardProduct(p.product))}
						startDateExpired={
							hideStartDateExpired ? false : isLiftTicketStartDateExpired(product.startDate)
						}
						readOnly={readOnly}
					/>
				);
			}),
			...otherProducts.map(({ product, quantity, maxAvailable }, index) => {
				if (!product) return null;
				const startDateExpired = hideStartDateExpired
					? false
					: isLiftTicketProduct(product)
					? isLiftTicketStartDateExpired(product.startDate)
					: isStartDateExpired(product.startDate);

				return (
					<CartProductsRowItem
						key={index}
						product={product}
						quantity={quantity}
						maxAvailable={maxAvailable}
						onQuantityIncrease={handleIncrease}
						onQuantityDecrease={handleDecrease}
						isReadOnly={readOnly}
						isStartDateExpired={startDateExpired}
					/>
				);
			}),
		];
	};

	const product = products[0]?.product;

	if (!product) return null;

	return (
		<Box className={classes.wrapper}>
			{!hideHeader && (
				<Stack
					className={classes.dates}
					direction="row"
					spacing={2}
					justifyContent="space-between"
					alignItems="center"
				>
					{isRentalPurchaseType(purchaseType) || isSubscriptionPurchaseType(purchaseType) ? (
						<>
							{renderDates(product)}
							{renderDuration(product)}
						</>
					) : (
						<Typography variant="body2">{t('common:actions.buy', 'Buy')}</Typography>
					)}
				</Stack>
			)}
			{renderProducts(products)}
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			padding: ({ innerPadding }: Props) => (innerPadding ? theme.spacing(0, innerPadding) : 0),
		},
		dates: {
			background: '#f3f3f3',
			padding: ({ innerPadding }: Props) =>
				innerPadding ? theme.spacing(1, innerPadding) : theme.spacing(1),
			margin: ({ innerPadding }: Props) => (innerPadding ? theme.spacing(0, -innerPadding) : 0),
		},
		dateInterval: {
			fontSize: '1.3rem',
		},
	}),
);

export default CartProductsRow;
