import { createBrowserHistory } from 'history';
import { store } from 'redux/store';

import { sendEventToParentPage } from 'common/modules/embed';
import * as Analytics from 'common/services/analytics';
import { isRunInIframe } from 'common/utils/browserUtils';

const history = createBrowserHistory();

const logPageView = (url: string) => {
	const shopGoogleAnalytics = store.getState().shop?.publicInfo?.data?.plugins?.googleAnalytics;
	Analytics.pageView(url, 'ONLINE', shopGoogleAnalytics);
};

history.listen((location, action) => {
	logPageView(location.pathname);
	if (isRunInIframe() && action === 'PUSH') {
		sendEventToParentPage({
			origin: 'rentle',
			type: 'location-change',
			payload: {
				pathname: location.pathname + location.search,
			},
		});
	}
	// Use setTimeout to make sure this runs after React Router's own listener
	setTimeout(() => {
		window.scrollTo(0, 0);
	});
});

export default history;
