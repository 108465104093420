import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { onlineLocales as onlineLocales_EN } from 'common/locales/en/index';
import { COMMON_NS } from 'common/locales/utils';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { runningJestTests } from 'common/utils/browserUtils';
import { isDevEnv } from 'common/utils/common';

const DEFAULT_NS = 'online';

i18n
	.use(initReactI18next)
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		lng: 'en',
		fallbackLng: 'en',
		debug: isDevEnv() && !runningJestTests() ? true : false,
		load: 'all',
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		ns: [DEFAULT_NS, COMMON_NS],
		defaultNS: DEFAULT_NS,
		resources: { en: onlineLocales_EN }, // We lazy load all other languages when needed
		returnEmptyString: false,
	});

export const updateI18nLanguage = async (lang: string) => {
	if (i18n.hasResourceBundle(lang, DEFAULT_NS)) {
		i18n.changeLanguage(lang);
		return;
	}
	try {
		const { onlineLocales } = await import(`common/locales/${lang}/index.ts`);
		if (onlineLocales) {
			for (const ns of Object.keys(onlineLocales)) {
				i18n.addResourceBundle(lang, ns, onlineLocales[ns]);
			}
			i18n.changeLanguage(lang);
		}
	} catch (e) {
		errorHandler.report(e);
	}
};

export default i18n;

export const t = i18n.t.bind(i18n);
