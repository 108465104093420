import React from 'react';

import { TFunction } from 'i18next';

import { CategoryWithTerms } from 'common/types';

interface Props {
	categoriesWithTerms: CategoryWithTerms[];
	privacyLink?: string;
	t: TFunction;
	termsClassName: string;
	showCancellationPolicyText?: boolean;
}

const TermsLinkElements = ({
	categoriesWithTerms,
	privacyLink,
	t,
	termsClassName,
	showCancellationPolicyText,
}: Props) => {
	const multipleTerms = categoriesWithTerms.length > 1;
	const termsLinks = categoriesWithTerms.map((category, index) => {
		return (
			<React.Fragment key={category.id}>
				<a
					className={termsClassName}
					// tslint:disable-next-line
					href={category.terms!.url || ''}
					target="_blank"
					rel="noopener noreferrer"
				>
					{multipleTerms ? t('common:terms.terms') : t('common:terms.termsOfService')}
					{multipleTerms ? ` (${category.name.toLowerCase()})` : ''}
				</a>
				{index !== categoriesWithTerms.length - 1 && <span>, </span>}
			</React.Fragment>
		);
	});
	return (
		<span>
			{termsLinks}
			{!!privacyLink && (
				<>
					<span>
						{showCancellationPolicyText ? ', ' : ` ${t('common:conjunctions.and', 'and')} `}
					</span>
					<a
						className={termsClassName}
						// tslint:disable-next-line
						href={privacyLink ? privacyLink : ''}
						target="_blank"
						rel="noopener noreferrer"
					>
						{t('common:terms.privacyPolicy_inflected', 'Privacy Policy')}
					</a>
				</>
			)}
			{showCancellationPolicyText && (
				<span>
					{' '}
					{t('common:conjunctions.and', 'and')}{' '}
					{t('common:cancellation.cancellationPolicy', 'Cancellation Policy')}
				</span>
			)}
		</span>
	);
};

export default TermsLinkElements;
