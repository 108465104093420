import { has } from 'lodash';
import { setActiveShopperIndex } from 'redux/actions/view';
import { store } from 'redux/store';

import { OrderProduct, SetProduct, Shopper, UserDetail } from 'common/types';
import {
	productsHasPhotoRequired,
	productsIncludeExistingSkipass,
} from 'common/utils/liftTicketUtils';
import { Routes } from 'services/routing/constants';
import history from 'services/routing/history';
import { pushRoute } from 'services/routing/utils';

import {
	areAllProductsSelected,
	getShopperProducts,
	getShopperProductsNeedDetails,
} from './utils/productUtils';

interface ProductsContinueProps {
	shopName: string;
	locationName?: string | null;
	products: OrderProduct[];
	shoppers: Shopper[];
	activeShopperIndex: number;
}

interface ProductsBackProps {
	shopName: string;
	locationName?: string | null;
	activeShopperIndex: number;
	products: OrderProduct[];
	shoppers: Shopper[];
}

interface MeasurementsContinueProps {
	shopName: string;
	locationName?: string | null;
	activeShopperIndex: number;
	shoppers: Shopper[];
	products: OrderProduct[];
}

interface PhotoContinueProps {
	shopName: string;
	locationName?: string | null;
	shoppers: Shopper[];
	products: OrderProduct[];
}

interface PhotoBackProps {
	shopName: string;
	locationName?: string | null;
	activeShopperIndex: number;
	products: OrderProduct[];
	shoppers: Shopper[];
}

const nextShopperHasNoName = (shopperIndex: number, shoppers: Shopper[]) => {
	return shoppers[shopperIndex].firstName === '';
};

const assignShopperIdToSetProduct = (setProducts: SetProduct[], shopperId: string) =>
	setProducts.map((setProduct) => ({ ...setProduct, shopperId }));

const goToNextShopper = (
	index: number,
	shopName: string,
	shoppers: Shopper[],
	locationName?: string | null,
) => {
	store.dispatch(setActiveShopperIndex(index));
	return nextShopperHasNoName(index, shoppers)
		? pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsInfo)
		: pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsProducts);
};

const nextPersonIndexWithoutDetails = (shoppers: Shopper[], products: OrderProduct[]) => {
	const shopperProductsHaveNeededDetails = (shopper: Shopper) => {
		const { productIds } = shopper;
		const shopperProducts = products.filter((p) => productIds.includes(p.id));

		const productsThatNeedDetails = shopperProducts
			.map((p) => (p.set ? assignShopperIdToSetProduct(p.setProducts, p.shopperId) : p))
			.flat()
			.filter((p) => !!p.userDetails);

		return (
			productIds.length > 0 &&
			productsThatNeedDetails.every((product) =>
				(product.userDetails ?? [])
					.filter((detail: UserDetail) => detail.required)
					.every((detail: UserDetail) => has(shopper.userProperties, detail.name)),
			)
		);
	};

	return shoppers.map(shopperProductsHaveNeededDetails).findIndex((s) => s === false);
};

export const productsContinue = (props: ProductsContinueProps) => {
	const { shopName, locationName, products, shoppers, activeShopperIndex } = props;
	const activeShopper = shoppers[activeShopperIndex];

	const allProductsSelected = areAllProductsSelected(products, shoppers, activeShopper);

	const shopperProducts = getShopperProducts(products, activeShopper);
	const shopperProductsNeedDetails = getShopperProductsNeedDetails(shopperProducts);
	const shopperHasExistingKeyCard = productsIncludeExistingSkipass(shopperProducts);
	const shopperHasPhotoRequired = productsHasPhotoRequired(shopperProducts);

	const nextShopperIndex = activeShopperIndex + 1;
	const noNextShopper = nextShopperIndex > shoppers.length - 1;

	return shopperProductsNeedDetails || shopperHasExistingKeyCard
		? pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsDetails)
		: shopperHasPhotoRequired
		? pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsPhotoUpload)
		: allProductsSelected || noNextShopper
		? pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsSummary)
		: goToNextShopper(nextShopperIndex, shopName, shoppers, locationName);
};

export const productsBack = (props: ProductsBackProps) => {
	const { shopName, locationName, activeShopperIndex, shoppers, products } = props;
	const previousShopperIndex = activeShopperIndex - 1;
	const previousShopper = shoppers[previousShopperIndex];

	const shopperProductsNeedDetails = getShopperProductsNeedDetails(
		getShopperProducts(products, previousShopper),
	);

	if (previousShopperIndex >= 0) {
		store.dispatch(setActiveShopperIndex(previousShopperIndex));
		return shopperProductsNeedDetails
			? pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsDetails)
			: pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsProducts);
	}
};

export const measurementsContinue = (props: MeasurementsContinueProps) => {
	const { shopName, locationName, shoppers, activeShopperIndex, products } = props;
	const activeShopper = shoppers[activeShopperIndex];
	const shopperProducts = getShopperProducts(products, activeShopper);
	const shopperHasPhotoRequired = productsHasPhotoRequired(shopperProducts);

	const nextShopperIndex = nextPersonIndexWithoutDetails(shoppers, products);

	return shopperHasPhotoRequired
		? pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsPhotoUpload)
		: nextShopperIndex < 0
		? pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsSummary)
		: goToNextShopper(nextShopperIndex, shopName, shoppers, locationName);
};

export const measurementsBack = (shopName: string, locationName?: string | null) => {
	pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsProducts);
};

export const photoContinue = (props: PhotoContinueProps) => {
	const { shopName, shoppers, products, locationName } = props;
	const nextShopperIndex = nextPersonIndexWithoutDetails(shoppers, products);

	return nextShopperIndex < 0
		? pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsSummary)
		: goToNextShopper(nextShopperIndex, shopName, shoppers, locationName);
};

export const photoBack = (props: PhotoBackProps) => {
	const { shopName, shoppers, products, activeShopperIndex, locationName } = props;
	const activeShopper = shoppers[activeShopperIndex];
	const shopperProducts = getShopperProducts(products, activeShopper);
	const shopperProductsNeedDetails = getShopperProductsNeedDetails(shopperProducts);
	const shopperHasExistingKeyCard = productsIncludeExistingSkipass(shopperProducts);

	return shopperHasExistingKeyCard || shopperProductsNeedDetails
		? pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsDetails)
		: pushRoute(history)({ shopName, locationName })(Routes.confirmDetailsProducts);
};
