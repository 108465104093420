import React from 'react';

import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';

import EmptyState from 'common/components/states/EmptyState';
import { getShopLocationsWithContact } from 'common/utils/shopUtils';
import CenteredContent from 'components/CenteredContent';
import { useTranslation } from 'services/localization';

import LocationsGrid from './Locations/LocationsGrid';

interface Props {
	title?: string;
	description?: string;
	locationId: string;
}

const HiddenLocation = ({ title, description, locationId }: Props) => {
	const { t } = useTranslation();
	const shopPublicInfoData = useSelector(ShopSelectors.shopPublicInfoData);
	if (!shopPublicInfoData) return null;

	const shopLocations = getShopLocationsWithContact(shopPublicInfoData, 'ONLINE');

	const shopLocation = shopLocations.find((l) => l.id === locationId);

	const locationString = shopLocation
		? t('Shop.locationOnlineStore', {
				defaultValue: '{{locationName}} online store',
				locationName: shopLocation.name ?? '',
		  })
		: t('common:shopLocation.location');

	return (
		<Box pt={4}>
			<EmptyState
				title={
					title ??
					t('Shop.locationClosed', {
						defaultValue: '{{locationString}} is currently closed.',
						locationString: locationString ?? '',
					})
				}
				description={description ?? ''}
				cta={
					shopLocations.length > 1 ? (
						<Box width="100%" textAlign="left" mt={4}>
							<CenteredContent>
								<Box mb={2}>
									<Typography variant="body1" align="left">
										{t('Shop.visitOtherOnlineStores', 'Visit our other online stores')}:
									</Typography>
								</Box>
								<LocationsGrid />
							</CenteredContent>
						</Box>
					) : undefined
				}
			/>
		</Box>
	);
};

export default HiddenLocation;
