import React from 'react';

import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import * as ConfirmSelectors from 'redux/selectors/confirm';

import { getCustomCheckoutContentValue } from 'common/modules/customization';
import { useTranslation } from 'services/localization';

const CustomCheckoutFields = () => {
	const { t, getTranslation } = useTranslation();

	const customCheckoutFields = useSelector(ConfirmSelectors.customCheckoutFields);

	return !!customCheckoutFields?.length ? (
		<Stack spacing={2} mb={2}>
			{customCheckoutFields.map(
				(field, index) =>
					!!field.value && (
						<Stack key={index} spacing={0.5}>
							<Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
								{getTranslation(!!field.label ? field.label : { def: '' })}
							</Typography>
							<Typography variant="subtitle2">
								{getCustomCheckoutContentValue(field.value, t)}
							</Typography>
						</Stack>
					),
			)}
		</Stack>
	) : null;
};

export default CustomCheckoutFields;
