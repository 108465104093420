import { POST_MESSAGE_PROVIDER } from 'hooks/useExternalAuth/constants';
import { PostMessageAuthEvent, PostMessageAuthEventByName } from 'hooks/useExternalAuth/types';

export const createPostMessage = <T extends PostMessageAuthEvent['event']>({
	event,
	value,
	state,
}: {
	event: T;
	value: PostMessageAuthEventByName<T>['value'];
	state?: string;
}): PostMessageAuthEvent => {
	return {
		event,
		value,
		provider: POST_MESSAGE_PROVIDER,
		state,
	} as PostMessageAuthEvent;
};

export const isLogOutPath = (pathname: string) => {
	const pathList = pathname.split('/');
	const lastPathString = pathList[pathList.length - 1];
	return lastPathString?.includes('logout');
};
