import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { resetCart } from 'redux/actions/cart';
import { fetchOrder } from 'redux/actions/confirm';
import { setDeliveryOption } from 'redux/actions/view';
import * as ConfirmSelectors from 'redux/selectors/confirm';
import * as ShopSelectors from 'redux/selectors/shop';

import Spinner from 'common/components/Spinner';
import { getQueryParam } from 'common/utils/frontUtils';
import OrderFetchError from 'components/OrderFetchError';
import { useRoutes } from 'services/routing/useRoutes';

import Completed from './Completed';
import ShopperDetails from './ShopperDetails';

export const Confirm = () => {
	const dispatch = useDispatch();
	const { getPath, getRoute, Routes } = useRoutes();
	const queryOrderId = getQueryParam('orderId');
	const [initOrderLoading, setInitOrderLoading] = useState(Boolean(queryOrderId));
	const shopInfo = useSelector(ShopSelectors.shopPublicInfoData);
	const order = useSelector(ConfirmSelectors.order);
	const orderError = useSelector(ConfirmSelectors.orderError);
	const orderLoading = useSelector(ConfirmSelectors.orderLoading);
	const defaultDeliveryOption = useSelector(ShopSelectors.defaultDeliveryOption);
	const productsCount = order?.products?.length ?? 0;

	const isMissingDetails = useSelector(ConfirmSelectors.isMissingDetails);

	useEffect(() => {
		dispatch(resetCart());
		dispatch(setDeliveryOption(defaultDeliveryOption));
	}, [defaultDeliveryOption, dispatch]);

	useEffect(() => {
		const orderAlreadyFetched = () => {
			const currentOrderInfo = order?.rentalInfo;
			const shopId = shopInfo?.shopId;
			if (queryOrderId === currentOrderInfo?.id && shopId && shopId === currentOrderInfo?.shopId) {
				return true;
			}
			return false;
		};

		if (queryOrderId && initOrderLoading && shopInfo?.shopId) {
			setInitOrderLoading(false);
			if (!orderAlreadyFetched()) {
				dispatch(fetchOrder({ orderId: queryOrderId, shopId: shopInfo.shopId }));
			}
		}
	}, [orderLoading, queryOrderId, dispatch, initOrderLoading, order?.rentalInfo, shopInfo?.shopId]);

	if (orderLoading) return <Spinner color="#000" />;
	if (orderError || !order) return <OrderFetchError />;
	if (!productsCount && (order || !queryOrderId)) {
		return <Redirect to={getPath(Routes.browse)} />;
	}

	return (
		<Switch>
			<Route
				exact
				path={getRoute(Routes.confirm)}
				component={isMissingDetails ? ShopperDetails : Completed}
			/>
			<Route path={getRoute(Routes.confirmDetails)} component={ShopperDetails} />
			<Redirect to={getPath(Routes.confirm, { query: { orderId: queryOrderId ?? undefined } })} />
		</Switch>
	);
};
