import { useCallback, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'redux/hooks';
import * as NavSelectors from 'redux/selectors/nav';

import { BaseParams, QueryParams, Route, RouteParams, Routes } from './constants';
import * as Utils from './utils';

export const useRoutes = (args?: BaseParams) => {
	const history = useHistory();
	const location = useLocation();

	const reduxShopName = useSelector(NavSelectors.shopName);
	const reduxLocationName = useSelector(NavSelectors.locationName);

	/**
	 * If shopName/locationName are explicitly passed in,
	 * use those over the ones in Redux state
	 */
	const shopName = !!args ? args.shopName : reduxShopName;
	const locationName = !!args ? args.locationName : reduxLocationName;
	const currentPath = location.pathname;
	const queryParams = location.search;

	const withShopPath = !!shopName;
	const withLocation = !!locationName;
	const baseArgs = useMemo(() => ({ shopName, locationName }), [shopName, locationName]);

	const rootRoute = Utils.getRootRoute({ withShopPath, withLocation });
	const rootPath = Utils.getRootPath(baseArgs);

	const getRoute = useCallback(
		(route: Route) => {
			return Utils.getRoute({ withShopPath, withLocation })(route);
		},
		[withShopPath, withLocation],
	);

	const getPath = useCallback(
		(route: Route, args?: { params?: RouteParams; query?: QueryParams }) => {
			return Utils.getPath(baseArgs)(route, args?.params, args?.query);
		},
		[baseArgs],
	);

	const pushRoute = useCallback(
		(route: Route, args?: { params?: RouteParams; query?: QueryParams }) => {
			return Utils.pushRoute(history)(baseArgs)(route, args?.params, args?.query);
		},
		[baseArgs, history],
	);

	const replaceRoute = useCallback(
		(route: Route, args?: { params?: RouteParams; query?: QueryParams }) => {
			return Utils.replaceRoute(history)(baseArgs)(route, args?.params, args?.query);
		},
		[baseArgs, history],
	);

	const isInRoute = useCallback(
		(route: Route | Route[]) => {
			return Utils.pathIncludesRoute(currentPath, route);
		},
		[currentPath],
	);

	return {
		history,
		rootRoute,
		rootPath,
		getRoute,
		getPath,
		pushRoute,
		replaceRoute,
		goBack: history.goBack,
		Routes,
		locationName,
		isInRoute,
		queryParams,
	};
};
