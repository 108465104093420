import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiCheckLine } from 'react-icons/ri';

interface Props {
	header: string;
	disabled?: boolean;
	fullWidthContent?: boolean;
	collapsed?: boolean;
	completed?: boolean;
	hidden?: boolean;
}

type StyleProps = Pick<Props, 'disabled' | 'fullWidthContent'>;

export const CheckoutStep: React.FC<Props> = ({
	header,
	children,
	disabled,
	fullWidthContent = false,
	collapsed = false,
	completed = false,
	hidden = false,
}) => {
	const classes = useStyles({ disabled, fullWidthContent });

	return (
		<Accordion hidden={hidden} square expanded={!collapsed} classes={{ root: classes.container }}>
			<AccordionSummary disabled classes={{ root: classes.header, content: classes.headerContent }}>
				<Typography className={classes.headerLabel}>{header}</Typography>
				{completed && <RiCheckLine color="black" />}
			</AccordionSummary>
			<AccordionDetails classes={{ root: classes.content }}>{children}</AccordionDetails>
		</Accordion>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			boxShadow: 'none',
			pointerEvents: ({ disabled }: StyleProps) => (disabled ? 'none' : 'inherit'),
			margin: theme.spacing(2, 0),
		},
		disabled: {
			backgroundColor: 'none',
			opacity: 1,
			userSelect: 'none',
		},
		header: {
			backgroundColor: theme.palette.background.secondary,
			cursor: 'inherit !important',
			userSelect: 'inherit',
			minHeight: '0',
			height: 50,
			padding: theme.spacing(0, 2),
			opacity: '1 !important',
			borderRadius: 0,
			'&.Mui-expanded': {
				minHeight: '0 !important',
				height: 50,
			},
			[theme.breakpoints.down('md')]: {
				margin: theme.spacing(0, -7),
				padding: theme.spacing(0, 7),
			},
			[theme.breakpoints.down('sm')]: {
				margin: theme.spacing(0, -3),
				padding: theme.spacing(0, 3),
			},
		},
		headerLabel: {
			textTransform: 'uppercase',
			fontWeight: 500,
		},
		headerContent: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			opacity: ({ disabled }: StyleProps) => (disabled ? 0.3 : 1),
			padding: theme.spacing(4, 0),
			[theme.breakpoints.up('md')]: {
				padding: ({ fullWidthContent }: StyleProps) =>
					theme.spacing(4, fullWidthContent ? 0 : 2, 4, fullWidthContent ? 0 : 2),
			},
		},
	}),
);

export default CheckoutStep;
