import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { TFunction } from 'i18next';

import AnchorMe from 'common/components/AnchorMe';
import { OnlineLayoutSettings } from 'common/modules/customization';
import { getTranslation } from 'common/modules/translations';
import { Languages } from 'common/types';

export interface Props {
	about: OnlineLayoutSettings['about'];
	children: JSX.Element | JSX.Element[];
	t: TFunction;
	language: Languages;
}

const AboutContainer = (props: Props) => {
	const { about, t, children, language } = props;

	return (
		<>
			<Box mb={4}>
				<Typography variant="h5">
					{!!about?.heading
						? getTranslation(about.heading, language)
						: t('common:actions.about', 'About')}
				</Typography>
			</Box>
			<Grid container spacing={12}>
				{!!about?.textContent && !about.hidden && (
					<Grid item xs={12} lg={6}>
						<Box>
							<Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
								<AnchorMe>{getTranslation(about.textContent, language)}</AnchorMe>
							</Typography>
						</Box>
					</Grid>
				)}
				<Grid item xs={12} lg={6}>
					{children}
				</Grid>
			</Grid>
		</>
	);
};

export default AboutContainer;
