import { useEffect, useMemo, useState } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import { AvailabilityRange } from 'common/api/frontend/inventory_new/availabilityRange';
import { getBookingBufferForProduct } from 'common/modules/atoms/bookingBuffer';
import { getTimeSlots } from 'common/modules/availabilities/timeSlots';
import { TimeSlotType } from 'common/modules/availabilities/timeSlots/types';
import { isRentalPurchaseType, isSubscriptionPurchaseType } from 'common/modules/orders';
import { DEFAULT_START_TIMES_CONFIG } from 'common/modules/startTimes';
import {
	ByVariant,
	ISOString,
	LoadingData,
	ProductApi,
	PurchaseType,
	PurchaseTypes,
	StartTimeCount,
} from 'common/types';
import { switchUnreachable } from 'common/utils/common';
import { getDurationFromDurationWithPriceOption } from 'common/utils/pricing';
import { ProductForm } from 'hooks/useProductForm';
import { AvailabilityByStartTime } from 'utils/availability';
import { AvailabilityByTimeslot, getDeliveryTimeslotsWithAvailability } from 'utils/delivery';
import { getSelectedVariantStartTimes, getUnloadedStartTimesRange } from 'utils/startTimes';

import {
	calculateCalendarDateAvailabilities,
	getIsStartDateFromCartAvailable,
	getTotalAvailabilityForSelection,
	isSelectedDateAvailable,
} from '../views/Shop/Location/Product/utils';

interface Props {
	product: ProductApi;
	form: ProductForm;
	startTimeCounts: LoadingData<ByVariant<StartTimeCount>>;
	availability: LoadingData<ByVariant<AvailabilityRange[]>>;
	setProductsVariantIds: string[] | null | undefined;
	selectedMonth: ISOString;
	purchaseType: PurchaseType;
}

let setAvailabilityTimeout: NodeJS.Timeout | null = null;

const useStartTimesOrTimeslots = (props: Props) => {
	const {
		startTimeCounts,
		availability,
		product,
		setProductsVariantIds,
		form,
		selectedMonth,
		purchaseType,
	} = props;

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const hasDelivery = useSelector(ViewSelectors.deliverySelected);
	const stockProducts = useSelector(StockSelectors.stockProductsData);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const shopTimezone = useSelector(ShopSelectors.shopTimezone);
	const categories = useSelector(StockSelectors.stockCategoriesData);
	const categoriesById = useSelector(StockSelectors.categoriesById);

	const variantId = form.values.selection?.variantId;
	const { quantity: selectedQuantity, startDate: selectedDate } = form.values;
	const {
		selectedDuration,
		selectedSubscription,
		selectedSubscriptionDuration,
		forcedStartDate,
		forcedStartDateAndTime,
	} = form.meta;

	const [
		availabilityByStartTime,
		setAvailabilityByStartTime,
	] = useState<AvailabilityByStartTime | null>(null);
	const [
		availabilityByTimeslot,
		setAvailabilityByTimeslot,
	] = useState<AvailabilityByTimeslot | null>(null);
	const [loaded, setLoaded] = useState(false);

	const selectionTotalAvailability = useMemo(
		() =>
			getTotalAvailabilityForSelection({
				availability,
				selection: product.set
					? { set: true, variantIds: setProductsVariantIds ?? [] }
					: { set: false, variantId },
			}),
		[availability, product.set, setProductsVariantIds, variantId],
	);

	const selectedVariantStartTimes = useMemo(
		() => !hasDelivery && getSelectedVariantStartTimes(variantId, startTimeCounts),
		[hasDelivery, startTimeCounts, variantId],
	);

	const [lowDates, unavailableDates, disabledDates] = useMemo(() => {
		if (
			!loaded ||
			(!hasDelivery && !availabilityByStartTime) ||
			(hasDelivery && !availabilityByTimeslot)
		)
			return [[], [], []];
		const startTimeOrTimeslotAvailability = !hasDelivery
			? availabilityByStartTime!
			: availabilityByTimeslot!;
		return calculateCalendarDateAvailabilities(startTimeOrTimeslotAvailability, selectedQuantity);
	}, [loaded, availabilityByStartTime, availabilityByTimeslot, hasDelivery, selectedQuantity]);

	const isStartDateFromCartAvailable = useMemo(() => {
		return getIsStartDateFromCartAvailable({
			hasDelivery,
			forcedStartDate,
			forcedStartDateAndTime,
			availabilityByStartTime,
			selectedQuantity,
			availabilityByTimeslot,
		});
	}, [
		availabilityByStartTime,
		availabilityByTimeslot,
		hasDelivery,
		selectedQuantity,
		forcedStartDate,
		forcedStartDateAndTime,
	]);

	const startDateAvailable = useMemo(() => {
		return isSelectedDateAvailable({
			selectedDate,
			hasDelivery,
			availabilityByStartTime,
			availabilityByTimeslot,
			selectedQuantity,
		});
	}, [
		availabilityByStartTime,
		availabilityByTimeslot,
		hasDelivery,
		selectedDate,
		selectedQuantity,
	]);

	const calendarDisabled = useMemo(() => {
		const availabilityByStartTimeOrTimeslot = availabilityByStartTime ?? availabilityByTimeslot;

		switch (purchaseType) {
			case 'sales': {
				return !loaded || !availability || !variantId || (!hasDelivery && !startTimeCounts);
			}
			case 'rental': {
				return (
					!loaded ||
					!availabilityByStartTimeOrTimeslot ||
					isEmpty(availabilityByStartTimeOrTimeslot) ||
					!selectedDuration ||
					!availability ||
					(!hasDelivery && !startTimeCounts)
				);
			}
			case 'subscription': {
				return (
					!loaded ||
					!availabilityByStartTimeOrTimeslot ||
					isEmpty(availabilityByStartTimeOrTimeslot) ||
					!selectedSubscription ||
					!availability ||
					(!hasDelivery && !startTimeCounts)
				);
			}
			default:
				return switchUnreachable(purchaseType);
		}
	}, [
		availability,
		availabilityByStartTime,
		availabilityByTimeslot,
		hasDelivery,
		loaded,
		purchaseType,
		selectedDuration,
		selectedSubscription,
		startTimeCounts,
		variantId,
	]);

	const resetAvailabilityTimeout = () => {
		if (setAvailabilityTimeout) {
			clearTimeout(setAvailabilityTimeout);
			setAvailabilityTimeout = null;
		}
	};

	useEffect(() => {
		const setAvailability = async () => {
			if (!stockProducts || !selectedMonth) {
				resetAvailabilityTimeout();
				return Promise.resolve();
			}
			if (isRentalPurchaseType(purchaseType)) {
				if (!selectedDuration || !selectionTotalAvailability) {
					resetAvailabilityTimeout();
					return Promise.resolve();
				}
			}
			if (isSubscriptionPurchaseType(purchaseType)) {
				if (!selectedSubscription || !selectionTotalAvailability) {
					resetAvailabilityTimeout();
					return Promise.resolve();
				}
			}

			if (hasDelivery) {
				if (!!selectedDeliveryOption && !!selectionTotalAvailability) {
					const duration = isSubscriptionPurchaseType(purchaseType)
						? selectedSubscriptionDuration
						: selectedDuration;
					const _availabilityByTimeslots = getDeliveryTimeslotsWithAvailability({
						product,
						selectedDuration: duration,
						availability: selectionTotalAvailability ?? [],
						month: selectedMonth,
						stockProducts,
						isMobile,
						openingHours,
						selectedDeliveryOption,
						timezone: shopTimezone,
					});
					setAvailabilityByStartTime(null);
					setAvailabilityByTimeslot((prevAvailabilityByTimeslots) => {
						return { ...prevAvailabilityByTimeslots, ..._availabilityByTimeslots };
					});
				}
			} else {
				if (!!selectedVariantStartTimes) {
					const { start, end } = getUnloadedStartTimesRange(selectedMonth, isMobile, null);
					if (!start || !end) {
						return;
					}
					const timeSlotType: TimeSlotType | null = (() => {
						switch (purchaseType) {
							case PurchaseTypes.sales:
								return null;
							case PurchaseTypes.rental: {
								return {
									type: 'startTime',
									duration: {
										type: 'booking',
										value: !selectedDuration?.option
											? null
											: getDurationFromDurationWithPriceOption(selectedDuration.option),
									},
								};
							}
							case PurchaseTypes.subscription: {
								if (!selectedSubscription) return null;
								return {
									type: 'startTime',
									duration: {
										type: 'subscription',
										value: selectedSubscription.option,
									},
								};
							}
							default: {
								return switchUnreachable(purchaseType);
							}
						}
					})();
					if (!!timeSlotType) {
						const availabilityByStartTimes = getTimeSlots({
							product,
							timeSlotType,
							availabilities: selectionTotalAvailability ?? [],
							start: start.toISOString(),
							end: end.toISOString(),
							stockProducts,
							openingHours,
							startTimeCounts: selectedVariantStartTimes,
							useBookingBuffer: getBookingBufferForProduct(product, categories),
							variant: 'valid_with_invalid_today_times',
							alreadySelected: 0,
							startTimes: product.startTimes ?? DEFAULT_START_TIMES_CONFIG,
							timezone: shopTimezone,
						});

						if (!!availabilityByStartTimes) {
							setAvailabilityByTimeslot(null);
							setAvailabilityByStartTime((prevAvailabilityByStartTimes) => {
								return { ...prevAvailabilityByStartTimes, ...availabilityByStartTimes };
							});
						}
					}
				}
			}
			return Promise.resolve();
		};

		resetAvailabilityTimeout();
		setLoaded(false);

		setAvailabilityTimeout = setTimeout(async () => {
			await setAvailability();
			setLoaded(true);
		}, 0);
	}, [
		categories,
		categoriesById,
		hasDelivery,
		isMobile,
		openingHours,
		product,
		selectedDeliveryOption,
		selectedDuration,
		selectedMonth,
		selectedVariantStartTimes,
		selectionTotalAvailability,
		stockProducts,
		purchaseType,
		shopTimezone,
		selectedSubscription,
		selectedSubscriptionDuration,
	]);

	return {
		availabilityByStartTime,
		isStartTimesOrTimeslotsLoaded: loaded,
		availabilityByTimeslot,
		lowDates,
		unavailableDates,
		disabledDates,
		isStartDateFromCartAvailable,
		startDateAvailable,
		calendarDisabled,
	};
};

export default useStartTimesOrTimeslots;
