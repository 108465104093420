import type { History } from 'history';
import { isEmpty } from 'lodash';

import { BaseParams, QueryParams, Route, RouteParams, RoutePaths, Routes } from './constants';

const asQueryParams = (query: QueryParams) => {
	const qp = new URLSearchParams();
	Object.entries(query).forEach(([k, v]) => {
		!!v && qp.set(k, v);
	});
	return qp.toString();
};

const withPath = (fn: (a: string) => void) => (args: BaseParams) => (
	routeKey: Route,
	params?: RouteParams,
	query?: QueryParams,
) => fn(getPath(args)(routeKey, params, query));

export const pushRoute = (history: History) => withPath(history.push);
export const replaceRoute = (history: History) => withPath(history.replace);

const replaceParams = (route: string, params?: RouteParams | BaseParams) => {
	if (!params) {
		return route;
	}

	return route
		.split('/')
		.map((pathPart: string) => {
			const param = pathPart.replace(':', '');
			return params[param] ?? pathPart;
		})
		.join('/');
};

/** Terminology:
 *
 * Route: a route without parameters replaced -> e.g. /:shopName/l/:locationName
 * Path: a route with parameters replaced -> e.g. /ruka/l/rukavalley
 *
 */
export const getRootRoute = (args: { withShopPath: boolean; withLocation: boolean }): string => {
	return args.withLocation && args.withShopPath
		? RoutePaths[Routes.shopPathLocationRoot]
		: args.withLocation
		? RoutePaths[Routes.locationRoot]
		: args.withShopPath
		? RoutePaths[Routes.shopPathRoot]
		: RoutePaths[Routes.baseRoot];
};

const removeEndingSlash = (text: string) => (text.endsWith('/') ? text.slice(0, -1) : text);

export const getRoute = (args: { withShopPath: boolean; withLocation: boolean }) => (
	route: Route,
): string => {
	const rootRoute = getRootRoute(args);
	return `${removeEndingSlash(rootRoute)}${RoutePaths[route]}`;
};

export const getRootPath = (args: BaseParams): string => {
	const route = getRootRoute({ withShopPath: !!args.shopName, withLocation: !!args.locationName });
	return replaceParams(route, args);
};

export const getPath = (args: BaseParams) => (
	routeKey: Route,
	params?: RouteParams,
	query?: QueryParams,
) => {
	const rootPath = getRootPath(args);
	const route = RoutePaths[routeKey];
	const path = replaceParams(route, params);
	const qs = !!query && !isEmpty(query) ? '?' + asQueryParams(query) : '';
	return `${removeEndingSlash(rootPath)}${path}${qs}`;
};

export const pathIncludesRoute = (path: string, route: Route | Route[]) => {
	return Array.isArray(route) ? route.some((r) => path.includes(r)) : path.includes(route);
};
