import React from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'services/localization';

export const NONE_VALUE = '__NONE__';

interface Option {
	value: string;
	label: string;
}

interface Props {
	value: string | null;
	options: Option[];
	onChange: (value: string) => void;
}

const RadioGroupFilter = (props: Props) => {
	const { value, options, onChange } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<RadioGroup value={value ?? NONE_VALUE} onChange={(_, value) => onChange(value)}>
			<FormControlLabel
				className={classes.radioFormLabel}
				key="all"
				value={NONE_VALUE}
				control={
					<Radio
						color="default"
						classes={{
							root: classes.radio,
						}}
					/>
				}
				label={t('common:amounts.all')}
			/>
			{options.map((option) => (
				<FormControlLabel
					className={classes.radioFormLabel}
					key={option.value}
					value={option.value}
					control={
						<Radio
							color="default"
							classes={{
								root: classes.radio,
							}}
						/>
					}
					label={option.label}
				/>
			))}
		</RadioGroup>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		radioFormLabel: {
			position: 'relative',
			padding: theme.spacing(0.5, 0),
			marginRight: 0,
			'&::after': {
				content: '""',
				borderBottom: `1px solid #dedede`,
				marginLeft: 11,
				position: 'absolute',
				bottom: 0,
				right: 0,
				left: 0,
			},
		},
		radioGroup: {
			paddingBottom: 80,
		},
		radio: {
			color: theme.palette.common.black,
		},
	}),
);

export default RadioGroupFilter;
