import { TFunction } from 'i18next';
import moment from 'moment-timezone';

import { BookingBuffer, DateFormatObject } from 'common/types';
import { localFormat } from 'common/utils/dateUtils';

export const getLatestBookingTime = (
	plannedStartDate: moment.Moment,
	bookingBuffer: BookingBuffer,
): moment.Moment => {
	if (bookingBuffer.latestTime === undefined && bookingBuffer.minutesBefore === undefined) {
		return moment(plannedStartDate);
	}
	const days = bookingBuffer.daysBefore;

	if (days > 0 && bookingBuffer.latestTime) {
		const hours = bookingBuffer.latestTime;
		return moment(plannedStartDate)
			.add(-days, 'days')
			.hours(hours)
			.minutes(0)
			.seconds(0)
			.milliseconds(0);
	} else if (days === 0 && bookingBuffer.minutesBefore !== undefined) {
		return moment(plannedStartDate).add(-bookingBuffer.minutesBefore || 0, 'minutes');
	}
	return moment(plannedStartDate);
};

export const getBookingBufferText = (
	bookingBuffer: BookingBuffer,
	t: TFunction,
	shopDateFormat: DateFormatObject,
): string | null => {
	const { daysBefore, minutesBefore, latestTime } = bookingBuffer;
	if (daysBefore === 0 && minutesBefore) {
		const duration = moment.duration(minutesBefore, 'minutes');
		const hours = duration.hours()
			? `${duration.hours()} ${
					duration.hours() > 1 ? t('common:times.hours') : t('common:times.hour')
			  } `
			: '';
		const minutes = duration.minutes()
			? `${duration.minutes()} ${t('common:times.minutes', 'minutes')}`
			: '';
		return t('common:times.timeBefore', {
			defaultValue: '{{time}} before',
			time: `${hours}${minutes}`,
		});
	}
	if (daysBefore > 0) {
		const days = `${daysBefore} ${daysBefore > 1 ? t('common:times.days') : t('common:times.day')}`;
		const beforeBy = `${
			latestTime &&
			` ${t('common:times.beforeBy', {
				defaultValue: 'before by {{time}}',
				time: localFormat(moment(latestTime, 'HH:00'), 'HH:mm', shopDateFormat),
			})}`
		}`;
		return `${days}${beforeBy}`;
	}
	return null;
};

export const getHandlingTimeText = (handlingTimeMinutes: number, t: TFunction) => {
	const { days, hours, minutes } = getHandlingTimeDetailsFromMinutes(handlingTimeMinutes);
	const daysText = days
		? `${days} ${days > 1 ? t('common:times.days') : t('common:times.day')} `
		: '';
	const hoursText = hours
		? `${hours} ${hours > 1 ? t('common:times.hours') : t('common:times.hour')} `
		: '';
	const minutesText = minutes ? `${minutes} ${t('common:times.minutes', 'minutes')}` : '';
	return `${daysText}${hoursText}${minutesText}`;
};

export const getHandlingTimeDetailsFromMinutes = (
	value: number,
): { days: number; hours: number; minutes: number } => {
	const duration = moment.duration(value, 'minutes');
	const hours = duration.hours();
	const minutes = duration.minutes();
	const days = (value - hours * 60 - minutes) / 1440;
	return {
		days,
		hours,
		minutes,
	};
};

export const getDaysAndMinutesFromSeconds = (value: number): { days: number; hours: number } => {
	const duration = moment.duration(value, 'seconds');
	const hours = duration.hours();
	const days = (value - hours * 3600) / 86400;
	return {
		days,
		hours,
	};
};
