import React from 'react';

import Banner from 'common/components/states/Banner';
import { useTranslation } from 'services/localization';

interface ProductUnavailableProps {
	reason?: 'generic' | 'no-delivery-support';
}

const ProductUnavailable = (props: ProductUnavailableProps) => {
	const { t } = useTranslation();
	const { reason } = props;

	const getProps = () => {
		switch (reason) {
			case 'no-delivery-support': {
				return {
					title: t(
						'AmountSelector.notAvailableForDelivery',
						'This product is currently not available for delivery',
					),
				};
			}
			case 'generic':
			default: {
				return {
					title: t('AmountSelector.notInStock', 'This product is currently unavailable'),
				};
			}
		}
	};

	return <Banner variant="info" {...getProps()} />;
};

export default ProductUnavailable;
