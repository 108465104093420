import React from 'react';

import { Box, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { CancellationObject } from 'common/types';
import { getCancellationPolicyToRender } from 'common/utils/cancellations';
import { cancellationHoursAsString } from 'common/utils/dateUtils';
import useShopFormat from 'hooks/useShopFormat';
import { useTranslation } from 'services/localization/useTranslation';

interface Props {
	startDate: string;
	cancellationPolicy: CancellationObject[];
}

const CancellationPolicy = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { localFormat } = useShopFormat();

	const { startDate, cancellationPolicy } = props;

	const renderCancellationTable = (cancellationPolicy: CancellationObject[], startDate: string) => {
		const { cancellationPolicyToRender, noCancellation } = getCancellationPolicyToRender(
			startDate,
			localFormat,
			cancellationPolicy,
		);
		const cancellations = cancellationPolicyToRender.map((cancellation, index) => {
			const { beforeHours, refundPercentage, displayAs, cancelBefore } = cancellation;
			const cancellationObject: CancellationObject = { beforeHours, displayAs, refundPercentage };
			return (
				<CancellationRow
					key={index}
					leftCell={
						<div>
							<Typography variant="body2">
								{t('common:times.prior', {
									beforeHours: `${cancellationHoursAsString(cancellationObject, t)}`,
									defaultValue: '{{beforeHours}} prior',
								})}
							</Typography>
							<Typography variant="caption">{cancelBefore}</Typography>
						</div>
					}
					rightCell={
						<div>
							<Typography variant="body2">{Math.round(refundPercentage * 100)}%</Typography>
							<Typography variant="caption">
								{t('common:cancellation.rentalAmount', 'of the price')}
							</Typography>
						</div>
					}
				/>
			);
		});

		return (
			<div className={classes.cancellationTable}>
				{cancellations}
				{!!noCancellation && (
					<CancellationRow
						leftCell={
							<Typography variant="body2">
								{t('common:times.lessThan', {
									noCancellationHours: `${cancellationHoursAsString(noCancellation, t)}`,
									defaultValue: 'Less than {{noCancellationHours}}',
								})}
							</Typography>
						}
						rightCell={
							<Typography variant="body2">
								{t('common:cancellation.noRefund', 'No refund')}
							</Typography>
						}
					/>
				)}
			</div>
		);
	};

	if (!cancellationPolicy?.length) return null;

	return (
		<Box>
			<Box py={1}>
				<CancellationRow
					leftCell={
						<Typography>{t('common:cancellation.cancellationLatest', 'Cancel latest')}</Typography>
					}
					rightCell={<Typography>{t('common:payment.refund', 'Refund')}</Typography>}
				/>
			</Box>
			{renderCancellationTable(cancellationPolicy, startDate)}
		</Box>
	);
};

interface RowProps {
	leftCell: JSX.Element;
	rightCell: JSX.Element;
}

const CancellationRow = ({ leftCell, rightCell }: RowProps) => {
	const classes = useStyles();
	return (
		<div className={classes.cancellationRow}>
			<div className={classes.timeCell}>{leftCell}</div>
			<div className={classes.timeCell}>{rightCell}</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cancellationTable: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			padding: theme.spacing(1, 0),
			borderTop: `1px solid ${theme.palette.divider}`,
			borderBottom: `1px solid ${theme.palette.divider}`,
		},
		cancellationRow: {
			display: 'flex',
			flex: '1 1 auto',
			alignItems: 'center',
			padding: theme.spacing(1, 0),
		},
		timeCell: {
			flex: 1,
		},
	}),
);

export default CancellationPolicy;
