import { api as clientApi } from 'common/frontend/api';

import { handleAvailabilityFetch } from './getAvailabilities';
import { handleQuantityFetch } from './getQuantities';
import { AvailabilityFetchProps, ProductTotalAvailability } from './types';

export const handleQuantityAndAvailabilityFetch = async (
	props: AvailabilityFetchProps,
): Promise<ProductTotalAvailability> => {
	const {
		product,
		stockProducts,
		startLocationId,
		shopLocations,
		salesChannel,
		startDate,
		endDate,
		reservationId,
		ignoredOrderProductIds,
		dataSources,
		purchaseType,
	} = props;
	const api = clientApi();

	const quantities = await handleQuantityFetch({
		product,
		stockProducts,
		startLocationId,
		shopLocations,
		salesChannel,
		dataSources,
		api,
		purchaseType,
	});

	const availabilities = await handleAvailabilityFetch({
		quantities: quantities.variantQuantities,
		product,
		stockProducts,
		startDate,
		endDate,
		startLocationId,
		reservationId,
		ignoredOrderProductIds,
		dataSources,
		api,
		purchaseType,
	});

	return { ...quantities, ...availabilities };
};
