import { useCallback } from 'react';

import { useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import {
	deliveryOptionRequiresPickup,
	isDurationSupportedByDeliveryOption,
} from 'common/modules/delivery/utils';
import { getSubscriptionOptionDuration } from 'common/modules/subscriptions';
import { ProductSubscription } from 'common/modules/subscriptions/types';
import { isSameDurationOption } from 'common/utils/duration';

export interface SubscriptionOptionWithId {
	id: string;
	option: ProductSubscription;
}

export interface UseSubscriptionOptions {
	getSubscriptionOptions: () => SubscriptionOptionWithId[];
}

const useSubscriptionOptions = (productId: string): UseSubscriptionOptions => {
	const forcedStartDate = useSelector(CartSelectors.forcedStartDate);
	const productsById = useSelector(StockSelectors.productsById);
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const shortestCartRentalDuration = useSelector(CartSelectors.shortestCartRentalDuration);
	const product = productsById[productId];
	const { subscriptions } = product;

	const getSubscriptionOptions = useCallback(() => {
		if (!subscriptions?.enabled) return [];

		const filteredOptions = subscriptions?.options.filter((option) => {
			if (!!selectedDeliveryOption && !!forcedStartDate) {
				const duration = getSubscriptionOptionDuration(option, forcedStartDate);
				if (!isDurationSupportedByDeliveryOption(duration, selectedDeliveryOption)) return false;
				if (
					deliveryOptionRequiresPickup(selectedDeliveryOption) &&
					!!shortestCartRentalDuration &&
					!isSameDurationOption(duration, shortestCartRentalDuration)
				) {
					return false;
				}
			}

			return true;
		});

		return (
			filteredOptions.map((option) => ({
				id: JSON.stringify(option),
				option,
			})) ?? []
		);
	}, [
		forcedStartDate,
		selectedDeliveryOption,
		shortestCartRentalDuration,
		subscriptions?.enabled,
		subscriptions?.options,
	]);

	return { getSubscriptionOptions };
};

export default useSubscriptionOptions;
