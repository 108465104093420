import React from 'react';

import { Box, Theme, useMediaQuery, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as CartActions from 'redux/actions/cart';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import Banner from 'common/components/states/Banner';
import useScrollToError from 'common/hooks/useScrollToError';
import { sendEventToParentPage } from 'common/modules/embed';
import { isStoreClosedForDay } from 'common/modules/openingHours';
import { isRunInIframe } from 'common/utils/browserUtils';
import BoxButton from 'components/BoxButton';
import { useAnalytics } from 'hooks/useAnalytics';
import useLiftTicketForm from 'hooks/useLiftTicketForm';
import useScrollToCart from 'hooks/useScrollToCart';
import { useTranslation } from 'services/localization';
import { getCartProductsForLiftTickets } from 'utils/cart';

import AmountSelector from './components/AmountSelector';
import KeycardSelector from './components/KeycardSelector';
import SegmentSelector from './components/SegmentSelector';
import SelectionSummary from './components/SelectionSummary';
import StartDateSelector from './components/StartDateSelector';
import TicketTypeSelector from './components/TicketTypeSelector';

const TicketsForm = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const urlLocation = useLocation();
	const { logEcomEvent } = useAnalytics();

	const theme = useTheme();
	const [openCart] = useScrollToCart();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const { scrollToFirstError } = useScrollToError({
		rootId: 'tickets-form',
		scrollOffset: -120,
		onScroll: (offset) => {
			if (isRunInIframe()) {
				sendEventToParentPage({
					origin: 'rentle',
					type: 'trigger-scroll',
					payload: {
						offset,
					},
				});
			}
		},
	});
	const { t } = useTranslation();

	const liftTicketCategory = useSelector(StockSelectors.liftTicketCategory);
	const keycardProduct = useSelector(StockSelectors.keycardProduct);
	const startDateFromCart = useSelector(CartSelectors.startDate);
	const currency = useSelector(ShopSelectors.shopCurrency);
	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const shopId = useSelector(ShopSelectors.shopId);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const taxExcluded = useSelector(ShopSelectors.taxExcluded);

	const form = useLiftTicketForm();
	const isEmbedded = isRunInIframe();

	const isShopClosedForCartDate = !!startDateFromCart
		? isStoreClosedForDay({ openingHours, date: moment(startDateFromCart).format('YYYY-MM-DD') })
		: false;

	if (!liftTicketCategory || !activeLocation) {
		return null;
	}

	const handleAddToBag = async () => {
		const { valid, value, errors } = form.actions.validate();
		if (errors) {
			scrollToFirstError();
			return;
		}

		if (
			valid &&
			value &&
			form.meta.segment &&
			form.meta.product &&
			form.meta.ticketVariant &&
			keycardProduct
		) {
			const selectedAsAdditional =
				new URLSearchParams(urlLocation.search).get('ref') === 'recommended';
			const liftTicketCartProducts = getCartProductsForLiftTickets({
				startDate: value.startDate,
				shopCurrency: currency,
				startLocationId: activeLocation.id,
				endLocationId: activeLocation.id,
				shopId,
				quantity: value.quantity,
				externalSegment: form.meta.segment,
				selectedTicket: form.meta.product,
				variant: form.meta.ticketVariant,
				withNewKeycard: value.withNewKeycard,
				keycardProduct,
				selectedAsAdditional,
				taxExcluded,
			});

			dispatch(CartActions.addProductsToCart(liftTicketCartProducts));
			logEcomEvent('add_to_cart', { cartProducts: liftTicketCartProducts });
			dispatch(CartActions.resetReservationCreated());

			openCart();
		}
	};

	return (
		<Box id="tickets-form">
			<Box mb={2}>
				<StartDateSelector
					value={form.values.startDate}
					onChange={form.actions.setStartDate}
					error={form.errors.startDate}
					minDate={form.meta.validity?.minDate}
					maxDate={form.meta.validity?.maxDate}
					validWeekdays={form.meta.validity?.validWeekdays}
				/>
			</Box>
			<Box mt={2}>
				<SegmentSelector
					startDate={form.values.startDate}
					value={form.values.segmentId}
					onChange={form.actions.setSegmentId}
					error={form.errors.segmentId}
				/>
			</Box>
			<Box mt={2}>
				<TicketTypeSelector
					value={form.values.productId}
					onChange={form.actions.setProductId}
					error={form.errors.productId}
					selectedSegmentId={form.values.segmentId}
					startDate={form.values.startDate}
					loading={form.meta.loadingSegmentPrices}
				/>
			</Box>
			<Box mt={3} pt={2} className={classes.divider}>
				<AmountSelector
					name={t('common:phrases.howManyDoYouNeed', 'How many do you need?')}
					value={form.values.quantity}
					onChange={form.actions.setQuantity}
					error={form.errors.quantity ?? undefined}
				/>
			</Box>
			<Box mt={2} className={classes.divider}>
				<KeycardSelector
					value={form.values.withNewKeycard}
					onChange={form.actions.setWithNewKeycard}
					error={form.errors.withNewKeycard}
					keycardCount={form.values.quantity}
				/>
			</Box>
			<Box mt={3} pt={3} className={classes.divider}>
				<SelectionSummary
					product={form.meta.product}
					segment={form.meta.segment}
					withNewKeycard={form.values.withNewKeycard}
					quantity={form.values.quantity}
					charge={form.meta.charge}
					loading={form.meta.loadingSegmentPrices}
				/>
			</Box>
			{isShopClosedForCartDate ? (
				<Box pt={4}>
					<Banner variant="info" title={t('StartDateTimeSelector.selectionNotAvailable')} />
				</Box>
			) : (
				<>
					<Box mt={8} />

					<Box className={classNames({ [classes.mobileFixedBottomWrapper]: !isEmbedded })}>
						<BoxButton
							text={t('common:actions.addToBag', 'Add to bag')}
							variant="filled"
							padding={isSmallScreen ? 'large' : 'normal'}
							onClick={handleAddToBag}
							disabled={
								form.meta.loadingSegmentPrices ||
								!form.values.startDate ||
								!form.values.productId ||
								!form.values.segmentId
							}
							blurOnClick
						/>
					</Box>
				</>
			)}
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mobileFixedBottomWrapper: {
			position: 'fixed',
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: 1200,
			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(3),
			},
			[theme.breakpoints.up('md')]: {
				zIndex: 1,
				position: 'static',
			},
		},
		divider: {
			borderTop: `1px solid #e7e7e7`,
		},
	}),
);

export default TicketsForm;
