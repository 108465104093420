import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import useHeight from 'common/hooks/useHeight';

interface Props {
	transitionMs?: number;
}

const AutoResize: React.FC<Props> = (props) => {
	const classes = useStyles(props);

	const [innerRef, innerHeight] = useHeight();

	return (
		<div className={classes.wrapper} style={{ height: innerHeight ?? 0 }}>
			<div ref={innerRef}>{props.children}</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			overflow: 'hidden',
			transition: (props: Props) =>
				props.transitionMs ? `height ${props.transitionMs}ms` : 'height 0.3s',
		},
	}),
);

export default AutoResize;
