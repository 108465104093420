import React from 'react';

import { useSelector } from 'redux/hooks';
import * as StockSelectors from 'redux/selectors/stock';

import CategoryRow from './Category';

const ProductsByCategory = () => {
	const visibleCategories = useSelector(StockSelectors.visibleCategoriesSorted);

	return (
		<>
			{visibleCategories.map((c) => (
				<CategoryRow key={c.id} categoryId={c.id} />
			))}
		</>
	);
};

export default ProductsByCategory;
