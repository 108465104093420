import React from 'react';

import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import { ProductApi, SegmentMap } from 'common/types';
import { getSkidataSegmentTranslation } from 'common/utils/segments';
import PriceSummary from 'components/PriceSummary';
import { useTranslation } from 'services/localization/useTranslation';

interface Props {
	product: ProductApi | null;
	segment: SegmentMap | null;
	withNewKeycard: boolean;
	quantity: number;
	charge: number;
	loading?: boolean;
}

const SelectionSummary = React.memo((props: Props) => {
	const { product, segment, withNewKeycard, quantity, charge, loading } = props;
	const { t, getTranslation, language } = useTranslation();

	const currency = useSelector(ShopSelectors.shopCurrency);
	const keycardProduct = useSelector(StockSelectors.keycardProduct);
	const taxExcluded = useSelector(ShopSelectors.taxExcluded);

	if (!product || !segment) {
		return null;
	}

	const getRows = () => {
		const productName = getTranslation(product.name);
		const segmentText = getSkidataSegmentTranslation(segment, language);

		const rows = [
			{
				key: 'ticket',
				quantity,
				label: `${productName}, ${segmentText}`,
				unitPrice: charge,
				loading,
			},
		];

		if (withNewKeycard && keycardProduct) {
			rows.push({
				key: 'keycard',
				quantity,
				label: getTranslation(keycardProduct.name),
				unitPrice: keycardProduct.rentals?.basePrice ?? 0,
				loading,
			});
		}

		return rows;
	};

	return (
		<PriceSummary
			heading={t('common:booking.yourBooking', 'Your booking')}
			rows={getRows()}
			currency={currency}
			taxExcluded={taxExcluded}
		/>
	);
});

export default SelectionSummary;
