import { EnumValues, HH_MM } from 'common/types';
import { stringEnum } from 'common/utils/objects';

/**
 * Buffer time as an absolute amount of minutes. Can be used to declare absolute time values like "2 hours" or "2 days"
 *
 * @property unit - The unit of the buffer time
 * @property amount - The amount of the unit
 */
export type FixedBufferTime = {
	unit: BufferTimeUnit;
	amount: number;
};

export type FixedBufferTimeWithType = {
	type: 'fixed';
	value: FixedBufferTime;
};

/**
 * Buffer time as a relative value with time of day. Can be used to declare a time periods like
 * "2 days before by 10:00", "same day by 12:00" or "4 days after by 18:00".
 *
 * @property days - The amount of days before or after, or 0 for same day
 * @property timeOfDay - The time of day
 * @property dayType - The type of days to count towards the buffer time
 */
export type RelativeBufferTime = {
	days: number;
	timeOfDay: HH_MM;
	dayType: BufferTimeDayType;
};

export type RelativeBufferTimeWithType = {
	type: 'relative';
	value: RelativeBufferTime;
};

export const BufferTimeDayTypes = stringEnum(['days', 'weekDays', 'openingDays']);
export const BufferTimeUnitTypes = stringEnum(['minutes', 'hours']);
export const BufferTimeUnits = { ...BufferTimeDayTypes, ...BufferTimeUnitTypes };
export const BufferTimeDirections = stringEnum(['before', 'after']);

export type BufferTimeDayType = EnumValues<typeof BufferTimeDayTypes>;
export type BufferTimeUnit = EnumValues<typeof BufferTimeUnits>;
export type BufferTimeDirection = EnumValues<typeof BufferTimeDirections>;

export type BufferTime = FixedBufferTimeWithType | RelativeBufferTimeWithType;
