import React, { useEffect, useState } from 'react';

import { Box, Checkbox, FormControlLabel, Stack, Theme, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TFunction } from 'i18next';

import Note from 'common/components/states/Note';
import { useAdyen } from 'common/hooks/useAdyen';
import {
	getPaymentMethodById,
	getPaymentMethodId,
	isAdyenPaymentMethod,
	isWalletPaymentMethod,
} from 'common/modules/payments/paymentMethods';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { AmountObject, CurrencyObject, Languages } from 'common/types';
import { StorageKeys, Storages, getFromStorage, saveToStorage } from 'common/utils/frontUtils';

import { RecurringPaymentsAcceptanceProps } from '.';
import { PaymentMethodError } from '../types';
import PaymentButtonElement from './PaymentButtonElement';
import PaymentMethodContent from './PaymentMethodContent';
import PaymentMethodHeader from './PaymentMethodHeader';

export interface PaymentMethodSelectorTableProps {
	activePaymentMethods: ShopOnlinePaymentMethodObject[];
	selectorNegativeSpacing?: number;
	deposit?: {
		amount: AmountObject;
		reservationType: 'NOW' | 'LATER';
	};
	paymentInProgress: boolean;
	loading: boolean;
	error?: PaymentMethodError;
	t: TFunction;
	amount: AmountObject;
	currencyObject?: CurrencyObject;
	handleConfirm: (method: ShopOnlinePaymentMethodObject) => void;
	lang: Languages;
	recurringPaymentsAcceptance: RecurringPaymentsAcceptanceProps;
}

const getActivePaymentMethodFromStorage = (
	activePaymentMethods: ShopOnlinePaymentMethodObject[],
) => {
	const sessionPaymentMethodId = getFromStorage(Storages.SESSION, StorageKeys.PAYMENT_METHOD);
	const paymentMethod = sessionPaymentMethodId
		? getPaymentMethodById(sessionPaymentMethodId, activePaymentMethods)
		: undefined;
	return paymentMethod ?? null;
};

export const PaymentMethodSelectorTable = (props: PaymentMethodSelectorTableProps) => {
	const {
		t,
		activePaymentMethods,
		selectorNegativeSpacing = 0,
		deposit,
		loading,
		error,
		amount,
		currencyObject,
		handleConfirm,
		paymentInProgress,
		lang,
		recurringPaymentsAcceptance,
	} = props;
	const { adyenCheckout } = useAdyen();
	const classes = useStyles({ selectorNegativeSpacing });

	const { hasSubscription, isAccepted, setAcceptance } = recurringPaymentsAcceptance;
	const [
		selectedPaymentMethod,
		setSelectedPaymentMethod,
	] = useState<ShopOnlinePaymentMethodObject | null>(
		getActivePaymentMethodFromStorage(activePaymentMethods),
	);
	const selectedPaymentMethodId = selectedPaymentMethod
		? getPaymentMethodId(selectedPaymentMethod)
		: null;

	useEffect(() => {
		if (activePaymentMethods.length && !selectedPaymentMethodId) {
			const methodFromStorage = getActivePaymentMethodFromStorage(activePaymentMethods);
			handlePaymentMethodSelect(methodFromStorage ?? activePaymentMethods[0]);
		}
	}, [activePaymentMethods, selectedPaymentMethodId]);

	const selectedPaymentMethodObject = selectedPaymentMethodId
		? getPaymentMethodById(selectedPaymentMethodId, activePaymentMethods)
		: undefined;

	const handlePaymentMethodSelect = (method: ShopOnlinePaymentMethodObject) => {
		setSelectedPaymentMethod(method);
		saveToStorage(Storages.SESSION, StorageKeys.PAYMENT_METHOD, getPaymentMethodId(method));
	};

	return (
		<>
			{activePaymentMethods.map((method, i) => {
				const methodId = getPaymentMethodId(method);
				const isActive = selectedPaymentMethodId === methodId;
				if (!adyenCheckout && isAdyenPaymentMethod(method.id)) return null;
				return (
					<Accordion
						key={methodId}
						expanded={isActive}
						onChange={() => {
							handlePaymentMethodSelect(method);
						}}
						classes={{
							root: classes.panelRoot,
							disabled: classes.panelDisabled,
						}}
						disabled={loading || paymentInProgress}
					>
						<AccordionSummary
							aria-controls={methodId}
							id={methodId}
							classes={{
								root: classes.panelHeader,
								content: classes.panelHeaderContent,
								expanded: classes.panelHeaderExpanded,
							}}
						>
							<PaymentMethodHeader paymentMethod={method} isActive={isActive} t={t} lang={lang} />
						</AccordionSummary>
						<AccordionDetails className={classes.panelDetails}>
							<PaymentMethodContent
								lang={lang}
								method={method}
								deposit={deposit}
								adyenCheckoutObject={adyenCheckout}
								error={error}
								t={t}
							/>
						</AccordionDetails>
					</Accordion>
				);
			})}
			{hasSubscription && (
				<Box mt={2} pl={1.5}>
					<FormControlLabel
						control={
							<Stack justifyContent="flex-start">
								<Checkbox checked={isAccepted} onChange={(e) => setAcceptance(e.target.checked)} />
							</Stack>
						}
						label={
							<Stack pl={2.5} spacing={2}>
								<Typography>
									{t(
										'common:Checkout.recurringPayments',
										'I understand that one or more items in my cart is a deferred or recurring purchase. I agree that I will be charged at the prices, frequency and dates listed on this page until my order has ended',
									)}
								</Typography>
								{!!recurringPaymentsAcceptance.error && (
									<Note variant="danger" color="error" label={recurringPaymentsAcceptance.error} />
								)}
							</Stack>
						}
					/>
				</Box>
			)}
			{selectedPaymentMethodObject &&
				selectedPaymentMethodId &&
				!isWalletPaymentMethod(selectedPaymentMethodId) && (
					<PaymentButtonElement
						method={selectedPaymentMethodObject}
						loading={loading}
						t={t}
						disabled={loading || paymentInProgress}
						handleConfirm={handleConfirm}
						confirmError={error}
						amount={amount}
						currencyObject={currencyObject}
					/>
				)}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		panelRoot: {
			border: `1px solid #dcdcdc`,
			boxShadow: 'none',
			// ! important to overwrite Material UI default margin on expanded elements
			// Top margin -1 to fix double borders
			margin: ({ selectorNegativeSpacing: negativeMargin }: { selectorNegativeSpacing: number }) =>
				negativeMargin
					? `${theme.spacing('-1px', -negativeMargin, 0, -negativeMargin)} !important`
					: `${theme.spacing('-1px', 'auto', 0, 'auto')} !important`,
			minWidth: '100%',
			'&$panelRootDisabled': {
				backgroundColor: 'unset',
			},
			'&:before': {
				display: 'none',
			},
		},
		panelDisabled: {
			backgroundColor: 'transparent !important',
			opacity: 0.5,
		},
		panelHeader: {
			padding: theme.spacing(0, 2),
			border: `none`, // To not make content move when border appears on hover
			borderRadius: 4,
			minHeight: 64,
			'&:hover': {
				zIndex: 2,
			},
			marginBottom: -2,
		},
		panelHeaderExpanded: {
			margin: '0px !important', // To overwrite Material UI default margin on expanded elements
		},
		panelHeaderContent: {
			display: 'flex',
			justifyContent: 'center',
			margin: 0,
			flexDirection: 'column',
		},
		panelDetails: {
			position: 'relative',
			padding: theme.spacing(0, 2, 4, 2),
			display: 'block',
			'&:empty': {
				display: 'none',
			},
			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(0, 7, 4, 7),
			},
		},
	}),
);
