import { AvailabilityRange } from 'common/api/frontend/inventory_new/availabilityRange';
import { OpeningHours } from 'common/modules/openingHours';
import { getStockProductMaintenanceMinutes } from 'common/modules/products/utils';
import { StartTimesConfig } from 'common/modules/startTimes';
import { BookingBuffer, ProductApi, StartTimeCount, YYYY_MM_DD } from 'common/types';
import { isFixedPriceProduct } from 'common/utils/productUtils';

import { getRelevantRanges } from '../utils';
import { getAvailabilityForTimeSlotWithReason } from './availabilityForTimeSlot';
import {
	AvailabilityByTime,
	TimeSlotType,
	TimeSlotVariant,
	ValidSlotsByTime,
	ValidityWithReason,
} from './types';
import {
	getStartEndRangeFromTimeSlots,
	getTimeSlotsForDateRange,
	shouldIgnoreOpeningHoursForTimeSlot,
} from './utils';

export const getTimeSlots = (props: {
	start: YYYY_MM_DD;
	end: YYYY_MM_DD;
	openingHours: OpeningHours;
	variant: TimeSlotVariant;
	startTimes: StartTimesConfig;
	useBookingBuffer: BookingBuffer | undefined;
	product: ProductApi;
	stockProducts: ProductApi[];
	timeSlotType: TimeSlotType;
	availabilities: AvailabilityRange[];
	startTimeCounts: StartTimeCount;
	timezone: string;
	alreadySelected?: number;
}): AvailabilityByTime => {
	const {
		start,
		end,
		openingHours,
		variant,
		startTimes,
		useBookingBuffer,
		product,
		stockProducts,
		timeSlotType,
		availabilities,
		startTimeCounts,
		alreadySelected,
		timezone,
	} = props;
	const timeSlots = getTimeSlotsForDateRange({
		startDate: start,
		endDate: end,
		startTimesConfig: startTimes,
		openingHours,
		bookingBuffer: useBookingBuffer,
		variant,
		timeSlotType: timeSlotType.type,
		timezone,
	});
	return addAvailabilityToTimeSlots({
		timeSlots,
		product,
		stockProducts,
		timeSlotType,
		availabilities,
		startTimeCounts,
		startTimes,
		alreadySelected,
		openingHours,
		timezone,
	});
};

export const addAvailabilityToTimeSlots = (props: {
	product: ProductApi;
	stockProducts: ProductApi[];
	timeSlotType: TimeSlotType;
	timeSlots: ValidSlotsByTime;
	availabilities: AvailabilityRange[];
	startTimeCounts: StartTimeCount;
	openingHours: OpeningHours;
	startTimes: StartTimesConfig;
	timezone: string;
	alreadySelected?: number;
}): AvailabilityByTime => {
	const {
		product,
		timeSlotType,
		timeSlots,
		availabilities,
		stockProducts,
		openingHours,
		startTimes: startTimesConfig,
		startTimeCounts,
		alreadySelected,
		timezone,
	} = props;
	const inventoryBlockerMinutesBefore = 0;
	const inventoryBlockerMinutesAfter = getStockProductMaintenanceMinutes(product, stockProducts);

	const startTimesRange = getStartEndRangeFromTimeSlots(
		timeSlots,
		timeSlotType,
		openingHours,
		timezone,
	);

	const relevantRanges =
		Object.keys(timeSlots).length > 0 && !!startTimesRange.start && !!startTimesRange.end
			? getRelevantRanges({
					start: startTimesRange.start,
					end: startTimesRange.end,
					availabilityRanges: availabilities,
			  })
			: [];

	const orderLimit = startTimesConfig.maxOrdersPerStartTime ?? Infinity;

	// This helper is to reduce the amount of valid ranges to compare against
	// The logic is: returns valid ranges from last check, and if end time of last range is still after the next end time, we can use this small subset of ranges for comparing
	let rangesToCompareFirst: AvailabilityRange[] | undefined = !!relevantRanges.length
		? [relevantRanges[0]]
		: undefined;

	const getAvailabilityForDate = (dayString: string) => {
		const startTimes = timeSlots[dayString];
		return Object.entries(startTimes).reduce((result, [time, validityWithReason]) => {
			/* For fixed price products, we should skip checking if the end time is within opening hours.
			 * This is because they have 0 duration, so the end time is the same as the start time.
			 * We have already checked earlier whether the start time should be allowed even if it is outside opening hours. */
			const allowEndOutsideOpeningHours =
				isFixedPriceProduct(product) || shouldIgnoreOpeningHoursForTimeSlot(validityWithReason);

			const { availability, overlappingRanges } = getAvailabilityForTimeSlotWithReason({
				timeWithReason: { day: dayString, time, validity: validityWithReason },
				timeSlotType,
				openingHours,
				orderLimit,
				startTimeCounts,
				availabilityRanges: relevantRanges,
				availabilityRangesToCompareFirst: rangesToCompareFirst,
				inventoryBlockerMinutesBefore,
				inventoryBlockerMinutesAfter,
				alreadySelected: alreadySelected ?? 0,
				timezone,
				allowEndOutsideOpeningHours,
			});
			if (overlappingRanges) {
				rangesToCompareFirst = overlappingRanges;
			}
			return {
				...result,
				[time]: availability,
			};
		}, {} as { [time: string]: ValidityWithReason });
	};

	const dates = Object.keys(timeSlots);

	const availabilityByStartTime = dates.reduce((result, date) => {
		result[date] = getAvailabilityForDate(date);
		return result;
	}, {});

	return availabilityByStartTime;
};
