import { clamp } from 'lodash';

/** Constrain @value between @min and @max
 *
 * @param min The minimum result value
 * @param max The maximum result value
 */
export const constrain = (min: number, max: number) => (value: number) => {
	return clamp(value, min, max);
};

/** Get @percent percent of @ofValue
 *
 * @param percent
 * @param ofValue
 */
export const percentageOf = (percent: number, ofValue: number) => {
	if (percent === 0 || ofValue === 0) return 0;
	if (!isFinite(ofValue)) return ofValue;

	const result = (percent * ofValue) / 100;

	return result === -0 ? 0 : result;
};

/** Get @percent percent of @value where @percent is constrained to 0-100
 *
 * @param value
 * @param ofValue
 */

export const percentageOfConstrained = (percent: number, ofValue: number) => {
	return percentageOf(constrain(0, 100)(percent), ofValue);
};

/** Get the ratio of two values as a percentage, i.e. how many percent is @value of @ofValue
 *
 * @param value
 * @param ofValue
 */
export const asPercentage = (value: number, ofValue: number) => {
	if (value === 0) return 0;
	if (ofValue === 0) return Infinity;
	return (value * 100) / ofValue;
};

export const removeNonNumeric = (string: string) => Number(string.replace(/[^0-9.-]+/g, ''));

export const randomNumber = (min: number, max: number) => {
	return Math.random() * (max - min) + min;
};
