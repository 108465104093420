import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';

import { pageView } from 'common/services/analytics';
import { GTM_DATA_LAYER_NAME } from 'common/services/analytics/gtm';
import {
	isValidGA4AnalyticsTag,
	isValidGtmTag,
	isValidUAAnalyticsTag,
} from 'common/utils/validation';

export const useShopGoogleAnalytics = () => {
	const shopGoogleAnalytics = useSelector(ShopSelectors.shopGoogleAnalytics);

	const addGtmScript = (gtmId: string) => {
		const gtmScriptContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','${GTM_DATA_LAYER_NAME}','${gtmId}');`;
		const gtmScript = document.createElement('script');
		gtmScript.innerHTML = gtmScriptContent;
		document.head.insertBefore(gtmScript, document.head.childNodes[0]);
	};

	// gtag.js function in initialized in index.html, so no need to add the script here
	useEffect(() => {
		if (window.gtag === undefined) return;
		if (!!shopGoogleAnalytics) {
			const validGa4Tags = (shopGoogleAnalytics.GA4 ?? []).filter((tag) =>
				isValidGA4AnalyticsTag(tag),
			);
			const validUATags = (shopGoogleAnalytics.UA ?? []).filter((tag) =>
				isValidUAAnalyticsTag(tag),
			);
			const validAnalyticsTags = [...validGa4Tags, ...validUATags];
			for (const gaTag of validAnalyticsTags) {
				window.gtag('config', gaTag, { send_page_view: false, anonymize_ip: true });
			}
			if (shopGoogleAnalytics.GTM?.length) {
				for (const GTM of shopGoogleAnalytics.GTM) {
					if (!isValidGtmTag(GTM)) continue;
					addGtmScript(GTM);
				}
			}
		}
		// Track first pageview that is not tracked by history.listen event
		pageView(window.location.pathname, 'ONLINE', shopGoogleAnalytics);
	}, [shopGoogleAnalytics]);
};
