import React from 'react';

import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import * as CartActions from 'redux/actions/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import AmountPicker from 'common/components/pickers/AmountPicker';
import Note from 'common/components/states/Note';
import { SkidataPackageProductApi } from 'common/types';
import { isRunInIframe } from 'common/utils/browserUtils';
import { getSkidataSegmentTranslationById } from 'common/utils/segments';
import BoxButton from 'components/BoxButton';
import usePackageLiftTicketForm from 'hooks/usePackageLiftTicketForm';
import useScrollToCart from 'hooks/useScrollToCart';
import { useTranslation } from 'services/localization';

import KeycardSelector from '../Tickets/components/KeycardSelector';
import StartDateSelector from '../Tickets/components/StartDateSelector';
import PackageTicketsPricingSummary from './PackageTicketsPricingSummary';
import TicketTypeSelector from './TicketTypeSelector';

interface Props {
	packageProduct: SkidataPackageProductApi;
}

const PackageTicketsForm = (props: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const urlLocation = useLocation();

	const [openCart] = useScrollToCart();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const { t, language } = useTranslation();

	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const liftTicketCategory = useSelector(StockSelectors.liftTicketCategory);

	const form = usePackageLiftTicketForm({ packageProduct: props.packageProduct });

	const isEmbedded = isRunInIframe();

	const handleAddToBag = async () => {
		const validationResult = form.actions.validate();

		if (!validationResult.valid || !validationResult.value) return;

		dispatch(
			CartActions.addPackageTicketProductToCart({
				formState: validationResult.value,
				selectedAsAdditional: new URLSearchParams(urlLocation.search).get('ref') === 'recommended',
			}),
		);

		openCart();
	};

	if (!liftTicketCategory || !activeLocation) {
		return null;
	}

	return (
		<Box id="tickets-form">
			<Box mb={2}>
				<StartDateSelector
					value={form.values.startDate}
					onChange={form.actions.setStartDate}
					error={form.errors.startDate}
					minDate={form.meta.validity?.minDate}
					maxDate={form.meta.validity?.maxDate}
				/>
			</Box>
			<Box mb={2}>
				<TicketTypeSelector
					value={form.values.externalId}
					onChange={form.actions.setExternalId}
					error={form.errors.externalId}
					startDate={form.values.startDate}
					ticketTypeOptions={form.meta.ticketTypeOptions}
				/>
			</Box>
			<Box>
				{!!form.errors.minItemQuantity && (
					<div className={classes.minItemQuantityError}>
						<Note variant="danger" label={form.errors.minItemQuantity} />
					</div>
				)}
				{form.values.packageItems.map((i) => (
					<Box key={`${i.externalId}_${i.externalSegmentId}`} className={classes.packageItem}>
						<Typography variant="body1">
							{getSkidataSegmentTranslationById(
								i.externalSegmentId,
								liftTicketCategory?.segmentMapping ?? [],
								language,
							)}
						</Typography>
						<Box>
							<AmountPicker
								value={i.quantity.selected ?? i.quantity.min}
								onChange={(qty) => form.actions.setPackageItemQuantity(i.externalSegmentId, qty)}
								minValue={Number(i.quantity.min)}
								maxValue={Number(i.quantity.max)}
							/>
						</Box>
					</Box>
				))}
			</Box>
			<KeycardSelector
				value={form.values.withNewKeycard}
				onChange={form.actions.setWithNewKeycard}
				error={form.errors.withNewKeycard}
				keycardCount={form.meta.totalTicketCount}
			/>
			<Box mb={2} />
			{!!form.values.externalId && !!form.values.startDate && (
				<PackageTicketsPricingSummary
					items={form.values.packageItems}
					pricing={form.meta.pricingInfo}
					externalId={form.values.externalId}
					segments={liftTicketCategory?.segmentMapping ?? []}
					withNewKeycards={form.values.withNewKeycard}
				/>
			)}
			<Box mt={8} />
			<Box
				className={classNames({
					[classes.mobileFixedBottomWrapper]: !isEmbedded,
					[classes.embeddedBottomWrapper]: isEmbedded,
				})}
			>
				<BoxButton
					text={t('common:actions.addToBag', 'Add to bag')}
					variant="filled"
					padding={isSmallScreen ? 'large' : 'normal'}
					onClick={handleAddToBag}
					disabled={!form.meta.pricingInfo.data}
					blurOnClick
				/>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mobileFixedBottomWrapper: {
			position: 'fixed',
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: 1200,
			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(3),
			},
			[theme.breakpoints.up('md')]: {
				zIndex: 1,
				position: 'static',
			},
		},
		embeddedBottomWrapper: {
			marginBottom: theme.spacing(4),
		},
		divider: {
			borderTop: `1px solid #e7e7e7`,
		},
		packageItem: {
			marginBottom: theme.spacing(2),
			padding: theme.spacing(1, 0),
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		minItemQuantityError: {
			color: theme.palette.error.main,
		},
	}),
);

export default PackageTicketsForm;
