import React from 'react';

import { Box, Stack } from '@mui/material';

import Note from 'common/components/states/Note';
import {
	CheckoutSettings,
	CustomCheckboxValue,
	CustomContentError,
} from 'common/modules/customization';
import { Languages, getTranslation } from 'common/modules/translations';

import CheckboxItem from '../CheckboxItem';

export interface Props {
	values?: CustomCheckboxValue[];
	checkout: CheckoutSettings;
	onChange: (args: CustomCheckboxValue) => void;
	errors?: CustomContentError[] | null;
	language: Languages;
}
const CustomCheckbox = (props: Props) => {
	const { checkout, onChange, errors, language, values } = props;

	const getErrorById = (id: string) => {
		return errors?.find((error) => error.id === id);
	};

	return (
		<Stack spacing={2}>
			{checkout.customContent?.sections?.map((section, sectionIndex) => (
				<Stack
					spacing={2}
					direction={{ xs: 'column', md: 'row' }}
					key={sectionIndex}
					sx={{ width: '100%' }}
				>
					{section?.content?.map((content, contentIndex) => {
						const checkboxValue = values?.find((value) => value?.id === content?.id)?.value;
						return (
							content?.type === 'checkbox' && (
								<Stack sx={{ width: '100%' }}>
									<CheckboxItem
										checked={!!checkboxValue}
										label={getTranslation(content.label, language) + (content.required ? '*' : '')}
										id={content.id}
										onCheckedChange={(value) =>
											onChange({ value, sectionIndex, contentIndex, id: content.id })
										}
										noPadding
										checkboxSize="small"
										value={content.id}
										error={
											!!getErrorById(content.id)?.error ? (
												<Box mt={1.5}>
													<Note variant="danger" label={getErrorById(content.id)?.error ?? ''} />
												</Box>
											) : undefined
										}
									/>
								</Stack>
							)
						);
					})}
				</Stack>
			))}
		</Stack>
	);
};

export default CustomCheckbox;
