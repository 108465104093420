import React, { useEffect, useRef } from 'react';

import { Box } from '@mui/material';
import { PaymentIntent, StripeError } from '@stripe/stripe-js';
import { TFunction } from 'i18next';

import Spinner from 'common/components/Spinner';
import { OnlineMethods } from 'common/modules/payments/constants';
import {
	isCustomShopPaymentMethodObject,
	shopHasPaymentMethod,
} from 'common/modules/payments/paymentMethods';
import {
	AdyenPaymentMethod,
	FixedManualPaymentMethod,
	OnlinePaymentMethod,
	ShopCustomOnlinePaymentMethodObject,
	ShopOnlinePaymentMethodObject,
	StripePaymentMethod,
	WalletPaymentMethod,
} from 'common/modules/payments/types';
import { AdditionalPaymentAction, AmountObject, CurrencyObject, Languages } from 'common/types';

import PaymentProcessDialog from '../PaymentProcessDialog';
import WalletPaymentMethods from '../elements/WalletPaymentMethods';
import { PaymentMethodError } from '../types';
import { PaymentMethodSelectorTable } from './PaymentMethodSelectorTable';

export interface PaymentMethodObject {
	paymentMethodName: OnlinePaymentMethod;
	childElement: JSX.Element | null;
}

export type ConfirmAction =
	| {
			type: 'ADYEN_PAYMENT';
			paymentMethodName: AdyenPaymentMethod;
	  }
	| {
			type: 'STRIPE_PAYMENT';
			paymentMethodName: StripePaymentMethod;
	  }
	| {
			type: 'MANUAL';
			paymentMethod: FixedManualPaymentMethod | ShopCustomOnlinePaymentMethodObject;
	  };

export type AdditionalDetailsUpdate =
	| { provider: 'ADYEN'; data: ICheckout.DetailsRequest }
	| {
			provider: 'STRIPE';
			data:
				| {
						paymentIntent?: PaymentIntent | undefined;
						error?: StripeError | undefined;
				  }
				| undefined;
	  };

export type RecurringPaymentsAcceptanceProps = {
	hasSubscription: boolean;
	isAccepted: boolean;
	setAcceptance: (value: boolean) => void;
	error?: string | null;
};
export interface PaymentMethodSelectorProps {
	activePaymentMethods: ShopOnlinePaymentMethodObject[];
	paymentMethodsLoading: boolean;
	amount: AmountObject;
	currencyObject?: CurrencyObject;
	country: string | undefined;
	deposit?: {
		amount: AmountObject;
		reservationType: 'NOW' | 'LATER';
	};
	handleSubmit: (action: ConfirmAction) => void;
	handleAdditionalDetailsUpdate: (args: AdditionalDetailsUpdate) => void;
	confirmLoading: boolean;
	paymentInProgress: boolean;
	confirmError?: PaymentMethodError;
	additionalPaymentAction: AdditionalPaymentAction | undefined;
	lang: Languages;
	merchantName: string;
	t: TFunction;
	selectorNegativeSpacing?: number;
	merchantId: string;
	recurringPaymentsAcceptance: RecurringPaymentsAcceptanceProps;
}

const PaymentMethodSelector = (props: PaymentMethodSelectorProps) => {
	const {
		activePaymentMethods,
		paymentMethodsLoading,
		paymentInProgress,
		confirmLoading,
		t,
		additionalPaymentAction,
		confirmError,
		deposit,
		amount,
		country,
		merchantName,
		selectorNegativeSpacing,
		currencyObject,
		lang,
		merchantId,
		recurringPaymentsAcceptance,
	} = props;

	// These refs are here to solve issue with stale function props
	// Happening because checkout object (and its functions) are defined on first render, but shopper might still change details after it
	const handleSubmitRef = useRef<typeof props.handleSubmit>(() => undefined);
	const handleAdditionalDetailsUpdateRef = useRef<typeof props.handleAdditionalDetailsUpdate>(
		() => undefined,
	);

	useEffect(() => {
		handleSubmitRef.current = props.handleSubmit;
		handleAdditionalDetailsUpdateRef.current = props.handleAdditionalDetailsUpdate;
	});

	const handleConfirmTrigger = (method: ShopOnlinePaymentMethodObject | WalletPaymentMethod) => {
		if (method === 'APPLEPAY' || method === 'GOOGLEPAY') {
			return handleSubmitRef.current({
				type: 'ADYEN_PAYMENT',
				paymentMethodName: method,
			});
		}

		if (isCustomShopPaymentMethodObject(method)) {
			return handleSubmitRef.current({
				type: 'MANUAL',
				paymentMethod: method,
			});
		}

		switch (method.id) {
			case 'PAY_STORE':
				return handleSubmitRef.current({
					type: 'MANUAL',
					paymentMethod: method.id,
				});
			case 'CARD_ONLINE_STRIPE':
				return handleSubmitRef.current({
					type: 'STRIPE_PAYMENT',
					paymentMethodName: 'CARD_ONLINE_STRIPE',
				});
			case 'EPASSI_ONLINE':
				return;
			default:
				return handleSubmitRef.current({
					type: 'ADYEN_PAYMENT',
					paymentMethodName: method.id,
				});
		}
	};

	const hidePayments = paymentInProgress && paymentMethodsLoading;

	return (
		<Box display="block">
			{hidePayments ? null : paymentMethodsLoading ? (
				<Spinner
					relative
					text={`${t(
						'common:payment.loadingPaymentMethods',
						'Getting available payment options',
					)}...`}
				/>
			) : (
				<>
					{shopHasPaymentMethod(OnlineMethods.CARD_ONLINE, activePaymentMethods) && (
						<WalletPaymentMethods
							t={t}
							loading={confirmLoading}
							paymentError={confirmError}
							amount={amount}
							country={country}
							merchantName={merchantName}
							hasDeposit={!!deposit?.amount.value && deposit.amount.value > 0}
							handlePayment={handleConfirmTrigger}
							merchantId={merchantId}
							hasSubscription={recurringPaymentsAcceptance.hasSubscription}
						/>
					)}
					<PaymentMethodSelectorTable
						t={t}
						lang={lang}
						activePaymentMethods={activePaymentMethods}
						selectorNegativeSpacing={selectorNegativeSpacing}
						deposit={deposit}
						loading={confirmLoading}
						paymentInProgress={paymentInProgress}
						error={confirmError}
						amount={amount}
						currencyObject={currencyObject}
						handleConfirm={handleConfirmTrigger}
						recurringPaymentsAcceptance={recurringPaymentsAcceptance}
					/>
				</>
			)}
			<PaymentProcessDialog
				paymentInProgress={paymentInProgress}
				paymentLoading={paymentInProgress && confirmLoading}
				additionalPaymentAction={additionalPaymentAction}
				t={t}
				paymentError={paymentInProgress ? confirmError?.error : undefined}
				onAdditionalPaymentDetailsChange={handleAdditionalDetailsUpdateRef.current}
			/>
		</Box>
	);
};

export default PaymentMethodSelector;
