import React from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Image from 'common/components/Image';

interface Props {
	src: string;
	alt: string;
}

const ProductMedia = (props: Props) => {
	const classes = useStyles();
	const { src, alt } = props;

	return (
		<Box className={classes.wrapper}>
			<Image src={src} alt={alt} magnifier={true} />
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	}),
);

export default ProductMedia;
