import React, { useState } from 'react';

import {
	Box,
	Checkbox,
	FormControl,
	Icon,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { RiArrowDownSLine, RiCheckLine } from 'react-icons/ri';

import { isRunInIframe } from 'common/utils/browserUtils';

export interface SelectOption {
	label: string;
	description?: string;
	rightContent?: string;
	rightContentLoading?: boolean;
	id: string;
}

interface SharedProps {
	label?: string;
	emptyLabel?: string;
	options: SelectOption[];
	maxWidth?: string | number;
	hideEmptyOption?: boolean;
	indicators?: 'checkbox' | 'checkmark' | 'none';
	variant?: 'outlined' | 'minimal';
	disabled?: boolean;
	hideDropdown?: boolean;
	customClasses?: {
		option?: string;
		optionLabel?: string;
		optionDescription?: string;
		selectedValue?: string;
	};
	IconComponent?: () => JSX.Element;
}

interface SelectProps extends SharedProps {
	multiple: boolean;
	value: string | string[] | undefined | null;
	handleSelect: (e: SelectChangeEvent<string | string[]>, closeFunc: () => void) => void;
}

export interface SingleSelectProps extends SharedProps {
	value: string | undefined | null;
	onChange?: (value: string | undefined) => void;
}

export interface MultiSelectProps extends SharedProps {
	value: string[] | undefined | null;
	onChange?: (value: string[]) => void;
}

const BaseSelect = (props: SelectProps) => {
	const classes = useStyles(props);
	const { customClasses } = props;

	const [isOpen, setIsOpen] = useState(false);

	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);

	const handleSelect = (e: SelectChangeEvent<string | string[]>) => {
		props.handleSelect(e, handleClose);
	};

	const DropdownIcon = () => (
		<Icon className={classes.dropdownIcon}>
			<RiArrowDownSLine size={24} />
		</Icon>
	);

	const renderIndicator = (selected: boolean) => {
		switch (props.indicators) {
			case 'checkbox': {
				return <Checkbox checked={selected} />;
			}
			case 'checkmark': {
				return selected ? <RiCheckLine fontSize={20} /> : null;
			}
			default:
				return null;
		}
	};

	const renderMenuItem = (option: SelectOption) => {
		const selected =
			option.id !== '__EMPTY__'
				? props.value?.includes(option.id) === true
				: !props.value || props.value?.length === 0;
		return (
			<MenuItem key={option.id} value={option.id}>
				{!!props.indicators && props.indicators !== 'none' && (
					<ListItemIcon>{renderIndicator(selected)}</ListItemIcon>
				)}
				<ListItemText
					primary={
						<Box className={classNames(classes.menuItemLabel, customClasses?.option)}>
							<Typography
								variant="body1"
								className={classNames(classes.primaryLabel, customClasses?.optionLabel)}
							>
								{option.label}
							</Typography>
							{option.rightContent && (
								<Typography variant="body2" className={classNames(classes.secondaryLabel)}>
									{option.rightContentLoading ? (
										<Skeleton animation="wave" width={50} />
									) : (
										option.rightContent
									)}
								</Typography>
							)}
						</Box>
					}
					secondary={option.description}
					secondaryTypographyProps={{ className: customClasses?.optionDescription }}
				/>
			</MenuItem>
		);
	};

	const renderValue = (_value: unknown) => {
		const value = _value as string | string[] | undefined;
		if (Array.isArray(value)) {
			if (value.length === 0) return props.emptyLabel;
			return value.map((id) => props.options.find((option) => option.id === id)?.label).join(', ');
		} else {
			if (!value) return props.emptyLabel;
			const selectedOption = props.options.find((option) => option.id === value);
			return (
				<ListItemText
					primary={
						<Box className={classNames(classes.menuItemLabel)}>
							<Typography
								variant="body1"
								className={classNames(
									classes.primaryLabel,
									classes.primaryValueLabel,
									customClasses?.selectedValue,
								)}
							>
								{selectedOption?.label ?? props.emptyLabel}
							</Typography>
							{selectedOption?.rightContent && (
								<Typography variant="body2" className={classNames(classes.secondaryLabel)}>
									{selectedOption.rightContentLoading ? (
										<Skeleton animation="wave" width={50} />
									) : (
										selectedOption.rightContent
									)}
								</Typography>
							)}
						</Box>
					}
				/>
			);
		}
	};

	return (
		<FormControl fullWidth={props.variant !== 'minimal'} className={classes.formControl}>
			{!!props.label && (
				<Typography variant="button" className={classes.inputLabel}>
					{props.label}
				</Typography>
			)}
			<Select
				fullWidth
				variant="standard"
				disabled={props.disabled}
				multiple={props.multiple}
				disableUnderline
				open={isOpen}
				onOpen={handleOpen}
				onClose={handleClose}
				value={props.value ? props.value : props.multiple ? [] : ''}
				onChange={handleSelect}
				className={classes.select}
				displayEmpty
				renderValue={renderValue}
				inputProps={{
					className: classes.selectInput,
					classes: {
						disabled: classes.selectInputDisabled,
					},
					readOnly: props.hideDropdown,
				}}
				IconComponent={props.IconComponent ?? DropdownIcon}
				MenuProps={{
					anchorOrigin: {
						horizontal: 'center',
						vertical: 'bottom',
					},
					disableAutoFocus: isRunInIframe(),
					MenuListProps: {
						autoFocusItem: isRunInIframe() ? false : true,
					},
				}}
			>
				{!props.hideEmptyOption &&
					renderMenuItem({ id: '__EMPTY__', label: props.emptyLabel ?? '' })}
				{props.options.map((option) => renderMenuItem(option))}
			</Select>
		</FormControl>
	);
};

const SingleSelect = (props: SingleSelectProps) => {
	const { onChange, ...restProps } = props;

	const handleSelect = (e: SelectChangeEvent<string | string[]>, close: () => void) => {
		const value = e.target.value as string;

		if (value === '__EMPTY__') {
			onChange?.(undefined);
			close();
		} else {
			onChange?.(value);
		}
	};

	return (
		<BaseSelect
			{...restProps}
			indicators={props.indicators ?? 'checkmark'}
			handleSelect={handleSelect}
			multiple={false}
			disabled={props.disabled}
		/>
	);
};

const MultiSelect = (props: MultiSelectProps) => {
	const { onChange, ...restProps } = props;

	const handleSelect = (e: SelectChangeEvent<string | string[]>, close: () => void) => {
		const value = (e.target.value as unknown) as string[];

		if (
			value.includes('__EMPTY__') ||
			value.length === 0 ||
			value.length === props.options.length
		) {
			close();
			onChange?.([]);
		} else {
			onChange?.(value);
		}
	};

	return (
		<BaseSelect
			{...restProps}
			indicators={props.indicators ?? 'checkbox'}
			handleSelect={handleSelect}
			multiple={true}
			disabled={props.disabled}
		/>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		select: {
			border: (props: SharedProps) => (props.variant === 'minimal' ? 'none' : '1px solid black'),
			height: (props: SharedProps) => (props.variant === 'minimal' ? 'auto' : '40px'),
			opacity: (props: SharedProps) => (props.disabled ? 0.4 : 1),
		},
		selectInput: {
			padding: `${theme.spacing(1, 7, 1, 2)} !important`,
			fontSize: '1.6rem',
			[theme.breakpoints.down('md')]: {
				padding: `${theme.spacing(1, 6, 1, 2)} !important`,
			},
		},
		selectInputDisabled: {
			color: theme.palette.text.primary,
		},
		menuItemLabel: {
			display: 'flex',
			justifyContent: 'space-between',
			whiteSpace: 'normal',
			alignItems: 'baseline',
		},
		primaryLabel: {
			lineHeight: 'normal',
			flex: 1,
		},
		primaryValueLabel: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		secondaryLabel: {
			flex: 0,
			whiteSpace: 'nowrap',
			paddingLeft: theme.spacing(2),
		},
		inputLabel: {
			marginBottom: theme.spacing(1),
			fontSize: '1.2rem',
		},
		formControl: {
			maxWidth: (props: SharedProps) => props.maxWidth ?? 'none',
			width: '100%',
		},
		icon: {
			width: (props: SharedProps) => (props.variant === 'minimal' ? 24 : 18),
			color: 'black',
			fontSize: '2.4rem',
			marginRight: theme.spacing(1.5),
			position: 'relative',
			left: (props: SharedProps) => (props.variant === 'minimal' ? -16 : 0),
		},
		listItemIconSelected: {
			color: theme.palette.primary.main,
		},
		dropdownIcon: {
			fontSize: '2.4rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			pointerEvents: 'none',
			position: 'absolute',
			right: theme.spacing(1.5),
		},
	}),
);

const Selects = {
	Single: SingleSelect,
	Multi: MultiSelect,
};

export default Selects;
