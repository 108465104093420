import { TFunction } from 'i18next';

import { getFunctionsBaseUrl } from 'common/api/configs';
import {
	AdyenPaymentDetails,
	AdyenPaymentRequest,
	AdyenRedirectDetails,
	CreateOnlineOrder,
	VerifyOnlinePayment,
} from 'common/api/db/functions/_callable/orders/online';
import { Callable } from 'common/frontend/callable';
import { AdyenPaymentMethod, OnlinePaymentMethod } from 'common/modules/payments/types';
import errorHandler from 'common/services/errorHandling/errorHandler';
import {
	AdditionalDetailRedirectRequest,
	AdditionalDetailRequest,
	Environment,
	Languages,
	OnlineOrderRequest,
	OrderObject,
	PaymentMethodData,
} from 'common/types';
import { StorageKeys, Storages, getBrowserInfo, getFromStorage } from 'common/utils/frontUtils';

import { getRedirectUrl } from '../../../utils';

const functionsBaseUrl = getFunctionsBaseUrl(process.env.REACT_APP_ENV as Environment);

export type AdyenPaymentResult =
	| {
			success: true;
			action?: object;
			order?: OrderObject;
	  }
	| {
			success: false;
			error: { methodId: OnlinePaymentMethod | null; error: string };
	  };

interface PaymentArgs {
	paymentMethodData: PaymentMethodData;
	paymentMethodName: AdyenPaymentMethod;
	orderId: string;
	transactionId: string;
	onlineOrderRequest: OnlineOrderRequest;
	userLanguage: Languages;
	t: TFunction;
}

export const adyenPayment = async ({
	paymentMethodData,
	paymentMethodName,
	orderId,
	transactionId,
	userLanguage,
	onlineOrderRequest,
	t,
}: PaymentArgs): Promise<AdyenPaymentResult> => {
	const clientPaymentData: AdyenPaymentRequest = {
		provider: 'ADYEN',
		method: paymentMethodName,
		paymentMethodData: paymentMethodData,
		returnUrl: `${functionsBaseUrl}/api/v1/transaction-redirect/${transactionId}?redirectUrl=${encodeURIComponent(
			getRedirectUrl({ orderId, transactionId }),
		)}`,
		origin: window.location.origin,
		browserInfo: getBrowserInfo(window),
		transactionId,
	};
	const data: CreateOnlineOrder = {
		order: onlineOrderRequest.order,
		shopId: onlineOrderRequest.shopId,
		clientPaymentData,
		userLanguage,
	};
	const paymentError = t('common:errors.paymentError', 'Error processing payment');
	try {
		const result = await Callable.orders.online.create(data);
		const providerResult = result.providerResult;
		if (result.status === 'SUCCESS') {
			return { success: true, order: result.order };
		}
		if (providerResult?.provider !== 'ADYEN') {
			throw new Error(paymentError);
		}
		if (result.status === 'REQUIRES_ACTION') {
			return {
				success: true,
				action: providerResult.action,
				order: undefined,
			};
		}
		return {
			success: false,
			error: { methodId: paymentMethodName, error: providerResult?.refusalReason ?? paymentError },
		};
	} catch (e) {
		errorHandler.report(e);
		return {
			success: false,
			error: { methodId: paymentMethodName, error: paymentError },
		};
	}
};

interface AdditionalDetailsArgs {
	data: AdditionalDetailRequest | AdditionalDetailRedirectRequest;
	t: TFunction;
}

export const adyenPaymentWithAdditionalDetails = async ({
	data,
	t,
}: AdditionalDetailsArgs): Promise<AdyenPaymentResult> => {
	const sessionPaymentMethod = getFromStorage(
		Storages.SESSION,
		StorageKeys.PAYMENT_METHOD,
	) as OnlinePaymentMethod | null;
	const adyenDetails: AdyenPaymentDetails | AdyenRedirectDetails = isRedirectRequest(data)
		? { provider: 'ADYEN', redirect: true }
		: { provider: 'ADYEN', details: data.additionalDetails };
	const paymentError = t('common:errors.paymentError', 'Error processing payment');
	try {
		const request: VerifyOnlinePayment = {
			orderId: data.orderId,
			transactionId: data.transactionId,
			returnUrl: data.returnUrl,
			origin: data.origin,
			paymentDetails: adyenDetails,
			userLanguage: data.userLanguage,
		};
		const result = await Callable.orders.online.verify(request);
		if (result.status === 'SUCCESS') {
			return {
				success: true,
				order: result.order,
			};
		}
		if (result.providerResult?.provider !== 'ADYEN') {
			throw new Error(paymentError);
		}
		if (result.status === 'REQUIRES_ACTION' && !!result.providerResult?.action) {
			return {
				success: true,
				action: result.providerResult?.action,
			};
		}
		return {
			success: false,
			error: {
				methodId: sessionPaymentMethod,
				error:
					result.providerResult?.refusalReason ??
					t('common:errors.paymentError', 'Error processing payment'),
			},
		};
	} catch (e) {
		errorHandler.report(e);
		return {
			success: false,
			error: {
				methodId: sessionPaymentMethod,
				error: t('common:errors.paymentError', 'Error processing payment'),
			},
		};
	}
};

export const isRedirectRequest = (
	request: AdditionalDetailRequest | AdditionalDetailRedirectRequest,
): request is AdditionalDetailRedirectRequest => {
	return !!(request as AdditionalDetailRedirectRequest).redirect;
};
