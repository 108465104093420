import 'common/services/cookieConsent/styleOverrides.css';
import { useCallback, useEffect, useRef, useState } from 'react';

import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';

import { executeCookieQueue } from 'common/frontend/helpers/CookieQueue';
import {
	CookieParameters,
	CookieScriptCategories,
	CookieScriptCategory,
	CookieScriptService,
	CookieShortCode,
	allCookieScriptCategories,
	getRentleCookieConsentForShop,
	updateRentleCookieConsents,
} from 'common/services/cookieConsent';
import { isRunInIframe } from 'common/utils/browserUtils';
import { notNull } from 'common/utils/common';

import useSearchParams from './useSearchParams';

declare var CookieScript: CookieScriptService;
const MAIN_COOKIE_DOMAIN = 'rentle.store';

export const useCookieConsent = () => {
	const [cookieConsentInitialized, setCookieConsentInitialized] = useState<boolean>(false);
	const shopPublicInfo = useSelector(ShopSelectors.shopPublicInfo);
	const shopUrlsData = useSelector(ShopSelectors.shopUrlsData);
	const searchParams = useSearchParams();
	const cookieConsentParams = searchParams.value.get('cookies');
	const cookieConsentSpecialUpdateType = useRef<'parameter' | 'consent-reset' | null>(null);
	const previousConsentTimestamp = useRef<string | null>(null);
	const shopUrlPath = shopUrlsData?.storeUrl;

	const triggerCookieConsentChanged = useCallback(() => {
		/**
		 * This is used because CookieScript might get the default consent if you visited some other rentle.store url. But we do not want to save other store's consent for the current store.
		 * That's why we might pass this "consent-reset" update type, which means that no other consentChange action should be done yet
		 */
		if (cookieConsentSpecialUpdateType.current === 'consent-reset') return;
		handleCookieConsentChanged({
			shopUrlPath,
			cookieConsentType:
				cookieConsentSpecialUpdateType.current === 'parameter' ? 'parameter' : 'banner',
			consentTimestamp: previousConsentTimestamp.current ?? new Date().toISOString(),
		});
		cookieConsentSpecialUpdateType.current = null;
		previousConsentTimestamp.current = null;
	}, [shopUrlPath]);

	/**
	 * Add a listener to hide and set initialized the domain banner when loaded
	 */
	useEffect(() => {
		let observer: MutationObserver | undefined = undefined;
		const handleCookieConsentLoaded = () => {
			CookieScript.instance.hide();
			setCookieConsentInitialized(true);
			observer = observeAndUpdateCookieConsentTableDomains();
		};
		window.addEventListener('CookieScriptLoaded', handleCookieConsentLoaded, {
			once: true,
		});
		return () => {
			window.removeEventListener('CookieScriptLoaded', handleCookieConsentLoaded);
			observer?.disconnect();
		};
	}, []);

	/**
	 * Add listeners for updating cookie consent
	 */
	useEffect(() => {
		const setCookieConsentChangeListeners = () => {
			CookieScript.instance.onAcceptAll = () => {
				triggerCookieConsentChanged();
			};
			CookieScript.instance.onAccept = () => {
				triggerCookieConsentChanged();
			};
			CookieScript.instance.onReject = () => {
				triggerCookieConsentChanged();
			};
			CookieScript.instance.onClose = () => {
				triggerCookieConsentChanged();
			};
		};
		setCookieConsentChangeListeners();
	}, [triggerCookieConsentChanged]);

	/**
	 * Add cookie consent from query parameters if existing, or shows cookie consent based on store settings
	 */
	useEffect(() => {
		if (
			!cookieConsentInitialized ||
			!shopPublicInfo.data ||
			!shopUrlPath ||
			// If we are still processing the query param consent, ignore the effect
			cookieConsentSpecialUpdateType.current === 'parameter'
		) {
			return;
		}
		const consentFromQueryParams = getCookieCategoriesFromQueryParams(cookieConsentParams);
		if (!!consentFromQueryParams) {
			cookieConsentSpecialUpdateType.current = 'parameter';
			CookieScript.instance.acceptAction(consentFromQueryParams);
			return;
		}
		// If cookie response has already been given - set that one as the content
		const previousConsent = getRentleCookieConsentForShop(shopUrlPath);
		if (!!previousConsent) {
			const currentConsent = CookieScript.instance.currentState();
			previousConsentTimestamp.current = previousConsent.timestamp ?? null;
			cookieConsentSpecialUpdateType.current =
				previousConsent.type === 'parameter' ? 'parameter' : null;
			if (
				!!currentConsent.action &&
				currentConsent.key === previousConsent.key &&
				isEqual(currentConsent.categories, previousConsent.categories)
			) {
				triggerCookieConsentChanged();
				return;
			}
			CookieScript.instance.acceptAction(previousConsent.categories);
			return;
		}
		const isEmbedded = isRunInIframe();
		const cookieSetting = isEmbedded
			? shopPublicInfo.data.cookieSettings?.embed
			: shopPublicInfo.data.cookieSettings?.default;
		if (cookieSetting === 'SHOW_NOTICE') {
			if (!CookieScript.instance.currentState().action) {
				CookieScript.instance.show();
			} else {
				cookieConsentSpecialUpdateType.current = 'consent-reset';
				CookieScript.instance.rejectAllAction();
				cookieConsentSpecialUpdateType.current = null;
				CookieScript.instance.show();
			}
			return;
		}
		if (cookieSetting === 'DISABLE') {
			CookieScript.instance.rejectAllAction();
		} else {
			CookieScript.instance.acceptAllAction();
		}
	}, [
		cookieConsentInitialized,
		cookieConsentParams,
		shopPublicInfo.data,
		shopUrlPath,
		triggerCookieConsentChanged,
	]);
};

const handleCookieConsentChanged = (args: {
	shopUrlPath: string | undefined;
	cookieConsentType: 'banner' | 'parameter';
	consentTimestamp: string;
}) => {
	const { shopUrlPath, cookieConsentType, consentTimestamp } = args;
	if (!shopUrlPath) {
		return;
	}
	const consent = CookieScript.instance.currentState();
	// Otherwise, we get the updated consent and save it to local storage
	updateRentleCookieConsents(shopUrlPath, consent, cookieConsentType, consentTimestamp);
	executeCookieQueue(consent);
	// CookieScript "currentState" returns old key if called synchronously, so we re-update the consent to Rentle local storage after 1sec
	setTimeout(() => {
		const updatedConsent = CookieScript.instance.currentState();
		if (!!consent.action && !!updatedConsent.action && consent.key !== updatedConsent.key) {
			updateRentleCookieConsents(shopUrlPath, updatedConsent, cookieConsentType, consentTimestamp);
		}
	}, 1000);
};

const observeAndUpdateCookieConsentTableDomains = () => {
	const observer = new MutationObserver(() => {
		const cookieTable = document.querySelector('.cookiescript_fsd_cookies_table');
		if (!cookieTable) return;
		/**
		 * We want to replace all cells that mention rentle.store domain to show the current domain instead
		 * Cookie scanner scans only rentle.store domain, so it shows that as originating domain for the cookies by default
		 */
		const domainElements = document.querySelectorAll(
			'.cookiescript_fsd_cookies_table td:nth-child(2)',
		);
		for (const domainElement of domainElements) {
			if (domainElement.innerHTML.includes(MAIN_COOKIE_DOMAIN)) {
				domainElement.innerHTML = window.location.hostname;
			}
		}
		/**
		 * We want to show all the Cookie descriptions in english only
		 * We can't translate descriptions to all languages and we don't want to use default localized descriptions, because they might be wrong
		 */
		const cookieDescriptionElements = document.querySelectorAll(
			'.cookiescript_fsd_cookies_table td:nth-child(4)',
		);
		for (const cookieDescriptionElement of cookieDescriptionElements) {
			const localeAttribute = cookieDescriptionElement.getAttribute('data-cs-i18n-text');
			if (!localeAttribute) return;
			try {
				const localeJson = JSON.parse(localeAttribute);
				if (!!localeJson?.['en']) {
					cookieDescriptionElement.innerHTML = localeJson?.['en'];
					cookieDescriptionElement.removeAttribute('data-cs-i18n-text');
				}
			} catch {
				return;
			}
		}
	});
	observer.observe(document.body, {
		subtree: false,
		childList: true,
		attributes: false,
		attributeOldValue: false,
		characterData: false,
		characterDataOldValue: false,
	});
	return observer;
};

const getCookieCategoriesFromQueryParams = (
	consentParameters: string | null,
): CookieScriptCategory[] | null => {
	if (!consentParameters) return null;
	if (consentParameters === 'true') {
		return allCookieScriptCategories;
	}
	if (consentParameters === 'false') {
		return [CookieScriptCategories.strict];
	}
	const cookieCategories = consentParameters.split(',');
	const hasFunctionality = hasConsentParameter(CookieParameters.functionality, cookieCategories);
	const hasPerformance = hasConsentParameter(CookieParameters.performance, cookieCategories);
	const hasTargeting = hasConsentParameter(CookieParameters.targeting, cookieCategories);
	const hasUnclassified = hasConsentParameter(CookieParameters.unclassified, cookieCategories);
	return [
		CookieScriptCategories.strict,
		hasFunctionality ? CookieScriptCategories.functionality : null,
		hasPerformance ? CookieScriptCategories.performance : null,
		hasTargeting ? CookieScriptCategories.targeting : null,
		hasUnclassified ? CookieScriptCategories.unclassified : null,
	].filter(notNull);
};

const hasConsentParameter = (code: CookieShortCode, array: string[]) => {
	return array.includes(code);
};
