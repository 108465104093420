import React, { useEffect, useState } from 'react';

import { Box, Dialog, Fade, Grid, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as CartActions from 'redux/actions/cart';
import * as ViewActions from 'redux/actions/view';
import { useDispatch, useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import { usePrevious } from 'common/hooks/usePrevious';
import {
	getDeliveryInventoryBlockersAfter,
	getDeliveryPickupDetails,
} from 'common/modules/delivery/utils';
import { isRunInIframe } from 'common/utils/browserUtils';
import { useAnalytics } from 'hooks/useAnalytics';
import useCartAvailability from 'hooks/useCartAvailability';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';
import { CartRow } from 'services/types';
import {
	getCartProductsWithAvailabilities,
	getCartProductsWithHandlingTimeAfter,
	getCartProductsWithPickupUnavailability,
	getIsCartValid,
} from 'utils/cart';
import { getLatestPossiblePickupslot } from 'utils/delivery';

import CartFooter from './CartFooter';
import CartHeader from './CartHeader';
import CartProducts from './CartProducts';
import CartRecommendedProducts from './CartRecommendedProducts';
import TopCheckoutButton from './TopCheckoutButton';

const isEmbedded = isRunInIframe();

const CartModal = () => {
	const { logEcomEvent } = useAnalytics();
	const { t, getTranslation } = useTranslation();
	const [isValidatingCart, setIsValidatingCart] = useState(false);
	const [hasValidationError, setHasValidationError] = useState(false);
	const [pickupSlotsNotFoundError, setPickupSlotsNotFoundError] = useState(false);
	const dispatch = useDispatch();
	const { pushRoute, Routes } = useRoutes();
	const classes = useStyles();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const productsCount = useSelector(CartSelectors.cartProductsCount);
	const recommendedProducts = useSelector(CartSelectors.recommendedProducts);
	const recommendLiftTicket = useSelector(CartSelectors.recommendLiftTicket);
	const isCartOpen = useSelector(ViewSelectors.cartOpen);
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const locationId = useSelector(CartSelectors.cartLocationId);
	const items = useSelector(CartSelectors.items);
	const cartDelivery = useSelector(CartSelectors.delivery);
	const hasCartDelivery = useSelector(CartSelectors.hasCartDelivery);
	const { isCartValid } = getIsCartValid(items);
	const prevProductsCount = usePrevious(productsCount);
	const taxExcluded = useSelector(ShopSelectors.taxExcluded);
	const currency = useSelector(ShopSelectors.shopCurrency);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const timezone = useSelector(ShopSelectors.shopTimezone);
	const hasProductsToReturn = useSelector(CartSelectors.hasProductsToReturnInCart);

	const showRecommendedProducts = !!recommendedProducts.length || recommendLiftTicket;
	const showTopCheckoutButton =
		isEmbedded &&
		isSmallScreen &&
		((productsCount >= 1 && showRecommendedProducts) || productsCount > 3);

	const {
		getCartRowAvailabilities,
		refreshCartRowAvailabilities,
		getPotentialPickupSlotsForCart,
	} = useCartAvailability({
		cartRows: items,
		locationId,
	});

	useEffect(() => {
		setHasValidationError(false);
		setPickupSlotsNotFoundError(false);
	}, [isCartOpen]);

	useEffect(() => {
		if (isCartValid || prevProductsCount === productsCount) return;
		const refreshCartAvailabilities = async () => {
			const cartRowAvailabilities = await refreshCartRowAvailabilities();
			if (!cartRowAvailabilities) return;
			const updatedCartItems = getCartProductsWithAvailabilities(items, cartRowAvailabilities);
			dispatch(CartActions.updateCartItems(updatedCartItems));
		};
		refreshCartAvailabilities();
	}, [
		productsCount,
		isCartValid,
		refreshCartRowAvailabilities,
		dispatch,
		prevProductsCount,
		items,
	]);

	useEffect(() => {
		if (isCartOpen) {
			logEcomEvent('view_cart', {});
		}
	}, [isCartOpen, logEcomEvent]);

	useEffect(() => {
		if (hasCartDelivery && productsCount === 0) {
			dispatch(CartActions.removeDelivery());
		}
	}, [dispatch, hasCartDelivery, productsCount]);

	const getPotentialPickupSlots = async () => {
		if (!selectedDeliveryOption || !hasCartDelivery || !hasProductsToReturn) return [];
		const slots = await getPotentialPickupSlotsForCart(cartDelivery);
		return slots ?? [];
	};

	const validateCart = async (goToCheckout?: boolean) => {
		setIsValidatingCart(true);
		setHasValidationError(false);
		setPickupSlotsNotFoundError(false);

		try {
			const cartRowAvailabilities = await getCartRowAvailabilities();
			if (!cartRowAvailabilities) {
				setIsValidatingCart(false);
				return;
			}

			let updatedCartRows: CartRow[] = items;
			let shouldUpdateCartInRedux: boolean = true;

			if (!!selectedDeliveryOption) {
				switch (selectedDeliveryOption.type) {
					case 'DELIVERY_AND_OPTIONAL_PICKUP': {
						const potentialPickupSlots = await getPotentialPickupSlots();
						const latestPossiblePickupSlot = getLatestPossiblePickupslot(potentialPickupSlots);
						dispatch(ViewActions.setAvailablePickupSlots(potentialPickupSlots));

						if (!!latestPossiblePickupSlot) {
							updatedCartRows = getCartProductsWithPickupUnavailability({
								cartRows: items,
								pickupTimeslot: latestPossiblePickupSlot,
								selectedDeliveryOption,
								openingHours,
								timezone,
							});
							shouldUpdateCartInRedux = false;
						}
						break;
					}
					case 'DELIVERY_AND_PICKUP': {
						if (!hasProductsToReturn) {
							break;
						}
						const potentialPickupSlots = await getPotentialPickupSlots();
						const latestPossiblePickupSlot = getLatestPossiblePickupslot(potentialPickupSlots);
						dispatch(ViewActions.setAvailablePickupSlots(potentialPickupSlots));

						if (!!latestPossiblePickupSlot) {
							updatedCartRows = getCartProductsWithPickupUnavailability({
								cartRows: items,
								pickupTimeslot: latestPossiblePickupSlot,
								selectedDeliveryOption,
								openingHours,
								timezone,
							});

							if (!!selectedDeliveryOption && !!cartDelivery?.from) {
								const from = getDeliveryPickupDetails({
									deliveryOption: selectedDeliveryOption,
									options: {
										taxExcluded,
										currency: currency.code,
										getTranslation,
										openingHours,
										timezone,
										t,
									},
								});

								if (!!from) {
									dispatch(
										CartActions.setDelivery({
											...cartDelivery,
											from: {
												...from,
												timeslot: latestPossiblePickupSlot,
											},
										}),
									);
								}
							}
						} else {
							setPickupSlotsNotFoundError(true);
							throw new Error('No pickup slots found');
						}
						break;
					}
					case 'DELIVERY_ONLY': {
						const handlingMinutesAfter = !!cartDelivery
							? getDeliveryInventoryBlockersAfter({
									deliveryOption: selectedDeliveryOption,
									pickupSlotOrEndDate: items[0]?.endDate ?? null,
									openingHours,
									timezone,
							  }).deliveryHandlingAfter ?? 0
							: 0;
						updatedCartRows = getCartProductsWithHandlingTimeAfter(items, handlingMinutesAfter);
						break;
					}
					default:
						break;
				}
			}

			const cartProductsWithAvailabilities = getCartProductsWithAvailabilities(
				updatedCartRows,
				cartRowAvailabilities,
			);

			const { isCartValid } = getIsCartValid(cartProductsWithAvailabilities);
			setIsValidatingCart(false);
			if (!isCartValid) {
				dispatch(CartActions.updateCartItems(cartProductsWithAvailabilities));
			} else if (goToCheckout && isCartValid) {
				dispatch(ViewActions.setCartOpen(false));
				if (shouldUpdateCartInRedux) {
					dispatch(CartActions.updateCartItems(cartProductsWithAvailabilities));
				}
				await dispatch(
					CartActions.createReservation({
						cartItems: cartProductsWithAvailabilities,
					}),
				);

				logEcomEvent('begin_checkout', {});
				pushRoute(Routes.checkout);
			}
		} catch (e) {
			setHasValidationError(true);
			setIsValidatingCart(false);
		}
	};

	return (
		<Dialog
			open={isCartOpen}
			onClose={() => dispatch(ViewActions.setCartOpen(false))}
			TransitionComponent={Fade}
			BackdropProps={{
				className: classes.dialogBackdrop,
			}}
			PaperProps={{
				className: classes.dialogPaper,
				//@ts-ignore
				'data-iframe-height': true,
			}}
			classes={{
				container: classes.dialogContainer,
			}}
			aria-labelledby="cart-modal-title"
			fullWidth={isSmallScreen}
		>
			<Box className={classes.dialogContent} onClick={(e) => e.stopPropagation()}>
				<Grid container className={classes.grid}>
					{!isSmallScreen && showRecommendedProducts && (
						<Grid item xs={12} md={6} className={classes.gridLeft}>
							<CartRecommendedProducts />
						</Grid>
					)}
					<Grid item xs={12} md="auto" className={classes.gridRight}>
						<Box flex={1} display="flex" flexDirection="column">
							<Box className={classes.scrollableDialogContent}>
								<Box className={classes.stickyCartHeader}>
									<CartHeader />
								</Box>
								<Box p={3} mb={4}>
									{showTopCheckoutButton && (
										<Box mb={2}>
											<TopCheckoutButton
												disabled={!isCartValid}
												loading={isValidatingCart}
												onClick={() => validateCart(true)}
											/>
										</Box>
									)}
									<CartProducts />
								</Box>
								{showRecommendedProducts && isSmallScreen && (
									<Box my={4} display={{ xs: 'block', md: 'none' }}>
										<CartRecommendedProducts />
									</Box>
								)}
							</Box>
							<CartFooter
								onCheckoutButtonClick={() => validateCart(true)}
								isCartValid={isCartValid}
								isCartValidating={isValidatingCart}
								hasValidationError={hasValidationError}
								hasPickupSlotsNotFoundError={pickupSlotsNotFoundError}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogBackdrop: {
			background: 'rgba(0,0,0,0.2)',
		},
		dialogContainer: {
			flexDirection: 'row',
			alignItems: 'flex-start',
			justifyContent: 'flex-end',
			margin: 0,
			padding: 0,
			[theme.breakpoints.up('md')]: {
				margin: '0 auto',
				maxWidth: theme.breakpoints.values.lg + 60,
			},
		},
		dialogPaper: {
			margin: 0,
			width: '100%',
			maxWidth: theme.breakpoints.values.lg,
			maxHeight: isEmbedded ? '100vh' : '100%',
			minHeight: isEmbedded ? 0 : '100%',
			overflow: 'hidden',
			[theme.breakpoints.up('md')]: {
				width: 'auto',
				margin: theme.spacing(4),
				maxHeight: `calc(100vh - ${theme.spacing(8)})`,
				minHeight: 0,
			},
		},
		dialogContent: {
			display: 'flex',
			overflow: 'auto',
			flex: '1',
		},
		scrollableDialogContent: {
			flex: 1,
			overflowY: 'auto',
			position: 'relative',
		},
		stickyCartHeader: {
			position: 'sticky',
			top: 0,
			background: 'white',
			zIndex: 100,
		},
		grid: {
			flex: 1,
			flexWrap: 'nowrap',
		},
		gridRight: {
			order: 1,
			flex: 1,
			display: 'flex',
			minHeight: '100%',
			overflow: 'hidden',
			[theme.breakpoints.up('md')]: {
				order: 2,
				minWidth: 440,
			},
			[theme.breakpoints.up('lg')]: {
				minWidth: 500,
			},
		},
		gridLeft: {
			order: 2,
			minHeight: '100%',
			overflow: 'auto',
			display: 'flex',
			background: '#f9f9f9',
			[theme.breakpoints.up('md')]: {
				order: 1,
				width: `max(calc((100vw - 56px) / 2), 640px)`,
			},
		},
	}),
);
export default CartModal;
