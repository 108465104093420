import React from 'react';

import { FormControl, InputLabel, MenuItem, Select, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { upperFirst } from 'lodash';

import { Shopper } from 'common/types';
import { getSegmentTranslation } from 'common/utils/translations';
import { useTranslation } from 'services/localization/useTranslation';

import { updateShopperProperty } from '../utils/shopperUtils';

interface Props {
	shopper: Shopper;
	segments: string[];
	updateShopper: (shopper: Shopper) => void;
	segmentDisabled: (segment: string) => boolean;
}

const SegmentsDropDown = (props: Props) => {
	const classes = useStyles();
	const { shopper, segments, updateShopper, segmentDisabled } = props;
	const { t } = useTranslation();

	return (
		<FormControl className={classes.segmentDropdown}>
			<InputLabel htmlFor="age-native-simple">{t('shopperInfo.segment', 'Segment')}</InputLabel>
			<Select
				variant="standard"
				value={shopper.segment ? shopper.segment : 'regular'}
				onChange={(e) => updateShopper(updateShopperProperty(e, shopper, 'segment'))}
				inputProps={{
					name: 'segment',
					id: 'age-native-simple',
				}}
			>
				<MenuItem value={'regular'}>
					{upperFirst(t('common:ageGroups.regular', 'Regular'))}
				</MenuItem>
				{segments.map((segment, idx) => (
					<MenuItem
						key={`segment${idx}`}
						value={segment ? segment : ''}
						disabled={segmentDisabled(segment)}
					>
						{upperFirst(getSegmentTranslation(segment, t))}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		segmentDropdown: {
			display: 'flex',
			width: '100%',
		},
	}),
);

export default SegmentsDropDown;
