import React from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { RiArrowLeftLine } from 'react-icons/ri';
import { Link, Route, Switch } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import { isRunInIframe } from 'common/utils/browserUtils';
import CenteredContent from 'components/CenteredContent';
import ReservationExpireBanner from 'components/Reservation/ReservationExpireBanner';
import ReservationTimer from 'components/Reservation/ReservationTimer';
import AuthErrorBanner from 'components/auth/AuthErrorBanner';
import useExternalAuth from 'hooks/useExternalAuth';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';

import NavEmbedContent from './NavEmbedContent';
import NavMainContent from './NavMainContent';
import NavTopContent from './NavTopContent';

const Nav = () => {
	const { getRoute, Routes } = useRoutes();

	return (
		<>
			<Switch>
				<Route path={getRoute(Routes.checkout)} component={CheckoutNav} />
				<Route path={getRoute(Routes.product)} component={ProductPageNav} />
				<Route path={getRoute(Routes.tickets)} component={ProductPageNav} />
				<Route path={getRoute(Routes.packageTicket)} component={ProductPageNav} />
				<Route path={getRoute(Routes.confirm)} component={ConfirmNav} />
				<Route path={getRoute(Routes.locations)} component={StoresPageNav} />
				<Route component={DefaultNav} />
			</Switch>
			<AuthErrorBanner />
			<Switch>
				<Route path={getRoute(Routes.checkout)} component={ReservationTimer} />
				<Route path={getRoute(Routes.confirm)} component={() => null} />
				<Route component={ReservationExpireBanner} />
			</Switch>
		</>
	);
};

const DefaultNav = () => {
	const classes = useStyles();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
	const isEmbedded = isRunInIframe();
	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const { isAuthInUse } = useExternalAuth();

	if (!activeLocation) {
		if (isMobile || isEmbedded) return null;
		return <NavTopContent isMobile={false} />;
	}

	if (isEmbedded)
		return <EmbedNav isMobile={isMobile} sticky={true} showCart showLogin={!!isAuthInUse} />;

	return (
		<Box className={classNames(classes.nav)}>
			<NavTopContent isMobile={isMobile} borderBottom />
			<NavMainContent
				isMobile={isMobile}
				paddingBottom={isMobile ? 2 : 4}
				paddingTop={isMobile ? 2 : 4}
				containsCart
				showLogin={!!isAuthInUse && !isMobile}
			/>
		</Box>
	);
};

interface EmbedNavProps {
	isMobile: boolean;
	backLink?: string | (() => void);
	showCart: boolean;
	sticky: boolean;
	noBorder?: boolean;
	showCenterContent?: boolean;
	hideBackLink?: boolean;
	showLogin?: boolean;
}

const EmbedNav = (props: EmbedNavProps) => {
	const { isMobile, backLink, showCart, sticky, hideBackLink, showLogin } = props;
	const classes = useStyles();

	return (
		<Box
			className={classNames(classes.nav, {
				[classes.stickyNav]: sticky,
			})}
		>
			<NavEmbedContent
				isMobile={isMobile}
				showCart={showCart}
				backLink={backLink}
				hideBackLink={hideBackLink}
				showLogin={showLogin}
			/>
		</Box>
	);
};

const StoresPageNav = () => {
	const { goBack } = useRoutes();
	const theme = useTheme();
	const isEmbedded = isRunInIframe();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	return isEmbedded ? (
		<EmbedNav isMobile={isMobile} sticky={true} showCart backLink={goBack} showLogin={false} />
	) : (
		<DefaultNav />
	);
};

const ProductPageNav = () => {
	const classes = useStyles();
	const { getPath, Routes } = useRoutes();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
	const isEmbedded = isRunInIframe();

	const category = useSelector(ViewSelectors.filtersCategory) ?? undefined;
	const productType = useSelector(ViewSelectors.filtersProductType) ?? undefined;
	const backLink = getPath(Routes.browse, { query: { category: category, productType } });
	const { isAuthInUse } = useExternalAuth();

	return isEmbedded ? (
		<EmbedNav
			isMobile={isMobile}
			sticky={true}
			showCart
			backLink={backLink}
			showLogin={!!isAuthInUse}
		/>
	) : isMobile ? (
		<Box className={classes.nav}>
			<NavTopContent isMobile borderBottom />
			<NavMainContent
				isMobile={isMobile}
				paddingBottom={2}
				paddingTop={2}
				containsCart
				mobileBackTo={backLink}
				showLogin={isAuthInUse && !isMobile}
			/>
		</Box>
	) : (
		<DefaultNav />
	);
};

const CheckoutNav = () => {
	const { t } = useTranslation();
	const { getPath, Routes } = useRoutes();
	const theme = useTheme();
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const handlePaymentRedirect = useSelector(CheckoutSelectors.handlePaymentRedirect);

	return (
		<Box py={isMobile ? 2 : 3} className={classNames(classes.nav, classes.borderBottom)}>
			<CenteredContent innerClassName={classes.flexContent}>
				{!handlePaymentRedirect && (
					<Link to={getPath(Routes.browse)} className={classes.backLink}>
						<RiArrowLeftLine color="black" size={24} />
						<Box mr={0.5} />
						<Typography variant="body1">{t('common:actions.goBack', 'Go back')}</Typography>
					</Link>
				)}
			</CenteredContent>
		</Box>
	);
};

const ConfirmNav = () => {
	return <NavTopContent hideLocation />;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		nav: {
			zIndex: theme.zIndex.appBar,
			background: '#ffffff',
			position: 'relative',
		},
		borderBottom: {
			borderBottom: `1px solid ${theme.palette.border.paper}`,
		},
		backLink: {
			display: 'inline-flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		stickyNav: {
			position: 'sticky',
			top: 0,
		},
		flexContent: {
			display: 'flex',
		},
	}),
);

export default Nav;
