import { partition } from 'lodash';
import { IconType } from 'react-icons/lib';
import { RiCheckboxMultipleLine } from 'react-icons/ri';
import { isVariantInStock } from 'views/Shop/Location/Product/utils';

import {
	AvailabilityRange,
	getInitialAvailabilityRange,
	getStartAndEndTimeFromRanges,
	mergeAvailabilityRangeToRanges,
} from 'common/api/frontend/inventory_new/availabilityRange';
import productPlaceholder from 'common/assets/product-placeholder.png';
import ticketPlaceholder from 'common/assets/ticket-placeholder.png';
import {
	getDeliveryInventoryBlockersAfter,
	getDeliveryInventoryBlockersBefore,
} from 'common/modules/delivery/utils';
import { getInventoryBlocker } from 'common/modules/inventoryBlockers';
import { OpeningHours } from 'common/modules/openingHours';
import { getProductsInPackage, isPackageProduct } from 'common/modules/products/utils';
import { getTranslation } from 'common/modules/translations';
import {
	ByVariant,
	CartDelivery,
	CartProduct,
	Category,
	CurrencyObject,
	DeliveryOption,
	Languages,
	LoadingData,
	OrderDeliveryDetails,
	OrderProduct,
	PricingTableObject,
	ProductApi,
} from 'common/types';
import { notFalsey, notNullish } from 'common/utils/common';
import { isLiftTicketProduct } from 'common/utils/liftTicketUtils';
import { getFromPriceString } from 'common/utils/pricing';
import { getProductImageSrc, getVariantName } from 'common/utils/productUtils';
import { t } from 'services/localization';
import * as RouteUtils from 'services/routing/utils';
import { ProductTag, ProductType } from 'services/types';

import { getLiftTicketsFromPriceString } from './liftTicket';

export const getProductImage = (product: ProductApi | OrderProduct | CartProduct) =>
	getProductImageSrc(product) ??
	(isLiftTicketProduct(product) && !product.set ? ticketPlaceholder : productPlaceholder);

export const getCategoryImage = (category: Category) => category.imageUrl ?? productPlaceholder;

export const getProductImages = (product: ProductApi | OrderProduct | CartProduct) =>
	product.images || [getProductImage(product)];

export const getLiftTicketCategoryImageSrc = (liftTicketCategory?: Category) =>
	liftTicketCategory?.imageUrl ?? ticketPlaceholder;

export const getSetTotalQuantity = (variantIds: string[], quantity: ByVariant<number | null>) => {
	const allSetProductQuantities = variantIds.map((id) => quantity[id]);
	if (!allSetProductQuantities.length) return 0;
	const nonNullSetProductQuantities = allSetProductQuantities.filter(notNullish);
	return !nonNullSetProductQuantities.length ? null : Math.min(...nonNullSetProductQuantities);
};

export const getSetTotalAvailability = (
	variantIds: string[],
	availability: ByVariant<AvailabilityRange[]>,
) => {
	const allVariantRanges = variantIds.map((id) => availability[id]).flat();
	const { start, end } = getStartAndEndTimeFromRanges(allVariantRanges);
	if (!start || !end) {
		return [];
	}
	const initialRange = getInitialAvailabilityRange(Infinity, start, end);
	const nonNullVariantRanges = allVariantRanges.filter((a) => notNullish(a.units));
	return nonNullVariantRanges.reduce(
		(total, curr) => mergeAvailabilityRangeToRanges(total, curr, Math.min),
		[initialRange],
	);
};

export const isSetProductInStock = (
	product: ProductApi,
	stockProducts: ProductApi[],
	quantities: LoadingData<ByVariant<number | null>>,
): boolean => {
	const setProducts = getProductsInPackage(product, stockProducts);
	return setProducts.every((setProduct) => isProductInStock(setProduct, quantities));
};

export const isProductInStock = (
	product: ProductApi,
	quantities: LoadingData<ByVariant<number | null>>,
): boolean => {
	return product.variants.options.some((variant) => isVariantInStock(variant.id, quantities));
};

export interface BrowseItemProps {
	name: string;
	imageSrc: string;
	fromPriceString: string;
	linkTo: string;
	onClick: () => void;
}

export const getBrowseProductProps = (args: {
	product: ProductApi;
	pricingTables: PricingTableObject;
	openingHours: OpeningHours;
	currency: CurrencyObject;
	shopName: string;
	timezone: string;
	locationName?: string | null;
	lang: Languages;
}): Omit<BrowseItemProps, 'onClick'> => {
	const {
		product,
		pricingTables,
		openingHours,
		currency,
		shopName,
		locationName,
		timezone,
		lang,
	} = args;
	return {
		name: getTranslation(product.name, lang),
		imageSrc: getProductImage(product),
		linkTo: RouteUtils.getPath({ shopName, locationName })('product', { productId: product.id }),
		fromPriceString: getFromPriceString(
			{
				product,
				pricingTables,
				openingHours,
				currency,
				startDate: null,
				channel: 'ONLINE',
				timezone,
			},
			t,
		),
	};
};

export const getBrowseLiftTicketProps = (args: {
	liftTicketCategory: Category;
	liftTicketProducts: ProductApi[];
	currency: CurrencyObject;
	shopName: string;
	locationName?: string | null;
	lang: Languages;
}): Omit<BrowseItemProps, 'onClick'> => {
	const { liftTicketCategory, liftTicketProducts, currency, shopName, locationName, lang } = args;
	return {
		name: getTranslation(liftTicketCategory.name, lang),
		imageSrc: getLiftTicketCategoryImageSrc(liftTicketCategory),
		linkTo: RouteUtils.getPath({ shopName, locationName })('tickets'),
		fromPriceString: getLiftTicketsFromPriceString(liftTicketProducts, currency),
	};
};

/**
 * Partition sorted products into ones that have the category ordering before and after lift ticket category
 */
export const partitionedProductsByLiftTicket = (
	products: ProductApi[],
	selectedCategories: Category[],
	liftTicketCategory?: Category,
) => {
	const categoryIdsBeforeTicket = selectedCategories
		.filter(
			(cat) => !liftTicketCategory || (cat.orderIndex ?? 0) < (liftTicketCategory.orderIndex ?? 0),
		)
		.map((c) => c.id);
	return partition(products, (p) =>
		p.categoryIds?.some((id) => categoryIdsBeforeTicket.includes(id)),
	);
};

export const getProductVariantText = (product: OrderProduct | CartProduct, language: Languages) => {
	const variantName = getVariantName(product.variant, language) ?? null;
	if (variantName) {
		return variantName;
	}
	if (product.set) {
		const selectedVariantNames = product.setProducts
			.map((setProduct) => getVariantName(setProduct.variant, language) ?? null)
			.filter(notFalsey);
		if (selectedVariantNames.length) {
			return selectedVariantNames.join(' - ');
		}
	}
	return null;
};

export const getProductTagOptions = (): {
	value: ProductTag;
	label: string;
	Icon: IconType;
	validate: (product: ProductApi) => boolean;
}[] => [
	{
		value: 'package',
		label: t('common:tags.completePackage', 'Complete package'),
		Icon: RiCheckboxMultipleLine,
		validate: isPackageProduct,
	},
	// TODO: this will be used after the delivery deployment
	// {
	// 	value: 'delivery',
	// 	label: t('delivery.delivery'),
	// 	Icon: RiTruckLine,
	// 	validate: (product: ProductApi) => true,
	// },
];

export const getProductTagsForProduct = (product: ProductApi) => {
	return getProductTagOptions().filter((tag) => tag.validate(product));
};

export const getProductTypeOptions = (): {
	value: ProductType;
	label: string;
}[] => [
	{
		value: 'package',
		label: t('common:tags.completePackages', 'Complete packages'),
	},
	{
		value: 'single',
		label: t('common:tags.singleProducts', 'Single products'),
	},
];

export const getProductTypeText = (productType: ProductType | null) => {
	return getProductTypeOptions().find((type) => type.value === productType)?.label ?? null;
};

export const isValidProductType = (productType: ProductType | null) => {
	return !productType || getProductTypeOptions().some((type) => type.value === productType);
};

export const hasProductType = (product: ProductApi, productType: ProductType | null) => {
	return (
		!productType ||
		(productType === 'package'
			? isPackageProduct(product)
			: productType === 'single'
			? !isPackageProduct(product)
			: true)
	);
};

export const assignDeliveryOrderUnavailabilities = (args: {
	products: OrderProduct[];
	deliveryOption: DeliveryOption;
	cartDelivery: CartDelivery;
	openingHours: OpeningHours;
	timezone: string;
}) => {
	const { products, deliveryOption, cartDelivery, openingHours, timezone } = args;
	const deliveryTo: OrderDeliveryDetails | null = !!cartDelivery.to
		? {
				...cartDelivery.to,
				handlingTimeMinutes:
					getDeliveryInventoryBlockersBefore({
						deliveryOption,
						deliverySlot: cartDelivery.to?.timeslot ?? null,
						openingHours,
						timezone,
					}).deliveryHandlingBefore ?? 0,
		  }
		: null;

	const deliveryFrom: OrderDeliveryDetails | null = !!cartDelivery.from
		? {
				...cartDelivery.from,
				handlingTimeMinutes:
					getDeliveryInventoryBlockersAfter({
						deliveryOption,
						pickupSlotOrEndDate: cartDelivery.from?.timeslot ?? products[0]?.endDate ?? null,
						openingHours,
						timezone,
					}).deliveryHandlingAfter ?? 0,
		  }
		: null;
	return products.map((product) => {
		const { unavailable } = product;
		return {
			...product,
			unavailable: getInventoryBlocker(unavailable, {
				deliveryDetails: deliveryTo,
				pickupDetails: deliveryFrom,
			}),
		};
	});
};
