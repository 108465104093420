import React from 'react';

import { Box, Collapse, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import TimeChipSelector from 'common/components/TimeChipSelector';
import { timeslotToString } from 'common/modules/delivery/utils';
import { DeliveryTimeSlot } from 'common/types';
import { useTranslation } from 'services/localization';
import { AvailabilityByTimeslot, getTimeslotsWithValidityForDate } from 'utils/delivery';

interface Props {
	date: string | null;
	value: DeliveryTimeSlot | null;
	onChange: (value: DeliveryTimeSlot) => void;
	productId: string;
	availabilityByTimeslot: AvailabilityByTimeslot | null;
	selectedDayString: string | null;
	selectedQuantity: number;
}

const DeliverySlotSelector = (props: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		date,
		value,
		onChange,
		productId,
		availabilityByTimeslot,
		selectedDayString,
		selectedQuantity,
	} = props;

	const shopDateFormat = useSelector(ShopSelectors.shopDateFormat);
	const shopTimezone = useSelector(ShopSelectors.shopTimezone);
	const productsById = useSelector(StockSelectors.productsById);
	const product = productsById[productId];

	const timeslotsWithValidity = getTimeslotsWithValidityForDate(
		availabilityByTimeslot,
		selectedQuantity,
		selectedDayString,
	);

	if (!product || timeslotsWithValidity.length === 0) return null;

	const options = timeslotsWithValidity.map((item) => {
		const { startDate, endDate, disabled, units } = item;

		return {
			label: timeslotToString({
				timeslot: { startDate, endDate },
				shopDateFormat,
				timezone: shopTimezone,
				hideDates: true,
			}),
			value: `${startDate}_${endDate}`,
			disabled: disabled || (units !== null && units <= 0),
		};
	});

	return (
		<Collapse in={!!date}>
			<Box pt={2}>
				<Box className={classes.openingHours}>
					<Typography variant="body1">
						{t('delivery.selectDeliveryTime', 'Choose delivery time')}
					</Typography>
				</Box>
				<TimeChipSelector
					value={!!value ? `${value.startDate}_${value.endDate}` : null}
					onChange={(value) => {
						const [startDate, endDate] = value.split('_');
						onChange({ startDate, endDate });
					}}
					options={options}
					pageSize={6}
				/>
			</Box>
		</Collapse>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		openingHours: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			textAlign: 'center',
		},
		startTimes: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			flexWrap: 'wrap',
			flex: 1,
			margin: theme.spacing(3, 0),
			maxWidth: 450,
		},
		startTime: {
			padding: theme.spacing(0.5, 1),
			borderRadius: 16,
			border: '1px solid #e5e7e7',
			minWidth: 60,
			margin: theme.spacing(0.5),
			'&:hover': {
				borderColor: theme.palette.common.black,
			},
		},
		startTimeSelected: {
			background: theme.palette.common.black,
			color: theme.palette.common.white,
			borderColor: theme.palette.common.black,
		},
		startTimeDisabled: {
			background: '#e5e7e7',
			color: '#716a80',
			pointerEvents: 'none',
		},
		smallText: {
			fontSize: '1rem',
			padding: theme.spacing(0.5),
		},
	}),
);

export default DeliverySlotSelector;
