import { TFunction } from 'i18next';

import {
	DayType,
	FixedTimesDayType,
	PaymentAction,
	PaymentState,
	UserDetailName,
} from 'common/types';

import { switchUnreachable } from './common';
import { StartDay } from './dateUtils';

export const getUserDetailTranslation = (detail: UserDetailName, t: TFunction) => {
	switch (detail) {
		case 'age':
			return t('common:form.age', 'Age');
		case 'experienceLevel':
			return t('common:form.experienceLevel', 'Experience level');
		case 'height':
			return t('common:form.height', 'Height');
		case 'weight':
			return t('common:form.weight', 'Weight');
		case 'shoeSize':
			return t('common:form.shoeSize', 'Shoe Size');
		case 'stance':
			return t('common:form.stance', 'Stance');
		default:
			try {
				switchUnreachable(detail);
			} catch (err) {}
			return detail;
	}
};

export const getDayTypeTranslation = (
	dayType: DayType | FixedTimesDayType | StartDay,
	t: TFunction,
) => {
	switch (dayType) {
		case 'allDays':
			return t('common:dayTypes.allDays', 'All days');
		case 'weekDays':
			return t('common:dayTypes.weekdays', 'Weekdays');
		case 'weekends':
			return t('common:dayTypes.weekend', 'Weekends');
		case 'monday':
			return t('common:dayTypes.monday', 'Monday');
		case 'tuesday':
			return t('common:dayTypes.tuesday', 'Tuesday');
		case 'wednesday':
			return t('common:dayTypes.wednesday', 'Wednesday');
		case 'thursday':
			return t('common:dayTypes.thursday', 'Thursday');
		case 'friday':
			return t('common:dayTypes.friday', 'Friday');
		case 'saturday':
			return t('common:dayTypes.saturday', 'Saturday');
		case 'sunday':
			return t('common:dayTypes.sunday', 'Sunday');
		case 'custom':
			return t('common:dayTypes.custom', 'Custom');
		default:
			try {
				switchUnreachable(dayType);
			} catch (err) {}
			return dayType;
	}
};

export const getSegmentTranslation = (segment: string | undefined, t: TFunction) => {
	switch (segment) {
		case 'adult':
			return t('common:ageGroups.adult', 'Adult');
		case 'kid':
			return t('common:ageGroups.kid', 'Kid');
		case 'kidSkiMacInfo':
			return t(
				'common:ageGroups.kidSkiMacInfo',
				"Kid's equipment is suitable only for kids under 150cm and 50kg",
			);
		case 'notUsed':
			return t('common:ageGroups.notUsed', 'Not used');
		case 'over':
			return t('common:ageGroups.over', 'Over');
		case 'regular':
			return t('common:ageGroups.regular', 'Regular');
		case 'senior':
			return t('common:ageGroups.senior', 'Senior');
		case 'teen':
			return t('common:ageGroups.teen', 'Teen');
		case 'under':
			return t('common:ageGroups.under', 'under');
		default:
			return segment;
	}
};

export const getPaymentStateOrActionTranslation = (
	action: PaymentAction | PaymentState,
	t: TFunction,
) => {
	switch (action) {
		case 'AUTHORISATION':
			return t('common:payments.statesAndActions.AUTHORISATION', 'Authorisation');
		case 'AUTHORISATION_PENDING':
			return t('common:payments.statesAndActions.AUTHORISATION_PENDING', 'Authorisation pending');
		case 'AUTHORISATION_FAILED':
			return t('common:payments.statesAndActions.AUTHORISATION_FAILED', 'Authorisation failed');
		case 'PARTIAL_CAPTURE':
			return t('common:payments.statesAndActions.PARTIAL_CAPTURE', 'Partial capture');
		case 'CAPTURE':
			return t('common:payments.statesAndActions.CAPTURE', 'Capture');
		case 'CAPTURE_FAILED':
			return t('common:payments.statesAndActions.CAPTURE_FAILED', 'Capture failed');
		case 'CAPTURE_PENDING':
			return t('common:payments.statesAndActions.CAPTURE_PENDING', 'Capture pending');
		case 'PARTIAL_REFUND':
			return t('common:payments.statesAndActions.PARTIAL_REFUND', 'Partial refund');
		case 'REFUND':
			return t('common:payments.statesAndActions.REFUND', 'Refund');
		case 'REFUND_PENDING':
			return t('common:payments.statesAndActions.REFUND_PENDING', 'Refund pending');
		case 'REFUND_FAILED':
			return t('common:payments.statesAndActions.REFUND_FAILED', 'Refund failed');
		case 'CANCEL':
			return t('common:payments.statesAndActions.CANCEL', 'Cancel');
		case 'CANCEL_PENDING':
			return t('common:payments.statesAndActions.CANCEL_PENDING', 'Cancel pending');
		case 'CANCEL_FAILED':
			return t('common:payments.statesAndActions.CANCEL_FAILED', 'Cancel failed');
		case 'PAYMENT':
			return t('common:payments.statesAndActions.PAYMENT', 'Payment');
		case '':
			return '';
		default: {
			try {
				switchUnreachable(action);
			} catch (err) {}
			return action;
		}
	}
};
