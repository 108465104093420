import React from 'react';

import { Container, Grid, TextField, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { updateShopper } from 'redux/actions/confirm';
import * as ConfirmSelectors from 'redux/selectors/confirm';
import * as ViewSelectors from 'redux/selectors/view';

import LinkButton from 'common/components/LinkButton';
import { useTranslation } from 'services/localization/useTranslation';
import { useRoutes } from 'services/routing/useRoutes';

import ShopperHeaderBar from './ShopperNameStepper';
import SegmentsDropDown from './components/SegmentsDropDown';
import {
	getProductSegments,
	getShopperSegmentFromProducts,
	getUnchosenProductSegments,
	getUniqueSegments,
} from './utils/segmentUtils';
import { updateShopperProperty } from './utils/shopperUtils';

const ShopperDetails = () => {
	const { t } = useTranslation();
	const { getPath, Routes } = useRoutes();
	const classes = useStyles();
	const dispatch = useDispatch();
	const activeShopperIndex = useSelector(ViewSelectors.activeShopperIndex);
	const order = useSelector(ConfirmSelectors.orderData_FORCE);
	const { products, shoppers } = order;

	const shopper = shoppers.find((s, i) => i === activeShopperIndex)!;
	const productSegments = getProductSegments(products);
	const uniqueSegments = getUniqueSegments(productSegments);
	const unchosenProductSegments = getUnchosenProductSegments(shoppers, products);
	const chosenProductSegment = getShopperSegmentFromProducts(shopper, products);
	const shopperSegment = shopper.segment;

	const segmentEnabled = (segment: string) => {
		const isShoppersCurrentSegment = segment !== 'regular' && segment === shopperSegment;
		const isShoppersChosenProductsSegment = segment === chosenProductSegment;
		const shopperHasNoSegment = shopperSegment === undefined || shopperSegment === 'regular';
		const segmentInUnchosenProducts =
			unchosenProductSegments.length > 0 && unchosenProductSegments.includes(segment);
		return (
			isShoppersCurrentSegment ||
			isShoppersChosenProductsSegment ||
			(shopperHasNoSegment && segmentInUnchosenProducts)
		);
	};

	const segmentDisabled = (segment: string) => !segmentEnabled(segment);

	return (
		<Container className={classes.section} maxWidth="sm">
			<ShopperHeaderBar />
			<Typography variant="h5" gutterBottom>
				{t('shopperInfo.addNamesOfPeople', 'Who’s participating?')}
			</Typography>
			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
				className={classes.section}
				spacing={1}
			>
				<Grid item xs={12}>
					<div className={classes.flex}>
						<TextField
							id="firstName"
							label={t('common:form.firstName')}
							variant="standard"
							name="firstName"
							value={shopper.firstName}
							onChange={(e) =>
								dispatch(updateShopper(updateShopperProperty(e, shopper, 'firstName')))
							}
							type="text"
							className={classes.textField}
							required
						/>
					</div>
					{uniqueSegments.length > 0 && (
						<SegmentsDropDown
							shopper={shopper}
							segments={uniqueSegments}
							updateShopper={(shopper) => dispatch(updateShopper(shopper))}
							segmentDisabled={segmentDisabled}
						/>
					)}
				</Grid>
			</Grid>
			<div className={classes.fixedButtonContainer}>
				<LinkButton
					variant="contained"
					color="primary"
					className={classes.btn}
					to={getPath(Routes.confirmDetailsProducts)}
					disabled={shopper.firstName === ''}
				>
					{t('common:actions.continue')}
				</LinkButton>
			</div>
		</Container>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textAlignCenter: {
			textAlign: 'center',
		},
		section: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(6),
		},
		continueBtn: {
			paddingTop: 12,
			paddingBottom: 12,
			height: '40px',
		},
		fixedButtonContainer: {
			width: '100%',
			bottom: 0,
			left: 0,
			padding: '4px 8px',
			backgroundColor: '#fff',
			textAlign: 'center',
		},
		btn: {
			width: '100%',
			minWidth: 250,
			marginTop: theme.spacing(1),
			paddingTop: 12,
			paddingBottom: 12,
		},
		textField: {
			width: '100%',
			marginBottom: theme.spacing(2),
		},
		deleteIcon: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		flex: {
			display: 'flex',
		},
		info: {
			color: theme.palette.colors.tundra.main,
		},
		segmentDropdown: {
			display: 'flex',
			width: '50%',
			[theme.breakpoints.down('lg')]: {
				width: '100%',
			},
		},
	}),
);

export default ShopperDetails;
