import React from 'react';

import { Route, Switch } from 'react-router-dom';
import Shop from 'views/Shop';

import { getRootRoute } from 'services/routing/utils';

const Main = () => {
	return (
		<Switch>
			<Route path={getRootRoute({ withShopPath: false, withLocation: true })} component={Shop} />
			<Route path={getRootRoute({ withShopPath: true, withLocation: false })} component={Shop} />
			<Route path={getRootRoute({ withShopPath: false, withLocation: false })} component={Shop} />
		</Switch>
	);
};

export default Main;
