import React from 'react';

import { Box, ButtonBase, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { clamp, range } from 'lodash';
import { RiAddLine, RiDeleteBinLine, RiSubtractLine } from 'react-icons/ri';

export interface AmountPickerProps {
	value: number;
	onChange?: (value: number) => void;
	onIncrease?: () => void;
	onDecrease?: () => void;

	minValue?: number;
	maxValue?: number;
	activeStyle?: 'outlined' | 'filled';
	size?: 'small' | 'normal' | 'large';
	borderWidth?: number;
	borderColor?: string;
	borderRadius?: number;
	fillColor?: string;
	showDeleteIcon?: boolean;
	readOnly?: boolean;
	disabled?: boolean;
	disableIncrease?: boolean;
}

interface AmountPickerValuesProps {
	min: number;
	max: number;
	activeStyle?: 'outlined' | 'filled';
	size: number;
	fontSize: 'small' | 'normal' | 'large';
}

const SIZE = 40;
const SIZE_SMALL = 24;
const SIZE_LARGE = 50;

const getSize = (size: 'small' | 'normal' | 'large' | undefined) => {
	switch (size) {
		case 'small':
			return SIZE_SMALL;
		case 'large':
			return SIZE_LARGE;
		default:
			return SIZE;
	}
};

const AmountPickerValues = React.memo((props: AmountPickerValuesProps) => {
	const classes = useValueStyles(props);

	return (
		<>
			{range(props.min, props.max + 1, 1)
				.reverse()
				.map((itemValue) => (
					<div
						key={itemValue}
						className={classNames(classes.valueItem, {
							[classes.valueItemInverted]: props.activeStyle === 'filled' && itemValue !== 0,
						})}
					>
						<Typography
							sx={{ fontWeight: 700 }}
							variant="body2"
							color="inherit"
							className={classes.valueItemText}
						>
							{itemValue}
						</Typography>
					</div>
				))}
		</>
	);
});

const AmountPicker = (props: AmountPickerProps) => {
	const classes = useStyles(props);

	const {
		onChange,
		onIncrease,
		onDecrease,
		showDeleteIcon,
		readOnly,
		disabled,
		disableIncrease,
	} = props;
	const minValue = clamp(props.minValue ?? 0, 0, 99);
	const maxValue = clamp(props.maxValue ?? 99, 0, 99);
	const value = clamp(props.value, minValue, maxValue);
	const iconSize = props.size === 'small' ? 18 : 24;
	const size = getSize(props.size);

	const handleIncrease = () => {
		onIncrease?.();
		onChange?.(value + 1);
	};

	const handleDecrease = () => {
		onDecrease?.();
		onChange?.(value - 1);
	};

	return (
		<Box
			className={classes.wrapper}
			role="spinbutton"
			aria-valuenow={props.value}
			aria-valuemin={minValue}
			aria-valuemax={maxValue}
		>
			<ButtonBase
				focusRipple
				aria-label="Decrease"
				className={classNames(classes.button, readOnly && classes.hidden)}
				onClick={handleDecrease}
				disabled={disabled || value <= minValue}
			>
				{value < 2 && showDeleteIcon ? (
					<RiDeleteBinLine size={iconSize} />
				) : (
					<RiSubtractLine size={iconSize} />
				)}
			</ButtonBase>
			<Box className={classNames(classes.value, { [classes.valueActive]: value !== 0 })}>
				<Box
					className={classes.valueWrapper}
					style={{
						transform: `translate3d(0, ${(value - minValue) * size}px, 0)`,
					}}
				>
					<AmountPickerValues
						min={minValue}
						max={maxValue}
						activeStyle={props.activeStyle}
						size={size}
						fontSize={props.size ?? 'normal'}
					/>
				</Box>
			</Box>
			<ButtonBase
				focusRipple
				aria-label="Add"
				className={classNames(classes.button, readOnly && classes.hidden)}
				onClick={handleIncrease}
				disabled={disableIncrease || disabled || value >= maxValue}
			>
				<RiAddLine size={iconSize} />
			</ButtonBase>
		</Box>
	);
};

const useValueStyles = makeStyles((theme: Theme) =>
	createStyles({
		valueItem: {
			width: '100%',
			height: (props: AmountPickerValuesProps) => props.size,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.common.black,
			pointerEvents: 'none',
			userSelect: 'none',
		},
		valueItemText: {
			fontSize: (props: AmountPickerValuesProps) => (props.fontSize === 'large' ? '18px' : '14px'),
		},
		valueItemInverted: {
			color: theme.palette.common.white,
		},
	}),
);

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'row',
			opacity: (props: AmountPickerProps) => (props.disabled ? 0.4 : 1),
		},
		button: {
			width: (props: AmountPickerProps) => getSize(props.size),
			height: (props: AmountPickerProps) => getSize(props.size),
			transition: 'opacity 0.3s',
			'&:disabled': {
				opacity: 0.3,
			},
		},
		value: {
			width: (props: AmountPickerProps) => getSize(props.size),
			height: (props: AmountPickerProps) => getSize(props.size),
			margin: (props: AmountPickerProps) => (props.size === 'small' ? theme.spacing(0, 0.5) : 0),
			position: 'relative',
			overflow: 'hidden',
			borderStyle: 'solid',
			borderWidth: (props: AmountPickerProps) =>
				props.borderWidth ?? (props.size === 'small' ? 1 : 2),
			borderColor: (props: AmountPickerProps) => props.borderColor ?? 'black',
			borderRadius: (props: AmountPickerProps) => props.borderRadius ?? 0,
			transition: 'background 0.3s',
		},
		valueActive: {
			background: (props: AmountPickerProps) =>
				props.activeStyle === 'filled'
					? props.fillColor ?? theme.palette.common.black
					: 'transparent',
			borderColor: (props: AmountPickerProps) => props.fillColor ?? theme.palette.common.black,
		},
		valueWrapper: {
			position: 'absolute',
			bottom: -1,
			left: -1,
			width: (props: AmountPickerProps) => getSize(props.size),
			display: 'flex',
			flexDirection: 'column',
			transition: 'transform 0.3s ease',
		},
		hidden: {
			display: 'none',
		},
	}),
);

export default AmountPicker;
