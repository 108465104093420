import React from 'react';

import { Box, MenuItem, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TFunction } from 'i18next';

import { UnitSystemType } from 'common/types';
import { getUnitSystemOptions } from 'common/utils/userDetails';

interface Props {
	t: TFunction;
	value: UnitSystemType;
	onChange: (value: UnitSystemType) => void;
}

const UnitSystemSelect = (props: Props) => {
	const { t, value, onChange } = props;
	const classes = useStyles();

	return (
		<Box display="inline-flex" alignItems="baseline">
			<TextField
				select
				variant="standard"
				value={value}
				onChange={(e) => onChange(e.target.value as UnitSystemType)}
				className={classes.unitSystem}
				InputProps={{
					disableUnderline: true,
				}}
			>
				{getUnitSystemOptions(t).map((unit) => (
					<MenuItem key={unit.value} value={unit.value}>
						<Typography variant="body2">{unit.label}</Typography>
					</MenuItem>
				))}
			</TextField>
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	unitSystem: {
		minWidth: 120,
		margin: theme.spacing(-0.5, 0, -0.5, 1),
	},
}));

export default UnitSystemSelect;
