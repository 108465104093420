import { createSelector } from '@reduxjs/toolkit';
import { maxBy, minBy, uniq } from 'lodash';
import moment from 'moment-timezone';
import { ReduxState } from 'redux/types';

import { getTotalItemPricing, itemPricingToOrderPricing } from 'common/modules/atoms/pricing';
import { getDiscountCategoryIds, getDiscountProductId } from 'common/modules/discountCodes';
import { getTotalProductPricing, isSubscriptionPurchaseType } from 'common/modules/orders';
import { getNextInstalmentFromProducts } from 'common/modules/subscriptions';
import { CustomCheckoutField, Location, OrderInfo, ResponsiblePerson, Shopper } from 'common/types';
import { notUndefined } from 'common/utils/common';
import { getOnlineOrderRequest, isBookedOrderMissingDetails } from 'common/utils/confirmation';
import { getCurrencyObjectFromCurrencyCode } from 'common/utils/currencyUtils';
import { getNextProductReturnTime } from 'common/utils/dateCalculations';
import { MomentDateFormat, localFormat } from 'common/utils/dateUtils';
import { getClientEnv } from 'common/utils/frontUtils';
import {
	getShopperNameKeywords,
	getShopperNames,
	getUniqProductTypes,
} from 'common/utils/rentalUtils';
import { t } from 'services/localization';
import { cartDeliveryToOrderDelivery, generateOrderInfoDelivery } from 'utils/cart';

import * as ShopSelectors from './shop';
import * as StockSelectors from './stock';
import * as ViewSelectors from './view';

export const contactPerson = (state: ReduxState) => state.checkout.contactPerson;

export const customCheckout = (state: ReduxState) => state.checkout.customCheckout;

export const bookingTermsAccepted = (state: ReduxState) => state.checkout.termsAcceptance.accepted;

export const recurringPaymentsAccepted = (state: ReduxState) =>
	state.checkout.recurringPaymentsAcceptance;

export const adyenPaymentMethods = (state: ReduxState) => state.checkout.adyenPaymentMethods;

export const additionalPaymentAction = (state: ReduxState) =>
	state.checkout.additionalPaymentAction;

export const paymentLoading = (state: ReduxState) => state.checkout.paymentLoading;

export const paymentInProgress = (state: ReduxState) => state.checkout.paymentInProgress;

export const paymentError = (state: ReduxState) => state.checkout.paymentError;

export const transactionId = (state: ReduxState) => state.checkout.transactionId;

export const orderId = (state: ReduxState) => state.checkout.orderId;

export const orderProducts = (state: ReduxState) => state.checkout.orderProducts;

export const cartDelivery = (state: ReduxState) => state.cart.delivery;

export const cartDeliveryPricing = (state: ReduxState) => state.cart.delivery?.pricing;

export const handlePaymentRedirect = (state: ReduxState) => state.checkout.handlePaymentRedirect;

export const appliedDiscountCodes = (state: ReduxState) => state.checkout.appliedDiscountCodes;

export const hasDiscountCodes = (state: ReduxState) =>
	!!Object.keys(state.checkout.appliedDiscountCodes).length;

export const currencyCode = createSelector(
	orderProducts,
	ShopSelectors.shopCurrency,
	(products, shopCurrency) => {
		return products[0]?.pricing.currency ?? shopCurrency.code;
	},
);

export const orderCategoryIds = createSelector(orderProducts, (products) => {
	return uniq(products.flatMap((p) => p.categoryIds ?? []));
});

export const discountProductIds = createSelector(orderProducts, (products) => {
	return uniq(products.map(getDiscountProductId).filter(notUndefined));
});

export const discountCategoryIds = createSelector(orderProducts, (products) => {
	return uniq(products.flatMap((p) => getDiscountCategoryIds(p)));
});

export const currency = createSelector(
	currencyCode,
	ShopSelectors.shopCurrency,
	(currencyCode, shopCurrency) => {
		return getCurrencyObjectFromCurrencyCode(currencyCode, shopCurrency);
	},
);

export const orderDelivery = createSelector(
	cartDelivery,
	ShopSelectors.shopId,
	orderId,
	ViewSelectors.availablePickupSlots,
	(cartDelivery, shopId, orderId, availablePickupSlots) => {
		return cartDelivery
			? cartDeliveryToOrderDelivery({ cartDelivery, orderId, shopId, availablePickupSlots })
			: null;
	},
);

export const checkoutPricing = createSelector(
	orderProducts,
	orderDelivery,
	currencyCode,
	(products, orderDelivery, fallbackCurrency) => {
		const productPricing = getTotalProductPricing(products, fallbackCurrency);
		const totalPricing = getTotalItemPricing(
			[productPricing, orderDelivery?.pricing].filter(notUndefined),
			fallbackCurrency,
		);
		return totalPricing;
	},
);

export const taxExcluded = createSelector(checkoutPricing, (pricing) => pricing.taxExcluded);

export const startLocationId = createSelector(orderProducts, (products) => {
	return products[0]?.startLocationId ?? '';
});

export const startDate = createSelector(orderProducts, (products) => {
	return minBy(products, (product) => product.startDate)?.startDate ?? null;
});

export const endDate = createSelector(orderProducts, (products) => {
	return maxBy(products, (product) => product.endDate)?.endDate ?? null;
});

export const orderPurchaseTypes = createSelector(orderProducts, (products) => {
	return uniq(products.map((p) => p.purchaseType));
});

export const subscriptionOrderProducts = createSelector(orderProducts, (products) => {
	return products.filter(({ purchaseType }) => isSubscriptionPurchaseType(purchaseType));
});

export const buildOrderObject = (state: ReduxState) => {
	const createdAt = moment().toISOString();
	const shopId = ShopSelectors.shopId(state);
	const orderId = state.checkout.orderId;
	const contactPerson = state.checkout.contactPerson;
	const termsAcceptance = state.checkout.termsAcceptance;
	const orderProducts = state.checkout.orderProducts;
	const delivery = orderDelivery(state);
	const discountCodes = state.checkout.appliedDiscountCodes;
	const onlineLocationsById = ShopSelectors.onlineLocationsById(state);
	const totalItemPricing = checkoutPricing(state);
	const shopCancellationPolicy = ShopSelectors.shopCancellationPolicy(state);
	const orderStartDate = startDate(state) ?? createdAt; // Always existing, CheckoutDataFetcher is checking if orderProducts exist;
	const returnTimeNext = getNextProductReturnTime(orderProducts);
	const locationId = startLocationId(state);
	const purchaseTypes = orderPurchaseTypes(state);
	const hasSubscription = purchaseTypes.includes('subscription');
	const location: Location = onlineLocationsById[locationId];
	const responsiblePerson: ResponsiblePerson = {
		external: false,
		shopperId: contactPerson.id,
	};
	const orderHasDiscountCodes = hasDiscountCodes(state);

	const productIds = orderProducts.map((p) => p.id);
	const categoryIds = uniq(orderProducts.flatMap((p) => p.categoryIds ?? []));
	const stockProductIds = orderProducts.map((p) => p.productApiId);

	const customCheckoutSections = state.checkout.customCheckout?.customContent?.sections;

	const customCheckoutFields: CustomCheckoutField[] | undefined = customCheckoutSections
		?.flatMap((section) =>
			section.content
				?.map((content) =>
					content?.type === 'textfield'
						? {
								label: content.label,
								value: content.value ?? null,
						  }
						: content?.type === 'checkbox'
						? {
								label: content.label,
								value: content.value ?? false,
						  }
						: undefined,
				)
				.filter(notUndefined),
		)
		.filter(notUndefined);

	const shopper: Shopper = {
		id: contactPerson.id,
		created: createdAt,
		firstName: contactPerson.firstName,
		lastName: contactPerson.lastName,
		phone: contactPerson.phone,
		email: contactPerson.email,
		marketing: contactPerson.marketing,
		homeAddress: contactPerson.homeAddress,
		accommodation: contactPerson.accommodation,
		language: contactPerson.language,
		categoryIds: categoryIds,
		segment: undefined,
		userProperties: {},
		productsReturned: false,
		productIds,
		rentalId: orderId,
		shopId,
	};

	const onlineOrderInfo: OrderInfo = {
		id: orderId,
		activeState: 'ONLINE_BOOKED',
		created: createdAt,
		startDate: orderStartDate,
		initialStartDate: orderStartDate,
		startDateException: false,
		returnTimeNext,
		endDateReturned: null,
		charge: {
			paid: 0,
		},
		deposit: {
			paid: 0,
		},
		cancellationPolicy: shopCancellationPolicy,
		channel: 'ONLINE',
		rentalState: 'BOOKED',
		shopId,
		startLocation: location,
		endLocation: location,
		live: false, // TODO Refactor how "live" is used later on /Toomas
		responsiblePerson,
		terms: termsAcceptance,
		shopperNames: getShopperNames([shopper], responsiblePerson),
		shopperNameKeywords: getShopperNameKeywords([shopper], responsiblePerson),
		shopperIdsWithProducts: {
			[shopper.id]: productIds,
		},
		categoryIds,
		stockProductIds: stockProductIds,
		rentalProductCodes: [],
		includedProductTypes: getUniqProductTypes(orderProducts),
		...(orderHasDiscountCodes && { appliedDiscountCodes: discountCodes }),
		...(delivery && {
			services: {
				delivery: generateOrderInfoDelivery(delivery),
			},
		}),
		pricing: itemPricingToOrderPricing(totalItemPricing),
		customCheckoutFields,
		purchaseTypes,
		purchaseType: purchaseTypes.length === 1 ? purchaseTypes[0] : null,
		...(hasSubscription && {
			subscription: { nextBillingDate: getNextInstalmentFromProducts(orderProducts)?.date ?? null },
		}),
	};

	return {
		shoppers: [shopper],
		products: orderProducts,
		rentalInfo: onlineOrderInfo,
		...(delivery && { orderDelivery: delivery }),
	};
};

export const buildOnlineOrderRequest = (state: ReduxState) => {
	const orderObject = buildOrderObject(state);
	const shopPublicInfo = ShopSelectors.shopPublicInfoData(state)!; // Shop public info always existing if we are at Checkout view
	const shopUrlsData = ShopSelectors.shopUrlsData(state)!; // Shop url data always existing if we are at Checkout view
	const categories = StockSelectors.stockCategoriesData(state);
	const language = state.view.language;
	const { products, shoppers } = orderObject;
	const isMissingDetails = isBookedOrderMissingDetails(products, shoppers);
	const shopDateFormat = ShopSelectors.shopDateFormat(state);

	const _localFormat = (
		dateTime: string | moment.Moment | undefined,
		dateFormat: MomentDateFormat,
	) => {
		return localFormat(dateTime, dateFormat, shopDateFormat);
	};

	return getOnlineOrderRequest({
		order: orderObject,
		shopInfo: shopPublicInfo,
		categories,
		lang: language,
		shopUrlDoc: shopUrlsData,
		isMissingDetails,
		locationId: orderObject.rentalInfo.startLocation.id,
		localFormat: _localFormat,
		t,
		env: getClientEnv(),
	});
};
