import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';

import 'indexeddb-getall-shim';

loadPolyfills();
/**
 * Do feature detection, to figure out which polyfills needs to be imported.
 **/
async function loadPolyfills() {
	// Smoothscroll polyfill
	smoothscroll.polyfill();
	// Load IntersectionObserver polyfill if needed
	if (typeof window.IntersectionObserver === 'undefined') {
		await import('intersection-observer');
	}
}
