import * as AnalyticsSelectors from 'redux/selectors/analytics';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import { ReduxThunkAction } from 'redux/types';

import { logEcomEvent } from 'common/services/analytics';
import { centsToMajorUnits } from 'common/utils/common';
import { getAnalyticsItemsFromOrderProducts } from 'utils/analytics';

import { EcomEventParams } from '..';
import { EcomEvents } from '../constants';

const eventName = EcomEvents.add_payment_info;

export const logAddPaymentInfo = (params: EcomEventParams[typeof eventName]): ReduxThunkAction => (
	_,
	getState,
) => {
	const { paymentMethod } = params;
	const state = getState();
	const shopGoogleAnalytics = ShopSelectors.shopGoogleAnalytics(state);
	const categoriesById = StockSelectors.categoriesById(state);
	const itemListSelectionById = AnalyticsSelectors.itemListSelectionById(state);
	const appliedDiscountCodes = CheckoutSelectors.appliedDiscountCodes(state);
	const orderProducts = CheckoutSelectors.orderProducts(state);
	const locationsById = ShopSelectors.onlineLocationsById(state);
	const checkoutPricing = CheckoutSelectors.checkoutPricing(state);
	const locationId = CheckoutSelectors.startLocationId(state);
	const coupon = Object.keys(appliedDiscountCodes)[0] ?? undefined;
	logEcomEvent(
		{
			name: eventName,
			params: {
				payment_type: paymentMethod,
				items: getAnalyticsItemsFromOrderProducts(orderProducts, {
					categoriesById,
					location: locationsById[locationId],
					itemListSelectionById,
				}),
				value: centsToMajorUnits(checkoutPricing.subtotal),
				currency: checkoutPricing.currency,
				coupon,
			},
		},
		shopGoogleAnalytics,
	);
};
