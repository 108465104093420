import { ClientAuthProfile } from 'redux/reducers/auth';

import { ResponsiblePersonDetails } from 'common/types';
import { CheckoutFormState } from 'hooks/useCheckoutForm';

export const getContactPersonFromCheckoutFrom = (
	formState: CheckoutFormState,
): Omit<Partial<ResponsiblePersonDetails>, 'id'> => {
	const { firstName, lastName, marketing, email, language, phone } = formState;
	const { areaCode, numberWithoutCode } = phone;
	const fullPhoneNumber = areaCode + numberWithoutCode;
	return {
		firstName,
		lastName,
		marketing,
		email,
		language,
		phone: fullPhoneNumber,
	};
};

export const getAuthProfileFromCheckoutForm = (
	formState: CheckoutFormState,
): Omit<Partial<ClientAuthProfile>, 'id'> => {
	const { firstName, lastName, email, phone, dateOfBirth, businessAccount } = formState;
	const { areaCode, numberWithoutCode } = phone;
	const fullPhoneNumber = areaCode + numberWithoutCode;
	return {
		firstName,
		lastName,
		email,
		phone: fullPhoneNumber,
		dateOfBirth: dateOfBirth,
		businessAccount,
	};
};
