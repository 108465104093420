import React from 'react';

import { Box, Button, Link, Stack, Typography } from '@mui/material';

import { FeaturedImageButton } from 'common/modules/customization';
import { getTranslation } from 'common/modules/translations';
import { Languages, LocaleField } from 'common/types';
import { ellipsize } from 'common/utils/text';

export interface Props {
	featuredImage: string;
	alignment: string;
	heading?: LocaleField | null;
	description?: LocaleField | null;
	button: FeaturedImageButton | null;
	isDeactivated?: boolean;
	language: Languages;
}

const FeaturedImage = (props: Props) => {
	const { featuredImage, alignment, heading, description, button, isDeactivated, language } = props;

	const imageHasContent = !!heading?.def || !!description?.def || !!button?.label?.def;

	return (
		<Box
			sx={{
				width: '100%',
				height: { xs: '300px', sm: '400px' },
				mb: 4,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'relative',
			}}
		>
			<img
				src={featuredImage}
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					objectFit: 'cover',
					objectPosition: alignment,
					zIndex: 0,
					filter: imageHasContent ? 'brightness(64%)' : undefined,
				}}
				alt=""
			/>
			<Stack
				justifyContent="center"
				alignItems="center"
				sx={{
					padding: 4,
					color: (theme) => theme.palette.common.white,
					width: '100%',
					height: '100%',
					zIndex: 1,
					overflow: 'hidden',
				}}
			>
				<Typography variant="h6" align="center" maxWidth="600px">
					{getTranslation(heading, language)}
				</Typography>
				<Typography
					variant="body1"
					align="center"
					fontSize={{ xs: '12px', md: '14px' }}
					maxWidth="600px"
				>
					{getTranslation(description, language)}
				</Typography>
				{!!button?.label?.def?.length && !!button?.src?.length && (
					<Box mt={2}>
						<Link
							target="_blank"
							rel="noopener noreferrer"
							href={!isDeactivated ? button.src : undefined}
							sx={{ textDecoration: 'none' }}
						>
							<Button
								variant="contained"
								disabled={isDeactivated}
								sx={{
									'&.Mui-disabled': {
										backgroundColor: (theme) => theme.palette.primary.main,
										color: (theme) => theme.palette.common.white,
									},
								}}
							>
								{getTranslation(button.label, language)}
							</Button>
						</Link>
					</Box>
				)}
			</Stack>
		</Box>
	);
};

export default FeaturedImage;
