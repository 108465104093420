import React from 'react';

import { Theme, lighten } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
	animationSpeed?: 'slow' | 'fast';
	opacity?: number;
	color?: 'light' | 'dark';
	hidden?: boolean;
	tint?: string;
	degrees?: number;
	stripeWidth?: number;
	nonStripeColorOpacity?: number;
}

const StripePattern = (props: Props) => {
	const classes = useStyles(props);
	return (
		<div className={classes.wrapper}>
			<div className={classes.pattern} />
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			overflow: 'hidden',
			transition: 'all 0.5s ease',
			opacity: (props: Props) => (props.hidden ? 0 : 1),
			transform: (props: Props) => (props.hidden ? 'scale3d(3,3,1)' : 'scale3d(1,1,1)'),
			background: (props: Props) =>
				props.tint && theme.palette[props.tint]
					? lighten(theme.palette[props.tint].main, 0.6)
					: 'transparent',
		},
		pattern: {
			position: 'absolute',
			zIndex: 1,
			top: 0,
			left: '-30px',
			right: '-30px',
			height: '100%',
			transition: 'all 2s ease',
			background: (props: Props) => `repeating-linear-gradient(
                ${props.degrees ? props.degrees + 'deg' : '45deg'},
                rgba(${props.color === 'light' ? '255,255,255' : '0,0,0'}, ${
				(props.opacity ?? 1) * 0.25
			}),
                rgba(${props.color === 'light' ? '255,255,255' : '0,0,0'}, ${
				(props.opacity ?? 1) * 0.25
			}) ${props.stripeWidth ? props.stripeWidth + 'px' : '10px'},
                rgba(${props.color === 'light' ? '255,255,255' : '0,0,0'}, ${
				(props.opacity ?? 1) * (props.nonStripeColorOpacity ?? 0.1)
			}) 1px,
                rgba(${props.color === 'light' ? '255,255,255' : '0,0,0'}, ${
				(props.opacity ?? 1) * (props.nonStripeColorOpacity ?? 0.1)
			}) 20px
            )`,
			animationName: '$animatePattern',
			animationDuration: (props: Props) => (props.animationSpeed === 'slow' ? '1s' : '0.5s'),
			animationTimingFunction: 'linear',
			animationIterationCount: (props: Props) => (!props.animationSpeed ? 0 : 'infinite'),
		},
		'@keyframes animatePattern': {
			'0%': {
				transform: 'translate3d(0, 0, 0)',
			},
			'100%': {
				transform: 'translate3d(28px, 0, 0)',
			},
		},
	}),
);

export default StripePattern;
