import React, { useState } from 'react';

import { Box, ButtonBase, Stack, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiQuestionLine } from 'react-icons/ri';
import { useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';

import Divider from 'common/components/Divider';
import { PriceBreakdown } from 'common/components/molecules/PriceBreakdown';
import { getNextInstalmentFromProducts } from 'common/modules/subscriptions';
import { isRunInIframe } from 'common/utils/browserUtils';
import { getPricingString } from 'common/utils/common';
import CartDeliveryRow from 'components/Cart/CartDeliveryRow';
import CartProductsRows from 'components/Cart/CartProductsRows';
import DepositInfoModal from 'components/DepositInfoModal';
import ShopInfo from 'components/ShopInfo';
import { useTranslation } from 'services/localization';

import Discount from './Discount';
import { EditBag } from './EditBag';

interface Props {
	showBorder?: boolean;
	innerPadding?: number;
	hideShopInfo?: boolean;
}

const CheckoutCart = (props: Props) => {
	const { innerPadding, hideShopInfo } = props;
	const { t, language: lang } = useTranslation();
	const classes = useStyles(props);
	const [depositModalOpen, setDepositModalOpen] = useState<boolean>(false);

	const itemsWithoutKeycard = useSelector(CartSelectors.itemsWithoutKeycard);
	const includedRentalProductIds = useSelector(CartSelectors.includedRentalProductIds);
	const cartDelivery = useSelector(CartSelectors.delivery);
	const checkoutPricing = useSelector(CheckoutSelectors.checkoutPricing);
	const subscriptionOrderProducts = useSelector(CheckoutSelectors.subscriptionOrderProducts);
	const nextInstalment = getNextInstalmentFromProducts(subscriptionOrderProducts);
	const currency = useSelector(CheckoutSelectors.currency);
	const startLocationId = useSelector(CheckoutSelectors.startLocationId);
	const shopHasActiveDiscountCodes = useSelector(ShopSelectors.shopHasActiveDiscountCodes);
	const earliestStartDate = useSelector(CartSelectors.startDate);
	const latestEndDate = useSelector(CartSelectors.latestEndDate);

	return (
		<Box className={classes.cart}>
			{!hideShopInfo && (
				<Box mx={innerPadding ?? 0} my={1}>
					<ShopInfo
						shopLocationId={startLocationId}
						showBusinessId={isRunInIframe()}
						showLocationName
					/>
				</Box>
			)}
			<EditBag innerPadding={innerPadding} />
			<Box className={classes.cartContent}>
				<Box className={classes.wrapper}>
					<Box className={classes.products}>
						<CartProductsRows rows={itemsWithoutKeycard} readOnly innerPadding={innerPadding} />
					</Box>
					{!!cartDelivery && (
						<>
							<Divider />
							<Box className={classes.orderDelivery}>
								<CartDeliveryRow
									innerPadding={innerPadding}
									delivery={cartDelivery}
									startDate={earliestStartDate}
									endDate={latestEndDate}
								/>
							</Box>
						</>
					)}
					{!!checkoutPricing.deposit && (
						<>
							<Divider />
							<Box className={classes.deposit}>
								<ButtonBase onClick={() => setDepositModalOpen(true)}>
									<Typography variant="body2">
										{`+ ${t('summary.securityDeposit', 'Security deposit')}, ${getPricingString(
											checkoutPricing.deposit,
											currency,
										)}`}
									</Typography>
									<Box
										display="inline"
										sx={{ ml: 1, color: (theme) => theme.palette.text.secondary }}
									>
										<RiQuestionLine color="inherit" size={24} />
									</Box>
								</ButtonBase>
								<DepositInfoModal
									isOpen={depositModalOpen}
									onClose={() => setDepositModalOpen(false)}
									productIds={includedRentalProductIds}
									depositAmount={checkoutPricing.deposit}
								/>
							</Box>
						</>
					)}
					<Divider />
					<Stack spacing={2} className={classes.summary}>
						{shopHasActiveDiscountCodes && (
							<Box mb={2}>
								<Discount />
							</Box>
						)}
						<Stack spacing={2}>
							<PriceBreakdown
								pricing={checkoutPricing}
								t={t}
								shopCurrency={currency}
								rows={[
									'manualDiscount',
									'subtotal',
									'taxes',
									'total',
									'includedTax',
									'nextInstalment',
								]}
								lang={lang}
								nextInstalment={nextInstalment}
							/>
						</Stack>
					</Stack>
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cart: {
			display: 'flex',
			flexDirection: 'column',
			border: ({ showBorder }: Props) =>
				showBorder ? `1px solid ${theme.palette.border.paper}` : 'none',
		},
		cartHeader: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: theme.spacing(3, 3, 0, 3),
		},
		cartHeaderClose: {
			fontSize: 14,
		},
		cartContent: {
			flex: '1 1 auto',
			[theme.breakpoints.up('md')]: {
				overflowY: 'auto',
			},
		},
		wrapper: {
			minHeight: 200,
			display: 'flex',
			flexDirection: 'column',
		},
		products: {
			flex: 1,
		},
		orderDelivery: {
			flex: 1,
		},
		summary: {
			margin: ({ innerPadding }: Props) => theme.spacing(2, innerPadding ?? 0),
		},
		deposit: {
			padding: theme.spacing(1, 0),
			margin: ({ innerPadding }: Props) => theme.spacing(0, innerPadding ?? 0),
		},
		total: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			margin: theme.spacing(1, 0),
		},
		subtotal: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginTop: theme.spacing(2),
			padding: ({ innerPadding }: Props) => (innerPadding ? theme.spacing(0, innerPadding) : 0),
		},
	}),
);

export default CheckoutCart;
