import React, { useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { uniqBy } from 'lodash';

import { BaseProductVariant, VariantProperty } from 'common/modules/inventory';
import { getProductVariantProperties, getProductVariants } from 'common/modules/products/variants';
import { LocaleField, ProductApi } from 'common/types';
import BoxRadioButton from 'components/BoxRadioButton';
import ErrorText from 'components/ErrorText';
import { useTranslation } from 'services/localization';

const variantMatchesSelection = (
	variant: BaseProductVariant,
	selection: SelectionByProperty,
	properties: VariantProperty[],
) => {
	return properties.every((prop) => {
		const propertyId = prop.id;
		const selectedValue = selection[propertyId];
		if (selectedValue === null || selectedValue === undefined) return true;

		const variantValue = variant.values[propertyId]?.def ?? '';
		return variantValue === selectedValue;
	});
};

interface Props {
	product: ProductApi;
	value: string | null;
	onChange: (productId: string, variantId: string | null) => void;
	showError?: boolean;
	hideLabel?: boolean;
	disabledVariants?: string[];
}
interface OptionsByProperty {
	[propertyId: string]: Option[];
}

interface Option {
	key: string;
	value: LocaleField | undefined;
}

interface SelectionByProperty {
	[propertyId: string]: string | null;
}

const VariantSelect = (props: Props) => {
	const classes = useStyles();
	const { t, getTranslation } = useTranslation();
	const { product, value, onChange, showError, hideLabel, disabledVariants } = props;

	const productId = product.id;
	const variants = getProductVariants(product);
	const enabledVariants = useMemo(() => {
		if (!disabledVariants?.length) return variants;
		return variants.filter((v) => !disabledVariants.includes(v.id));
	}, [variants, disabledVariants]);
	const selectedVariant = enabledVariants.find((v) => v.id === value) ?? null;

	const properties = useMemo(() => product.variants.properties, [product]);
	const optionsByProperty: OptionsByProperty = useMemo(() => {
		const result: OptionsByProperty = {};
		variants.forEach((variant) => {
			properties.forEach((property) => {
				const propertyId = property.id;
				const option: Option = {
					key: variant.values[propertyId]?.def ?? '',
					value: variant.values[propertyId],
				};
				result[propertyId] = !!result[propertyId]
					? uniqBy(result[propertyId].concat(option), (item) => item.key)
					: [option];
			});
		});

		return result;
	}, [properties, variants]);

	const selectedVariantSelection: SelectionByProperty | null = useMemo(() => {
		if (!selectedVariant) return null;
		return properties.reduce((result, { id }) => {
			result[id] = selectedVariant.values[id]?.def ?? '';
			return result;
		}, {});
	}, [properties, selectedVariant]);

	const [state, setState] = useState<SelectionByProperty>(selectedVariantSelection ?? {});

	useEffect(() => {
		const selectedProps = Object.values(state).filter((v) => v !== null);
		const allSelected = selectedProps.length === properties.length;
		if (allSelected) {
			const matchingVariant = enabledVariants.find((v) =>
				variantMatchesSelection(v, state, properties),
			);
			if (matchingVariant) {
				onChange(productId, matchingVariant.id);
			}
		} else {
			onChange(productId, null);
		}
	}, [state, onChange, properties, enabledVariants, productId]);

	const getRemainingOptionsForProperty = (propertyId: string, state: SelectionByProperty) => {
		const options = optionsByProperty[propertyId];

		return options.filter((option) => {
			const stateWithOption = { ...state, [propertyId]: option.key };
			return enabledVariants.some((variant) => {
				return variantMatchesSelection(variant, stateWithOption, properties);
			});
		});
	};

	const handleOptionSelect = (propertyId: string, option: Option) => {
		const newState = {
			...state,
			[propertyId]: option.key,
		};
		setState(newState);
	};

	const handleOptionDeselect = (propertyId: string) => {
		const newState = {
			...state,
			[propertyId]: null,
		};

		setState(newState);
	};

	const isOptionSelected = (propertyId: string, option: Option) => {
		return state[propertyId] === option.key;
	};

	const renderPropertyOptions = (property: VariantProperty) => {
		const propertyId = property.id;
		const remainingOptions = getRemainingOptionsForProperty(propertyId, state);
		const options = optionsByProperty[propertyId] ?? [];

		return (
			<Box className={classes.propertyOptions}>
				{options.map((option) => {
					const isDisabled = !remainingOptions.some((o) => o.key === option.key);
					const label = !!option.key
						? getTranslation(option.value ?? { def: '' })
						: t('common:phrases.any', 'Any');
					return (
						<BoxRadioButton
							key={option.key}
							text={label}
							disabled={isDisabled}
							onSelect={() => handleOptionSelect(propertyId, option)}
							onDeselect={() => handleOptionDeselect(propertyId)}
							selected={!isDisabled && isOptionSelected(propertyId, option)}
						/>
					);
				})}
			</Box>
		);
	};

	const renderProperty = (property: VariantProperty) => {
		const propertyId = property.id;
		const hasValue = state[propertyId] !== null && state[propertyId] !== undefined;
		return (
			<Box key={propertyId}>
				<Box>
					<Typography variant="body2">
						{t('common:prompts.selectProperty', {
							property: getTranslation(property.name),
							defaultValue: 'Select {{property}}',
						})}
					</Typography>

					{!hasValue && showError && (
						<ErrorText>{t('common:prompts.pleaseSelect', 'Please select')}</ErrorText>
					)}
				</Box>
				{renderPropertyOptions(property)}
			</Box>
		);
	};

	return (
		<Box>
			{!hideLabel && (
				<Typography variant="body1" className={classes.label}>
					{getTranslation(product.name)}
				</Typography>
			)}
			<Box>{getProductVariantProperties(product)?.map(renderProperty)}</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			fontWeight: 500,
			fontSize: '1.8rem',
			marginBottom: theme.spacing(1.5),
		},
		propertyOptions: {
			marginTop: theme.spacing(1.5),
			'& > *': {
				marginBottom: theme.spacing(2),
				marginRight: theme.spacing(2),
			},
		},
	}),
);

export default VariantSelect;
