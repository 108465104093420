import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useInView } from 'react-intersection-observer';

import useHeight from 'common/hooks/useHeight';
import useScrollDirection from 'common/hooks/useScrollDirection';

interface RenderProps {
	isStuck: boolean;
	isScrolledDown: boolean;
}

interface Props {
	expandedContent?: (props: RenderProps) => JSX.Element | null;
	children?: (props: RenderProps) => JSX.Element | null;
}

const Sticky = React.memo((props: Props) => {
	const classes = useStyles();

	const [wrapperRef, inView] = useInView({ threshold: 1, initialInView: true });
	const [scrollRef, scrollRefInView] = useInView({ threshold: 1, initialInView: true });
	const [expandedRef, expandedHeight] = useHeight();
	const isStuck = !inView;
	const isScrolledDown = !scrollRefInView;
	const scrollDirection = useScrollDirection();

	const getInnerStyle = () => {
		return {
			transform:
				isStuck && isScrolledDown && scrollDirection === 'UP'
					? `translate3d(0, ${expandedHeight}px, 0)`
					: 'translate3d(0,0,0)',
		};
	};

	const getExpandedStyle = () => {
		if (!isStuck) return { display: 'none' as 'none' };
		return isScrolledDown ? {} : { opacity: 0 };
	};

	return (
		<>
			<div ref={scrollRef} className={classes.scrollRef} style={{ top: isScrolledDown ? 0 : 50 }} />
			<div ref={wrapperRef} className={classes.wrapper}>
				<div className={classes.inner} style={getInnerStyle()}>
					<div className={classes.expanded} style={getExpandedStyle()}>
						<div ref={expandedRef} className={classes.expandedContent}>
							<div style={isStuck ? {} : { visibility: 'hidden' as 'hidden' }}>
								{props.expandedContent?.({ isStuck, isScrolledDown })}
							</div>
						</div>
					</div>
					<div>{props.children?.({ isStuck, isScrolledDown }) ?? null}</div>
				</div>
			</div>
		</>
	);
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			position: 'sticky',
			top: -1,
			zIndex: theme.zIndex.appBar - 1,
			background: '#ffffff',
		},
		inner: {
			transition: 'transform 0.2s',
			transitionDelay: '0.2s',
			background: '#ffffff',
		},
		expanded: {
			height: 0,
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			transtion: 'opacity 0.2s',
			transitionDelay: '0.2s',
		},
		expandedContent: {
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			background: '#ffffff',
		},
		scrollRef: {
			position: 'relative',
			width: '100%',
		},
	}),
);

export default Sticky;
