import React, { useCallback, useEffect, useState } from 'react';

import { isEqual, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import {
	addProductsToCart,
	clearCartItemsForCheckoutCartReplace,
	removeReservation,
	setDelivery,
} from 'redux/actions/cart';
import {
	clearDiscountCodes,
	initialiseCustomCheckoutFields,
	replaceDiscountCodesInCartOnly,
	setHandlePaymentRedirect,
	setOrderId,
	setOrderProducts,
	setTransactionId,
	updateAdyenPaymentMethods,
	updateContactPerson,
	updateRecurringPaymentsAcceptance,
	updateTermsAcceptance,
} from 'redux/actions/checkout';
import { setOrder } from 'redux/actions/confirm';
import * as ViewActions from 'redux/actions/view';
import { setPhoneObject } from 'redux/actions/view';
import * as CartSelectors from 'redux/selectors/cart';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import { getBookedOnlineOrderObject } from 'common/api/db/functions';
import Spinner from 'common/components/Spinner';
import { api } from 'common/frontend/api';
import { Callable } from 'common/frontend/callable';
import { AdyenProvider } from 'common/hooks/useAdyen/AdyenContext';
import { pricingToAmountObject } from 'common/modules/atoms/pricing';
import { CheckoutSettings } from 'common/modules/customization';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { CartDelivery, OrderObject, OrderProduct } from 'common/types';
import { stringQueryParam } from 'common/utils/browserUtils';
import { StorageKeys, Storages, saveToStorage } from 'common/utils/frontUtils';
import { newFirestoreId } from 'common/utils/newRentalUtils';
import OrderFetchError from 'components/OrderFetchError';
import { useRoutes } from 'services/routing/useRoutes';
import { getOrderDeliveryOption } from 'utils/delivery';

import {
	cartProductsToOrderProducts,
	defaultPhoneObject,
	getInitialPhoneObject,
	getStateDataFromOrder,
} from './utils';

const CheckoutDataFetcher: React.FC = ({ children }) => {
	const dispatch = useDispatch();
	const { getPath, Routes, pushRoute } = useRoutes();
	const adyenPaymentMethods = useSelector(CheckoutSelectors.adyenPaymentMethods);
	const { search } = useLocation();
	const cartProducts = useSelector(CartSelectors.cartProducts);
	const cartProductsCount = useSelector(CartSelectors.cartProductsCount);
	const shopCountry = useSelector(ShopSelectors.shopCountry);
	const shopId = useSelector(ShopSelectors.shopId);
	const shopHasAdyenPayments = useSelector(ShopSelectors.shopHasAdyenPayments);
	const phoneObject = useSelector(ViewSelectors.phoneObject);
	const reservationCreated = useSelector(CartSelectors.reservationCreated);
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const delivery = useSelector(CartSelectors.delivery);
	const shopDeliveryOptions = useSelector(ShopSelectors.shopDeliveryOptionsData);
	const defaultDeliveryOption = useSelector(ShopSelectors.defaultDeliveryOption);
	const customThemeId = useSelector(ShopSelectors.activeLocationOnlineThemeId);

	const lang = useSelector(ViewSelectors.language);

	const queryString = new URLSearchParams(search);
	const queryOrderId = stringQueryParam(queryString.get('orderId'));
	const queryTransactionId = stringQueryParam(queryString.get('transactionId'));
	const externalHomeUrl = stringQueryParam(queryString.get('extHomeUrl'));

	const [checkoutDataFetched, setCheckoutDataFetched] = useState<boolean>(false);
	const [orderLoading, setOrderLoading] = useState<boolean>(Boolean(queryOrderId));
	const [pendingOrderError, setPendingOrderError] = useState<string | undefined>();
	const [hasPendingOrder, setHasPendingOrder] = useState<boolean>(false);
	const handlePaymentRedirect = useSelector(CheckoutSelectors.handlePaymentRedirect);
	const checkoutPricing = useSelector(CheckoutSelectors.checkoutPricing);

	const nonPendingOrderWithoutReservation = !hasPendingOrder && !reservationCreated;
	const adyenLoadNotInitialized = shopHasAdyenPayments && !adyenPaymentMethods;

	const getCustomCheckoutSettings = async (
		customThemeId: string,
	): Promise<CheckoutSettings | undefined> => {
		const customizerTheme = await api().customThemes.doc(customThemeId).get();
		return customizerTheme?.live.checkout;
	};

	useEffect(() => {
		if (checkoutDataFetched && shopHasAdyenPayments) {
			dispatch(updateAdyenPaymentMethods(pricingToAmountObject(checkoutPricing).charge));
		}
		// We should not re-mount this if chargeAmount changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, checkoutDataFetched, shopHasAdyenPayments]);

	useEffect(() => {
		if (!!externalHomeUrl) {
			saveToStorage(Storages.SESSION, StorageKeys.EXTERNAL_HOME_URL, externalHomeUrl);
		}
	}, [externalHomeUrl]);

	useEffect(() => {
		if (!!queryTransactionId) {
			dispatch(setTransactionId(queryTransactionId));
			dispatch(setHandlePaymentRedirect(true));
		}
	}, [dispatch, queryTransactionId]);

	useEffect(() => {
		const populateCheckoutStateFromPendingOrder = async (orderId: string, shopId: string) => {
			try {
				setOrderLoading(true);
				const order = await Callable.orders.pending.get({ orderId, shopId });
				if (order) {
					const {
						cartProducts,
						orderId,
						contactPerson,
						termsAcceptance,
						appliedDiscountCodes,
						phoneObject,
					} = getStateDataFromOrder(order);
					dispatch(clearCartItemsForCheckoutCartReplace());
					dispatch(addProductsToCart(cartProducts));
					dispatch(setOrderProducts(order.products));
					dispatch(setOrderId(orderId));
					dispatch(updateContactPerson(contactPerson));
					dispatch(updateTermsAcceptance(termsAcceptance));
					dispatch(updateRecurringPaymentsAcceptance(false));
					dispatch(replaceDiscountCodesInCartOnly(appliedDiscountCodes));
					dispatch(setPhoneObject(phoneObject));
					if (!!customThemeId) {
						try {
							const customCheckoutFieldsFromDb = await getCustomCheckoutSettings(customThemeId);
							if (!!customCheckoutFieldsFromDb) {
								dispatch(initialiseCustomCheckoutFields(customCheckoutFieldsFromDb));
							}
						} catch (error) {
							//eslint-disable-next-line
							console.error('Error fetching custom theme', error);
						}
					}

					if (!!order.orderDelivery) {
						const selectedDeliveryOption = getOrderDeliveryOption(shopDeliveryOptions, order);
						dispatch(
							ViewActions.setDeliveryOption(selectedDeliveryOption ?? defaultDeliveryOption),
						);
						dispatch(setDelivery(order.orderDelivery));
						if (!!order.orderDelivery.availablePickupSlots) {
							dispatch(
								ViewActions.setAvailablePickupSlots(order.orderDelivery.availablePickupSlots),
							);
						}
						if (!!order.orderDelivery.from) {
							dispatch(ViewActions.setPickupChecked(true));
						}
					}
					setHasPendingOrder(true);
				} else {
					const existingOrder = await getBookedOnlineOrderObject({ orderId, shopId });
					if (existingOrder) {
						handleExistingOrderFound(existingOrder);
						return;
					} else {
						setPendingOrderError('No pending order found');
					}
					dispatch(setHandlePaymentRedirect(false));
					setHasPendingOrder(false);
				}
				setOrderLoading(false);
				setCheckoutDataFetched(true);
			} catch (e) {
				errorHandler.report(e);
				setPendingOrderError('Error fetching pending order');
				dispatch(setHandlePaymentRedirect(false));
				setOrderLoading(false);
				setCheckoutDataFetched(true);
				setHasPendingOrder(false);
			}
		};

		const populateCheckoutStateFromNewOrder = async () => {
			const handlePhoneObjectUpdate = () => {
				if (isEqual(phoneObject, defaultPhoneObject)) {
					dispatch(setPhoneObject(getInitialPhoneObject(shopCountry)));
				}
			};

			const orderId = newFirestoreId();
			const contactPersonId = newFirestoreId();
			const orderProducts: OrderProduct[] = cartProductsToOrderProducts({
				cartProducts,
				shopperId: contactPersonId,
				orderId,
				shopId,
			});

			dispatch(setTransactionId(newFirestoreId()));
			dispatch(clearDiscountCodes());
			dispatch(setOrderId(orderId));
			dispatch(updateContactPerson({ id: contactPersonId }));
			dispatch(setOrderProducts(orderProducts));
			dispatch(updateTermsAcceptance({ accepted: false }));
			dispatch(updateRecurringPaymentsAcceptance(false));
			if (!!customThemeId) {
				try {
					const customCheckoutFieldsFromDb = await getCustomCheckoutSettings(customThemeId);
					if (!!customCheckoutFieldsFromDb) {
						dispatch(initialiseCustomCheckoutFields(customCheckoutFieldsFromDb));
					}
				} catch (error) {
					//eslint-disable-next-line
					console.error('Error fetching custom theme', error);
				}
			}

			handlePhoneObjectUpdate();
			if (!!delivery?.from) {
				dispatch(ViewActions.setPickupChecked(true));
			}
			setHasPendingOrder(false);
			setCheckoutDataFetched(true);
		};

		if (queryOrderId) {
			populateCheckoutStateFromPendingOrder(queryOrderId, shopId);
		} else {
			populateCheckoutStateFromNewOrder();
		}
		// Init checkout data only on mount
		return () => handleCheckoutDataFetcherUnMount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCheckoutDataFetcherUnMount = () => {
		removePickupFromCartDelivery();
		dispatch(ViewActions.setPickupChecked(false));
		dispatch(ViewActions.setAvailablePickupSlots(null));
	};

	const removePickupFromCartDelivery = useCallback(() => {
		if (!delivery || !selectedDeliveryOption) return;
		if (delivery?.from?.disabled) return;
		const updatedCartDelivery: CartDelivery = omit(delivery, 'from');
		dispatch(setDelivery(updatedCartDelivery));
	}, [selectedDeliveryOption, delivery, dispatch]);

	const handleExistingOrderFound = (order: OrderObject) => {
		dispatch(setOrder(order));
		dispatch(removeReservation());
		pushRoute(Routes.confirm, { query: { orderId: order.rentalInfo.id } });
	};

	if (!checkoutDataFetched || orderLoading || adyenLoadNotInitialized)
		return <Spinner color="#000" />;

	if (!!pendingOrderError) return <OrderFetchError />;

	if (
		!orderLoading &&
		!handlePaymentRedirect &&
		(cartProductsCount === 0 || nonPendingOrderWithoutReservation)
	) {
		return <Redirect to={getPath(Routes.browse)} />;
	}

	return (
		<AdyenProvider paymentMethods={adyenPaymentMethods?.data ?? null} lang={lang}>
			{children}
		</AdyenProvider>
	);
};

export default CheckoutDataFetcher;
