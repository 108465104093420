import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';
import { Prompt, useHistory } from 'react-router';
import * as CartActions from 'redux/actions/cart';
import * as DeliveryActions from 'redux/actions/view';
import { useDispatch, useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import BackdropDialog from 'common/components/BackdropDialog';
import { sendEventToParentPage } from 'common/modules/embed';
import { isRunInIframe } from 'common/utils/browserUtils';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';

const locationPathPrefix = '/l/';
const getLocationFromPath = (path: string | null): string | null => {
	const [, locationName] = path?.match(new RegExp(`${locationPathPrefix}(.*?)/`)) || [];
	return locationName ?? null;
};

const LocationChangeGuard = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const { locationName } = useRoutes();

	const dispatch = useDispatch();

	const itemsInCart = useSelector(CartSelectors.cartProductsCount);
	const cartLocationId = useSelector(CartSelectors.cartLocationId);
	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const locationsByName = useSelector(ShopSelectors.onlineLocationsByName);
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const shopId = useSelector(ShopSelectors.shopId);
	const mainLocationId = useSelector(ShopSelectors.shopMainLocation)?.id;
	const mainLocationName = (() => {
		return Object.keys(locationsByName).find(
			(name) => locationsByName[name]?.id === mainLocationId,
		);
	})();
	const currentLocationName = locationName ?? mainLocationName;

	const [confirmingUrl, setConfirmingUrl] = useState<string | null>(null);
	const confirmingLocationName = getLocationFromPath(confirmingUrl);

	const confirmingLocation = confirmingLocationName
		? locationsByName[confirmingLocationName]
		: null;

	useEffect(() => {
		if (
			(itemsInCart > 0 &&
				cartLocationId &&
				activeLocation &&
				cartLocationId !== activeLocation.id) ||
			(!!selectedDeliveryOption &&
				!!activeLocation?.id &&
				!selectedDeliveryOption.locationIds.includes(activeLocation.id))
		) {
			dispatch(CartActions.resetCart());
			dispatch(DeliveryActions.setDeliveryOption(null));
		}
	}, [dispatch, itemsInCart, cartLocationId, activeLocation, selectedDeliveryOption, shopId]);

	const changingStoreInfo = {
		cart_and_delivery: t(
			'location.changingStoreTextCartAndDelivery',
			'Your shopping bag and delivery option will be reset.',
		),
		cart: t('location.changingStoreText2', 'Your shopping bag will be reset.'),
		delivery: t('location.changingStoreTextDelivery', 'Your delivery option will be reset.'),
	};

	const changingStoreStatus =
		itemsInCart > 0 && !!selectedDeliveryOption
			? 'cart_and_delivery'
			: itemsInCart > 0
			? 'cart'
			: 'delivery';

	useEffect(() => {
		if (!!confirmingLocation && isRunInIframe()) {
			sendEventToParentPage({
				origin: 'rentle',
				type: 'trigger-scroll',
				payload: {
					offset: 0,
				},
			});
		}
	}, [confirmingLocation]);

	return (
		<>
			<Prompt
				when={itemsInCart > 0 || !!selectedDeliveryOption}
				message={(location, action) => {
					const newLocationName = getLocationFromPath(location.pathname);

					const notConfirmingUrl = location.pathname !== confirmingUrl;
					const differentLocations = (newLocationName ?? null) !== (currentLocationName ?? null);
					const locationNameExists = newLocationName && !!locationsByName[newLocationName];
					if (notConfirmingUrl && differentLocations && locationNameExists) {
						setConfirmingUrl(location.pathname);
						return false;
					}
					return true;
				}}
			/>
			<BackdropDialog
				isOpen={!!confirmingLocation}
				onClose={() => setConfirmingUrl(null)}
				label={t('location.changingStore', 'Changing store')}
				position={isRunInIframe() ? 'top' : 'center'}
				body={
					<>
						<Typography variant="body2">
							{t(
								'location.changingStoreText1',
								'You are about to change to another store location.',
							)}
						</Typography>
						<Box mt={2} />
						<Typography variant="body2">{changingStoreInfo[changingStoreStatus]}</Typography>
					</>
				}
				variant="danger"
				confirm={{
					label: t('location.confirmChangingStore', {
						locationName: confirmingLocation?.name ?? '',
						defaultValue: 'Change location to {{locationName}}',
					}),
					callback: () => {
						history.replace(confirmingUrl!);
					},
					closeAfterDone: true,
				}}
				cancel={{
					label: t('location.cancelChangingStore', {
						locationName: activeLocation?.name ?? '',
						defaultValue: 'Continue shopping at {{locationName}}',
					}),
					callback: () => {},
					closeAfterDone: true,
				}}
				buttonDirection="column-reverse"
				width="sm"
			/>
		</>
	);
};

export default LocationChangeGuard;
