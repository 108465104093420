import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';

import { MomentDateFormat, localFormat } from 'common/utils/dateUtils';

const useShopFormat = () => {
	const shopDateFormat = useSelector(ShopSelectors.shopDateFormat);

	const _localFormat = (
		dateTime: string | moment.Moment | undefined,
		dateFormat: MomentDateFormat,
	) => {
		return localFormat(dateTime, dateFormat, shopDateFormat);
	};

	return {
		shopDateFormat,
		localFormat: _localFormat,
	};
};

export default useShopFormat;
