import { useCallback, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';
import { useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import { AvailabilityRange } from 'common/api/frontend/inventory_new/availabilityRange';
import { isRentalPurchaseType, isSubscriptionPurchaseType } from 'common/modules/orders';
import { hasAutoRenewProductSubscription } from 'common/modules/subscriptions';
import { ByVariant, CartDelivery } from 'common/types';
import { CartRow } from 'services/types';
import {
	CartAvailabilityOpts,
	getCartProductAvailabilities,
	getCartProductsMaxAvailabilityCount,
	getPotentialPickupSlotsFromCartProducts,
} from 'utils/availability';
import { cartRowsToCartProducts } from 'utils/cart';

interface CartAvailabilities {
	cartRows: CartRow[];
	locationId: string | null;
}

const useCartAvailability = (props: CartAvailabilities) => {
	const { cartRows, locationId } = props;
	const stockProducts = useSelector(StockSelectors.stockProductsData);
	const shopId = useSelector(ShopSelectors.shopId);
	const onlineLocations = useSelector(ShopSelectors.onlineLocations);
	const timezone = useSelector(ShopSelectors.shopTimezone);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const reservationId = useSelector(CartSelectors.sessionId);
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const cartDelivery = useSelector(CartSelectors.delivery);
	const salesChannel = 'ONLINE';
	const [cartProductAvailabilities, setCartProductAvailabilites] = useState<
		(ByVariant<AvailabilityRange[]>[] | null)[]
	>([]);

	const availabilityOpts: CartAvailabilityOpts = useMemo(
		() => ({
			shopLocations: onlineLocations,
			stockProducts,
			shopId,
			reservationId,
			salesChannel,
			selectedDeliveryOption,
			cartDelivery,
			openingHours,
			timezone,
		}),
		[
			cartDelivery,
			onlineLocations,
			reservationId,
			selectedDeliveryOption,
			shopId,
			stockProducts,
			timezone,
			openingHours,
		],
	);

	const getCartRowAvailabilities = useCallback(async () => {
		if (!locationId) return undefined;
		const availabilities = await getCartProductAvailabilities({
			cartRows,
			locationId,
			opts: availabilityOpts,
		});
		setCartProductAvailabilites(availabilities);
		return getCartProductsMaxAvailabilityCount({
			cartRows,
			cartProductAvailabilities: availabilities,
			stockProducts,
		});
	}, [availabilityOpts, cartRows, locationId, stockProducts]);

	const refreshCartRowAvailabilities = useCallback(async () => {
		if (!isEmpty(cartProductAvailabilities)) {
			return getCartProductsMaxAvailabilityCount({
				cartRows,
				cartProductAvailabilities,
				stockProducts,
			});
		}
		return getCartRowAvailabilities();
	}, [cartProductAvailabilities, cartRows, getCartRowAvailabilities, stockProducts]);

	const getPotentialPickupSlotsForCart = async (cartDelivery?: CartDelivery) => {
		if (
			!locationId ||
			!selectedDeliveryOption ||
			hasAutoRenewProductSubscription(cartRowsToCartProducts(cartRows))
		) {
			return undefined;
		}

		const rentalOrSubscriptionCartRows = cartRows.filter(
			({ purchaseType }) =>
				isRentalPurchaseType(purchaseType) || isSubscriptionPurchaseType(purchaseType),
		);

		return await getPotentialPickupSlotsFromCartProducts({
			cartRows: rentalOrSubscriptionCartRows,
			locationId,
			opts: availabilityOpts,
			selectedDeliveryOption,
			cartDelivery,
			timezone,
		});
	};

	return { getCartRowAvailabilities, refreshCartRowAvailabilities, getPotentialPickupSlotsForCart };
};

export default useCartAvailability;
