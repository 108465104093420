import { createReducer } from '@reduxjs/toolkit';
import * as AnalyticsActions from 'redux/actions/analytics';

import { ById } from 'common/types';

export interface ItemListSelection {
	name: string;
	id: string;
	index?: number;
}

type AnalyticsState = {
	itemListSelectionById: ById<ItemListSelection | undefined>;
};

const INITIAL_STATE: AnalyticsState = {
	itemListSelectionById: {},
};

const AnalyticsReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(AnalyticsActions.setItemListSelection, (state, action) => {
		if (!action.payload) {
			state.itemListSelectionById = {};
		} else {
			const { productApiId, itemListSelection } = action.payload;
			state.itemListSelectionById[productApiId] = itemListSelection;
		}
	});
});

export default AnalyticsReducer;
