import React, { useCallback, useEffect, useState } from 'react';

import { Box, Collapse, Grid, Hidden, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { RiArrowDownSLine, RiRecordCircleLine, RiStore2Line, RiTruckLine } from 'react-icons/ri';
import * as CartActions from 'redux/actions/cart';
import * as ViewActions from 'redux/actions/view';
import { useDispatch, useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import { getDeliveryPricingString } from 'common/modules/delivery/utils';
import CenteredContent from 'components/CenteredContent';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';

import DeliveryOptionChangeGuard from './DeliveryOptionChangeGuard';
import DeliveryOptionModal from './DeliveryOptionModal';

interface Props {
	isScrolledDown?: boolean;
}

const DeliveryBar = (props: Props) => {
	const { isScrolledDown } = props;
	const classes = useStyles();
	const tooltipClasses = tooltipStyles();
	const dispatch = useDispatch();
	const { pushRoute, Routes, queryParams } = useRoutes();
	const deliveryOptions = useSelector(ShopSelectors.activeLocationDeliveryOptions);
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const cartProductsCount = useSelector(CartSelectors.cartProductsCount);
	const isDeliveryOnly = useSelector(ShopSelectors.isDeliveryOnly);
	const defaultDeliveryOption = useSelector(ShopSelectors.defaultDeliveryOption);
	const currency = useSelector(ShopSelectors.shopCurrency);

	const [isDeliveryOptionChangeGuardOpen, setIsDeliveryOptionChangeGuardOpen] = useState(false);
	const [isDeliveryOptionModalOpen, setIsDeliveryOptionModalOpen] = useState(false);

	const { t, getTranslation } = useTranslation();

	const handleDeliveryOptionChange = useCallback(
		(id: string | undefined) => {
			dispatch(CartActions.resetCart());

			if (!!id) {
				const option = deliveryOptions?.find((o) => o.id === id);
				dispatch(ViewActions.setDeliveryOption(option ?? defaultDeliveryOption));
			} else {
				dispatch(ViewActions.setDeliveryOption(defaultDeliveryOption));
			}

			if (queryParams.includes('category')) {
				pushRoute(Routes.browse, { query: { category: 'all' } });
			} else {
				pushRoute(Routes.browse);
			}
		},
		[Routes.browse, defaultDeliveryOption, deliveryOptions, dispatch, pushRoute, queryParams],
	);

	useEffect(() => {
		if (!deliveryOptions || !selectedDeliveryOption) return;

		const latestDeliveryOption = deliveryOptions.find((o) => o.id === selectedDeliveryOption.id);
		const wasDeliveryOptionUpdated = !isEqual(latestDeliveryOption, selectedDeliveryOption);

		if (cartProductsCount === 0 && !!latestDeliveryOption && wasDeliveryOptionUpdated) {
			dispatch(ViewActions.setDeliveryOption(latestDeliveryOption));
		}
	}, [cartProductsCount, deliveryOptions, dispatch, selectedDeliveryOption]);

	useEffect(() => {
		const selectedDeliveryOptionNotFound =
			!!deliveryOptions &&
			!!selectedDeliveryOption &&
			!deliveryOptions.find((o) => o.id === selectedDeliveryOption.id);

		if (
			isDeliveryOnly &&
			(!selectedDeliveryOption || deliveryOptions?.length === 0 || selectedDeliveryOptionNotFound)
		) {
			dispatch(ViewActions.setDeliveryOption(defaultDeliveryOption));
		}
	}, [
		defaultDeliveryOption,
		deliveryOptions,
		deliveryOptions?.length,
		dispatch,
		isDeliveryOnly,
		selectedDeliveryOption,
	]);

	useEffect(() => {
		const allDeliveryOptionsDeleted = deliveryOptions?.length === 0;
		const selectedDeliveryOptionDeleted =
			!!selectedDeliveryOption &&
			!!deliveryOptions?.length &&
			!deliveryOptions?.find((o) => o.id === selectedDeliveryOption?.id);

		if (
			!isDeliveryOnly &&
			((!!selectedDeliveryOption && allDeliveryOptionsDeleted) ||
				(cartProductsCount === 0 && selectedDeliveryOptionDeleted))
		) {
			handleDeliveryOptionChange(undefined);
		}
	}, [
		cartProductsCount,
		deliveryOptions,
		handleDeliveryOptionChange,
		isDeliveryOnly,
		selectedDeliveryOption,
	]);

	const switchToBrowse = () => {
		if (!!selectedDeliveryOption) {
			return cartProductsCount > 0
				? setIsDeliveryOptionChangeGuardOpen(true)
				: handleDeliveryOptionChange(undefined);
		} else {
			if (queryParams.includes('category')) {
				pushRoute(Routes.browse, { query: { category: 'all' } });
			} else {
				pushRoute(Routes.browse);
			}
		}
	};

	const confirmDeliveryOptionChangeGuard = () => {
		handleDeliveryOptionChange(undefined);
		setIsDeliveryOptionChangeGuardOpen(false);
	};

	const confirmDeliveryOptionModal = (id?: string) => {
		handleDeliveryOptionChange(id);
		setIsDeliveryOptionModalOpen(false);
	};

	const renderMobile = () => (
		<Box className={classes.outer}>
			{!isDeliveryOnly && (
				<Collapse
					appear={false}
					in={!isScrolledDown || (!!isScrolledDown && !selectedDeliveryOption)}
				>
					<Box className={classes.barContainerMobile}>
						<Box
							className={classNames(classes.optionContainerMobile, {
								[classes.selected]: !selectedDeliveryOption,
								[classes.barContainerMobileSpace]: !selectedDeliveryOption,
							})}
							onClick={switchToBrowse}
						>
							<RiStore2Line className={classes.icon} size={19} />
							<Typography variant="body1" display="inline" className={classes.mobileText}>
								{t('delivery.storePickup', 'Store pickup')}
							</Typography>
						</Box>
						<Box
							className={classNames(classes.optionContainerMobile, {
								[classes.selectedDelivery]: !!selectedDeliveryOption,
								[classes.barContainerMobileSpace]: !selectedDeliveryOption,
							})}
							onClick={() => !selectedDeliveryOption && setIsDeliveryOptionModalOpen(true)}
						>
							<RiTruckLine className={classes.icon} size={18} />
							<Typography variant="body1" display="inline" className={classes.mobileText}>
								{t('delivery.delivery', 'Delivery')}
							</Typography>
						</Box>
					</Box>
				</Collapse>
			)}
			{selectedDeliveryOption && (
				<Box
					className={classNames(classes.editOptionContainer, classes.editOptionContainerMobile)}
					onClick={() => setIsDeliveryOptionModalOpen(true)}
				>
					{isDeliveryOnly ? (
						<RiTruckLine className={classes.icon} size={18} />
					) : (
						<RiRecordCircleLine className={classes.icon} size={16} />
					)}
					<Typography variant="body1" display="inline" className={classes.mobileText}>
						{getTranslation(selectedDeliveryOption.name)}
					</Typography>
					<Typography variant="body2" className={classes.deliveryPrice}>
						{getDeliveryPricingString(selectedDeliveryOption, currency)}
					</Typography>
					{isDeliveryOnly && <RiArrowDownSLine className={classes.deliveryOptionArrow} size={18} />}
				</Box>
			)}
		</Box>
	);

	const renderDesktop = () => (
		<Box className={classes.outer}>
			<CenteredContent className={classes.barContainerDesktop}>
				<Grid container justifyContent="center" className={classes.buttonGrid}>
					{selectedDeliveryOption && !isDeliveryOnly && <Box className={classes.emptyFlex} />}
					{!isDeliveryOnly && (
						<Box display="flex">
							<Box
								className={classNames(classes.optionContainer, {
									[classes.selected]: !selectedDeliveryOption,
								})}
								onClick={switchToBrowse}
							>
								<RiStore2Line className={classes.icon} size={18} />
								<Typography variant="body2" display="inline">
									{t('delivery.storePickup', 'Store pickup')}
								</Typography>
							</Box>

							<Tooltip
								title={
									<Typography variant="body2" display="inline">
										{t(
											'delivery.exploreWhatCanBeDelivered',
											'Explore products that can be delivered to you',
										)}
									</Typography>
								}
								classes={tooltipClasses}
								enterDelay={50}
								leaveDelay={200}
								placement="bottom-start"
								arrow
							>
								<Box
									className={classNames(classes.optionContainer, {
										[classes.selectedDelivery]: !!selectedDeliveryOption,
									})}
									onClick={() => !selectedDeliveryOption && setIsDeliveryOptionModalOpen(true)}
								>
									<RiTruckLine className={classes.icon} size={18} />

									<Typography variant="body2" display="inline">
										{t('delivery.delivery', 'Delivery')}
									</Typography>
								</Box>
							</Tooltip>
						</Box>
					)}
					{selectedDeliveryOption && (
						<Box
							className={classNames(classes.editOptionContainer, {
								[classes.justifyContentFlexStart]: isDeliveryOnly,
							})}
							onClick={() => setIsDeliveryOptionModalOpen(true)}
						>
							{isDeliveryOnly ? (
								<RiTruckLine className={classes.icon} size={18} />
							) : (
								<RiRecordCircleLine className={classes.icon} size={16} />
							)}
							<Typography variant="body2" display="inline">
								{getTranslation(selectedDeliveryOption.name)}
							</Typography>
							<Typography variant="body2" className={classes.deliveryPrice}>
								{getDeliveryPricingString(selectedDeliveryOption, currency)}
							</Typography>
							{isDeliveryOnly && (
								<RiArrowDownSLine className={classes.deliveryOptionArrow} size={18} />
							)}
						</Box>
					)}
				</Grid>
			</CenteredContent>
		</Box>
	);

	return (
		<>
			<Hidden lgDown>{renderDesktop()}</Hidden>
			<Hidden lgUp>{renderMobile()}</Hidden>

			<DeliveryOptionChangeGuard
				isOpen={isDeliveryOptionChangeGuardOpen}
				confirm={confirmDeliveryOptionChangeGuard}
				cancel={() => setIsDeliveryOptionChangeGuardOpen(false)}
			/>
			{!!deliveryOptions && !!deliveryOptions.length && (
				<DeliveryOptionModal
					isOpen={isDeliveryOptionModalOpen}
					confirm={confirmDeliveryOptionModal}
					cancel={() => setIsDeliveryOptionModalOpen(false)}
				/>
			)}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		outer: {
			borderBottom: `1px solid ${theme.palette.border.paper}`,
		},
		barContainerDesktop: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		barContainerMobile: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			'& $optionContainer': {
				padding: theme.spacing(0.5),
			},
		},
		buttonGrid: {
			justifyContent: 'center',
		},
		optionContainer: {
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(1, 2),
			border: '1px solid #eee',
			borderRadius: theme.spacing(3),
			margin: theme.spacing(2, 1),
			cursor: 'pointer',
		},
		optionContainerMobile: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			padding: theme.spacing(1, 2),
			border: '1px solid #eee',
			borderRadius: theme.spacing(3),
			margin: theme.spacing(2, 1, 0, 1),
			cursor: 'pointer',
		},
		barContainerMobileSpace: {
			margin: theme.spacing(2, 1),
		},
		editOptionContainer: {
			display: 'flex',
			alignItems: 'center',
			color: '#0050ff',
			cursor: 'pointer',
			flex: 1,
			justifyContent: 'flex-end',
			margin: theme.spacing(2, 0),
			padding: theme.spacing(1, 0),
		},
		justifyContentFlexStart: {
			justifyContent: 'flex-start',
		},
		editOptionContainerMobile: {
			padding: theme.spacing(1, 0),
			margin: theme.spacing(1, 0),
			justifyContent: 'center',
		},
		selected: {
			borderColor: theme.palette.colors.tundra.main,
		},
		selectedDelivery: {
			color: '#0050ff',
			borderColor: '#0050ff',
			backgroundColor: '#fafcff',
		},
		icon: {
			marginRight: theme.spacing(1),
		},
		deliveryOptionArrow: {
			marginLeft: theme.spacing(0.5),
		},
		mobileText: {
			fontSize: '1.4rem',
		},
		emptyFlex: {
			flex: 1,
		},
		deliveryPrice: {
			marginLeft: theme.spacing(0.5),
		},
	}),
);

const tooltipStyles = makeStyles((theme: Theme) =>
	createStyles({
		tooltip: {
			backgroundColor: 'black',
			maxWidth: 150,
			marginTop: theme.spacing(2),
			padding: theme.spacing(2),
		},
		arrow: {
			color: 'black',
		},
	}),
);

export default DeliveryBar;
