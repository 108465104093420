import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';

import { ReduxDispatch, ReduxState } from './types';

type DefaultThunkApiConfig = {
	dispatch: ReduxDispatch;
	state: ReduxState;
};

export const createThunk = <
	Returned,
	ThunkArg = void,
	ThunkConfig extends DefaultThunkApiConfig = DefaultThunkApiConfig
>(
	type: string,
	payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkConfig>,
) => createAsyncThunk<Returned, ThunkArg, ThunkConfig>(type, payloadCreator);
