import { getProductVariants } from 'common/modules/products/variants';
import { OrderProduct, ProductApi, Shopper } from 'common/types';
import { isKeycardProduct } from 'common/utils/liftTicketUtils';
import { getSortedRequiredDetails } from 'common/utils/userDetails';

const isMultipleProducts = (products: OrderProduct[]) => {
	return products.filter((p) => !isKeycardProduct(p)).length > 1;
};

const segmentNotNull = (products: OrderProduct[]) => {
	return products.some((product) => Boolean(product.segment && product.segment !== null));
};

export const someProductNeedsUserDetails = (products: OrderProduct[]) => {
	return products.some((product) => {
		const a = Boolean(product.userDetails && product.userDetails.length);
		const b =
			product.set &&
			product.setProducts.some((setProduct) =>
				Boolean(setProduct.userDetails && setProduct.userDetails.length),
			);
		return a || b;
	});
};

export const multipleProductsAndSegmentNotNull = (products: OrderProduct[]) => {
	return isMultipleProducts(products) && segmentNotNull(products);
};

export const areAllProductsSelected = (
	products: OrderProduct[],
	shoppers: Shopper[],
	activeShopper: Shopper,
) =>
	products.every((product) =>
		shoppers.some((shopper) =>
			shopper.productIds.some((pId) => pId === product.id && shopper.id !== activeShopper.id),
		),
	);

export const getUnchosenProducts = (shoppers: Shopper[], products: OrderProduct[]) => {
	const alreadyChosenProductsIds = shoppers.map((shopper) => shopper.productIds).flat();
	return products.filter((p) => !alreadyChosenProductsIds.includes(p.id));
};

export const getShopperProducts = (allProducts: OrderProduct[], shopper: Shopper) =>
	allProducts.filter((p) => shopper.productIds.some((id) => id === p.id));

export const getShopperProductsNeedDetails = (shopperProducts: OrderProduct[]) =>
	!!getSortedRequiredDetails(shopperProducts).length;

export const productHasVariantsWithPriceIncrease = (product: ProductApi) => {
	const productVariants = getProductVariants(product);
	return (
		productVariants.length > 1 &&
		productVariants
			.map((variant) => variant.rentals?.priceIncrease ?? 0)
			.reduce((prev, current) => prev + current) > 0
	);
};
