import React, { useEffect, useRef, useState } from 'react';

import AdyenCheckout from '@adyen/adyen-web';
import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TFunction } from 'i18next';

import GooglePayDark from 'common/assets/icons/gpay_dark_short.svg';
import { useAdyen } from 'common/hooks/useAdyen';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { AmountObject } from 'common/types';
import { isDevEnv } from 'common/utils/common';

interface Props {
	adyenCheckout: AdyenCheckout;
	paymentError?: string;
	t: TFunction;
	amount: AmountObject;
	merchantName: string;
	loading: boolean;
	disabled?: boolean;
	onSubmit: () => void;
}

const GooglePay = (props: Props) => {
	const classes = useStyles();
	const { setMethodSubmitAction } = useAdyen();
	const {
		adyenCheckout,
		t,
		paymentError,
		amount,
		merchantName,
		loading,
		disabled,
		onSubmit,
	} = props;
	const paymentElementRef = useRef<HTMLDivElement>(null);
	const [isAvailable, setIsAvailable] = useState<boolean>(false);

	useEffect(() => {
		const renderPaymentForm = async () => {
			const devEnv = isDevEnv();
			const googlepay = adyenCheckout.create('paywithgoogle', {
				environment: devEnv ? 'TEST' : 'PRODUCTION',
				amount: {
					currency: amount.currency,
					value: amount.value,
				},
				configuration: {
					gatewayMerchantId: 'RentleECOM', // Adyen merchant or company account name
					...(!devEnv && { merchantIdentifier: 'BCR2DN6TVOF3J7JQ' }), // Required for PRODUCTION. Remove this field in TEST. Your Google Merchant ID as described in https://developers.google.com/pay/api/web/guides/test-and-deploy/deploy-production-environment#obtain-your-merchantID
					merchantName,
				},
				allowedCardNetworks: ['MASTERCARD', 'VISA'],
				existingPaymentMethodRequired: false,
			});
			try {
				const googlePayAvailable = !!(await googlepay.isAvailable());
				setIsAvailable(googlePayAvailable);
				if (googlePayAvailable && !!paymentElementRef.current) {
					const paymentMethod = googlepay.mount(paymentElementRef.current);
					setMethodSubmitAction('GOOGLEPAY', paymentMethod.submit);
				}
			} catch (e) {
				errorHandler.report(e);
			}
		};
		renderPaymentForm();
	}, [adyenCheckout, amount.currency, amount.value, merchantName, setMethodSubmitAction]);

	const handleSubmit = () => {
		if (loading || disabled) return;
		onSubmit();
	};

	return (
		<>
			<Box
				component="div"
				style={{ display: !isAvailable ? 'none' : undefined }}
				ref={paymentElementRef}
				className={classes.buttonGooglePay}
				onClick={handleSubmit}
			></Box>
			{paymentError ? (
				<Box mb={2}>
					<Typography color="error" align="center">
						{t('common:payment.paymentError', 'Payment error')} ({props.paymentError})
					</Typography>
				</Box>
			) : null}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		/* Indicates how Google Pay button will appear */
		buttonGooglePay: {
			width: '100%',
			height: 50,
			display: 'block',
			backgroundColor: '#000',
			backgroundImage: `url(${GooglePayDark})`,
			backgroundOrigin: 'content-box',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'auto 54%',
			border: 0,
			borderRadius: 0,
			outline: 0,
			cursor: 'pointer',
			margin: theme.spacing(0, 'auto'),
			marginBottom: theme.spacing(2),
			'&:hover': {
				backgroundColor: '#3c4043',
			},
			'&:active': {
				backgroundColor: '#5f6368',
			},
		},
		hidden: {
			display: 'none',
		},
	}),
);

export default GooglePay;
