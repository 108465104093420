import { createReducer } from '@reduxjs/toolkit';
import * as ShopActions from 'redux/actions/shop';
import { LoadingData } from 'redux/types';

import { CustomizerTheme } from 'common/modules/customization/types';
import { OpeningHoursDoc } from 'common/modules/openingHours';
import { RestrictedFeature } from 'common/modules/plans';
import { DeliveryOption, ShopPublicInfo } from 'common/types';
import { ShopUrlApiWithStoreUrl } from 'services/types';

type ShopState = {
	loading: boolean;
	error: string | null;
	publicInfo: LoadingData<ShopPublicInfo, string>;
	openingHours: LoadingData<OpeningHoursDoc[], string>;
	shopUrls: LoadingData<ShopUrlApiWithStoreUrl, string>;
	isLiteShop: LoadingData<boolean, string>;
	hasActiveDiscountCodes: LoadingData<boolean, string>;
	enabledFeatures: LoadingData<RestrictedFeature[], string>;
	deliveryOptions: LoadingData<DeliveryOption[], string>;
	stripeAccountId: LoadingData<string | null, string>;
	customThemes: LoadingData<CustomizerTheme[], string>;
	rentleCustomTheme: LoadingData<CustomizerTheme | null, string>;
};

const INITIAL_STATE: ShopState = {
	loading: true,
	error: null,
	shopUrls: {
		data: null,
		error: null,
		loading: false,
	},
	publicInfo: {
		data: null,
		error: null,
		loading: false,
	},
	openingHours: {
		data: null,
		error: null,
		loading: false,
	},
	isLiteShop: {
		data: null,
		error: null,
		loading: false,
	},
	hasActiveDiscountCodes: {
		data: null,
		error: null,
		loading: false,
	},
	stripeAccountId: {
		data: null,
		error: null,
		loading: false,
	},
	enabledFeatures: {
		data: null,
		error: null,
		loading: false,
	},
	deliveryOptions: {
		data: null,
		error: null,
		loading: false,
	},
	customThemes: {
		data: null,
		error: null,
		loading: false,
	},
	rentleCustomTheme: {
		data: null,
		error: null,
		loading: false,
	},
};

const ShopReducer = createReducer(INITIAL_STATE, (builder) => {
	builder
		.addCase(ShopActions.updateShopListeners.pending, (state, action) => {
			state.loading = true;
			state.error = null;
			state.shopUrls.loading = true;
			state.shopUrls.error = null;
			state.publicInfo.loading = true;
			state.publicInfo.error = null;
			state.openingHours.loading = true;
			state.openingHours.error = null;
			state.isLiteShop.loading = true;
			state.isLiteShop.error = null;
			state.enabledFeatures.loading = true;
			state.enabledFeatures.error = null;
			state.deliveryOptions.loading = true;
			state.deliveryOptions.error = null;
		})
		.addCase(ShopActions.updateShopListeners.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload ?? 'Error updating shop listeners';
		})
		.addCase(ShopActions.updateShopListeners.fulfilled, (state, action) => {
			state.loading = false;
		});

	builder
		.addCase(ShopActions.setShopPublicInfo, (state, action) => {
			state.publicInfo.data = action.payload ?? null;
			state.publicInfo.loading = false;
		})
		.addCase(ShopActions.setShopPublicInfoError, (state, action) => {
			state.publicInfo.loading = false;
			state.publicInfo.error = action.payload;
		});

	builder
		.addCase(ShopActions.setOpeningHours, (state, action) => {
			state.openingHours.data = action.payload ?? null;
			state.openingHours.loading = false;
		})
		.addCase(ShopActions.setOpeningHoursError, (state, action) => {
			state.openingHours.loading = false;
			state.openingHours.error = action.payload;
		});

	builder
		.addCase(ShopActions.setDeliveryOptions, (state, action) => {
			state.deliveryOptions.data = action.payload ?? null;
			state.deliveryOptions.loading = false;
		})
		.addCase(ShopActions.setDeliveryOptionsError, (state, action) => {
			state.deliveryOptions.loading = false;
			state.deliveryOptions.error = action.payload;
		});

	builder
		.addCase(ShopActions.setShopUrls, (state, action) => {
			state.shopUrls.data = action.payload ?? null;
			state.shopUrls.loading = false;
		})
		.addCase(ShopActions.setShopUrlsError, (state, action) => {
			state.shopUrls.error = action.payload;
			state.shopUrls.loading = false;
		});

	builder
		.addCase(ShopActions.updateShopFeatures.pending, (state, action) => {
			state.enabledFeatures.loading = true;
			state.enabledFeatures.error = null;
		})
		.addCase(ShopActions.updateShopFeatures.fulfilled, (state, action) => {
			state.enabledFeatures.data = action.payload;
			state.enabledFeatures.loading = false;
		})
		.addCase(ShopActions.updateShopFeatures.rejected, (state, action) => {
			state.enabledFeatures.error = 'Error updating shop features';
			state.enabledFeatures.loading = false;
		});

	builder
		.addCase(ShopActions.updateIsLiteShop.pending, (state, action) => {
			state.isLiteShop.loading = true;
			state.isLiteShop.error = null;
		})
		.addCase(ShopActions.updateIsLiteShop.rejected, (state, action) => {
			state.isLiteShop.loading = false;
			state.isLiteShop.error = 'Error updating shop status';
		})
		.addCase(ShopActions.updateIsLiteShop.fulfilled, (state, action) => {
			state.isLiteShop.loading = false;
			state.isLiteShop.data = action.payload;
		});

	builder
		.addCase(ShopActions.updateShopHasActiveDiscountCodes.pending, (state, action) => {
			state.hasActiveDiscountCodes.loading = true;
			state.hasActiveDiscountCodes.error = null;
		})
		.addCase(ShopActions.updateShopHasActiveDiscountCodes.rejected, (state, action) => {
			state.hasActiveDiscountCodes.loading = false;
			state.hasActiveDiscountCodes.error = 'Error getting discount code status';
		})
		.addCase(ShopActions.updateShopHasActiveDiscountCodes.fulfilled, (state, action) => {
			state.hasActiveDiscountCodes.loading = false;
			state.hasActiveDiscountCodes.error = null;
			state.hasActiveDiscountCodes.data = action.payload;
		});

	builder
		.addCase(ShopActions.getShopStripeAccountId.pending, (state, action) => {
			state.stripeAccountId = { loading: true, error: null, data: null };
		})
		.addCase(ShopActions.getShopStripeAccountId.rejected, (state, action) => {
			state.stripeAccountId = { loading: false, error: 'Error getting stripe account', data: null };
		})
		.addCase(ShopActions.getShopStripeAccountId.fulfilled, (state, action) => {
			state.stripeAccountId = { loading: false, error: null, data: action.payload };
		});

	builder
		.addCase(ShopActions.setOnlineThemes, (state, action) => {
			state.customThemes.data = action.payload ?? null;
			state.customThemes.loading = false;
		})
		.addCase(ShopActions.setOnlineThemeError, (state, action) => {
			state.customThemes.loading = false;
			state.customThemes.error = action.payload;
		});
	builder
		.addCase(ShopActions.setRentleCustomTheme, (state, action) => {
			state.rentleCustomTheme.data = action.payload ?? null;
			state.rentleCustomTheme.loading = false;
		})
		.addCase(ShopActions.setRentleCustomThemeError, (state, action) => {
			state.rentleCustomTheme.loading = false;
			state.rentleCustomTheme.error = action.payload;
		});
});

export default ShopReducer;
