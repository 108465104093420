import React from 'react';

import { Box, Icon, Stack, styled } from '@mui/material';

import { ReactComponent as ApplePayIcon } from 'common/assets/icons/icon-applepay.svg';
import { ReactComponent as GooglePayIcon } from 'common/assets/icons/icon-googlepay.svg';
import { ReactComponent as McIcon } from 'common/assets/icons/payment-mc.svg';
import { ReactComponent as PaytrailIcon } from 'common/assets/icons/payment-paytrail.svg';
import { ReactComponent as TrustlyIcon } from 'common/assets/icons/payment-trustly.svg';
import { ReactComponent as VisaIcon } from 'common/assets/icons/payment-visa.svg';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';

interface PaymentIconProps {
	paymentMethod: ShopOnlinePaymentMethodObject;
	isInFooter?: boolean;
}

const PaymentMethodIcons = (props: PaymentIconProps) => {
	const { paymentMethod, isInFooter } = props;

	const renderVisaAndMastercardIcons = () => {
		return (
			<Stack direction="row" spacing={1}>
				<IconsContainer>
					<Icon sx={{ width: '100%', height: '100%' }}>
						<VisaIcon />
					</Icon>
				</IconsContainer>
				<IconsContainer>
					<Icon sx={{ width: '100%', height: '100%' }}>
						<McIcon />
					</Icon>
				</IconsContainer>
			</Stack>
		);
	};

	const renderPaymentMethodIcons = () => {
		switch (paymentMethod.id) {
			case 'CARD_ONLINE':
				return (
					<>
						{isInFooter && (
							<>
								<IconsContainer>
									<Icon sx={{ width: '100%', height: '100%' }}>
										<ApplePayIcon />
									</Icon>
								</IconsContainer>
								<IconsContainer>
									<Icon sx={{ width: '100%', height: '100%' }}>
										<GooglePayIcon />
									</Icon>
								</IconsContainer>
							</>
						)}
						{renderVisaAndMastercardIcons()}
					</>
				);
			case 'CARD_ONLINE_STRIPE': {
				return (
					<Stack direction="row" spacing={1}>
						<IconsContainer>
							<Icon sx={{ width: '100%', height: '100%' }}>
								<VisaIcon />
							</Icon>
						</IconsContainer>
						<IconsContainer>
							<Icon sx={{ width: '100%', height: '100%' }}>
								<McIcon />
							</Icon>
						</IconsContainer>
					</Stack>
				);
			}
			case 'TRUSTLY': {
				return (
					<IconsContainer>
						<Icon sx={{ width: '100%', height: '100%' }}>
							<TrustlyIcon />
						</Icon>
					</IconsContainer>
				);
			}
			/**
			 * Disabled until MobilePay is a valid payment option
			
			case 'MOBILEPAY': {
				return (
					<IconsContainer>
						<Icon sx={{ width: '100%', height: '100%' }}>
							<MobilePayIcon />
						</Icon>
					</IconsContainer>
				);
			}
			 */
			case 'PAYTRAIL': {
				return (
					<IconsContainer>
						<Icon sx={{ width: '100%', height: '100%' }}>
							<PaytrailIcon />
						</Icon>
					</IconsContainer>
				);
			}
			default:
				return null;
		}
	};

	return renderPaymentMethodIcons();
};

const IconsContainer = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	flex: 1,
	maxWidth: 100,
	'& > *': {
		flex: 1,
		display: 'flex',

		'& svg': {
			maxHeight: '20px',
		},
		justifyContent: 'flex-end',
		fontSize: '2.8rem',
		'&:last-child': {
			marginRight: 0,
		},
	},
});

export default PaymentMethodIcons;
