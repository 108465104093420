import { hasWindow } from './browserUtils';
import { notUndefined } from './common';

export const getFaviconLinks = (args: {
	merchantName: string | undefined;
	siteIcon: string | undefined;
	addManifest: boolean;
}) => {
	const { merchantName, siteIcon, addManifest } = args;
	if (!siteIcon) return undefined;

	const getManifestUrl = (args: { name: string; icon: string }) => {
		const { name, icon } = args;
		const dynamicManifest = {
			name,
			short_name: name,
			icons: [
				{
					src: `${icon}`,
					sizes: '144x144',
					type: 'image/png',
				},
			],
			theme_color: '#ffffff',
			background_color: '#ffffff',
			display: 'standalone',
		};

		const stringifiedManifest = JSON.stringify(dynamicManifest);
		// URL is part of Web API, so we return early if we are not in browser environment
		if (!hasWindow()) return undefined;
		try {
			const blob = new Blob([stringifiedManifest], { type: 'application/json' });
			return URL.createObjectURL(blob);
		} catch (e) {
			return undefined;
		}
	};

	const manifestUrl =
		merchantName && addManifest
			? getManifestUrl({ name: merchantName, icon: siteIcon })
			: undefined;

	const links = [
		`<link rel="shortcut icon" href=${siteIcon} />`,
		`<link rel="icon" type="image/png" sizes="16x16" href=${siteIcon} />`,
		`<link rel="icon" type="image/png" sizes="32x32" href=${siteIcon} />`,
		`<link rel="apple-touch-icon" type="image/png" sizes="180x180" href=${siteIcon} />`,
		`<link rel="apple-touch-icon" type="image/png" sizes="167x167" href=${siteIcon} />`,
		`<link rel="mask-icon" href=${siteIcon} color="#275F69">`,
		manifestUrl ? `<link rel="manifest" href="${manifestUrl}" />` : undefined,
	].filter(notUndefined);

	const linksWithReactHelmetDataAttribute = links.map((link) =>
		link.replace('/>', 'data-react-helmet="true" />'),
	);

	return linksWithReactHelmetDataAttribute;
};
