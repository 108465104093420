import React from 'react';

import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import { isRunInIframe } from 'common/utils/browserUtils';
import { useRoutes } from 'services/routing/useRoutes';

import FooterComponent from './Footer';

const FooterRouter = () => {
	const { getRoute, Routes } = useRoutes();
	const isEmbedded = isRunInIframe();
	const cookieSettings = useSelector(ShopSelectors.cookieSettings);
	const shopPublicInfoLoading = useSelector(ShopSelectors.shopPublicInfoLoading);

	const categoriesLoading = useSelector(StockSelectors.stockCategories).loading;
	const productsLoading = useSelector(StockSelectors.stockProducts).loading;
	const shopFeaturesLoading = useSelector(ShopSelectors.shopFeaturesLoading);
	const embeddedWithoutCookieConsent = isEmbedded && cookieSettings?.embed !== 'SHOW_NOTICE';
	if (embeddedWithoutCookieConsent) return null;
	// If shop or stock is still loading, we don't want to display a footer as the only content

	if (shopFeaturesLoading || categoriesLoading || productsLoading || shopPublicInfoLoading) {
		return null;
	}
	return (
		<>
			<Switch>
				<Route path={getRoute(Routes.checkout)} component={CheckoutFooter} />
				<Route component={DefaultFooter} />
			</Switch>
		</>
	);
};

const CheckoutFooter = () => {
	const locationId = useSelector(CheckoutSelectors.startLocationId);

	return <FooterComponent fullShopInfo={{ show: true, locationId }} />;
};

const DefaultFooter = () => {
	return <FooterComponent />;
};

export default FooterRouter;
