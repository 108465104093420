import { getFunctionsBaseUrl } from 'common/api/configs';
import { useFunctionsEmulator } from 'common/frontend/firebase/init';
import { Environment } from 'common/types';
import { AuthProfile, OauthTokenResponse } from 'common/types/external/auth';
import { isDevEnv } from 'common/utils/common';

import { getRedirectUrl } from './utils';

export type ExternalAuthProvider = 'DECATHLON' | 'MOCK';

const env = process.env.REACT_APP_ENV as Environment;
const functionsEnv = env === 'local' ? (useFunctionsEmulator ? 'local' : 'development') : env;
const functionsBaseUrl = getFunctionsBaseUrl(functionsEnv);

interface ExternalAuthInfo {
	getOauthUrl: (state?: string) => string;
	getLogoutUrl: (token: string, state?: string) => string | undefined;
	getProfileInfo: (token: string) => Promise<AuthProfile>;
	getAuthToken: (code: string) => Promise<OauthTokenResponse>;
	updateProfileInfo: undefined | ((token: string, data: Partial<AuthProfile>) => Promise<void>);
}

type OAuthGetQueryParamsBase = Record<string, string> & {
	client_id: string;
	redirect_uri: string;
	response_type: string;
};

type OAuthGetQueryParams =
	| OAuthGetQueryParamsBase
	| (OAuthGetQueryParamsBase & { scope: string })
	| (OAuthGetQueryParamsBase & { state: string })
	| (OAuthGetQueryParamsBase & { scope: string; state: string });

const getMockAuthConfigs = (): ExternalAuthInfo => {
	const apiBaseUrl = `${functionsBaseUrl}/externalAuth-mock`;
	const clientId = '123';
	const authRedirectUrl = getRedirectUrl({
		origin: window.location.origin,
		path: '/_auth/mock',
	});
	return {
		getOauthUrl: (state?: string) => {
			const oauthBaseUrl = 'https://oauth.mocklab.io/oauth/authorize';
			const responseType = 'code';
			const queryParams: OAuthGetQueryParams = {
				client_id: clientId,
				redirect_uri: authRedirectUrl,
				response_type: responseType,
				...(state && { state }),
			};
			const queryParamString = new URLSearchParams(queryParams).toString();
			const oauthUrl = `${oauthBaseUrl}?${queryParamString}`;
			return oauthUrl;
		},
		getLogoutUrl: (_token: string) => {
			return undefined;
		},
		getAuthToken: async (code: string) => {
			const queryParams = new URLSearchParams({
				client_id: clientId,
				code,
				redirect_uri: authRedirectUrl,
			}).toString();
			const response = await fetch(`${apiBaseUrl}/token?${queryParams}`);
			if (response.ok) {
				const result = await (response.json() as Promise<OauthTokenResponse>);
				return result;
			} else {
				throw new Error('Unable to get auth token');
			}
		},
		getProfileInfo: async (token: string) => {
			const response = await fetch(`${apiBaseUrl}/profile`, {
				method: 'GET',
				credentials: 'include',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});
			if (response.ok) {
				const result = await (response.json() as Promise<AuthProfile>);
				return result;
			}
			throw new Error('Unable to get user profile');
		},
		updateProfileInfo: undefined,
	};
};

interface AuthConfigs {
	apiBaseUrl: string;
	clientId: string;
	authBaseUrl: string;
	oauthScope: string;
}

const getDecathlonAuthConfigs = (): ExternalAuthInfo => {
	const envConfigs: Record<'DEV' | 'PROD', AuthConfigs> = {
		DEV: {
			apiBaseUrl: `${functionsBaseUrl}/externalAuth-decathlon`,
			clientId: 'c4f32374-bbff-4657-a8c2-069b56a07117',
			authBaseUrl: 'https://api-global.preprod.decathlon.net/connect',
			oauthScope:
				'account:roles country birthdate gender sports_tracking_data roles account:entity account:birthdate account:shipping_address shipping_address email account:profile address sports email:write openid stores identifiers profile store account:address account:gender account:phone phone:write profile:write account:identifiers phone account:email sports:write contacts',
		},
		PROD: {
			apiBaseUrl: `${functionsBaseUrl}/externalAuth-decathlon`,
			clientId: '62aed3ff-cb5a-44ef-a85d-0ecf72f7ca6a',
			authBaseUrl: 'https://api-global.decathlon.net/connect',
			oauthScope:
				'account:roles country birthdate gender sports_tracking_data roles account:entity account:birthdate account:shipping_address shipping_address email account:profile address sports email:write openid stores identifiers profile store account:address account:gender account:phone phone:write profile:write account:identifiers phone account:email sports:write contacts',
		},
	};
	// The development version uses only dev.rentle.store as the whitelisted redirect url
	const redirectOrigin = isDevEnv() ? 'https://dev.rentle.store' : window.location.origin;
	const config = env === 'production' ? envConfigs.PROD : envConfigs.DEV;
	const authRedirectUrl = getRedirectUrl({
		origin: redirectOrigin,
		path: '/_auth/decathlon',
	});
	return {
		getOauthUrl: (state?: string) => {
			const oauthBaseUrl = `${config.authBaseUrl}/oauth/authorize`;
			const responseType = 'code';
			const queryParams: OAuthGetQueryParams = {
				client_id: config.clientId,
				redirect_uri: authRedirectUrl,
				response_type: responseType,
				scope: config.oauthScope,
				...(state && { state }),
			};
			const queryParamString = new URLSearchParams(queryParams).toString();
			let oauthUrl = `${oauthBaseUrl}?${queryParamString}`;
			return oauthUrl;
		},
		getLogoutUrl: (token: string, _state?: string) => {
			const logoutBaseUrl = `${config.authBaseUrl}/openid/logout`;
			const logoutRedirectUrl = getRedirectUrl({
				origin: redirectOrigin,
				path: '/_auth/decathlon/logout',
			});
			const queryParams = new URLSearchParams({
				id_token_hint: token,
				post_logout_redirect_uri: logoutRedirectUrl,
			}).toString();
			const url = `${logoutBaseUrl}?${queryParams}`;
			return url;
		},
		getAuthToken: async (code: string) => {
			const queryParams = new URLSearchParams({
				client_id: config.clientId,
				code,
				redirect_uri: authRedirectUrl,
			}).toString();
			const response = await fetch(`${config.apiBaseUrl}/token?${queryParams}`);
			if (response.ok) {
				const result = await (response.json() as Promise<OauthTokenResponse>);
				return result;
			} else {
				throw new Error('Unable to get auth token');
			}
		},
		getProfileInfo: async (token: string) => {
			const response = await fetch(`${config.apiBaseUrl}/profile`, {
				method: 'GET',
				credentials: 'include',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});
			if (response.ok) {
				const result = await (response.json() as Promise<AuthProfile>);
				return result;
			}
			throw new Error('Unable to get user profile');
		},
		updateProfileInfo: async (token: string, authProfile: Partial<AuthProfile>) => {
			await fetch(`${config.apiBaseUrl}/profile`, {
				method: 'POST',
				credentials: 'include',
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(authProfile),
			});
		},
	};
};

export const getAuthConfigs = (provider: ExternalAuthProvider | undefined) => {
	if (!provider) return undefined;
	const AUTH_PROVIDER_CONFIGS: Record<ExternalAuthProvider, ExternalAuthInfo> = {
		DECATHLON: getDecathlonAuthConfigs(),
		MOCK: getMockAuthConfigs(),
	};
	return AUTH_PROVIDER_CONFIGS[provider];
};
