import { isEmpty } from 'lodash';

import { availableLanguagesWithInfo } from 'common/modules/translations';
import {
	CustomerPersona,
	IndustryCategory,
	ShopAnalytics,
} from 'common/services/analytics/tractionAnalytics';
import {
	AdditionalDetails,
	ByProductId,
	ISOWeekdays,
	LocaleField,
	OrderInfo,
	OrderProduct,
	ProductApi,
	ProductTypes,
	SetIncludesItem,
	ShopPublicInfo,
	TransactionApi,
	UnitSystems,
} from 'common/types';
import { getDefaultCurrencyObject } from 'common/utils/currencyUtils';
import { getDefaultDateObject } from 'common/utils/dateUtils';
import { getCountryTimeZone } from 'common/utils/shopUtils';
import { mapToNewItemType, mapToPricingSupportedProduct } from 'common/utils/taxMigrationHelpers';

export const updateToNewOrderTypes = <T extends OrderInfo>(order: T): T => {
	if (order.initialStartDate === undefined) {
		order.initialStartDate = order.startDate;
	}
	if (order.startDateException === undefined) {
		order.startDateException = false;
	}

	if (order.purchaseTypes === undefined) {
		order.purchaseTypes = ['rental'];
	}

	if (order.purchaseTypes.length === 1) {
		order.purchaseType = order.purchaseTypes[0];
	}

	if (!!order.services?.delivery?.to && !order.services.delivery.to.startDate) {
		order.services.delivery.to.startDate = (order.services.delivery.to as any).startTime;
	}

	if (!!order.services?.delivery?.from && !order.services.delivery.from.endDate) {
		order.services.delivery.from.endDate = (order.services.delivery.from as any).endTime;
	}

	if (!!order.bookingComment?.value) {
		order['customCheckoutFields'] = [
			{ label: order.bookingComment.label ?? null, value: order.bookingComment.value },
		];
	}

	return order;
};

export const updateToNewOrderProductType = <T extends OrderProduct>(orderProduct: T): T => {
	if (typeof (orderProduct as any).name === 'string') {
		const localeName: LocaleField = {
			def: (orderProduct as any).name as string,
			...(((orderProduct as any).name_en as string) && {
				en: (orderProduct as any).name_en as string,
			}),
		};
		orderProduct.name = localeName;
		delete (orderProduct as any).name_en;
	}
	if (orderProduct.set) {
		orderProduct.setProducts.map((setProduct) => {
			if (typeof (setProduct as any).name === 'string') {
				const localeName: LocaleField = {
					def: (setProduct as any).name as string,
					...(((setProduct as any).name_en as string) && {
						en: (setProduct as any).name_en as string,
					}),
				};
				setProduct.name = localeName;
				delete (setProduct as any).name_en;
			}
			if (setProduct.bookingBuffer && isNaN(setProduct.bookingBuffer.daysBefore)) {
				setProduct.bookingBuffer.daysBefore = 0;
			}
		});
	}
	if ((orderProduct as any).description && typeof (orderProduct as any).description === 'string') {
		const localeName: LocaleField = {
			def: (orderProduct as any).description as string,
			...(((orderProduct as any).description_en as string) && {
				en: (orderProduct as any).description_en as string,
			}),
		};
		orderProduct.description = localeName;
		delete (orderProduct as any).description_en;
	}
	if ((orderProduct as any).additionalDetails) {
		const newAdditionalDetails: AdditionalDetails[] = [];
		for (let i = 0; i < (orderProduct as any).additionalDetails.length; i++) {
			if ((orderProduct as any).additionalDetails[i].name === 'string') {
				const localeName: LocaleField = {
					def: (orderProduct as any).additionalDetails[i].name,
					...((orderProduct as any).additionalDetails_en &&
						(orderProduct as any).additionalDetails_en[i] && {
							en: (orderProduct as any).additionalDetails_en[i].name,
						}),
				};
				const localeDetail: LocaleField = {
					def: (orderProduct as any).additionalDetails[i].detail,
					...((orderProduct as any).additionalDetails_en &&
						(orderProduct as any).additionalDetails_en[i] && {
							en: (orderProduct as any).additionalDetails_en[i].detail,
						}),
				};
				newAdditionalDetails.push({ name: localeName, detail: localeDetail });
			}
		}
		if (newAdditionalDetails.length > 0) {
			orderProduct.additionalDetails = newAdditionalDetails;
		}
	}
	if ((orderProduct as any).additionalDetails_en) {
		delete (orderProduct as any).additionalDetails_en;
	}
	if (!!(orderProduct as any).selectedVariant?.variantValues?.length) {
		const newVariantValues: LocaleField[] = [];
		for (let j = 0; j < (orderProduct as any).selectedVariant.variantValues.length; j++) {
			if (typeof (orderProduct as any).selectedVariant.variantValues[j] === 'string') {
				const localeName: LocaleField = {
					def: (orderProduct as any).selectedVariant.variantValues[j],
					...((orderProduct as any).selectedVariant.variantValues_en &&
						(orderProduct as any).selectedVariant.variantValues_en[j] && {
							en: (orderProduct as any).selectedVariant.variantValues_en[j],
						}),
				};
				newVariantValues.push(localeName);
			}
		}
		if (newVariantValues.length > 0) {
			(orderProduct as any).selectedVariant.variantValues = newVariantValues;
			delete (orderProduct as any).selectedVariant.variantValues_en;
		}
	}
	if ((orderProduct as any).durationName === undefined) {
		orderProduct.durationName = null;
	}
	if (orderProduct.bookingBuffer && isNaN(orderProduct.bookingBuffer.daysBefore)) {
		orderProduct.bookingBuffer.daysBefore = 0;
	}
	if (!orderProduct.pricing) {
		orderProduct.pricing = mapToPricingSupportedProduct(orderProduct).pricing;
	}
	if (orderProduct.purchaseType === undefined) {
		orderProduct.purchaseType = 'rental';
	}

	if (!isEmpty(orderProduct.setProducts)) {
		orderProduct.setProducts = orderProduct.setProducts.map((setProduct) => {
			setProduct.purchaseType = orderProduct.purchaseType;
			return setProduct;
		});
	}

	return orderProduct;
};

export const updateToNewTransactionType = <T extends TransactionApi>(transaction: T): T => {
	const updatedTransaction: T = {
		...transaction,
		amount: {
			...transaction.amount,
			...(!transaction.amount.captured && {
				captured: transaction.amount.paid || 0,
			}),
		},
		fee: transaction.fee ? mapToNewItemType(transaction.fee) : transaction.fee,
	};
	let refundedValue = 0;
	if (transaction.refunds && transaction.refunds.length) {
		refundedValue += transaction.refunds
			.map((r) => r.amount.value)
			.reduce((sum, curr) => sum + curr, 0);
	} else if (transaction.refund) {
		refundedValue += transaction.refund.amount.value;
	} else if (transaction.refundedAmount) {
		refundedValue += transaction.refundedAmount;
	}
	if (refundedValue || transaction.amount.refunded) {
		return {
			...updatedTransaction,
			amount: {
				...updatedTransaction.amount,
				refunded: (updatedTransaction.amount.refunded || 0) + refundedValue,
			},
		};
	}
	return updatedTransaction;
};

export const updateToNewProductTypes = <T extends ProductApi>(product: T): T => {
	// To handle new type errors with backend
	if ((product as any).additionalProducts && product.additionalProductIds === undefined) {
		product.additionalProductIds = arrayUnique(
			(product as any).additionalProducts.concat(product.additionalProductIds || []),
		);
	}
	delete (product as any).additionalProducts;
	if ((product as any).setProducts && product.setIncludes === undefined) {
		const setProductIds = arrayUnique(
			(product as any).setProducts.concat((product as any).setProductIds || []),
		);
		product.setIncludes = setProductIds.reduce((acc, curr, index) => {
			acc[curr] = {
				units: 1,
				index,
			};
			return acc;
		}, {} as ByProductId<SetIncludesItem>);
	}
	delete (product as any).setProducts;
	if (typeof (product as any).name === 'string') {
		const localeName: LocaleField = {
			def: (product as any).name as string,
			...(((product as any).name_en as string) && { en: (product as any).name_en as string }),
		};
		product.name = localeName;
		delete (product as any).name_en;
	}
	if ((product as any).description && typeof (product as any).description === 'string') {
		const localeName: LocaleField = {
			def: (product as any).description as string,
			...(((product as any).description_en as string) && {
				en: (product as any).description_en as string,
			}),
		};
		product.description = localeName;
		delete (product as any).description_en;
	}
	if ((product as any).additionalDetails) {
		const newAdditionalDetails: AdditionalDetails[] = [];
		for (let i = 0; i < (product as any).additionalDetails.length; i++) {
			if (typeof (product as any).additionalDetails[i].name === 'string') {
				const localeName: LocaleField = {
					def: (product as any).additionalDetails[i].name,
					...((product as any).additionalDetails_en &&
						(product as any).additionalDetails_en[i] && {
							en: (product as any).additionalDetails_en[i].name,
						}),
				};
				const localeDetail: LocaleField = {
					def: (product as any).additionalDetails[i].detail,
					...((product as any).additionalDetails_en &&
						(product as any).additionalDetails_en[i] && {
							en: (product as any).additionalDetails_en[i].detail,
						}),
				};
				newAdditionalDetails.push({ name: localeName, detail: localeDetail });
			}
		}
		if (newAdditionalDetails.length > 0) {
			product.additionalDetails = newAdditionalDetails;
		}
	}
	if ((product as any).additionalDetails_en) {
		delete (product as any).additionalDetails_en;
	}
	if ((product as any).productVariants) {
		for (let i = 0; i < (product as any).productVariants.length; i++) {
			const newVariantValues: LocaleField[] = [];
			for (let j = 0; j < (product as any).productVariants[i].variantValues.length; j++) {
				if (typeof (product as any).productVariants[i].variantValues[j] === 'string') {
					const localeName: LocaleField = {
						def: (product as any).productVariants[i].variantValues[j],
						...((product as any).productVariants[i].variantValues_en &&
							(product as any).productVariants[i].variantValues_en[j] && {
								en: (product as any).productVariants[i].variantValues_en[j],
							}),
					};
					newVariantValues.push(localeName);
				}
			}
			if (newVariantValues.length > 0) {
				(product as any).productVariants[i].variantValues = newVariantValues;
				delete (product as any).productVariants[i].variantValues_en;
			}
		}
		if ((product as any).productVariants_en) {
			delete (product as any).productVariants_en;
		}
	}
	if ((product as any).pricing) {
		for (let i = 0; i < (product as any).pricing.length; i++) {
			if (typeof (product as any).pricing[i].label === 'string') {
				const localeName: LocaleField = {
					def: (product as any).pricing[i].label,
					...(((product as any).pricing[i].label_en as string) && {
						en: (product as any).pricing[i].label_en as string,
					}),
				};
				(product as any).pricing[i].label = localeName;
				delete (product as any).pricing[i].label_en;
			}
		}
	}
	if (product.segmentPricings) {
		for (const segmentPrice of product.segmentPricings) {
			for (let i = 0; i < segmentPrice.pricingTable.length; i++) {
				if (typeof segmentPrice.pricingTable[i].label === 'string') {
					const localeName: LocaleField = {
						def: (segmentPrice.pricingTable[i] as any).label,
						...(((segmentPrice.pricingTable[i] as any).label_en as string) && {
							en: (segmentPrice.pricingTable[i] as any).label_en as string,
						}),
					};
					segmentPrice.pricingTable[i].label = localeName;
					delete (segmentPrice.pricingTable[i] as any).label_en;
				}
			}
			segmentPrice.pricingTable = segmentPrice.pricingTable.map((item) => ({
				...item,
				channels: !item.channels || !item.channels.length ? (['ALL'] as any) : item.channels,
			}));
		}
	}
	if (product.categoryIds === undefined) {
		product.categoryIds = (product as any).categoryId ? [(product as any).categoryId] : [];
	}
	// Comment out deleting categoryId for now to not cause issues with cached views
	// 17.10.2019
	/*if ((product as any).categoryId !== undefined) {
		delete (product as any).categoryId;
	}*/
	if (!!product.pricing && Array.isArray(product.pricing)) {
		product.pricing = product.pricing.map((item) => ({
			...item,
			channels: !item.channels || !item.channels.length ? (['ALL'] as any) : item.channels,
		}));
	}
	if (product.bookingBuffer && isNaN(product.bookingBuffer.daysBefore)) {
		product.bookingBuffer.daysBefore = 0;
	}

	if (product.sales === undefined) {
		product.sales = {
			enabled: false,
			basePrice: 0,
		};
	}

	if (product.rentals === undefined) {
		product.rentals = {
			enabled: true,
			basePrice: (product as any).fixedPrice || 0,
		};
	}

	if (!!product.variants) {
		product.variants.options = product.variants.options.map((option) => {
			if (option.rentals === undefined) {
				option.rentals = {
					enabled: true,
					priceIncrease: (option as any).priceIncrease ?? null,
				};
			}

			if (option.sales === undefined) {
				option.sales = {
					enabled: false,
					priceOverride: null,
				};
			}
			return option;
		});
	}

	if (product.type === ('service' as any)) {
		product.type = ProductTypes.RENTAL;
	}

	if (!!product.startTimes?.value) {
		product.startTimes.value = product.startTimes.value.map((item) => {
			if (item.type === 'weekly') {
				item.value.weekDays = item.value.weekDays.map((weekDay) => {
					if ((weekDay as any) === 0) return ISOWeekdays.Sunday;
					return weekDay;
				});
			}
			return item;
		});
	}

	return product;
};

export const updateToNewShopAnalyticsType = <T extends ShopAnalytics>(shopAnalytics: T): T => {
	if (!shopAnalytics) return shopAnalytics;
	if (shopAnalytics.industryCategories && Array.isArray(shopAnalytics.industryCategories as any)) {
		shopAnalytics = {
			...shopAnalytics,
			industryCategories: {
				values: (shopAnalytics.industryCategories as any) as IndustryCategory[],
			},
		};
	}
	if (shopAnalytics.customerPersona && typeof shopAnalytics.customerPersona === 'string') {
		shopAnalytics = {
			...shopAnalytics,
			customerPersona: {
				value: shopAnalytics.customerPersona as CustomerPersona,
			},
		};
	}
	return shopAnalytics;
};

export const updateToNewShopPublicInfoType = (shopPublicInfo: ShopPublicInfo): ShopPublicInfo => {
	// Old marketing consent format
	if (
		shopPublicInfo.marketingConsentText &&
		!shopPublicInfo.marketingConsentText.text &&
		(shopPublicInfo.marketingConsentText as any).def
	) {
		shopPublicInfo = {
			...shopPublicInfo,
			marketingConsentText: {
				text: (shopPublicInfo.marketingConsentText as any) as LocaleField,
				active: true,
			},
		};
	}

	if (!shopPublicInfo.timeZone) {
		const timeZone = getCountryTimeZone(shopPublicInfo.country);
		shopPublicInfo = {
			...shopPublicInfo,
			timeZone,
		};
	}

	if (shopPublicInfo.pricingTables) {
		Object.values(shopPublicInfo.pricingTables).map((pricingTable) => {
			pricingTable.map((pricingItem) => {
				if (typeof (pricingItem as any).label === 'string') {
					const localeLabel: LocaleField = {
						def: (pricingItem as any).label,
						...((pricingItem as any).label_en && { en: (pricingItem as any).label_en }),
					};
					pricingItem.label = localeLabel;
					delete (pricingItem as any).label_en;
				}
				if (!pricingItem.channels?.length) {
					pricingItem.channels = ['ALL'];
				}
			});
		});
	}
	if (shopPublicInfo.visitingAddress && !shopPublicInfo.visitingAddress.name) {
		shopPublicInfo = {
			...shopPublicInfo,
			visitingAddress: {
				...shopPublicInfo.visitingAddress,
				name: shopPublicInfo.name,
			},
		};
	}
	if (
		shopPublicInfo.visitingAddress &&
		typeof (shopPublicInfo.visitingAddress as any).description === 'string'
	) {
		const localeName: LocaleField = {
			def: (shopPublicInfo.visitingAddress as any).description as string,
		};
		shopPublicInfo = {
			...shopPublicInfo,
			visitingAddress: {
				...shopPublicInfo.visitingAddress,
				description: localeName,
			},
		};
	}
	if (shopPublicInfo.shopLocations) {
		const updatedShopLocations = shopPublicInfo.shopLocations.map((shopLocation) => {
			if (typeof (shopLocation as any).description === 'string') {
				const localeName: LocaleField = {
					def: (shopLocation as any).description as string,
				};
				shopLocation.description = localeName;
				return shopLocation;
			}
			return shopLocation;
		});
		shopPublicInfo = {
			...shopPublicInfo,
			shopLocations: [...updatedShopLocations],
		};
	}
	if (!shopPublicInfo.defaultLanguage) {
		shopPublicInfo = {
			...shopPublicInfo,
			defaultLanguage: 'fi',
		};
	}
	if (!shopPublicInfo.languages) {
		const languages = availableLanguagesWithInfo.map((lang) => lang.lang);
		shopPublicInfo = {
			...shopPublicInfo,
			languages,
		};
	}
	if (!shopPublicInfo.currency) {
		shopPublicInfo = {
			...shopPublicInfo,
			currency: getDefaultCurrencyObject(),
		};
	}
	if (shopPublicInfo.onlinePaymentsEnabled && !shopPublicInfo.activePaymentMethods) {
		shopPublicInfo = {
			...shopPublicInfo,
			activePaymentMethods: [{ id: 'CARD_ONLINE' }],
		};
	}

	if (!shopPublicInfo.dateFormat) {
		shopPublicInfo = {
			...shopPublicInfo,
			dateFormat: getDefaultDateObject(),
		};
	}
	if (!shopPublicInfo.unitSystem) {
		shopPublicInfo = {
			...shopPublicInfo,
			unitSystem: UnitSystems.METRIC,
		};
	}

	return shopPublicInfo;
};

const arrayUnique = (array: any[]) => {
	const a = array.concat();
	for (let i = 0; i < a.length; ++i) {
		for (let j = i + 1; j < a.length; ++j) {
			if (a[i] === a[j]) {
				a.splice(j--, 1);
			}
		}
	}
	return a;
};
