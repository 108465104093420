/// <reference types="@adyen/api-library/lib/src/typings/checkout" />
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import 'common/frontend/firebase/init';
// import 'services/sentry/frontendSdkInit';
import 'common/services/errorHandling/errorHandler';
import React from 'react';

import * as ReactDOM from 'react-dom';
import { IconContext } from 'react-icons';
import { Route, Router, Switch } from 'react-router-dom';
import ReduxRoot from 'redux/store';

import iconConfig from 'common/styles/icons';
import * as Themes from 'common/styles/themes';

import 'services/polyfills';
import AppRouter from 'views/index';

import history from 'services/routing/history';
import { unregister } from 'services/serviceWorker';

unregister();

ReactDOM.render(
	<ReduxRoot>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={Themes.WhiteLabelLight}>
				<CssBaseline />
				<div
					id="app"
					style={{
						display: 'flex',
					}}
				>
					<Router history={history}>
						<IconContext.Provider value={iconConfig}>
							<Switch>
								<Route path="/" component={AppRouter} />
							</Switch>
						</IconContext.Provider>
					</Router>
				</div>
			</ThemeProvider>
		</StyledEngineProvider>
	</ReduxRoot>,
	document.getElementById('root') as HTMLElement,
);
