import React from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'redux/hooks';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import CheckboxItem from 'common/components/CheckboxItem';
import TermsLinkElements from 'common/components/TermsLinkElements';
import Note from 'common/components/states/Note';
import { notUndefined } from 'common/utils/common';
import { getCategoriesWithTerms } from 'common/utils/rentals';
import useCheckoutForm from 'hooks/useCheckoutForm';
import { Trans, useTranslation } from 'services/localization';

const TermsAcceptanceCheckbox = () => {
	const classes = useStyles();
	const { t, language } = useTranslation();
	const orderCategoryIds = useSelector(CheckoutSelectors.orderCategoryIds);
	const categoriesById = useSelector(StockSelectors.categoriesById);
	const shopTerms = useSelector(ShopSelectors.shopTerms);
	const shopPrivacyPolicy = useSelector(ShopSelectors.shopPrivacyPolicy);
	const shopHasCancellationPolicy = useSelector(ShopSelectors.shopHasCancellationPolicy);
	const useLegacyCategoryTermsOverwrite = useSelector(
		ShopSelectors.useLegacyCategoryTermsOverwrite,
	);
	const { values, actions, errors } = useCheckoutForm();

	const getTermsConsent = () => {
		const categories = orderCategoryIds.map((id) => categoriesById[id]).filter(notUndefined);
		const categoriesWithTerms = getCategoriesWithTerms(
			categories,
			shopTerms,
			language,
			t,
			useLegacyCategoryTermsOverwrite,
		);
		return (
			<Trans
				i18nKey="common:terms.termsConsent"
				defaults="I agree to the <terms></terms> of the order"
				components={{
					terms: (
						<TermsLinkElements
							categoriesWithTerms={categoriesWithTerms}
							privacyLink={shopPrivacyPolicy}
							termsClassName={classes.rentleTermsLink}
							t={t}
							showCancellationPolicyText={shopHasCancellationPolicy}
						/>
					),
				}}
			/>
		);
	};

	return (
		<CheckboxItem
			id="terms"
			value="terms"
			checked={values.termsAcceptance}
			onCheckedChange={actions.setTermsAcceptance}
			label={getTermsConsent()}
			checkboxSize="small"
			noPadding
			error={
				errors.terms ? (
					<Box mt={1.5}>
						<Note variant="danger" label={errors.terms} />
					</Box>
				) : undefined
			}
		/>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		rentleTermsLink: {
			color: theme.palette.common.black,
			textDecoration: 'underline',
			textTransform: 'capitalize',
		},
	}),
);

export default TermsAcceptanceCheckbox;
