import { AtLeast, DayType, ISOString, LocaleField, OrderPayment, ProductApi } from 'common/types';
import { stringEnum } from 'common/utils/objects';

import { Address } from '../atoms/addresses';
import { BufferTime, FixedBufferTimeWithType } from '../atoms/bufferTimes';
import { ItemPricing } from '../atoms/pricing';

export interface OrderDeliveryService {
	id: string;
	shopId: string;
	orderId: string;
	name: LocaleField;
	pricing: ItemPricing;
	charge: OrderPayment;
	createdAt: string;
	deliveryOptionId: string;
	to?: OrderDeliveryDetails;
	from?: OrderDeliveryDetails;
	availablePickupSlots?: DeliveryTimeSlot[];
	returnInstructions?: LocaleField;
}
export interface OrderDeliveryDetails {
	timeslot: DeliveryTimeSlot | null;
	pricing: ItemPricing;
	handlingTimeMinutes: number;
	location: {
		address: Address | null;
	};
	carrier?: {
		id: string;
		name: LocaleField;
	};
	trackingId?: string;
	disabled?: boolean;
}

export type OrderDelivery = OrderDeliveryService;

export type CartDelivery = AtLeast<OrderDelivery, 'id' | 'name' | 'pricing' | 'deliveryOptionId'>;

export const DeliveryTypes = stringEnum([
	'DELIVERY_AND_PICKUP',
	'DELIVERY_AND_OPTIONAL_PICKUP',
	'DELIVERY_ONLY',
]);
export type DeliveryType = keyof typeof DeliveryTypes;

export interface DeliveryOption {
	id: string;
	name: LocaleField;
	type: DeliveryType;
	description: LocaleField;
	vatPercent: number;
	minimalDurationInSeconds: number;
	locationIds: string[];
	shopId: string;
	createdAt: string;
	deliveryPeriods: DeliveryPeriod[];
	price: {
		delivery: number;
		pickup: number;
	};
	hidden?: boolean;
	returnInstructions?: LocaleField;
	handlingTimes?: DeliveryOptionHandlingTimes;
	openingHoursBehaviour?: DeliveryOpeningHoursBehaviour;
	orderIndex: number;
}

export interface DeliveryOptionHandlingTimes {
	preparationTime?: BufferTime;
	handlingTimeBefore?: FixedBufferTimeWithType;
	handlingTimeAfter?: FixedBufferTimeWithType;

	/**
	 * These will be implemented later
	 */
	//transitTimeBefore?: FixedBufferTimeWithType;
	//transitTimeAfter?: FixedBufferTimeWithType;
}

export type DeliveryOptionWithProducts = DeliveryOption & {
	products: ProductApi[];
};

export interface OrderInfoDelivery {
	orderDeliveryId: string;
	to?: {
		startDate: ISOString;
		carrierId?: string;
		trackingId?: string;
	};
	from?: {
		endDate: ISOString;
		carrierId?: string;
		trackingId?: string;
	};
}
export interface DeliveryPeriod {
	startDate: string;
	endDate: string | null;
	times: DeliveryTime[];
}

export interface DeliveryOpeningHoursBehaviour {
	allowStartOnClosedDays: boolean;
	allowEndOnClosedDays: boolean;
}

export interface DeliveryTime {
	dayType: DayType;
	slots: DeliveryTimeSlotTimes[];
}

export interface DeliveryTimeSlotTimes {
	startTime: string;
	endTime: string;
}

export interface DeliveryTimeSlot {
	startDate: ISOString;
	endDate: ISOString;
}

export interface Carrier {
	id: string;
	name: LocaleField;
	locationIds: string[];
	shopId: string;
	createdAt: string;
}
