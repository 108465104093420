import React from 'react';

import { Box, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import Sticky from 'hocs/Sticky';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';
import { ALL_CATEGORIES_ID } from 'views/Shop/Location/Browse/components/Sidebar';

import { isRunInIframe } from 'common/utils/browserUtils';
import CenteredContent from 'components/CenteredContent';
import DeliveryBar from 'components/NavBar/DeliveryBar';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';

import BreadcrumbNav from './BreadcrumbNav';

export interface Props {
	productName: string;
}

const ProductNav = (props: Props) => {
	const { getPath, Routes } = useRoutes();
	const { getTranslation } = useTranslation();
	const { t } = useTranslation();
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

	const { productName } = props;
	const filteredCategory = useSelector(StockSelectors.filteredCategory);
	const filtersProductType = useSelector(ViewSelectors.filtersProductType);
	const isDeliveryBarVisible = useSelector(ShopSelectors.isDeliveryBarVisible);
	const isEmbedded = isRunInIframe();

	const getBreadcrumbLink = () => {
		const categoryParam = {
			category: filteredCategory?.id ?? ALL_CATEGORIES_ID,
			productType: filtersProductType ?? undefined,
		};
		return getPath(Routes.browse, { query: categoryParam });
	};

	const Breadcrumb = () => (
		<CenteredContent className={classNames(classes.breadcrumbs)}>
			<Box sx={{ py: isMobile ? 2 : 3 }}>
				<BreadcrumbNav
					breadCrumbs={[
						{
							name: !!filteredCategory
								? getTranslation(filteredCategory.name)
								: t('Browse.allProducts'),
							link: getBreadcrumbLink(),
						},
						{
							name: productName,
							link: null,
						},
					]}
				/>
			</Box>
		</CenteredContent>
	);

	return (
		<>
			{isDeliveryBarVisible && (
				<>
					{!isEmbedded ? (
						<Sticky>
							{({ isScrolledDown }) => <DeliveryBar isScrolledDown={isScrolledDown} />}
						</Sticky>
					) : (
						<DeliveryBar />
					)}
				</>
			)}
			<Breadcrumb />
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		breadcrumbs: {
			background: 'white',
			transition: 'border 0.3s',
			borderBottom: '2px solid transparent',
		},
	}),
);

export default ProductNav;
