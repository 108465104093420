export const shops = 'shops';
export const shopDocBase = shops + '/:shopId';
export const shopPublicDocBase = shopDocBase + '/public/data';
export const shopReadOnlyDocBase = shopDocBase + '/readOnly/data';
export const shopExceptionPeriodsBase = shopPublicDocBase + '/exceptionPeriods';
export const shopExceptionPeriodDocBase = shopExceptionPeriodsBase + '/:periodId';
export const shopUrls = 'shopUrls';
export const shopWebhooksBase = shopDocBase + '/webhooks';
export const shopWebhooksDocBase = shopWebhooksBase + '/:webhookId';

export const adminCollection = 'admin';
export const adminDocBase = adminCollection + '/data';

export const adminApiKeys = adminDocBase + '/apiKeys';
export const adminApiKeysDocBase = adminApiKeys + '/:docId';

export const aggregations = 'aggregations';

export const availabilityRanges = 'availabilityRanges';
export const availabilityRangesDocBase = availabilityRanges + '/:rangeId';

export const availabilityBlockers = 'availabilityBlockers';
export const availabilityBlockersDocBase = availabilityBlockers + '/:blockerId';

export const availabilityStartTimes = 'availabilityStartTimes';
export const availabilityStartTimesDocBase = availabilityStartTimes + '/:startTimeId';

export const availabilityQuantities = 'availabilityQuantities';
export const availabilityQuantitiesDocBase = availabilityQuantities + '/:quantityId';

export const adyenNotifications = 'notifications/data/adyen';
export const adyenNotificationDocBase = adyenNotifications + '/:notificationId';

export const deliveryOptions = 'deliveryOptions';
export const deliveryOptionDocBase = deliveryOptions + '/:deliveryOptionId';

export const carriers = 'carriers';
export const carriersDocBase = carriers + '/:carrierId';

export const openingHours = 'openingHours';
export const openingHoursDocBase = openingHours + '/:storeId';

export const orders = 'orders';
export const orderDocBase = orders + '/:orderId';

export const orderPaymentRequestsBase = orderDocBase + '/orderPaymentRequests';
export const orderPaymentRequestDocBase = orderPaymentRequestsBase + '/:paymentRequestId';

export const orderSubscriptionPayments = 'orderSubscriptionPayments';
export const orderSubscriptionPaymentsDocBase = orderSubscriptionPayments + '/:docId';

export const orderEventsBase = orderDocBase + '/orderEvents';
export const orderEventDocBase = orderEventsBase + '/:eventId';

export const orderHistoryBase = orderDocBase + '/history';
export const orderHistoryDocBase = orderHistoryBase + '/:historyId';

export const orderShoppers = 'orderShoppers';
export const orderShopperDocBase = orderShoppers + '/:shopperId';

export const orderProducts = 'orderProducts';
export const orderProductDocBase = orderProducts + '/:productId';

export const orderDeliveries = 'orderDeliveries';
export const orderDeliveriesDocBase = orderDeliveries + '/:orderDeliveryId';

export const products = 'products';
export const productDocBase = products + '/:productId';

export const payments = 'payments';
export const paymentDocBase = payments + '/:paymentId';

export const transactions = 'transactions';
export const transactionDocBase = transactions + '/:transactionId';

export const transactionLedger = 'transactionLedger';
export const transactionLedgerDocBase = transactionLedger + '/:transactionLedgerId';

export const receipts = 'receipts';
export const receiptDocBase = receipts + '/:receiptId';

export const pendingOrders = 'pendingOrders';
export const pendingOrdersDocBase = pendingOrders + '/:orderId';

export const pendingOrderRequests = 'pendingOrderRequests';
export const pendingOrderRequestDocBase = pendingOrderRequests + '/:orderId';

export const pendingExtendRequests = 'pendingExtendRequests';
export const pendingExtendRequestDocBase = pendingExtendRequests + '/:orderId';

export const pendingTransactions = 'pendingTransactions';
export const pendingTransactionDocBase = pendingTransactions + '/:transactionId';

export const pendingRefunds = 'pendingRefunds';
export const pendingRefundDocBase = pendingRefunds + '/:refundId';

export const pendingDeposits = 'pendingDeposits';
export const pendingDepositDocBase = pendingDeposits + '/:depositId';

export const pendingCaptures = 'pendingCaptures';
export const pendingCaptureDocBase = pendingCaptures + '/:captureId';

export const productCategories = 'productCategories';
export const productCategoriesDocBase = productCategories + '/:categoryId';

export const skuItems = 'skuItems';
export const skuItemsDocBase = skuItems + '/:docId';

export const inventoryItems = 'inventoryItems';
export const inventoryItemsDocBase = inventoryItems + '/:docId';

export const inventoryConfigurations = 'inventoryConfigurations';
export const inventoryConfigurationsDocBase = inventoryConfigurations + '/:docId';

export const inventoryTransfers = 'inventoryTransfers';
export const inventoryTransfersDocBase = inventoryTransfers + '/:docId';

export const rentleAccountSeeds = 'rentleAccountSeeds';
export const rentleAccountSeedsDocBase = rentleAccountSeeds + '/:docId';

export const reservations = 'reservations';
export const reservationsDocBase = reservations + '/:reservationId';

export const salesReservations = 'salesReservations';
export const salesReservationsDocBase = salesReservations + '/:reservationId';

export const shopAnalyticsCollection = 'analyticsShop';
export const shopAnalyticsDocBase = shopAnalyticsCollection + '/:docId';

export const shopBalances = 'shopBalances';
export const shopBalancesDocBase = shopBalances + '/:shopBalancesId';

export const usersCollection = 'users';
export const usersDocBase = usersCollection + '/:userId';

export const discountCodesCollection = 'discountCodes';
export const discountCodesDocBase = discountCodesCollection + '/:docId';

export const clientUpdates = 'clientUpdates';
export const clientUpdatesDocBase = clientUpdates + '/:docId';

export const customers = 'customers';
export const customersDocBase = customers + '/:docId';

export const customersOrderHistoryBase = customersDocBase + '/orderHistory';
export const customersOrderHistoryDocBase = customersOrderHistoryBase + '/:docId';

export const deletedShops = 'deletedShops';
export const deletedShopsDocBase = deletedShops + '/:docId';

export const billingCollection = 'billing';
export const billingDocBase = billingCollection + '/data';

export const billingEventsCollection = billingDocBase + '/billingEvents';
export const billingEventsDocBase = billingEventsCollection + '/:eventId';

export const billingCreditsCollection = billingDocBase + '/billingCredits';
export const billingCreditsDocBase = billingCreditsCollection + '/:eventId';

export const billingInvoicesCollection = billingDocBase + '/billingInvoices';
export const billingInvoicesDocBase = billingInvoicesCollection + '/:invoiceId';

export const planAuthTransactions = 'planAuthTransactions';
export const planAuthTransactionsDocBase = planAuthTransactions + '/:docId';

export const planAuthPayments = 'planAuthPayments';
export const planAuthPaymentsDocBase = planAuthPayments + '/:docId';

export const planAuthReceipts = 'planAuthReceipts';
export const planAuthReceiptsDocBase = planAuthReceipts + '/:docId';

export const transactionsMissing = 'transactionsMissing';
export const transactionsMissingDocBase = transactionsMissing + '/:docId';

export const invoiceTransactions = 'invoiceTransactions';
export const invoiceTransactionsDocBase = invoiceTransactions + '/:docId';

export const invoicePayments = 'invoicePayments';
export const invoicePaymentsDocBase = invoicePayments + '/:docId';

export const invoiceReceipts = 'invoiceReceipts';
export const invoiceReceiptsDocBase = invoiceReceipts + '/:docId';

export const usageHistory = 'usageHistory';
export const usageHistoryDocBase = usageHistory + '/:docId';

export const currencyConversions = 'currencyConversions';
export const currencyConversionsDocBase = currencyConversions + '/:docId';

export const reportExportsCollection = 'reportExports';
export const reportExportsDocBase = reportExportsCollection + '/:docId';

export const storeEvents = 'storeEvents';
export const storeEventsDocBase = storeEvents + '/:docId';

export const emailSettings = 'emailSettings';
export const emailSettingsDocBase = emailSettings + '/:locationId';

export const shopTemplates = 'shopTemplates';
export const shopTemplatesDocBase = shopTemplates + '/:docId';

export const customThemes = 'customThemes';
export const customThemesDocBase = customThemes + '/:docId';

export const appmixerUsers = 'appmixerUsers';
export const appmixerUsersDocBase = appmixerUsers + '/:docId';

export const acsGateways = 'acsGateways';
export const acsGatewaysDocBase = acsGateways + '/:docId';

export const mockAcsDb = 'mockAcsDb';
export const mockAcsDbDocBase = mockAcsDb + '/:docId';
