import { createReducer, original } from '@reduxjs/toolkit';
import * as CheckoutActions from 'redux/actions/checkout';
import { LoadingData } from 'redux/types';

import { PaymentMethodError } from 'common/components/payment/types';
import { recalculateItemPricingFromListPrice } from 'common/modules/atoms/pricing';
import { CheckoutSettings } from 'common/modules/customization';
import { getDiscountCodeValueForOrder } from 'common/modules/discountCodes';
import { applyDiscountCodeToOrderProducts } from 'common/modules/orders';
import {
	AdditionalPaymentAction,
	AppliedDiscountCodes,
	OrderObject,
	OrderProduct,
	ResponsiblePersonDetails,
	TermsAcceptance,
} from 'common/types';
import { newFirestoreId } from 'common/utils/newRentalUtils';

type CheckoutState = {
	adyenPaymentMethods: LoadingData<ICheckout.PaymentMethodsResponse | null, string> | undefined;
	paymentError: undefined | PaymentMethodError;
	paymentLoading: boolean;
	paymentInProgress: boolean;
	handlePaymentRedirect: boolean;
	additionalPaymentAction: AdditionalPaymentAction;
	transactionId: string;
	orderId: string;
	contactPerson: ResponsiblePersonDetails;
	termsAcceptance: TermsAcceptance;
	recurringPaymentsAcceptance: boolean;
	orderProducts: OrderProduct[];
	appliedDiscountCodes: AppliedDiscountCodes;
	pendingOrder?: OrderObject;
	customCheckout?: CheckoutSettings;
};

const getEmptyContactPerson = (): ResponsiblePersonDetails => ({
	id: newFirestoreId(),
	firstName: '',
	lastName: '',
	phone: '',
	email: '',
	marketing: false,
});

const INITIAL_STATE: CheckoutState = {
	adyenPaymentMethods: undefined,
	paymentError: undefined,
	paymentLoading: false,
	paymentInProgress: false,
	handlePaymentRedirect: false,
	additionalPaymentAction: undefined,
	transactionId: newFirestoreId(),
	orderId: newFirestoreId(),
	contactPerson: getEmptyContactPerson(),
	termsAcceptance: {
		accepted: false,
	},
	recurringPaymentsAcceptance: false,
	orderProducts: [],
	appliedDiscountCodes: {},
};

const CheckoutReducer = createReducer(INITIAL_STATE, (builder) => {
	builder
		.addCase(CheckoutActions.updateAdyenPaymentMethods.pending, (state, action) => {
			if (state.adyenPaymentMethods === undefined) {
				state.adyenPaymentMethods = { loading: true, error: null, data: null };
			} else {
				state.adyenPaymentMethods.loading = true;
				state.adyenPaymentMethods.error = null;
			}
		})
		.addCase(CheckoutActions.updateAdyenPaymentMethods.rejected, (state, action) => {
			state.adyenPaymentMethods = {
				loading: false,
				error: 'Error fetching payment methods',
				data: null,
			};
		})
		.addCase(CheckoutActions.updateAdyenPaymentMethods.fulfilled, (state, action) => {
			state.adyenPaymentMethods = { loading: false, error: null, data: action.payload.data };
		});

	builder.addCase(CheckoutActions.setPaymentError, (state, action) => {
		state.paymentError = action.payload;
	});

	builder.addCase(CheckoutActions.setPaymentLoading, (state, action) => {
		state.paymentLoading = action.payload;
	});

	builder.addCase(CheckoutActions.setPaymentInProgress, (state, action) => {
		state.paymentInProgress = action.payload;
	});

	builder.addCase(CheckoutActions.setAdditionalPaymentAction, (state, action) => {
		state.additionalPaymentAction = action.payload;
	});

	builder.addCase(CheckoutActions.setTransactionId, (state, action) => {
		state.transactionId = action.payload;
	});

	builder.addCase(CheckoutActions.setOrderId, (state, action) => {
		state.orderId = action.payload;
	});

	builder.addCase(CheckoutActions.updateContactPerson, (state, action) => {
		state.contactPerson = {
			...state.contactPerson,
			...action.payload,
		};
	});

	builder.addCase(CheckoutActions.updateTermsAcceptance, (state, action) => {
		state.termsAcceptance = action.payload;
	});

	builder.addCase(CheckoutActions.updateRecurringPaymentsAcceptance, (state, action) => {
		state.recurringPaymentsAcceptance = action.payload;
	});

	builder.addCase(CheckoutActions.initialiseCustomCheckoutFields, (state, action) => {
		state.customCheckout = action.payload;
	});

	builder.addCase(CheckoutActions.updateCustomCheckoutField, (state, action) => {
		const { sectionIndex, contentIndex, value } = action.payload;
		const customCheckoutPath =
			state.customCheckout?.customContent?.sections?.[sectionIndex].content?.[contentIndex];

		const isDefined = !!customCheckoutPath && customCheckoutPath.type === 'textfield';
		if (isDefined) {
			customCheckoutPath.value = value;
		}
	});

	builder.addCase(CheckoutActions.updateCustomCheckoutCheckbox, (state, action) => {
		const { sectionIndex, contentIndex, value } = action.payload;
		const customCheckoutPath =
			state.customCheckout?.customContent?.sections?.[sectionIndex].content?.[contentIndex];

		const isDefined = !!customCheckoutPath && customCheckoutPath.type === 'checkbox';
		if (isDefined) {
			customCheckoutPath.value = value;
		}
	});

	builder.addCase(CheckoutActions.setOrderProducts, (state, action) => {
		state.orderProducts = action.payload;
	});

	builder.addCase(CheckoutActions.setHandlePaymentRedirect, (state, action) => {
		state.handlePaymentRedirect = action.payload;
	});

	builder.addCase(CheckoutActions.clearDiscountCodes, (state, action) => {
		state.appliedDiscountCodes = {};
		for (const product of state.orderProducts) {
			delete product.pricing.discountCodes;
			product.pricing = recalculateItemPricingFromListPrice(product.pricing);
		}
	});

	builder.addCase(CheckoutActions.addDiscountCode, (state, action) => {
		const discountCode = action.payload;
		const orderProducts = original(state.orderProducts)!;
		const discountValue = getDiscountCodeValueForOrder(discountCode, orderProducts);
		state.appliedDiscountCodes[discountCode.code] = {
			quantity: 1,
			totalDiscountValue: discountValue,
		};
		state.orderProducts = applyDiscountCodeToOrderProducts(discountCode, orderProducts).full;
	});

	builder.addCase(CheckoutActions.removeDiscountCode, (state, action) => {
		const code = action.payload;
		if (state.appliedDiscountCodes[code]) {
			delete state.appliedDiscountCodes[code];
		}
		for (const product of state.orderProducts) {
			if (product.pricing.discountCodes?.[code]) {
				delete product.pricing.discountCodes[code];
				product.pricing = recalculateItemPricingFromListPrice(product.pricing);
			}
		}
	});

	builder.addCase(CheckoutActions.replaceDiscountCodesInCartOnly, (state, action) => {
		state.appliedDiscountCodes = action.payload;
	});
});

export default CheckoutReducer;
