import React from 'react';

import { MenuItem, TextField, TextFieldProps } from '@mui/material';

import { AuthOrganisationType } from 'common/types/external/auth';
import { useTranslation } from 'services/localization';

interface Props extends Omit<TextFieldProps, 'value' | 'onChange' | 'error'> {
	value: AuthOrganisationType | null;
	onChange: (newValue: AuthOrganisationType) => void;
	error?: string;
}

const OrganizationType = (props: Props) => {
	const { t } = useTranslation();

	const orgTypeMap: Record<AuthOrganisationType, string> = {
		school: t('decathlon.school', 'School'),
		administration: t('decathlon.administration', 'Governmental service'),
		association: t('decathlon.association', 'Non-profit organization'),
		business: t('decathlon.business', 'Company'),
		club_sport: t('decathlon.club_sport', 'Sports club'),
		other: t('decathlon.other', 'Other'),
	};

	return (
		<TextField
			label={t('decathlon.orgType', 'Type of company')}
			variant="outlined"
			required
			value={props.value ?? ''}
			onChange={(e) => props.onChange(e.target.value as AuthOrganisationType)}
			fullWidth
			select
			error={!!props.error}
			helperText={props.error ?? undefined}
		>
			<MenuItem disabled value="">
				{t('decathlon.orgTypePlaceholder', 'Choose your type of organization')}
			</MenuItem>
			{Object.entries(orgTypeMap).map(([value, description]) => (
				<MenuItem value={value} key={value}>
					{description}
				</MenuItem>
			))}
		</TextField>
	);
};

export default OrganizationType;
