import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { useSelector } from 'redux/hooks';
import * as StockSelectors from 'redux/selectors/stock';

import Select, { SelectOption } from 'common/components/pickers/Select';
import { ISOString } from 'common/types';
import { getSkidataSegmentTranslation } from 'common/utils/segments';
import ErrorText from 'components/ErrorText';
import { useTranslation } from 'services/localization/useTranslation';

export interface SegmentSelectorProps {
	value: string | null;
	onChange: (value: string | null) => void;
	startDate: ISOString | null;
	error?: string | null;
}

const SegmentSelector = React.memo((props: SegmentSelectorProps) => {
	const { startDate, value, onChange, error } = props;
	const { t, language } = useTranslation();

	const visibleLiftTicketSegments = useSelector(StockSelectors.visibleLiftTicketSegments);

	const segmentOptions = useMemo(
		() =>
			visibleLiftTicketSegments.map(
				(s): SelectOption => ({
					id: s.externalSegmentId,
					label: getSkidataSegmentTranslation(s, language),
				}),
			),
		[language, visibleLiftTicketSegments],
	);

	const handleTicketSegmentChange = (segmentId?: string) => {
		onChange(segmentId ?? null);
	};

	return (
		<Box>
			<Select.Single
				label=""
				emptyLabel={t('tickets.chooseAgeGroup', 'Choose age group')}
				value={value}
				hideEmptyOption
				indicators="none"
				onChange={handleTicketSegmentChange}
				disabled={!startDate}
				options={segmentOptions}
			/>
			{error && (
				<Box mt={0.5}>
					<ErrorText>{error}</ErrorText>
				</Box>
			)}
		</Box>
	);
});

export default SegmentSelector;
