import parsePhoneNumberFromString from 'libphonenumber-js';
import { partition } from 'lodash';
import moment from 'moment-timezone';

import { getUniqIdForOrderProductSubscription } from 'common/modules/subscriptions';
import errorHandler from 'common/services/errorHandling/errorHandler';
import {
	AppliedDiscountCodes,
	CartProduct,
	OrderObject,
	OrderProduct,
	PhoneObject,
	ResponsiblePersonDetails,
	TermsAcceptance,
} from 'common/types';
import { addQueryParameter, getIframeParentOrigin } from 'common/utils/browserUtils';
import {
	getPhoneObjectForCountry,
	getPhoneObjectForDialCode,
	notUndefined,
} from 'common/utils/common';
import { getCountryFromTimezone } from 'common/utils/dateUtils';
import { isKeycardProduct, productHasNewKeycard } from 'common/utils/liftTicketUtils';
import { getResponsiblePersonDetails, newFirestoreId } from 'common/utils/newRentalUtils';
import { orderProductToCartProduct } from 'utils/cart';

export const getRedirectUrl = ({
	transactionId,
	orderId,
}: {
	transactionId: string;
	orderId: string;
}) => {
	const extHomeUrl = getIframeParentOrigin();
	return addQueryParameter(window.location.href, {
		transactionId,
		orderId,
		...(extHomeUrl && { extHomeUrl }),
	});
};

interface StateData {
	cartProducts: CartProduct[];
	orderId: string;
	contactPerson: ResponsiblePersonDetails;
	termsAcceptance: TermsAcceptance;
	appliedDiscountCodes: AppliedDiscountCodes;
	phoneObject: PhoneObject;
}

export const getStateDataFromOrder = (order: OrderObject): StateData => {
	const { products, shoppers, rentalInfo } = order;

	const cartProducts: CartProduct[] = products.map((p) => orderProductToCartProduct(p));
	const orderId = rentalInfo.id;
	const contactPerson: ResponsiblePersonDetails = getResponsiblePersonDetails(
		rentalInfo.responsiblePerson,
		shoppers,
	);
	const phoneObject =
		getPhoneObjectFromFullPhoneNumber(contactPerson.phone) ?? getInitialPhoneObject();
	const termsAcceptance = rentalInfo.terms;
	const appliedDiscountCodes = order.rentalInfo.pricing.discountCodes ?? {};
	return {
		cartProducts,
		orderId,
		contactPerson,
		termsAcceptance,
		appliedDiscountCodes,
		phoneObject,
	};
};

export const cartProductToOrderProduct = (
	cartProduct: CartProduct,
	opts: { id: string; orderId: string; shopId: string; shopperId: string },
): OrderProduct => {
	const { id, orderId, shopId, shopperId } = opts;
	const subscription = !!cartProduct.subscription
		? getUniqIdForOrderProductSubscription(cartProduct.subscription)
		: undefined;
	return {
		...cartProduct,
		id,
		rentalId: orderId,
		shopId,
		shopperId,
		...(!!subscription && { subscription }),
	};
};

export const cartProductsToOrderProducts = (args: {
	cartProducts: CartProduct[];
	orderId: string;
	shopId: string;
	shopperId: string;
}) => {
	const { cartProducts, orderId, shopId, shopperId } = args;

	const orderProducts = cartProducts.map((p) =>
		cartProductToOrderProduct(p, {
			id: newFirestoreId(),
			shopperId,
			orderId,
			shopId,
		}),
	);

	const [keycardProducts, otherProducts] = partition(orderProducts, isKeycardProduct);
	if (keycardProducts.length) {
		const ticketProducts = otherProducts.filter(productHasNewKeycard);
		const keycardProductsWithTicketId = keycardProducts.map((keycard, i) => ({
			...keycard,
			ticketId: ticketProducts[i].id,
		}));

		return [...otherProducts, ...keycardProductsWithTicketId];
	}
	return orderProducts;
};

export const defaultPhoneObject: PhoneObject = {
	countryCode: 'FI',
	areaCode: '+358',
	numberWithoutCode: '',
};

export const getInitialPhoneObject = (shopCountry?: string): PhoneObject => {
	const userTimeZone = moment.tz.guess();
	const countryFromTimeZone = getCountryFromTimezone(userTimeZone)?.id;
	const possibleCountries = [countryFromTimeZone, shopCountry].filter(notUndefined);
	let initialPhoneObject = defaultPhoneObject;
	for (const country of possibleCountries) {
		const phoneObjForCountry = getPhoneObjectForCountry(country);
		if (phoneObjForCountry) {
			initialPhoneObject = phoneObjForCountry;
			break;
		}
	}
	return initialPhoneObject;
};

export const getPhoneObjectFromFullPhoneNumber = (
	fullPhoneNumber: string,
): PhoneObject | undefined => {
	try {
		const parsedPhoneObject = parsePhoneNumberFromString(fullPhoneNumber);
		if (parsedPhoneObject) {
			const { countryCallingCode, nationalNumber } = parsedPhoneObject;
			const dialCode = `+${countryCallingCode}`;
			const phoneObject = getPhoneObjectForDialCode(dialCode);
			if (phoneObject && typeof nationalNumber === 'string') {
				return {
					countryCode: phoneObject.countryCode,
					areaCode: phoneObject.areaCode,
					numberWithoutCode: nationalNumber,
				};
			}
		}
	} catch (e) {
		errorHandler.report(e);
	}
	return undefined;
};
