import React, { useState } from 'react';

import { Box, ButtonBase, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { sumBy } from 'lodash';
import { RiInformationLine, RiQuestionLine } from 'react-icons/ri';

import Divider from 'common/components/Divider';
import { CurrencyObject } from 'common/types';
import { getPricingString, notUndefined } from 'common/utils/common';
import DepositInfoModal from 'components/DepositInfoModal';
import { useTranslation } from 'services/localization';

export interface PriceSummaryRow {
	key: string;
	quantity: number;
	label: string;
	unitPrice: number;
	unitPriceBase?: number;
	children?: string[];
	loading?: boolean;
}

interface Props {
	heading?: string | null;
	headingSecondary?: string | null;
	description?: string | null;
	tooltip?: string | null;
	rows: PriceSummaryRow[];
	currency: CurrencyObject;
	taxExcluded?: boolean;
	depositAmount?: number;
	isSubscription?: boolean;
}
const PriceSummary = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		rows,
		currency,
		taxExcluded,
		depositAmount,
		isSubscription,
		heading,
		headingSecondary,
		description,
		tooltip,
	} = props;
	const [depositModalOpen, setDepositModalOpen] = useState<boolean>(false);
	const isRentalOrSales = !isSubscription;

	const renderRow = (item: PriceSummaryRow) => {
		const hasDiscount = notUndefined(item.unitPriceBase) && item.unitPrice < item.unitPriceBase;
		return (
			<Box key={item.key} className={classes.row}>
				<Box className={classes.rowLeft}>
					<Typography className={classes.rowQty} variant="body2">
						{item.quantity}x
					</Typography>
					<Box>
						<Typography variant="body2">{item.label}</Typography>
						{item.children?.map((child, idx) => (
							<Box key={`${child}_${idx}`}>
								<Typography variant="caption" color="secondary">
									{child}
								</Typography>
							</Box>
						))}
					</Box>
				</Box>
				<Box className={classes.pricing}>
					{item.loading ? (
						<Skeleton width={100} animation="wave" />
					) : (
						<>
							{hasDiscount && (
								<Typography variant="body2" className={classes.basePrice}>
									{getPricingString(item.unitPriceBase!, currency)}
								</Typography>
							)}
							<Typography variant="body2">
								{getPricingString(item.unitPrice * item.quantity, currency)}
							</Typography>
						</>
					)}
				</Box>
			</Box>
		);
	};

	const renderTotalRow = () => {
		const totalPrice = sumBy(rows, (r) => r.unitPrice * r.quantity);
		const loading = rows.some((row) => row.loading);
		return (
			<Box className={classes.row}>
				<Box>
					{isRentalOrSales && (
						<Typography>
							{taxExcluded ? t('common:payment.subtotal', 'Subtotal') : t('common:payment.total')}
						</Typography>
					)}
					{isSubscription && (
						<Typography>{t('common:payment.payPerMonth', 'Pay per month')}</Typography>
					)}
					{!!depositAmount && (
						<Box>
							<ButtonBase onClick={() => setDepositModalOpen(true)}>
								<Typography variant="body2">
									{`+ ${t('summary.securityDeposit', 'Security deposit')}, ${getPricingString(
										depositAmount,
										currency,
									)}`}
								</Typography>
								<Box
									display="inline"
									sx={{ ml: 1, color: (theme) => theme.palette.text.secondary }}
								>
									<RiQuestionLine color="inherit" size={24} />
								</Box>
							</ButtonBase>
							<DepositInfoModal
								isOpen={depositModalOpen}
								onClose={() => setDepositModalOpen(false)}
								depositAmount={depositAmount}
							/>
						</Box>
					)}
					{taxExcluded && (
						<Typography variant="caption" color="textSecondary">
							{t('common:taxes.calculatedAtCheckout', 'Taxes are calculated at checkout')}
						</Typography>
					)}
				</Box>
				<Typography>
					{loading ? (
						<Skeleton width={100} animation="wave" />
					) : (
						getPricingString(totalPrice, currency)
					)}
				</Typography>
			</Box>
		);
	};

	return (
		<Box>
			<Box className={classes.header}>
				{!!heading && (
					<Typography variant="body1" className={classes.heading}>
						{props.heading}
					</Typography>
				)}
				{!!headingSecondary && (
					<Typography variant="body2" color="secondary">
						{props.headingSecondary}
					</Typography>
				)}
				<Stack direction="row" justifyContent="space-between">
					{!!description && (
						<Typography variant="body2" color="textSecondary">
							{description}
						</Typography>
					)}
					{!!tooltip && isSubscription && (
						<Tooltip placement="top" title={tooltip}>
							{
								<Box>
									<RiInformationLine size={20} />{' '}
								</Box>
							}
						</Tooltip>
					)}
				</Stack>
			</Box>
			{props.rows.map(renderRow)}
			<Box py={2}>
				<Divider />
			</Box>
			{renderTotalRow()}
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		header: {
			marginBottom: theme.spacing(2),
			'&:empty': {
				display: 'none',
			},
		},
		heading: {
			fontWeight: 500,
		},
		row: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			marginBottom: theme.spacing(0.5),
		},
		rowLeft: {
			display: 'flex',
			flexDirection: 'row',
		},
		rowQty: {
			width: 40,
		},
		pricing: {
			[theme.breakpoints.up('sm')]: {
				display: 'inherit',
			},
		},
		basePrice: {
			textDecoration: 'line-through',
			marginRight: theme.spacing(1),
		},
	}),
);

export default PriceSummary;
