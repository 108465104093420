import {
	DTAOrderStatus,
	DTAOrderStatuses,
	ShopperIdWithPhotoUploaded,
	SkidataCancelledOrder,
	SkidataCredentials,
	SkidataOrder,
	SkidataProductApi,
	SkidataProps,
	USER_DIDNT_INPUT_PHOTO,
} from 'common/modules/skidata';
import { OnlineOrderRequest, OrderObject } from 'common/types';

import { CallableCreator } from '..';

export interface ActivateSkidataArgs {
	shopId: string;
	credentials: SkidataCredentials;
}

export interface DisableSkidataArgs {
	shopId: string;
}

export interface SyncSkidataCatalogueArgs {
	shopId: string;
}

export interface SyncSkidataCatalogueResponse {
	liftTicketProducts: SkidataProductApi[];
	invalidTicketProducts: SkidataProductApi[];
}

export interface GetSkidataOrderArgs {
	shopId: string;
	orderId: string;
}

export interface DTAContactImageData {
	imageType: 'Jpeg' | 'Bitmap';
	height: string;
	width: string;
	length: string;
	data: string;
}

export interface ShopperIdsWithDTAImage {
	[shopperId: string]: DTAContactImageData | typeof USER_DIDNT_INPUT_PHOTO;
}

export interface UpdateSkidataOrderArgs {
	order: OrderObject;
	shopperIdsWithDTAImage?: ShopperIdsWithDTAImage;
}

export interface CancelSkidataOrderArgs {
	shopId: string;
	skidataOrderId: string;
	skidataOrderStatus: DTAOrderStatus;
	orderId: string;
}

export interface GetSkidataTicketsPricesArgs {
	shopId: string;
	productIds: string[];
	currencyCode: string;
	quantity: number;
	segmentId: string;
	startDate: string;
}

export interface GetSkidataTicketPriceResponse {
	price: number;
	currency: string;
}

export interface GetSkidataTicketsPricesResponse {
	[productId: string]: GetSkidataTicketPriceResponse;
}

export interface DTACalculateOrderPriceOrderItems {
	externalId: string;
	externalSegmentId: string;
	quantity: number;
}

export interface GetSkidataOrderPriceArgs {
	shopId: string;
	currencyCode: string;
	date: string;
	externalId: string;
	orderItems: DTACalculateOrderPriceOrderItems[];
}

export interface GetSkidataOrderPriceResponse {
	cumulatedOrderPriceAmount: number;
	cumulatedOrderItemPriceBase: number;
	cumulatedOrderItemPriceBest: number;
	packageItemPrices: {
		[externalSegmentId: string]: {
			basePrice: number;
			bestPrice: number;
		};
	};
}

export interface UpdateSkidataOrderResponse {
	shopperIdsWithPhotoStatus?: ShopperIdWithPhotoUploaded[];
	orderStatus: DTAOrderStatuses;
}

export interface GetSkidataContactArgs {
	shopId: string;
	contactId: string;
}

export interface GetSkidataContactResponse {}

export const skidata = (createCallable: CallableCreator) => ({
	activate: createCallable<ActivateSkidataArgs, void>('skidata-functions-activate'),
	disable: createCallable<DisableSkidataArgs, void>('skidata-functions-disable'),
	catalogue: {
		sync: createCallable<SyncSkidataCatalogueArgs, SyncSkidataCatalogueResponse>(
			'skidata-functions-syncCatalogue',
		),
		get: createCallable<SyncSkidataCatalogueArgs, SyncSkidataCatalogueResponse>(
			'skidata-functions-getCatalogue',
		),
	},
	orders: {
		create: createCallable<OnlineOrderRequest, SkidataProps>('skidata-functions-createOrder'),
		update: createCallable<UpdateSkidataOrderArgs, UpdateSkidataOrderResponse>(
			'skidata-functions-updateOrder',
		),
		get: createCallable<GetSkidataOrderArgs, SkidataOrder>('skidata-functions-getOrder'),
		cancel: createCallable<CancelSkidataOrderArgs, SkidataCancelledOrder>(
			'skidata-functions-cancelOrder',
		),
		email: createCallable<OnlineOrderRequest, 'OK'>('skidata-functions-sendConfirmationEmail'),
	},
	prices: {
		getTicketPrice: createCallable<GetSkidataTicketsPricesArgs, GetSkidataTicketsPricesResponse>(
			'skidata-functions-getTicketPrice',
		),
		getOrderPrice: createCallable<GetSkidataOrderPriceArgs, GetSkidataOrderPriceResponse>(
			'skidata-functions-getOrderPrice',
		),
	},
	contacts: {
		get: createCallable<GetSkidataContactArgs, GetSkidataContactResponse>(
			'skidata-functions-getContact',
		),
	},
});
