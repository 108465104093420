import { createReducer } from '@reduxjs/toolkit';
import * as ConfirmActions from 'redux/actions/confirm';
import { LoadingData } from 'redux/types';

import { ShopperIdsWithDTAImage } from 'common/modules/skidata';
import { OrderObject } from 'common/types';

type ConfirmState = {
	order: LoadingData<OrderObject, string>;
	shopperIdsWithDTAImage: ShopperIdsWithDTAImage;
};

const INITIAL_STATE: ConfirmState = {
	order: { loading: true, data: null, error: null },
	shopperIdsWithDTAImage: {},
};

const ConfirmReducer = createReducer(INITIAL_STATE, (builder) => {
	builder
		.addCase(ConfirmActions.fetchOrder.pending, (state, action) => {
			state.order.loading = true;
		})
		.addCase(ConfirmActions.fetchOrder.rejected, (state, action) => {
			state.order.loading = false;
			state.order.data = null;
			state.order.error = 'Error fetching order';
		})
		.addCase(ConfirmActions.fetchOrder.fulfilled, (state, action) => {
			state.order.loading = false;
			state.order.data = action.payload;
			state.order.error = null;
		});
	builder.addCase(ConfirmActions.setOrder, (state, action) => {
		state.order = { loading: false, data: action.payload, error: null };
		state.shopperIdsWithDTAImage = {};
	});

	builder.addCase(ConfirmActions.addShopper, (state, action) => {
		if (state.order.data) {
			const shopper = action.payload;
			state.order.data.shoppers = [...state.order.data.shoppers, shopper];
			// state.order.data.rentalInfo.shopperIdsWithProducts[shopper.id] = shopper.productIds;
			// state.order.data = createCompleteOrderObject(state.order.data);
		}
	});
	builder.addCase(ConfirmActions.removeShopper, (state, action) => {
		if (state.order.data) {
			const shopperId = action.payload;
			state.order.data.shoppers = state.order.data.shoppers.filter((s) => s.id !== shopperId);
			// delete state.order.data.rentalInfo.shopperIdsWithProducts[shopperId];
			// state.order.data = createCompleteOrderObject(state.order.data);
		}
	});
	builder.addCase(ConfirmActions.updateShopper, (state, action) => {
		if (state.order.data) {
			const shopper = action.payload;
			state.order.data.shoppers = state.order.data.shoppers.map((s) =>
				s.id === shopper.id ? shopper : s,
			);
			// state.order.data.rentalInfo.shopperIdsWithProducts[shopper.id] = shopper.productIds;
			// state.order.data = createCompleteOrderObject(state.order.data);
		}
	});

	builder.addCase(ConfirmActions.setShoppers, (state, action) => {
		if (state.order.data) {
			state.order.data.shoppers = action.payload;
		}
	});

	builder.addCase(ConfirmActions.addProduct, (state, action) => {
		if (state.order.data) {
			const product = action.payload;
			state.order.data.products = [...state.order.data.products, product];
			// state.order.data = createCompleteOrderObject(state.order.data);
		}
	});
	builder.addCase(ConfirmActions.updateProduct, (state, action) => {
		if (state.order.data) {
			const product = action.payload;
			state.order.data.products = state.order.data.products.map((p) =>
				p.id === product.id ? product : p,
			);
			// state.order.data = createCompleteOrderObject(state.order.data);
		}
	});
	builder.addCase(ConfirmActions.updateProducts, (state, action) => {
		if (state.order.data) {
			state.order.data.products = action.payload;
			// state.order.data = createCompleteOrderObject(state.order.data);
		}
	});
	builder.addCase(ConfirmActions.removeProduct, (state, action) => {
		if (state.order.data) {
			const productId = action.payload;
			state.order.data.products = state.order.data.products.filter((p) => p.id !== productId);
			// state.order.data = createCompleteOrderObject(state.order.data);
		}
	});

	builder.addCase(ConfirmActions.updateOrderInfo, (state, action) => {
		if (state.order.data) {
			state.order.data.rentalInfo = {
				...state.order.data.rentalInfo,
				...action.payload,
			};
			// state.order.data = createCompleteOrderObject(state.order.data);
		}
	});

	builder.addCase(ConfirmActions.updateShopperIdsWithDTAImage, (state, action) => {
		state.shopperIdsWithDTAImage = action.payload;
	});
});

export default ConfirmReducer;
