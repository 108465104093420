import React from 'react';

import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';
import { RENTLE_DEFAULT_FAVICON_PATH } from 'shared-server/favicon';

import {
	getOnlineStoreCategoryMetaTags,
	getOnlineStoreNoShopFoundMetaTags,
	getOnlineStoreProductMetaTags,
	getOnlineStoreShopMetaTags,
} from 'common/modules/seo';
import { Category, ProductApi } from 'common/types';
import { addQueryParameter, stringQueryParam } from 'common/utils/browserUtils';
import { getFaviconLinks } from 'common/utils/getFaviconLinks';

type Props = ShopProps | ProductProps | CategoryProps | NoShopFoundProps;

type NoShopFoundProps = {
	type: 'no-shop-found';
};

type ShopProps = {
	type: 'shop';
};

type ProductProps = {
	type: 'product';
	product: ProductApi;
};

type CategoryProps = {
	type: 'category';
	category: Category;
};

const MetaTags = (props: Props) => {
	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const shopInfo = useSelector(ShopSelectors.shopPublicInfoData);
	const shopUrlData = useSelector(ShopSelectors.shopUrlsData);
	const onlineLayoutSettings = useSelector(ShopSelectors.onlineLayout);
	const language = useSelector(ViewSelectors.language);
	const faviconSrc = onlineLayoutSettings?.general?.siteIconSrc;

	const getSeoMetaTags = () => {
		const urlWithoutQueryParams = window.location.href.split('?')[0];
		const queryString = new URLSearchParams(window.location.search);
		const fbLocaleString = stringQueryParam(queryString.get('fb_locale'));
		const categoryId = stringQueryParam(queryString.get('category'));
		const primaryCustomDomain = shopUrlData?.primaryCustomDomain;
		const hasDifferentPrimaryDomain =
			!!primaryCustomDomain && primaryCustomDomain !== window.location.hostname;
		const pathWithQueryParams = window.location.pathname + window.location.search;
		const storeUrl = shopUrlData?.storeUrl;
		const fullPathWithoutStorePath = !!storeUrl
			? pathWithQueryParams.replace(`/${storeUrl}`, '')
			: pathWithQueryParams;
		const canonicalUrl = hasDifferentPrimaryDomain
			? `https://${primaryCustomDomain}${fullPathWithoutStorePath}`
			: undefined;
		const defaultLanguageUrl = !categoryId
			? urlWithoutQueryParams
			: addQueryParameter(urlWithoutQueryParams, { category: categoryId });

		if (props.type === 'no-shop-found') {
			return getOnlineStoreNoShopFoundMetaTags({ canonicalUrl: window.location.origin });
		}

		if (!shopInfo) {
			return undefined;
		}

		switch (props.type) {
			case 'shop': {
				return getOnlineStoreShopMetaTags(shopInfo, {
					locationId: activeLocation?.id,
					defaultLanguageUrl,
					language,
					fbLocaleString: fbLocaleString ?? null,
					canonicalUrl,
				});
			}
			case 'product': {
				return getOnlineStoreProductMetaTags(props.product, {
					defaultLanguageUrl,
					language,
					shopName: shopInfo.name,
					shopLanguages: shopInfo.languages,
					fbLocaleString: fbLocaleString ?? null,
					canonicalUrl,
				});
			}
			case 'category': {
				return getOnlineStoreCategoryMetaTags(props.category, {
					defaultLanguageUrl,
					language,
					shopName: shopInfo.name,
					shopLanguages: shopInfo.languages,
					fbLocaleString: fbLocaleString ?? null,
					canonicalUrl,
				});
			}
			default: {
				return undefined;
			}
		}
	};

	const getFaviconMetaTags = () => {
		if (props.type === 'no-shop-found') {
			return getFaviconLinks({
				merchantName: 'Rentle',
				siteIcon: RENTLE_DEFAULT_FAVICON_PATH,
				addManifest: true,
			});
		}

		if (!shopInfo) {
			return undefined;
		}

		return getFaviconLinks({
			merchantName: shopInfo.name,
			siteIcon: faviconSrc ?? RENTLE_DEFAULT_FAVICON_PATH,
			addManifest: true,
		});
	};

	// It's important that we return null here to not allow react-helmet to render and remove any existing meta tags when we are still loading shop info
	if (props.type !== 'no-shop-found' && !shopInfo) {
		return null;
	}

	const seoMetaTags = getSeoMetaTags();
	const favIconMetaTags = getFaviconMetaTags();
	const seoMetaTagsAsReactElements = (seoMetaTags ?? []).map((tag) => parse(tag));
	const favIconMetaTagsAsReactElements = (favIconMetaTags ?? []).map((tag) => parse(tag));
	const metaTags = [...seoMetaTagsAsReactElements, ...favIconMetaTagsAsReactElements];

	return <Helmet>{metaTags}</Helmet>;
};

export default MetaTags;
