import { EcomEventName } from 'common/services/analytics';
import { stringEnum } from 'common/utils/objects';

const ecomEvents: EcomEventName[] = [
	'view_item_list',
	'view_item',
	'select_item',
	'add_to_cart',
	'remove_from_cart',
	'view_cart',
	'begin_checkout',
	'add_payment_info',
	'purchase',
];

export const EcomEvents = stringEnum(ecomEvents);
