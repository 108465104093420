import React from 'react';

import { Breadcrumbs, Link, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link as RRLink } from 'react-router-dom';

interface Props {
	breadCrumbs: {
		name: string;
		link: string | null;
	}[];
	fontSize?: 'small' | 'medium';
}

const BreadcrumbNav = (props: Props) => {
	const classes = useStyles();
	const theme = useTheme();
	const breadCrumbs = props.breadCrumbs;

	return (
		<Breadcrumbs
			classes={{ ol: classes.noWrap, li: classes.breadcrumbItem }}
			aria-label="breadcrumb"
			separator={<RiArrowRightSLine size={16} />}
		>
			{breadCrumbs.map(({ name, link }) => {
				return !!link ? (
					<RRLink key={name} to={link}>
						<Typography
							color="primary"
							className={classes.noTextWrap}
							sx={{ fontSize: props.fontSize ?? 'inherit' }}
						>
							{name}
						</Typography>
					</RRLink>
				) : (
					<Link key={name} className={classes.ellipsis}>
						<Typography
							sx={{
								color: theme.palette.common.black,
								fontWeight: 500,
								fontSize: props.fontSize ?? 'inherit',
							}}
						>
							{name}
						</Typography>
					</Link>
				);
			})}
		</Breadcrumbs>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		noWrap: {
			flexWrap: 'nowrap',
		},
		breadcrumbItem: {
			'&:hover': {
				textDecoration: 'underline',
			},
			'&:last-child': {
				textOverflow: 'ellipsis',
				overflow: 'hidden',
			},
		},
		noTextWrap: {
			whiteSpace: 'nowrap',
			color: theme.palette.common.black,
		},
		ellipsis: {
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			textDecoration: 'none',
		},
	}),
);

export default BreadcrumbNav;
