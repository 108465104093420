import React, { useCallback, useEffect, useRef } from 'react';

import { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { useAdyen } from 'common/hooks/useAdyen';
import { AdditionalDetailsState } from 'common/hooks/useAdyen/AdyenContext';
import {
	getPaymentMethodFromAdyenType,
	needsExternalRedirect,
} from 'common/modules/payments/paymentMethods';
import { AdditionalAdyenPaymentAction } from 'common/types';
import { isRunInIframe } from 'common/utils/browserUtils';

import { AdditionalDetailsUpdate } from '../PaymentMethodSelector';

interface Props {
	additionalPaymentAction: AdditionalAdyenPaymentAction | undefined;
	onAdditionalPaymentDetailsChange: (args: AdditionalDetailsUpdate) => void;
}

const AdyenAdditionalAction = (props: Props) => {
	const classes = useStyles();
	const { additionalPaymentAction, onAdditionalPaymentDetailsChange } = props;
	const additionalActionRef = useRef<HTMLDivElement>(null);

	const handleOnAdditionalAdyenDetails = useCallback(
		(state: AdditionalDetailsState) => {
			onAdditionalPaymentDetailsChange({ provider: 'ADYEN', data: state.data });
		},
		[onAdditionalPaymentDetailsChange],
	);

	const { adyenCheckout } = useAdyen(handleOnAdditionalAdyenDetails);

	useEffect(() => {
		const handleAdditionalAdyenAction = (_action: AdditionalAdyenPaymentAction['action']) => {
			// Force to use PaymentAction type to make TS happy, these should be equal anyway
			const action = _action as PaymentAction;
			const { paymentMethodType: adyenMethodType, url } = action;
			// Issue with Adyen types, need to cast the type field manually
			// https://github.com/Adyen/adyen-node-api-library/issues/616

			const type = action.type as
				| undefined
				| 'threeDS2Fingerprint'
				| 'threeDS2Challenge'
				| 'redirect';
			const isGetAction = action.method === 'GET';
			const inIframe = isRunInIframe();
			const paymentMethod = adyenMethodType
				? getPaymentMethodFromAdyenType(adyenMethodType)
				: undefined;
			const isRedirectActionInIframe = inIframe && type === 'redirect';
			const doExternalRedirect =
				isRedirectActionInIframe &&
				!!paymentMethod &&
				needsExternalRedirect(paymentMethod) &&
				isGetAction;
			if (doExternalRedirect && !!url && !!window?.top) {
				window.top.location.href = url;
				return;
			}
			if (additionalActionRef.current) {
				adyenCheckout!
					.createFromAction(
						action,
						/**
						 * Value '05' ensures that the challenge window always takes 100%x100% size
						 * From: https://docs.adyen.com/online-payments/3d-secure/native-3ds2/web-component?tab=create-new-component_2#handle-the-3d-secure-2-action
						 */
						{ challengeWindowSize: '05' },
					)
					.mount(additionalActionRef.current);
			}
		};

		if (additionalPaymentAction) {
			handleAdditionalAdyenAction(additionalPaymentAction.action);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [additionalPaymentAction]);

	return <div ref={additionalActionRef} className={classes.dialogAdditionalActionContainer} />;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogAdditionalActionContainer: {
			flex: 1,
			zIndex: 20,
			'&:empty': {
				display: 'none',
			},
		},
		'@global': {
			'.adyen-checkout__threeds2__challenge': {
				height: '100%',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
	}),
);

export default AdyenAdditionalAction;
