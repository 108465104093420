import React from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
	title?: string;
	delay?: number;
	minHeight?: number;
}

const LoadingState = (props: Props) => {
	const classes = useStyles(props);

	return (
		<Box className={classes.wrapper} minHeight={props.minHeight}>
			{!!props.title && <Typography variant="body1">{props.title}</Typography>}
			<CircularProgress />
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			width: '100%',
			minHeight: 200,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			padding: theme.spacing(2),
			animationName: '$fadeIn',
			animationDuration: (props: Props) => (props.delay ? `${(props.delay * 3) / 2}ms` : '800ms'),
		},
		'@keyframes fadeIn': {
			'0%': {
				opacity: 0,
			},
			'66%': {
				opacity: 0,
			},
			'100%': {
				opacity: 1,
			},
		},
	}),
);

export default LoadingState;
