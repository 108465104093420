import { createReducer } from '@reduxjs/toolkit';
import * as NavActions from 'redux/actions/nav';

type NavState = {
	shopName: string | null | undefined;
	locationName: string | null | undefined;
	isCustomStoreDomain: boolean | undefined;
};

const INITIAL_STATE: NavState = {
	shopName: undefined,
	locationName: undefined,
	isCustomStoreDomain: undefined,
};

const NavReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(NavActions.setShopName, (state, action) => {
		state.shopName = action.payload;
	});

	builder.addCase(NavActions.setLocationName, (state, action) => {
		state.locationName = action.payload;
	});

	builder.addCase(NavActions.setIsCustomStoreDomain, (state, action) => {
		state.isCustomStoreDomain = action.payload;
	});
});

export default NavReducer;
