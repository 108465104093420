import { TFunction } from 'i18next';
import { min, minBy, omit, sumBy } from 'lodash';
import moment from 'moment-timezone';

import { getDurationLabel } from 'common/constants/timePeriods';
import { OpeningHours } from 'common/modules/openingHours';
import { getTotalProductPricing } from 'common/modules/orders';
import {
	isProductForRent,
	isProductForSale,
	isProductForSubscription,
} from 'common/modules/products/utils';
import { getProductVariants } from 'common/modules/products/variants';
import { isLiftTicketPackageProduct } from 'common/modules/skidata';
import { ProductSubscription } from 'common/modules/subscriptions';
import { getSubscriptionPeriodAsMonths } from 'common/modules/subscriptions/utils/subscriptions';
import {
	AmountObject,
	CurrencyObject,
	Duration,
	DurationType,
	DurationWithPrice,
	Languages,
	OrderObject,
	OrderProduct,
	PricingItem,
	PricingTableObject,
	ProductApi,
	SegmentMap,
	SegmentPricing,
	SetProduct,
	ShopPublicInfo,
	Shopper,
	TimePeriod,
} from 'common/types';

import { BaseProductVariant } from '../../modules/inventory/types';
import { getPricingString, notNull, switchUnreachable } from '../common';
import { getDurationString, getNewEndTime } from '../dateCalculations';
import { findStockProductForOrderProduct } from '../productUtils';
import { getClosestPricingMultiplierForGivenDuration } from './getClosestPricingMultiplierForGivenDuration';

// TODO Kaikki tarvittavat propertyt hinnan hakuun hintataulukosta
export interface PricingInfoProperties {
	pricingTable: PricingItem[];
	basePrice: number;
	variantPriceIncrease: number;
	deposit: number;
}

interface ChargeInfo {
	charge: number;
	deposit: number;
}

export const getPricingTableForStockProduct = (
	stockProduct: ProductApi,
	savedPricingTables: PricingTableObject,
	segment?: string | null,
): PricingItem[] => {
	let pricingTable =
		(stockProduct.useSavedPricingTable &&
			stockProduct.savedPricingTable &&
			savedPricingTables[stockProduct.savedPricingTable]) ||
		stockProduct.pricing ||
		[];
	const activeSegments = (stockProduct.segmentPricings || []).flatMap(
		(segmentPricing) => segmentPricing.segments,
	);
	if (segment && activeSegments.indexOf(segment) !== -1) {
		const pricingTableItem = (stockProduct.segmentPricings || []).filter(
			(segmentPricing) => segmentPricing.segments.indexOf(segment) !== -1,
		)[0];
		if (pricingTableItem) {
			const tableOfPricingItem =
				pricingTableItem.useSavedPricingTable && pricingTableItem.savedPricingTable
					? savedPricingTables[pricingTableItem.savedPricingTable] ?? []
					: pricingTableItem.pricingTable;
			const pricingItemsToBeAdded = pricingTable.filter(
				(pItem) =>
					!tableOfPricingItem.some(
						(tItem) => tItem.timeValue === pItem.timeValue && tItem.timePeriod === pItem.timePeriod,
					),
			);
			const ItemsAdded = pricingItemsToBeAdded.map((pItem) => {
				const updatedMultiplier =
					((stockProduct.rentals?.basePrice ?? 0) / (pricingTableItem.fixedPrice || 1)) *
					(pItem.multiplier || 0);
				return {
					...pItem,
					multiplier: updatedMultiplier,
				};
			});
			pricingTable = getUniquePricingItems(tableOfPricingItem.concat(ItemsAdded));
		}
	}
	return pricingTable;
};

const getFixedPriceForStockProduct = (stockProduct: ProductApi, segment?: string | null) => {
	const activeSegments = (stockProduct.segmentPricings || []).flatMap(
		(segmentPricing) => segmentPricing.segments,
	);
	if (segment && activeSegments.indexOf(segment) !== -1) {
		const segmentPricingItem = (stockProduct.segmentPricings || []).filter(
			(segmentPricing) => segmentPricing.segments.indexOf(segment!) !== -1,
		)[0];
		if (segmentPricingItem) {
			return segmentPricingItem.fixedPrice || 0;
		}
	}
	return stockProduct.rentals?.basePrice ?? 0;
};

export const getVariantPriceIncreaseForPartialProduct = (
	mainVariant: BaseProductVariant | null,
	setProducts: SetProduct[],
) => {
	let variantPriceIncrease = 0;
	if (mainVariant && mainVariant.rentals?.priceIncrease) {
		variantPriceIncrease += mainVariant.rentals.priceIncrease;
	}
	// Disable variant price addition now for set, might need to add this later? // Toomas
	/*for (const setProduct of setProducts) {
		if (setProduct.selectedVariant && setProduct.selectedVariant.priceIncrease) {
			variantPriceIncrease += setProduct.selectedVariant.priceIncrease;
		}
	}*/
	return variantPriceIncrease;
};

export const getVariantPriceIncrease = (orderProduct: OrderProduct) => {
	let variantPriceIncrease = 0;
	if (orderProduct.variant && orderProduct.variant.rentals?.priceIncrease) {
		variantPriceIncrease += orderProduct.variant.rentals?.priceIncrease;
	}
	// Disable variant price addition now for set, might need to add this later? // Toomas
	/*if (orderProduct.set && orderProduct.setProducts) {
		for (const setProduct of orderProduct.setProducts) {
			if (setProduct.selectedVariant && setProduct.selectedVariant.priceIncrease) {
				variantPriceIncrease += setProduct.selectedVariant.priceIncrease;
			}
		}
	}*/
	return variantPriceIncrease;
};

export const getPricingInfoPropertiesForProduct = (
	stockProduct: ProductApi,
	savedPricingTables: PricingTableObject,
	variantPriceIncrease: number = 0,
	channel: 'ALL' | 'ADMIN' | 'ONLINE' | 'STORE' | 'HIDDEN' | 'API',
	segment?: string | null,
): PricingInfoProperties => {
	const isPricingItemAvailableForChannel = (item: PricingItem) => {
		if (!item.channels || item.channels.includes('ALL')) return true;
		if (item.channels.includes('HIDDEN')) return false;
		/**
		 * Type casting to as any is needed here because otherwise .includes would complain that API never exists in item.channels.
		 * Using simple .includes here is better than needing to define a separate case for API channel, and it's rather safe to add as any for the channel,
		 * as it's only a check if that value exists in the array.
		 */
		return item.channels.includes(channel as any);
	};

	const pricingTable = getPricingTableForStockProduct(stockProduct, savedPricingTables, segment);
	const filteredPricingTableByChannel = pricingTable.filter((item) =>
		isPricingItemAvailableForChannel(item),
	);
	const deposit = stockProduct.terms ? stockProduct.terms.deposit || 0 : 0;
	const basePrice = getFixedPriceForStockProduct(stockProduct, segment);
	return {
		pricingTable: filteredPricingTableByChannel,
		basePrice,
		variantPriceIncrease,
		deposit,
	};
};

export const getDurationFromPricingItemAndOpeningHours = (
	pricingItem: Pick<PricingItem, 'timePeriod' | 'timeValue'>,
	openingHours: OpeningHours,
	startDate: string | null,
	timezone: string,
): { durationInSeconds: number; validTime: boolean; endTime?: string } => {
	const timeValue = pricingItem.timeValue || 0;
	let durationInSeconds: number | null = null;
	switch (pricingItem.timePeriod) {
		case 'minutes':
			durationInSeconds = timeValue * 60;
			break;
		case 'hours':
			durationInSeconds = timeValue * 3600;
			break;
		case 'days':
		case '24h_days':
			durationInSeconds = timeValue * 24 * 3600;
			break;
		case 'days_within_opening_hours':
			break;
		case 'weeks':
			durationInSeconds = timeValue * 24 * 7 * 3600;
			break;
		case 'months':
			durationInSeconds = timeValue * 24 * 30 * 3600;
			break;
		case 'years':
			durationInSeconds = timeValue * 24 * 3600 * 365;
			break;
		default:
			switchUnreachable(pricingItem.timePeriod);
			durationInSeconds = timeValue * 3600;
			break;
	}
	if (durationInSeconds != null) {
		return { durationInSeconds, validTime: true };
	}
	// This is to get duration that is 1min over the previous day
	// to work with the fact that "1day" can be 1 - 24h if it is inside opening hours
	durationInSeconds = Math.max(timeValue - 1, 0) * 24 * 3600 + 60;
	if (!startDate) {
		return {
			// This is to get duration that is 1min over the previous day
			// to work with the fact that "1day" can be 1 - 24h if it is inside opening hours
			durationInSeconds,
			validTime: true,
		};
	}
	const endTime = moment(
		getNewEndTime(
			startDate,
			{ durationInSeconds, durationType: 'opening_hours', durationName: null },
			openingHours,
			timezone,
		),
	);
	const diffInDays = Math.ceil(endTime.diff(startDate, 'days', true));
	return {
		durationInSeconds:
			diffInDays === pricingItem.timeValue ? endTime.diff(startDate, 'seconds') : durationInSeconds,
		endTime: endTime.toISOString(),
		validTime: true,
	};
};

export const getDurationInSecondsFromPricingItem = (
	timePeriod: TimePeriod,
	timeValue: number,
): number => {
	switch (timePeriod) {
		case 'minutes':
			return timeValue * 60;
		case 'hours':
			return timeValue * 3600;
		case 'days':
		case '24h_days':
		case 'days_within_opening_hours':
			return timeValue * 24 * 3600;
		case 'weeks':
			return timeValue * 24 * 7 * 3600;
		case 'months':
			return timeValue * 24 * 30 * 3600;
		case 'years':
			return timeValue * 24 * 3600 * 365;
		default:
			try {
				switchUnreachable(timePeriod);
			} catch (error) {
				console.error(error);
			}
			return timeValue * 3600;
	}
};

const getUniquePricingItems = (pricingItems: PricingItem[]): PricingItem[] => {
	const duplicateObject: { [key: number]: string[] } = {};
	const uniquePricingItems = pricingItems.reduce(
		(uniquePricingItems: PricingItem[], pricingItem) => {
			const durationInSeconds = getDurationInSecondsFromPricingItem(
				pricingItem.timePeriod,
				pricingItem.timeValue || 0,
			);
			const pricingTypeWithLabel =
				getDurationTypeFromTimePeriod(pricingItem.timePeriod) +
				(pricingItem.label ? pricingItem.label.def : '');
			if (
				!duplicateObject[durationInSeconds] ||
				!duplicateObject[durationInSeconds].includes(pricingTypeWithLabel)
			) {
				duplicateObject[durationInSeconds] = [
					...(duplicateObject[durationInSeconds] || []),
					pricingTypeWithLabel,
				];
				uniquePricingItems.push(pricingItem);
			}
			return uniquePricingItems;
		},
		[],
	);
	return uniquePricingItems.sort(sortPricingItems);
};

export const getChargesFromPricingInfoForGivenDuration = (
	pricingInfo: PricingInfoProperties,
	duration: Duration,
): ChargeInfo => {
	const { pricingTable, basePrice, deposit, variantPriceIncrease } = pricingInfo;
	let charge = basePrice;
	const multiplier = getClosestPricingMultiplierForGivenDuration(pricingTable, duration);
	if (multiplier != null) {
		charge = basePrice * multiplier;
	}
	charge += variantPriceIncrease;
	return {
		charge: Math.round(charge),
		deposit: Math.round(deposit),
	};
};

export const getDurationTypeFromTimePeriod = (timePeriod: TimePeriod | 'fixed'): DurationType => {
	if (timePeriod === 'days_within_opening_hours') {
		return 'opening_hours';
	}
	return '24h';
};

export const getDurationFromDurationWithPriceOption = (option: DurationWithPrice): Duration => ({
	durationType: getDurationTypeFromTimePeriod(option.timePeriod),
	durationInSeconds: option.durationInSeconds,
	durationName: option.label ?? null,
});

export const getSalesProductDuration = () =>
	({
		durationInSeconds: 0, //TODO: If this is 0 does it break anything
		durationType: '24h',
		durationName: null,
	} as Duration);

export const getLabelForDurationWithPrice = (
	durationWithPrice: DurationWithPrice,
	lang: Languages,
	t: TFunction,
): string => {
	const { timePeriod, timeValue, label } = durationWithPrice;
	if (!label && !!timePeriod && !!timeValue && timePeriod !== 'fixed') {
		return getDurationLabel(timePeriod, timeValue, t);
	}
	return (
		getDurationString(getDurationFromDurationWithPriceOption(durationWithPrice), 'long', lang, t) ||
		(durationWithPrice.timePeriod === 'fixed'
			? t('common:pricing.fixed', 'Fixed price')
			: durationWithPrice.timePeriod)
	);
};

export const getDescriptionForDurationWithPrice = (
	durationWithPrice: DurationWithPrice,
	t: TFunction,
): string | null => {
	const duration = getDurationFromDurationWithPriceOption(durationWithPrice);

	return duration.durationType === 'opening_hours'
		? t('common:times.untilStoreClosing', 'Until store closing')
		: null;
};

const getDurationWithPriceOptionsFromPricingItems = (
	pricingItems: PricingItem[],
	pricingInfos: PricingInfoProperties[],
	openingHours: OpeningHours,
	startDate: string | null,
	timezone: string,
) => {
	const durationWithPriceOptions = [];
	for (const pricingItem of pricingItems) {
		if (pricingItem.timeValue === '') {
			continue;
		}
		const { durationInSeconds, endTime, validTime } = getDurationFromPricingItemAndOpeningHours(
			pricingItem,
			openingHours,
			startDate,
			timezone,
		);
		const durationType = getDurationTypeFromTimePeriod(pricingItem.timePeriod);
		const duration: Duration = {
			durationInSeconds,
			durationType,
			durationName: pricingItem.label || null,
		};
		const totalPrice = pricingInfos
			.map((pricingInfo) => {
				const { charge } = getChargesFromPricingInfoForGivenDuration(pricingInfo, duration);
				return charge;
			})
			.reduce((a, b) => a + b, 0);
		const durationWithPrice: DurationWithPrice = {
			disabled: !validTime,
			timePeriod: pricingItem.timePeriod,
			timeValue: pricingItem.timeValue,
			price: totalPrice,
			durationInSeconds,
			endTime,
			...(pricingItem.label && { label: pricingItem.label }),
		};
		durationWithPriceOptions.push(durationWithPrice);
	}
	if (durationWithPriceOptions.length === 0) {
		const price: number = pricingInfos
			.map((pricingInfo) => pricingInfo.basePrice)
			.reduce((totalPrice: number, productPrice) => totalPrice + productPrice, 0);
		const option: DurationWithPrice = {
			disabled: false,
			timePeriod: 'fixed',
			price,
			timeValue: 0,
			durationInSeconds: 0,
		};
		durationWithPriceOptions.push(option);
	}
	return durationWithPriceOptions;
};

export const getDurationOptionsForOrderProducts = (args: {
	orderProducts: OrderProduct[];
	stockProducts: ProductApi[];
	savedPricingTables: PricingTableObject;
	openingHours: OpeningHours;
	startDate: string | null;
	channel: 'ALL' | 'ADMIN' | 'ONLINE' | 'STORE' | 'HIDDEN';
	timezone: string;
	segment?: string | null;
	useAdditionalProduct?: boolean;
}): DurationWithPrice[] => {
	const {
		orderProducts,
		stockProducts,
		savedPricingTables,
		openingHours,
		startDate,
		channel,
		timezone,
		segment,
		useAdditionalProduct,
	} = args;
	const nonAdditionalProductsPricingItems: PricingItem[] = [];
	const modifiedPriceProducts = orderProducts.filter((product) => {
		const isModifiedPrice = product.pricing.originalListPrice !== product.pricing.listPrice;
		return isModifiedPrice;
	});
	const productsPricingInfos: PricingInfoProperties[] = orderProducts
		.map((orderProduct) => {
			const stockProduct = findStockProductForOrderProduct(orderProduct, stockProducts);
			if (!stockProduct) {
				const orderProductPricingProperties: PricingInfoProperties = {
					basePrice: orderProduct.pricing.listPrice,
					deposit: orderProduct.pricing.deposit ?? 0,
					pricingTable: [],
					variantPriceIncrease: 0,
				};
				return orderProductPricingProperties;
			}
			let pricingInfo = getPricingInfoPropertiesForProduct(
				stockProduct,
				savedPricingTables,
				getVariantPriceIncrease(orderProduct),
				channel,
				orderProduct.segment ? orderProduct.segment : segment,
			);
			if (modifiedPriceProducts.map((p) => p.id).includes(orderProduct.id)) {
				const basePrice = orderProduct.pricing.listPrice;
				pricingInfo = {
					...pricingInfo,
					pricingTable: pricingInfo.pricingTable.map((t) => ({
						...t,
						multiplier: 1,
						price: basePrice,
					})),
					basePrice,
					variantPriceIncrease: 0,
				};
			}
			if (!orderProduct.selectedAsAdditional || useAdditionalProduct) {
				nonAdditionalProductsPricingItems.push(...pricingInfo.pricingTable);
			}
			return pricingInfo;
		})
		.filter(notNull);
	const pricingItems = getUniquePricingItems(nonAdditionalProductsPricingItems);
	const productsDurationOptions = getDurationWithPriceOptionsFromPricingItems(
		pricingItems,
		productsPricingInfos,
		openingHours,
		startDate,
		timezone,
	);
	return productsDurationOptions;
};

export const getDurationOptionsForStockProducts = (args: {
	stockProducts: ProductApi[];
	savedPricingTables: PricingTableObject;
	openingHours: OpeningHours;
	startDate: string | null;
	channel: 'ALL' | 'ADMIN' | 'ONLINE' | 'STORE' | 'HIDDEN';
	timezone: string;
	segment?: string;
}): DurationWithPrice[] => {
	const {
		stockProducts,
		savedPricingTables,
		openingHours,
		startDate,
		channel,
		segment,
		timezone,
	} = args;
	const listOfPricingInfoProperties: PricingInfoProperties[] = stockProducts.map((stockProduct) => {
		return getPricingInfoPropertiesForProduct(
			stockProduct,
			savedPricingTables,
			0,
			channel,
			segment,
		);
	});
	const stockProductsPricingItems: PricingItem[] = listOfPricingInfoProperties.flatMap(
		({ pricingTable }) => pricingTable,
	);
	const pricingItems = getUniquePricingItems(stockProductsPricingItems);
	return getDurationWithPriceOptionsFromPricingItems(
		pricingItems,
		listOfPricingInfoProperties,
		openingHours,
		startDate,
		timezone,
	);
};

export const getSelectedDurationOptionIndex = (
	durationOptions: DurationWithPrice[],
	duration: Duration | null,
) => {
	if (!duration) {
		return -1;
	}
	const { durationInSeconds, durationType, durationName } = duration;
	const durationInDays = Math.ceil(moment.duration(durationInSeconds, 'seconds').asDays());
	if (durationType === 'opening_hours') {
		for (let i = 0; i < durationOptions.length; i++) {
			const durationOption = durationOptions[i];
			const optionDurationType = getDurationTypeFromTimePeriod(durationOption.timePeriod);
			if (optionDurationType === 'opening_hours') {
				const days = Math.ceil(
					moment.duration(durationOption.durationInSeconds, 'seconds').asDays(),
				);
				if (
					days === durationInDays &&
					((!durationName && !durationOption.label) ||
						(durationOption.label && durationName && durationOption.label.def === durationName.def))
				) {
					return i;
				}
			}
		}
		return -1;
	}
	// tslint:disable-next-line: prefer-for-of
	for (let i = 0; i < durationOptions.length; i++) {
		const durationOption = durationOptions[i];
		if (
			durationOption.durationInSeconds === durationInSeconds &&
			((!durationName && !durationOption.label) ||
				(durationOption.label && durationName && durationOption.label.def === durationName.def))
		) {
			return i;
		}
	}
	return -1;
};

export const getSelectedDurationOption = (
	durationOptions: DurationWithPrice[],
	duration: Duration | null,
) => {
	const index = getSelectedDurationOptionIndex(durationOptions, duration);
	return index !== -1 ? durationOptions[index] : null;
};

const timePeriodValues: Record<TimePeriod, number> = {
	minutes: 1,
	hours: 2,
	days: 3,
	days_within_opening_hours: 3,
	'24h_days': 4,
	weeks: 5,
	months: 6,
	years: 7,
};

/**
 *  Compares two pricing items and returns a number indicating their relative order.
 *  The order is determined by two criteria:
 *
 *  1. The duration of the pricing item in seconds
 *  2. If the duration is equal, the time period unit
 *
 * When sorting an array of time periods, this will result in the following order:
 *
 *  - 1 hour
 *  - 3 days
 *  - 7 days
 *  - 1 week
 *  - 8 days
 *  - 1 month
 *
 * @param a A pricing item
 * @param b A pricing item
 * @returns Less than 0 if A should be first, greater than 0 if B should be first, 0 if they are equal
 */
export const sortPricingItems = (a: PricingItem, b: PricingItem) => {
	const aTimePeriodValue = timePeriodValues[a.timePeriod];
	const bTimePeriodValue = timePeriodValues[b.timePeriod];
	const aTimeValue = a.timeValue || 0;
	const bTimeValue = b.timeValue || 0;

	if (aTimePeriodValue === bTimePeriodValue) {
		return aTimeValue - bTimeValue;
	} else if (aTimeValue === bTimeValue) {
		return aTimePeriodValue - bTimePeriodValue;
	} else {
		const aDuration = getDurationInSecondsFromPricingItem(a.timePeriod, a.timeValue || 0);
		const bDuration = getDurationInSecondsFromPricingItem(b.timePeriod, b.timeValue || 0);

		if (aDuration !== bDuration) {
			return aDuration - bDuration;
		} else {
			return aTimePeriodValue - bTimePeriodValue;
		}
	}
};

export const sortSubscriptions = (a: ProductSubscription, b: ProductSubscription) => {
	const aMinCommitmentMonths = getSubscriptionPeriodAsMonths(a.minCommitment);
	const bMinCommitmentMonths = getSubscriptionPeriodAsMonths(b.minCommitment);

	if (aMinCommitmentMonths !== bMinCommitmentMonths) {
		return aMinCommitmentMonths - bMinCommitmentMonths;
	}

	const aTimePeriodValue = timePeriodValues[a.cycle.unit];
	const bTimePeriodValue = timePeriodValues[b.cycle.unit];

	if (aTimePeriodValue !== bTimePeriodValue) {
		return aTimePeriodValue - bTimePeriodValue;
	}

	return 0;
};

export const getSubscriptionPrice = (productSubscription: ProductSubscription | undefined) => {
	if (!productSubscription) {
		return 0;
	}
	return productSubscription.pricePerCycle.value;
};

export const calculateProportionOfDiscount = (
	amountToCalculateProportionFor: number,
	amountsToDivideProportionallyFor: number[],
	discountAmount: number,
	addPossibleRemainder: boolean,
) => {
	const total = amountsToDivideProportionallyFor.reduce((prev, total) => (total += prev), 0);
	if (total === 0) {
		return 0;
	}
	let proportionToReturn = Math.round(discountAmount * (amountToCalculateProportionFor / total));
	if (addPossibleRemainder) {
		let possibleRemainder = discountAmount;
		for (const amount of amountsToDivideProportionallyFor) {
			const proportionOfAmount = Math.round(discountAmount * (amount / total));
			possibleRemainder -= proportionOfAmount;
		}
		proportionToReturn += possibleRemainder;
	}
	return proportionToReturn;
};

export const getDiscountForShopper = (
	shopperProducts: OrderProduct[],
	allProducts: OrderProduct[],
	shopper: Shopper,
	activeRental: OrderObject,
) => {
	const pricing = getTotalProductPricing(shopperProducts);
	return pricing.manualDiscount ?? 0;
};

export const deleteEmptyLabel = (pricingItem: PricingItem) => {
	if (pricingItem.label !== undefined && pricingItem.label.def === '') {
		return omit(pricingItem, 'label');
	}
	return pricingItem;
};

export const checkIfPricingItemLabelShouldBeDeletedForProduct = (product: ProductApi) => {
	if (
		!product.useSavedPricingTable &&
		product.pricing &&
		product.pricing.some((priceItem) => priceItem.label !== undefined && priceItem.label.def === '')
	) {
		const updatedPricing = product.pricing.map((pricingItem) => {
			return deleteEmptyLabel(pricingItem);
		});
		product.pricing = updatedPricing;
	}
	if (
		product.segmentPricings &&
		product.segmentPricings.some((segment) => !segment.useSavedPricingTable)
	) {
		const updatedSegmentPricing = product.segmentPricings.map((segment) => {
			if (
				!segment.useSavedPricingTable &&
				segment.pricingTable.some(
					(pricingItem) => pricingItem.label !== undefined && pricingItem.label.def === '',
				)
			) {
				const updatedPricingTable = segment.pricingTable.map((pricingItem) => {
					return deleteEmptyLabel(pricingItem);
				});
				segment.pricingTable = updatedPricingTable;
			}
			return segment;
		});
		product.segmentPricings = updatedSegmentPricing;
	}
	return product;
};

export const checkIfPricingItemLabelShouldBeDeletedForSavedPricingTable = (
	shopInfo: ShopPublicInfo,
) => {
	if (shopInfo.pricingTables) {
		const allPricingItems = Object.values(shopInfo.pricingTables);
		if (
			allPricingItems.some((pricingTable) =>
				pricingTable.some(
					(pricingItem) => pricingItem.label !== undefined && pricingItem.label.def === '',
				),
			)
		) {
			allPricingItems.map((pricingTable, index) => {
				if (
					pricingTable.some(
						(pricingItem) => pricingItem.label !== undefined && pricingItem.label.def === '',
					)
				) {
					const updatedPricingTable = pricingTable.map((pricingItem) => {
						return deleteEmptyLabel(pricingItem);
					});
					pricingTable = updatedPricingTable;
				}
				return pricingTable;
			});
		}
	}
	return shopInfo;
};

export const getRentleSegmentName = (segmentMapping: SegmentMap[]) => (externalId: string) =>
	segmentMapping.reduce(
		(segment, segmentMap) =>
			segmentMap.externalSegmentId === externalId ? segmentMap.rentleSegment || segment : segment,
		externalId,
	);

export const getLowestLiftTicketPrice = (pricings: SegmentPricing[]) => {
	const pricesLowestToHighest = pricings.map((p) => Number(p.fixedPrice)).sort((a, b) => a - b);
	const [lowestPrice] = pricesLowestToHighest;
	return !!lowestPrice ? lowestPrice : 0;
};

export const getLowestVariantPriceIncrease = (productVariants: BaseProductVariant[] = []) =>
	productVariants.reduce(
		(min, variant) =>
			(variant.rentals?.priceIncrease || 0) < min ? variant.rentals?.priceIncrease || 0 : min,
		(productVariants.length && productVariants[0].rentals?.priceIncrease) || 0,
	);

export const getLowestPriceFromDurationWithPrices = (
	durationWithPrices: DurationWithPrice[] = [],
) =>
	durationWithPrices.reduce(
		(min, durationWithPrice) => (durationWithPrice.price < min ? durationWithPrice.price : min),
		(durationWithPrices.length && durationWithPrices[0].price) || 0,
	);

export const getShortestDurationFromDurationWithPrices = (
	durationWithPrices: DurationWithPrice[] = [],
): DurationWithPrice | undefined => {
	return minBy(durationWithPrices, (d) => d.durationInSeconds);
};

export const getLowestPriceForSegmentOrRegular = (args: {
	stockProduct: ProductApi;
	savedPricingTables: PricingTableObject;
	openingHours: OpeningHours;
	startDate: string | null;
	channel: 'ALL' | 'ADMIN' | 'ONLINE' | 'STORE' | 'HIDDEN';
	timezone: string;
	segment?: string;
}) => {
	const {
		stockProduct,
		savedPricingTables,
		openingHours,
		startDate,
		channel,
		segment,
		timezone,
	} = args;
	const durationWithPrices = getDurationOptionsForStockProducts({
		stockProducts: [stockProduct],
		savedPricingTables,
		openingHours,
		startDate,
		channel,
		timezone,
		segment,
	});
	return getLowestPriceFromDurationWithPrices(durationWithPrices);
};

export const getShortestDurationForSegmentOrRegular = (args: {
	stockProduct: ProductApi;
	savedPricingTables: PricingTableObject;
	openingHours: OpeningHours;
	startDate: string | null;
	channel: 'ALL' | 'ADMIN' | 'ONLINE' | 'STORE' | 'HIDDEN';
	segment?: string;
	timezone: string;
}): DurationWithPrice | undefined => {
	const {
		stockProduct,
		savedPricingTables,
		openingHours,
		startDate,
		channel,
		segment,
		timezone,
	} = args;
	const durationWithPrices = getDurationOptionsForStockProducts({
		stockProducts: [stockProduct],
		savedPricingTables,
		openingHours,
		startDate,
		channel,
		timezone,
		segment,
	});
	return getShortestDurationFromDurationWithPrices(durationWithPrices);
};

export const getLowestRentalPriceFromStockProduct = (
	stockProduct: ProductApi,
	savedPricingTables: PricingTableObject,
	openingHours: OpeningHours,
	startDate: string | null,
	channel: 'ALL' | 'ADMIN' | 'ONLINE' | 'STORE' | 'HIDDEN',
	timezone: string,
): number | null => {
	if (!isProductForRent(stockProduct)) return null;

	const lowestVariantPriceIncrease = getLowestVariantPriceIncrease(
		getProductVariants(stockProduct),
	);

	const lowestRegularPrice = getLowestPriceForSegmentOrRegular({
		stockProduct,
		savedPricingTables,
		openingHours,
		startDate,
		channel,
		timezone,
	});
	const segments: string[] = [
		...new Set(
			(stockProduct.segmentPricings || [])
				.reduce((arr, pricing) => [...arr, ...pricing.segments], [] as string[])
				.filter((segment) => Boolean(segment)),
		),
	];
	const lowestPrice = segments
		.map((segment) =>
			getLowestPriceForSegmentOrRegular({
				stockProduct,
				savedPricingTables,
				openingHours,
				startDate,
				channel,
				segment,
				timezone,
			}),
		)
		.concat([lowestRegularPrice])
		.reduce((min, price) => (price < min ? price : min), lowestRegularPrice);

	return lowestPrice + lowestVariantPriceIncrease;
};

export const getLowestSalesPriceFromStockProduct = (product: ProductApi): number | null => {
	if (!isProductForSale(product)) return null;

	return min(product.variants.options.map((variant) => getSalesPrice(product, variant))) ?? null;
};

export const getLowestSubscriptionPriceFromStockProduct = (product: ProductApi): number | null => {
	if (!isProductForSubscription(product)) return null;

	return min(product.subscriptions?.options.map((option) => option.pricePerCycle.value)) ?? null;
};

export const getLowestPriceFromStockProduct = (args: {
	product: ProductApi;
	pricingTables: PricingTableObject;
	openingHours: OpeningHours;
	timezone: string;
	startDate?: string | null;
	channel?: 'ALL' | 'ADMIN' | 'ONLINE' | 'STORE' | 'HIDDEN';
}): number | null => {
	if (isLiftTicketPackageProduct(args.product)) return null;

	const lowestRentalPrice = getLowestRentalPriceFromStockProduct(
		args.product,
		args.pricingTables,
		args.openingHours,
		args.startDate ?? null,
		args.channel ?? 'ALL',
		args.timezone,
	);

	const lowestSalesPrice = getLowestSalesPriceFromStockProduct(args.product);

	return min([lowestRentalPrice, lowestSalesPrice].filter(notNull)) ?? null;
};

export const getFromPriceString = (
	args: {
		product: ProductApi;
		pricingTables: PricingTableObject;
		openingHours: OpeningHours;
		currency: CurrencyObject;
		timezone: string;
		startDate?: string | null;
		channel?: 'ALL' | 'ADMIN' | 'ONLINE' | 'STORE' | 'HIDDEN';
		kind?: 'book-from' | 'buy-from' | 'from';
	},
	t: TFunction,
): string => {
	const lowestPrice = getLowestPriceFromStockProduct(args);
	return lowestPrice !== null
		? buildFromPriceString({
				price: lowestPrice,
				currency: args.currency,
				t,
				kind: args.kind,
				noZeroDecimals: true,
		  })
		: '';
};

export const buildFromPriceString = (args: {
	price: number | undefined | null;
	currency: CurrencyObject;
	t: TFunction;
	kind?: 'book-from' | 'buy-from' | 'subscribe-from' | 'from';
	noZeroDecimals?: boolean;
}) => {
	const { price, currency, t, kind, noZeroDecimals } = args;
	if (!price || price <= 0) return t('common:pricing.free', 'Free');

	switch (kind) {
		case 'book-from':
			return t('common:pricing.bookFrom', {
				price: getPricingString(price, currency, noZeroDecimals),
				defaultValue: 'Book from {{price}}',
			});
		case 'buy-from':
			return t('common:pricing.buyFrom', {
				price: getPricingString(price, currency, noZeroDecimals),
				defaultValue: 'Buy from {{price}}',
			});

		case 'subscribe-from':
			return t('common:pricing.subscribeFrom', {
				price: getPricingString(price, currency, noZeroDecimals),
				defaultValue: 'From {{price}} /month',
			});

		case 'from':
		default:
			return t('common:pricing.from', {
				price: getPricingString(price, currency, noZeroDecimals),
				defaultValue: 'From {{price}}',
			});
	}
};

export const multiplyPrice = (price: AmountObject, multiplier: number): AmountObject => {
	return {
		...price,
		value: Math.round(price.value * multiplier),
	};
};

export const sumPrices = (prices: AmountObject[]): AmountObject => {
	if (prices.length === 0) {
		return {
			value: 0,
			currency: 'USD',
		};
	}

	return {
		currency: prices[0].currency,
		value: sumBy(prices, (price) => price.value),
	};
};

export const getRentalPrice = (
	priceFromDuration: number | undefined,
	selectedVariant: BaseProductVariant | null,
) => {
	if (!selectedVariant || priceFromDuration === undefined) {
		return null;
	}
	return (priceFromDuration || 0) + (selectedVariant.rentals?.priceIncrease || 0);
};

export const getSalesPrice = (
	product: ProductApi,
	selectedVariant: BaseProductVariant | null,
): number => {
	const variantPrice = selectedVariant
		? product.variants.options.find((o) => o.id === selectedVariant.id)?.sales?.priceOverride
		: null;
	return variantPrice ?? product.sales?.basePrice ?? 0;
};

export const getFromSubscriptionPriceString = (args: {
	product: ProductApi;
	currency: CurrencyObject;
	t: TFunction;
	kind: 'subscribe-from';
}) => {
	const { product, currency, t, kind } = args;
	const lowestPrice = getLowestSubscriptionPriceFromStockProduct(product);

	return lowestPrice !== null
		? buildFromPriceString({
				price: lowestPrice,
				currency,
				t,
				kind,
				noZeroDecimals: true,
		  })
		: '';
};

export const getFromSalesPriceString = (args: {
	product: ProductApi;
	currency: CurrencyObject;
	t: TFunction;
	kind: 'buy-from' | 'from';
}) => {
	const { product, currency, t, kind } = args;
	const lowestPrice = getLowestSalesPriceFromStockProduct(product);
	return lowestPrice !== null
		? buildFromPriceString({
				price: lowestPrice,
				currency,
				t,
				noZeroDecimals: true,
				kind,
		  })
		: '';
};

export const getFromRentalPriceString = (args: {
	product: ProductApi;
	pricingTables: PricingTableObject;
	openingHours: OpeningHours;
	currency: CurrencyObject;
	timezone: string;
	channel?: 'ALL' | 'ADMIN' | 'ONLINE' | 'STORE' | 'HIDDEN';
	kind: 'book-from' | 'from';
	t: TFunction;
}) => {
	const { product, pricingTables, openingHours, timezone, currency, channel, t, kind } = args;
	const lowestPrice = getLowestRentalPriceFromStockProduct(
		product,
		pricingTables,
		openingHours,
		null,
		channel ?? 'ALL',
		timezone,
	);

	return lowestPrice !== null
		? buildFromPriceString({
				price: lowestPrice,
				currency,
				t,
				noZeroDecimals: true,
				kind,
		  })
		: '';
};
