import React, { useState } from 'react';

import { Box, ButtonBase, Link, Theme, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { RiQuestionLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import * as ViewActions from 'redux/actions/view';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';

import Banner from 'common/components/states/Banner';
import { getPricingString } from 'common/utils/common';
import BoxButton from 'components/BoxButton';
import DepositInfoModal from 'components/DepositInfoModal';
import { useTranslation } from 'services/localization';

interface Props {
	onCheckoutButtonClick?: () => void;
	isCartValid?: boolean;
	isCartValidating?: boolean;
	hasValidationError?: boolean;
	hasPickupSlotsNotFoundError?: boolean;
}
const CartFooter = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const [depositModalOpen, setDepositModalOpen] = useState<boolean>(false);

	const {
		onCheckoutButtonClick,
		isCartValid,
		isCartValidating,
		hasValidationError,
		hasPickupSlotsNotFoundError,
	} = props;

	const productsCount = useSelector(CartSelectors.cartProductsCount);
	const cartPricing = useSelector(CartSelectors.cartPricing);
	const includedRentalProductIds = useSelector(CartSelectors.includedRentalProductIds);
	const cartCurrency = useSelector(ShopSelectors.shopCurrency);

	return (
		<Box className={classes.wrapper}>
			{productsCount > 0 && (!isCartValid || hasValidationError) && (
				<Box mb={3}>
					<Banner
						variant="danger"
						title={
							hasPickupSlotsNotFoundError
								? t('Cart.pickupslotsNotFound', 'Sorry, this selection is not available anymore.')
								: !isCartValid
								? t('Cart.updateBagToContinue', 'Update bag to continue')
								: t('common:errors.error')
						}
					/>
				</Box>
			)}
			{productsCount !== 0 && (
				<Box>
					<Box display="flex" flexDirection="row" justifyContent="space-between" mb={2}>
						<Box>
							<Typography variant="body2" sx={{ fontSize: '1.8rem', fontWeight: 500 }}>
								{cartPricing.taxExcluded ? t('common:payment.subtotal') : t('common:payment.total')}
							</Typography>
							{!!cartPricing.deposit && (
								<Box>
									<ButtonBase onClick={() => setDepositModalOpen(true)}>
										<Typography variant="body2">
											{`+ ${t('summary.securityDeposit', 'Security deposit')}, ${getPricingString(
												cartPricing.deposit,
												cartCurrency,
											)}`}
										</Typography>
										<Box
											display="inline"
											sx={{ ml: 1, color: (theme) => theme.palette.text.secondary }}
										>
											<RiQuestionLine color="inherit" size={24} />
										</Box>
									</ButtonBase>
									<DepositInfoModal
										isOpen={depositModalOpen}
										onClose={() => setDepositModalOpen(false)}
										productIds={includedRentalProductIds}
										depositAmount={cartPricing.deposit}
									/>
								</Box>
							)}
							{cartPricing.taxExcluded && (
								<Typography variant="caption" color="textSecondary">
									{t('common:taxes.calculatedAtCheckout', 'Taxes are calculated at checkout')}
								</Typography>
							)}
						</Box>
						<Typography variant="body2" sx={{ fontSize: '1.8rem', fontWeight: 500 }}>
							{getPricingString(cartPricing.listPrice, cartCurrency)}
						</Typography>
					</Box>
					<Box mt={1} mb={2.5}>
						<BoxButton
							text={t('common:actions.checkout', 'Checkout')}
							variant="filled"
							padding={isSmallScreen ? 'large' : 'normal'}
							onClick={onCheckoutButtonClick}
							disabled={!isCartValid || isCartValidating}
							isLoading={isCartValidating}
						/>
					</Box>
				</Box>
			)}
			<Link
				component="button"
				variant="body2"
				underline="none"
				className={classes.continueShopping}
				onClick={() => dispatch(ViewActions.setCartOpen(false))}
			>
				{t('common:actions.continueShopping', 'Continue shopping')}
			</Link>
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: theme.spacing(3),
		display: 'flex',
		flexDirection: 'column',
		boxShadow: '0 -2px 16px 0 rgba(0, 0, 0, 0.11)',
	},
	continueShopping: {
		textTransform: 'uppercase',
		paddingBottom: '1px',
		color: theme.palette.text.secondary,
		borderColor: theme.palette.text.secondary,
		borderBottomStyle: 'solid',
		borderBottomWidth: '1px',
		alignSelf: 'center',
		'&:hover': {
			color: theme.palette.text.primary,
			borderColor: theme.palette.text.primary,
		},
	},
}));

export default CartFooter;
