import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import * as Analytics from 'redux/actions/analytics';

import { EcomEventName, EventName, Events, logEvent } from 'common/services/analytics';

export const useAnalytics = () => {
	const dispatch = useDispatch();

	const _logEcomEvent = useCallback(
		<T extends EcomEventName>(eventName: T, eventParams: Analytics.EcomEventParams[T]) => {
			dispatch(Analytics.logEcomEvent(eventName, eventParams));
		},
		[dispatch],
	);

	const _logEvent = useCallback(
		<T extends Exclude<EventName, EcomEventName>>(name: T, params?: Events[T]) =>
			logEvent(name, params),
		[],
	);

	return {
		logEcomEvent: _logEcomEvent,
		logEvent: _logEvent,
	};
};
