import React, { useCallback, useEffect, useState } from 'react';

import {
	Box,
	Button,
	Checkbox,
	Container,
	FormControlLabel,
	Theme,
	Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrderInfo, updateProducts, updateShopper } from 'redux/actions/confirm';
import * as ConfirmSelectors from 'redux/selectors/confirm';
import * as NavSelectors from 'redux/selectors/nav';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import Spinner from 'common/components/Spinner';
import { Duration, OrderProduct, Shopper } from 'common/types';
import { getDurationString } from 'common/utils/dateCalculations';
import {
	findProductKeycard,
	isKeycardProduct,
	isLiftTicketProduct,
} from 'common/utils/liftTicketUtils';
import { getProductNameWithVariant } from 'common/utils/productUtils';
import { getSkidataSegmentTranslation } from 'common/utils/segments';
import { getSegmentTranslation } from 'common/utils/translations';
import { useTranslation } from 'services/localization/useTranslation';
import { Routes } from 'services/routing/constants';
import { useRoutes } from 'services/routing/useRoutes';

import ShopperHeaderBar from './ShopperNameStepper';
import { productsBack, productsContinue } from './shopperInfoNavigation';
import { areAllProductsSelected } from './utils/productUtils';
import {
	getShopperSegmentFromProducts,
	getSkidataSegmentFromShopperProducts,
} from './utils/segmentUtils';

const shopperDoesntHaveSegment = (shopper: Shopper) =>
	shopper.segment === undefined || shopper.segment === '' || shopper.segment === 'regular';

const ShopperProducts = () => {
	const { t, getTranslation, language } = useTranslation();
	const { pushRoute } = useRoutes();
	const classes = useStyles();
	const dispatch = useDispatch();
	const activeShopperIndex = useSelector(ViewSelectors.activeShopperIndex);
	const order = useSelector(ConfirmSelectors.orderData_FORCE);
	const stockProductsById = useSelector(StockSelectors.productsById);
	const { rentalInfo, products, shoppers } = order;

	const shopName = useSelector(NavSelectors.shopName);
	const locationName = useSelector(NavSelectors.locationName);

	const activeShopper = shoppers.find((s, i) => i === activeShopperIndex)!;
	const [error, setError] = useState('');
	const activeShopperProducts = products.filter((p) => activeShopper.productIds.includes(p.id));

	useEffect(() => {
		const allProductsSelected = areAllProductsSelected(products, shoppers, activeShopper);
		if (allProductsSelected) {
			pushRoute(Routes.confirmDetailsSummary);
		} else {
			if (products.length === 1 && !activeShopperProducts.length) {
				handleProductToggle(true, products[0]);
			}
		}
		//eslint-disable-next-line
	}, []);

	const handleContinue = useCallback(() => {
		productsContinue({
			shopName,
			locationName,
			products,
			shoppers,
			activeShopperIndex,
		});
	}, [activeShopperIndex, locationName, products, shopName, shoppers]);

	useEffect(() => {
		if (
			products.length === 1 &&
			activeShopperProducts.length === 1 &&
			activeShopper.productIds.includes(products[0].id)
		) {
			handleContinue();
		}
	}, [activeShopper, activeShopperProducts, handleContinue, products]);

	const handleProductToggle = (checked: boolean, product: OrderProduct) => {
		const checkedProducts: OrderProduct[] = [{ ...product, shopperId: activeShopper.id }];

		if (isLiftTicketProduct(product)) {
			const keycard = findProductKeycard(products, product.id);
			keycard && checkedProducts.push({ ...keycard, shopperId: activeShopper.id });
		}

		const updatedShopperProducts = checked
			? activeShopperProducts.concat(checkedProducts)
			: activeShopperProducts.filter((shopperProduct) =>
					checkedProducts.every((p) => p.id !== shopperProduct.id),
			  );

		const checkedProductIds = updatedShopperProducts.map((p) => p.id);

		const updatedRentalInfo = {
			...rentalInfo,
			shopperIdsWithProducts: {
				...rentalInfo.shopperIdsWithProducts,
				[activeShopper.id]: checkedProductIds,
			},
		};

		const updatedShopperWithProductIds = {
			...activeShopper,
			productIds: checkedProductIds,
		};

		const updatedProducts = products.map(
			(p) => checkedProducts.find((checkedProduct) => checkedProduct.id === p.id) ?? p,
		);

		const updatedShopperSegment = getShopperSegmentFromProducts(
			updatedShopperWithProductIds,
			updatedShopperProducts,
		);

		const updatedActiveShopper = !!updatedShopperSegment
			? {
					...updatedShopperWithProductIds,
					segment: updatedShopperSegment,
			  }
			: omit(updatedShopperWithProductIds, ['segment']);

		dispatch(updateOrderInfo(updatedRentalInfo));
		dispatch(updateProducts(updatedProducts));
		dispatch(updateShopper(updatedActiveShopper));

		const newError =
			updatedShopperProducts.filter((p) => isLiftTicketProduct(p) && !isKeycardProduct(p)).length >
			1
				? t(
						'shopperInfo.oneTicketPerPersonError',
						'We cannot assign more than one lift ticket to the same person.',
				  )
				: '';
		if (newError !== error) {
			setError(newError);
		}
	};

	const activeShopperFitsSegment = (product: OrderProduct) => {
		return (
			(activeShopper.segment !== undefined && activeShopper.segment === product.segment) ||
			product.segment === null ||
			product.segment === undefined ||
			shopperDoesntHaveSegment(activeShopper)
		);
	};

	const shopperAlreadyChoseProductsFromAnotherSegment = (
		shopper: Shopper,
		activeShopperProducts: OrderProduct[],
		product: OrderProduct,
	) => {
		const segmentFromChosenProducts = isLiftTicketProduct(product)
			? getSkidataSegmentFromShopperProducts(shopper, activeShopperProducts)
			: getShopperSegmentFromProducts(shopper, activeShopperProducts);
		const productSegment = isLiftTicketProduct(product)
			? product.externalSegment?.externalSegmentId
			: product.segment;
		return (
			product.segment !== null &&
			product.segment !== undefined &&
			segmentFromChosenProducts !== null &&
			segmentFromChosenProducts !== productSegment
		);
	};

	const getLiftTicketName = (ticket: OrderProduct) => {
		const ticketName = getTranslation(ticket.name);
		const keycard = findProductKeycard(products, ticket.id);
		const keycardName = keycard ? ` + ${getTranslation(keycard.name)}` : '';
		return `${ticketName} ${t(
			'tickets.liftTicket',
			'lift ticket',
		)}${keycardName} (${getSkidataSegmentTranslation(ticket.externalSegment, language)})`;
	};

	const renderProducts = () => {
		const productsWithoutKeycard = products.filter((p) => !isKeycardProduct(p));
		return productsWithoutKeycard.map((product, index) => {
			const shopperName = shoppers
				.filter((shopper) => shopper.productIds.find((pId) => pId === product.id))
				.map((shopper) => shopper.firstName);

			const productForChosenPerson = shopperName[0];

			const rentalDuration: Duration = {
				durationInSeconds: product.rentalDurationInSeconds || 0,
				durationType: product.durationType,
				durationName: product.durationName || null,
			};
			const rentalDurationString = isLiftTicketProduct(product)
				? '' // No durations rendered for lift tickets
				: `, ${getDurationString(rentalDuration, 'long', language, t)}`;
			const productNameWithVariant = isLiftTicketProduct(product)
				? getLiftTicketName(product)
				: getProductNameWithVariant(product, language);
			const stockProduct = stockProductsById[product.productApiId];
			const productNameWithSegment =
				stockProduct.segmentPricings && product.segment
					? `${productNameWithVariant} (${
							isLiftTicketProduct(product)
								? getSkidataSegmentTranslation(product.externalSegment, language)
								: getSegmentTranslation(product.segment, t)
					  })`
					: productNameWithVariant;
			const productNameWithDurationAndPerson = productForChosenPerson
				? `${productNameWithSegment}${rentalDurationString} (${productForChosenPerson})`
				: `${productNameWithSegment}${rentalDurationString}`;

			const anotherShopperHasProduct = shoppers
				.filter((s) => s.id !== activeShopper.id)
				.map((s) => s.productIds)
				.flat()
				.includes(product.id);

			const checkboxDisabled =
				!activeShopperFitsSegment(product) ||
				shopperAlreadyChoseProductsFromAnotherSegment(
					activeShopper,
					activeShopperProducts,
					product,
				) ||
				anotherShopperHasProduct;

			const checkboxChecked =
				activeShopperProducts.some((p) => p.id === product.id) || anotherShopperHasProduct;

			return (
				<div key={index} className={index % 2 === 0 ? classes.grayBackGround : undefined}>
					<FormControlLabel
						value=""
						control={
							<Checkbox
								disabled={checkboxDisabled}
								checked={checkboxChecked}
								color="primary"
								onChange={(e) => handleProductToggle(e.target.checked, product)}
							/>
						}
						label={productNameWithDurationAndPerson}
						labelPlacement="end"
						className={classes.paddingLeft}
					/>
				</div>
			);
		});
	};

	const goBack = () => {
		productsBack({ shopName, locationName, activeShopperIndex, shoppers, products });
	};

	const isContinueButtonDisabled = () =>
		activeShopperProducts.length === 0 ||
		activeShopperProducts.filter((p) => isLiftTicketProduct(p) && !isKeycardProduct(p)).length > 1;

	return activeShopper && products.length > 1 ? (
		<Container maxWidth="sm" className={classes.section}>
			<ShopperHeaderBar />
			<div>
				<div>
					<Typography className={classes.smallSpacingBottom} variant={'h5'}>
						{activeShopper.firstName + ','}{' '}
						{activeShopper.segment ? `(${getSegmentTranslation(activeShopper.segment, t)})` : ''}
					</Typography>
					<Typography className={classes.spacingBottom} variant={'h6'}>
						{t('shopperInfo.chooseEquipments', 'What is included for you?')}
					</Typography>
					{renderProducts()}
				</div>
				<Box mt={error ? 4 : 6.5} display="flex" justifyContent="center">
					<Typography color="error" variant="body2">
						{error}
					</Typography>
				</Box>
				<div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="primary"
						className={classNames(
							classes.btn,
							activeShopperIndex === 0 ? classes.fullWidthBtn : '',
						)}
						onClick={handleContinue}
						disabled={isContinueButtonDisabled()}
					>
						{t('common:actions.continue')}
					</Button>
					{activeShopperIndex > 0 && (
						<Button variant="outlined" color="primary" className={classes.btn} onClick={goBack}>
							{t('common:actions.back')}
						</Button>
					)}
				</div>
			</div>
		</Container>
	) : (
		<Container>
			<Spinner color="#000" />
		</Container>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		textAlignCenter: {
			textAlign: 'center',
		},
		section: {
			paddingBottom: theme.spacing(6),
		},
		continueBtn: {
			paddingTop: 12,
			paddingBottom: 12,
			height: '40px',
		},
		buttonContainer: {
			marginTop: theme.spacing(1),
			margin: 'auto',
			textAlign: 'center',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'row-reverse',
			},
		},
		btn: {
			margin: '8px auto',
			paddingTop: 12,
			paddingBottom: 12,
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '40%',
				margin: 0,
			},
		},
		fullWidthBtn: {
			width: '100%',
		},
		grayBackGround: {
			backgroundColor: theme.palette.background.secondary,
		},
		paddingLeft: {
			paddingLeft: theme.spacing(1),
			width: '100%',
		},
		info: {
			color: theme.palette.colors.tundra.main,
		},
		spacingBottom: {
			marginBottom: theme.spacing(4),
		},
		smallSpacingBottom: {
			marginBottom: theme.spacing(1),
		},
	}),
);

export default ShopperProducts;
