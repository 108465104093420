import { TFunction } from 'i18next';

export const admin = (t: TFunction) => ({
	paymentMethods: {
		APPLEPAY: t('common:payments.methods.APPLEPAY', 'ApplePay'),
		CARD_ONLINE: t('common:payments.methods.CARD_ONLINE', 'Card (online)'),
		CARD_ONLINE_STRIPE: t('common:payments.methods.CARD_ONLINE', 'Card (online)'),
		CARD_TERMINAL: t('common:payments.methods.CARD_TERMINAL', 'Card (terminal)'),
		CASH: t('common:payments.methods.CASH', 'Cash'),
		GOOGLEPAY: t('common:payments.methods.GOOGLEPAY', 'GooglePay'),
		INVOICE: t('common:payments.methods.INVOICE', 'Invoice'),
		MANUAL: t('common:payments.methods.MANUAL', 'Manual'),
		MOBILEPAY: t('common:payments.methods.MOBILEPAY', 'MobilePay'),
		PAYTRAIL: t('common:payments.methods.PAYTRAIL', 'Online bank'),
		POS: t('common:payments.methods.POS', 'POS'),
		TRUSTLY: t('common:payments.methods.TRUSTLY', 'Online bank'),
		VOUCHER: t('common:payments.methods.VOUCHER', 'Voucher'),
		PAY_STORE: t('admin:shop.paymentMethodDescription.PAY_STORE'),
	},
	paymentViewMethodSelection: {
		CARD_ONLINE: t('admin:shop.paymentMethodDescription.CARD_ONLINE', 'Credit or Debit card'),
		CARD_ONLINE_STRIPE: t(
			'admin:shop.paymentMethodDescription.CARD_ONLINE',
			'Credit or Debit card',
		),
		MOBILEPAY: t('admin:shop.paymentMethodDescription.MOBILEPAY', 'MobilePay'),
		PAYTRAIL: t(
			'admin:shop.paymentMethodDescription.PAYTRAIL',
			'Online bank payments (via Paytrail)',
		),

		PAY_STORE: t(
			'admin:shop.paymentMethodDescription.PAY_STORE',
			'Pay on pick-up (No pre-payment)',
		),
		TRUSTLY: t('admin:shop.paymentMethodDescription.TRUSTLY', 'Online bank payments (via Trustly)'),
	},
});
