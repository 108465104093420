import React, { useEffect } from 'react';

import { Box, CircularProgress, ListItemText, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import AmountPicker from 'common/components/pickers/AmountPicker';
import { getProductVariants } from 'common/modules/products/variants';
import { ProductApi } from 'common/types';
import { getVariantName } from 'common/utils/productUtils';
import { useTranslation } from 'services/localization';
import { ProductSelection } from 'services/types';

import ProductUnavailable from './ProductUnavailable';

export interface AmountSelectorProps {
	product: ProductApi;
	selection: ProductSelection | null;
	quantity: number | null;
	value: number;
	onChange: (value: number) => void;
	error?: string | null;
	loading?: boolean;
	outOfStock?: boolean;
	isDeliveryDisabled?: boolean;
}

const AmountSelector = React.memo((props: AmountSelectorProps) => {
	const classes = useStyles();
	const { t, getTranslation, language } = useTranslation();
	const {
		value,
		onChange,
		selection,
		product,
		quantity,
		error,
		loading,
		outOfStock,
		isDeliveryDisabled,
	} = props;

	// This ensures that if changing to a variant that has fewer quantities than selected quantity, amount gets correctly updated to match the value of the "maxValue" in the picker
	useEffect(() => {
		if (!!quantity && value > quantity) {
			onChange(quantity);
		}
	}, [value, onChange, quantity]);

	const getSecondaryText = () => {
		if (!selection) {
			return t('AmountSelector.completeSelection', 'Complete selection');
		}
		const variant = getProductVariants(product).find((v) => v.id === selection?.variantId);
		return getVariantName(variant, language);
	};

	if (!loading) {
		if (!!outOfStock || (!!selection && quantity === 0)) {
			return <ProductUnavailable reason="generic" />;
		}

		if (!!isDeliveryDisabled) {
			return <ProductUnavailable reason="no-delivery-support" />;
		}
	}

	return (
		<Box>
			<Box className={classes.wrapper}>
				<ListItemText
					primary={getTranslation(product.name)}
					primaryTypographyProps={{
						variant: 'body1',
					}}
					secondary={getSecondaryText()}
				></ListItemText>
				{loading ? (
					<Box width={120} height={40} display="flex" alignItems="center" justifyContent="center">
						<CircularProgress size={24} />
					</Box>
				) : (
					<AmountPicker
						disabled={!selection}
						value={value}
						onChange={onChange}
						minValue={1}
						maxValue={!selection ? 1 : quantity ?? 99}
					/>
				)}
			</Box>
			{error && (
				<Typography variant="body2" color="error">
					{error}
				</Typography>
			)}
		</Box>
	);
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
		},
	}),
);

export default AmountSelector;
