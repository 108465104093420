import React from 'react';

import { Trans as TransComponent, TransProps } from 'react-i18next';

import { CommonKey, OnlineKey } from 'common/locales/utils/TranslationKeys';

interface Props extends Omit<TransProps<any>, 'i18nKey'> {
	children?: any;
	i18nKey: OnlineKey | CommonKey;
}

export const Trans = ({ i18nKey, children, ...rest }: Props) => {
	return (
		/* i18next-extract-disable-next-line */
		<TransComponent i18nKey={i18nKey} {...rest}>
			{children}
		</TransComponent>
	);
};
