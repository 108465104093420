import React from 'react';

import { TFunction } from 'i18next';
import { RiLock2Line } from 'react-icons/ri';

import { OnlineMethods } from 'common/modules/payments/constants';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { AmountObject, CurrencyObject } from 'common/types';
import { getPricingString } from 'common/utils/common';
import { getCurrencyObjectFromCurrencyCode } from 'common/utils/currencyUtils';

import PaymentButton from '../PaymentButton';
import { PaymentMethodError } from '../types';

interface Props {
	method: ShopOnlinePaymentMethodObject;
	loading: boolean;
	t: TFunction;
	disabled: boolean;
	handleConfirm: (method: ShopOnlinePaymentMethodObject) => void;
	confirmError?: PaymentMethodError;
	amount: AmountObject;
	currencyObject?: CurrencyObject;
	customLabel?: string;
	noIcon?: boolean;
}

const PaymentButtonElement = ({
	method,
	loading,
	t,
	handleConfirm,
	disabled,
	confirmError,
	amount,
	currencyObject,
	customLabel,
	noIcon,
}: Props) => {
	const currencyObjectForPayment = getCurrencyObjectFromCurrencyCode(
		amount.currency,
		currencyObject,
	);
	const pricingString = getPricingString(amount.value, currencyObjectForPayment);
	const genericError = t('common:errors.somethingWentWrong');

	const getPaymentMethod = (method: ShopOnlinePaymentMethodObject) => {
		switch (method.id) {
			case OnlineMethods.PAY_STORE:
				return (
					<PaymentButton
						onClick={() => handleConfirm(method)}
						loading={loading}
						error={confirmError?.methodId === method.id ? genericError : undefined}
						label={customLabel ?? t('common:payment.confirm', 'Confirm')}
						disabled={disabled}
					/>
				);
			case 'MANUAL':
				return (
					<PaymentButton
						onClick={() => handleConfirm(method)}
						loading={loading}
						error={confirmError?.methodId === method.details.id ? genericError : undefined}
						label={customLabel ?? t('common:payment.confirm', 'Confirm')}
						disabled={disabled}
					/>
				);
			case OnlineMethods.EPASSI_ONLINE:
				return null;
			case OnlineMethods.CARD_ONLINE:
			case OnlineMethods.CARD_ONLINE_STRIPE:
				const PayIcon = noIcon ? undefined : <RiLock2Line />;
				const payLabel =
					amount.value > 0
						? `${t('common:payment.pay')} ${pricingString}`
						: t('common:payment.authorize', 'Authorize');
				return (
					<PaymentButton
						onClick={() => handleConfirm(method)}
						loading={false}
						label={customLabel ?? payLabel}
						Icon={PayIcon}
						disabled={disabled}
						error={confirmError?.methodId === method.id ? genericError : undefined}
					/>
				);
			default:
				return (
					<PaymentButton
						onClick={() => handleConfirm(method)}
						loading={false}
						label={customLabel ?? t('common:actions.continueToPay', 'Continue to payment')}
						disabled={disabled}
						error={confirmError?.methodId === method.id ? genericError : undefined}
					/>
				);
		}
	};
	return <>{getPaymentMethod(method)}</>;
};

export default PaymentButtonElement;
