import React from 'react';

import { Box, Divider, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { TFunction } from 'i18next';

import FlexBox from 'common/components/FlexBox';
import { useAdyen } from 'common/hooks/useAdyen';
import { WalletMethods } from 'common/modules/payments/constants';
import {
	getAdyenMethodTypes,
	getAdyenPaymentMethodType,
	getWalletPaymentMethods,
	isAdyenPaymentMethod,
	isMethodInLimits,
} from 'common/modules/payments/paymentMethods';
import { WalletPaymentMethod } from 'common/modules/payments/types';
import { AmountObject } from 'common/types';
import { isRunInIframe } from 'common/utils/browserUtils';
import { switchUnreachable } from 'common/utils/common';
import { getIsRentleHostname } from 'common/utils/urls';

import { PaymentMethodError } from '../types';
import ApplePay from './ApplePay';
import GooglePay from './GooglePay';

interface Props {
	t: TFunction;
	loading: boolean;
	paymentError?: PaymentMethodError;
	amount: AmountObject;
	country: string | undefined;
	merchantName: string;
	hasDeposit: boolean;
	handlePayment: (method: WalletPaymentMethod) => void;
	merchantId: string;
	hasSubscription: boolean;
}

const WalletPaymentMethods = (props: Props) => {
	const {
		amount,
		country,
		t,
		paymentError,
		merchantName,
		loading,
		hasDeposit,
		merchantId,
		handlePayment,
		hasSubscription,
	} = props;
	const classes = useStyles();
	const { adyenCheckout, paymentMethods } = useAdyen();
	const adyenMethodTypes = getAdyenMethodTypes(paymentMethods);
	if (!adyenCheckout) return null;
	const validWalletPaymentMethods = getWalletPaymentMethods().filter((method) => {
		const adyenPaymentMethod = isAdyenPaymentMethod(method);
		const adyenType = getAdyenPaymentMethodType(method);
		const methodInPaymentLimits = isMethodInLimits(method, {
			recurringRequired: false,
			country,
			currency: amount.currency,
			paymentIsInIframe: isRunInIframe(),
			hasDeposit,
			isCustomDomain: !getIsRentleHostname(window.location.hostname),
			hasSubscription,
		});
		return (
			!adyenPaymentMethod ||
			(adyenType && adyenMethodTypes?.includes(adyenType) && methodInPaymentLimits)
		);
	});

	const renderWalletPayment = (method: WalletPaymentMethod) => {
		switch (method) {
			case WalletMethods.APPLEPAY: {
				return (
					<ApplePay
						key={method}
						adyenCheckout={adyenCheckout}
						paymentError={
							paymentError?.methodId === WalletMethods.APPLEPAY ? paymentError.error : undefined
						}
						t={t}
						loading={loading}
						amount={amount}
						merchantName={merchantName}
						onSubmit={() => handlePayment(method)}
						merchantId={merchantId}
					/>
				);
			}
			case WalletMethods.GOOGLEPAY: {
				return (
					<GooglePay
						key={method}
						adyenCheckout={adyenCheckout}
						paymentError={
							paymentError?.methodId === WalletMethods.GOOGLEPAY ? paymentError.error : undefined
						}
						loading={loading}
						t={t}
						amount={amount}
						merchantName={merchantName}
						onSubmit={() => handlePayment(method)}
					/>
				);
			}
			default:
				return switchUnreachable(method);
		}
	};

	return !!validWalletPaymentMethods.length ? (
		<Box className={classes.walletWrapper}>
			{validWalletPaymentMethods.map((method, i) => (
				<Box key={method} className={classes.paymentMethodWrapper}>
					{renderWalletPayment(method)}
					{i === validWalletPaymentMethods.length - 1 && (
						<Box pt={1} pb={3} component="div">
							<TextDivider text={t('common:conjunctions.or', 'or')} />
						</Box>
					)}
				</Box>
			))}
		</Box>
	) : null;
};

const TextDivider = ({ text }: { text: string }) => {
	const classes = useStyles();
	return (
		<FlexBox margin="auto" justifyContent="center">
			<Divider className={classNames(classes.divider, classes.dividerLeft)} />
			<Box px={2}>{text}</Box>
			<Divider className={classNames(classes.divider, classes.dividerRight)} />
		</FlexBox>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		walletWrapper: {
			margin: 'auto',
			width: '100%',
		},
		divider: {
			width: 50,
		},
		dividerLeft: {
			background: `linear-gradient(to right, transparent, #d8d8d8)`,
		},
		dividerRight: {
			background: `linear-gradient(to left, transparent, #d8d8d8)`,
		},
		// Hide the --or-- element if payment method is hidden because it's unavailable
		paymentMethodWrapper: {
			'& > div:is([style*="display: none"]) + div': {
				display: 'none',
			},
		},
	}),
);

export default WalletPaymentMethods;
