import { Theme, ThemeOptions, alpha, createTheme } from '@mui/material/styles';

import * as Colors from './colors';
import * as Fonts from './fonts';
import { getOverrides } from './overrides';

//mui4 -> mui5 migration related module declaration, more info https://mui.com/guides/migration-v4/#mui-styles
declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		black: true;
		white: true;
		'white-text': true;
	}
}

const Base: Partial<ThemeOptions> = {
	breakpoints: {
		keys: ['xs', 'sm', 'md', 'lg', 'xl'],
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
	direction: 'ltr',
	mixins: {
		toolbar: {
			minHeight: 56,
			'@media (min-width:0px) and (orientation: landscape)': {
				minHeight: 48,
			},
			'@media (min-width:600px)': {
				minHeight: 64,
			},
		},
	},
	typography: {
		fontFamily: Fonts.inter,
		htmlFontSize: 10,
		allVariants: {
			fontWeight: 400,
		},
		h1: {
			fontWeight: 500,
		},
		h2: {
			fontWeight: 500,
		},
		h3: {
			fontWeight: 500,
		},
		h4: {
			fontWeight: 500,
		},
		h5: {
			fontWeight: 500,
		},
		h6: {
			fontWeight: 500,
		},
		button: {
			fontWeight: 700,
		},
		caption: {
			fontWeight: 500,
		},
		body2: {
			fontWeight: 400,
		},
	},
	shape: {
		borderRadius: 4,
	},
	spacing: 8,
};

/**
 * Base, white-labelable light theme (online, etc.)
 */
export const WhiteLabelLight = createTheme({
	...Base,
	typography: {
		...Base.typography,
		subtitle2: {
			fontWeight: 400,
		},
	},
	palette: Colors.paletteWhiteLabelLight,
	components: getOverrides({
		extras: {
			MuiFab: {
				styleOverrides: {
					root: {
						borderRadius: 0,
					},
					extended: {
						borderRadius: 0,
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 0,
					},
				},
			},
		},
	}),
});

/**
 * Base, white-labelable dark theme (online, consumer, etc.)
 */
export const WhiteLabelDark = createTheme({
	...Base,
	palette: Colors.paletteWhiteLabelDark,
	components: getOverrides({
		extras: {
			MuiButton: {
				styleOverrides: {
					containedSizeLarge: {
						borderRadius: '3px',
						minHeight: '51px',
						fontSize: '18px',
					},
				},
			},
			MuiLink: {
				styleOverrides: {
					button: {
						textTransform: 'uppercase',
						textDecoration: 'underline',
						fontSize: '12px',
					},
				},
			},
		},
	}),
});

/**
 * Rentle-branded light theme (admin, etc.)
 */
export const RentleLight = createTheme({
	...Base,
	palette: Colors.paletteRentleLight,
	components: getOverrides({
		extras: {
			MuiIconButton: {
				styleOverrides: {
					root: {
						color: Colors.pru.main,
					},
				},
			},
			MuiButton: {
				variants: [
					{
						props: { variant: 'black' },
						style: ({ theme }) => ({
							background: theme.palette.common.black,
							color: theme.palette.common.white,
							'&:hover': {
								background: theme.palette.primary.dark,
							},
							'&.Mui-disabled': {
								background: theme.palette.action.disabled,
								color: theme.palette.common.white,
							},
						}),
					},
					{
						props: { variant: 'white' },
						style: ({ theme }) => ({
							background: theme.palette.common.white,
							color: theme.palette.primary.dark,
							'&:hover': {
								background: alpha(theme.palette.common.white, 0.9),
							},
							'&.Mui-disabled': {
								background: alpha(theme.palette.common.white, 0.5),
							},
						}),
					},
					{
						props: { variant: 'white-text' },
						style: ({ theme }) => ({
							background: 'transparent',
							color: theme.palette.common.white,
							'&:hover': {
								background: alpha(theme.palette.common.white, 0.1),
							},
							'&.Mui-disabled': {
								color: alpha(theme.palette.common.white, 0.5),
							},
						}),
					},
				],
				styleOverrides: {
					root: {
						borderRadius: 50,
						fontWeight: 700,
					},
					contained: {
						'&.Mui-disabled': {
							color: '#fff',
						},
					},
					containedPrimary: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.primary.dark,
						},
					}),
					containedSecondary: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.secondary.dark,
						},
					}),
					containedSuccess: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.success.dark,
						},
					}),
					containedError: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.error.dark,
						},
					}),
					outlined: ({ theme }) => ({
						borderWidth: '2px !important',
						'&.Mui-disabled': {
							borderColor: theme.palette.action.disabled,
							color: theme.palette.action.disabled,
						},
					}),
					outlinedPrimary: ({ theme }) => ({
						borderColor: theme.palette.primary.main,
						'&:hover': {
							backgroundColor: theme.palette.primary.lightest,
							borderColor: theme.palette.primary.light,
						},
					}),
					outlinedSecondary: ({ theme }) => ({
						borderColor: theme.palette.secondary.main,
						'&:hover': {
							backgroundColor: theme.palette.secondary.lightest,
							borderColor: theme.palette.secondary.light,
						},
					}),
					outlinedError: ({ theme }) => ({
						borderColor: theme.palette.error.main,
						'&:hover': {
							backgroundColor: theme.palette.error.lightest,
							borderColor: theme.palette.error.light,
						},
					}),
					outlinedSuccess: ({ theme }) => ({
						borderColor: theme.palette.success.main,
						'&:hover': {
							backgroundColor: theme.palette.success.lightest,
							borderColor: theme.palette.success.light,
						},
					}),
					textPrimary: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.primary.lightest,
						},
					}),
					textSecondary: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.secondary.lightest,
						},
					}),
					textError: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.error.lightest,
						},
					}),
					textSuccess: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.success.lightest,
						},
					}),
				},
			},
			MuiButtonGroup: {
				styleOverrides: {
					root: {
						borderRadius: 50,
						fontWeight: 700,
					},
					contained: {
						'&.Mui-disabled': {
							color: '#fff',
						},
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: ({ theme }) => ({
						borderBottom: `1px solid ${theme.palette.border.table}`,
					}),
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: '0px 2px 6px rgba(163, 163, 163, 0.5)',
					},
					elevation0: {
						boxShadow: 'none',
					},
				},
			},
			MuiTable: {
				styleOverrides: {
					root: ({ theme }) => ({
						outline: `1px solid ${theme.palette.border.table}`,
						outlineOffset: '-1px',
						overflow: 'hidden',
						borderRadius: '4px',
					}),
				},
			},
			MuiTableHead: {
				styleOverrides: {
					root: ({ theme }) => ({
						background: theme.palette.background.secondary,
					}),
				},
			},
			MuiLink: {
				styleOverrides: {
					button: {
						textTransform: 'uppercase',
						textDecoration: 'underline',
						fontSize: '12px',
					},
				},
			},
			MuiMenu: {
				styleOverrides: {
					list: {
						minWidth: 200,
						maxWidth: '100%',
					},
				},
				defaultProps: {
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'right',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
				},
			},
		},
	}),
});

/**
 * Rentle-branded dark theme (consumer app etc.)
 */

export const RentleDark = createTheme({
	...Base,
	palette: Colors.paletteRentleDark,
	components: getOverrides({
		extras: {
			MuiIconButton: {
				styleOverrides: {
					root: {
						color: Colors.pru.main,
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 50,
						minHeight: 40,
						fontWeight: 700,
					},
					contained: {
						'&.Mui-disabled': {
							color: '#fff',
						},
					},
					containedPrimary: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.primary.dark,
						},
					}),
					containedSecondary: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.secondary.dark,
						},
					}),
					containedSuccess: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.success.dark,
						},
					}),
					containedError: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.error.dark,
						},
					}),
					outlined: ({ theme }) => ({
						borderWidth: '2px !important',
						'&.Mui-disabled': {
							borderColor: theme.palette.action.disabled,
							color: theme.palette.action.disabled,
						},
					}),
					outlinedPrimary: ({ theme }) => ({
						borderColor: theme.palette.primary.main,
						'&:hover': {
							backgroundColor: theme.palette.primary.lightest,
							borderColor: theme.palette.primary.light,
						},
					}),
					outlinedSecondary: ({ theme }) => ({
						borderColor: theme.palette.secondary.main,
						'&:hover': {
							backgroundColor: theme.palette.secondary.lightest,
							borderColor: theme.palette.secondary.light,
						},
					}),
					outlinedError: ({ theme }) => ({
						borderColor: theme.palette.error.main,
						'&:hover': {
							backgroundColor: theme.palette.error.lightest,
							borderColor: theme.palette.error.light,
						},
					}),
					outlinedSuccess: ({ theme }) => ({
						borderColor: theme.palette.success.main,
						'&:hover': {
							backgroundColor: theme.palette.success.lightest,
							borderColor: theme.palette.success.light,
						},
					}),
					textPrimary: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.primary.lightest,
						},
					}),
					textSecondary: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.secondary.lightest,
						},
					}),
					textError: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.error.lightest,
						},
					}),
					textSuccess: ({ theme }) => ({
						'&:hover': {
							backgroundColor: theme.palette.success.lightest,
						},
					}),
				},
			},
			MuiButtonGroup: {
				styleOverrides: {
					root: {
						borderRadius: 50,
						minHeight: 40,
						fontWeight: 700,
					},
					contained: {
						'&.Mui-disabled': {
							color: '#fff',
						},
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: ({ theme }) => ({
						borderBottom: `1px solid ${theme.palette.border.table}`,
					}),
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: '0px 2px 6px rgba(163, 163, 163, 0.5)',
					},
					elevation0: {
						boxShadow: 'none',
					},
				},
			},
			MuiTable: {
				styleOverrides: {
					root: ({ theme }) => ({
						outline: `1px solid ${theme.palette.border.table}`,
						outlineOffset: '-1px',
						overflow: 'hidden',
						borderRadius: '4px',
					}),
				},
			},
			MuiTableHead: {
				styleOverrides: {
					root: ({ theme }) => ({
						background: theme.palette.background.secondary,
					}),
				},
			},
		},
	}),
});
