import React from 'react';

import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import FooterContent from 'common/components/customizer/FooterContent';
import { isRunInIframe } from 'common/utils/browserUtils';
import { getAddressStringFromLocation, notNullish } from 'common/utils/common';
import { getShopLocationById } from 'common/utils/shopUtils';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';

export interface Props {
	fullShopInfo?: {
		show: true;
		locationId: string;
	};
}
const Footer = (props: Props) => {
	const { fullShopInfo } = props;

	const { t } = useTranslation();
	const { Routes } = useRoutes();
	const shopInfo = useSelector(ShopSelectors.shopPublicInfoData);
	const footerInfo = useSelector(ShopSelectors.footerInfo);
	const lang = useSelector(ViewSelectors.language);
	const isLiteShop = useSelector(ShopSelectors.isLiteShop);

	const isEmbedded = isRunInIframe();

	const embeddedCookiesDisabled = isEmbedded && !navigator.cookieEnabled;
	const showCookieConsent = isEmbedded
		? shopInfo?.cookieSettings?.embed === 'SHOW_NOTICE'
		: shopInfo?.cookieSettings?.default === 'SHOW_NOTICE';
	const showManageCookies = showCookieConsent && !embeddedCookiesDisabled;

	const activePaymentMethods = shopInfo?.activePaymentMethods;

	const getShopInfoString = ({
		withDetails,
		locationId,
	}: {
		withDetails: boolean;
		locationId?: string;
	}): string | null => {
		if (!shopInfo) return null;
		const { name, businessId } = shopInfo;
		if (!withDetails) return name;
		const startLocation = locationId
			? getShopLocationById(locationId, shopInfo, 'ONLINE')
			: undefined;
		const nameString = `${name}${businessId ? ' (' + businessId + ')' : ''}`;
		const addressString = startLocation ? getAddressStringFromLocation(startLocation) : null;
		const shopInfoString = [nameString, addressString].filter(notNullish).join(', ');
		return shopInfoString;
	};

	return (
		<>
			<FooterContent
				footerInfo={footerInfo}
				routes={{
					home: Routes.baseRoot,
					about: Routes.about,
					stores: Routes.locations,
				}}
				paymentMethods={activePaymentMethods}
				shopInfoString={getShopInfoString({
					withDetails: !!fullShopInfo,
					locationId: fullShopInfo?.locationId,
				})}
				t={t}
				language={lang}
				showManageCookies={showManageCookies}
				isLiteShop={isLiteShop}
			/>
		</>
	);
};

export default Footer;
