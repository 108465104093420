import { createReducer } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import * as ShopActions from 'redux/actions/shop';
import * as StockActions from 'redux/actions/stock';
import { LoadingData } from 'redux/types';

import { Category, ProductApi } from 'common/types';

type StockState = {
	products: LoadingData<ProductApi[], string>;
	categories: LoadingData<Category[], string>;
};

const INITIAL_STATE: StockState = {
	products: {
		data: null,
		error: null,
		loading: false,
	},
	categories: {
		data: null,
		error: null,
		loading: false,
	},
};

const StockReducer = createReducer(INITIAL_STATE, (builder) => {
	builder
		.addCase(StockActions.updateStockProductsListener.pending, (state, action) => {
			state.products.loading = true;
			state.products.error = null;
		})
		.addCase(StockActions.setStockProducts, (state, action) => {
			state.products.data = action.payload;
			state.products.loading = false;
		})
		.addCase(StockActions.setStockProductsError, (state, action) => {
			state.products.error = action.payload;
			state.products.loading = false;
		})
		.addCase(StockActions.updateTicketProducts, (state, action) => {
			const ticketsById = keyBy(action.payload, 'id');
			state.products.data = !!state.products.data
				? state.products.data.map((p) => ticketsById[p.id] ?? p)
				: null;
		});

	builder
		.addCase(StockActions.updateStockCategoriesListener.pending, (state, action) => {
			state.categories.loading = true;
			state.categories.error = null;
		})
		.addCase(StockActions.setStockCategories, (state, action) => {
			state.categories.data = action.payload;
			state.categories.loading = false;
		})
		.addCase(StockActions.setStockCategoriesError, (state, action) => {
			state.categories.error = action.payload;
			state.categories.loading = false;
		});

	/**
	 * If the shop changes, reset stock state
	 */
	builder.addCase(ShopActions.updateShopListeners.pending, () => {
		return INITIAL_STATE;
	});
});

export default StockReducer;
