import React from 'react';

import { TextField, TextFieldProps } from '@mui/material';

import { useTranslation } from 'services/localization';

interface Props extends Omit<TextFieldProps, 'value' | 'onChange' | 'error'> {
	value: string;
	onChange: (newValue: string) => void;
	error?: string;
}

const OrganizationName = (props: Props) => {
	const { t } = useTranslation();
	return (
		<TextField
			label={t('decathlon.orgName', 'Name of organization')}
			variant="outlined"
			required
			value={props.value}
			onChange={(e) => props.onChange(e.target.value)}
			fullWidth
			error={!!props.error}
			helperText={props.error ?? undefined}
		/>
	);
};

export default OrganizationName;
