import { stringEnum } from 'common/utils/objects';

import { RoutePaths as _RoutePaths } from '../../shared-server/routes';

export const RoutePaths = _RoutePaths;

export type Route = keyof typeof RoutePaths;

export const Routes = stringEnum(Object.keys(RoutePaths) as Route[]);

export type BaseParams = {
	shopName?: string;
	locationName?: string | null;
};

export type RouteParams = {
	productId?: string;
	categoryId?: string;
};

export type QueryParams = {
	category?: string;
	orderId?: string;
	ref?: 'recommended';
	productType?: string;
};
