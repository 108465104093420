import React from 'react';

import { Box, Divider, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';

import { getAddressStringFromLocation } from 'common/utils/common';
import { getShopLocationById } from 'common/utils/shopUtils';

import LocationName from './LocationName';

interface Props {
	shopLocationId: string;
	showShopLogo?: boolean;
	alignContent?: 'center' | 'left';
	showBusinessId?: boolean;
	showLocationName?: boolean;
	textSize?: 'caption' | 'body2';
}

const ShopInfo = (props: Props) => {
	const {
		shopLocationId,
		showShopLogo = true,
		showBusinessId = false,
		showLocationName = false,
		textSize = 'caption',
	} = props;
	const classes = useStyles(props);
	const shopInfo = useSelector(ShopSelectors.shopPublicInfoData);

	if (!shopInfo) {
		return null;
	}

	const { name, logoImgUrl, businessId } = shopInfo;
	const startLocation = getShopLocationById(shopLocationId, shopInfo, 'ONLINE');
	const locationEmail = startLocation?.email;
	const locationPhoneNumber = startLocation?.phone;
	return (
		<Box className={classes.shopInfoWrapper}>
			{showShopLogo && logoImgUrl && (
				<div className={classes.imgContainer}>
					<img className={classes.shopImg} src={logoImgUrl} alt={'logo'} />
				</div>
			)}
			<Box flex="1">
				<Typography variant="body1" className={classes.shopName}>
					{name}
				</Typography>
				{showLocationName && (
					<>
						<LocationName
							textVariant={textSize}
							showCity
							showIcon
							justify="flex-start"
							classes={{
								locationName: textSize === 'caption' ? classes.captionFont : undefined,
							}}
						/>
						<Box my={1}>
							<Divider />
						</Box>
					</>
				)}
				{startLocation && (
					<Typography
						component="div"
						className={textSize === 'caption' ? classes.captionFont : undefined}
						variant={textSize}
					>
						{getAddressStringFromLocation(startLocation)}
					</Typography>
				)}
				{!startLocation?.hideEmail && locationEmail && (
					<Typography
						component="div"
						className={textSize === 'caption' ? classes.captionFont : undefined}
						variant={textSize}
					>
						{locationEmail}
					</Typography>
				)}
				{!startLocation?.hidePhone && locationPhoneNumber && (
					<Typography
						component="div"
						className={textSize === 'caption' ? classes.captionFont : undefined}
						variant={textSize}
					>
						{locationPhoneNumber}
					</Typography>
				)}
				{businessId && showBusinessId && (
					<Typography
						component="div"
						className={textSize === 'caption' ? classes.captionFont : undefined}
						variant={textSize}
					>
						{businessId}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme) =>
	createStyles({
		shopInfoWrapper: {
			padding: theme.spacing(2, 0),
			display: 'flex',
			justifyContent: ({ alignContent }: Props) =>
				alignContent === 'center' ? 'center' : 'inherit',
			textAlign: ({ alignContent }: Props) => (alignContent === 'center' ? 'center' : 'inherit'),
		},
		imgContainer: {
			width: 69,
			height: 69,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginRight: theme.spacing(2),
			padding: theme.spacing(0.5),
		},
		shopImg: {
			maxWidth: '100%',
			maxHeight: '100%',
		},
		shopName: {
			fontWeight: 500,
			marginBottom: theme.spacing(0.5),
		},
		captionFont: {
			fontWeight: 400,
		},
	}),
);

export default ShopInfo;
