import React, { useCallback } from 'react';

import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { ReactComponent as RentleCheckBoxCheckedIcon } from 'common/assets/icons/form-checkbox-checked.svg';
import { ReactComponent as RentleCheckBoxUnCheckedIcon } from 'common/assets/icons/form-checkbox-unchecked.svg';

type StringOrElement = string | JSX.Element | (string | JSX.Element)[];
type Color = 'primary' | 'secondary';
type Size = 'normal' | 'small';

/**
 * CheckboxItem - a generic checkbox component which can be supplied with a label, description and extra content.
 *
 * @param checkboxColor: The color of the checkbox (primary or secondary).
 * @param checked: Whether the checkbox is checked or not
 * @param description: An optional description under the label
 * @param error: An error text to show under the description
 * @param extra: An optional extra element, such as an InfoButton, to show on the right side of the component
 * @param label: The label of the checkbox, i.e. the question you want to ask
 * @param onChange: Called with the raw ChangeEvent when the checkbox is clicked
 * @param onCheckedChange: Called with the new boolean value when the checkbox is clicked
 * @param value: The value prop passed directly to the inner material-ui Checkbox
 * @param noPadding: Removes the default padding from around the component
 */

interface Props {
	checkboxColor?: Color;
	checkboxSize?: Size;
	checked: boolean;
	description?: StringOrElement;
	error?: StringOrElement;
	extra?: JSX.Element;
	label: StringOrElement;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onCheckedChange?: (checked: boolean) => void;
	value?: string;
	noPadding?: boolean;
	id?: string;
	backgroundColor?: 'secondary' | 'none';
	disabled?: boolean;
}

const CheckboxItem = (props: Props) => {
	const classes = useStyles(props);
	const {
		value,
		checked,
		onChange,
		onCheckedChange,
		label,
		description,
		error,
		extra,
		checkboxSize = 'normal',
		id,
	} = props;

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (onChange) {
				onChange(e);
			}
			if (onCheckedChange) {
				onCheckedChange(e.target.checked);
			}
		},
		[onChange, onCheckedChange],
	);

	return (
		<FormGroup className={classes.wrapper} id={id}>
			<FormControlLabel
				classes={{
					root: classes.formControlLabel,
				}}
				disabled={props.disabled}
				control={
					<Checkbox
						checked={checked}
						onChange={handleChange}
						className={classes.checkbox}
						value={value}
						sx={{ color: '#000' }}
						size="small"
						icon={<RentleCheckBoxUnCheckedIcon className={classes.checkboxIcon} />}
						checkedIcon={<RentleCheckBoxCheckedIcon className={classes.checkboxIcon} />}
					/>
				}
				label={
					<Box pt={'10px'} pb={1}>
						<Typography
							variant={checkboxSize === 'small' ? 'body2' : 'body1'}
							className={classes.checkboxLabel}
						>
							{label}
						</Typography>
						{!!description && (
							<Typography variant="caption" className={classes.formControlLabelDescription}>
								{description}
							</Typography>
						)}
						{!!error && (
							<Typography color="error" variant="caption">
								{error}
							</Typography>
						)}
					</Box>
				}
			/>
			<Box mt={0.5} mr={-0.5}>
				{extra}
			</Box>
		</FormGroup>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			padding: (props: Props) => (props.noPadding ? 0 : theme.spacing(1, 2, 1, 2)),
			flexDirection: 'row',
			flexWrap: 'nowrap',
			backgroundColor: (props: Props) =>
				props.backgroundColor === 'secondary' ? theme.palette.background.secondary : 'none',
			borderRadius: 4,
		},
		checkbox: {
			marginRight: theme.spacing(1),
		},
		checkboxLabel: {
			lineHeight: 'auto',
		},
		checkboxIcon: {
			width: ({ checkboxSize = 'normal' }: Props) => (checkboxSize === 'small' ? 20 : 28),
			height: ({ checkboxSize = 'normal' }: Props) => (checkboxSize === 'small' ? 20 : 28),
		},
		formControlLabel: {
			alignItems: 'flex-start',
			flex: 1,
			marginLeft: theme.spacing(-1),
		},
		formControlLabelDescription: {
			fontSize: '1.4rem',
			color: theme.palette.colors.tundra.light,
		},
	}),
);

export default CheckboxItem;
