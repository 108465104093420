import { combineReducers } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import analytics from './analytics';
import auth from './auth';
import cart from './cart';
import checkout from './checkout';
import confirm from './confirm';
import nav from './nav';
import shop from './shop';
import stock from './stock';
import view, { ViewState } from './view';

const persistSelectedDeliveryOption: PersistConfig<ViewState> = {
	key: 'view',
	storage,
	whitelist: ['selectedDeliveryOption', 'availablePickupSlots'],
};

const rootReducer = combineReducers({
	shop,
	stock,
	view: persistReducer(persistSelectedDeliveryOption, view),
	cart,
	checkout,
	nav,
	confirm,
	analytics,
	auth,
});

export default rootReducer;
