import React from 'react';

import { Box, Collapse, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import TimeChipSelector from 'common/components/TimeChipSelector';
import { formatTime } from 'common/modules/atoms/times';
import { getOpeningTimesForDate } from 'common/modules/openingHours';
import { ISOString } from 'common/types';
import useShopFormat from 'hooks/useShopFormat';
import { useTranslation } from 'services/localization';
import { AvailabilityByStartTime } from 'utils/availability';
import { getStartTimesWithValidityForSelectedDate } from 'utils/startTimes';

interface Props {
	date: string | null;
	productId: string;
	onSelect: (value: ISOString) => void;
	selectedQuantity: number;
	availabilityByStartTime: AvailabilityByStartTime | null;
	selectedDayString: string | null;
}

const StartTimeSelector = (props: Props) => {
	const classes = useStyles();
	const { localFormat, shopDateFormat } = useShopFormat();
	const { t } = useTranslation();
	const {
		onSelect,
		date,
		productId,
		selectedQuantity,
		availabilityByStartTime,
		selectedDayString,
	} = props;

	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const productsById = useSelector(StockSelectors.productsById);
	const product = productsById[productId];

	const startTimesWithValidity = getStartTimesWithValidityForSelectedDate(
		availabilityByStartTime,
		selectedQuantity,
		selectedDayString,
	);

	const renderOpeningTimes = () => {
		if (!date) return null;
		const openingTimes = getOpeningTimesForDate({
			openingHours,
			date: moment(date).format('YYYY-MM-DD'),
		});

		if (openingTimes.isClosed) return null;
		const openTime = formatTime(openingTimes.openTime, shopDateFormat);
		const closeTime = formatTime(openingTimes.closeTime, shopDateFormat);

		return (
			<Typography variant="caption" color="textSecondary">
				{t('common:times.openingHoursFromTo', {
					openTime,
					closeTime,
					defaultValue: 'Opening hours {{openTime}} - {{closeTime}}',
				})}
			</Typography>
		);
	};

	if (!product) return null;

	return (
		<Collapse in={!!date}>
			<Box pt={2}>
				<Box className={classes.openingHours}>{renderOpeningTimes()}</Box>
				<TimeChipSelector
					value={date}
					onChange={(value) => onSelect(value)}
					options={startTimesWithValidity.map((item) => {
						const label = localFormat(moment(item.value), 'HH:mm');
						return {
							label,
							value: item.value,
							disabled: item.disabled,
						};
					})}
					chipsMaxWidth={450}
					pageSize={12}
				/>
			</Box>
		</Collapse>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		openingHours: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			textAlign: 'center',
		},
		startTimes: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			flexWrap: 'wrap',
			flex: 1,
			margin: theme.spacing(3, 0),
			maxWidth: 450,
		},
		startTime: {
			padding: theme.spacing(0.5, 1),
			borderRadius: 16,
			border: '1px solid #e5e7e7',
			minWidth: 60,
			margin: theme.spacing(0.5),
			'&:hover': {
				borderColor: theme.palette.common.black,
			},
		},
		startTimeSelected: {
			background: theme.palette.common.black,
			color: theme.palette.common.white,
			borderColor: theme.palette.common.black,
		},
		startTimeDisabled: {
			background: '#e5e7e7',
			color: '#716a80',
			pointerEvents: 'none',
		},
		smallText: {
			fontSize: '1rem',
			padding: theme.spacing(0.5),
		},
	}),
);

export default StartTimeSelector;
