import React from 'react';

import { Box, Radio, Typography } from '@mui/material';
import { TFunction } from 'i18next';

import { LocaleHelpers } from 'common/locales/helpers';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { getTranslation } from 'common/modules/translations';
import { Languages } from 'common/types';

import PaymentMethodIcons from '../PaymentMethodIcons';

export interface PaymentMethodHeaderProps {
	paymentMethod: ShopOnlinePaymentMethodObject;
	isActive: boolean;
	t: TFunction;
	lang: Languages;
}

const PaymentMethodHeader = (props: PaymentMethodHeaderProps) => {
	const { paymentMethod, isActive, t, lang } = props;

	const getPaymentMethodLabel = (method: ShopOnlinePaymentMethodObject) => {
		if (method.id === 'MANUAL') {
			return getTranslation(method.details?.label ?? { def: '' }, lang);
		} else {
			return LocaleHelpers.common(t).paymentMethods[paymentMethod.id];
		}
	};

	return (
		<Box display="flex" alignItems="center">
			<Radio
				checked={isActive}
				size="medium"
				value={paymentMethod.id}
				name={paymentMethod.id}
				sx={{
					color: '#000',
					padding: (theme) => theme.spacing(1),
					marginLeft: (theme) => theme.spacing(-1),
					margin: (theme) => theme.spacing(0, 1),
				}}
			/>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					flex: 1,
				}}
			>
				<Typography variant="body1">{getPaymentMethodLabel(paymentMethod)}</Typography>
				<PaymentMethodIcons paymentMethod={paymentMethod} />
			</Box>
		</Box>
	);
};

export default PaymentMethodHeader;
