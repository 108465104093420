import React, { useEffect, useState } from 'react';

import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import { AvailabilityRange } from 'common/api/frontend/inventory_new/availabilityRange';
import BookingCalendar from 'common/components/calendars/BookingCalendar';
import Banner from 'common/components/states/Banner';
import { getNextOpenDay } from 'common/modules/openingHours';
import {
	isRentalPurchaseType,
	isSalesPurchaseType,
	isSubscriptionPurchaseType,
} from 'common/modules/orders';
import {
	getSubscriptionOptionDuration,
	hasAutoRenewProductApiSubscription,
} from 'common/modules/subscriptions';
import {
	ByVariant,
	Duration,
	ISOString,
	LoadingData,
	ProductApi,
	PurchaseType,
	StartTimeCount,
} from 'common/types';
import { momentToDateInShopTimezone } from 'common/utils/dateUtils';
import ErrorText from 'components/ErrorText';
import { ProductForm } from 'hooks/useProductForm';
import useShopFormat from 'hooks/useShopFormat';
import useStartTimesOrTimeslots from 'hooks/useStartTimesOrTimeslots';
import { Trans, useTranslation } from 'services/localization';
import { MAX_CALENDAR_RANGE, getEarliestAvailableDateForDayString } from 'utils/startTimes';

import {
	getDeliverySlotForNewDate,
	getEarliestFixedCustomStartDate,
	getStartTimeForNewDate,
} from '../utils';
import BookingDatesSummary from './BookingDatesSummary';
import DeliverySlotSelector from './DeliverySlotSelector';
import StartTimeSelector from './StartTimeSelector';

interface Props {
	product: ProductApi;
	availabilitiesLoading: boolean;
	form: ProductForm;
	setInitialProductSelectionIsAvailable: (isIt: boolean) => void;
	availability: LoadingData<ByVariant<AvailabilityRange[]>>;
	setProductsVariantIds: string[] | null | undefined;
	startTimeCounts: LoadingData<ByVariant<StartTimeCount>>;
	purchaseType: PurchaseType;
}

const StartDateTimeSelector = React.memo((props: Props) => {
	const { t, language } = useTranslation();
	const { localFormat } = useShopFormat();
	const classes = useStyles();

	const {
		product,
		availabilitiesLoading,
		form,
		setInitialProductSelectionIsAvailable,
		availability,
		setProductsVariantIds,
		startTimeCounts,
		purchaseType,
	} = props;

	const { startDate: selectedDate, quantity: selectedQuantity, deliveryTimeslot } = form.values;
	const { setStartDate, setDeliveryTimeslot } = form.actions;
	const {
		selectedDuration,
		selectedSubscription,
		forcedStartDateAndTime,
		forcedStartDate,
	} = form.meta;
	const showError = !!form.errors.startDate;
	const { durationInSeconds, durationType } = ((): Duration => {
		switch (purchaseType) {
			case 'rental': {
				return {
					durationInSeconds: selectedDuration?.option.durationInSeconds ?? 0,
					durationType:
						selectedDuration?.option.timePeriod === 'days_within_opening_hours'
							? 'opening_hours'
							: '24h',
					durationName: null,
				};
			}
			case 'subscription': {
				return !!selectedSubscription && !!selectedDate
					? getSubscriptionOptionDuration(selectedSubscription.option, selectedDate)
					: {
							durationInSeconds: 0,
							durationType: '24h',
							durationName: null,
					  };
			}
			case 'sales': {
				return {
					durationInSeconds: 0,
					durationType: '24h',
					durationName: null,
				};
			}
		}
	})();

	const startDateFromCart = forcedStartDateAndTime ?? forcedStartDate ?? null;

	const hasDelivery = useSelector(ViewSelectors.deliverySelected);
	const shopStartDay = useSelector(ShopSelectors.shopStartDay);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const selectedDayString = !!forcedStartDate
		? moment(forcedStartDate).format('YYYY-MM-DD')
		: !!selectedDate
		? moment(selectedDate).format('YYYY-MM-DD')
		: null;
	const [selectedMonth, setSelectedMonth] = useState(() => {
		return (
			form.meta.forcedStartDateAndTime ??
			getEarliestFixedCustomStartDate(product) ??
			getNextOpenDay({ openingHours, limit: moment().add(6, 'months').format('YYYY-MM-DD') }) ??
			moment().toISOString()
		);
	});
	const forcedStartDayOnly = !!forcedStartDate && !forcedStartDateAndTime;
	const hasAutoRenew = hasAutoRenewProductApiSubscription(product);

	const {
		availabilityByStartTime,
		isStartTimesOrTimeslotsLoaded,
		availabilityByTimeslot,
		lowDates,
		unavailableDates,
		disabledDates,
		isStartDateFromCartAvailable,
		startDateAvailable,
		calendarDisabled,
	} = useStartTimesOrTimeslots({
		startTimeCounts,
		availability,
		product,
		setProductsVariantIds,
		form,
		selectedMonth,
		purchaseType,
	});

	const availabilityByStartTimeOrTimeslot = availabilityByStartTime ?? availabilityByTimeslot;

	useEffect(() => {
		setInitialProductSelectionIsAvailable(isStartDateFromCartAvailable);
	}, [isStartDateFromCartAvailable, setInitialProductSelectionIsAvailable]);

	// If we have a forced start date (but not start time), select the first possible start time for that date
	useEffect(() => {
		if (!!selectedDate || !!forcedStartDateAndTime || !startDateFromCart) return;
		if (forcedStartDayOnly) {
			if (!!availabilityByStartTimeOrTimeslot && !!selectedDayString) {
				const earliestStartTime = getEarliestAvailableDateForDayString(
					availabilityByStartTimeOrTimeslot,
					selectedQuantity,
				)(selectedDayString);
				setStartDate(earliestStartTime ?? null);
			}
		} else {
			setStartDate(startDateFromCart);
		}
	}, [
		availabilityByStartTimeOrTimeslot,
		forcedStartDayOnly,
		setStartDate,
		selectedDayString,
		selectedQuantity,
		selectedDate,
		startDateFromCart,
		forcedStartDateAndTime,
	]);

	useEffect(() => {
		if (!!forcedStartDateAndTime) return;
		if (!!selectedDate && !startDateAvailable) {
			setStartDate(null);
		}
	}, [setStartDate, selectedDate, startDateAvailable, forcedStartDateAndTime]);

	const handleDateSelect = (date: ISOString) => {
		if (hasDelivery) {
			const newDeliverySlot = getDeliverySlotForNewDate({
				newDate: date,
				currentDeliverySlot: deliveryTimeslot,
				availabilityByTimeslot,
				selectedQuantity,
			});

			if (!!newDeliverySlot) {
				setDeliveryTimeslot(newDeliverySlot);
			}
		} else {
			const newStartTime = getStartTimeForNewDate({
				newDate: date,
				currentStartTime: selectedDate,
				availabilityByStartTime,
				selectedQuantity,
			});

			if (!!newStartTime) {
				setStartDate(newStartTime);
			}
		}
	};

	return (
		<Box mt={4} className={classes.wrapper}>
			{!!forcedStartDateAndTime || (isSalesPurchaseType(purchaseType) && !!forcedStartDate) ? (
				<>
					{!calendarDisabled && startDateAvailable && (
						<BookingDatesSummary
							startDate={forcedStartDateAndTime ?? forcedStartDate!}
							durationType={durationType}
							durationInSeconds={durationInSeconds}
							showOnlyStartDate={isSubscriptionPurchaseType(purchaseType) && hasAutoRenew}
						/>
					)}
					{!startDateAvailable && !availabilitiesLoading && isRentalPurchaseType(purchaseType) && (
						<Box mt={3}>
							<Banner
								variant="info"
								title={t(
									'StartDateTimeSelector.selectionNotAvailable',
									'This selection is not available on the chosen dates',
								)}
								description={t(
									'StartDateTimeSelector.selectionNotAvailableDescription',
									'If you wish to see the availability of this product with a different start date & time, please make a separate booking.',
								)}
							/>
						</Box>
					)}
				</>
			) : (
				<>
					{forcedStartDayOnly ? (
						<Typography variant="body2">
							<Trans
								i18nKey="common:booking.startDateFromCart"
								defaults="Selected date <start>{{startDate}}</start>"
								values={{ startDate: localFormat(moment(forcedStartDate), 'dddd DD.MM.') }}
								components={{ start: <strong /> }}
							/>
						</Typography>
					) : (
						<Box mb={3}>
							{isSalesPurchaseType(purchaseType) && (
								<Typography variant="body1" className={classes.chooseDeliveryTime}>
									{t('delivery.selectDeliveryDate', 'Choose delivery date')}
								</Typography>
							)}
							{showError && !selectedDate && (
								<Box display="flex" flexDirection="column" alignItems="center">
									<ErrorText align="center">
										{t('common:prompts.pleaseSelectStartDate', 'Please select a start date')}
									</ErrorText>
								</Box>
							)}
							<BookingCalendar
								startDate={selectedDate ? momentToDateInShopTimezone(moment(selectedDate)) : null}
								durationInSeconds={durationInSeconds}
								onStartDateChange={(date) => handleDateSelect(date.toISOString())}
								calendarDate={momentToDateInShopTimezone(moment(selectedMonth))}
								onCalendarDateChange={(date) => setSelectedMonth(date.toISOString())}
								disabled={calendarDisabled}
								maxDate={momentToDateInShopTimezone(moment().add(...MAX_CALENDAR_RANGE))}
								minDate={momentToDateInShopTimezone(moment().startOf('day'))}
								weekStartDay={shopStartDay}
								lowDates={lowDates}
								unavailableDates={unavailableDates}
								disabledDates={disabledDates}
								loading={!isStartTimesOrTimeslotsLoaded}
								locale={language}
								hideRangeSelection={isSubscriptionPurchaseType(purchaseType)}
							/>
						</Box>
					)}

					{showError && !!selectedDate && (
						<Box display="flex" flexDirection="column" alignItems="center">
							<ErrorText align="center">
								{t('common:prompts.pleaseSelectStartTime', 'Please select a start time')}
							</ErrorText>
						</Box>
					)}
					{!isStartDateFromCartAvailable && isStartTimesOrTimeslotsLoaded ? (
						<Banner
							variant="info"
							title={t(
								'StartDateTimeSelector.productNotAvailableForDuration',
								'This product is not available for the chosen selection',
							)}
						/>
					) : !!hasDelivery ? (
						<>
							<DeliverySlotSelector
								date={selectedDate ?? null}
								productId={product.id}
								value={form.values.deliveryTimeslot}
								onChange={setDeliveryTimeslot}
								selectedQuantity={selectedQuantity}
								availabilityByTimeslot={availabilityByTimeslot}
								selectedDayString={selectedDayString}
							/>
						</>
					) : (
						<StartTimeSelector
							date={selectedDate ?? null}
							productId={product.id}
							onSelect={setStartDate}
							selectedQuantity={selectedQuantity}
							availabilityByStartTime={availabilityByStartTime}
							selectedDayString={selectedDayString}
						/>
					)}
					{!!selectedDate && !isSalesPurchaseType(purchaseType) && (
						<BookingDatesSummary
							startDate={selectedDate ?? null}
							durationInSeconds={durationInSeconds}
							durationType={durationType}
							showPrompt={!!selectedDayString}
							showOnlyStartDate={isSubscriptionPurchaseType(purchaseType) && hasAutoRenew}
						/>
					)}
				</>
			)}
		</Box>
	);
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			'&:empty': {
				display: 'none',
			},
		},
		chooseDeliveryTime: {
			fontWeight: 500,
		},
	}),
);

export default StartDateTimeSelector;
