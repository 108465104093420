import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ReduxDispatch, ReduxState } from 'redux/types';

import { getBookedOnlineOrderObject } from 'common/api/db/functions';
import { ShopperIdsWithDTAImage } from 'common/modules/skidata';
import { OrderInfo, OrderObject, OrderProduct, Shopper } from 'common/types';

export const fetchOrder = createAsyncThunk<
	null | OrderObject,
	{ orderId: string; shopId: string },
	{
		dispatch: ReduxDispatch;
		state: ReduxState;
	}
>('Confirm/FETCH_ORDER', ({ orderId, shopId }, thunkAPI) => {
	return getBookedOnlineOrderObject({ orderId, shopId });
});

export const setOrder = createAction<OrderObject | null>('Confirm/SET_ORDER');

export const addShopper = createAction<Shopper>('Confirm/ADD_SHOPPER');

type ShopperId = string;
export const removeShopper = createAction<ShopperId>('Confirm/REMOVE_SHOPPER');

export const updateShopper = createAction<Shopper>('Confirm/UPDATE_SHOPPER');

export const setShoppers = createAction<Shopper[]>('Confirm/SET_SHOPPERS');

export const addProduct = createAction<OrderProduct>('Confirm/ADD_PRODUCT');

type ProductId = string;
export const removeProduct = createAction<ProductId>('Confirm/REMOVE_PRODUCT');

export const updateProduct = createAction<OrderProduct>('Confirm/UPDATE_PRODUCT');

export const updateProducts = createAction<OrderProduct[]>('Confirm/UPDATE_PRODUCTS');

export const updateOrderInfo = createAction<Partial<OrderInfo>>('Confirm/UPDATE_ORDER_INFO');

export const updateShopperIdsWithDTAImage = createAction<ShopperIdsWithDTAImage>(
	'Checkout/UPDATE_SHOPPER_IDS_WITH_DTA_IMAGE',
);
