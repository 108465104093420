import React from 'react';

import { TFunction } from 'i18next';

import InfoBlock from 'common/components/InfoBlock';
import { isAutomaticDepositPaymentMethod } from 'common/modules/payments/paymentMethods';
import { DepositPaymentMethod } from 'common/modules/payments/types';
import { AmountObject } from 'common/types';
import { getPricingString } from 'common/utils/common';

interface Props {
	method: DepositPaymentMethod;
	amount: AmountObject;
	depositReservationType: 'NOW' | 'LATER';
	t: TFunction;
}

const DepositInfoBlock = ({ method, amount, depositReservationType, t }: Props) => {
	const isAutomaticDeposit = isAutomaticDepositPaymentMethod(method);
	if (isAutomaticDeposit) {
		return (
			<InfoBlock
				content={`${t('common:deposit.depositInfoPayment', {
					deposit: getPricingString(amount.value, amount.currency),
					time:
						depositReservationType === 'LATER'
							? t('common:deposit.24hBeforeRental', '24 hours before the rental starts')
							: t('common:deposit.duringPayment', 'during payment'),
					defaultValue:
						'To secure the rental equipment, a security deposit ({{deposit}}) will automatically be reserved as a cover reservation from your payment card latest {{time}}, and released after the rental has ended.',
				})}`}
			/>
		);
	}
	return (
		<InfoBlock
			content={`${t('common:deposit.depositInfoReservation', {
				pricingString: getPricingString(amount.value, amount.currency),
				defaultValue:
					'To secure the rental equipment, we will also reserve a security deposit ({{pricingString}}) for the duration of the rental. It will be released after the rental has ended.',
			})}`}
		/>
	);
};

export default DepositInfoBlock;
