import React, { useEffect } from 'react';

import { Box, Grid, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import CheckboxItem from 'common/components/CheckboxItem';
import CustomCheckbox from 'common/components/customizer/CustomCheckbox';
import CustomTextfield from 'common/components/customizer/CustomTextfield';
import { Callable } from 'common/frontend/callable';
import countryCodes from 'common/services/countryCodes.json';
import { PhoneObject } from 'common/types';
import useCheckoutForm from 'hooks/useCheckoutForm';
import useExternalAuth from 'hooks/useExternalAuth';
import { useTranslation } from 'services/localization';

import PhoneNumberInput from '../../components/PhoneNumberInput';
import DecathlonRegistration from './DecathlonRegistration';

interface Props {
	autoFocus?: boolean;
}

const Details = (props: Props) => {
	const { t } = useTranslation();
	const customMarketingConsent = useSelector(ShopSelectors.shopMarketingConsent);
	const customCheckout = useSelector(ShopSelectors.customCheckout);
	const language = useSelector(ViewSelectors.language);

	const { values, actions, errors } = useCheckoutForm();
	const { autoFocus = true } = props;
	const { isNewDecathlonRegistration } = useExternalAuth();

	useEffect(() => {
		const getuseCountryCode = async () => {
			const { country } = await Callable.users.getUserInfoFromIP();

			const defaultCountryCodeByIp = countryCodes.find(({ code }) => code === country);

			const phoneObjectByIp: PhoneObject = {
				countryCode: defaultCountryCodeByIp?.code ?? values.phone.countryCode,
				areaCode: defaultCountryCodeByIp?.dial_code ?? values.phone.areaCode,
				numberWithoutCode: values.phone.numberWithoutCode,
			};
			actions.setPhone(phoneObjectByIp);
		};
		getuseCountryCode();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // we only want to get the country code once on mount

	return (
		<Box>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<TextField
						id="firstName"
						label={t('common:form.firstName')}
						variant="outlined"
						required
						autoFocus={autoFocus}
						value={values.firstName}
						onChange={(e) => actions.setFirstName(e.target.value as string)}
						fullWidth
						error={!!errors.firstName}
						helperText={errors.firstName ?? undefined}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						id="lastName"
						label={t('common:form.lastName')}
						variant="outlined"
						required
						value={values.lastName}
						onChange={(e) => actions.setLastName(e.target.value as string)}
						fullWidth
						error={!!errors.lastName}
						helperText={errors.lastName ?? undefined}
					/>
				</Grid>
				{isNewDecathlonRegistration && (
					<Grid item xs={12}>
						<DecathlonRegistration.DateOfBirth
							value={values.dateOfBirth}
							onChange={actions.setDateOfBirth}
							error={errors.dateOfBirth ?? undefined}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<PhoneNumberInput
						value={values.phone}
						onChange={actions.setPhone}
						error={errors.phone ?? undefined}
						variant="outlined"
						id="phone"
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label={t('common:form.email')}
						variant="outlined"
						required
						value={values.email}
						onChange={(e) => actions.setEmail(e.target.value as string)}
						fullWidth
						error={!!errors.email}
						helperText={errors.email ?? undefined}
						id="email"
						inputMode="email"
						type="email"
					/>
				</Grid>
				{isNewDecathlonRegistration && (
					<Grid item xs={12} mb={3}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<DecathlonRegistration.BusinessUser
									value={!!values.businessAccount}
									onChange={actions.setBusinessAccount}
								/>
							</Grid>
							{!!values.businessAccount && (
								<>
									<Grid item xs={12} sm={6}>
										<DecathlonRegistration.OrganizationType
											value={values.businessAccount.organisationType ?? null}
											onChange={actions.setOrganisationType}
											error={errors.businessAccount?.organisationType ?? undefined}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										<DecathlonRegistration.OrganizationName
											value={values.businessAccount.organisationName}
											onChange={actions.setOrganisationName}
											error={errors.businessAccount?.organisationName ?? undefined}
										/>
									</Grid>
								</>
							)}
						</Grid>
					</Grid>
				)}
				{!!customCheckout && (
					<>
						<Grid item xs={12}>
							<Stack spacing={1}>
								<CustomTextfield
									checkout={customCheckout}
									onChange={(args) => actions.setCustomTextfield([args])}
									errors={errors.customTextfield}
									language={language}
								/>
								<CustomCheckbox
									values={values.customCheckbox}
									checkout={customCheckout}
									onChange={(args) => actions.setCustomCheckbox([args])}
									language={language}
									errors={errors.customCheckbox}
								/>
							</Stack>
						</Grid>
					</>
				)}
			</Grid>
			<Box mt={2}>
				<CheckboxItem
					value="marketing"
					checked={values.marketing}
					onCheckedChange={actions.setMarketing}
					label={
						customMarketingConsent ??
						t(
							'responsible.marketingConsent',
							'You may send me additional information and benefits related to my booking.',
						)
					}
					checkboxSize="small"
					noPadding
				/>
			</Box>
		</Box>
	);
};

export default Details;
