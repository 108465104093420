import React from 'react';

import {
	Box,
	Collapse,
	Fade,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import Sticky from 'hocs/Sticky';
import { RiEqualizerLine } from 'react-icons/ri';
import * as ViewActions from 'redux/actions/view';
import { useDispatch, useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import { isRunInIframe } from 'common/utils/browserUtils';
import CategoryNav from 'components/CategoryNav';
import CenteredContent from 'components/CenteredContent';
import DeliveryBar from 'components/NavBar/DeliveryBar';
import { useTranslation } from 'services/localization';

const Header = React.memo(() => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
	const isEmbedded = isRunInIframe();
	const { t, getTranslation } = useTranslation();

	const isDeliveryBarVisible = useSelector(ShopSelectors.isDeliveryBarVisible);
	const filteredProductsCount = useSelector(StockSelectors.productsFilteredCount);
	const filteredCategory = useSelector(StockSelectors.filteredCategory);
	const homePageLayout = useSelector(ShopSelectors.onlineLayout)?.defaultLayout;
	const isCategoryLayout = homePageLayout === 'categories';

	const BrowseNavBar = ({
		isStuck,
		collapsed,
		showBreadcrumbs = false,
	}: {
		isStuck: boolean;
		collapsed: boolean;
		showBreadcrumbs: boolean;
	}) => (
		<>
			<CenteredContent
				className={classNames(classes.wrapper, { [classes.wrapperCollapsed]: collapsed })}
			>
				<Box
					className={classNames(classes.content, {
						[classes.biggerPadding]: (!isMobile || !isStuck) && isCategoryLayout,
					})}
				>
					<Box>
						{showBreadcrumbs && (
							<Collapse appear={false} in={!isMobile || (isMobile && !collapsed)}>
								<Box mb={0.8}>
									<CategoryNav />
								</Box>
							</Collapse>
						)}
						<Box mr={4}>
							<Typography variant="h5" className={classes.title}>
								{!!filteredCategory
									? getTranslation(filteredCategory.name)
									: t('common:products.allProducts', 'All products')}{' '}
								({filteredProductsCount})
							</Typography>
						</Box>
					</Box>
					{!isEmbedded && (
						<Box>
							<Fade appear={false} in={isMobile || collapsed}>
								<IconButton
									onClick={() => dispatch(ViewActions.setFiltersOpen(true))}
									edge="end"
									size="large"
								>
									<RiEqualizerLine size={32} color="black" />
								</IconButton>
							</Fade>
						</Box>
					)}
				</Box>
			</CenteredContent>
		</>
	);

	return (
		<>
			{isDeliveryBarVisible && (
				<>
					{!isEmbedded ? (
						<Sticky>
							{({ isScrolledDown }) => <DeliveryBar isScrolledDown={isScrolledDown} />}
						</Sticky>
					) : (
						<DeliveryBar />
					)}
				</>
			)}
			<BrowseNavBar collapsed={false} isStuck={false} showBreadcrumbs={isCategoryLayout} />
		</>
	);
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			borderBottom: '2px solid transparent',
		},
		wrapperCollapsed: {
			borderBottomColor: '#f2f2f2',
		},
		content: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			transition: 'padding 0.2s ease',
			transitionDelay: '0.2s',
			background: '#ffffff',
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		biggerPadding: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
		title: {
			transition: 'font-size 0.2s',
			[theme.breakpoints.down('lg')]: {
				fontSize: '2rem',
			},
		},
	}),
);

export default Header;
