import type { TFunction } from 'i18next';
import moment from 'moment-timezone';

import { getTranslation } from 'common/modules/translations';

import {
	Category,
	CategoryWithTerms,
	Languages,
	OrderInfo,
	OrderObject,
	OrderProduct,
	Shopper,
	TermsDocument,
} from '../types';
import { notUndefined } from './common';

export const getNotExpiredRentals = (rentals: OrderInfo[]) => {
	return rentals.filter((rental) => {
		const dateToFilter = rental.startDate
			? moment(rental.startDate)
			: rental.created
			? moment(rental.created)
			: moment();
		return dateToFilter.isSameOrAfter(moment(), 'day');
	});
};

export const getCategoriesWithTerms = (
	categories: Category[],
	shopTerms: TermsDocument | null,
	lang: string,
	t: TFunction,
	useCategoryTermsOverwrite: boolean,
): CategoryWithTerms[] => {
	const shopGeneralTerms: CategoryWithTerms | undefined = !!shopTerms
		? {
				name: !!t ? t('common:nouns.general', 'General') : lang === 'fi' ? 'Yleiset' : 'General',
				id: '',
				terms: shopTerms,
		  }
		: undefined;
	const someCategoryUsesGeneralTerms = categories.some((c) => !c?.terms);
	const addGeneralTerms = !useCategoryTermsOverwrite || someCategoryUsesGeneralTerms;
	const categoriesWithTerms: CategoryWithTerms[] = categories
		.map((category) => {
			return !category?.terms
				? undefined
				: {
						name: getTranslation(category.name, lang ? (lang as Languages) : 'en'),
						id: category.id,
						terms: category.terms,
				  };
		})
		.filter((category, index, self) => {
			if (!category?.terms) {
				return false;
			}
			return (
				index ===
				self.findIndex((c) => {
					return c?.terms ? c.terms.name === category.terms!.name : false;
				})
			);
		}) as CategoryWithTerms[];
	if (!categoriesWithTerms.length && !!shopGeneralTerms) {
		return [shopGeneralTerms];
	}
	return [addGeneralTerms ? shopGeneralTerms : undefined, ...categoriesWithTerms].filter(
		notUndefined,
	);
};
export const getCategoriesWithTermsFromShoppers = (
	shoppers: Shopper[],
	categories: Category[],
	shopTerms: TermsDocument | null,
	lang: string,
	t: TFunction,
	useCategoryTermsOverwrite: boolean,
): CategoryWithTerms[] => {
	const uniqueCategoryIds = [
		...new Set(
			([] as string[]).concat.apply(
				[],
				shoppers.map((s) => s.categoryIds || ['']),
			),
		),
	];

	const uniqueCategories = uniqueCategoryIds
		.map((id) => categories.find((c) => c.id === id))
		.filter((c): c is Category => !!c);

	return getCategoriesWithTerms(uniqueCategories, shopTerms, lang, t, useCategoryTermsOverwrite);
};

const hasMaxLiability = (orderProduct: OrderProduct): boolean => {
	if (
		orderProduct.terms &&
		orderProduct.terms.lostOrDamaged &&
		orderProduct.terms.lostOrDamaged.maxCompensation
	) {
		return true;
	}
	return false;
};

const hasLateHourFees = (orderProduct: OrderProduct): boolean => {
	if (orderProduct.terms && orderProduct.terms.lateReturn && orderProduct.terms.lateReturn.hour) {
		return true;
	}
	return false;
};

const hasLateDayFees = (orderProduct: OrderProduct): boolean => {
	if (orderProduct.terms && orderProduct.terms.lateReturn && orderProduct.terms.lateReturn.day) {
		return true;
	}
	return false;
};

export const rentalHasExtraConditions = (rental: OrderObject): boolean => {
	return !rental.products.every(
		(product) => !hasMaxLiability(product) && !hasLateHourFees(product) && !hasLateDayFees(product),
	);
};

export const productHasExtraConditions = (product: OrderProduct): boolean => {
	return hasMaxLiability(product) || hasLateHourFees(product) || hasLateDayFees(product);
};
