import React from 'react';

import { IconButton } from '@mui/material';
import { RiArrowLeftLine, RiMenuLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import * as ViewActions from 'redux/actions/view';
import { useDispatch } from 'redux/hooks';

interface Props {
	edge?: 'start' | 'end';
	backTo?: string;
}

const NavAction = (props: Props) => {
	const dispatch = useDispatch();

	if (props.backTo) {
		return (
			<Link to={props.backTo}>
				<IconButton edge={props.edge} size="large">
					<RiArrowLeftLine size={32} color="black" />
				</IconButton>
			</Link>
		);
	}

	return (
		<IconButton
			onClick={() => dispatch(ViewActions.setSidebarOpen(true))}
			edge={props.edge}
			size="large"
		>
			<RiMenuLine size={32} color="black" />
		</IconButton>
	);
};

export default NavAction;
