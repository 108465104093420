import { maxBy, reduce } from 'lodash';
import moment from 'moment-timezone';

import { AvailabilityRange } from 'common/api/frontend/inventory_new/availabilityRange';
import { MAX_BUFFER_TIME_MINUTES, getBufferTimeAsMinutes } from 'common/modules/atoms/bufferTimes';
import {
	deliveryOptionRequiresPickup,
	getDeliveryTimeslotsForDate,
} from 'common/modules/delivery/utils';
import { OpeningHours, isStoreClosedForDay } from 'common/modules/openingHours';
import {
	isRentalProduct,
	isRentalPurchaseType,
	isSubscriptionProduct,
} from 'common/modules/orders';
import { getStockProductMaintenanceMinutes } from 'common/modules/products/utils';
import {
	CartDelivery,
	DeliveryOption,
	DeliveryOptionHandlingTimes,
	DeliveryTimeSlot,
	ISOString,
	OrderInfo,
	OrderObject,
	OrderProduct,
	ProductApi,
} from 'common/types';
import { getNewEndTime } from 'common/utils/dateCalculations';
import { DurationOptionWithId } from 'hooks/useDurationOptions';
import { InvalidStartTimeReason, getAvailableUnitsForRange } from 'utils/availability';

export interface TimeslotsWithValidity extends DeliveryTimeSlot {
	value: string;
	disabled: boolean;
	units: number | null;
	valid: boolean;
	reason: InvalidStartTimeReason | null;
}

export interface AvailabilityByTimeslot {
	[date: string]: {
		[time: ISOString]: TimeslotsWithValidity;
	};
}

export const getTimeslotsWithValidityForDate = (
	availabilityByTimeslot: AvailabilityByTimeslot | null,
	selectedQuantity: number,
	dayString: string | null,
): TimeslotsWithValidity[] => {
	if (!dayString) return [];

	const availabilityForSelectedDate = availabilityByTimeslot?.[dayString] ?? {};

	return Object.entries(availabilityForSelectedDate).map(([datetime, timeslotWithAvailability]) => {
		const { units, valid } = timeslotWithAvailability;
		const disabled = !units || units < selectedQuantity || !valid;
		return {
			...timeslotWithAvailability,
			value: datetime,
			disabled,
			valid,
		};
	});
};

export const getUnloadedTimeslotsRange = (args: {
	calendarMonth: ISOString;
	isMobile: boolean;
	deliveryOption: DeliveryOption;
	timezone: string;
}) => {
	const { calendarMonth, isMobile, deliveryOption, timezone } = args;
	const _rangeStart = moment(calendarMonth).startOf('month');
	const rangeStart = moment().isAfter(_rangeStart) ? moment() : _rangeStart;
	const rangeEnd = moment(calendarMonth)
		.add(isMobile ? 1 : 2, 'month')
		.endOf('month');

	const timeslots = getTimeslotsForDateRange({
		startDate: rangeStart,
		endDate: rangeEnd,
		deliveryOption,
		timezone,
	});

	return timeslots;
};

const getTimeslotsForDateRange = (args: {
	startDate: moment.Moment;
	endDate: moment.Moment;
	deliveryOption: DeliveryOption;
	timezone: string;
}): { [date: string]: DeliveryTimeSlot[] } => {
	const { startDate, endDate, deliveryOption, timezone } = args;
	const rangeStart = moment(startDate);
	const rangeEnd = moment(endDate);

	const timeslotsByDate: { [date: string]: DeliveryTimeSlot[] } = {};

	while (rangeStart.isBefore(rangeEnd)) {
		const timeslots = getDeliveryTimeslotsForDate({
			date: rangeStart.toISOString(),
			deliveryOption,
			timezone,
		});
		if (timeslots?.length) {
			timeslotsByDate[rangeStart.format('YYYY-MM-DD')] = timeslots;
		} else {
			timeslotsByDate[rangeStart.format('YYYY-MM-DD')] = [];
		}
		rangeStart.add(1, 'day');
	}
	return timeslotsByDate;
};

export const getDeliveryTimeslotsWithAvailabilities = (args: {
	timeslotRanges: { [date: string]: DeliveryTimeSlot[] };
	availabilityRanges: AvailabilityRange[];
	maintenanceTime: number;
	selectedDuration: DurationOptionWithId | null;
	openingHours: OpeningHours;
	selectedDeliveryOption: DeliveryOption;
	timezone: string;
}) => {
	const {
		timeslotRanges,
		availabilityRanges,
		maintenanceTime,
		selectedDuration,
		openingHours,
		selectedDeliveryOption,
		timezone,
	} = args;
	const timeslotsWithAvailabilities = reduce(
		timeslotRanges,
		(result, timeslots, date) => {
			result[date] = assignDeliveryTimeslotAvailabilities({
				dateTimeslots: timeslots,
				availabilityRanges,
				maintenanceTime,
				selectedDuration,
				openingHours,
				timezone,
				handlingTimes: selectedDeliveryOption.handlingTimes,
				deliveryOption: selectedDeliveryOption,
			});
			return result;
		},
		{},
	);

	return timeslotsWithAvailabilities;
};

const assignDeliveryTimeslotAvailabilities = (args: {
	dateTimeslots: DeliveryTimeSlot[];
	availabilityRanges: AvailabilityRange[];
	maintenanceTime: number;
	selectedDuration: DurationOptionWithId | null;
	openingHours: OpeningHours;
	timezone: string;
	handlingTimes: DeliveryOptionHandlingTimes | undefined;
	deliveryOption: DeliveryOption;
}) => {
	const {
		dateTimeslots,
		availabilityRanges,
		maintenanceTime,
		selectedDuration,
		handlingTimes,
		openingHours,
		deliveryOption,
		timezone,
	} = args;
	const now = moment();
	const timeslotAvailabilities = dateTimeslots.reduce((result, timeslot, idx) => {
		const handlingTimeBefore = getBufferTimeAsMinutes({
			value: handlingTimes?.handlingTimeBefore,
			from: timeslot.startDate,
			direction: 'before',
			openingHours,
			timezone,
		});
		const startTime = moment(timeslot.startDate).subtract(handlingTimeBefore ?? 0, 'minutes');

		const endTime = moment(
			getNewEndTime(
				timeslot.endDate,
				{
					durationInSeconds: selectedDuration?.option.durationInSeconds || 1, //Fixed product rentals might not have duration,
					durationType:
						selectedDuration?.option.timePeriod === 'days_within_opening_hours'
							? 'opening_hours'
							: '24h',
					durationName: null,
				},
				openingHours,
				timezone,
			),
		);

		if (!deliveryOption.openingHoursBehaviour?.allowStartOnClosedDays) {
			if (isStoreClosedForDay({ openingHours, date: moment(startTime).format('YYYY-MM-DD') })) {
				return result;
			}
		}

		if (!deliveryOption.openingHoursBehaviour?.allowEndOnClosedDays) {
			if (isStoreClosedForDay({ openingHours, date: moment(endTime).format('YYYY-MM-DD') })) {
				return result;
			}
		}

		//For delivery only store & and same day bookings the last timeslot needs to be "reserved" for pickup
		const isPickupRequired = deliveryOptionRequiresPickup(deliveryOption);
		const isSameDayBooking = moment(timeslot.startDate).isSame(endTime, 'day');
		const isLastTimeSlotOfDate = dateTimeslots.length - 1 === idx;

		if (isSameDayBooking && isPickupRequired && isLastTimeSlotOfDate) {
			return result;
		}

		const startTimeInThePast = startTime.isBefore(now);
		const bufferTimeMinutes = getBufferTimeAsMinutes({
			value: handlingTimes?.preparationTime,
			from: timeslot.startDate,
			direction: 'before',
			openingHours,
			timezone,
		});

		const bufferTimeExceeded = moment(timeslot.startDate)
			.subtract(bufferTimeMinutes, 'minutes')
			.isBefore(now);

		if (bufferTimeExceeded || startTimeInThePast) {
			result[timeslot.endDate] = {
				...timeslot,
				units: 0,
				disabled: true,
				valid: false,
			};
			return result;
		}

		const handlingTimeAfter = getBufferTimeAsMinutes({
			value: handlingTimes?.handlingTimeAfter,
			from: endTime.toISOString(),
			direction: 'after',
			openingHours,
			timezone,
		});

		if (handlingTimeAfter >= MAX_BUFFER_TIME_MINUTES) {
			result[timeslot.endDate] = {
				...timeslot,
				units: 0,
				disabled: true,
				valid: false,
			};
			return result;
		}

		const endTimeWithInventoryBlockers = moment(endTime)
			.add(handlingTimeAfter ?? 0, 'minutes')
			.add(maintenanceTime, 'minutes');

		const units = getAvailableUnitsForRange({
			startTime,
			endTime: endTimeWithInventoryBlockers,
			availabilityRanges,
		});

		const disabled = units === 0;

		result[timeslot.endDate] = {
			...timeslot,
			units,
			disabled,
			valid: !disabled,
		};

		return result;
	}, {});

	return timeslotAvailabilities;
};

export const isProductInitiallyAvailableTimeslot = ({
	forcedStartDate,
	forcedStartDateAndTime,
	availabilityByTimeslot,
	selectedQuantity,
}: {
	forcedStartDate: ISOString | null;
	forcedStartDateAndTime: ISOString | null;
	availabilityByTimeslot: AvailabilityByTimeslot | null;
	selectedQuantity: number;
}) => {
	if (!availabilityByTimeslot) return true;
	if (!someTimeslotsAvailable(availabilityByTimeslot, selectedQuantity)) return false;

	const isStartDateFromCartAvailable = !!forcedStartDateAndTime
		? isTimeslotAvailableForDateTime(
				forcedStartDateAndTime,
				availabilityByTimeslot,
				selectedQuantity,
		  )
		: !!forcedStartDate
		? areAllTimeslotsForDateAvailable(forcedStartDate, availabilityByTimeslot, selectedQuantity)
		: true;

	return isStartDateFromCartAvailable;
};

const someTimeslotsAvailable = (
	availabilityByTimeslot: AvailabilityByTimeslot,
	selectedQuantity: number,
) => {
	if (!Object.keys(availabilityByTimeslot).length) return false;
	for (const date of Object.keys(availabilityByTimeslot)) {
		const availabilities = Object.values(availabilityByTimeslot[date]);
		const hasValidTimeslots = availabilities.some(
			({ units }) => units && units >= selectedQuantity,
		);
		if (hasValidTimeslots) {
			return true;
		}
	}
	return false;
};

const areAllTimeslotsForDateAvailable = (
	_date: ISOString,
	availabilityByTimeslot: AvailabilityByTimeslot,
	selectedQuantity: number,
) => {
	if (!availabilityByTimeslot) return true;
	const date = moment(_date).format('YYYY-MM-DD');
	const availabilities = availabilityByTimeslot[date]
		? Object.values(availabilityByTimeslot[date])
		: undefined;
	if (!availabilities?.length) {
		return false;
	}
	return availabilities.every(({ units }) => units && units >= selectedQuantity);
};

export const isTimeslotAvailableForDateTime = (
	datetime: ISOString,
	availabilityByTimeslot: AvailabilityByTimeslot | null,
	selectedQuantity: number,
) => {
	if (!availabilityByTimeslot) return true;
	const date = moment(datetime).format('YYYY-MM-DD');
	const timeslotWithValidity = availabilityByTimeslot[date]?.[datetime];
	const availableUnits = timeslotWithValidity?.units ?? 0;

	return (
		!!timeslotWithValidity && !timeslotWithValidity.disabled && availableUnits >= selectedQuantity
	);
};

export const isDeliveryTimeslotAvailable = (
	timeslotEnd: ISOString,
	availabilityByTimeslot: AvailabilityByTimeslot | null,
	selectedQuantity: number,
): boolean => {
	if (!availabilityByTimeslot) return true;
	const date = moment(timeslotEnd).format('YYYY-MM-DD');
	const availability = availabilityByTimeslot[date]?.[timeslotEnd];

	return availability?.valid && (availability.units ?? 0) >= selectedQuantity;
};

export const getDeliveryTimeslotsWithAvailability = (args: {
	product: ProductApi;
	availability: AvailabilityRange[];
	selectedDuration: DurationOptionWithId | null;
	month: ISOString;
	selectedDeliveryOption: DeliveryOption;
	stockProducts: ProductApi[];
	isMobile: boolean;
	openingHours: OpeningHours;
	timezone: string;
}) => {
	const {
		product,
		availability,
		selectedDuration,
		month,
		selectedDeliveryOption,
		stockProducts,
		isMobile,
		openingHours,
		timezone,
	} = args;

	const maintenanceTime = getStockProductMaintenanceMinutes(product, stockProducts);
	const timeslotRanges = getUnloadedTimeslotsRange({
		calendarMonth: month,
		isMobile,
		deliveryOption: selectedDeliveryOption,
		timezone,
	});

	const availabilityByTimeslot: AvailabilityByTimeslot | null = getDeliveryTimeslotsWithAvailabilities(
		{
			timeslotRanges,
			availabilityRanges: availability,
			maintenanceTime,
			selectedDuration,
			openingHours,
			timezone,
			selectedDeliveryOption,
		},
	);

	return availabilityByTimeslot;
};

export const hasDeliveryStartTime = (cartDelivery?: CartDelivery) =>
	cartDelivery?.to?.timeslot?.startDate;

export const getOrderDeliveryOption = (
	shopDeliveryOptions: DeliveryOption[] | null,
	order: OrderObject,
) => {
	return shopDeliveryOptions?.find((o) => o.id === order.orderDelivery?.deliveryOptionId) ?? null;
};

export const getLatestPossiblePickupslot = (availablePickupSlots: DeliveryTimeSlot[]) =>
	maxBy(availablePickupSlots, (slot) => slot.endDate);

export const productSupportsPickup = (product: OrderProduct) =>
	isRentalProduct(product) || isSubscriptionProduct(product);

export const orderSupportsPickup = (order: OrderInfo) =>
	order.purchaseTypes.some(isRentalPurchaseType);
