import React, { useMemo } from 'react';

import { Box } from '@mui/material';

import Select, { SelectOption } from 'common/components/pickers/Select';
import { ISOString, ProductApi } from 'common/types';
import ErrorText from 'components/ErrorText';
import { useTranslation } from 'services/localization/useTranslation';

export interface TicketTypeSelectorProps {
	value: string | null;
	onChange: (value: string | null) => void;
	startDate: ISOString | null;
	ticketTypeOptions: ProductApi[];
	error?: string | null;
}

const TicketTypeSelector = React.memo((props: TicketTypeSelectorProps) => {
	const { value, onChange, error, ticketTypeOptions, startDate } = props;
	const { t, getTranslation } = useTranslation();

	const options = useMemo((): SelectOption[] => {
		return (
			ticketTypeOptions?.map((ticket) => {
				return {
					id: ticket.externalId ?? '',
					label: getTranslation(ticket.name),
				};
			}) ?? []
		);
	}, [getTranslation, ticketTypeOptions]);

	const handleTicketTypeChange = (productId?: string) => {
		onChange(productId ?? null);
	};

	return (
		<Box>
			<Select.Single
				emptyLabel={t('tickets.chooseTicketType', 'Choose ticket type')}
				value={value}
				hideEmptyOption
				indicators="none"
				onChange={handleTicketTypeChange}
				options={options}
				disabled={!startDate}
			/>
			{error && (
				<Box mt={0.5}>
					<ErrorText>{error}</ErrorText>
				</Box>
			)}
		</Box>
	);
});

export default TicketTypeSelector;
