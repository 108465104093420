import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { updateProduct } from 'redux/actions/confirm';
import * as ShopSelector from 'redux/selectors/shop';

import KeycardImage from 'common/assets/skidata.png';
import InfoButton from 'common/components/InfoButton';
import Banner, { BannerProps } from 'common/components/states/Banner';
import { USER_DIDNT_INPUT_KEYCARD } from 'common/constants/skidataErrors';
import { CurrencyObject, OrderProduct } from 'common/types';
import { SerialNumberUtils } from 'common/utils/keycardSerialNumberUtils';
import {
	getCustomValidKeycards,
	isCustomKeycardShop,
	isValidCustomKeycardChip,
	isValidKeycardChip,
	isValidKeycardFormat,
	keycardNumberAlreadyUsedInOrder,
	luhnValidate,
	validKeycardChipIds,
} from 'common/utils/liftTicketUtils';
import { useTranslation } from 'services/localization/useTranslation';

import NoCardDialog from './NoCardDialog';

interface OwnProps {
	currency: CurrencyObject | undefined;
	liftTicketProduct: OrderProduct;
	setIsKeycardValid: (value: boolean) => void;
	liftTicketProductsWithExistingKeycard: OrderProduct[];
}

type KeycardValidationResults = null | undefined | BannerProps;

const KEYCARD_VALID = null;
const KEYCARD_INVALID_NO_ALERT = undefined;

const arrayToString = (array: string[]) =>
	array.map((v, i) => ` ${v}-${i - 1 === array.length ? ',' : ''}`);

const validKeycardChipIdsString = arrayToString(validKeycardChipIds);

const KeycardDetails = (props: OwnProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const shopId = useSelector(ShopSelector.shopId);
	const [proceedWithoutKeycardNumber, setProceedWithoutKeycardNumber] = useState(false);
	const [isKeycardDialogOpen, setIsKeycardDialogOpen] = useState(false);
	const {
		liftTicketProduct,
		currency,
		setIsKeycardValid,
		liftTicketProductsWithExistingKeycard,
	} = props;

	const keycardPriceIncrease = liftTicketProduct.variant?.rentals?.priceIncrease ?? undefined;

	const inputRef: React.Ref<HTMLInputElement | null | undefined> = useRef();

	const orderOtherLiftTicketProducts = useMemo(
		() => liftTicketProductsWithExistingKeycard.filter((p) => p.id !== liftTicketProduct.id),
		[liftTicketProduct.id, liftTicketProductsWithExistingKeycard],
	);

	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { value, selectionStart } = e.target;
		if (SerialNumberUtils.shouldValueChange(value)) {
			handleKeycardNumberChange(value);

			//Hacky! - Prevent caret from jumping to the end when inputting in the middle of the value
			if (!!selectionStart && selectionStart !== value.length) {
				setTimeout(() => inputRef.current?.setSelectionRange(selectionStart, selectionStart), 0);
			}
		}
	};

	const handleKeycardNumberChange = useCallback(
		(keycardNumber: string) => {
			if (!!liftTicketProduct) {
				const updatedKeyCard = {
					...liftTicketProduct,
					liftKeycardNumber: SerialNumberUtils.formatToDB(
						keycardNumber,
						liftTicketProduct.liftKeycardNumber,
					),
				};
				dispatch(updateProduct(updatedKeyCard));
			}
		},
		[dispatch, liftTicketProduct],
	);

	useEffect(() => {
		if (
			liftTicketProduct.liftKeycardNumber === USER_DIDNT_INPUT_KEYCARD &&
			!proceedWithoutKeycardNumber
		) {
			handleKeycardNumberChange('');
		}
	}, [liftTicketProduct, handleKeycardNumberChange, proceedWithoutKeycardNumber]);

	const keycardValidationError: KeycardValidationResults = useMemo(() => {
		if (!liftTicketProduct || proceedWithoutKeycardNumber) return KEYCARD_VALID;

		const { liftKeycardNumber } = liftTicketProduct;

		if (!liftKeycardNumber) return KEYCARD_INVALID_NO_ALERT;

		//HOTFIX, needs more scalable solution
		if (liftKeycardNumber.length > 3 && !!shopId && isCustomKeycardShop(shopId)) {
			const validCustomKeycardChipIds = getCustomValidKeycards(shopId);
			if (!isValidCustomKeycardChip(validCustomKeycardChipIds, liftKeycardNumber)) {
				return {
					variant: 'warning',
					title: t('tickets.invalidKeycardChipId', {
						validKeycardChipIds: arrayToString(validCustomKeycardChipIds),
						defaultValue:
							'Only keycards starting with {{validKeycardChipIds}} work on the online shop',
					}),
				};
			}
		}

		if (liftKeycardNumber.length > 3 && !isValidKeycardChip(liftKeycardNumber)) {
			return {
				variant: 'warning',
				title: t('tickets.invalidKeycardChipId', {
					validKeycardChipIds: validKeycardChipIdsString,
					defaultValue:
						'Only keycards starting with {{validKeycardChipIds}} work on the online shop',
				}),
			};
		}

		if (isValidKeycardFormat(liftKeycardNumber) && !luhnValidate(liftKeycardNumber)) {
			return {
				variant: 'warning',
				title: t(
					'tickets.invalidKeycardSerialNumber',
					'Invalid KeyCard serial number, double check for typos.',
				),
			};
		}

		if (keycardNumberAlreadyUsedInOrder(liftKeycardNumber, orderOtherLiftTicketProducts)) {
			return {
				variant: 'warning',
				title: t(
					'tickets.keycardSerialNumberAlreadyExists',
					'This KeyCard ID already exists in the order',
				),
			};
		}

		if (!isValidKeycardFormat(liftKeycardNumber)) return KEYCARD_INVALID_NO_ALERT;

		return KEYCARD_VALID;
	}, [liftTicketProduct, orderOtherLiftTicketProducts, proceedWithoutKeycardNumber, shopId, t]);

	useEffect(() => {
		setIsKeycardValid(keycardValidationError === null);
	}, [keycardValidationError, setIsKeycardValid]);

	const handleNoKeycardDialogAction = (accepted: boolean) => {
		setIsKeycardDialogOpen(false);
		setProceedWithoutKeycardNumber(accepted);
		accepted && handleKeycardNumberChange(USER_DIDNT_INPUT_KEYCARD);
	};

	const renderInfoButton = () => (
		<InfoButton
			title={t('shopperInfo.keycardFAQTitle', 'Where can I find my KeyCard ID?')}
			content={
				<>
					<div className={classes.imgContainer}>
						<img src={KeycardImage} alt="keycard-backside" className={classes.keycardImg} />
					</div>
					<Typography
						variant="body2"
						className={classnames(classes.smallSpacingBottom, classes.infoBtnContent)}
					>
						{t(
							'shopperInfo.keycardSerialGuide',
							'The KeyCard ID is located in the bottom-left corner on the back of the card. Insert the whole series of numbers. Your card will be recognized at the gate by the ID number.',
						)}
					</Typography>
					<Typography
						variant="h6"
						className={classnames(classes.smallSpacingBottom, classes.light)}
					>
						{t('shopperInfo.keycardStartTitle', 'When will the KeyCard start to work?')}
					</Typography>
					<Typography variant="body2" className={classes.infoBtnContent}>
						{t(
							'shopperInfo.keycardDelay',
							'Your KeyCard will be activated at latest 20 minutes after your purchase',
						)}
					</Typography>
				</>
			}
			buttonText={t('common:greetings.okBtn')}
			iconsButtonSize={3.5}
		/>
	);

	return (
		<Box mt={2}>
			<Box className={proceedWithoutKeycardNumber ? classes.noKeycard : ''}>
				<Typography variant="body1" display="inline-block">
					{t('shopperInfo.existingKeycard', 'Existing KeyCard ID')}
				</Typography>
				<Box display="inline-block" pl={0.5}>
					{renderInfoButton()}
				</Box>
			</Box>
			<Typography variant="body2" className={classes.keycardDescriptionText} gutterBottom>
				{t(
					'shopperInfo.keycardDescription',
					'Enter the ID of the KeyCard that you wish to assign this ticket to. It will be automatically activated when you use it for the first time at the lift gates.',
				)}
			</Typography>
			<TextField
				id="keycard"
				inputRef={inputRef}
				placeholder={'01-2847 8127 1263 1231 3123-0'}
				name="KeyCard"
				className={classes.textField}
				value={
					liftTicketProduct?.liftKeycardNumber !== USER_DIDNT_INPUT_KEYCARD
						? liftTicketProduct?.liftKeycardNumber
							? SerialNumberUtils.formatToUI(liftTicketProduct.liftKeycardNumber)
							: ''
						: ''
				}
				onChange={onChange}
				disabled={proceedWithoutKeycardNumber}
				type="text"
				variant="outlined"
			/>
			{keycardValidationError && <Banner {...keycardValidationError}></Banner>}
			<Typography
				variant="body2"
				className={classes.enterKeycard}
				onClick={() =>
					proceedWithoutKeycardNumber
						? setProceedWithoutKeycardNumber(false)
						: setIsKeycardDialogOpen(true)
				}
			>
				{proceedWithoutKeycardNumber
					? t('shopperInfo.enterKeycardId', 'Enter KeyCard ID')
					: t('shopperInfo.cantFindKeycard', `Can't find your KeyCard?`)}
			</Typography>
			<NoCardDialog
				dialogAction={handleNoKeycardDialogAction}
				isOpen={isKeycardDialogOpen}
				keycardPrice={keycardPriceIncrease}
				currency={currency}
			/>
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	textField: {
		width: '100%',
		marginBottom: theme.spacing(1),
	},
	smallSpacingBottom: {
		marginBottom: theme.spacing(1),
	},
	noKeycard: {
		opacity: 0.2,
	},
	imgContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	keycardImg: {
		width: '90%',
		height: 'auto',
		marginBottom: theme.spacing(1),
	},
	light: {
		fontWeight: 250,
	},
	enterKeycard: {
		marginTop: theme.spacing(2),
		textTransform: 'uppercase',
		textDecoration: 'underline',
		fontWeight: 500,
		color: theme.palette.text.secondary,
		cursor: 'pointer',
	},
	infoBtnContent: {
		fontFamily: '"Nunito Sans", "CircularStd", "Helvetica", "Arial", sans-serif',
	},
	keycardDescriptionText: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
}));

export default KeycardDetails;
