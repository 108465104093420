import React, { ReactNode } from 'react';

import { Link } from '@mui/material';
import { Anchorme, LinkComponentProps } from 'react-anchorme';

interface Props {
	children: string & ReactNode;
}
const AnchorMe = (props: Props) => {
	return (
		<Anchorme target="_blank" rel="noopener noreferrer" truncate={60} linkComponent={LinkComponent}>
			{props.children}
		</Anchorme>
	);
};

const LinkComponent = (props: LinkComponentProps) => (
	<Link
		href={props.href}
		target={props.target}
		rel={props.rel}
		children={props.children}
		sx={{ color: (theme) => theme.palette.primary.main }}
	/>
);

export default AnchorMe;
