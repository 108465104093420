import { useCallback, useEffect, useState } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as ViewActions from 'redux/actions/view';
import * as ViewSelectors from 'redux/selectors/view';

import { sendEventToParentPage } from 'common/modules/embed';
import { isRunInIframe } from 'common/utils/browserUtils';

const useScrollToCart = (): [() => void, boolean] => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const cartOpen = useSelector(ViewSelectors.cartOpen);

	const [isCartOpenTriggered, setIsCartOpenTriggered] = useState<boolean>(cartOpen);

	useEffect(() => {
		setIsCartOpenTriggered(cartOpen);
	}, [cartOpen]);

	const scrollToCart = useCallback(() => {
		setIsCartOpenTriggered(true);
		// Depending on screen size, change order of scrolling and cart modal opening

		if (isSmallScreen) {
			dispatch(ViewActions.setCartOpen(true));
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
			if (isRunInIframe()) {
				sendEventToParentPage({
					origin: 'rentle',
					type: 'trigger-scroll',
					payload: {
						offset: 0,
					},
				});
			}
		} else {
			window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
			if (isRunInIframe()) {
				sendEventToParentPage({
					origin: 'rentle',
					type: 'trigger-scroll',
					payload: {
						offset: 0,
					},
				});
			}
			setTimeout(() => {
				dispatch(ViewActions.setCartOpen(true));
			}, 300);
		}
	}, [isSmallScreen, dispatch]);

	return [scrollToCart, isCartOpenTriggered];
};

export default useScrollToCart;
