import React from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as NavSelectors from 'redux/selectors/nav';
import * as ShopSelectors from 'redux/selectors/shop';

import { Location } from 'common/types';
import BoxButton from 'components/BoxButton';
import LocationDetailContainer from 'components/LocationDetailContainer';
import { useTranslation } from 'services/localization/useTranslation';
import { getPath } from 'services/routing/utils';

interface Props {
	location: Location;
	onSelect?: () => void;
	hideActiveLocation?: boolean;
	hideContactInfo?: boolean;
	hideExceptions?: boolean;
	hideAdditionalInformation?: boolean;
}
const LocationCard = (props: Props) => {
	const classes = useStyles();
	const {
		location,
		hideActiveLocation,
		hideContactInfo,
		hideExceptions,
		hideAdditionalInformation,
	} = props;
	const { t } = useTranslation();

	const shopName = useSelector(NavSelectors.shopName);
	const onlineUrlsByLocationId = useSelector(ShopSelectors.onlineUrlsByLocationId);
	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const onlineLocations = useSelector(ShopSelectors.onlineLocations);
	const mainLocation = useSelector(ShopSelectors.shopMainLocation);

	const isActive = activeLocation?.id === location.id;

	const locationPath = (() => {
		const singleAndMainLocation = onlineLocations.length === 1 && location.id === mainLocation?.id;
		const locationName = !singleAndMainLocation ? onlineUrlsByLocationId[location.id] : undefined;
		return getPath({ shopName, locationName })('browse');
	})();

	return (
		<Box className={classes.wrapper}>
			<LocationDetailContainer
				location={location}
				variant="location"
				hideContactInfo={hideContactInfo}
				hideExceptions={hideExceptions}
				hideAdditionalInformation={hideAdditionalInformation}
			/>
			{isActive && !hideActiveLocation ? (
				<Link to={locationPath}>
					<BoxButton
						variant="outlined"
						text={t('location.continueShopping', 'Continue shopping')}
						onClick={() => {
							props.onSelect?.();
						}}
					/>
				</Link>
			) : (
				<Link to={locationPath}>
					<BoxButton
						variant="filled"
						text={
							hideActiveLocation
								? t('location.goToStore', 'Go to store')
								: t('location.changeStore', 'Change store')
						}
						onClick={() => {
							props.onSelect?.();
						}}
					/>
				</Link>
			)}
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			border: '1px solid black',
			padding: theme.spacing(4),
			minHeight: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
	}),
);

export default LocationCard;
