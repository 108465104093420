import React from 'react';

import { Typography, useTheme } from '@mui/material';
import { TFunction } from 'i18next';

import { LocaleHelpers } from 'common/locales/helpers';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { getTranslation } from 'common/modules/translations';
import { Languages } from 'common/types';

export interface PaymentMethodDescriptionProps {
	paymentMethod: ShopOnlinePaymentMethodObject;
	t: TFunction;
	lang: Languages;
}

const PaymentMethodDescription = (props: PaymentMethodDescriptionProps) => {
	const { paymentMethod, t, lang } = props;
	const theme = useTheme();
	const text = (() => {
		if (paymentMethod.id === 'MANUAL') {
			return getTranslation(paymentMethod.details?.description ?? { def: '' }, lang);
		} else {
			return LocaleHelpers.common(t).paymentMethodDescriptions[paymentMethod.id];
		}
	})();

	if (!text) {
		return null;
	}
	return (
		<Typography
			sx={{
				margin: theme.spacing(0, 2, 2, 2),
				[theme.breakpoints.down('sm')]: {
					marginLeft: theme.spacing(7),
				},
			}}
			variant="body2"
			color="textSecondary"
		>
			{text}
		</Typography>
	);
};

export default PaymentMethodDescription;
