import {
	BillingAddress,
	BillingCreditsBalance,
	BillingEmail,
	BillingInformation,
	RentleProductRef,
} from 'common/modules/billing';
import { ById } from 'common/types';

import { CallableCreator } from '..';

export interface GetInvoiceUrlArgs {
	invoiceId: string;
	type: 'receipt' | 'invoice';
}

export interface CancelInvoiceArgs {
	invoiceId: string;
	/**
	 * Whether to un-assign events related to this invoice
	 *
	 * - If yes, the events will be freed up for use in future invoices
	 * - If no, the events will not be freed up for use in future invoices, and cannot be charged later
	 */
	unassignEvents: boolean;
}

export interface ChargeInvoiceArgs {
	invoiceId: string;
}

export interface RefundInvoiceArgs {
	invoiceId: string;
}

export interface CreateInvoiceArgs {
	merchantId: string;
	locationId: string;
	invoiceNumber?: number;
	month: number;
	year: number;
}

export interface CreateAllMonthlyBillableEventsArgs {
	/**
	 * 4-digit year
	 */
	year: number;
	/**
	 * Month number, 1-12
	 */
	month: number;
}

export interface RenewAllMerchantSubscriptionsArgs {
	date: string;
	/**
	 * To create daily billable events for a date range (day-endDay)
	 */
	endDate?: string;
}

export interface RenewMerchantSubscriptionArgs {
	merchantId: string;
	date: string;
	invoiceNumbers: number[];
}

export interface RecreateBillableEventsForLocationArgs {
	merchantId: string;
	locationId: string;
	/**
	 * 4-digit year
	 */
	year: number;
	/**
	 * Month number, 1-12
	 */
	month: number;
}

export interface RecreateBillableEventsForAllLocationsArgs {
	/**
	 * 4-digit year
	 */
	year: number;
	/**
	 * Month number, 1-12
	 */
	month: number;
}

export interface CreateMonthlyBillableEventsArgs {
	merchantId: string;
	locationId: string;
	/**
	 * The month to generate the events for (1-12)
	 */
	month: number;
	/**
	 * The 4-digit year to generate the events for
	 */
	year: number;
}

export interface CreateAllInvoicesArgs {
	/**
	 * Month number, 1-12
	 */
	month: number;
	/**
	 * The 4-digit year to generate the events for
	 */
	year: number;
}

export interface CreateCustomEventArgs {
	merchantId: string;
	locationId: string;
	billingDate: string;
	amount: number;
	product: RentleProductRef.CUSTOM_DISCOUNT_ONE_OFF | RentleProductRef.CUSTOM_FEE_ONE_OFF;
	description: string;
}

export interface DeleteCustomEventArgs {
	eventId: string;
}

export interface UpdateVatNumberArgs {
	merchantId: string;
	vatNumber: string;
	exempt: boolean;
}

export interface MarkInvoiceAsPaidArgs {
	invoiceId: string;
}

export interface RegenerateInvoicePDFsArgs {
	invoiceId: string;
}

export interface MarkInvoiceAsRefundedArgs {
	invoiceId: string;
}

export interface UpdateBillingEmailArgs {
	merchantId: string;
	email: BillingEmail;
}

export interface UpdateBillingAddressArgs {
	merchantId: string;
	address: BillingAddress;
}

export interface UpdateBillingInformationArgs {
	merchantId: string;
	billingInformation: BillingInformation;
}

export interface GetRevenueDiscountEligibilityByLocationArgs {
	merchantId: string;
}

export type AddManualCreditsArgs = {
	merchantId: string;
	description: string;
	amount: number;
};

export type AddManualCreditsReturnArgs = {
	balanceAfter: BillingCreditsBalance;
	creditsAdded: { refund: number; manual: number; total: number };
};

export const billing = (createCallable: CallableCreator) => ({
	invoices: {
		getUrl: createCallable<GetInvoiceUrlArgs, string | null>('billing-f-invoices-getUrl'),
		create: createCallable<CreateInvoiceArgs, void>('billing-f-invoices-create'),
		createAll: createCallable<CreateAllInvoicesArgs, void>('billing-f-invoices-createAll'),
		cancel: createCallable<CancelInvoiceArgs, void>('billing-f-invoices-cancel'),
		refund: createCallable<RefundInvoiceArgs, void>('billing-f-invoices-refund'),
		charge: createCallable<ChargeInvoiceArgs, void>('billing-f-invoices-charge'),
		chargeAll: createCallable<void, void>('billing-f-invoices-chargeAll'),
		markPaid: createCallable<MarkInvoiceAsPaidArgs, void>('billing-f-invoices-markPaid'),
		markRefunded: createCallable<MarkInvoiceAsRefundedArgs, void>(
			'billing-f-invoices-markRefunded',
		),
		regeneratePDFs: createCallable<RegenerateInvoicePDFsArgs, void>(
			'billing-f-invoices-regeneratePDFs',
		),
	},
	addManualCredits: createCallable<AddManualCreditsArgs, AddManualCreditsReturnArgs>(
		'billing-f-addManualCredits',
	),
	getRevenueDiscountEligibilityByLocation: createCallable<
		GetRevenueDiscountEligibilityByLocationArgs,
		ById<boolean>
	>('billing-f-subscriptions-getRevenueDiscountEligibility'),
	updateVatNumber: createCallable<UpdateVatNumberArgs, void>('billing-f-updateVatNumber'),
	createMonthlyBillableEvents: createCallable<CreateMonthlyBillableEventsArgs, void>(
		'billing-f-createMonthlyBillableEvents',
	),
	renewAllSubscriptions: createCallable<RenewAllMerchantSubscriptionsArgs, void>(
		'billing-f-renewAllSubscriptions',
	),
	renewMerchantSubscription: createCallable<RenewMerchantSubscriptionArgs, void>(
		'billing-f-renewMerchantSubscription',
	),
	createAllMonthlyBillableEvents: createCallable<CreateAllMonthlyBillableEventsArgs, void>(
		'billing-f-createAllMonthlyBillableEvents',
	),
	createCustomEvent: createCallable<CreateCustomEventArgs, void>('billing-f-createCustomEvent'),
	deleteCustomEvent: createCallable<DeleteCustomEventArgs, void>('billing-f-deleteCustomEvent'),
	recreateBillableEventsForLocation: createCallable<RecreateBillableEventsForLocationArgs, void>(
		'billing-f-recreateBillableEventsForLocation',
	),
	recreateBillableEventsForAllLocations: createCallable<
		RecreateBillableEventsForAllLocationsArgs,
		void
	>('billing-f-recreateBillableEventsForAllLocations'),
	updateBillingEmail: createCallable<UpdateBillingEmailArgs, void>('billing-f-updateBillingEmail'),
	updateBillingAddress: createCallable<UpdateBillingAddressArgs, void>(
		'billing-f-updateBillingAddress',
	),
	updateBillingInformation: createCallable<UpdateBillingInformationArgs, void>(
		'billing-f-updateBillingInformation',
	),
});
