import { useEffect, useState } from 'react';

import { AvailabilityRange } from 'common/api/frontend/inventory_new/availabilityRange';
import { handleQuantityAndAvailabilityFetch } from 'common/api/frontend/inventory_new/getQuantitiesAndAvailabilities';
import { Callable } from 'common/frontend/callable';
import {
	ByVariant,
	Channel,
	LoadingData,
	Location,
	ProductApi,
	PurchaseType,
	StartTimeCount,
} from 'common/types';
import {
	getEndDateWithInventoryBlockers,
	getStartDateWithInventoryBlockers,
} from 'common/utils/productUtils';

interface AvailabilityResult {
	quantity: LoadingData<ByVariant<number | null>>;
	availability: LoadingData<ByVariant<AvailabilityRange[]>>;
	startTimeCounts: LoadingData<ByVariant<StartTimeCount>>;
}

interface AvailabilityOptions {
	stockProducts: ProductApi[];
	startDate: string;
	endDate: string;
	shopId: string;
	locationId: string;
	shopLocations: Location[];
	reservationId?: string;
	salesChannel?: Channel;
	useServerFetching?: boolean;
	purchaseType: PurchaseType;
}

const useAvailability = (productId: string, options: AvailabilityOptions): AvailabilityResult => {
	const {
		stockProducts,
		startDate,
		endDate,
		shopId,
		locationId,
		shopLocations,
		salesChannel,
		reservationId,
		useServerFetching = false,
		purchaseType,
	} = options;
	const product = stockProducts.find((p) => p.id === productId)!;
	const [quantity, setQuantity] = useState<LoadingData<ByVariant<number | null>>>({
		loading: true,
		data: null,
		error: null,
	});
	const [availability, setAvailability] = useState<LoadingData<ByVariant<AvailabilityRange[]>>>({
		loading: true,
		data: null,
		error: null,
	});
	const [startTimeCounts, setStartTimeCounts] = useState<LoadingData<ByVariant<StartTimeCount>>>({
		loading: true,
		data: null,
		error: null,
	});

	useEffect(() => {
		const fetchAvailabilities = async () => {
			try {
				setAvailability({ loading: true, data: null, error: null });
				setQuantity({ loading: true, data: null, error: null });
				setStartTimeCounts({ loading: true, data: null, error: null });
				const startDateWithInventoryBlockers = getStartDateWithInventoryBlockers({ startDate });
				const endDateWithInventoryBlockers = getEndDateWithInventoryBlockers(
					endDate,
					product,
					stockProducts,
				);
				const availabilityFetcherFunction = useServerFetching
					? Callable.availability.getQuantitiesAndAvailabilities
					: handleQuantityAndAvailabilityFetch;
				const totalAvailabilities = await availabilityFetcherFunction({
					product,
					stockProducts,
					startDate: startDateWithInventoryBlockers,
					endDate: endDateWithInventoryBlockers,
					shopId,
					startLocationId: locationId,
					shopLocations,
					reservationId,
					salesChannel,
					purchaseType,
				});
				const {
					availabilityRangesByVariant,
					variantQuantities,
					startTimeCountsByVariant,
				} = totalAvailabilities;

				setAvailability({ loading: false, data: availabilityRangesByVariant, error: null });
				setQuantity({ loading: false, data: variantQuantities, error: null });
				setStartTimeCounts({ loading: false, data: startTimeCountsByVariant, error: null });
			} catch (e) {
				setAvailability({
					loading: false,
					data: null,
					error: 'Error',
				});
				setQuantity({
					loading: false,
					data: null,
					error: 'Error',
				});
				setStartTimeCounts({
					loading: false,
					data: null,
					error: 'Error',
				});
			}
		};
		fetchAvailabilities();
	}, [productId, purchaseType]);

	return {
		availability,
		quantity,
		startTimeCounts,
	};
};

export default useAvailability;
