import { uniq } from 'lodash';
import moment from 'moment-timezone';

import { AvailabilityRange } from 'common/api/frontend/inventory_new/availabilityRange';

import { ISOString } from '../atoms/dates';
import { AvailabilityByTime, AvailabilityWithReason } from './timeSlots/types';

export const mergeTimeSlots = (timeSlots: AvailabilityByTime[]) => {
	const allDays = uniq(timeSlots.flatMap((availability) => Object.keys(availability))).sort();
	return allDays.reduce((total, day) => {
		const allHours = uniq(
			timeSlots.flatMap((availability) => Object.keys(availability[day])),
		).sort();
		const timesWithReason = allHours.reduce((total, hour) => {
			const allStartTimesWithDateTime: (AvailabilityWithReason | undefined)[] = timeSlots.map(
				(availability) => availability[day][hour],
			);
			if (allStartTimesWithDateTime.some((time) => !time)) {
				const availability: AvailabilityWithReason = {
					valid: false,
					units: 0,
					reason: 'opening_hours',
				};
				return {
					...total,
					[hour]: availability,
				};
			}
			const minUnits = Math.min(...allStartTimesWithDateTime.map((time) => time?.units ?? 0));
			const invalid = allStartTimesWithDateTime.find((time) => !time?.valid);
			if (!!invalid) {
				const availability: AvailabilityWithReason = { ...invalid, units: minUnits };
				return {
					...total,
					[hour]: availability,
				};
			}
			const availability: AvailabilityWithReason = { valid: true, units: minUnits };
			return {
				...total,
				[hour]: availability,
			};
		}, {} as { [time: string]: AvailabilityWithReason });
		return {
			...total,
			[day]: timesWithReason,
		};
	}, {} as AvailabilityByTime);
};

export const getRelevantRanges = (args: {
	start: ISOString;
	end: ISOString;
	availabilityRanges: AvailabilityRange[];
}): AvailabilityRange[] => {
	const relevantRanges = [];

	for (const range of args.availabilityRanges) {
		if (moment(range.end).isBefore(args.start)) {
			continue;
		} else if (moment(range.start).isAfter(args.end)) {
			break;
		} else {
			relevantRanges.push(range);
		}
	}

	return relevantRanges;
};
