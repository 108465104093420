import { useCallback, useMemo } from 'react';

import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import {
	setAuthError,
	setAuthProfile,
	setAuthToken,
	setIsNewRegistration,
} from 'redux/actions/auth';
import { updateContactPerson } from 'redux/actions/checkout';
import { setPhoneObject } from 'redux/actions/view';
import * as AuthSelectors from 'redux/selectors/auth';
import * as ShopSelectors from 'redux/selectors/shop';
import { getPhoneObjectFromFullPhoneNumber } from 'views/Shop/Location/Checkout/utils';

import errorHandler from 'common/services/errorHandling/errorHandler';
import { ResponsiblePersonDetails } from 'common/types';
import { AuthProfile } from 'common/types/external/auth';
import { sleep } from 'common/utils/async';
import { isDevEnv, notUndefined, switchUnreachable } from 'common/utils/common';
import {
	StorageKeys,
	getFromStorage,
	removeFromStorage,
	saveToStorage,
} from 'common/utils/frontUtils';
import { newFirestoreId } from 'common/utils/newRentalUtils';
import { PostMessageAuthEvent } from 'hooks/useExternalAuth/types';

import { ExternalAuthProvider, getAuthConfigs } from './authProviders';
import { POST_MESSAGE_EVENTS, POST_MESSAGE_PROVIDER } from './constants';
import { getLoginPopupParams } from './utils';

type PostMessageEvent = MessageEvent<PostMessageAuthEvent | undefined>;

let windowObjectReference: Window | null = null;
let previousUrl: string | null = null;
let handleAuthPostMessageWrapper: (event: PostMessageEvent) => void = () => undefined;

const useExternalAuth = () => {
	const dispatch = useDispatch();
	const externalAuthProvider = useSelector(ShopSelectors.externalAuthProvider);
	const authToken = useSelector(AuthSelectors.authToken);
	const authProfile = useSelector(AuthSelectors.authProfile);
	const isNewRegistation = useSelector(AuthSelectors.isNewRegistation);
	const isLoggedIn = !!authToken && !!authProfile.data;
	const loginLoading = authProfile.loading;
	const authProviderConfigs = useMemo(() => getAuthConfigs(externalAuthProvider), [
		externalAuthProvider,
	]);
	const isAuthInUse = !!externalAuthProvider && !!authProviderConfigs;
	const isNewDecathlonRegistration = isNewRegistation && externalAuthProvider === 'DECATHLON';

	const getIsNewRegistrationFromProfile = (
		authProvider: ExternalAuthProvider,
		profile: AuthProfile,
	) => {
		switch (authProvider) {
			case 'DECATHLON':
				/**
				 * We don't have a better way to check if the user does a new user registration,
				 * so we check if the first name and last name of the user are empty.
				 */
				return !profile.firstName && !profile.lastName;
			default:
				return false;
		}
	};

	const fetchUserProfile = useCallback(
		async (authToken: string, opts?: { hideError: boolean }) => {
			if (!externalAuthProvider || !authProviderConfigs) return;
			try {
				const profile = await authProviderConfigs.getProfileInfo(authToken);
				const contactPerson: Partial<ResponsiblePersonDetails> = {
					firstName: profile.firstName,
					lastName: profile.lastName,
					phone: profile.phone,
					email: profile.email,
					meta: {
						auth: {
							uid: profile.id,
							email: profile.email,
						},
					},
				};
				const phoneObject = contactPerson.phone
					? getPhoneObjectFromFullPhoneNumber(contactPerson.phone)
					: undefined;
				dispatch(
					setIsNewRegistration(getIsNewRegistrationFromProfile(externalAuthProvider, profile)),
				);
				dispatch(
					setAuthProfile({
						loading: false,
						data: {
							...profile,
							dateOfBirth: profile.dateOfBirth ? moment(profile.dateOfBirth) : undefined,
						},
						error: null,
					}),
				);
				dispatch(updateContactPerson(contactPerson));
				if (phoneObject) {
					dispatch(setPhoneObject(phoneObject));
				}
			} catch (e) {
				errorHandler.report(e);
				dispatch(
					setAuthProfile({ loading: false, data: null, error: 'Unable to get user profile' }),
				);
				if (!opts?.hideError) {
					dispatch(setAuthError('Unable to get user profile'));
				}
			}
		},
		[authProviderConfigs, dispatch, externalAuthProvider],
	);

	const getValidPostMessageEvent = (message: PostMessageEvent) => {
		const origin = message.origin;
		const validOrigins = [
			isDevEnv() ? 'https://dev.rentle.store' : undefined,
			window.location.origin,
		].filter(notUndefined);
		if (!validOrigins.includes(origin)) return false;
		const rentlePostMessage = message.data?.provider === POST_MESSAGE_PROVIDER;
		return rentlePostMessage ? message.data : undefined;
	};

	const handleAuthPostMessage = useCallback(
		async (postMessage: PostMessageAuthEvent, state?: string) => {
			switch (postMessage.event) {
				case POST_MESSAGE_EVENTS.AUTH_CODE: {
					if (!!state && postMessage.state !== state) {
						dispatch(setAuthError('State values do not match'));
						return true;
					}
					dispatch(setAuthProfile({ loading: true, data: null, error: null }));
					const authCode = postMessage.value;
					try {
						const result = await authProviderConfigs?.getAuthToken(authCode);
						const authToken = result?.access_token;
						if (!!authToken) {
							const defaultAuthExpirationSeconds = 300;
							const expiresInSeconds = result?.expires_in ?? defaultAuthExpirationSeconds;
							const expiryTime = moment().add(expiresInSeconds, 'seconds').toISOString();
							saveToStorage('localStorage', StorageKeys.EXT_AUTH_TOKEN, authToken);
							saveToStorage('localStorage', StorageKeys.EXT_AUTH_TOKEN_EXPIRY, expiryTime);
							fetchUserProfile(authToken);
							dispatch(setAuthToken(authToken));
						}
						return true;
					} catch (e) {
						dispatch(setAuthProfile({ loading: false, data: null, error: null }));
						dispatch(setAuthError('Error fetching auth token'));
						return true;
					}
				}
				case POST_MESSAGE_EVENTS.AUTH_CODE_ERROR: {
					dispatch(setAuthError(postMessage.value));
					return true;
				}
				case POST_MESSAGE_EVENTS.LOGOUT: {
					return true;
				}
				case POST_MESSAGE_EVENTS.WINDOW_CLOSE: {
					return true;
				}
				default: {
					return switchUnreachable(postMessage);
				}
			}
		},
		[authProviderConfigs, dispatch, fetchUserProfile],
	);

	const openPopupWindow = (url: string, targetName?: string) => {
		const authWindowFeatures = getLoginPopupParams();
		if (windowObjectReference === null || windowObjectReference.closed) {
			windowObjectReference = window.open(url, targetName, authWindowFeatures);
		} else if (previousUrl !== url) {
			windowObjectReference = window.open(url, targetName, authWindowFeatures);
			if (windowObjectReference) {
				windowObjectReference.focus();
			}
		} else {
			windowObjectReference.focus();
		}
		previousUrl = url;
	};

	const closeExternalWindow = async (windowRef: Window) => {
		/**
		 * There's an issue with iOS Safari, where calling window.close after redirect does not close the window correctly.
		 * To fix this, we use a recursion that tries to close the window until it's successful (or max attempts is reached)
		 * More info: https://stackoverflow.com/questions/10712906/window-close-doesnt-work-on-ios
		 */
		const closeWindow = async (windowRef: Window) => {
			windowRef.close();
			if (windowRef.closed || attempts > MAX_ATTEMPTS) {
				return;
			} else {
				attempts++;
				await sleep(200);
				closeWindow(windowRef);
			}
		};

		let attempts = 0;
		const MAX_ATTEMPTS = 5;
		windowRef.focus();
		await closeWindow(windowRef);
	};

	const handleAuthPostMessageEvents = useCallback(
		async (state?: string) => {
			window.removeEventListener('message', handleAuthPostMessageWrapper);
			return new Promise<void>((resolve) => {
				handleAuthPostMessageWrapper = async (message: PostMessageEvent) => {
					const event = getValidPostMessageEvent(message);
					if (!!event) {
						if (windowObjectReference && !windowObjectReference.closed) {
							await closeExternalWindow(windowObjectReference);
						}
						window.removeEventListener('message', handleAuthPostMessageWrapper);
						await handleAuthPostMessage(event, state);
						resolve();
					}
				};
				window.addEventListener('message', handleAuthPostMessageWrapper, false);
			});
		},
		[handleAuthPostMessage],
	);

	const initExternalAuth = useCallback(() => {
		const storageAuthToken = getFromStorage('localStorage', StorageKeys.EXT_AUTH_TOKEN);
		const storageAuthTokenExpiry = getFromStorage(
			'localStorage',
			StorageKeys.EXT_AUTH_TOKEN_EXPIRY,
		);
		if (!storageAuthToken || !storageAuthTokenExpiry) return;
		const expiryTime = moment(storageAuthTokenExpiry);
		if (expiryTime.isBefore(moment())) {
			removeFromStorage('localStorage', StorageKeys.EXT_AUTH_TOKEN);
			removeFromStorage('localStorage', StorageKeys.EXT_AUTH_TOKEN_EXPIRY);
			return;
		}
		try {
			fetchUserProfile(storageAuthToken, { hideError: true });
			dispatch(setAuthToken(storageAuthToken));
		} catch (e) {
			removeFromStorage('localStorage', StorageKeys.EXT_AUTH_TOKEN);
			removeFromStorage('localStorage', StorageKeys.EXT_AUTH_TOKEN_EXPIRY);
		}
	}, [dispatch, fetchUserProfile]);

	const handleOauthRedirect = useCallback(async () => {
		if (!authProviderConfigs) return;
		const state = newFirestoreId();
		const loginUrl = authProviderConfigs.getOauthUrl(state);
		openPopupWindow(loginUrl, 'authLogin');
		await handleAuthPostMessageEvents(state);
	}, [authProviderConfigs, handleAuthPostMessageEvents]);

	const handleLogout = useCallback(async () => {
		if (!authProviderConfigs) return;
		if (!!authToken) {
			const logoutUrl = authProviderConfigs.getLogoutUrl(authToken);
			if (logoutUrl) {
				openPopupWindow(logoutUrl, 'authLogout');
			}
		}
		dispatch(setAuthToken(null));
		dispatch(setAuthProfile({ loading: false, data: null, error: null }));
		removeFromStorage('localStorage', StorageKeys.EXT_AUTH_TOKEN);
		removeFromStorage('localStorage', StorageKeys.EXT_AUTH_TOKEN_EXPIRY);
		await handleAuthPostMessageEvents();
		return;
	}, [authProviderConfigs, authToken, dispatch, handleAuthPostMessageEvents]);

	const updateRegisteredAuthProfile = useCallback(async () => {
		if (!externalAuthProvider || !authProviderConfigs?.updateProfileInfo || !authToken) return;
		if (isNewRegistation && authProfile.data) {
			try {
				const newAuthProfile: AuthProfile = {
					...authProfile.data,
					dateOfBirth: authProfile.data.dateOfBirth?.isValid()
						? authProfile.data.dateOfBirth.format('YYYY-MM-DD')
						: undefined,
					businessAccount: authProfile.data.businessAccount?.organisationType
						? {
								organisationType: authProfile.data.businessAccount?.organisationType,
								organisationName: authProfile.data.businessAccount?.organisationName,
						  }
						: undefined,
				};
				await authProviderConfigs.updateProfileInfo(authToken, newAuthProfile);
				dispatch(
					setIsNewRegistration(
						getIsNewRegistrationFromProfile(externalAuthProvider, newAuthProfile),
					),
				);
			} catch (e) {
				errorHandler.report(e);
			}
		}
		return;
	}, [
		authProfile.data,
		authProviderConfigs,
		authToken,
		dispatch,
		externalAuthProvider,
		isNewRegistation,
	]);

	return {
		initExternalAuth,
		isAuthInUse,
		isLoggedIn,
		loginLoading,
		authProfile,
		logout: handleLogout,
		oauthRedirect: handleOauthRedirect,
		authProvider: externalAuthProvider,
		updateRegisteredAuthProfile,
		isNewRegistation,
		isNewDecathlonRegistration,
	} as const;
};

export default useExternalAuth;
