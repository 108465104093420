import React from 'react';

import { Box, Typography } from '@mui/material';

const PoweredByRentle = () => {
	return (
		<Box component="span">
			<Typography variant="body2" component="span" color="secondary">
				Powered by{' '}
			</Typography>
			<Typography
				style={{ textDecoration: 'underline' }}
				variant="body2"
				component="a"
				color="secondary"
				href="https://www.rentle.io/"
				rel="noopener noreferrer"
				target="blank_"
			>
				Rentle
			</Typography>
		</Box>
	);
};

export default PoweredByRentle;
