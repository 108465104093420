import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { getQueryParam } from 'common/utils/frontUtils';
import { useRoutes } from 'services/routing/useRoutes';

import ShopperDetails from './ShopperDetails';
import ShopperDetailsStart from './ShopperDetailsStart';
import ShopperInfo from './ShopperInfo';
import ShopperMeasurements from './ShopperMeasurements';
import ShopperPhoto from './ShopperPhoto';
import ShopperProducts from './ShopperProducts';
import ShoppersSummary from './ShopperSummary';

const ShopperDetailsRoutes = () => {
	const queryOrderId = getQueryParam('orderId');
	const { getPath, getRoute, Routes } = useRoutes();

	return (
		<Switch>
			<Route exact path={getRoute(Routes.confirm)} component={ShopperDetailsStart} />
			<Route exact path={getRoute(Routes.confirmDetails)} component={ShopperInfo} />
			<Route exact path={getRoute(Routes.confirmDetailsProducts)} component={ShopperProducts} />
			<Route exact path={getRoute(Routes.confirmDetailsDetails)} component={ShopperMeasurements} />
			<Route exact path={getRoute(Routes.confirmDetailsInfo)} component={ShopperDetails} />
			<Route exact path={getRoute(Routes.confirmDetailsPhotoUpload)} component={ShopperPhoto} />
			<Route exact path={getRoute(Routes.confirmDetailsSummary)} component={ShoppersSummary} />
			<Redirect to={getPath(Routes.confirm, { query: { orderId: queryOrderId ?? undefined } })} />
		</Switch>
	);
};

export default ShopperDetailsRoutes;
