import React from 'react';

import {
	Box,
	StyledEngineProvider,
	Theme,
	ThemeProvider,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import * as Themes from 'common/styles/themes';
import CenteredContent from 'components/CenteredContent';
import { useTranslation } from 'services/localization';

import CheckoutContainer from './CheckoutContainer';
import CheckoutDataFetcher from './CheckoutDataFetcher';
import CheckoutCart from './components/CheckoutCart';
import MobileCartWrapper from './components/CheckoutCart/MobileCartWrapper';

const Checkout = () => {
	const classes = useStyles();
	const theme = useTheme();
	const { t } = useTranslation();

	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={Themes.WhiteLabelLight}>
				<CssBaseline />
				<CheckoutDataFetcher>
					{isSmallScreen && <MobileCartWrapper />}
					<CenteredContent paddingBottom={8} paddingTop={isSmallScreen ? 3 : 4}>
						<Typography variant="h5">{t('Checkout.checkout', 'Checkout')}</Typography>
						<Box pt={isSmallScreen ? 3 : 4} />
						<Box className={classes.wrapper}>
							<Box className={classes.main}>
								<CheckoutContainer />
							</Box>
							<Box p={4} />
							{!isSmallScreen && (
								<Box className={classes.sidebar}>
									<CheckoutCart showBorder innerPadding={2} />
								</Box>
							)}
						</Box>
					</CenteredContent>
				</CheckoutDataFetcher>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.up('md')]: {
				flexDirection: 'row',
				justifyContent: 'space-between',
			},
		},
		main: {
			flex: 1,
			[theme.breakpoints.up('md')]: {
				maxWidth: 700,
			},
		},
		sidebar: {
			flex: 1,
			[theme.breakpoints.up('md')]: {
				maxWidth: 380,
			},
		},
	}),
);

export default Checkout;
