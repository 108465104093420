import { Dictionary, groupBy, uniq } from 'lodash';
import moment from 'moment-timezone';

import { InventoryStatus } from 'common/modules/inventory';
import { OpeningHours } from 'common/modules/openingHours';
import { MappedDataWithChanges } from 'common/modules/orders';
import { getNextInstalmentFromProducts } from 'common/modules/subscriptions';
import { endOrderProductSubscription } from 'common/modules/subscriptions/utils/subscriptions';
import {
	ActiveState,
	Channel,
	Duration,
	OrderInfo,
	OrderProduct,
	PartialWithId,
	ProductApi,
	ProductType,
	PurchaseType,
	RentalState,
	ResponsiblePerson,
	ResponsiblePersonDetails,
	SetProduct,
	SetProductWithParentId,
	Shopper,
} from 'common/types';

import { notUndefined, switchUnreachable } from './common';
import { getNewEndTime, getNextProductReturnTime } from './dateCalculations';
import { assignPartialData } from './partialData';

export const getCategoryIdsFromShoppers = (shoppers: Shopper[]) => {
	return [...new Set(shoppers.map((shopper) => shopper.categoryIds || []).flat())];
};

export const getShopperIdsWithProductsFromShoppers = (shoppers: Shopper[]) => {
	const shopperIdsWithProducts = {};
	shoppers.forEach((shopper) => {
		const productIds = shopper.productIds.slice();
		shopperIdsWithProducts[shopper.id] = productIds;
	});
	return shopperIdsWithProducts;
};

export const getShopperProducts = (allProducts: OrderProduct[], shopper: Shopper): OrderProduct[] =>
	allProducts.filter((p) => p.shopperId === shopper.id);

export const getUpdatedShoppersForReturning = (
	shoppers: Shopper[],
	updatedProducts: OrderProduct[],
): MappedDataWithChanges<Shopper[]> => {
	let updatedShoppers: PartialWithId<Shopper>[] = [];
	shoppers.forEach((shopper) => {
		const shoppersReturnedProducts = updatedProducts
			.filter((p) => p.shopperId === shopper.id)
			.filter((p) => p.endDateReturned);
		if (shoppersReturnedProducts.length === shopper.productIds.length) {
			updatedShoppers.push({
				id: shopper.id,
				data: {
					productsReturned: true,
				},
			});
		}
	});
	return {
		changes: updatedShoppers,
		full: assignPartialData(shoppers, updatedShoppers),
	};
};

export const getStockProductIdsFromProducts = (products: OrderProduct[]) => {
	return [...new Set(products.map((product) => product.productApiIds).flat())];
};

export const getUpdatedParentsOfSetProductsToBeReturned = (
	setProductsToReturn: SetProductWithParentId[],
	allOrderProducts: OrderProduct[],
) => {
	const endDateReturned = moment().toISOString();
	const setProductsToReturnWithUpdatedEndDateReturned = setProductsToReturn.map((setProduct) => {
		if (!setProduct.returnedDate) {
			return {
				...setProduct,
				returnedDate: endDateReturned,
			};
		}
		return setProduct;
	});
	const parentIdsOfSetProductsToReturn = [
		...new Set(setProductsToReturn.map((setP) => setP.parentId)),
	];

	const updatedParentsOfSetProductsToReturn = parentIdsOfSetProductsToReturn.reduce(
		(updatedParents: PartialWithId<OrderProduct>[], parentId) => {
			const parentProduct = allOrderProducts.find((p) => p.id === parentId);
			if (parentProduct) {
				const setProductsToReturnForCurrentParent = setProductsToReturnWithUpdatedEndDateReturned
					.filter((setP) => setP.parentId === parentProduct.id)
					.map((setP) => {
						const { parentId, ...setProduct } = setP;
						return setProduct as SetProduct;
					});

				const setProducts = parentProduct.setProducts.map((setP) => {
					const setProductToReturn = setProductsToReturnForCurrentParent.find(
						(p) => p.productApiId === setP.productApiId,
					);
					return setProductToReturn || setP;
				});
				const setProductsThatStayUnreturned = setProducts
					.filter((setP) => !setP.removedFromParent)
					.filter((setP) => !setP.returnedDate);
				if (!setProductsThatStayUnreturned.length) {
					const updatedParent = {
						id: parentProduct.id,
						data: {
							endDateReturned,
							setProducts,
							...(parentProduct.subscription
								? { subscription: endOrderProductSubscription(parentProduct.subscription) }
								: undefined),
						},
					};
					updatedParents.push(updatedParent);
				} else {
					const updatedParent = {
						id: parentProduct.id,
						data: {
							setProducts,
						},
					};
					updatedParents.push(updatedParent);
				}
			}
			return updatedParents;
		},
		[],
	);
	return {
		changes: updatedParentsOfSetProductsToReturn,
		full: assignPartialData(allOrderProducts, updatedParentsOfSetProductsToReturn),
	};
};

export const getUpdatedProductsForReturning = (
	productIdsToReturn: string[],
	updatedParentsOfSetProductsToBeReturned: PartialWithId<OrderProduct>[],
	allOrderProducts: OrderProduct[],
): MappedDataWithChanges<OrderProduct[]> => {
	const endDateReturned = moment().toISOString();
	const updatedNonSetProductsToBeReturned = allOrderProducts.reduce(
		(updatedNonSetProducts: PartialWithId<OrderProduct>[], product) => {
			if (productIdsToReturn.includes(product.id)) {
				const updatedProduct = {
					id: product.id,
					data: {
						endDateReturned,
						...(product.subscription
							? { subscription: endOrderProductSubscription(product.subscription) }
							: undefined),
					},
				};
				updatedNonSetProducts.push(updatedProduct);
			}
			return updatedNonSetProducts;
		},
		[],
	);

	const allUpdatedProducts = [
		...updatedNonSetProductsToBeReturned,
		...updatedParentsOfSetProductsToBeReturned,
	];
	return {
		changes: allUpdatedProducts,
		full: assignPartialData(allOrderProducts, allUpdatedProducts),
	};
};

export const allProductsReturned = (updatedProducts: OrderProduct[]) => {
	if (updatedProducts.some((p) => !p.endDateReturned)) {
		return false;
	}
	return true;
};

export type OrderAction =
	| 'START_ORDER'
	| 'FULFILL_ORDER'
	| 'END_ORDER'
	| 'REOPEN_ORDER'
	| 'CANCEL_ORDER'
	| 'CANCEL_ORDER_START'
	| 'CANCEL_ORDER_FULFILLMENT';

export const getNewActiveAndRentalState = (args: {
	action: OrderAction;
	channel: Channel;
	hasDelivery: boolean;
}): {
	newRentalState: RentalState;
	newActiveState: ActiveState;
} => {
	const { action, channel } = args;
	switch (action) {
		case 'START_ORDER':
			return {
				newRentalState: 'ACTIVE',
				newActiveState: 'RENT_OUT',
			};
		case 'FULFILL_ORDER':
		case 'END_ORDER': {
			return {
				newRentalState: 'COMPLETED',
				newActiveState: 'RENT_ENDED',
			};
		}
		case 'REOPEN_ORDER': {
			return {
				newRentalState: 'ACTIVE',
				newActiveState: 'RENT_OUT',
			};
		}
		case 'CANCEL_ORDER': {
			return {
				newRentalState: 'COMPLETED',
				newActiveState: 'RENT_CANCEL',
			};
		}
		case 'CANCEL_ORDER_FULFILLMENT':
		case 'CANCEL_ORDER_START': {
			return {
				newRentalState: 'BOOKED',
				newActiveState:
					channel === 'STORE'
						? 'STORE_BOOKED'
						: channel === 'ADMIN'
						? 'ADMIN_BOOKED'
						: channel === 'ONLINE'
						? 'ONLINE_BOOKED'
						: channel === 'PAYLINK'
						? 'PAYLINK_CONFIRMED'
						: 'ADMIN_BOOKED',
			};
		}
		default:
			return switchUnreachable(action);
	}
};

export const getStatusChangesForSalesProducts = (
	action: OrderAction,
): {
	currentStatus: InventoryStatus;
	targetStatus: InventoryStatus;
} | null => {
	switch (action) {
		case 'START_ORDER':
		case 'FULFILL_ORDER':
			return {
				currentStatus: 'IN_USE',
				targetStatus: 'SOLD',
			};
		case 'CANCEL_ORDER':
		case 'REOPEN_ORDER':
		case 'END_ORDER': {
			return null;
		}
		case 'CANCEL_ORDER_FULFILLMENT':
		case 'CANCEL_ORDER_START': {
			return {
				currentStatus: 'SOLD',
				targetStatus: 'IN_USE',
			};
		}
		default: {
			return switchUnreachable(action);
		}
	}
};

export const getProductReturnProps = (
	product: OrderProduct,
	endDate: string,
	rentCancelled?: boolean,
) => {
	const updatedSetProducts = product.setProducts.map((setP) => {
		if (!setP.returnedDate && !setP.removedFromParent) {
			const updatedSetProduct = {
				...setP,
				returnedDate: endDate,
			};
			return updatedSetProduct;
		}
		return setP;
	});
	const updatedEndDate = product.endDateReturned ? product.endDateReturned : endDate;
	return {
		...(rentCancelled && { cancelled: true }),
		endDateReturned: updatedEndDate,
		setProducts: updatedSetProducts,
		...(product.subscription
			? { subscription: endOrderProductSubscription(product.subscription) }
			: undefined),
	};
};

export const getProductUnreturnProps = (product: OrderProduct) => {
	const updatedSetProducts = product.setProducts.map((setP) => {
		if (setP.returnedDate) {
			const { returnedDate, ...updatedSetProduct } = setP;
			return updatedSetProduct;
		}
		return setP;
	});
	return {
		endDateReturned: null,
		setProducts: updatedSetProducts,
	};
};

// TODO TOOMAS: REMOVE AND REWRITE LOGIC THAT USES THESE TO USE FUNCTIONS ABOVE
export const getProductWithUpdatedReturnTimes = (
	product: OrderProduct,
	endDate: string,
): Partial<OrderProduct> => {
	const updatedSetProducts = product.setProducts.map((setP) => {
		if (!setP.returnedDate && !setP.removedFromParent) {
			const updatedSetProduct = {
				...setP,
				returnedDate: endDate,
			};
			return updatedSetProduct;
		}
		return setP;
	});
	const updatedEndDate = product.endDateReturned ? product.endDateReturned : endDate;
	return {
		endDateReturned: updatedEndDate,
		setProducts: updatedSetProducts,
	};
};

export const removeEndTimesFromProduct = (product: OrderProduct): Partial<OrderProduct> => {
	const updatedSetProducts = product.setProducts.map((setP) => {
		if (setP.returnedDate) {
			const { returnedDate, ...updatedSetProduct } = setP;
			return updatedSetProduct;
		}
		return setP;
	});
	return {
		...product,
		endDateReturned: null,
		setProducts: updatedSetProducts,
	};
};

export const updateProductTimeOnBookingStart = (
	product: OrderProduct,
	opts: {
		newStartTime: string | undefined;
		openingHours: OpeningHours;
		timezone: string;
	},
): Partial<OrderProduct> => {
	const { newStartTime, openingHours, timezone } = opts;
	const { rentalDurationInSeconds, durationType, durationName } = product;
	const productDuration: Duration = {
		durationInSeconds: rentalDurationInSeconds || 0,
		durationType: durationType || '24h',
		durationName: durationName || null,
	};
	const productEndDate = product.endDate;
	const newEndTime = !newStartTime
		? undefined
		: getNewEndTime(newStartTime, productDuration, openingHours, timezone) || productEndDate;

	const updatedSetProducts = product.setProducts.map((setP) => {
		if (setP.returnedDate) {
			const { returnedDate, ...updatedSetProduct } = setP;
			return updatedSetProduct;
		}
		return setP;
	});
	return {
		endDateReturned: null,
		...(!!newStartTime && { startDate: newStartTime }),
		...(!!newEndTime && { endDate: newEndTime }),
		setProducts: updatedSetProducts,
	};
};

export const updateStartTimeForProducts = (products: OrderProduct[], startTime: string) => {
	const updatedProducts = products.map((product) => {
		const updatedSetProducts = product.setProducts.map((setP) => {
			const updatedSetP = {
				...setP,
				startDate: startTime,
			};
			return updatedSetP;
		});
		return {
			...product,
			setProducts: updatedSetProducts,
			startDate: startTime,
		};
	});
	return updatedProducts;
};

export const updateEndTimeForProducts = (
	products: OrderProduct[],
	startTime: string,
	openingHours: OpeningHours,
	timezone: string,
) => {
	const updatedProducts = products.map((product) => {
		const { rentalDurationInSeconds, durationType, durationName } = product;
		const productDuration: Duration = {
			durationInSeconds: rentalDurationInSeconds || 0,
			durationType: durationType || '24h',
			durationName: durationName || null,
		};
		const productEndDate = product.endDate;
		const newProductEndDate =
			getNewEndTime(startTime, productDuration, openingHours, timezone) || productEndDate;
		const updatedSetProducts = product.setProducts.map((setP) => {
			const updatedSetP = {
				...setP,
				endDate: newProductEndDate,
			};
			return updatedSetP;
		});
		return {
			...product,
			setProducts: updatedSetProducts,
			endDate: newProductEndDate,
		};
	});
	return updatedProducts;
};

export const getNewProductsAndShoppers = (
	productObjects: OrderProduct[],
	returnedShoppers: Shopper[],
	latestBarCodeString: string,
	oldProducts: OrderProduct[],
) => {
	let products: OrderProduct[] = [...oldProducts];
	let shoppers: Shopper[] = [];
	if (productObjects) {
		const productObjectWithCode = productObjects.filter(
			(product) => product.productCode && product.productCode.toUpperCase() === latestBarCodeString,
		);
		if (productObjectWithCode.length > 0) {
			const productCodeShopper = productObjectWithCode
				.filter((product) => oldProducts.every((oldProduct) => oldProduct.id !== product.id))
				.map((product) => product.shopperId);
			const shopperAlreadyScanned = shoppers.some(
				(shopper) => shopper.id === productCodeShopper[0],
			);
			const returnProductObject = !shopperAlreadyScanned
				? productObjects.filter((product) => product.shopperId === productCodeShopper[0])
				: productObjectWithCode;
			products = [...returnProductObject];
			const newShoppers =
				!shopperAlreadyScanned &&
				returnedShoppers.filter((shopper) => shopper.id === productCodeShopper[0]);
			if (newShoppers) {
				shoppers = [...newShoppers];
			}
		} else {
			const productObjectWithCode = productObjects.filter((product) =>
				product.setProducts.some(
					(setProduct) =>
						!!setProduct.productCode &&
						setProduct.productCode.toUpperCase() === latestBarCodeString,
				),
			);
			const productCodeShopper = productObjectWithCode
				.filter((product) => products.every((oldProduct) => oldProduct.id !== product.id))
				.map((product) => product.shopperId);
			const shopperAlreadyScanned = shoppers.some(
				(shopper) => shopper.id === productCodeShopper[0],
			);
			const returnProductObject = !shopperAlreadyScanned
				? productObjects.filter((product) => product.shopperId === productCodeShopper[0])
				: productObjectWithCode;
			products = [...returnProductObject];
			const newShoppers =
				!shopperAlreadyScanned &&
				returnedShoppers.filter((shopper) => shopper.id === productCodeShopper[0]);
			if (newShoppers) {
				shoppers = [...newShoppers];
			}
		}
	}
	return { products, shoppers };
};

export const getShopperNameFromShopper = (shopper: Shopper | ResponsiblePersonDetails): string =>
	`${shopper.firstName}${shopper.lastName && ` ${shopper.lastName}`}`.trim();

export const getShopperNames = (
	shoppers: Shopper[],
	responsiblePerson: ResponsiblePerson,
): string[] =>
	(responsiblePerson.external ? [getShopperNameFromShopper(responsiblePerson.person)] : []).concat(
		sortShoppers(shoppers, responsiblePerson).map((shopper) => getShopperNameFromShopper(shopper)),
	);

export const getShopperNameKeywordsFromShopper = (
	shopper: Shopper | ResponsiblePersonDetails,
): string[] =>
	[shopper.firstName, shopper.lastName, `${shopper.firstName} ${shopper.lastName}`]
		.map((keyword) => keyword.trim().toLowerCase())
		.filter((keyword) => Boolean(keyword));

export const getShopperNameKeywords = (
	shoppers: Shopper[],
	responsiblePerson: ResponsiblePerson,
): string[] => [
	...new Set(
		(responsiblePerson.external
			? getShopperNameKeywordsFromShopper(responsiblePerson.person)
			: []
		).concat(
			sortShoppers(shoppers, responsiblePerson).flatMap((shopper) =>
				getShopperNameKeywordsFromShopper(shopper),
			),
		),
	),
];

export const sortShoppers = (shoppers: Shopper[], responsiblePerson: ResponsiblePerson) => {
	const sortedShoppersAlphabetically: Shopper[] = [...shoppers].sort((a, b) =>
		a.firstName.localeCompare(b.firstName),
	);

	return !responsiblePerson.external
		? [...sortedShoppersAlphabetically].sort((a, b) =>
				a.id === responsiblePerson.shopperId ? -1 : b.id === responsiblePerson.shopperId ? 1 : 0,
		  )
		: sortedShoppersAlphabetically;
};

export const getUpdatedProductsForProductReturn = ({
	productIdsToReturn,
	setProductsToReturn,
	products,
}: {
	productIdsToReturn: string[];
	setProductsToReturn: SetProductWithParentId[];
	products: OrderProduct[];
}) => {
	const updatedParentsOfSetProductsToBeReturned = getUpdatedParentsOfSetProductsToBeReturned(
		setProductsToReturn,
		products,
	);
	const updatedProducts = getUpdatedProductsForReturning(
		productIdsToReturn,
		updatedParentsOfSetProductsToBeReturned.changes,
		products,
	);
	return updatedProducts;
};

export const getUpdatedRentalInfoForProductReturn = (
	products: OrderProduct[],
): Partial<OrderInfo> => {
	let updatedRentalInfo: Partial<OrderInfo> = {};
	if (allProductsReturned(products)) {
		const endDate = new Date().toISOString();
		const activeState: ActiveState = 'RENT_ENDED';
		const rentalState: RentalState = 'COMPLETED';
		updatedRentalInfo = {
			returnTimeNext: null,
			endDateReturned: endDate,
			activeState,
			rentalState,
			includedProductTypes: getUniqProductTypes(products),
		};
	} else {
		const unReturnedProducts = products.filter((p) => !p.endDateReturned);
		const updatedNextProductReturnTime = getNextProductReturnTime(unReturnedProducts);
		const nextBillingDate = getNextInstalmentFromProducts(unReturnedProducts)?.date ?? null;
		updatedRentalInfo = {
			returnTimeNext: updatedNextProductReturnTime,
			subscription: {
				nextBillingDate,
			},
		};
	}
	return updatedRentalInfo;
};

export const getUniqProductTypes = (products: OrderProduct[]): ProductType[] =>
	uniq(products.map((p) => p.type).filter(notUndefined));

export const getUniqPurchaseTypes = (products: OrderProduct[]): PurchaseType[] =>
	uniq(products.map((p) => p.purchaseType).filter(notUndefined));

export const getUniqueProducts = (
	products: OrderProduct[],
	stockProductsMap: { [productId: string]: ProductApi },
): ProductApi[] => {
	const productIds = products.flatMap((orderProduct) => {
		return orderProduct.productApiId;
	});

	const uniqueProducts = uniq(productIds)
		.map((id) => stockProductsMap[id])
		.filter(notUndefined);

	return uniqueProducts;
};

export const variantAmountsFromProducts = (products: OrderProduct[]): Dictionary<string[]> => {
	const allVariantIds = products.flatMap((product) => product.summary.variantIds);
	const groupedVariantIds = groupBy(allVariantIds);
	return groupedVariantIds;
};

export const getPurchaseTypesFromProducts = (products: OrderProduct[]) =>
	uniq(products.map((p) => p.purchaseType));

export const getPurchaseTypeFromProducts = (products: OrderProduct[]) => {
	const purchaseTypes = getPurchaseTypesFromProducts(products);
	return purchaseTypes.length === 1 ? purchaseTypes[0] : null;
};
