import React from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';

import CenteredContent from 'components/CenteredContent';

import NavLocation from './NavLocation';

interface Props {
	isMobile?: boolean;
	borderBottom?: boolean;
	hideLocation?: boolean;
}
const NavTopContent = (props: Props) => {
	const { isMobile, borderBottom, hideLocation = false } = props;
	const classes = useStyles();
	const onlineLocations = useSelector(ShopSelectors.onlineLocations);
	const showLocationSelector = onlineLocations.length > 1 && !hideLocation;

	return (
		<CenteredContent className={borderBottom ? classes.borderBottom : ''}>
			{isMobile && !showLocationSelector ? null : isMobile ? (
				<Box py={1.5}>
					<NavLocation variant="link" isMobile={!!isMobile} ellipsize />
				</Box>
			) : null}
		</CenteredContent>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		borderBottom: {
			borderBottom: `1px solid ${theme.palette.border.paper}`,
		},
	}),
);

export default NavTopContent;
