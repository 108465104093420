import React, { useMemo, useState } from 'react';

import { Box, ButtonBase, Collapse, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiArrowDownSLine, RiArrowUpSLine, RiQuestionLine } from 'react-icons/ri';
import { useSelector } from 'redux/hooks';
import * as ConfirmSelectors from 'redux/selectors/confirm';
import * as ShopSelectors from 'redux/selectors/shop';

import Divider from 'common/components/Divider';
import { PriceBreakdown } from 'common/components/molecules/PriceBreakdown';
import { isSubscriptionPurchaseType } from 'common/modules/orders';
import {
	allOrderProductsHaveAutoRenewSubscription,
	getRecurringSubscriptionPriceFromProducts,
} from 'common/modules/subscriptions';
import { OrderObject } from 'common/types';
import { isRunInIframe } from 'common/utils/browserUtils';
import { getPricingString } from 'common/utils/common';
import { isKeycardProduct } from 'common/utils/liftTicketUtils';
import CartDeliveryRow from 'components/Cart/CartDeliveryRow';
import CartProductsRows from 'components/Cart/CartProductsRows';
import DepositInfoModal from 'components/DepositInfoModal';
import ShopInfo from 'components/ShopInfo';
import { useTranslation } from 'services/localization';
import { orderProductsToCartRows } from 'utils/cart';

interface Props {
	order: OrderObject;
	collapsible?: boolean;
}
const BookingSummary = (props: Props) => {
	const classes = useStyles();
	const { t, language: lang } = useTranslation();
	const { order } = props;
	const { orderDelivery } = order;

	const [expanded, setExpanded] = useState(false);
	const [depositModalOpen, setDepositModalOpen] = useState<boolean>(false);

	const currency = useSelector(ShopSelectors.shopCurrency);

	const earliestStartDate = useSelector(ConfirmSelectors.startDate);
	const latestEndDate = useSelector(ConfirmSelectors.endDate);

	const rows = useMemo(() => {
		const nonKeycardProducts = order.products.filter((p) => !isKeycardProduct(p));
		return orderProductsToCartRows(nonKeycardProducts);
	}, [order.products]);

	const { pricing } = order.rentalInfo;
	const deposit = pricing.deposit?.value;

	const subscriptionOrderProducts = order.products.filter((product) =>
		isSubscriptionPurchaseType(product.purchaseType),
	);
	const totalRecurringPrice = getRecurringSubscriptionPriceFromProducts(subscriptionOrderProducts);
	const hasOnlyAutoRenewOrderProducts = allOrderProductsHaveAutoRenewSubscription(order.products);

	return (
		<>
			{props.collapsible && (
				<ButtonBase className={classes.expandButton} onClick={() => setExpanded((prev) => !prev)}>
					<Typography variant="body2" align="left">
						{t('common:actions.showOrderSummary', 'Show order summary')}
						{expanded ? <RiArrowUpSLine size={20} /> : <RiArrowDownSLine size={20} />}
					</Typography>
					<Typography variant="body2">{getPricingString(pricing.total, currency)}</Typography>
				</ButtonBase>
			)}
			<Collapse in={!props.collapsible || expanded} appear={false}>
				<Box className={classes.wrapper}>
					<Box className={classes.shopInfo}>
						<ShopInfo
							shopLocationId={order.rentalInfo.startLocation.id}
							showBusinessId={isRunInIframe()}
							showLocationName
						/>
					</Box>
					<Divider />
					<Box>
						<CartProductsRows rows={rows} readOnly innerPadding={2} hideStartDateExpired />
					</Box>
					{!!orderDelivery && (
						<>
							<Divider />
							<CartDeliveryRow
								innerPadding={2}
								delivery={orderDelivery}
								startDate={earliestStartDate}
								endDate={latestEndDate}
								hideSelfReturn={hasOnlyAutoRenewOrderProducts}
							/>
						</>
					)}
					{!!deposit && (
						<>
							<Divider />
							<Box className={classes.deposit}>
								<ButtonBase onClick={() => setDepositModalOpen(true)}>
									<Typography variant="body2">
										{`+ ${t('summary.securityDeposit', 'Security deposit')}, ${getPricingString(
											deposit,
											currency,
										)}`}
									</Typography>
									<Box
										display="inline"
										sx={{ ml: 1, color: (theme) => theme.palette.text.secondary }}
									>
										<RiQuestionLine color="inherit" size={24} />
									</Box>
								</ButtonBase>
								<DepositInfoModal
									isOpen={depositModalOpen}
									onClose={() => setDepositModalOpen(false)}
									depositAmount={deposit}
								/>
							</Box>
						</>
					)}
					<Divider />
					<Box p={2}>
						<PriceBreakdown
							pricing={pricing}
							shopCurrency={currency}
							t={t}
							rows={[
								'manualDiscount',
								'discountCodes',
								'subtotal',
								'taxes',
								'total',
								'includedTax',
								'recurring',
							]}
							lang={lang}
							recurring={totalRecurringPrice}
						/>
					</Box>
				</Box>
			</Collapse>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			border: '1px solid #dcdcdc',
		},
		shopInfo: {
			padding: theme.spacing(2),
		},
		deposit: {
			padding: theme.spacing(1, 2),
		},
		expandButton: {
			background: '#f2f2f2',
			borderRadius: 4,
			padding: theme.spacing(2),
			marginBottom: theme.spacing(2),
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
	}),
);

export default BookingSummary;
