import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as ViewSelectors from 'redux/selectors/view';

import ticketPlaceholder from 'common/assets/ticket-placeholder.png';
import { getTranslation } from 'common/modules/translations';
import { ProductApi } from 'common/types';
import { getProductImageSrc } from 'common/utils/productUtils';
import { useTranslation } from 'services/localization/useTranslation';
import { useRoutes } from 'services/routing/useRoutes';

interface Props {
	packageProduct: ProductApi;
	onClick?: () => void;
}

const TicketsPackageGridItem = React.memo((props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { getPath } = useRoutes();
	const lang = useSelector(ViewSelectors.language);

	return (
		<Link
			className={classes.wrapper}
			to={getPath(`packageTicket`, { params: { productId: props.packageProduct.id } })}
			onClick={props.onClick}
			key={props.packageProduct.id}
		>
			<Box className={classes.imageWrapper}>
				<Box className={classes.imageInnerWrapper}>
					<img
						src={getProductImageSrc(props.packageProduct) ?? ticketPlaceholder}
						className={classes.image}
						alt=""
					/>
				</Box>
			</Box>
			<Box py={2}>
				<Typography variant="body1" gutterBottom className={classes.productName}>
					{getTranslation(props.packageProduct.name, lang)}
				</Typography>
				<Typography variant="body2" color="textSecondary">
					{t('tickets.packageLiftTicket', 'Package lift ticket')}
				</Typography>
			</Box>
		</Link>
	);
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'block',
			[theme.breakpoints.up('md')]: {
				marginBottom: theme.spacing(2),
			},
			'&:hover *': {
				textDecoration: 'underline',
			},
			'&:hover img': {
				opacity: 0.85,
			},
			'&:focus img': {
				opacity: 0.85,
			},
		},
		imageWrapper: {
			width: '100%',
			padding: theme.spacing(2),
			border: '1px solid #f8f8f8',
		},
		imageInnerWrapper: {
			width: '100%',
			paddingTop: '100%',
			position: 'relative',
		},
		image: {
			width: '100%',
			height: '100%',
			position: 'absolute',
			top: 0,
			left: 0,
			objectPosition: 'center',
			objectFit: 'contain',
		},
		productName: {
			fontWeight: 500,
		},
	}),
);

export default TicketsPackageGridItem;
