import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Container, Grid, Icon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { updateShopperIdsWithDTAImage } from 'redux/actions/confirm';
import * as ConfirmSelectors from 'redux/selectors/confirm';
import * as NavSelectors from 'redux/selectors/nav';
import * as ViewSelectors from 'redux/selectors/view';

import { ReactComponent as CloseIcon } from 'common/assets/icons/icon-close-outline.svg';
import { ReactComponent as PersonIcon } from 'common/assets/icons/icon-person-solid.svg';
import Spinner from 'common/components/Spinner';
import { USER_DIDNT_INPUT_PHOTO } from 'common/modules/skidata';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { ShopperIdsWithDTAImage } from 'common/types';
import ImageTools from 'common/utils/ImageTools';
import {
	getDTAContactImageData,
	getDataURLFromDTAContactImageData,
	hasLiftTicketProducts,
} from 'common/utils/liftTicketUtils';
import NoPhotoDialog from 'components/NoPhotoDialog';
import { useTranslation } from 'services/localization/useTranslation';

import ShopperHeaderBar from './ShopperNameStepper';
import { photoBack, photoContinue } from './shopperInfoNavigation';

const ShopperPhoto = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [dialogOpen, setDialogOpen] = useState(false);
	const [uploadingPhoto, setUploadingPhoto] = useState(false);
	const [error, setError] = useState<string>('');

	const shopName = useSelector(NavSelectors.shopName);
	const locationName = useSelector(NavSelectors.locationName);
	const shopperIdsWithDTAImage = useSelector(ConfirmSelectors.shopperIdsWithDTAImage);
	const activeShopperIndex = useSelector(ViewSelectors.activeShopperIndex);
	const order = useSelector(ConfirmSelectors.orderData_FORCE);
	const { products, shoppers } = order;

	const shopper = shoppers.find((s, i) => i === activeShopperIndex)!;
	const activeShopperDTAImage = shopperIdsWithDTAImage?.[shopper.id];
	const shopperProducts = products.filter((p) =>
		shopper.productIds.some((activeId) => activeId === p.id),
	);

	const handleContinueButton = useCallback(() => {
		photoContinue({
			shopName,
			locationName,
			shoppers,
			products: shopperProducts,
		});
	}, [locationName, shopName, shopperProducts, shoppers]);

	useEffect(() => {
		if (!hasLiftTicketProducts(shopperProducts)) {
			handleContinueButton();
		}
	}, [handleContinueButton, products.length, shopName, shopperProducts]);

	const handleBackButton = useCallback(() => {
		photoBack({ shopName, shoppers, products, activeShopperIndex, locationName });
	}, [shopName, shoppers, products, activeShopperIndex, locationName]);

	const getThumbImage = (file: File): Promise<Blob> =>
		new Promise((resolve) =>
			ImageTools.resize(file, { width: 500, height: 500 }, (blob: any, _didItResize: boolean) =>
				resolve(blob),
			),
		);

	const handleAddPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			try {
				setUploadingPhoto(true);
				// Skidata supports only jpeg, bitmap formats
				if (!file.type.match('image/jpeg|image/bmp')) {
					setError(t('shopperInfo.wrongImageFormat', 'Wrong photo format'));
					setUploadingPhoto(false);
					return;
				}
				const thumbImage = await getThumbImage(file);
				const dtaImage = await getDTAContactImageData(thumbImage);
				const updatedShopperIdsWithDTAImage: ShopperIdsWithDTAImage = {
					...shopperIdsWithDTAImage,
					[shopper.id]: dtaImage,
				};
				dispatch(updateShopperIdsWithDTAImage(updatedShopperIdsWithDTAImage));
				setUploadingPhoto(false);
			} catch (e) {
				errorHandler.report(e);
				setError(
					t(
						'shopperInfo.photoUploadFailed',
						'Something went wrong uploading the photo, please try again.',
					),
				);
				setUploadingPhoto(false);
			}
		}
	};

	const handleRemovePhoto = () => {
		if (!shopperIdsWithDTAImage) {
			return;
		}
		const { [shopper.id]: _, ...updatedShopperIdsWithDTAImage } = shopperIdsWithDTAImage;
		dispatch(updateShopperIdsWithDTAImage(updatedShopperIdsWithDTAImage));
	};

	const handleContinueWithoutPhoto = () => {
		const updatedShopperIdsWithDTAImage: ShopperIdsWithDTAImage = {
			...shopperIdsWithDTAImage,
			[shopper.id]: USER_DIDNT_INPUT_PHOTO,
		};
		dispatch(updateShopperIdsWithDTAImage(updatedShopperIdsWithDTAImage));
		handleContinueButton();
	};

	const renderShopperPhotoContent = () => (
		<>
			<Box>
				<Typography variant="body1" gutterBottom>
					{t('shopperInfo.yourPhoto', 'Your photo')}
				</Typography>
				<Typography variant="body2">
					{t('shopperInfo.shopperPhotoDescription', 'Upload a photo that clearly shows your face.')}
				</Typography>
				<Typography variant="body2" gutterBottom>
					{t('shopperInfo.supportedFormat', ' Supported format: jpeg, bmp.')}
				</Typography>
				<Grid container alignItems="center" wrap="nowrap" className={classes.uploadPhotoContainer}>
					<Grid item>
						{activeShopperDTAImage && activeShopperDTAImage !== USER_DIDNT_INPUT_PHOTO ? (
							<Box
								style={{
									backgroundImage: `url(${getDataURLFromDTAContactImageData(
										activeShopperDTAImage,
									)})`,
								}}
								className={classes.shopperPhoto}
							/>
						) : (
							<Icon className={classes.imageWrapper}>
								<PersonIcon width={40} color="#d8d8d8" />
							</Icon>
						)}
					</Grid>
					<Grid item container alignItems="center" className={classes.uploadBtnWrapper}>
						{error ? (
							<Box className={classes.errorBox}>
								<Box>
									<Typography variant="body2" color="error">
										{t('shopperInfo.failToUpload', 'Upload failed')}
									</Typography>
									<Typography variant="body2" color="error">
										{error}
									</Typography>
								</Box>
								<Icon className={classes.closeBtn} onClick={() => setError('')}>
									<CloseIcon width={10} />
								</Icon>
							</Box>
						) : (
							<>
								<Button
									variant="outlined"
									color="primary"
									component="label"
									size="small"
									fullWidth
									className={classes.uploadBtn}
									disabled={uploadingPhoto}
								>
									{uploadingPhoto ? (
										<Spinner size={24} />
									) : (
										t('shopperInfo.uploadPhoto', 'Upload photo')
									)}
									<input
										onChange={handleAddPhoto}
										style={{ display: 'none' }}
										type="file"
										accept="image/jpeg, image/bmp"
									/>
								</Button>
								{activeShopperDTAImage && activeShopperDTAImage !== USER_DIDNT_INPUT_PHOTO && (
									<Button
										variant="text"
										color="secondary"
										size="small"
										fullWidth
										className={classes.uploadBtn}
										disabled={uploadingPhoto}
										onClick={handleRemovePhoto}
									>
										{t('shopperInfo.removePhoto', 'Remove photo')}
									</Button>
								)}
							</>
						)}
					</Grid>
				</Grid>
			</Box>
			<Typography
				variant="body2"
				className={classNames(
					classes.continueWithoutPhoto,
					uploadingPhoto && classes.continueWithoutPhotoDisabled,
				)}
				onClick={() => setDialogOpen(true)}
			>
				{t('shopperInfo.continueWithoutPhoto', `Continue without a photo?`)}
			</Typography>
			<NoPhotoDialog
				open={dialogOpen}
				handleContinue={handleContinueWithoutPhoto}
				onClose={() => setDialogOpen(false)}
			/>
		</>
	);

	return (
		<Container maxWidth="sm">
			<ShopperHeaderBar />
			<div>
				<Typography className={classes.smallSpacingBottom} variant={'h5'}>
					{shopper.firstName + ','}
				</Typography>
				<Typography className={classes.spacingBottom} variant={'h6'}>
					{t(
						'shopperInfo.shopperPhotoHeader',
						'To identify you at the gates we need the following details',
					)}
				</Typography>
				{renderShopperPhotoContent()}
			</div>
			<div className={classes.buttonContainer}>
				<Button
					variant="contained"
					color="primary"
					className={classes.btn}
					onClick={handleContinueButton}
					disabled={
						!!error || !activeShopperDTAImage || activeShopperDTAImage === USER_DIDNT_INPUT_PHOTO
					}
				>
					{t('common:actions.continue')}
				</Button>
				<Button
					variant="outlined"
					color="primary"
					className={classes.btn}
					onClick={handleBackButton}
				>
					{t('common:actions.back')}
				</Button>
			</div>
		</Container>
	);
};

const useStyles = makeStyles((theme) => ({
	smallSpacingBottom: {
		marginBottom: theme.spacing(1),
	},
	spacingBottom: {
		marginBottom: theme.spacing(4),
	},
	continueWithoutPhoto: {
		marginTop: theme.spacing(2),
		textTransform: 'uppercase',
		textDecoration: 'underline',
		fontWeight: 500,
		color: theme.palette.text.secondary,
		cursor: 'pointer',
	},
	continueWithoutPhotoDisabled: {
		color: theme.palette.text.secondary,
		cursor: 'default',
		pointerEvents: 'none',
	},
	buttonContainer: {
		marginTop: 32,
		margin: 'auto',
		textAlign: 'center',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'row-reverse',
		},
	},
	btn: {
		margin: '8px auto',
		paddingTop: 12,
		paddingBottom: 12,
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '40%',
			margin: 0,
		},
	},
	uploadPhotoContainer: {
		marginTop: theme.spacing(2),
	},
	imageWrapper: {
		display: 'flex',
		justifyContent: 'center',
		width: 64,
		height: 64,
		backgroundColor: theme.palette.background.secondary,
	},
	uploadBtnWrapper: {
		marginLeft: theme.spacing(3),
	},
	uploadBtn: {
		maxWidth: 240,
		minHeight: 28,
		'&:not(:last-child)': {
			marginBottom: theme.spacing(1),
		},
	},
	shopperPhoto: {
		width: 64,
		height: 64,
		backgroundColor: theme.palette.background.secondary,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		marginRight: theme.spacing(1.5),
	},
	errorBox: {
		maxWidth: 240,
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	closeBtn: {
		cursor: 'pointer',
	},
}));

export default ShopperPhoto;
