import React from 'react';

import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { RiArrowDownSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';

import { isRunInIframe } from 'common/utils/browserUtils';
import { notUndefined } from 'common/utils/common';
import LocationName from 'components/LocationName';
import { useRoutes } from 'services/routing/useRoutes';

interface Props {
	variant: 'text' | 'link' | 'menu';
	isMobile: boolean;
	ellipsize?: boolean;
}

const NavLocation = ({ variant, isMobile, ellipsize }: Props) => {
	switch (variant) {
		case 'menu':
			return <Menu />;
		case 'link':
		case 'text':
			return <TextLink isMobile={isMobile} link={variant === 'link'} ellipsize={ellipsize} />;
		default:
			return null;
	}
};

const Menu = () => {
	const { getPath, Routes } = useRoutes();
	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const locationNameString = [activeLocation?.name, activeLocation?.city]
		.filter(notUndefined)
		.join(', ');
	return (
		<Link to={getPath(Routes.locations)}>
			<Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
				<Typography variant="body2">{locationNameString}</Typography>
				<Box mr={0.5} />
				<RiArrowDownSLine size={20} />
			</Box>
		</Link>
	);
};

interface TextLinkProps {
	isMobile: boolean;
	link: boolean;
	ellipsize?: boolean;
}

const TextLink = ({ link, isMobile, ellipsize }: TextLinkProps) => {
	const classes = useStyles();
	const isEmbed = isRunInIframe();
	const embedMobile = isEmbed && isMobile;
	const showIcon = !isMobile;

	return (
		<LocationName
			classes={{
				locationName: classNames({
					[classes.mobileLocation]: embedMobile,
					[classes.ellipsize]: ellipsize,
				}),
			}}
			textVariant={isMobile || isEmbed ? 'body2' : 'body1'}
			isLink={link}
			showCity
			showIcon={showIcon}
			justify={isMobile ? 'center' : 'flex-start'}
		/>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mobileLocation: {
			textDecoration: 'underline',
		},
		ellipsize: {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	}),
);

export default NavLocation;
