import { createAction } from '@reduxjs/toolkit';

import {
	DeliveryOption,
	DeliveryTimeSlot,
	Languages,
	PhoneObject,
	UnitSystemType,
} from 'common/types';
import { CheckoutFormErrors } from 'hooks/useCheckoutForm';
import { ProductType } from 'services/types';

export const setLanguage = createAction<Languages>('View/SET_LANGUAGE');
export const setCartOpen = createAction<boolean>('View/SET_CART_OPEN');
export const toggleCartOpen = createAction('View/TOGGLE_CART_OPEN');

export const setFilterCategory = createAction<string | null>('View/SET_FILTER_CATEGORY');
export const setFilterProductType = createAction<ProductType | null>(
	'View/SET_FILTER_PRODUCT_TYPE',
);
export const setFiltersOpen = createAction<boolean>('View/SET_FILTERS_OPEN');

export const setSidebarOpen = createAction<boolean>('View/SET_SIDEBAR_OPEN');

export const setActiveShopperIndex = createAction<number>('View/SET_ACIVE_SHOPPER_INDEX');

export const setActiveUnitSystem = createAction<UnitSystemType | null>(
	'View/SET_ACTIVE_UNIT_SYSTEM',
);

export const setCheckoutFormErrors = createAction<CheckoutFormErrors>(
	'View/SET_CHECKOUT_FORM_ERRORS',
);

export const setPhoneObject = createAction<PhoneObject>('View/SET_PHONE_OBJECT');

export const showReservationExpiredBanner = createAction<boolean>(
	'View/SHOW_RESERVATION_EXPIRED_BANNER',
);

export const setDeliveryOption = createAction<DeliveryOption | null>('View/SET_DELIVERY_OPTION');

export const setAvailablePickupSlots = createAction<DeliveryTimeSlot[] | null>(
	'View/SET_AVAILABLE_PICKUP_SLOTS',
);

export const setPickupChecked = createAction<boolean>('View/SET_PICKUP_CHECKED');
