import React from 'react';

import { Box } from '@mui/material';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';

import Select from 'common/components/pickers/Select';
import { getPricingString, getPricingStringPerDuration } from 'common/utils/common';
import {
	getDescriptionForDurationWithPrice,
	getLabelForDurationWithPrice,
} from 'common/utils/pricing';
import ErrorText from 'components/ErrorText';
import { DurationOptionWithId } from 'hooks/useDurationOptions';
import { useTranslation } from 'services/localization';

interface Props {
	options: DurationOptionWithId[];
	value: string | null;
	onChange: (value: string) => void;
	quantity: number;
	priceIncrease?: number | null;
	error?: string | null;
	disabled?: boolean;
	isSubscription?: boolean;
}

const DurationSelector = React.memo((props: Props) => {
	const {
		options,
		value,
		onChange,
		error,
		disabled,
		priceIncrease,
		quantity,
		isSubscription,
	} = props;
	const { t, language } = useTranslation();
	const currency = useSelector(ShopSelectors.shopCurrency);

	const getRentalPrice = (option: DurationOptionWithId) => {
		return getPricingString((option.option.price + (priceIncrease || 0)) * quantity, currency);
	};

	const getSubscriptionPrice = (option: DurationOptionWithId) => {
		return getPricingStringPerDuration(getPricingString(option.option.price, currency), 'month', t);
	};

	return (
		<Box>
			<Select.Single
				label=""
				emptyLabel={t('common:prompts.chooseDuration', 'Choose duration')}
				value={value}
				hideEmptyOption
				indicators="none"
				onChange={(value) => value && onChange(value)}
				disabled={disabled}
				options={options.map((option) => ({
					id: option.id,
					label: getLabelForDurationWithPrice(option.option, language, t),
					description: getDescriptionForDurationWithPrice(option.option, t) ?? '',
					rightContent: isSubscription ? getSubscriptionPrice(option) : getRentalPrice(option),
				}))}
				hideDropdown={options.length === 1 && options[0].option.timePeriod === 'fixed'}
			/>
			{error && (
				<Box mt={1}>
					<ErrorText>{error}</ErrorText>
				</Box>
			)}
		</Box>
	);
});

export default DurationSelector;
