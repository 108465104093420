import { ItemListSelection } from 'redux/reducers/analytics';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import { ReduxThunkAction } from 'redux/types';

import { logEcomEvent } from 'common/services/analytics';
import { Category, ProductApi } from 'common/types';
import { centsToMajorUnits } from 'common/utils/common';
import { isLiftTicketCategory } from 'common/utils/liftTicketUtils';
import { removeNonNumeric } from 'common/utils/math';
import { getLowestPriceFromStockProduct } from 'common/utils/pricing';
import {
	getAnalyticsItemFromLiftTicket,
	getAnalyticsItemFromStockProduct,
	getItemListInfo,
	getRecommendedItemListInfo,
} from 'utils/analytics';
import { getLiftTicketsFromPriceString } from 'utils/liftTicket';

import { EcomEventParams, SelectLiftTicketItem, setItemListSelection } from '..';
import { EcomEvents } from '../constants';

const eventName = EcomEvents.select_item;

export const isLiftTicket = (
	params: EcomEventParams[typeof eventName],
): params is SelectLiftTicketItem => {
	return (params as SelectLiftTicketItem).isLiftTicket === true;
};

export const logSelectItem = (params: EcomEventParams[typeof eventName]): ReduxThunkAction => (
	dispatch,
	getState,
) => {
	const state = getState();
	const shopGoogleAnalytics = ShopSelectors.shopGoogleAnalytics(state);
	const categoriesById = StockSelectors.categoriesById(state);
	const productsById = StockSelectors.productsById(state);
	const sortedGridProducts = StockSelectors.sortedProducts(state);
	const currency = ShopSelectors.shopCurrency(state);
	const pricingTables = ShopSelectors.shopPricingTables(state);
	const openingHours = ShopSelectors.activeStoreOpeningHours(state);
	const timezone = ShopSelectors.shopTimezone(state);
	const activeLocation = ShopSelectors.activeLocation(state);
	const liftTicketCategory = StockSelectors.liftTicketCategory(state);
	const liftTicketProducts = StockSelectors.liftTicketProducts(state);
	const selectedCategories = StockSelectors.selectedCategories(state);
	const filteredCategory = StockSelectors.filteredCategory(state);
	const recommendLiftTicket = CartSelectors.recommendLiftTicket(state);
	const recommendedProducts = CartSelectors.recommendedProducts(state);

	const recommendedListWithTicket = !!params.isRecommendedList && recommendLiftTicket;
	const listProducts = !!params.isRecommendedList ? recommendedProducts : sortedGridProducts;
	const { item_list_id, item_list_name } = !!params.isRecommendedList
		? getRecommendedItemListInfo()
		: getItemListInfo(filteredCategory?.id ?? null, categoriesById);

	const getSelectedAnalyticsItem = () => {
		if (isLiftTicket(params)) {
			if (!liftTicketCategory) return undefined;
			const liftTicketFromPrice = getLiftTicketsFromPriceString(liftTicketProducts, currency);
			const itemPosition = !!params.isRecommendedList
				? 1
				: getLiftTicketPosition(selectedCategories, sortedGridProducts) || 0;
			const itemListSelection: ItemListSelection = {
				name: item_list_name,
				id: item_list_id,
				index: itemPosition,
			};
			return getAnalyticsItemFromLiftTicket(liftTicketCategory, {
				priceInMajorUnits: {
					value: removeNonNumeric(liftTicketFromPrice),
					currency: currency.code,
				},
				itemListSelectionById: { [liftTicketCategory.id]: itemListSelection },
				location: activeLocation,
			});
		}
		const product = productsById[params.productId] ?? undefined;
		if (!product) return undefined;
		const price = getLowestPriceFromStockProduct({
			product,
			pricingTables,
			openingHours,
			startDate: null,
			channel: 'ONLINE',
			timezone,
		});
		const afterLiftTicket = recommendedListWithTicket
			? true
			: isAfterLiftTicketInGrid(params.categoryId ?? 'others', selectedCategories);
		const itemPosition =
			listProducts.findIndex((p) => p.id === params.productId) + 1 + (afterLiftTicket ? 1 : 0);
		const itemListSelection: ItemListSelection = {
			name: item_list_name,
			id: item_list_id,
			index: itemPosition,
		};
		return getAnalyticsItemFromStockProduct(product, {
			priceInMajorUnits: { value: centsToMajorUnits(price ?? 0), currency: currency.code },
			itemListSelectionById: { [product.id]: itemListSelection },
			categoriesById,
			location: activeLocation,
		});
	};

	const item = getSelectedAnalyticsItem();
	if (!item) return;
	dispatch(
		setItemListSelection({
			productApiId: item.item_id,
			itemListSelection: {
				name: item_list_name,
				id: item_list_id,
				index: item.index,
			},
		}),
	);
	logEcomEvent(
		{
			name: eventName,
			params: {
				item_list_id,
				item_list_name,
				items: [item],
			},
		},
		shopGoogleAnalytics,
	);
};

const getLiftTicketPosition = (selectedCategories: Category[], sortedProducts: ProductApi[]) => {
	let position = 0;
	for (const category of selectedCategories) {
		const liftTicketCategory = isLiftTicketCategory(category);
		if (liftTicketCategory) {
			position++;
			return position;
		} else {
			const categoryProductsCount = sortedProducts.filter((p) =>
				p.categoryIds?.includes(category.id),
			).length;
			position = position + categoryProductsCount;
		}
	}
	return null;
};

const isAfterLiftTicketInGrid = (categoryId: string, selectedCategories: Category[]) => {
	let liftTicketLooped = false;
	for (const category of selectedCategories) {
		const liftTicketCategory = isLiftTicketCategory(category);
		if (liftTicketCategory) {
			liftTicketLooped = true;
		}
		if (category.id === categoryId) {
			return liftTicketLooped;
		}
	}
	return liftTicketLooped;
};
