import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';

import { getOrderPayments } from 'common/modules/atoms/paymentAmounts';
import { isCustomShopPaymentMethodObject } from 'common/modules/payments/paymentMethods';
import { OrderObject } from 'common/types';
import { getPricingString } from 'common/utils/common';
import { useTranslation } from 'services/localization';

interface Props {
	order: OrderObject;
}
const PaymentInfo = (props: Props) => {
	const classes = useStyles();
	const { t, getTranslation } = useTranslation();

	const { rentalInfo } = props.order;
	const currency = useSelector(ShopSelectors.shopCurrency);
	const isPaid = getOrderPayments(rentalInfo).charge.unpaid <= 0;
	const customPaymentMethod =
		!!rentalInfo.pricing.paymentMethod &&
		isCustomShopPaymentMethodObject(rentalInfo.pricing.paymentMethod)
			? rentalInfo.pricing.paymentMethod
			: null;
	return (
		<Box>
			<Typography variant="body2" gutterBottom>
				{t('common:payment.payment', 'Payment')}
			</Typography>
			{!!customPaymentMethod ? (
				<>
					<Typography variant="subtitle2">
						{getTranslation(customPaymentMethod.details.label)}{' '}
						<span className={classes.semibold}>
							({getPricingString(rentalInfo.pricing.total, currency)})
						</span>
					</Typography>
					<Typography variant="subtitle2">
						{getTranslation(customPaymentMethod.details.description ?? { def: '' })}
					</Typography>
				</>
			) : (
				<Typography variant="subtitle2">
					{isPaid
						? t('common:payment.bookingHasBeenPaid', 'The order has been paid')
						: t(
								'common:payment.bookingPaidOnPickup',
								'The order is paid at the store on pick-up',
						  )}{' '}
					<span className={classes.semibold}>
						({getPricingString(rentalInfo.pricing.total, currency)})
					</span>
				</Typography>
			)}
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		semibold: {
			fontWeight: 500,
		},
	}),
);

export default PaymentInfo;
