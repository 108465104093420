import { matchPath } from 'react-router';

import { Languages } from 'common/types';

import { RentleStoreArgs } from '.';
import { IframeEvent } from './types';
import urls from './urls.json';

/**
 * Sends a message from an iframe to the parent page window
 */
export const sendEventToParentPage = (event: IframeEvent) => {
	if ('parentIFrame' in window) {
		window.parentIFrame?.sendMessage(event);
	}
};

/**
 * Sends a message to the current page window
 */
export const sendEventToCurrentPage = (event: IframeEvent) => {
	if ('postMessage' in window) {
		window.postMessage(event, '*');
	}
};

/**
 * Parse the payload of an incoming message on window
 *
 * @returns An IframeEvent if it was a Rentle-dispatched event, or null if not
 */
export const parseEvent = (event: MessageEvent): IframeEvent | null => {
	return isIframeEvent(event.data) ? event.data : null;
};

export const isIframeEvent = (event: any): event is IframeEvent => {
	return typeof event === 'object' && event?.origin === 'rentle';
};

export const getEmbedBundleUrl = (env: string | undefined): string => {
	if (!env || !urls[env]) return '';

	const { protocol, domain, port, path } = urls[env];
	return `${protocol}://${domain}${port ? `:${port}` : ''}/${path}`;
};

const removeUndefinedProperties = <T extends object>(object: T) => {
	return Object.entries(object).reduce((result, [key, value]) => {
		if (value !== undefined) {
			result[key] = value;
		}
		return result;
	}, {} as T);
};

/** Get the RentleStoreArgs that would result in a given path
 *
 * @param path A path in the online store, for example /shopName/product/123
 * @returns The RentleStoreArgs that would result in the given path
 */
export const getRentleStoreArgsFromPath = (path: string): RentleStoreArgs | null => {
	const getArgs = () => {
		if (!path) return null;
		const prefixedPath = path[0] !== '/' ? `/${path}` : path;
		let [pathname, queryString] = prefixedPath.split('?');
		const queryParams = new URLSearchParams('?' + queryString);

		const baseMatch = matchPath<{ shop: string }>(pathname, {
			path: '/:shop',
			exact: false,
		});

		if (!baseMatch) return null;

		const storeMatch = matchPath<{ store: string }>(pathname, {
			path: '/:shop/l/:store',
			exact: false,
		});

		const baseArgs: RentleStoreArgs = {
			shop: baseMatch.params.shop,
			store: storeMatch?.params.store,
			lang: (queryParams.get('lang') as Languages) ?? undefined,
		};
		const prefix = !!storeMatch ? '/:shop/l/:store' : '/:store';

		const shopMatch = matchPath(pathname, {
			path: [`${prefix}`, `${prefix}/shop`],
			exact: true,
		});

		if (!!shopMatch) {
			return {
				...baseArgs,
				category: queryParams.get('category') ?? undefined,
			};
		}

		const productMatch = matchPath<{ product: string }>(pathname, {
			path: `${prefix}/product/:product`,
			exact: true,
		});

		if (!!productMatch) {
			return {
				...baseArgs,
				product: productMatch.params.product,
			};
		}

		const ticketsMatch = matchPath(pathname, {
			path: `${prefix}/tickets`,
			exact: true,
		});

		if (!!ticketsMatch) {
			return {
				...baseArgs,
				ticket: 'all',
			};
		}

		const packageTicketMatch = matchPath<{ ticket: string }>(pathname, {
			path: `${prefix}/packageticket/:ticket`,
			exact: true,
		});

		if (!!packageTicketMatch) {
			return {
				...baseArgs,
				ticket: packageTicketMatch.params.ticket,
			};
		}

		const locationsMatch = matchPath(pathname, {
			path: `${prefix}/locations`,
			exact: true,
		});

		if (!!locationsMatch) {
			return {
				...baseArgs,
				store: undefined,
				locationview: true,
			};
		}
		return null;
	};

	const args = getArgs();
	return !!args ? removeUndefinedProperties(args) : args;
};

/** Get an online store path from some RentleStoreArgs. This is the reverse of
 * getRentleStoreArgsFromPath.
 *
 * @param args The RentleStoreArgs
 * @returns The path corresponding to the given args
 */
export const getPathFromRentleStoreArgs = (args: RentleStoreArgs): string => {
	if (!args.shop) return '';
	const { shop = '', store, category, product, ticket, lang, locationsview } = args;

	const prefix = !!store ? `/${shop}/l/${store}` : `/${shop}`;

	const path = (() => {
		return locationsview && !category && !store && !product && !ticket
			? '/'
			: !store && !category && !product && !ticket && !locationsview
			? '/'
			: !!product
			? `/product/${product}`
			: !!ticket
			? ticket === 'all'
				? '/tickets'
				: `/packageticket/${ticket}`
			: '/shop';
	})();

	const queryString = (() => {
		const queryParams = new URLSearchParams();

		if (path === '/shop' && !!category) {
			queryParams.set('category', category);
		}

		if (!!lang) {
			queryParams.set('lang', lang);
		}

		return queryParams.toString();
	})();

	return `${prefix}${path}${!!queryString ? `?${queryString}` : ''}`;
};
