import React from 'react';

import { Typography, TypographyProps } from '@mui/material';

interface Props extends TypographyProps {}

const ErrorText = (props: Props) => {
	return <Typography color="error" variant="body2" {...props} data-error="true" />;
};

export default ErrorText;
