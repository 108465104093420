import * as AnalyticsSelectors from 'redux/selectors/analytics';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import { ReduxThunkAction } from 'redux/types';

import { logEcomEvent } from 'common/services/analytics';
import { centsToMajorUnits } from 'common/utils/common';
import { getAnalyticsItemsFromCartItem } from 'utils/analytics';

import { EcomEventParams } from '..';
import { EcomEvents } from '../constants';

const eventName = EcomEvents.view_cart;

export const logViewCart = (_params: EcomEventParams[typeof eventName]): ReduxThunkAction => (
	_,
	getState,
) => {
	const state = getState();
	const shopGoogleAnalytics = ShopSelectors.shopGoogleAnalytics(state);
	const categoriesById = StockSelectors.categoriesById(state);
	const cartPricing = CartSelectors.cartPricing(state);
	const cartItems = CartSelectors.items(state);
	const currency = ShopSelectors.shopCurrency(state);
	const locationId = CartSelectors.cartLocationId(state);
	const locationsById = ShopSelectors.onlineLocationsById(state);
	const itemListSelectionById = AnalyticsSelectors.itemListSelectionById(state);
	logEcomEvent(
		{
			name: eventName,
			params: {
				currency: currency.code,
				value: centsToMajorUnits(cartPricing.subtotal),
				items: cartItems.flatMap((item) =>
					getAnalyticsItemsFromCartItem(item, {
						categoriesById,
						location: locationId ? locationsById[locationId] : undefined,
						itemListSelectionById,
					}),
				),
			},
		},
		shopGoogleAnalytics,
	);
};
