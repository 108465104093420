import React, { useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import * as CheckoutSelectors from 'redux/selectors/checkout';

import { getPricingString } from 'common/utils/common';
import CenteredContent from 'components/CenteredContent';
import { useTranslation } from 'services/localization';

import CheckoutCart from '.';

export const MobileCartWrapper = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const theme = useTheme();

	const [summaryOpen, setSummaryOpen] = useState<boolean>(false);
	const checkoutPricing = useSelector(CheckoutSelectors.checkoutPricing);

	const currency = useSelector(CheckoutSelectors.currency);
	const overSm = useMediaQuery(theme.breakpoints.up('sm'));
	return (
		<>
			<Box className={classes.root}>
				<Button
					className={classes.summaryHeader}
					onClick={() => setSummaryOpen((open) => !open)}
					fullWidth
				>
					<CenteredContent paddingTop={0} innerClassName={classes.header}>
						<Box className={classes.headerTop}>
							<Box className={classes.headerTitle}>
								<Typography variant="body1" align="left">
									{t('common:actions.orderSummary', 'Order summary')}
								</Typography>
								<Box ml={1} display="flex">
									{summaryOpen ? <ExpandLess /> : <ExpandMore />}
								</Box>
							</Box>
							<Typography variant="body1">
								{getPricingString(checkoutPricing.total, currency)}
							</Typography>
						</Box>
					</CenteredContent>
				</Button>
				<Collapse in={summaryOpen} timeout="auto">
					<CheckoutCart hideShopInfo innerPadding={overSm ? 7 : 3} />
				</Collapse>
			</Box>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderBottom: `2px solid ${theme.palette.divider}`,
		},
		header: {
			width: '100%',
		},
		summaryHeader: {
			padding: theme.spacing(2, 0),
			textTransform: 'inherit',
			borderRadius: 0,
			display: 'block',
		},
		headerTop: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
		},
		headerTitle: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			paddingRight: theme.spacing(2),
		},
		headerBottom: {
			paddingTop: theme.spacing(1),
			textAlign: 'left',
		},

		btnLabel: {
			display: 'block',
		},
	}),
);

export default MobileCartWrapper;
