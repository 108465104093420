export const getIsRentleHostname = (hostname: string) => {
	const rentleHostnames = ['rentle.store', 'rentle.shop', 'rentle.io'];
	const endsWithRentleHostname = rentleHostnames.some((rentleHostname) =>
		hostname.endsWith(rentleHostname),
	);
	const appspotWithRentleProject = hostname.endsWith('appspot.com') && hostname.includes('rentle-');
	// We have a specific localhost port that we can use to test custom domain behaviour
	const customDomainLocalhost = 'localhost:4002';
	const isCustomDomainLocalhost =
		typeof window !== 'undefined' && window?.location?.host === customDomainLocalhost;
	const isLocalhost = !isCustomDomainLocalhost && hostname === 'localhost';
	return endsWithRentleHostname || appspotWithRentleProject || isLocalhost;
};
