import * as AnalyticsSelectors from 'redux/selectors/analytics';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import { ReduxThunkAction } from 'redux/types';

import { logEcomEvent } from 'common/services/analytics';
import { centsToMajorUnits } from 'common/utils/common';
import { getAnalyticsItemsFromOrderProducts } from 'utils/analytics';

import { EcomEventParams } from '..';
import { EcomEvents } from '../constants';

const eventName = EcomEvents.purchase;

export const logPurchase = (params: EcomEventParams[typeof eventName]): ReduxThunkAction => (
	_,
	getState,
) => {
	const state = getState();
	const { transactionId } = params;
	const shopGoogleAnalytics = ShopSelectors.shopGoogleAnalytics(state);
	const categoriesById = StockSelectors.categoriesById(state);
	const itemListSelectionById = AnalyticsSelectors.itemListSelectionById(state);
	const appliedDiscountCodes = CheckoutSelectors.appliedDiscountCodes(state);
	const orderProducts = CheckoutSelectors.orderProducts(state);
	const locationsById = ShopSelectors.onlineLocationsById(state);
	const checkoutPricing = CheckoutSelectors.checkoutPricing(state);
	const locationId = CheckoutSelectors.startLocationId(state);

	const location = locationsById[locationId] ?? undefined;
	const coupon = Object.keys(appliedDiscountCodes)[0] ?? undefined;
	const items = getAnalyticsItemsFromOrderProducts(orderProducts, {
		categoriesById,
		location,
		itemListSelectionById,
	});
	logEcomEvent(
		{
			name: eventName,
			params: {
				currency: checkoutPricing.currency,
				value: centsToMajorUnits(checkoutPricing.total),
				transaction_id: transactionId,
				items,
				tax: centsToMajorUnits(checkoutPricing.totalTaxes),
				affiliation: location.name,
				coupon,
			},
		},
		shopGoogleAnalytics,
	);
};
