import React, { useEffect, useRef, useState } from 'react';

import AdyenCheckout from '@adyen/adyen-web';
import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TFunction } from 'i18next';

import { validateApplePaySession } from 'common/api/db/functions';
import { useAdyen } from 'common/hooks/useAdyen';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { AmountObject } from 'common/types';
import { isDevEnv } from 'common/utils/common';

interface Props {
	adyenCheckout: AdyenCheckout;
	paymentError?: string;
	t: TFunction;
	amount: AmountObject;
	merchantName: string;
	loading: boolean;
	disabled?: boolean;
	onSubmit: () => void;
	merchantId: string;
}

const ApplePay = (props: Props) => {
	const classes = useStyles();
	const { setMethodSubmitAction } = useAdyen();
	const {
		t,
		paymentError,
		amount,
		merchantName,
		loading,
		disabled,
		onSubmit,
		adyenCheckout,
		merchantId,
	} = props;
	const paymentElementRef = useRef<HTMLDivElement>(null);
	const [isAvailable, setIsAvailable] = useState<boolean>(false);
	const [validationError, setValidationError] = useState<string | undefined>(undefined);

	useEffect(() => {
		const initApplePay = () => {
			return adyenCheckout.create('applepay', {
				amount: {
					currency: amount.currency,
					value: amount.value,
				},
				countryCode: 'FI',
				configuration: {
					merchantName,
					merchantIdentifier: isDevEnv()
						? 'merchant.com.adyen.rentle.test'
						: 'merchant.com.adyen.rentle',
				},
				// Button config
				buttonType: 'plain', // Optional. The type of button you want to be displayed in the payments form
				buttonColor: 'black', // Optional. Specify the color of the button
				onValidateMerchant: async (resolve, reject, { validationURL }) => {
					try {
						const result = await validateApplePaySession({
							validationURL,
							merchantName,
							hostName: window.location.hostname,
							env: isDevEnv() ? 'TEST' : 'PRODUCTION',
							merchantId,
						});
						resolve(result);
						setValidationError(undefined);
					} catch (e) {
						reject();
						setValidationError(t('common:errors.error'));
						errorHandler.report(e);
					}
				},
			});
		};
		const createPaymentForm = async (applepay: any) => {
			try {
				const applePayAvailable = !!(await applepay.isAvailable());
				setIsAvailable(applePayAvailable);
				if (applePayAvailable) {
					const paymentMethod = applepay.mount(paymentElementRef.current);
					setMethodSubmitAction('APPLEPAY', paymentMethod.submit);
				}
			} catch (e) {}
		};
		try {
			const applePayInstance = initApplePay();
			createPaymentForm(applePayInstance);
		} catch (e) {}
	}, [adyenCheckout, amount.currency, amount.value, merchantName, setMethodSubmitAction]);

	const handleSubmit = () => {
		if (loading || disabled) return;
		onSubmit();
	};

	return (
		<>
			<Box
				component="div"
				style={{ display: !isAvailable ? 'none' : undefined }}
				className={classes.applePayButton}
				ref={paymentElementRef}
				onClick={handleSubmit}
			></Box>
			{validationError || paymentError ? (
				<Box mb={2}>
					{validationError ? (
						<Typography color="error" align="center" gutterBottom>
							{validationError}
						</Typography>
					) : (
						<Typography color="error" align="center" gutterBottom>
							{t('common:payment.paymentError', 'Payment error')} ({props.paymentError})
						</Typography>
					)}
				</Box>
			) : null}
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		applePayButton: {
			display: 'block',
			width: '100%',
			height: 50,
			cursor: 'pointer',
			borderRadius: 0,
			backgroundSize: 'auto 54%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '50% 50%',
			padding: 0,
			boxSizing: 'border-box',
			backgroundImage: '-webkit-named-image(apple-pay-logo-white)',
			backgroundColor: 'black',
			margin: theme.spacing(0, 'auto'),
			marginBottom: theme.spacing(2),
		},
		hidden: {
			display: 'none',
		},
	}),
);

export default ApplePay;
