import { find, range, uniq, uniqBy } from 'lodash';

import { getTaxDetails, getTaxRateFromVatPercent } from 'common/modules/atoms/taxes';
import {
	getDeliveryInventoryBlockersAfter,
	getDeliveryInventoryBlockersBefore,
} from 'common/modules/delivery/utils';
import { BaseProductVariant } from 'common/modules/inventory';
import { getInventoryBlocker } from 'common/modules/inventoryBlockers';
import { OpeningHours } from 'common/modules/openingHours';
import { isRentalPurchaseType } from 'common/modules/orders';
import { getAllProductVariants, isSubscriptionPurchaseType } from 'common/modules/orders/products';
import {
	getProductIdsInPackageWithUnits,
	getProductsInPackage,
	getStockProductMaintenanceMinutes,
	isPackageProduct,
} from 'common/modules/products/utils';
import {
	getBaseVariant,
	getProductVariants,
	getVariantSkuIds,
	getVariantSkuOption,
} from 'common/modules/products/variants';
import { ProductSubscription, getSubscriptionEndDate } from 'common/modules/subscriptions';
import {
	createOrderProductSubscription,
	isAutoRenewSubscription,
} from 'common/modules/subscriptions/utils/subscriptions';
import {
	CartDelivery,
	Category,
	DeliveryOption,
	DurationType,
	ISOString,
	Languages,
	LocaleField,
	OrderInfo,
	OrderObject,
	OrderProduct,
	PricingTableObject,
	ProductApi,
	ProductTypes,
	PurchaseType,
	PurchaseTypes,
	ResponsiblePerson,
	ResponsiblePersonDetails,
	SetProduct,
	Shopper,
} from 'common/types';
import { notUndefined } from 'common/utils/common';

import { OrderDeliveryDetails } from './../modules/delivery/types';
import { initialStockFromVariant } from './inventoryUpdateUtils';
import {
	getChargesFromPricingInfoForGivenDuration,
	getPricingInfoPropertiesForProduct,
	getSalesPrice,
	getVariantPriceIncreaseForPartialProduct,
} from './pricing';
import { getBaseProductFromStockProduct, getProductNameWithVariant } from './productUtils';
import { getShopperNameKeywords, getShopperNames, getUniqProductTypes } from './rentalUtils';

export const getEmptyShopper = (
	id: string,
	rentalId: string,
	shopId: string,
	segment?: string,
): Shopper => {
	return {
		id,
		created: new Date().toISOString(),
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		marketing: false,
		userProperties: {},
		productIds: [],
		categoryIds: [],
		rentalId,
		shopId,
		...(segment && { segment }),
	};
};

export interface PartialOrderProductFields {
	id: string;
	startDate: ISOString | null;
	endDate: ISOString | null;
	durationType: DurationType;
	rentalDurationInSeconds: number;
	durationName: LocaleField | null;
	shopperId: string;
	rentalId: string;
	selectedAsAdditional: boolean;
	startLocationId?: string;
	endLocationId?: string;
}

export interface OrderProductCreation {
	stockProduct: ProductApi;
	stockProductListForSetProducts: ProductApi[] | null;
	/**
	 * The variant ID to use for the main product. If null, the first variant is selected.
	 */
	variantId: string | null;
	/**
	 * An array of variant IDs to use for included products. If a given variant should be used multiple times,
	 * the ID should appear in the array multiple times also. For all products where a valid variant ID is not found,
	 * the first variant is selected.
	 */
	variantIdsForSetProducts: string[] | null;
	partialOrderProductFields: PartialOrderProductFields;
	savedPricingTables: PricingTableObject;
	currencyCode: string;
	taxExcluded: boolean;
	deliveryOption?: DeliveryOption | null;
	segment: string | null;
	cartDelivery?: CartDelivery;
	channel: 'ADMIN' | 'ONLINE' | 'STORE' | 'API';
	purchaseType: PurchaseType;
	timezone: string;
	openingHours: OpeningHours;
	subscription?: ProductSubscription;
}

export const createOrderProductFromProductApi = ({
	stockProduct,
	stockProductListForSetProducts,
	variantId,
	variantIdsForSetProducts,
	partialOrderProductFields,
	savedPricingTables,
	currencyCode,
	taxExcluded,
	segment,
	deliveryOption,
	cartDelivery,
	channel,
	purchaseType,
	timezone,
	openingHours,
	subscription,
}: OrderProductCreation): OrderProduct => {
	const setProducts = createSetProductsForProduct({
		product: stockProduct,
		stockProducts: stockProductListForSetProducts ?? [],
		variantIds: variantIdsForSetProducts ?? [],
		purchaseType,
	});

	const productVariants = getProductVariants(stockProduct);
	const selectedVariant = productVariants.find((v) => v.id === variantId) ?? productVariants[0];

	if (!selectedVariant) {
		throw new Error('Cannot create an order product without a variant');
	}
	const childProductIds = uniq(setProducts.map((p) => p.productApiId));
	const childVariantIds = uniq(setProducts.map((p) => p.variant?.id)).filter(notUndefined);

	const productApiIds = [stockProduct.id, ...childProductIds];
	const variantIds = [selectedVariant.id, ...childVariantIds];

	const baseProduct = getBaseProductFromStockProduct(stockProduct);
	const { segmentMapping } = stockProduct;

	const maintenanceTime = getStockProductMaintenanceMinutes(
		stockProduct,
		stockProductListForSetProducts ?? [],
	);
	const allProductVariants = getAllProductVariants(selectedVariant, setProducts);
	const mainVariantSku = getVariantSkuOption(selectedVariant);
	const allSkuIds = getVariantSkuIds(allProductVariants);

	const { startDate } = partialOrderProductFields;
	const subscriptionFields: Pick<OrderProduct, 'subscription'> =
		purchaseType === PurchaseTypes.subscription && !!startDate && !!subscription
			? {
					subscription: createOrderProductSubscription({
						subscription,
						startDate,
					}),
			  }
			: {};

	const hasAutoRenew = !!subscriptionFields.subscription
		? isAutoRenewSubscription(subscriptionFields.subscription)
		: false;

	const endDate = !!subscriptionFields.subscription
		? getSubscriptionEndDate(subscriptionFields.subscription)
		: partialOrderProductFields.endDate;

	const deliveryDetails: OrderDeliveryDetails | null =
		!!deliveryOption && !!cartDelivery?.to
			? {
					...cartDelivery.to,
					handlingTimeMinutes:
						getDeliveryInventoryBlockersBefore({
							deliveryOption,
							deliverySlot: cartDelivery.to?.timeslot ?? null,
							timezone,
							openingHours,
						}).deliveryHandlingBefore ?? 0,
			  }
			: null;

	const pickupDetails: OrderDeliveryDetails | null =
		!!deliveryOption && !!cartDelivery?.from
			? {
					...cartDelivery.from,
					handlingTimeMinutes:
						getDeliveryInventoryBlockersAfter({
							deliveryOption,
							pickupSlotOrEndDate: cartDelivery?.from?.timeslot ?? endDate,
							timezone,
							openingHours,
						}).deliveryHandlingAfter ?? 0,
			  }
			: null;

	const orderProduct: OrderProduct = {
		...baseProduct,
		...partialOrderProductFields,
		...subscriptionFields,
		// Deprecated, used to keep old availabilities in sync
		includedVariantIds: allSkuIds,
		created: new Date().toISOString(),
		setProducts,
		segment,
		...(segmentMapping && { segmentMapping }),
		productApiId: stockProduct.id,
		productApiIds,
		endDate,
		endDateReturned: null,
		startLocationId: partialOrderProductFields.startLocationId ?? '',
		endLocationId: partialOrderProductFields.endLocationId ?? '',
		unavailable: getInventoryBlocker(null, {
			startDate,
			endDate,
			maintenanceTime,
			deliverySlot: deliveryDetails?.timeslot,
			deliveryHandlingBefore: deliveryDetails?.handlingTimeMinutes,
			pickupSlot: pickupDetails?.timeslot,
			deliveryHandlingAfter: pickupDetails?.handlingTimeMinutes,
			isOpenEnded: hasAutoRenew,
		}),
		variant: selectedVariant,
		summary: {
			variantIds,
			skuIds: allSkuIds,
			itemCodes: [],
		},
		stock: !mainVariantSku
			? {}
			: {
					[mainVariantSku.skuId]: initialStockFromVariant(
						mainVariantSku,
						stockProduct,
						selectedVariant!,
						null,
					),
			  },
		...getPricingProperties({
			setProducts,
			savedPricingTables,
			segment,
			channel,
			partialOrderProductFields,
			vatPercent: baseProduct.vatPercent,
			taxExcluded,
			currencyCode,
			selectedVariant,
			stockProduct,
			purchaseType,
			subscription,
		}),
		purchaseType,
		fulfillmentDate: null,
	};

	return orderProduct;
};

interface GetRentalPricingPropertiesArgs {
	setProducts: SetProduct[];
	savedPricingTables: PricingTableObject;
	channel: 'ADMIN' | 'ONLINE' | 'STORE' | 'API';
	segment: string | null;
	partialOrderProductFields: PartialOrderProductFields;
	vatPercent: number;
	taxExcluded: boolean;
	currencyCode: string;
	purchaseType: PurchaseType;
	stockProduct: ProductApi;
	selectedVariant: BaseProductVariant | null;
	subscription?: ProductSubscription;
}

const getPricingProperties = (
	args: GetRentalPricingPropertiesArgs,
): Pick<OrderProduct, 'charge' | 'deposit' | 'pricing'> => {
	const {
		setProducts,
		savedPricingTables,
		segment,
		channel,
		vatPercent,
		taxExcluded,
		currencyCode,
		purchaseType,
		stockProduct,
		selectedVariant,
		partialOrderProductFields,
		subscription,
	} = args;

	const { rentalDurationInSeconds, durationType, durationName } = partialOrderProductFields;

	const variantPriceIncrease = getVariantPriceIncreaseForPartialProduct(
		selectedVariant,
		setProducts,
	);
	const pricingInfo = getPricingInfoPropertiesForProduct(
		stockProduct,
		savedPricingTables,
		variantPriceIncrease,
		channel,
		segment ?? undefined,
	);

	const stockProductDeposit = stockProduct.terms?.deposit ?? 0;

	const { charge, deposit } = isRentalPurchaseType(purchaseType)
		? getChargesFromPricingInfoForGivenDuration(pricingInfo, {
				durationInSeconds: rentalDurationInSeconds || 0,
				durationType,
				durationName,
		  })
		: isSubscriptionPurchaseType(purchaseType)
		? { charge: subscription?.pricePerCycle.value ?? 0, deposit: stockProductDeposit }
		: { charge: getSalesPrice(stockProduct, selectedVariant), deposit: 0 };

	const taxes = getTaxDetails(charge, {
		rate: getTaxRateFromVatPercent(vatPercent),
		taxExcluded,
	});
	const subtotal = charge;
	const totalPrice = taxExcluded ? subtotal + taxes.totalTaxes : subtotal;

	return {
		charge: {
			paid: 0,
		},
		deposit: !deposit
			? null
			: {
					paid: 0,
			  },
		pricing: {
			currency: currencyCode,
			total: totalPrice,
			totalTaxes: taxes.totalTaxes,
			totalDiscounts: 0,
			taxExcluded,
			taxLines: taxes.taxLines,
			manualDiscount: 0,
			...(!!deposit && { deposit }), // If stock product deposit is 0, we want to not include it
			subtotal,
			listPrice: subtotal,
			originalListPrice: subtotal,
		},
	};
};

export const EMPTY_UNAVAILABLE_OBJECT = {
	from: null,
	until: null,
	details: {},
};

export const getProductsFromIds = (
	productIds: string[],
	productList: ProductApi[],
): ProductApi[] => {
	const products: ProductApi[] = [];
	for (const id of productIds) {
		const product = find(productList, (p) => p.id === id);
		if (product) {
			products.push(product);
		}
	}
	return products;
};

export const createSetProductsForProduct = (args: {
	product: ProductApi;
	stockProducts: ProductApi[];
	variantIds: string[];
	purchaseType: PurchaseType;
}): SetProduct[] => {
	const { product, stockProducts, variantIds, purchaseType } = args;
	if (!isPackageProduct(product)) return [];
	const productIdsWithUnits = getProductIdsInPackageWithUnits(product);

	let remainingVariantIds = variantIds.slice();

	return productIdsWithUnits.flatMap(({ id, units }) => {
		const packageProduct = stockProducts.find((p) => p.id === id);
		if (!packageProduct) return [];
		return range(units).map(() => {
			const baseProduct = getBaseProductFromStockProduct(packageProduct);
			const selectedVariant =
				packageProduct.variants.options.find((variant) => {
					const variantIndex = remainingVariantIds.indexOf(variant.id);
					if (variantIndex !== -1) {
						remainingVariantIds.splice(variantIndex, 1);
						return true;
					}
					return false;
				}) ?? packageProduct.variants.options[0];

			const variantSku = selectedVariant ? getVariantSkuOption(selectedVariant) : null;
			return {
				...baseProduct,
				customValues: [],
				selectedAsAdditional: false,
				productApiId: id,
				...(selectedVariant && { selectedVariant }),
				stock: {
					...(variantSku && {
						[variantSku.skuId]: initialStockFromVariant(
							variantSku,
							packageProduct,
							selectedVariant!,
							null,
						),
					}),
				},
				variant: selectedVariant ? getBaseVariant(selectedVariant) : undefined,
				purchaseType,
			};
		});
	});
};

export const getResponsiblePersonDetails = (
	responsiblePerson: ResponsiblePerson,
	shoppers: Shopper[],
): ResponsiblePersonDetails => {
	if (responsiblePerson.external) {
		return responsiblePerson.person;
	}
	return shoppers.find((s) => s.id === responsiblePerson.shopperId)!;
};

export const getResponsibleShopper = (
	responsiblePerson: ResponsiblePersonDetails,
	shoppers: Shopper[],
): Shopper | null => {
	return shoppers.filter((s) => s.id === responsiblePerson.id)[0] || null;
};

export const isResponsibleShopper = (
	responsiblePerson: ResponsiblePerson,
	shopperId: string,
): boolean => {
	return (!responsiblePerson.external && responsiblePerson.shopperId === shopperId) || false;
};

export const newFirestoreId = (): string => {
	// Alphanumeric characters
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let autoId = '';
	for (let i = 0; i < 20; i++) {
		autoId += chars.charAt(Math.floor(Math.random() * chars.length));
	}
	if (autoId.length !== 20) {
		throw new Error('Invalid auto ID: ' + autoId);
	}
	return autoId;
};

export const getUncategorizedCategory = (orderIndex: number): Category => {
	return {
		id: '',
		shopId: '',
		name: {
			def: 'Others',
			fi: 'Muut',
			en: 'Others',
			sv: 'Övriga',
		},
		imageUrl: null,
		terms: null,
		orderIndex,
	};
};

export const getActiveCategories = (categories: Category[], stockProducts: ProductApi[]) => {
	const activeCategoryIds: string[] = [
		...new Set(
			stockProducts.flatMap((p) => (p.categoryIds && p.categoryIds.length ? p.categoryIds : '')),
		),
	];
	const maxOrderIndex = Math.max(...categories.map((c) => c.orderIndex ?? 0));
	const categoriesWithUncategorized = [...categories, getUncategorizedCategory(maxOrderIndex + 1)];
	return categoriesWithUncategorized.filter((c) => activeCategoryIds.includes(c.id));
};

export const createCompleteOrderObject = ({
	rentalInfo,
	products,
	shoppers,
	orderDelivery,
}: OrderObject): OrderObject => {
	const shopperIdsWithProducts = {};
	const categoryIds: string[] = [];
	const stockProductIds: string[] = [];
	const categoriesWithoutIds: string[] = [];
	const { shopId, startLocation, endLocation } = rentalInfo;
	const startLocationId = startLocation.id;
	const endLocationId = endLocation.id;
	const orderId = rentalInfo.id;
	for (const shopper of shoppers) {
		if (shopper.categoryIds) {
			categoryIds.push(...shopper.categoryIds);
		}
		if (shopper.category) {
			categoriesWithoutIds.push(shopper.category);
		}
		const productIds = shopper.productIds.slice();
		shopperIdsWithProducts[shopper.id] = productIds;
	}
	for (const product of products) {
		stockProductIds.push(product.productApiId);
	}

	const updatedProducts = products.map((p) => ({
		...p,
		rentalId: orderId,
		shopId,
		startLocationId,
		endLocationId,
	}));
	const productTypes = getUniqProductTypes(updatedProducts);

	const newRentalInfo: OrderInfo = {
		...rentalInfo,
		created: rentalInfo.created || new Date().toISOString(),
		initialStartDate: rentalInfo.startDate,
		shopperIdsWithProducts,
		shopperNames: getShopperNames(shoppers, rentalInfo.responsiblePerson),
		shopperNameKeywords: getShopperNameKeywords(shoppers, rentalInfo.responsiblePerson),
		categoryIds: [...new Set(categoryIds)],
		stockProductIds: [...new Set(stockProductIds)],
		...(categoriesWithoutIds.length && {
			categoriesWithoutIds: [...new Set(categoriesWithoutIds)],
		}),
		includedProductTypes: !!productTypes.length ? productTypes : [ProductTypes.RENTAL],
	};

	return {
		rentalInfo: newRentalInfo,
		shoppers: shoppers.map((s) => ({ ...s, rentalId: orderId, shopId })),
		products: updatedProducts,
		orderDelivery,
	};
};

export const getAllProductsToFetchAvailabilityFor = (
	visibleStockProducts: ProductApi[],
	stockProducts: ProductApi[],
) => {
	const productsToFetchAvailabilityFor = [...visibleStockProducts];
	visibleStockProducts.forEach((product) => {
		const productsInPackage = getProductsInPackage(product, stockProducts);
		if (!!productsInPackage.length) {
			const childrenNotYetIncluded = productsInPackage.filter(
				(child) => !productsToFetchAvailabilityFor.map((p) => p.id).includes(child.id),
			);
			if (childrenNotYetIncluded.length > 0) {
				productsToFetchAvailabilityFor.push(...childrenNotYetIncluded);
			}
		}
	});
	return productsToFetchAvailabilityFor;
};

interface SelectedVariantAmounts {
	[variantId: string]: number;
}

export const getSelectedVariantAmounts = (products: OrderProduct[]) => {
	const activeSelectedVariants: SelectedVariantAmounts = {};
	products.forEach((orderProduct) => {
		orderProduct.summary.variantIds.forEach((variantId) => {
			if (!activeSelectedVariants[variantId]) {
				activeSelectedVariants[variantId] = 1;
			} else {
				activeSelectedVariants[variantId] += 1;
			}
		});
	});
	return activeSelectedVariants;
};

export const getUniqueProductsFromVariantIds = (
	rentalProducts: OrderProduct[],
	variantIds: string[],
): OrderProduct[] => {
	const products = rentalProducts.filter((p) =>
		variantIds.some((id) => p.summary.variantIds.includes(id)),
	);
	return uniqBy(products, 'productApiId');
};

export const getUniqueProductWithVariantNamesFromVariantIds = (
	rentalProducts: OrderProduct[],
	variantIds: string[],
	lang: Languages,
): string[] => {
	const products = rentalProducts.filter((p) =>
		variantIds.some((id) => p.summary.variantIds.includes(id)),
	);
	const allProductNamesWithVariants = products.map((product) =>
		getProductNameWithVariant(product, lang),
	);

	return uniq(allProductNamesWithVariants);
};
