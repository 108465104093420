import { useCallback, useMemo, useState } from 'react';

import { TFunction } from 'i18next';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import * as StockActions from 'redux/actions/stock';
import { useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import { ProductVariant } from 'common/modules/inventory';
import { SkidataKeycardType } from 'common/modules/skidata';
import { ISOString, ProductApi, SegmentMap } from 'common/types';
import { isValidWeekday } from 'common/utils/dateUtils';
import { getTicketPrice, getTicketVariant } from 'common/utils/liftTicketUtils';
import { doesTicketHaveSegment, getSkidataSegmentById } from 'common/utils/segments';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';
import { isLiftTicketStartDateExpired } from 'utils/cart';
import {
	getTicketValidityAsMinMaxDateAndValidWeekdays,
	getTicketsWithDynamicPrices,
	isDateOutsideOfValidity,
} from 'utils/liftTicket';

import useSearchParams from './useSearchParams';

type LiftTicketFormState = {
	productId: string | null;
	segmentId: string | null;
	withNewKeycard: boolean;
	quantity: number;
	startDate: ISOString | null;
};

type ValidatedFormState = {
	productId: string;
	segmentId: string;
	withNewKeycard: boolean;
	quantity: number;
	startDate: ISOString;
};

type LiftTicketFormErrors = {
	productId?: string | null;
	segmentId?: string | null;
	withNewKeycard?: string | null;
	quantity?: string | null;
	startDate?: string | null;
};

interface LiftTicketForm {
	values: LiftTicketFormState;
	errors: LiftTicketFormErrors;
	actions: {
		setProductId: (value: string | null) => void;
		setSegmentId: (value: string | null) => void;
		setWithNewKeycard: (value: boolean) => void;
		setQuantity: (value: number) => void;
		setStartDate: (value: ISOString | null) => void;
		reset: () => void;
		validate: () => { valid: boolean; value?: ValidatedFormState; errors?: string[] };
	};
	meta: {
		product: ProductApi | null;
		segment: SegmentMap | null;
		ticketVariant?: ProductVariant;
		charge: number;
		loadingSegmentPrices: boolean;
		validity: { minDate: ISOString; maxDate: ISOString; validWeekdays?: string[] } | null;
	};
}

const validateStartDate = (
	startDate: LiftTicketFormState['startDate'],
	t: TFunction,
	validity?: { minDate: ISOString; maxDate: ISOString; validWeekdays?: string[] } | null,
): string | null => {
	if (!startDate) {
		return t('LiftTicketForm.pleaseChooseStartTime', 'Please choose a start time');
	}

	if (isLiftTicketStartDateExpired(startDate)) {
		return t(
			'LiftTicketForm.startTimeInPast',
			'The start time you have selected is in the past - please choose a new start time',
		);
	}

	if (
		(!!validity && isDateOutsideOfValidity(startDate, validity)) ||
		!isValidWeekday(validity?.validWeekdays, moment(startDate).isoWeekday())
	) {
		return t(
			'LiftTicketForm.ticketOutsideOfValidity',
			'Not available for the selected date - please choose a new start time',
		);
	}

	return null;
};

const validateProductId = (
	productId: LiftTicketFormState['productId'],
	t: TFunction,
): string | null => {
	return !productId
		? t('LiftTicketForm.pleaseChooseTicketType', 'Please choose a ticket type')
		: null;
};

const validateSegmentName = (
	segmentId: LiftTicketFormState['segmentId'],
	t: TFunction,
): string | null => {
	return !segmentId ? t('LiftTicketForm.pleaseChooseAgeGroup', 'Please choose an age group') : null;
};

const getSelectedTicketPrice = (selectedTicket: ProductApi | null, segmentId: string | null) => {
	const selectedTicketPrice =
		!!selectedTicket && segmentId ? getTicketPrice(selectedTicket, segmentId) : 0;
	return selectedTicketPrice || 0;
};

const useLiftTicketForm = (): LiftTicketForm => {
	const { replaceRoute, Routes } = useRoutes();
	const searchParams = useSearchParams();
	const { t } = useTranslation();
	const productId = searchParams.value.get('type');
	const segmentId = searchParams.value.get('segment');

	const startDateFromCart = useSelector(CartSelectors.liftTicketsStartDate);
	const keycardTypeFromCart = useSelector(CartSelectors.keycardTypeFromCart);
	const productsById = useSelector(StockSelectors.productsById);
	const visibleLiftTicketSegments = useSelector(StockSelectors.visibleLiftTicketSegments);
	const liftTicketProducts = useSelector(StockSelectors.normalLiftTicketProducts);

	const currency = useSelector(ShopSelectors.shopCurrency);
	const shopId = useSelector(ShopSelectors.shopId);
	const dispatch = useDispatch();
	const [loadingSegmentPrices, setLoadingSegmentPrices] = useState(false);

	const productFromSearchParams = productId ? productsById[productId] ?? null : null;

	const initialFormState: LiftTicketFormState = useMemo(() => {
		return {
			productId: productFromSearchParams?.id ?? null,
			segmentId,
			withNewKeycard: keycardTypeFromCart !== SkidataKeycardType.EXISTING,
			quantity: 1,
			startDate: startDateFromCart,
		};
	}, [productFromSearchParams, keycardTypeFromCart, segmentId, startDateFromCart]);

	const [formState, setFormState] = useState<LiftTicketFormState>(initialFormState);
	const [formErrors, setFormErrors] = useState<LiftTicketFormErrors>({});

	const selectedTicket = formState.productId ? productsById[formState.productId] ?? null : null;
	const selectedTicketPrice = getSelectedTicketPrice(selectedTicket, formState.segmentId);

	const ticketVariant = useMemo(() => getTicketVariant(selectedTicket, formState.withNewKeycard), [
		selectedTicket,
		formState.withNewKeycard,
	]);

	const validity = getTicketValidityAsMinMaxDateAndValidWeekdays(selectedTicket);

	const assignDynamicPricesToTickets = useCallback(
		async (segmentId: string | null, startDate: ISOString | null) => {
			if (!segmentId || !startDate) return;
			setLoadingSegmentPrices(true);
			try {
				const ticketsWithDynamicPrice = await getTicketsWithDynamicPrices({
					ticketProducts: liftTicketProducts,
					segmentId,
					shopId,
					currencyCode: currency.code,
					quantity: 1,
					startDate: startDate,
				});

				dispatch(StockActions.updateTicketProducts(ticketsWithDynamicPrice));
			} catch (e) {}
			setLoadingSegmentPrices(false);
		},
		[currency.code, dispatch, liftTicketProducts, shopId],
	);

	const setProductId = useCallback(
		(productId: string | null) => {
			if (formErrors.productId) {
				setFormErrors((prev) => ({
					...prev,
					productId: validateProductId(productId, t),
				}));
			}
			setFormState((prev) => ({
				...prev,
				productId,
			}));
			const params = searchParams
				.with({ type: productId, segment: formState.segmentId })
				.toString();
			window.history.replaceState(null, '', `?${params}`);
		},
		[formErrors.productId, formState.segmentId, searchParams, t],
	);

	const setSegmentId = useCallback(
		async (segmentId: string | null) => {
			if (formErrors.segmentId) {
				setFormErrors((prev) => ({
					...prev,
					segmentId: validateSegmentName(segmentId, t),
				}));
			}

			const ticketTypeHasSegment =
				!!formState.productId &&
				doesTicketHaveSegment(segmentId)(productsById[formState.productId]);

			setFormState((prev) => ({
				...prev,
				segmentId,
				...(!ticketTypeHasSegment && { productId: null }),
			}));
			const params = searchParams
				.with({ type: ticketTypeHasSegment ? formState.productId : null, segment: segmentId })
				.toString();
			window.history.replaceState(null, '', `?${params}`);

			await assignDynamicPricesToTickets(segmentId, formState.startDate);
		},
		[
			assignDynamicPricesToTickets,
			formErrors.segmentId,
			formState.productId,
			formState.startDate,
			productsById,
			searchParams,
			t,
		],
	);

	const setWithNewKeycard = useCallback((withNewKeycard: boolean) => {
		setFormState((prev) => ({
			...prev,
			withNewKeycard,
		}));
	}, []);

	const setQuantity = useCallback((quantity: number) => {
		setFormState((prev) => ({
			...prev,
			quantity,
		}));
	}, []);

	const setStartDate = useCallback(
		async (startDate: ISOString | null) => {
			if (formErrors.startDate) {
				setFormErrors((prev) => ({
					...prev,
					startDate: validateStartDate(startDate, t, validity),
				}));
			}
			setFormState((prev) => ({
				...prev,
				startDate,
			}));

			await assignDynamicPricesToTickets(formState.segmentId, startDate);
		},
		[assignDynamicPricesToTickets, formErrors.startDate, formState.segmentId, validity, t],
	);

	const validate = useCallback(() => {
		const productIdError = validateProductId(formState.productId, t);
		const segmentIdError = validateSegmentName(formState.segmentId, t);
		const startDateError = validateStartDate(formState.startDate, t, validity);

		const errors: LiftTicketFormErrors = {
			...(productIdError && { productId: productIdError }),
			...(segmentIdError && { segmentId: segmentIdError }),
			...(startDateError && { startDate: startDateError }),
		};
		setFormErrors(errors);

		return isEmpty(errors)
			? { valid: true, value: formState as ValidatedFormState }
			: { valid: false, errors: Object.keys(errors) };
	}, [formState, t, validity]);

	const reset = useCallback(() => {
		replaceRoute(Routes.tickets);
		setFormState(initialFormState);
		setFormErrors({});
	}, [Routes.tickets, initialFormState, replaceRoute]);

	return {
		values: formState,
		errors: formErrors,
		actions: {
			setProductId,
			setSegmentId,
			setWithNewKeycard,
			setQuantity,
			setStartDate,
			reset,
			validate,
		},
		meta: {
			product: selectedTicket,
			charge: selectedTicketPrice,
			ticketVariant,
			segment: getSkidataSegmentById(formState.segmentId, visibleLiftTicketSegments) ?? null,
			loadingSegmentPrices,
			validity,
		},
	};
};

export default useLiftTicketForm;
