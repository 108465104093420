import React from 'react';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

import { useTranslation } from 'services/localization/useTranslation';

interface Props {
	open: boolean;
	handleContinue: () => void;
	onClose: () => void;
}

const NoPhotoDialog = (props: Props) => {
	const { open, handleContinue, onClose } = props;
	const { t } = useTranslation();

	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{t('shopperInfo.continueWithoutPhoto')}</DialogTitle>
			<DialogContent>
				<Typography variant="body2" paragraph>
					{t(
						'shopperInfo.continueWithoutPhotoInfo1',
						'The ticket can’t be activated without a photo.',
					)}
				</Typography>
				<Typography variant="body2" paragraph>
					{t(
						'shopperInfo.continueWithoutPhotoInfo2',
						'To upload a photo later, use the link in the confirmation email or visit the cashier upon arrival.',
					)}
				</Typography>
			</DialogContent>
			<DialogActions className={classes.actions}>
				<Grid container justifyContent="center" direction={isMobile ? 'column' : 'row-reverse'}>
					<Button
						fullWidth={isMobile}
						variant="contained"
						onClick={handleContinue}
						color="primary"
						className={classnames(isMobile ? classes.botSpacing : classes.leftSpacing)}
					>
						{t('common:actions.continue')}
					</Button>
					<Button fullWidth={isMobile} variant="text" onClick={onClose}>
						{t('common:actions.cancel')}
					</Button>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};

const useStyles = makeStyles((theme) => ({
	actions: {
		padding: theme.spacing(0, 3, 3),
	},
	botSpacing: {
		marginBottom: theme.spacing(2),
	},
	leftSpacing: {
		marginLeft: theme.spacing(2),
	},
}));

export default NoPhotoDialog;
