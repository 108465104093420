import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';

import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';

const NavLinks = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { getPath, Routes } = useRoutes();

	const onlineLocations = useSelector(ShopSelectors.onlineLocations);
	const multiLocation = onlineLocations.length > 1;

	const browseRoute = getPath(Routes.browse);
	const aboutRoute = getPath(Routes.about);
	const locationsRoute = getPath(Routes.locations);

	return (
		<Box className={classes.wrapper}>
			<Link to={browseRoute} className={classes.link}>
				<Typography
					variant="h6"
					className={classNames(classes.linkText, {
						[classes.linkTextActive]: pathname === browseRoute,
					})}
				>
					{t('common:actions.home', 'Home')}
				</Typography>
			</Link>
			<Link to={aboutRoute} className={classes.link}>
				<Typography
					variant="h6"
					className={classNames(classes.linkText, {
						[classes.linkTextActive]: pathname === aboutRoute,
					})}
				>
					{t('common:actions.about', 'About')}
				</Typography>
			</Link>
			{multiLocation && (
				<Link to={locationsRoute} className={classes.link}>
					<Typography
						variant="h6"
						className={classNames(classes.linkText, {
							[classes.linkTextActive]: pathname === locationsRoute,
						})}
					>
						{t('common:shopLocation.stores', 'Stores')}
					</Typography>
				</Link>
			)}
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
		},
		link: {
			padding: theme.spacing(0.5),
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		linkText: {
			fontSize: '1.6rem',
			position: 'relative',
			'&:after': {
				content: '""',
				position: 'absolute',
				bottom: -2,
				left: 0,
				right: 0,
				height: 2,
				background: 'black',
				transform: 'scale3d(0, 1, 1)',
				transition: 'transform 0.2s',
			},
		},
		linkTextActive: {
			'&:after': {
				transform: 'scale3d(1, 1, 1)',
			},
		},
	}),
);

export default NavLinks;
