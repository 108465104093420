import { isEmpty, minBy } from 'lodash';
import moment from 'moment-timezone';

import { AvailabilityRange } from 'common/api/frontend/inventory_new/availabilityRange';
import { ByVariant, DeliveryTimeSlot, ISOString, LoadingData, ProductApi } from 'common/types';
import { AvailabilityByStartTime } from 'utils/availability';
import {
	AvailabilityByTimeslot,
	getTimeslotsWithValidityForDate,
	isDeliveryTimeslotAvailable,
	isProductInitiallyAvailableTimeslot,
} from 'utils/delivery';
import { getSetTotalAvailability, getSetTotalQuantity } from 'utils/products';
import {
	getHasAvailabilityByStartTime,
	getStartTimesWithValidityForSelectedDate,
	isStartTimeAvailable,
	isStartTimeAvailableForDateTime,
	isStartTimeAvailableForDay,
} from 'utils/startTimes';

type Selection =
	| { set: true; variantIds: string[] }
	| { set: false; variantId: string | undefined };

interface QuantityForSelection {
	quantity: LoadingData<ByVariant<number | null>>;
	selection: Selection;
}

export const getTotalQuantityForSelection = ({ quantity, selection }: QuantityForSelection) => {
	if (!quantity.data || isEmpty(quantity.data)) return 0;
	if (selection.set) {
		return !selection?.variantIds.length
			? 0
			: getSetTotalQuantity(selection.variantIds, quantity.data);
	}
	return selection.variantId === undefined || quantity.data[selection.variantId] === undefined
		? 0
		: quantity.data[selection.variantId];
};

interface AvailabilityForSelection {
	availability: LoadingData<ByVariant<AvailabilityRange[]>>;
	selection: Selection;
}

export const getTotalAvailabilityForSelection = ({
	availability,
	selection,
}: AvailabilityForSelection) => {
	if (!availability.data || isEmpty(availability.data)) return null;
	if (selection.set) {
		return !selection.variantIds?.length
			? null
			: getSetTotalAvailability(selection.variantIds, availability.data);
	}
	return selection.variantId === undefined ? null : availability.data[selection.variantId] ?? null;
};

export const getZeroQuantityVariants = (quantity: LoadingData<ByVariant<number | null>>) => {
	if (!quantity.data || isEmpty(quantity.data)) return [];
	return Object.keys(quantity.data).reduce((result, variantId) => {
		const qty = quantity.data![variantId];
		if (qty !== null && qty !== undefined && qty <= 0) {
			result.push(variantId);
		}
		return result;
	}, [] as string[]);
};

export const getVariantQuantity = (
	variantId: string,
	quantities: LoadingData<ByVariant<number | null>>,
): number => {
	if (!quantities.data || isEmpty(quantities.data)) return 0;

	const variantQuantity = quantities.data[variantId];
	return variantQuantity === null ? Infinity : variantQuantity ?? 0;
};

export const isVariantInStock = (
	variantId: string,
	quantities: LoadingData<ByVariant<number | null>>,
): boolean => {
	return getVariantQuantity(variantId, quantities) > 0;
};

export const isProductInitiallyAvailable = ({
	forcedStartDate,
	forcedStartDateAndTime,
	availabilityByStartTime,
	selectedQuantity,
}: {
	forcedStartDate: ISOString | null;
	forcedStartDateAndTime: ISOString | null;
	availabilityByStartTime: AvailabilityByStartTime | null;
	selectedQuantity: number;
}) => {
	if (!availabilityByStartTime) return true;
	const hasAvailabilityByStartTime = getHasAvailabilityByStartTime(
		availabilityByStartTime,
		selectedQuantity,
	);
	const isStartDateFromCartAvailable = !!forcedStartDateAndTime
		? isStartTimeAvailableForDateTime(
				forcedStartDateAndTime,
				availabilityByStartTime,
				selectedQuantity,
		  )
		: !!forcedStartDate
		? isStartTimeAvailableForDay(forcedStartDate, availabilityByStartTime, selectedQuantity)
		: true;
	return hasAvailabilityByStartTime && isStartDateFromCartAvailable;
};

export const calculateCalendarDateAvailabilities = (
	availabilityByStartTimeOrTimeslot: AvailabilityByStartTime | AvailabilityByTimeslot,
	selectedQuantity: number,
) => {
	const lowDates: string[] = [];
	const unavailableDates: string[] = [];
	const disabledDates: string[] = [];

	Object.keys(availabilityByStartTimeOrTimeslot).forEach((date) => {
		const timeslotAvailabilities = Object.values(availabilityByStartTimeOrTimeslot[date]);

		if (!timeslotAvailabilities.length) {
			//Shop is closed / no start times exist for the day
			disabledDates.push(date);
		} else if (
			timeslotAvailabilities.every(
				({ units, valid }) => !valid || units === 0 || (units && units < selectedQuantity),
			)
		) {
			unavailableDates.push(date);
		}
	});

	return [lowDates, unavailableDates, disabledDates];
};

export const getIsStartDateFromCartAvailable = (args: {
	hasDelivery: boolean;
	forcedStartDate: ISOString | null;
	forcedStartDateAndTime: ISOString | null;
	availabilityByTimeslot: AvailabilityByTimeslot | null;
	availabilityByStartTime: AvailabilityByStartTime | null;
	selectedQuantity: number;
}) => {
	const {
		hasDelivery,
		forcedStartDate,
		forcedStartDateAndTime,
		availabilityByTimeslot,
		availabilityByStartTime,
		selectedQuantity,
	} = args;

	return !hasDelivery
		? isProductInitiallyAvailable({
				forcedStartDate,
				forcedStartDateAndTime,
				availabilityByStartTime,
				selectedQuantity,
		  })
		: isProductInitiallyAvailableTimeslot({
				forcedStartDate,
				forcedStartDateAndTime,
				availabilityByTimeslot,
				selectedQuantity,
		  });
};

export const isSelectedDateAvailable = (args: {
	selectedDate: ISOString | null;
	hasDelivery: boolean;
	availabilityByTimeslot: AvailabilityByTimeslot | null;
	availabilityByStartTime: AvailabilityByStartTime | null;
	selectedQuantity: number;
}) => {
	const {
		selectedDate,
		hasDelivery,
		availabilityByStartTime,
		availabilityByTimeslot,
		selectedQuantity,
	} = args;
	return !!selectedDate
		? !hasDelivery
			? isStartTimeAvailable(selectedDate, availabilityByStartTime, selectedQuantity)
			: isDeliveryTimeslotAvailable(selectedDate, availabilityByTimeslot, selectedQuantity)
		: false;
};

export const getDeliverySlotForNewDate = (args: {
	newDate: ISOString;
	currentDeliverySlot: DeliveryTimeSlot | null;
	availabilityByTimeslot: AvailabilityByTimeslot | null;
	selectedQuantity: number;
}): DeliveryTimeSlot | null => {
	const { currentDeliverySlot, newDate, availabilityByTimeslot, selectedQuantity } = args;

	const validDeliverySlotsForNewDate = getTimeslotsWithValidityForDate(
		availabilityByTimeslot,
		selectedQuantity,
		moment(newDate).format('YYYY-MM-DD'),
	).filter((item) => !item.disabled);

	const currentDeliverySlotStartTimeHours = !!currentDeliverySlot
		? moment(currentDeliverySlot.startDate).hours()
		: 0;
	const closestValidDeliverySlot = minBy(validDeliverySlotsForNewDate, (deliverySlot) => {
		return Math.abs(moment(deliverySlot.startDate).hours() - currentDeliverySlotStartTimeHours);
	});

	const newDeliverySlot = closestValidDeliverySlot ?? validDeliverySlotsForNewDate[0] ?? null;

	return !!newDeliverySlot
		? {
				startDate: newDeliverySlot.startDate,
				endDate: newDeliverySlot.endDate,
		  }
		: null;
};

export const getStartTimeForNewDate = (args: {
	newDate: ISOString;
	currentStartTime: ISOString | null;
	availabilityByStartTime: AvailabilityByStartTime | null;
	selectedQuantity: number;
}): ISOString | null => {
	const { newDate, currentStartTime, availabilityByStartTime, selectedQuantity } = args;

	const validStartTimesForNewDate = getStartTimesWithValidityForSelectedDate(
		availabilityByStartTime,
		selectedQuantity,
		moment(newDate).format('YYYY-MM-DD'),
	).filter((startTime) => !startTime.disabled);

	const currentStartTimeHours = !!currentStartTime ? moment(currentStartTime).hours() : 0;
	const closestValidStartTime = minBy(validStartTimesForNewDate, (startTime) =>
		Math.abs(moment(startTime.value).hours() - currentStartTimeHours),
	);
	const newStartTime = closestValidStartTime ?? validStartTimesForNewDate[0];

	return newStartTime?.value ?? null;
};

export const getEarliestFixedCustomStartDate = (product: ProductApi): ISOString | null => {
	const hasOnlyFixedCustomDates = product.startTimes?.value.every((v) => v.type === 'exceptional');

	if (!hasOnlyFixedCustomDates) {
		return null;
	}

	const exceptionDates = product.startTimes?.value
		.flatMap((v) => (v.type === 'exceptional' ? v.value.dates : []))
		.map((date) => moment(date));

	return !!exceptionDates?.length ? moment.min(exceptionDates).toISOString() : null;
};
