import { TFunction } from 'i18next';

export const common = (t: TFunction) => ({
	paymentMethods: {
		CARD_ONLINE: t('common:payment.paymentMethods.CARD_ONLINE', 'Card'),
		CARD_ONLINE_STRIPE: t('common:payment.paymentMethods.CARD_ONLINE', 'Card'),
		PAYTRAIL: t('common:payment.paymentMethods.PAYTRAIL', 'Online bank'),
		TRUSTLY: t('common:payment.paymentMethods.TRUSTLY', 'Online bank'),
		APPLEPAY: t('common:payment.paymentMethods.APPLEPAY', 'ApplePay'),
		GOOGLEPAY: t('common:payment.paymentMethods.GOOGLEPAY', 'GooglePay'),
		MOBILEPAY: t('common:payment.paymentMethods.MOBILEPAY', 'MobilePay'),
		EPASSI_ONLINE: 'Epassi',
		PAY_STORE: t('common:payment.paymentMethods.PAY_STORE', 'Pay on pickup'),
	},
	paymentMethodDescriptions: {
		PAYTRAIL: t(
			'common:payment.paymentMethodDescription.PAYTRAIL',
			'You will be redirected to Paytrail service to finish the bank payment, and redirected back once the payment process is completed.',
		),
		TRUSTLY: t(
			'common:payment.paymentMethodDescription.TRUSTLY',
			'You will be redirected to Trustly service to finish the bank payment, and redirected back once the payment process is completed.',
		),
	},
	errors: {
		ERROR_TRY_AGAIN_LATER: t('common:errors.error', 'Error occured. Please try again later'),
		ERROR_FETCHING_PAYMENT_INFO: t(
			'common:errors.errorFetchingPaymentInfo',
			'Error fetching payment information',
		),
		ERROR_TRY_AGAIN: t('common:errors.error', 'Error. Try again'),
		ERROR_SHORT: t('common:errors.error_short', 'Error:'),
		PAGE_NOT_FOUND: t('common:errors.notFound', 'Sorry, the page cannot be found'),
		PAYMENT_ERROR: t('common:errors.paymentError', 'Error processing the payment'),
		ORDER_ERROR: t('common:errors.orderError', 'Error completing the order'),
		RELOAD_PAGE: t('common:errors.pleaseReloadPage', 'Please reload the page to try again'),
		SOMETHING_WRONG_TRY_AGAIN: t(
			'common:errors.somethingWentWrong',
			'Something went wrong - please try again.',
		),
		SOMETHING_WRONG: t('common:errors.somethingWentWrongShort', 'Something went wrong'),
	},
});
