import React from 'react';

import { ButtonBase, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

interface Props {
	disabled?: boolean;
	selected?: boolean;
	label: string;
	onClick?: () => void;
	hoverEffect?: boolean;
}

const TimeChip = (props: Props) => {
	const classes = useStyles(props);
	const { disabled, selected, onClick, label } = props;
	return (
		<ButtonBase
			focusRipple
			disabled={disabled}
			onClick={onClick}
			className={classNames(classes.chip, {
				[classes.selected]: selected,
				[classes.disabled]: disabled,
			})}
		>
			<Typography variant="body2" className={classes.label}>
				{label}
			</Typography>
		</ButtonBase>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		chip: {
			padding: theme.spacing(0.5, 1),
			borderRadius: 16,
			border: '1px solid #e5e7e7',
			minWidth: 60,
			margin: theme.spacing(0.5, 0.5, 0.5, 0),
			'&:hover': {
				borderColor: (props: Props) =>
					props.hoverEffect ? theme.palette.common.black : '1px solid #e5e7e7',
			},
		},
		selected: {
			background: theme.palette.common.black,
			color: theme.palette.common.white,
			borderColor: theme.palette.common.black,
		},
		disabled: {
			background: '#e5e7e7',
			color: '#716a80',
			pointerEvents: 'none',
		},
		label: {
			fontSize: '14px',
		},
	}),
);

export default TimeChip;
