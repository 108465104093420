import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'common/frontend/api';
import { Category, ProductApi } from 'common/types';
import { ListenerManager } from 'services/ListenerManager';

const stockListeners = new ListenerManager<'products' | 'categories'>();

export const updateStockCategoriesListener = createAsyncThunk(
	'Stock/UPDATE_CATEGORIES_LISTENER',
	(shopId: string, thunkAPI) => {
		return new Promise<void>((resolve, reject) => {
			stockListeners.update({
				id: 'categories',
				key: shopId,
				listener: api()
					.productCategories.get.where('shopId', '==', shopId)
					.onSnapshot(
						(categories) => {
							resolve();
							thunkAPI.dispatch(setStockCategories(categories));
						},
						(error) => {
							reject();
							thunkAPI.rejectWithValue(error.message);
							thunkAPI.dispatch(setStockCategoriesError(error.message));
						},
					),
			});
		});
	},
);

export const setStockCategories = createAction<Category[]>('Stock/SET_CATEGORIES');
export const setStockCategoriesError = createAction<string>('Stock/SET_CATEGORIES_ERROR');

export const updateStockProductsListener = createAsyncThunk(
	'Stock/UPDATE_PRODUCTS_LISTENER',
	(shopId: string, thunkAPI) => {
		return new Promise<void>((resolve, reject) => {
			stockListeners.update({
				id: 'products',
				key: shopId,
				listener: api()
					.products.get.where('shopId', '==', shopId)
					.orderBy('orderIndex', 'asc')
					.onSnapshot(
						(products) => {
							resolve();
							thunkAPI.dispatch(setStockProducts(products));
						},
						(error) => {
							reject();
							thunkAPI.rejectWithValue(error.message);
							thunkAPI.dispatch(setStockProductsError(error.message));
						},
					),
			});
		});
	},
);

export const setStockProducts = createAction<ProductApi[]>('Stock/SET_PRODUCTS');
export const setStockProductsError = createAction<string>('Stock/SET_PRODUCTS_ERROR');

export const updateTicketProducts = createAction<ProductApi[]>('Stock/UPDATE_TICKETS');
