import React, { useMemo } from 'react';

import { Box, ButtonBase, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { sumBy } from 'lodash';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import * as CartActions from 'redux/actions/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import { CartProduct } from 'common/types';
import { getPricingString } from 'common/utils/common';
import { productHasNewKeycard } from 'common/utils/liftTicketUtils';
import { getSkidataSegmentTranslationById } from 'common/utils/segments';
import { useTranslation } from 'services/localization';
import { ProductWithQuantity } from 'services/types';
import { getProductImage } from 'utils/products';

interface Props {
	products: ProductWithQuantity<CartProduct>[];
	startDateExpired?: boolean;
	readOnly?: boolean;
}
const CartProductsPackageTicketRowItem = (props: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t, getTranslation, language } = useTranslation();
	const { products, startDateExpired, readOnly } = props;

	const keycardProduct = useSelector(StockSelectors.keycardProduct);
	const allPackageProducts = useSelector(StockSelectors.liftTicketPackageProducts);
	const currency = useSelector(ShopSelectors.shopCurrency);
	const categories = useSelector(StockSelectors.liftTicketCategory);
	const packageId = products[0]?.product.packageId;

	const packageProduct = allPackageProducts.find((product) => {
		return product.externalId === products[0]?.product.externalPackageId;
	});

	const keycardQuantity = sumBy(products, (p) => {
		if (productHasNewKeycard(p.product)) {
			return p.quantity;
		}
		return 0;
	});
	const keycardName = !!keycardProduct?.name ? getTranslation(keycardProduct.name) : '';

	const totalPrice = useMemo(() => {
		const ticketsPrice = sumBy(products, (product) => {
			return product.quantity * product.product.pricing.subtotal;
		});
		const keycardPrice = keycardProduct?.rentals?.basePrice ?? 0;

		return ticketsPrice + keycardQuantity * keycardPrice;
	}, [keycardProduct, keycardQuantity, products]);

	const getProductName = (product: CartProduct) => {
		const segmentId = product.externalSegment?.externalSegmentId;
		const segmentName = segmentId
			? getSkidataSegmentTranslationById(segmentId, categories?.segmentMapping ?? [], language)
			: '';
		const productName = getTranslation(product.name);

		if (segmentName) return `${productName}, ${segmentName}`;

		return productName;
	};

	if (!packageProduct || !packageId) return null;

	return (
		<div>
			<Box className={classes.details}>
				<Box className={classes.productImageWrapper}>
					<img src={getProductImage(packageProduct)} className={classes.productImage} alt="" />
				</Box>
				<Box className={classes.productName}>
					<Typography variant="body2">{getTranslation(packageProduct.name)}</Typography>
					{products.map(({ quantity, product }) => {
						return (
							<Typography
								variant="caption"
								key={product.externalSegment?.externalSegmentId}
								className={classes.productDetail}
							>
								{quantity} x {getProductName(product)}
							</Typography>
						);
					})}
					{keycardQuantity > 0 && !!keycardName && (
						<Typography variant="caption" className={classes.productDetail}>
							{keycardQuantity} x {keycardName}
						</Typography>
					)}

					<Box className={classes.bottom}>
						<Box className={classes.amount}>
							{!readOnly && (
								<ButtonBase
									focusRipple
									className={classes.removeButton}
									onClick={() =>
										dispatch(CartActions.removePackageTicketProductFromCart(packageId))
									}
								>
									<RiDeleteBinLine color="black" size={18} />
								</ButtonBase>
							)}
						</Box>

						<Box>
							<Typography variant="body2" className={classes.subtotal}>
								{getPricingString(totalPrice, currency)}
							</Typography>
						</Box>
					</Box>
					{startDateExpired && (
						<Box>
							<Typography variant="body2" className={classes.notAvailableText}>
								{startDateExpired ? t('Cart.startDateExpired', 'Start time expired') : ''}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		details: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-start',
			margin: theme.spacing(3, 0),
		},
		productImageWrapper: {
			height: 60,
			width: 60,
			border: '1px solid #d4d4d4',
			borderRadius: 4,
			padding: theme.spacing(0.5),
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.up('md')]: {
				height: 76,
				width: 76,
			},
		},
		productImage: {
			maxWidth: '100%',
			maxHeight: '100%',
		},
		productName: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			paddingLeft: theme.spacing(2),
		},
		productDetail: {
			fontSize: '1.3rem',
			fontWeight: 400,
		},
		bottom: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'flex-end',
			marginTop: theme.spacing(2),
		},
		amount: {
			flex: 1,
			marginRight: theme.spacing(2),
			color: 'black',
		},
		removeButton: {
			width: 24,
			height: 24,
		},
		subtotal: {
			fontWeight: 500,
		},
		notAvailableText: {
			color: '#ce0000',
			marginTop: theme.spacing(1.5),
			fontWeight: 'normal',
			[theme.breakpoints.down('lg')]: {
				fontSize: '1.25rem',
			},
		},
	}),
);

export default CartProductsPackageTicketRowItem;
