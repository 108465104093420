import { Languages } from 'common/types';

export const adyenPaymentComponentLocales: string[] = [
	'zh-CN',
	'zh-TW',
	'da-DK',
	'nl-NL',
	'en-US',
	'fi-FI',
	'fr-FR',
	'de-DE',
	'it-IT',
	'ja-JP',
	'ko-KR',
	'no-NO',
	'pl-PL',
	'pt-BR',
	'ru-RU',
	'es-ES',
	'sv-SE',
	'et-ET',
	'lt-LT',
];

export const adyenCustomTranslations = {
	'et-ET': {
		'creditCard.numberField.title': 'Kaardi number',
		'creditCard.expiryDateField.title': 'Kaardi aegumisaeg',
	},
	'lt-LT': {
		'creditCard.numberField.title': 'Kortelės numeris',
		'creditCard.expiryDateField.title': 'Galiojimo pabaigos data',
	},
};

export const allChannelLanguages: Languages[] = [
	'fi',
	'en',
	'de',
	'sv',
	'sk',
	'nl',
	'hu',
	'no',
	'fr',
	'es',
	'it',
	'cs',
	'ru',
	'et',
	'da',
	'lt',
	'sl',
];

export const RENTLE_FREE_LANGUAGES: Languages[] = ['de', 'cs', 'en', 'es', 'et', 'fi'];
