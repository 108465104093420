import React, { useCallback } from 'react';

import { Grid } from '@mui/material';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import { areProductsInCategory } from 'common/modules/products/utils';
import { ProductApi } from 'common/types';
import { isLiftTicketPackageProduct } from 'common/utils/liftTicketUtils';
import { getFromPriceString } from 'common/utils/pricing';
import GridItem from 'components/GridItem';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';
import { getProductImage } from 'utils/products';

import TicketsGridItem from './TicketsGridItem';
import TicketsPackageGridItem from './TicketsPackageGridItem';

interface Props {
	products: ProductApi[];
	categoryId: string;
}

const ProductsGrid = (props: Props) => {
	const { products, categoryId } = props;
	const { logEcomEvent } = useAnalytics();
	const { t, getTranslation } = useTranslation();

	const { getPath } = useRoutes();

	const pricingTables = useSelector(ShopSelectors.shopPricingTables);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const timezone = useSelector(ShopSelectors.shopTimezone);
	const currency = useSelector(ShopSelectors.shopCurrency);

	const normalLiftTicketProducts = useSelector(StockSelectors.normalLiftTicketProducts);
	const _products = products.filter((p) => !isLiftTicketPackageProduct(p));
	const ticketPackageProducts = products.filter(isLiftTicketPackageProduct);

	const productsGridColumnsDesktop = useSelector(ShopSelectors.productGridColumnsDesktop);

	const showLiftTickets =
		!!normalLiftTicketProducts.length &&
		areProductsInCategory(normalLiftTicketProducts, categoryId);
	const showPackageLiftTickets = areProductsInCategory(ticketPackageProducts, categoryId);

	const handleItemClick = useCallback(
		(product: ProductApi | 'lift_ticket') => {
			if (product === 'lift_ticket') {
				logEcomEvent('select_item', {
					categoryId,
					isLiftTicket: true,
				});
			} else {
				logEcomEvent('select_item', {
					categoryId,
					productId: product.id,
				});
			}
		},
		[categoryId, logEcomEvent],
	);

	return (
		<Grid container spacing={2}>
			{showLiftTickets && (
				<Grid item xs={6} sm={productsGridColumnsDesktop}>
					<TicketsGridItem onClick={() => handleItemClick('lift_ticket')} />
				</Grid>
			)}
			{showPackageLiftTickets &&
				ticketPackageProducts.map((packageProduct) => (
					<Grid item key={packageProduct.id} xs={6} sm={productsGridColumnsDesktop}>
						<TicketsPackageGridItem
							packageProduct={packageProduct}
							onClick={() => handleItemClick('lift_ticket')}
						/>
					</Grid>
				))}
			{_products.map((product) => (
				<Grid item key={product.id} xs={6} sm={productsGridColumnsDesktop}>
					<GridItem
						title={getTranslation(product.name)}
						description={getFromPriceString(
							{
								product,
								pricingTables,
								openingHours,
								currency,
								startDate: null,
								channel: 'ONLINE',
								timezone,
							},
							t,
						)}
						imgUrl={getProductImage(product)}
						linkUrl={getPath('product', { params: { productId: product.id } })}
						onClick={() => handleItemClick(product)}
						imageResize="contain"
					/>
				</Grid>
			))}
		</Grid>
	);
};

export default ProductsGrid;
