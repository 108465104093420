import { I as _I } from 'ts-toolbelt';
import { z } from 'zod';

import { TimeUnit } from 'common/constants/timeUnits';
import { Address } from 'common/modules/atoms/addresses';
import { CookieSettings } from 'common/modules/atoms/cookies/types';
import { ItemPricing, OrderPricing, OrderPricingForEmail } from 'common/modules/atoms/pricing';
import { ShopBilling } from 'common/modules/billing';
import { CustomDomain } from 'common/modules/customDomains/types/dbDocument';
import { OnlineLayoutSettings } from 'common/modules/customization/types';
import { OrderDeliveryService, OrderInfoDelivery } from 'common/modules/delivery/types';
import {
	BaseProductVariant,
	ProductVariant,
	VariantProperty,
} from 'common/modules/inventory/types';
import { InventoryBlocker } from 'common/modules/inventoryBlockers';
import { OpeningHours } from 'common/modules/openingHours';
import { OrderProductStock } from 'common/modules/orders/products/types';
import {
	OnlinePaymentMethod,
	PaymentProvider,
	PaymentResult,
	RecurringOnlinePaymentProvider,
	RecurringPaymentMethod,
	ShopOnlinePaymentMethod,
	ShopOnlinePaymentMethodObject,
} from 'common/modules/payments/types';
import { ShopFeatures } from 'common/modules/plans';
import {
	PhotoUploadedType,
	SkidataKeycardType,
	SkidataProps,
	SkidataTicketValidity,
} from 'common/modules/skidata';
import { StartTimesConfig } from 'common/modules/startTimes';
import {
	OrderProductSubscription,
	OrderSubscription,
	OrderSubscriptionPayment,
	ProductSubscriptions,
} from 'common/modules/subscriptions/types';
import { Languages, LocaleField } from 'common/modules/translations';
import { AccessClaims } from 'common/modules/users/types';
import {
	CapabilityOptionName,
	CustomerPersona,
	IndustryCategory,
	ShopAnalytics,
} from 'common/services/analytics/tractionAnalytics';
import { stringEnum } from 'common/utils/objects';

import { DateFormat, MomentDateFormat, StartDay, TimeFormat } from '../utils/dateUtils';
import { AdyenCommissionSplit, AmountObject } from './adyen';
import { MAX_OBJECT_DEPTH } from './dotObject';
import { ProductTypes, SizeChartType, UnitSystemType, UserDetailName } from './enums';
import { DayType, EnumValues, OrderPayment, ShopApi, _PaymentObject } from './index';
import { ShopIntegrationData } from './integrations';

export type NestedPartial<T> = {
	[P in keyof T]?: Partial<T[P]>;
};

export type DeepPartial<
	T,
	I extends _I.Iteration = _I.IterationOf<0>
> = MAX_OBJECT_DEPTH extends _I.Pos<I>
	? never
	: T extends Function
	? T
	: T extends (infer R)[]
	? R[]
	: T extends object
	? { [P in keyof T]?: DeepPartial<T[P], _I.Next<I>> }
	: T;

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export type RecursivePartial<T> = {
	[P in keyof T]?: RecursivePartial<T[P]>;
};

/*
    Remote data
*/

export type RemoteData<T> =
	| { kind: 'LOADING' }
	| { kind: 'ERROR'; error: string }
	| { kind: 'FETCHED'; data: T; loading?: boolean };

export interface PendingDeposit {
	id: string;
	createdAt: string;
	orderStartDate: string;
	// TODO Add to pending deposit requests
	initialDepositReserveTime?: string;
	shopId: string;
	orderId: string;
	amount: AmountObject;
	recurringData: RecurringData;
	returnUrl: string;
	failedAttempts?: {
		timestamp: string;
		error: string;
	}[];
	nextRetryTime?: string;
	active: boolean;
	paymentMethodName?: RecurringPaymentMethod;
}

export type ApiAccessType = 'ordersPaid';

export type ApiType = 'TEKSO_POS_INTEGRATION' | 'OSKAR_POS_INTEGRATION';

export interface ApiKeyApi {
	id: string;
	shopId: string | null;
	enabled: boolean;
	access: ApiAccessType[];
	type: ApiType;
}

export interface OrderObject {
	rentalInfo: OrderInfo;
	products: OrderProduct[];
	shoppers: Shopper[];
	orderDelivery?: OrderDeliveryService;
	subscriptionPayments?: OrderSubscriptionPayment[];
}

export interface ItemsPerPeriod {
	startDate: string | null;
	endDate: string | null;
	items: Array<{
		productId: string;
		quantity: number;
		variantIds: string[];
		skuIds: string[];
		unavailable: InventoryBlocker;
	}>;
}

export interface PartialReservation {
	startDate: string | null;
	endDate: string | null;
	units: number;
	skuIds: string[];
}

export interface Reservation {
	id: string;
	created: string;
	startLocationId: string;
	endLocationId: string;
	includedVariantIds: string[];
	skuIds: string[];
	itemsPerPeriod: ItemsPerPeriod[];
	channel: Channel;
}

export interface SalesReservation {
	id: string;
	created: string;
	startLocationId: string;
	endLocationId: string;
	includedVariantIds: string[];
	skuIds: string[];
	items: Array<{
		productId: string;
		quantity: number;
		variantIds: string[];
		skuIds: string[];
	}>;
	channel: Channel;
}

export type FixedStockProperty = 'soleLength' | 'bindingType' | 'dinVariation';

export interface Availability {
	[date: string]: number | null;
}

export interface ByVariant<T> {
	[variantId: string]: T;
}

export interface BySku<T> {
	[skuId: string]: T;
}

export interface ById<T> {
	[id: string]: T;
}

export interface ByProductId<T> {
	[productId: string]: T;
}

export interface ByLocationId<T> {
	[locationId: string]: T;
}

export type ByIndustry<T> = {
	[key in IndustryCategory]?: T;
};

export type ByCapabilityOption<T> = {
	[key in CapabilityOptionName]?: T;
};

export type ByCustomerPersona<T> = {
	[key in CustomerPersona]?: T;
};

export interface StartTimeCount {
	[date: string]: number;
}

export interface ItemAmounts {
	[variantId: string]: number;
}

export interface VatTable {
	vatValues: Array<{
		value: number;
		description: string;
	}>;
	defaultVat: number;
}

export interface TaxRow {
	// TODO Decide if we should use id or not
	id: string;
	description: string;
	taxes: {
		label: string;
		rate: number;
	}[];
	/**
	 * User if multiple tax rates used, but only one tax label is used in consumer channels
	 */
	combinedLabel?: string | null;
	isDefault: boolean;
}

export interface StockTaxRate {
	// TODO Decide if we should use id or not
	id: string;
	taxes: {
		label: string;
		rate: number;
	}[];
	/**
	 * User if multiple tax rates used, but only one tax label is used in consumer channels
	 */
	combinedLabel?: string | null;
}

/*
    SHOP TYPES
*/
type LocationId = string;
export interface LocationUrls {
	[locationUrl: string]: LocationId;
}

export interface ShopUrlApi {
	shopId: string;
	storeUrl?: string;
	customDomains?: string[];
	primaryCustomDomain?: string | null;
	locationUrls?: LocationUrls;
	urlType?: string;
	/**
	 * Field for migrating to use new online. Existence of the field is checked in old online to determine whether to redirect user or not.
	 * This is added through a db trigger cloud function, and should not be edited or used for other purposes.
	 */
	useOldOnline?: boolean;
}

export interface AdminApi {
	shopNumbering: number;
	templateIdsByIndustry?: ByIndustry<string[]>;
	// collection
	apiKeys?: {
		[key: string]: ApiKeyApi;
	};
}

export interface Shop {
	shopInfo: ShopApi;
	publicInfo: ShopPublicInfo;
	shopUrls?: ShopUrlApi;
	readOnly?: ShopReadOnlyData;
	shopAnalytics?: ShopAnalytics;
}

export type ShopUrls = Pick<ShopUrlApi, 'storeUrl' | 'locationUrls' | 'customDomains'>;

export interface AccountingGroup {
	id: string;
	name: string;
}

export type WithId<T> = T & { id: string };

export type NotificationRecipient = 'merchant' | 'store';
export interface EmailNotification {
	enabled: boolean;
	emails: string[] | null;
}

export interface ShopAdminData {
	paymentCommission: {
		online: {
			rental: Commission;
			sale: Commission;
		};
		store: {
			rental: Commission;
			sale: Commission;
		};
	};
}

export interface Commission {
	currency?: Currency;
	fixedInCents: number;
	percentageInDecimals: number;
}

export type PaymentCommission = {
	[paymentMethodName in ShopOnlinePaymentMethod]?: Commission;
};

export interface ShopReadOnlyData {
	paymentMethodCommission?: PaymentCommission;
	orderNumber: number;
	transferNumber?: number;
	live: boolean;
	shopId: string;
	billing: ShopBilling;
	features: ShopFeatures;
	integrations?: ShopIntegrationData;
	customDomains?: ById<CustomDomain>;
	adyenTransactionLedgerLastLine?: { [currency: string]: number };
	meta?: {
		affiliateId: string;
	};
}

export type DinCollectionMethod = null | 'manual' | 'automatic';

export interface CancellationObject {
	beforeHours: number | '';
	refundPercentage: number | '';
	displayAs?: TimeUnit; //Non-existing field treated as "hours"
}

export type UrlType = 'store';

/*
    TERMS
*/

export interface TermsEmailVerificationData {
	type: 'tos' | 'privacy';
	url: string;
	shop: Shop;
}

/*
    CURRENCY & PAYMENTS
*/

export type Currency = string;

/*
    DISCOUNT CODES
*/

export const zAppliedDiscountCodeObject = z.object({
	quantity: z.number().int(),
	totalDiscountValue: z.number().int(),
});
export const zAppliedDiscountCodes = z.record(
	z.string().describe('Discount code'),
	zAppliedDiscountCodeObject,
);

export type AppliedDiscountCodes = z.infer<typeof zAppliedDiscountCodes>;
export type AppliedDiscountCodeObject = z.infer<typeof zAppliedDiscountCodeObject>;

/*
    EMAILS
*/

export interface ContractEmailData {
	recipientEmail: string;
	responsiblePerson: ResponsiblePersonDetails | Shopper;
	language: Languages;
	shopPublicInfo: ShopPublicInfo;
	shoppersData: ShopperDataForContractEmail[];
	locationId: string;
	termsPDFs: Array<{ name: string; content: string }>;
	generalTermsPDF: string;
	isTest?: boolean;
}

export interface ShopperDataForContractEmail {
	name: string;
	rental_duration?: string;
	products: ProductDataForContractEmail[];
	end_date?: string;
	age_group?: string;
	weight?: string;
	height?: string;
	foot_size?: string;
	experience_level?: string;
	activity?: string;
}

export interface ProductDataForContractEmail {
	name: string;
	additional_products: any[];
}

/**
 * PRODUCT TYPES
 * */

export type ProductType = ProductTypes;

export interface PartialVariant {
	variantId: string;
	priceIncrease: number | '';
	variantValues: LocaleField[];
	internalId?: SkidataKeycardType;
	// Temporary only to store new variant ID
	_newProductVariantId?: string;
}

export interface SegmentPricing {
	segments: string[];
	useSavedPricingTable?: boolean;
	savedPricingTable?: string;
	pricingTable: PricingItem[];
	fixedPrice: number | '';
}

export interface CustomValue {
	name: string;
	value: number | string | null;
}

export interface CustomValueToCollect {
	name: string;
	type: 'number' | 'text';
}

export interface SegmentMap {
	externalSegmentId: string;
	externalSegmentName: string;
	rentleSegment?: 'kid' | 'teen' | 'adult' | 'senior';
	hidden?: boolean;
	name?: LocaleField;
}

interface StartTimeSlotsBase {
	orderLimit: number | null;
	fixedTimes?: FixedTimesObject[];
	outsideOpeningHours?: boolean;
	intervalMinutes?: number;
	type: 'interval' | 'fixed';
}

export interface StartTimeInterval extends StartTimeSlotsBase {
	type: 'interval';
	intervalMinutes: number;
}

export interface StartTimeFixed extends StartTimeSlotsBase {
	type: 'fixed';
	fixedTimes: FixedTimesObject[];
	outsideOpeningHours: boolean;
}

export type StartTimeSlots = StartTimeInterval | StartTimeFixed;

export type FixedTimesDayType = DayType | 'custom';

export interface FixedTimesObject {
	dayType: FixedTimesDayType;
	customDate?: string;
	time: string;
}

export interface SkiDataProperties {
	requirePhoto?: boolean;
	validity?: SkidataTicketValidity;
}

export interface BaseProduct {
	shopId: string;
	set: boolean;
	image?: string;
	images?: string[];
	name: LocaleField;
	categoryIds?: string[];
	vatPercent: number;
	terms?: Terms;
	description?: LocaleField;
	additionalDetails?: AdditionalDetails[];
	type?: ProductType;
	highlightProduct: boolean;
	segmentFilters?: string[];
	collectProductCode?: boolean;
	customValuesToCollect?: CustomValueToCollect[];
	userDetails?: UserDetail[];
	bookingBuffer?: BookingBuffer;
	collectDin?: boolean;
	collectBootSoleLength?: boolean;
	external?: string | 'LIFT_TICKET';
	offerLiftTickets?: boolean;
	segmentMapping?: SegmentMap[];
	/**
	 * @deprecated Use `startTimes` instead
	 */
	startTimeSlots?: StartTimeSlots;
	startTimes?: StartTimesConfig;
	isKeycard?: boolean;
	externalId?: string;
	externalCategoryIds?: string[];
	externalName?: LocaleField;
	externalPackageId?: string;
	packageId?: string;
	skidataProperties?: SkiDataProperties;
}

export interface ProductApi extends BaseProduct {
	id: string;
	created: string;
	additionalProductIds?: string[]; // PRODUCT ID:S
	setIncludes?: ByProductId<SetIncludesItem>;
	additionalProduct: boolean;
	useSavedPricingTable?: boolean;
	savedPricingTable?: string;
	pricing?: PricingItem[];
	segmentPricings?: SegmentPricing[];
	extensionPricing?: ExtensionBasePrice;
	hidden?: boolean; // DEPRECATED! Not to be used anymore, replaced by hiddenFromChannels
	hiddenFromChannels?: {
		STORE?: boolean;
		ADMIN?: boolean;
		ONLINE?: boolean;
	};
	stockProperties?: string[];
	orderIndex: number | null;
	maintenanceTimeMinutes?: number | null; // duration in minutes
	visibleInLocations?: string[];
	customAccountingGroups?: string[];
	deliveryOptionIds?: string[];
	// NEW INVENTORY PROPERTIES
	variants: {
		properties: VariantProperty[];
		options: ProductVariant[];
	};
	onlineLimit?: number | null;
	sales: {
		enabled: boolean;
		basePrice: number | null;
	} | null;
	rentals: {
		enabled: boolean;
		basePrice: number | null;
	} | null;
	subscriptions: ProductSubscriptions | null;
	fromTemplate?: boolean;
}

export interface SetIncludesItem {
	units: number;
	index: number;
}

export interface ProductApi_LEGACY {
	variantProperties?: LocaleField[];
	productVariants?: PartialVariant[];
}
export interface SetProduct extends BaseProduct {
	selectedAsAdditional: boolean;
	productCode?: string;
	customValues?: CustomValue[];
	productApiId: string;
	removedFromParent?: boolean;
	returnedDate?: string;
	dinValues?: ProductDinValues;
	unavailable?: InventoryBlocker;
	// New Inventory properties
	variant: BaseProductVariant | undefined;
	stock: OrderProductStock;
	purchaseType: PurchaseType;
}

export interface SetProductWithParentId extends SetProduct {
	parentId: string;
}

export interface MatchedProduct {
	orderProductId: string;
	productApiId: string;
	setIndex: null | number;
	productCode: string | undefined;
	selectedVariant: BaseProductVariant | undefined;
}

export interface OrderProductWithUnits extends OrderProduct {
	units: number;
	includedIds: string[];
	unConfirmed?: boolean;
	liftKeycardNumbers?: string[];
}

export interface ProductDinValues {
	customDin?: number | null | '';
	calculatedDin?: number | null;
	soleLength?: string | null | '';
	dinVariation?: string | null;
}

export interface OrderProduct extends BaseProduct {
	id: string;
	created: string;
	shopperId: string;
	rentalId: string;
	productApiId: string;
	productApiIds: string[];
	// Included STOCK variant IDs
	includedVariantIds: string[];
	setProducts: SetProduct[];
	selectedAsAdditional: boolean;
	productCode?: string;
	dinValues?: ProductDinValues;
	customValues?: CustomValue[];
	startDate: string | null;
	endDate: string | null;
	endDateReturned: string | null;
	cancelled?: boolean;
	durationName: null | LocaleField;
	durationType: DurationType;
	rentalDurationInSeconds: number;
	charge: OrderPayment;
	deposit: OrderPayment | null;
	startLocationId: string;
	endLocationId: string;
	unavailable: InventoryBlocker;
	// FALLBACK FOR IN-STORE
	segment?: string | null;
	skidataOrderItemId?: string;
	skidataTicketItemId?: string;
	externalSegment?: SegmentMap;
	ticketId?: string;
	liftKeycardNumber?: string;
	pricing: ItemPricing;
	subscription?: OrderProductSubscription;
	packageId?: string;
	stockPackageId?: string;
	externalPackageId?: string;
	// NEW INVENTORY PROPERTIES
	variant: BaseProductVariant | undefined;
	// Summary from whole product, including set products
	summary: {
		variantIds: string[];
		skuIds: string[];
		itemCodes: string[];
	};
	stock: OrderProductStock;
	purchaseType: PurchaseType;
	fulfillmentDate: string | null;
}

export const PurchaseTypes = stringEnum(['rental', 'sales', 'subscription']);
export type PurchaseType = EnumValues<typeof PurchaseTypes>;
export const AllPurchaseTypes: PurchaseType[] = Object.values(PurchaseTypes);

export type CartProduct = Omit<OrderProduct, 'id' | 'rentalId' | 'shopperId'>;

export type TimePeriod =
	| 'minutes'
	| 'hours'
	| 'days'
	| 'weeks'
	| 'months'
	| 'years'
	| 'days_within_opening_hours'
	| '24h_days';

export type DurationType = '24h' | 'opening_hours';

export interface DateProperties {
	startDate?: string;
	endDate?: string;
	duration?: Duration;
}

export interface PricingItem {
	label?: LocaleField;
	timePeriod: TimePeriod;
	timeValue: number | '';
	multiplier: number | '';
	price: number | '';
	channels?: ('ALL' | 'ONLINE' | 'STORE' | 'ADMIN' | 'HIDDEN')[];
}

export interface ExtensionBasePrice {
	enabled: boolean;
	pricePerHour: number;
	pricePerDay: number;
}

export interface ExtensionDuration {
	hours: number;
	days: number;
}

export interface Duration {
	durationInSeconds: number;
	durationType: DurationType;
	durationName: LocaleField | null;
}

export interface DurationWithPrice {
	disabled: boolean;
	timePeriod: TimePeriod | 'fixed';
	timeValue: number;
	price: number;
	durationInSeconds: number;
	endTime?: string;
	label?: LocaleField;
}

export interface Terms {
	deposit?: number | '';
	userFields?: UserDetail[];
	lateReturn?: LateReturnItem;
	lostOrDamaged?: LostOrDamagedItem;
	otherTerms?: OtherTermItem[];
	additionalKilometers?: AdditionalKilometers;
	excess?: {
		deductible: boolean;
		deductOptions: Array<LiabilityOption>;
	};
	refuelPolicy?: RefuelPolicy;
}

export interface LiabilityOption {
	amount: number | '';
	price: number | '';
}

export interface AdditionalDetails {
	name: LocaleField;
	detail: LocaleField;
}

export interface UserDetail {
	name: UserDetailName;
	required: boolean;
}

export interface LateReturnItem {
	hour: number | '';
	day: number | '';
}

export interface LostOrDamagedItem {
	maxCompensation: number | '';
	description?: string;
}

export interface OtherTermItem {
	header: string;
	terms: string; // Text field or file (PDF)
}

export interface RefuelPolicy {
	type: 'FULL' | 'EMPTY';
	description?: string;
	chargePerAdditional?: number | ''; // IF NOT GIVEN IN ADVANCE, ASK TO FILL WHEN CHARGING
	maximumCharge?: number | '';
	fixedCharge?: number | '';
}

export interface AdditionalKilometers {
	description?: string;
	includedKilometers: number | '';
	chargePerAdditional: number | '';
	maximumCharge: number | '';
	fixedCharge: number | '';
}

/** END OF PRODUCT TYPES */

/** RENTAL TYPES */

export const zDiscount = z.object({
	percentage: z.union([z.number(), z.literal('')]).optional(),
	amount: z.union([z.number(), z.literal('')]),
});
export type Discount = z.infer<typeof zDiscount>;

export interface OrderPaymentRequest {
	id: string;
	shopId: string;
	orderId: string;
	paymentNumber: number;
	amount: AmountObject;
	paid: boolean;
	shopperIds?: string[];
	productIds?: string[];
	orderDeliveryId?: string;
	paymentAction?: PaymentAction;
}

export interface OrderPaymentRequestData {
	id: string | null;
	shopId: string;
	orderId: string;
	amount: AmountObject;
	paymentNumber: number | null;
	shopperIds: string[];
	productIds: string[];
}

export type TermsAcceptance =
	| {
			accepted: false;
	  }
	| {
			signatureRef?: string;
			accepted: true;
			timestamp: string;
	  };

export interface OrderInfo {
	id: string;
	activeState: ActiveState;
	created: string;
	startDate: string;
	initialStartDate: string;
	startDateException: boolean;
	returnTimeNext: string | null;
	endDateReturned: string | null;
	handlePayment?: boolean;
	charge: OrderPayment;
	deposit: OrderPayment;
	type?: ProductType;
	cancellationPolicy?: CancellationObject[] | null;
	channel: Channel;
	rentalLink?: RentalLinkInfo;
	rentalState: RentalState;
	shopId: string;
	startLocation: Location;
	endLocation: Location;
	live: boolean;
	responsiblePerson: ResponsiblePerson;
	terms: TermsAcceptance;
	additionalInformation?: string;
	shopperNameKeywords: string[];
	shopperIdsWithProducts: {
		[shopperId: string]: string[];
	};
	shopperNames: string[];
	categoryIds: string[];
	categoriesWithoutIds?: string[];
	stockProductIds: string[];
	rentalProductCodes: string[];
	paymentNumber?: number;
	orderNumber?: number;
	prepared?: boolean;
	includedProductTypes?: ProductType[];
	skidataProps?: SkidataProps;
	services?: {
		delivery?: OrderInfoDelivery;
	};
	subscription?: OrderSubscription;
	pricing: OrderPricing;
	bookingComment?: {
		label?: LocaleField;
		value: string;
	};
	customCheckoutFields?: CustomCheckoutField[];
	purchaseTypes: Array<PurchaseType>;
	/**
	 * If purchaseTypes is an array with length one, this should be set to that value. This is so we can query by it. - Juuso, Aug 19th 2022
	 */
	purchaseType: PurchaseType | null;

	/**
	 * Only exists when the order uses ACS.
	 */
	acs?: {
		/**
		 * Describes the status of asynchronously creating/updating the order in ACS.
		 */
		updateState: 'PENDING' | 'IN_PROGRESS' | 'SUCCESS' | 'FAILED';
		/**
		 * ISO timestamp of the last known update state.
		 */
		updateStateTs: string;
		/**
		 * Error detailing the update failure. Null if updateState is not FAILED.
		 */
		updateError: {
			message: string;
			raw: any;
		} | null;
	};
}

export interface CustomCheckoutField {
	label: LocaleField | null;
	value: string | boolean | null;
}

export type ResponsiblePerson =
	| {
			external: false;
			shopperId: string;
	  }
	| {
			external: true;
			person: ResponsiblePersonDetails;
	  };

export interface ResponsiblePersonDetails {
	id: string;
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	marketing: boolean;
	language?: Languages;
	homeAddress?: Address;
	accommodation?: string;
	savedPaymentInfo?: RecurringData;
	meta?: {
		auth?: {
			uid?: string;
			email?: string;
		};
	};
	customerId?: string;
}

export const DEPOSIT_TYPES = ['payment', 'other', 'no-deposit'] as const;
export const zDepositType = z.enum(DEPOSIT_TYPES);
export type DepositType = z.infer<typeof zDepositType>;

export interface SavedShopper {
	id: string;
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	marketing: boolean;
	regularCustomer?: boolean;
	paymentToken?: string;
	language?: Languages;
	category?: string;
	segment?: string;
	userProperties: UserProperties;
	additionalInformation?: string;
	productsReturned?: boolean;
	productIds: string[];
}

export interface RecurringData {
	cardReference: string;
	shopperReference: string;
	// This might be undefined in DB, in that case default to "ADYEN"
	provider: RecurringOnlinePaymentProvider | undefined;
	cardDetails?: PaymentCardDetails | null;
}

export interface PaymentCardDetails {
	expiryMonth: number | null;
	expiryYear: number | null;
	lastFour: string | null;
	brand: string | null;
}

export interface ShopperSkidataProperties {
	photoUploaded?: PhotoUploadedType;
}

export interface Shopper extends ResponsiblePersonDetails {
	id: string;
	created: string;
	firstName: string;
	lastName: string;
	phone: string;
	email: string;
	marketing: boolean;
	homeAddress?: Address;
	accommodation?: string;
	regularCustomer?: boolean;
	language?: Languages;
	category?: string;
	categoryId?: string;
	categoryIds?: string[];
	segment?: string;
	userProperties: UserProperties;
	additionalInformation?: string;
	productsReturned?: boolean;
	productIds: string[];
	rentalId: string;
	shopId: string;
	skidataProperties?: ShopperSkidataProperties;
}

export interface Measurement<T = string> {
	value: string;
	unit: T;
	sizeChart?: SizeChartType;
}

export interface UserProperty {
	value: string;
	unit?: string;
	localInput?: Measurement;
}

export interface UserProperties {
	[property: string]: UserProperty;
}

export type ExperienceLevel = 'beginner' | 'intermediate' | 'advanced';

export type EventType = 'RENTAL_STATE' | 'RENTAL_DURATION' | 'ACTIVE_STATE';

export type HistoryEventType =
	| 'STATE_CHANGE'
	| 'DURATION_CHANGE'
	| 'PRICE_CHANGE'
	| 'PRODUCT_ADD'
	| 'PRODUCT_DELETE'
	| 'USER_INFO_CHANGE';

export interface OrderHistory {
	id: string;
	timestamp: string;
	events: HistoryEventType[];
	priceChange: number;
	documentType: 'order' | 'orderProduct' | 'orderShopper';
	data: Partial<OrderInfo | Shopper | OrderProduct>;
}

export interface HistoryObject {
	id: string;
	timestamp: string;
	eventType: EventType;
	field: string;
	previousValue: any;
	newValue: any;
}

//TODO!! Ja myös puhelinnumerokentän fixaus!
export interface RentalLinkInfo {
	linkId?: string;
	firstName?: string | null;
	lastName?: string | null;
	phone?: string | null;
	email?: string | null;
	comment?: string;
	websiteLink?: boolean;
	websiteUserEmail?: string;
	sid?: string;
	smsDeliveryStatusHistory?: [
		{
			status: string;
			timestamp: string;
		},
	];
}

export interface PayLinkInfo {
	linkId?: string;
	sendSms?: boolean;
	sendEmail?: boolean;
	comment?: string;
	websiteLink?: boolean;
	websiteUserEmail?: string;
}

export const RENTAL_STATES = ['INIT', 'BOOKED', 'ACTIVE', 'COMPLETED'] as const;
export type RentalState = typeof RENTAL_STATES[number];

export const ACTIVE_STATES = [
	'RENT_INIT',
	'PAYLINK_SENT',
	'PAYLINK_OPEN',
	'PAYLINK_PAID',
	'PAYLINK_CONFIRMED',
	'ADMIN_BOOKED',
	'API_BOOKED',
	'STORE_BOOKED',
	'ONLINE_BOOKED',
	'RENT_OUT',
	'RENT_ENDED',
	'RENT_CANCEL',
] as const;

export type ActiveState = typeof ACTIVE_STATES[number];

/** Type of payment */
export type PaymentType =
	| 'CHARGE'
	| 'DEPOSIT'
	| 'REFUND'
	| 'OTHER'
	| 'EXTRA'
	| 'CASH_CHANGE'
	| 'CARD_AUTH'
	| 'INVOICE_CHARGE';

export type PaymentRecordType = 'PendingCredit' | 'PendingDebit';

/**
 * Payment method
 * MANUAL = manually "set as paid"
 */
export type PaymentMethod =
	| 'CASH'
	| 'MANUAL'
	| 'POS'
	| 'CARD_TERMINAL'
	| 'INVOICE'
	| 'VOUCHER'
	| OnlinePaymentMethod
	| string;

export type PaymentStatus =
	| 'CREATED'
	| 'PENDING'
	| 'FAILED'
	| 'DECLINED'
	| 'SUCCESSFUL'
	| 'ADDITIONAL_DETAILS_REQUIRED';

export type PaymentState =
	| ''
	| 'AUTHORISATION'
	| 'AUTHORISATION_PENDING'
	| 'AUTHORISATION_FAILED'
	| 'PARTIAL_CAPTURE'
	| 'CAPTURE'
	| 'CAPTURE_FAILED'
	| 'CAPTURE_PENDING'
	| 'PARTIAL_REFUND'
	| 'REFUND'
	| 'REFUND_PENDING'
	| 'REFUND_FAILED'
	| 'CANCEL'
	| 'CANCEL_PENDING'
	| 'CANCEL_FAILED';

export const CHANNELS = ['ADMIN', 'STORE', 'ONLINE', 'PAYLINK', 'CONSUMER', 'API'] as const;
export type InternalChannel = 'INTERNAL';
export type Channel = typeof CHANNELS[number];
export type ChannelWithInternal = Channel | InternalChannel;

export type ReservationType = 'RESERVATION' | 'WALK-IN';
export interface PaymentAccountingRow {
	date: string; // YYYY-MM-DD
	type: 'SALES' | 'REFUND' | 'BOOKING';
	receiptNumber: number | null;
	receiptId: string | null;
	reference: string | null;
	channel: ChannelWithInternal;
	netAmount: number;
	taxRows: AccountingRow[];
	methodRows: AccountingRow[];
	itemNameRows?: AccountingRow[];
	customAccountingGroupRows?: AccountingRow[];
	taxAmount: number;
	totalAmount: number;
	paymentId: string;
	orderId: string;
	feeRows: AccountingRow[];
	currency: string;
	locationId: string | null;
	transactionIds: string[];
}

export interface ReceiptAccountingRow {
	receiptNumber: number | null;
	receiptId: string | null;
	paymentId: string;
	netAmount: number | null;
	taxAmount: number | null;
	totalAmount: number;
	orderId: string;
}

export interface AccountingRow {
	id: string;
	units?: number;
	name: string;
	netAmount: number | null;
	taxAmount: number | null;
	taxPercent: number | null;
	totalAmount: number;
	paymentIds: string[];
}

export interface SalesReportProductData {
	name: string;
	revenue: number;
	count: number;
}

export interface RentalReport {
	revenue: number;
	rentalsAmount: number;
	shoppers: number;
}

export interface SalesReport extends RentalReport {
	topProducts: {
		[productId: string]: SalesReportProductData;
	};
}

export interface SaleDatapoint {
	timestamp: string;
	hour: number;
	dayNumber: number;
	weekday: Weekday;
	revenue: number;
}

export interface DashboardDatapoint extends SaleDatapoint {
	productAmount: number;
	cominedDayAndMonth?: string;
}

export interface PaymentEvent {
	code: PaymentAction;
	timestamp: string;
	info?: any;
	referenceId?: string;
}

export type ErrorCondition =
	| 'Aborted'
	| 'Busy'
	| 'Cancel'
	| 'DeviceOut'
	| 'InsertedCard'
	| 'InProgress'
	| 'LoggedOut'
	| 'MessageFormat'
	| 'NotAllowed'
	| 'NotFound'
	| 'PaymentRestriction'
	| 'Refusal'
	| 'UnavailableDevice'
	| 'UnavailableService'
	| 'InvalidCard'
	| 'UnreachableHost'
	| 'WrongPIN';

export interface ReceiptItem {
	item_units: string;
	item_name: string;
	item_additionalProducts: ReceiptAdditionalItem[];
	item_price: string;
}

export interface ReceiptAdditionalItem {
	name: string;
	price: string;
	units: string;
}

export interface ReceiptRequest {
	startDate: string;
	endDate: string;
	paymentDate: string;
	totalCharge: AmountObject;
	products: ReceiptItem[];
	shopPublicInfo: ShopPublicInfo;
	shopId: string;
	to: string;
	lang: Languages;
	contractPDF?: string;
	generalTermsPDF?: string;
	discount: {
		amount: string;
		available: boolean;
	};
}

export interface CancellationPolicyEmailData {
	cancellations: {
		beforeHours: string;
		refundPercentage: number;
		cancelExpired: boolean;
		cancelBefore: string;
	}[];
	noCancellationHours: string | null;
}

export interface OnlineOrderRequest {
	shopId: string;
	lang: Languages;
	shopPublicInfo: ShopPublicInfo;
	order: OrderObject;
	localStartDate: string;
	localEndDate: string;
	categoriesWithTerms: CategoryWithTerms[];
	isMissingDetails?: boolean;
	orderLink?: string;
	cancellationPolicy?: CancellationPolicyEmailData;
}

export interface ExtendOrderRequest {
	orderId: string;
	extensionDuration: ExtensionDuration;
}

export interface ShopPublicInfoForConfirmationEmail {
	name: string;
	phone: string;
	email: string;
	address: string;
	currency: CurrencyObject;
	website?: string;
	businessId?: string;
	img?: string;
	dateFormat: DateFormatObject;
	hidePhone: boolean;
	hideEmail: boolean;
}

export interface LanguageForConfirmationEmail {
	[lang: string]: boolean;
}

export interface DynamicTemplateData {
	lang: LanguageForConfirmationEmail;
	logoImgUrl: string;
	startDate: string;
	endDate: string;
	startLocation: string;
	endLocation: string;
	totalCharge: string;
	reservationId: string;
	utcTime: string;
	shopPublicInfo: ShopPublicInfoForConfirmationEmail;
	shoppers: ShopperForConfirmationEmail[];
	responsiblePerson: ResponsiblePersonForConfirmationEmail;
	discount: null | string;
	pricing: OrderPricingForEmail;
	texts: object;
}

export interface ConfirmationEmailRequest {
	from: {
		email: string;
		name: string;
	};
	replyTo: {
		email: string;
	};
	templateId: 'd-231b89001eba42e18dc16aad02e50a8c' | 'd-ebe63eb1f72b450a8a081842740b685e';
	personalizations: {
		to: {
			email: string;
		}[];
		dynamicTemplateData: DynamicTemplateData;
	}[];
	attachments: Array<{
		content: string;
		type: string;
		filename: string;
		disposition: string;
	}>;
}

export interface ResponsiblePersonForConfirmationEmail {
	name: string;
	phone: string;
	email: string;
}

export interface ShopperForConfirmationEmail {
	name: string;
	userProperties: {
		property: string;
		value: string;
	}[];
	products: ProductForConfirmationEmail[];
}

export interface ProductForConfirmationEmail {
	name: string;
	quantity: number;
	charge: string;
	listPrice: string;
	duration: string;
	deposit?: string;
	newKeycard?: {
		quantity: number;
		charge: string;
		listPrice: string;
	};
	existingKeycard?: {
		keycardId?: string;
	};
	purchaseType: PurchaseType;
	endDate: string | null;
	prodSubText: string | null;
}

// SHOPPER API TYPES
export interface ShopperApi {
	id: string;
	firstName?: string;
	lastName?: string;
	phone?: string;
	email?: string;
	marketing: boolean;
	regularCustomer?: boolean;
	paymentToken?: string;
	language?: Languages;
}

export interface NewUserRequest {
	email: string;
	shops: string[];
	activeShopId: string;
	accessClaims?: AccessClaims;
}

export interface UserCreationResponse {
	didCreateUser: boolean;
	userCreationMessage: string;
}

// SHOP API TYPES

export type ShopRentalType = 'car' | 'bike';

export type LocationVisibleChannel = Exclude<Channel, 'PAYLINK' | 'CONSUMER' | 'API'>;

export interface Location extends Address {
	name: string;
	id: string;
	/**
	 * @deprecated ?
	 * This is not visible in any UIs, but is available in the Translations section. We only show `additionalInformation` in the UI.
	 */
	description?: LocaleField;
	channels?: LocationVisibleChannel[];
	phone?: string;
	email?: string;
	hidePhone?: boolean;
	hideEmail?: boolean;
	additionalInformation?: LocaleField;
	orderIndex?: number;
}

export interface LocationWithContact extends Location {
	phone?: string;
	email: string;
}

export interface FileUpload {
	name: string;
	url: string;
}
export interface TermsDocument extends FileUpload {
	verified?: boolean;
}

export type BrandingFont = {
	provider: 'google';
	/**
	 * Valid google font family name, such as 'Open Sans', 'Roboto', 'Roboto Condensed'
	 */
	fontFamily: string;
};
export interface Branding {
	active: boolean;
	mainColor: string;
	buttonRadius: number;
	buttonUppercase: boolean;
	bodyFont?: BrandingFont | null;
	titleFont?: BrandingFont | null;
}

export type ImageStyle = 'thumbnail' | 'fullWidth';

export interface ShopReceiptInfo {
	name: string;
	legalName: string;
	businessId: string;
	visitingAddress: Location | null;
	phone: string;
	receiptOpenTextfield?: string;
}

export interface ShopPublicInfo {
	shopId: string;
	name: string;
	type?: ProductType;
	/**
	 * @deprecated Not in use anymore
	 */
	legalName?: string;
	email: string;
	visitingAddress?: Location;
	shopLocations?: Location[];
	phone?: string;
	websiteUrl?: string | null;
	state?: string;
	vatCode?: string;
	businessId?: string;
	vatId?: string;
	logoImgUrl?: string;
	country?: string;
	timeZone: string;
	termsOfService?: TermsDocument;
	privacyPolicy?: TermsDocument;
	shopNumber?: number;
	taxExcluded: boolean;
	// bankStatementDescription?: string;
	productCategories?: string[];
	productCategories_en?: string[];
	pricingTables?: PricingTableObject;
	noSegments?: boolean;
	segments?: string[];
	segments_en?: string[];
	cancellationPolicy?: CancellationObject[] | null;
	marketingConsentText?: MarketingConsentObject;
	customBranding?: Branding;
	layouts?: {
		ONLINE?: OnlineLayoutObject;
	};
	themesByLocationId?: {
		ONLINE?: ByLocationId<string>;
	};
	termsAcceptanceText?: {
		text: LocaleField;
		active: boolean;
	};
	receiptOpenTextfield?: string;
	languages: Languages[];
	defaultLanguage: Languages;
	activePaymentMethods?: ShopOnlinePaymentMethodObject[];
	onlinePaymentsEnabled?: boolean;
	imageStyle?: ImageStyle;
	currency: CurrencyObject;
	dateFormat?: DateFormatObject;
	inStoreEndText?: InStoreEndText;
	onlineCalendarCustomization?: OnlineCalendarCustomization;
	unitSystem: UnitSystemType;
	cookieSettings?: CookieSettings;
	hideStorePickup?: HideStorePickup;
	checkoutCommentField?: ByLocationId<CheckoutCommentField>;
	disableCheckinAvailabilities?: boolean;
	plugins?: {
		// Tags for store's Google Analytics
		googleAnalytics?: GoogleAnalytics;
	};
	legacySettings?: {
		useCategoryTermsOverwrite?: boolean;
	};
}

export interface OnlineLayoutObject {
	default: OnlineLayoutSettings;
	locations?: ByLocationId<string>;
}
export interface HideStorePickup {
	location?: {
		[id: string]: boolean;
	};
}

export interface MarketingConsentObject {
	text: LocaleField;
	active: boolean;
}
export interface GoogleAnalytics {
	UA?: string[];
	GA4?: string[];
	GTM?: string[];
}

export interface OnlineCalendarCustomization {
	bookedText?: BookedText;
}

export type BookedText = {
	type: BookedTextType;
	customText?: LocaleField;
};

export type BookedTextType = 'CALL_US' | 'FULLY_BOOKED';

export interface InStoreEndText {
	defaultValue: InStoreEndTextRow[];
	location: {
		[id: string]: InStoreEndTextRow[];
	};
	active: boolean;
}

export interface InStoreEndTextRow {
	text: LocaleField;
	helpText: boolean;
}

export interface PricingTableObject {
	[name: string]: PricingItem[];
}

export interface CheckoutCommentField {
	enabled: boolean;
	label: LocaleField;
	required: boolean;
}

// Docs: https://github.com/rentle/rentle-monorepo/issues/510
export interface BookingBuffer {
	daysBefore: number;
	minutesBefore?: number;
	latestTime?: number; // the hour, as in 9 or 18.
}

export interface Category {
	id: string;
	shopId: string;
	name: LocaleField;
	description?: LocaleField;
	terms: TermsDocument | null;
	imageUrl: string | null;
	imageCover?: boolean;
	orderIndex: number | null;
	bookingBuffer?: BookingBuffer;
	noSegments?: boolean;
	external?: string | 'LIFT_TICKET';
	externalId?: string;
	segmentMapping?: SegmentMap[];
	fromTemplate?: boolean;
}

export interface LanguageString {
	[lang: string]: string;
}

export type SearchType = 'NAME' | 'PRODUCT_CODE' | 'CONFIRMATION_NUMBER' | 'ORDER_BARCODE';

export type ShopperSearchType = 'NAME' | 'EMAIL' | 'PHONE';

// REPORT TYPES

export type MetricType = 'revenue' | 'rentals' | 'products' | 'shoppers';

export type Weekday =
	| 'Monday'
	| 'Tuesday'
	| 'Wednesday'
	| 'Thursday'
	| 'Friday'
	| 'Saturday'
	| 'Sunday';

export type salesChartMode = 'month' | 'day' | 'week' | 'year';

/* --- PAYMENTS --- */

export interface HistoryEvent {
	id: string;
	timestamp: string;
	eventType: 'RENTAL_STATE' | 'RENTAL_DURATION';
	field: string;
	previousValue: any;
	newValue: any;
	price: null | number;
}

export interface Receipt {
	sum: number;
	vats: any;
	receiptLines: any[];
	transactionIds: string[];
}

export interface RefundRequest {
	originalTransactionId: string;
	amount?: AmountObject; // If missing, refunding all
	channel: Channel;
	method: PaymentMethod;
}

export interface FeeItemObject extends ItemObject {
	feeDetails?: Commission;
}

export interface ItemObject {
	name: string;
	durationString: string | null;
	units: number | null;
	productId: string | null;
	stockProductId: string | null;
	deliveryId?: string;
	pricing: ItemPricing;
}

export interface TransactionReceiptInfo {
	method: PaymentMethod;
	methodDetails: string | null;
	pspReference: string | null;
	amount: AmountObject;
	transactionId?: string; // Added 20.4.2021 to all receipts
}

export interface ReceiptApi {
	id: string;
	shopId: string;
	shopInfo: ShopReceiptInfo;
	orderId: string;
	paymentId: string;
	receiptNumber: number;
	items: ItemObject[];
	items_OLD?: ItemObject[]; // Temporary, only for type = REFUND types
	timestamp: string;
	transactionsInfo: TransactionReceiptInfo[];
	capture: boolean;
	type?: 'refund';
	originalReceiptNumber?: number; // In case of refund
	invoiceIds?: string[]; //Added 27.4.2022. Only defined in case this receipt is related to invoicing.
}

export interface PaymentApi {
	id: string;
	orderProductIds: string[];
	transactionIds: string[];
	orderId: string;
	shopperId: string | null;
	items: ItemObject[];
	items_OLD?: ItemObject[]; // Temporary, only for paymentAction = REFUND types
	shopId: string;
	channel: ChannelWithInternal;
	paymentType: PaymentType;
	method?: PaymentMethod;
	methods: PaymentMethod[];
	amount: _PaymentObject;
	originalPaymentId?: string | null; // In case of refunds
	timestamp: string;
	usageDate: string;
	receiptNumber: number; // Incremental receipt number by shop
	paymentRequestId?: string | null;
	live: boolean;
	receiptId: string;
	completed: boolean;
	capture: boolean; // Added 15.5
	paymentAction: PaymentAction; // Added 15.5
	state?: PaymentState; // Added 15.5
	locationId: string | null; // Added 25.9. Can be null only in case of Rentle billing payments
	invoiceIds?: string[]; // Added 27.4.2022. Only defined in case this payment is related to invoicing.
}

export type PaymentAction = 'PAYMENT' | 'AUTHORISATION' | 'CAPTURE' | 'REFUND' | 'CANCEL';

export interface TransactionApi {
	/**
	 * Internal ID of the transaction
	 */
	id: string;
	/**
	 * Provider of the transaction. ADYEN for adyen payments, MANUAL for shop-initiated external payments
	 */
	provider: PaymentProvider;
	/**
	 * False in case transaction failed
	 */
	success: boolean;
	/**
	 * Contains sunique Adyen reference value if ADYEN payment
	 */
	pspReference: string | null;
	/**
	 * Creation time
	 */
	timestamp: string;
	/**
	 * ID of shopper
	 */
	shopperId: string | null;
	/**
	 * ID of shop
	 */
	shopId: string;
	/**
	 * Amount of the transaction
	 */
	amount: _PaymentObject;
	/**
	 * Payment type
	 */
	paymentType: PaymentType;
	/**
	 * Paymetn action
	 */
	paymentAction: PaymentAction; // Added 15.5
	/**
	 * Payment method
	 */
	method: PaymentMethod;
	/**
	 * Internal ID of the payment
	 */
	paymentId: string;
	/**
	 * Internal ID of the order
	 */
	orderId: string;
	/**
	 * Added 27.4.2022. Only defined in case this transaction is related to invoice payments.
	 */
	invoiceIds?: string[];
	/**
	 * Original psp reference, in case of refund / cancel / capture
	 */
	originalReference?: string | null;
	/**
	 * Original internal transaction ID in case of refund / cancel / capture
	 */
	originalTransactionId?: string;
	/**
	 * Transaction live or not
	 */
	live: boolean;
	/**
	 * Status of the payment
	 */
	status: PaymentStatus;
	/**
	 * State of the payment
	 */
	state: PaymentState;
	/**
	 * Is payment captured
	 */
	capture: boolean;
	/**
	 * Payment events
	 */
	events: PaymentEvent[];
	/**
	 * Error condition in case of error
	 */
	errorCondition: ErrorCondition | null;
	/**
	 * Error message in case of error
	 */
	errorMessage: string | null;
	/**
	 * Split data from transaction
	 */
	splitData: SplitObject[] | null;
	fee: ItemObject | null;
	/**
	 * Refurring reference if recurring payment
	 */
	recurringReference?: string; // If recurring payment
	cardOnFile: boolean;
	cardToken: string | null;
	refunds: Array<{
		amount: AmountObject;
		transactionId: string;
	}>;
	/** DEPRECATED */
	refund?: null | {
		amount: AmountObject;
		transactionId: string;
	};
	refundedAmount?: number;
	commission?: {
		amount: AmountObject;
		reference: string | null;
	} | null;
	POIId?: string;
	POITransactionId?: string;
	details?: any;
	response?: any;
	progress?: TransactionProgress;
	progressHistory?: TransactionProgressHistory[];
}

// Transactions which aren't found in the database but appear in the daily Adyen summary
export interface TransactionMissingApi {
	/*
		The payment provider
	 */
	provider: 'ADYEN';
	/*
		The account number of the merchant in the payment provider
	 */
	providerAccount: string;
	/*
	 * 	The reference in the provider (e.g. PSP reference)
	 */
	providerReference: string;
	/*
	 * 	The payment reference in the provider (e.g. payment PSP reference)
	 */
	providerPaymentReference?: string;
	/*
		The type of transaction (e.g. PendingCredit, PendingDebit)
	*/
	recordType: PaymentRecordType;
	/**
	 * Amount of the transaction
	 */
	amount: AmountObject;
	shopId: string | null;
	date?: string;
	timezone?: string;
	createdAt: string;
}

export interface DailyBalanceRecord {
	currency: string;
	balance: number;
	pendingCredit: number;
	pendingDebit: number;
	balanceOnHold: number | null;
}

// Daily balances stored in different currencies
export interface ShopBalancesApi {
	id: string;
	/*
		The payment provider
	*/
	provider: 'ADYEN';
	/*
		The account number of the merchant in the payment provider
	*/
	providerAccount: string;
	/*
		The account holder reference in the payment provider
	*/
	providerAccountHolder: string;
	/*
		The date and time the report was created in adyen
	*/
	reportTime: string;
	/*
		Stores the balances for each currency
	*/
	balances: Array<DailyBalanceRecord>;
	/*
		The shopId in firestore
	*/
	shopId: string;
}

/*
	Tracking the progress of the transaction
*/
export interface TransactionProgressHistory {
	type: string;
	date: string;
}

// TransactionProgress is going @deprecated
export interface TransactionProgress {
	pendingCreditDate: string | null;
	pendingDebitDate: string | null;
	creditedDate: string | null;
	debitedDate: string | null;
	paidOut: {
		batch: string;
		timestamp: string;
	} | null;
}

export interface PendingRefundOrCancelTransaction {
	id: string;
	created: string;
	pspReference: string | null;
	amount: AmountObject | null;
	splitData: SplitObject[] | null;
	status: PaymentStatus;
	reason: string | null;
	type: 'REFUND' | 'CANCEL';
	originalTransactionId: string;
}

export interface PendingCaptureTransaction {
	id: string;
	created: string;
	pspReference: string | null;
	amount: AmountObject | null;
	splitData: SplitObject[] | null;
	status: PaymentStatus;
	reason: string | null;
	originalTransactionId: string;
	originalPspReference: string;
	description?: string;
	userMessage?: {
		email: string;
		message?: string;
		language?: Languages;
	};
}

export interface PendingOrderRequestApi extends OnlineOrderRequest {
	id: string;
}

export interface PendingExtendRequestApi extends ExtendOrderRequest {
	id: string;
}

export interface PendingTransaction {
	id: string;
	orderId: string;
	origin?: string;
	amount: AmountObject;
	splitData: SplitObject[] | null;
	status: PaymentStatus | 'REVOKED';
	result: null | PaymentResult<'PAYMENT'>;
	redirectData: {
		[key: string]: string;
	} | null;
	paymentData: any | null;
	paymentType: PaymentType;
	capture: boolean;
	saveCard: boolean;
	paymentMethodName?: OnlinePaymentMethod;
	redirectUrl: string;
}

export interface TransactionLedgerApi {
	/*
		The document ID
	 */
	id: string;
	/*
		Service used for the transaction
	 */
	provider: 'ADYEN';
	/*
		Account holder in the provider's platform
	 */
	accountHolder: string;
	/*
		Account number in the provider's platform
	 */
	providerAccount: string;
	/*
		Transaction reference in the provider's account
	 */
	providerReference: string;
	/*
		True if the transaction and payout has already been linked
	 */
	settled: boolean;
	/*
		The next payout where the transaction was accounted
	*/
	payoutId: string | null;
	/*
		Date when the state happened
	 */
	eventDate: string;
	/*
		Type of transaction
	 */
	recordType: string;
	/*
		Default amount (total)
	 */
	amount: AmountObject;
	/*
		When a payout isn't zero, stores the missing/remaining amount
	*/
	unsettledAmount: AmountObject | null;
	/*
		Transaction in Rentle
	 */
	transactionId: string | null;
	/*
		Rentle shopId
	 */
	shopId: string | null;
	/*
		The "line number" in the ledger
	*/
	ledgerOrder: number;
	/*
		The amount of balance that was not settled
		Common in pending transactions (pendingCredit, pendingDebit)
	*/
	pendingBalance: AmountObject;
	/*
		The impact of the transaction in the balance,
		zero if the operation is pending
	*/
	balanceImpact: AmountObject;
	/*
		The current balance based in the previous transaction
	*/
	balance: AmountObject;
	/*
		The balance sheet file name 
	*/
	balanceSheet: string;
}

export type SplitObject = MarketPlaceSplit | CommissionSplit;

export interface MarketPlaceSplit {
	account: string;
	amount: AmountObject;
	reference?: string;
	type: 'MarketPlace';
}

export interface CommissionSplit extends AdyenCommissionSplit {
	feeVatPercentage: number;
	feeCommission: Commission;
}

export interface PhoneObject {
	countryCode: string;
	areaCode: string;
	numberWithoutCode: string;
}

export interface MarketingObject {
	rentalId: string;
	firstName: string;
	lastName: string;
	email: string;
	date: string;
	category: string;
	categoryId: string;
	totalShoppers: number;
	stockProductIds: string[];
	categoryIds: string[];
	categoriesWithoutIds: string[];
}

export interface CategoryWithTerms {
	id: string;
	name: string;
	terms: TermsDocument;
}

// DIN Calculation

export type SkierCode =
	| 'A'
	| 'B'
	| 'C'
	| 'D'
	| 'E'
	| 'F'
	| 'G'
	| 'H'
	| 'I'
	| 'J'
	| 'K'
	| 'L'
	| 'M'
	| 'N'
	| 'O'
	| 'P';

export interface SkierCodeInfo {
	code: SkierCode;
	minWeight?: number;
	maxWeight?: number;
	minHeight?: number;
	maxHeight?: number;
}

export interface GetSkierCodeOptions {
	weight: number;
	height: number;
	age: number;
	skierType: number;
}

export interface DINChartSkierCode {
	code: SkierCode;
	[bootSoleLengthGroup: number]: string;
}

export interface DINChartInfo {
	name: string;
	data: DINChartSkierCode[];
}

export type DINChart = {
	atomic: DINChartInfo;
};

export interface GetDINOptions extends GetSkierCodeOptions {
	bootSoleLength: string | number;
	dinVariation: string | number | null;
}

export interface UpdateOrderProductsCreation {
	products: OrderProduct[];
	stockProducts: ProductApi[];
	shoppers: Shopper[];
	shop: Shop;
	startDate: string;
	currencyCode: string;
	keepProductCodes: boolean;
	channel: 'ADMIN' | 'ONLINE' | 'STORE';
	openingHours: OpeningHours;
}

export interface TransactionRequest {
	amount: AmountObject;
	method: PaymentMethod;
	provider: PaymentProvider;
	pspReference: string | null;
	transactionId: string;
	splitData: SplitObject[] | null;
	result?: any;
}

export interface ModifyPaymentRequest extends PaymentRequest {
	originalPaymentId: string;
	originalTransactionId: string;
	originalReceiptNumber: number;
	originalPspReference: string | null;
}

export interface PaymentRequest {
	startDate: string;
	amount: AmountObject;
	orderId: string;
	locationId: string | null; // Can be null only in case of Rentle billing payments
	orderPaymentRequestId: string | null;
	shopId: string;
	shopperId: string | null;
	transactionsInfo: TransactionRequest[];
	channel: ChannelWithInternal;
	live: boolean;
	receiptNumber: number;
	shopReceiptInfo: ShopReceiptInfo;
	paymentType: PaymentType;
	productIds: string[];
	invoiceIds?: string[];
	items: ItemObject[];
	capture: boolean;
	paymentAction: PaymentAction;
	appliedDiscountCode?: {
		code: string;
		value: number;
	};
}

export interface CurrencyInfo {
	code: string;
	code_num: number;
	countries: string[];
	minor: number;
	name: string;
	symbols: string[];
	complementary?: boolean;
}

export type CurrencyPosition = 'prefix' | 'suffix';

export interface CurrencyObject {
	code: string;
	displayAs: string;
	position: CurrencyPosition;
	noSpacing?: boolean;
}

export interface DateFormatObject {
	dateFormat: DateFormat;
	timeFormat: TimeFormat;
	startDay: StartDay;
}

// DEPRECATED
export interface OLD_TransactionResponse {
	success: boolean;
	pspReference: string | null;
	recurringReference?: string;
	partial?: boolean;
	originalReference?: string;
	amount: number;
	currency: string;
	timestamp: string;
	errorCondition: ErrorCondition | null;
	errorMessage: string | null;
	details?: any;
	response?: any;
	POIId?: string;
	POITransactionId?: string;
	totalFee?: number | null;
	feeDetails?: Array<{
		amount: number;
		type: string;
	}> | null;
}

export interface OLD_TransactionApi extends OLD_TransactionResponse, OLD_TransactionRequest {
	state: PaymentState;
	capture: boolean;
	events: PaymentEvent[];
	refundedAmount?: number;
}

export interface OLD_TransactionRequest {
	id: string;
	saveCard: boolean;
	shopperId: string | null;
	shopId: string;
	amount: number;
	currency: string;
	provider: 'adyen';
	paymentType: PaymentType;
	method: PaymentMethod;
	cardOnFile: boolean;
	cardToken: string | null;
	bankStatementDescription: string;
	paymentId: string;
	rentalId: string;
	originalReference?: string;
	live: boolean;
}

export interface OLD_PaymentApi {
	id: string;
	chargeId: string;
	refundId?: string[];
	currency: string;
	amount: number;
	refundedAmount?: number;
	state: PaymentState;
	rentalId: string;
	shopId: string;
	shopperId: string;
	paymentType: PaymentType;
	method: PaymentMethod;
	cardOnFile: boolean;
	timestamp: string;
	channel: Channel;
	events?: PaymentEvent[];
	response?: any;
	live: boolean;
	provider: 'adyen';
	success: boolean;
	info?: string;
	capture: boolean;
	paymentAccountId: string;
	paymentToken?: string;
	receiptNumber: null | string;
	totalFeePercentage?: number;
	totalFee?: number | null;
	feeDetails?: Array<{
		amount: number;
		type: string;
	}> | null;
	refusalReason?: string;
}

export interface AccountOwner {
	dob?: string;
	firstName?: string;
	gender?: 'male' | 'female';
	lastName?: string;
	address?: Address;
	personalIdNumber?: string;
	phone?: string;
	ssnLast4?: string;
	verification?: {
		document: string;
	};
}

export interface AdditionalOwner {
	address?: Address;
	dob?: string;
	firstName: string;
	lastName: string;
	personalIdNumber?: string;
	verification?: {
		document: string;
	};
}

export type LocalFormatFunction = (
	dateTime: string | moment.Moment | undefined,
	dateFormat: MomentDateFormat,
) => string;

export interface OLD_RentalApi {
	activeState: ActiveState;
	activeStates: Array<{
		state: ActiveState;
		timestamp: string;
	}>;
	rentalStates: Array<{
		state: RentalState;
		timestamp: string;
	}>;
	charge: OLD_RentalPayment;
	terms: {
		signatureRef?: string;
		accepted: boolean;
		timestamp?: string;
	};
	handlePayment: boolean;
	type?: ProductType;
	cancellationPolicy?: CancellationObject[];
	createdAt?: string;
	deposit: OLD_RentalPayment;
	extraCharges?: OLD_RentalPayment[];
	endDate: string;
	id: string;
	channel: Channel;
	payLink?: PayLinkInfo;
	products: OrderProduct[];
	rentalState: RentalState;
	shopId: string;
	startDate: string;
	startLocation: Location;
	endLocation: Location;
	shopper: ShopperApi;
	discount: Discount;
	terminal?: {
		state: string;
		info?: string;
	};
	live: boolean;
	history?: HistoryObject[];
}

export interface OLD_RentalPayment {
	amount: number | '';
	id: string;
	chargeId: string;
	refundedAmount?: number;
	refundId?: string[];
	state: PaymentState;
	timestamp?: string;
	description?: string;
	provider: 'adyen';
	error?: string;
}

export type Environment = 'production' | 'development' | 'local';

export type Primitive = string | number | boolean | undefined | null;
