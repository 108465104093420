import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import * as ViewActions from 'redux/actions/view';
import { useDispatch } from 'redux/hooks';

import { useTranslation } from 'services/localization';

interface Option {
	value: string;
	label: string;
	path: string;
}

interface Props {
	value: string | null;
	options: Option[];
	selectAllPath: string;
	onSelectAllClick: () => void;
}

const LinksFilter = (props: Props) => {
	const { value, options, selectAllPath, onSelectAllClick } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	return (
		<Box onClick={() => dispatch(ViewActions.setFiltersOpen(false))}>
			<Link to={selectAllPath} onClick={onSelectAllClick} className={classes.link}>
				<Typography
					variant="body1"
					className={classNames(classes.linkText, {
						[classes.linkTextSelected]: value === null,
					})}
				>
					{t('common:amounts.all', 'All')}
				</Typography>
			</Link>
			{options.map((option) => (
				<Link to={option.path} key={option.value} className={classes.link}>
					<Typography
						variant="body1"
						className={classNames(classes.linkText, {
							[classes.linkTextSelected]: value === option.value,
						})}
					>
						{option.label}
					</Typography>
				</Link>
			))}
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		link: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			'& > *': {
				fontWeight: 500,
			},
		},
		linkText: {
			position: 'relative',
			'&:after': {
				content: '""',
				position: 'absolute',
				bottom: -2,
				left: 0,
				right: 0,
				height: 2,
				transition: 'all 0.2s',
				transform: 'scale3d(0, 1, 1)',
				transformOrigin: 'bottom left',
			},
		},
		linkTextSelected: {
			'&:after': {
				background: 'black',
				transform: 'scale3d(1, 1, 1)',
			},
		},
	}),
);

export default LinksFilter;
