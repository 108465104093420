import moment from 'moment-timezone';

import { AvailabilityRange } from 'common/api/frontend/inventory_new/availabilityRange';
import { OpeningHours, isDateTimeWithinOpeningHours } from 'common/modules/openingHours';
import { ISOString, StartTimeCount } from 'common/types';
import { getNewEndTime } from 'common/utils/dateCalculations';

import {
	AvailabilityWithReason,
	TimeSlotType,
	ValidityWithReason,
	isStartTimeSlotType,
} from './types';
import {
	getDurationFromStartTimeSlotType,
	getOverlappingAvailabilityRanges,
	isStartTimeOverOrderLimit,
} from './utils';

export const getAvailabilityForTimeSlotWithReason = (args: {
	timeWithReason: { day: string; time: string; validity: ValidityWithReason };
	timeSlotType: TimeSlotType;
	openingHours: OpeningHours;
	orderLimit: number;
	startTimeCounts: StartTimeCount;
	availabilityRanges: AvailabilityRange[];
	allowEndOutsideOpeningHours: boolean;
	availabilityRangesToCompareFirst?: AvailabilityRange[];
	inventoryBlockerMinutesBefore?: number;
	inventoryBlockerMinutesAfter?: number;
	timezone: string;
	alreadySelected?: number;
}): {
	availability: AvailabilityWithReason;
	overlappingRanges?: AvailabilityRange[];
} => {
	const {
		timeWithReason,
		timeSlotType,
		openingHours,
		availabilityRanges,
		availabilityRangesToCompareFirst,
		timezone,
		allowEndOutsideOpeningHours,
	} = args;
	const { start, end } = getStartEndTime({ timeWithReason, timeSlotType, openingHours, timezone });

	const startTimeWithInventoryBlockers = moment(start)
		.subtract(args.inventoryBlockerMinutesBefore, 'minutes')
		.toISOString();
	const endDateWithInventoryBlockers = moment(end)
		.add(args.inventoryBlockerMinutesAfter, 'minutes')
		.toISOString();
	const relevantAvailabilityRanges = getAvailabilityRangesToUse({
		availabilityRanges,
		availabilityRangesToCompareFirst,
		endDateWithInventoryBlockers,
	});
	const overlappingRanges = getOverlappingAvailabilityRanges({
		startTime: startTimeWithInventoryBlockers,
		endTime: endDateWithInventoryBlockers,
		availabilityRanges: relevantAvailabilityRanges,
	});
	const overlappingRangeUnits = overlappingRanges?.map((r) => r.units ?? Infinity);
	const availableUnits =
		(overlappingRangeUnits?.length ? Math.min(...overlappingRangeUnits) : 0) -
		(args.alreadySelected ?? 0);

	// Check if units are over the start time limit
	if (
		isStartTimeSlotType(args.timeSlotType) &&
		isStartTimeOverOrderLimit(start, args.orderLimit, args.startTimeCounts)
	) {
		const availability: AvailabilityWithReason = {
			valid: false,
			reason: 'order_limit',
			units: availableUnits,
		};
		return { availability };
	}

	if (
		!allowEndOutsideOpeningHours &&
		!isDateTimeWithinOpeningHours({
			openingHours,
			dateTime: end,
			timezone,
			inclusive: {
				start: true,
				end: true,
			},
		})
	) {
		const unavailableOpeninghours: AvailabilityWithReason = {
			units: availableUnits,
			valid: false,
			reason: 'opening_hours',
		};
		return { availability: unavailableOpeninghours };
	}

	const availability: AvailabilityWithReason =
		availableUnits > 0
			? { ...args.timeWithReason.validity, units: availableUnits }
			: {
					valid: false,
					reason: !args.timeWithReason.validity.valid
						? args.timeWithReason.validity.reason
						: 'availability',
					units: availableUnits,
			  };
	return { availability, overlappingRanges };
};

const getStartEndTime = (args: {
	timeWithReason: { day: string; time: string; validity: ValidityWithReason };
	timeSlotType: TimeSlotType;
	openingHours: OpeningHours;
	timezone: string;
}): { start: ISOString; end: ISOString } => {
	const { timeWithReason, timeSlotType, openingHours, timezone } = args;
	const timeSlotDate = moment
		.tz(`${timeWithReason.day}T${timeWithReason.time}`, timezone)
		.toISOString();
	if (isStartTimeSlotType(timeSlotType)) {
		const duration = getDurationFromStartTimeSlotType(timeSlotType, timeSlotDate);

		return {
			start: timeSlotDate,
			end: getNewEndTime(timeSlotDate, duration, openingHours, timezone),
		};
	} else {
		return {
			start: timeSlotType.startTime,
			end: timeSlotDate,
		};
	}
};

const getAvailabilityRangesToUse = (args: {
	availabilityRangesToCompareFirst?: AvailabilityRange[];
	availabilityRanges: AvailabilityRange[];
	endDateWithInventoryBlockers: string;
}): AvailabilityRange[] => {
	const {
		availabilityRangesToCompareFirst,
		availabilityRanges,
		endDateWithInventoryBlockers,
	} = args;
	let relevantAvailabilityRanges: AvailabilityRange[] = availabilityRanges;
	if (!!availabilityRangesToCompareFirst?.length) {
		const lastRange = availabilityRangesToCompareFirst[availabilityRangesToCompareFirst.length - 1];
		if (endDateWithInventoryBlockers < lastRange.end) {
			relevantAvailabilityRanges = availabilityRangesToCompareFirst;
		}
	}
	return relevantAvailabilityRanges;
};
