import { createSelector } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import * as NavSelectors from 'redux/selectors/nav';
import * as ViewSelectors from 'redux/selectors/view';
import { ReduxState } from 'redux/types';

import { PLAIN_THEME_ID, getDefaultOnlineLayout } from 'common/modules/customization';
import {
	DEFAULT_OPENING_HOURS,
	OpeningHours,
	getOpeningHoursForStore,
} from 'common/modules/openingHours';
import {
	isDepositPaymentMethod,
	shopHasAdyenPayment,
	shopHasStripePayment,
} from 'common/modules/payments/paymentMethods';
import { getOnlineStoreUrl } from 'common/modules/urls';
import { DEFAULT_BRANDING } from 'common/styles/constants';
import * as Themes from 'common/styles/themes';
import { getThemeWithBranding } from 'common/styles/utils';
import { Branding, ByLocationId, UnitSystems } from 'common/types';
import { hashByUniqueField } from 'common/utils/arrays';
import { isCancellationPolicyDefined } from 'common/utils/cancellations';
import { getSampleTerms, notUndefined } from 'common/utils/common';
import { getDefaultCurrencyObject } from 'common/utils/currencyUtils';
import { getDefaultDateObject, getDefaultTimezone } from 'common/utils/dateUtils';
import { getClientEnv } from 'common/utils/frontUtils';
import { getLocationStockProducts } from 'common/utils/productVisibilityFilters';
import { getCustomMarketingConsent, getShopLocationsWithContact } from 'common/utils/shopUtils';
import { gridColumnsAmountToValueDesktop } from 'common/utils/themeUtils';
import { isValidHexColor } from 'common/utils/validation';
import { ExternalAuthProvider } from 'hooks/useExternalAuth/authProviders';
import { t } from 'services/localization';

export const shopLoading = (state: ReduxState) => state.shop.loading;
export const shopError = (state: ReduxState) => state.shop.error;

export const shopUrls = (state: ReduxState) => state.shop.shopUrls;
export const shopUrlsData = (state: ReduxState) => shopUrls(state).data;
export const shopUrlsLoading = (state: ReduxState) => shopUrls(state).loading;
export const shopUrlsError = (state: ReduxState) => shopUrls(state).error;

export const shopPublicInfo = (state: ReduxState) => state.shop.publicInfo;
export const shopPublicInfoData = (state: ReduxState) => shopPublicInfo(state).data;
export const shopPublicInfoLoading = (state: ReduxState) => shopPublicInfo(state).loading;
export const shopPublicInfoError = (state: ReduxState) => shopPublicInfo(state).error;

export const shopFeatures = (state: ReduxState) => state.shop.enabledFeatures;
export const shopFeaturesData = (state: ReduxState) => shopFeatures(state).data ?? [];
export const shopFeaturesLoading = (state: ReduxState) => shopFeatures(state).loading;
export const shopFeaturesError = (state: ReduxState) => shopFeatures(state).error;

export const customThemes = (state: ReduxState) => state.shop.customThemes;
export const customThemesData = (state: ReduxState) => customThemes(state).data;
export const customThemesLoading = (state: ReduxState) => customThemes(state).loading;
export const customThemesError = (state: ReduxState) => customThemes(state).error;

export const rentleCustomTheme = (state: ReduxState) => state.shop.rentleCustomTheme;
export const rentleCustomThemeData = (state: ReduxState) => rentleCustomTheme(state).data;
export const rentleCustomThemeLoading = (state: ReduxState) => rentleCustomTheme(state).loading;
export const rentleCustomThemeError = (state: ReduxState) => rentleCustomTheme(state).error;

export const openingHours = (state: ReduxState) => state.shop.openingHours;
export const openingHoursData = (state: ReduxState) => openingHours(state).data;
export const openingHoursLoading = (state: ReduxState) => openingHours(state).loading;
export const openingHoursError = (state: ReduxState) => openingHours(state).error;

export const shopTimezone = (state: ReduxState) =>
	shopPublicInfo(state).data?.timeZone ?? getDefaultTimezone();
export const shopDateFormat = (state: ReduxState) =>
	shopPublicInfo(state).data?.dateFormat ?? getDefaultDateObject();
export const isLiteShop = (state: ReduxState) => !!state.shop.isLiteShop?.data;
export const shopHasActiveDiscountCodes = (state: ReduxState) =>
	!!state.shop.hasActiveDiscountCodes.data;

export const shopStripeAccountId = (state: ReduxState) => state.shop.stripeAccountId;

const stockProductsData = (state: ReduxState) => state.stock.products.data ?? [];

export const externalAuthProvider = createSelector(shopFeaturesData, (features):
	| ExternalAuthProvider
	| undefined => {
	if (features.includes('DECATHLON_LOGIN')) {
		return 'DECATHLON';
	}
	if (features.includes('MOCK_LOGIN_INTERNAL')) {
		return 'MOCK';
	}
	return undefined;
});

export const shopGoogleAnalytics = createSelector(
	shopPublicInfoData,
	(publicInfo) => publicInfo?.plugins?.googleAnalytics,
);

export const cookieSettings = createSelector(
	shopPublicInfoData,
	(publicInfo) => publicInfo?.cookieSettings,
);

export const shopDeliveryOptions = (state: ReduxState) => state.shop.deliveryOptions;
export const shopDeliveryOptionsLoading = (state: ReduxState) => state.shop.deliveryOptions.loading;
export const shopDeliveryOptionsData = (state: ReduxState) => state.shop.deliveryOptions.data;

export const shopStartDay = createSelector(shopDateFormat, (dateFormat) => dateFormat.startDay);

export const onlineLocations = createSelector(shopPublicInfo, (publicInfo) => {
	return publicInfo.data ? getShopLocationsWithContact(publicInfo.data, 'ONLINE') : [];
});

export const shopMainLocation = createSelector(shopPublicInfo, (publicInfo) => {
	return publicInfo.data ? publicInfo.data.visitingAddress ?? null : null;
});

export const mainLocationVisibleInOnline = createSelector(shopMainLocation, (shopMainLocation) => {
	if (!shopMainLocation) return false;
	return shopMainLocation.channels?.includes('ONLINE') ?? true;
});

export const allOnlineLocationsHidden = createSelector(onlineLocations, (locations) => {
	return locations.length === 0;
});

export const onlineLocationsById = createSelector(onlineLocations, (locations) => {
	return hashByUniqueField(locations, 'id');
});

/**
 * Some older shops might not have locationUrls defined, if they only have 1 main location.
 * This adds the main storeUrl as a locationUrl as well.
 **/
export const allShopLocationUrls = createSelector(
	shopUrlsData,
	shopMainLocation,
	(urls, shopMainLocation) => {
		const mainStoreUrl = urls?.storeUrl;
		const mainLocationId = shopMainLocation?.id;
		const locationUrls = { ...(urls?.locationUrls ?? {}) };
		if (mainStoreUrl && mainLocationId && !Object.values(locationUrls).includes(mainLocationId)) {
			locationUrls[mainStoreUrl] = mainLocationId;
		}
		return locationUrls;
	},
);

export const onlineUrlsByLocationId = createSelector(
	allShopLocationUrls,
	onlineLocationsById,
	(locationUrls, onlineLocationsById) => {
		const shopUrlsByLocationId = Object.keys(locationUrls).reduce((result, locationName) => {
			const locationId = locationUrls[locationName];
			const location = onlineLocationsById[locationId];
			if (location) {
				result[location.id] = locationName;
			}
			return result;
		}, {});
		return shopUrlsByLocationId;
	},
);

export const onlineLocationsByName = createSelector(
	onlineLocations,
	onlineUrlsByLocationId,
	(onlineLocations, onlineUrlsByLocationId) => {
		return hashByUniqueField(onlineLocations, (location) => onlineUrlsByLocationId[location.id]);
	},
);

export const activeLocation = createSelector(
	NavSelectors.locationName,
	onlineLocationsByName,
	shopMainLocation,
	mainLocationVisibleInOnline,
	(locationName, onlineLocationsByName, shopMainLocation, mainLocationVisibleInOnline) => {
		if (!locationName) {
			return shopMainLocation && mainLocationVisibleInOnline ? shopMainLocation : undefined;
		}
		return onlineLocationsByName[locationName] ?? undefined;
	},
);

export const hasDeliveryFeature = createSelector(shopFeaturesData, (features) => {
	return features.includes('DELIVERY_SERVICE');
});

export const hasSegmentsFeature = createSelector(shopFeaturesData, (features) => {
	return features.includes('SEGMENTS');
});

export const activeLocationDeliveryOptions = createSelector(
	activeLocation,
	shopDeliveryOptionsData,
	stockProductsData,
	(activeLocation, deliveryOptions, products) => {
		const deliveryOptionIdsFromProducts = uniq(
			getLocationStockProducts(products, activeLocation?.id ?? '', 'ONLINE')
				.flatMap((p) => p.deliveryOptionIds)
				.filter(notUndefined),
		);

		return products.length === 0
			? undefined
			: !!activeLocation
			? deliveryOptions
					?.filter((d) => d.locationIds.includes(activeLocation.id))
					.filter((o) => deliveryOptionIdsFromProducts.includes(o.id))
			: [];
	},
);

export const activeLocationDeliveryOptionsById = createSelector(
	activeLocationDeliveryOptions,
	(options) => hashByUniqueField(options ?? [], (option) => option.id),
);

export const isDeliveryOnly = createSelector(
	activeLocation,
	shopPublicInfoData,
	(activeLocation, publicInfo) => {
		const activeLocationId = activeLocation?.id;
		return !activeLocationId
			? false
			: publicInfo?.hideStorePickup?.location?.[activeLocationId] ?? false;
	},
);

export const isDeliveryBarVisible = createSelector(
	activeLocationDeliveryOptions,
	isDeliveryOnly,
	(deliveryOptions, deliveryOnly) => deliveryOnly || !!deliveryOptions?.length,
);

export const checkoutCommentField = createSelector(
	activeLocation,
	shopPublicInfoData,
	(activeLocation, publicInfo) => {
		const activeLocationId = activeLocation?.id;
		return activeLocationId ? publicInfo?.checkoutCommentField?.[activeLocationId] : undefined;
	},
);

export const defaultDeliveryOption = createSelector(
	activeLocationDeliveryOptions,
	isDeliveryOnly,
	(deliveryOptions, deliveryOnly) => {
		return deliveryOnly ? deliveryOptions?.[0] ?? null : null;
	},
);

export const shopPricingTables = createSelector(shopPublicInfo, (publicInfo) => {
	return publicInfo.data?.pricingTables ?? {};
});

export const openingHoursByStoreId = createSelector(
	openingHoursData,
	onlineLocations,
	(openingHoursDocs, locations) => {
		return locations.reduce((result, location) => {
			result[location.id] = getOpeningHoursForStore({
				openingHoursDocs: openingHoursDocs ?? [],
				storeId: location.id,
			});
			return result;
		}, {} as ByLocationId<OpeningHours>);
	},
);

export const activeStoreOpeningHours = createSelector(
	activeLocation,
	openingHoursByStoreId,
	(activeLocation, openingHoursByStoreId) => {
		return !!activeLocation
			? openingHoursByStoreId[activeLocation.id] ?? DEFAULT_OPENING_HOURS
			: DEFAULT_OPENING_HOURS;
	},
);

export const shopBranding = createSelector(
	shopPublicInfo,
	(publicInfo): Branding => {
		const shopBranding = publicInfo.data?.customBranding;
		const defaultOnlineBranding = DEFAULT_BRANDING;
		const activeBranding =
			!!shopBranding?.active && isValidHexColor(shopBranding.mainColor)
				? shopBranding
				: defaultOnlineBranding;

		return activeBranding;
	},
);

export const shopCurrency = createSelector(shopPublicInfo, (publicInfo) => {
	return publicInfo.data?.currency ?? getDefaultCurrencyObject();
});

export const taxExcluded = createSelector(shopPublicInfo, (publicInfo) => {
	return publicInfo?.data?.taxExcluded ?? false;
});

export const shopCountry = createSelector(shopPublicInfo, (publicInfo) => {
	return publicInfo.data?.country;
});

export const shopId = createSelector(shopPublicInfo, (publicInfo) => {
	return publicInfo.data?.shopId ?? '';
});

export const shopName = createSelector(shopPublicInfo, (publicInfo) => {
	return publicInfo.data?.name ?? '';
});

export const shopLogo = createSelector(shopPublicInfo, (publicInfo) => {
	return publicInfo.data?.logoImgUrl ?? null;
});

export const shopTheme = createSelector(shopBranding, (branding) => {
	return getThemeWithBranding(Themes.WhiteLabelLight, branding);
});

export const shopLanguages = createSelector(shopPublicInfoData, (publicInfo) => {
	return publicInfo?.languages ?? null;
});

export const shopMarketingConsent = createSelector(
	shopPublicInfo,
	ViewSelectors.language,
	(shopInfo, language) => {
		if (!shopInfo.data) return null;
		return getCustomMarketingConsent(language, shopInfo.data.marketingConsentText || null);
	},
);

export const shopTerms = createSelector(
	shopPublicInfo,
	(shopInfo) => shopInfo.data?.termsOfService ?? getSampleTerms(),
);

export const useLegacyCategoryTermsOverwrite = createSelector(
	shopPublicInfo,
	(shopInfo) => !!shopInfo.data?.legacySettings?.useCategoryTermsOverwrite,
);

export const shopPrivacyPolicy = createSelector(
	shopPublicInfo,
	(shopInfo) => shopInfo.data?.privacyPolicy?.url,
);

export const shopPaymentMethods = createSelector(
	shopPublicInfo,
	(shopInfo) => (!!shopInfo.data && shopInfo.data.activePaymentMethods) || [],
);

export const shopDepositPaymentMethods = createSelector(shopPaymentMethods, (paymentMethods) =>
	paymentMethods.map((m) => m.id).filter(isDepositPaymentMethod),
);

export const shopHasAdyenPayments = createSelector(shopPaymentMethods, (shopPaymentMethods) =>
	shopHasAdyenPayment(shopPaymentMethods),
);

export const shopHasStripePayments = createSelector(shopPaymentMethods, (shopPaymentMethods) =>
	shopHasStripePayment(shopPaymentMethods),
);

export const shopHasOnlinePaymentsInUse = createSelector(
	shopHasAdyenPayments,
	shopHasStripePayments,
	(adyenPayments, stripePayments) => !!adyenPayments || !!stripePayments,
);

export const shopCancellationPolicy = createSelector(
	shopPublicInfoData,
	(shopInfo) => shopInfo?.cancellationPolicy,
);

export const shopHasCancellationPolicy = createSelector(
	shopCancellationPolicy,
	(policy) => !!policy?.length && isCancellationPolicyDefined(policy),
);

export const shopUnitSystem = createSelector(shopPublicInfoData, (shop) =>
	shop ? shop.unitSystem : UnitSystems.METRIC,
);

export const activeLocationOnlineThemeId = createSelector(
	activeLocation,
	shopPublicInfoData,
	(location, publicInfo) => {
		return !!location?.id ? publicInfo?.themesByLocationId?.ONLINE?.[location?.id] : null;
	},
);

export const onlineLayout = createSelector(
	customThemesData,
	rentleCustomThemeData,
	shopUrlsData,
	activeLocation,
	activeLocationOnlineThemeId,
	ViewSelectors.language,
	(themes, customTheme, shopUrls, activeLocation, themeId, language) => {
		if (!customTheme) return undefined;
		const activeLocationId = activeLocation?.id;
		const onlineStoreUrl = getOnlineStoreUrl({
			env: getClientEnv(),
			locationId: activeLocationId ?? null,
			shopUrlDoc: shopUrls ?? undefined,
		});
		return themeId === PLAIN_THEME_ID
			? customTheme.live
			: themes?.find((t) => t.id === themeId)?.live ??
					getDefaultOnlineLayout(t, language, onlineStoreUrl);
	},
);

const DEFAULT_GRID_COLUMNS = 4;

export const categoryGridColumnsDesktop = createSelector(onlineLayout, (layout) => {
	return layout?.defaultLayout === 'categories'
		? gridColumnsAmountToValueDesktop(layout.productGridColumns?.desktop)
		: DEFAULT_GRID_COLUMNS;
});
export const productGridColumnsDesktop = createSelector(onlineLayout, (layout) => {
	return layout?.defaultLayout === 'products'
		? gridColumnsAmountToValueDesktop(layout.productGridColumns?.desktop)
		: DEFAULT_GRID_COLUMNS;
});

export const footerInfo = createSelector(onlineLayout, (layout) => layout?.footer);
export const headerLayout = createSelector(onlineLayout, (layout) => layout?.header);
export const customCheckout = createSelector(onlineLayout, (layout) => layout?.checkout);
