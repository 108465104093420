import {
	PaletteColor,
	PaletteOptions,
	TypeAction,
	TypeBackground,
	TypeText,
	alpha,
} from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
	interface PaletteColor {
		main: string;
		dark: string;
		darker: string;
		light: string;
		lighter: string;
		lightest: string;
		contrastText: string;
	}

	interface TypeBackground {
		default: string;
		paper: string;
		secondary: string;
	}

	interface TypeText {
		primary: string;
		secondary: string;
		tertiary: string;
		disabled: string;
		inverse: Omit<TypeText, 'inverse'>;
	}
	interface PaletteOptions {
		colors: {
			rentleGreen: SimplePaletteColorOptions;
			tundra: SimplePaletteColorOptions;
			pru: SimplePaletteColorOptions;
			peach: SimplePaletteColorOptions;
			statusError: SimplePaletteColorOptions;
			statusWarning: SimplePaletteColorOptions;
			statusSuccess: SimplePaletteColorOptions;
			availabilityNone: SimplePaletteColorOptions;
			availabilityLow: SimplePaletteColorOptions;
			availabilityGood: SimplePaletteColorOptions;
		};
		border: {
			paper: string;
			divider: string;
			table: string;
			inputOutline: string;
			inputStandard: string;
		};
	}

	interface Palette {
		colors: {
			rentleGreen: PaletteColor;
			tundra: PaletteColor;
			pru: PaletteColor;
			peach: PaletteColor;
			statusError: PaletteColor;
			statusWarning: PaletteColor;
			statusSuccess: PaletteColor;
			availabilityNone: PaletteColor;
			availabilityLow: PaletteColor;
			availabilityGood: PaletteColor;
		};
		border: {
			paper: string;
			divider: string;
			table: string;
			inputOutline: string;
			inputStandard: string;
		};
	}
}

export const rentleGreen: PaletteColor = {
	main: '#275F69',
	dark: '#35565C',
	darker: '#172527',
	light: '#1A7889',
	lighter: '#93AFB4',
	lightest: '#E3ECEC',
	contrastText: '#ffffff',
};

export const tundra: PaletteColor = {
	main: '#484848',
	dark: '#2E2E2E',
	darker: '#2e2e2e',
	light: '#6E6E6E',
	lighter: '#A3A3A3',
	lightest: '#F0F0F0',
	contrastText: '#ffffff',
};

export const pru: PaletteColor = {
	main: '#172527',
	dark: '#101A1B',
	darker: '#040707',
	light: '#2A4347',
	lighter: '#8B9293',
	lightest: '#ECEEEE',
	contrastText: '#ffffff',
};

export const peach: PaletteColor = {
	main: '#FFCFB8',
	dark: '#B39181',
	darker: '#FF9B6C',
	light: '#FFE7DC',
	lighter: '#FFF6EE',
	lightest: '#FFF9F6',
	contrastText: '#000000',
};

export const statusError: PaletteColor = {
	main: '#CE0000',
	dark: '#9B0000',
	darker: '#9B0000',
	light: '#EF0000',
	lighter: '#E68080',
	lightest: '#FAE5E5',
	contrastText: '#ffffff',
};

export const statusWarning: PaletteColor = {
	main: '#F5B53E',
	dark: '#AC7F2B',
	darker: '#E8A11D',
	light: '#F7C364',
	lighter: '#FADA9F',
	lightest: '#FEF9F0',
	contrastText: '#ffffff',
};

export const statusSuccess: PaletteColor = {
	main: '#008000',
	dark: '#004D00',
	darker: '#004D00',
	light: '#009000',
	lighter: '#80BF80',
	lightest: '#E5F2E5',
	contrastText: '#ffffff',
};

export const availabilityGood: PaletteColor = {
	main: '#22a3696b',
	dark: '#22a369',
	darker: '#22a369',
	light: '#ecf3e5',
	lighter: '#ecf3e5',
	lightest: '#ecf3e5',
	contrastText: '#000000',
};

export const availabilityLow: PaletteColor = {
	main: '#f7b5006b',
	dark: '#f7b500',
	darker: '#f7b500',
	light: '#fff7e5',
	lighter: '#fff7e5',
	lightest: '#fff7e5',
	contrastText: '#000000',
};

export const availabilityNone: PaletteColor = {
	main: '#e020206b',
	dark: '#e02020',
	darker: '#e02020',
	light: '#ffeaea',
	lighter: '#ffeaea',
	lightest: '#ffeaea',
	contrastText: '#ffffff',
};

/**
 * Text colors
 */
export const textDark: TypeText = {
	primary: '#000000',
	secondary: '#484848',
	tertiary: '#848484',
	disabled: '#ACA9B3',
	inverse: {
		primary: '#ffffff',
		secondary: '#ffffffb3',
		tertiary: '#ffffff80',
		disabled: '#ffffff80',
	},
};

export const textLight: TypeText = {
	primary: '#ffffff',
	secondary: '#ffffffb3',
	tertiary: '#ffffff80',
	disabled: '#ffffff80',
	inverse: {
		primary: '#000000',
		secondary: '#484848',
		tertiary: '#848484',
		disabled: '#ACA9B3',
	},
};

export const action: Partial<TypeAction> = {
	active: '#757575',
	hover: '#EBEBEB',
	focus: '#E0E0E0',
	selected: alpha(rentleGreen.main, 0.08),
	disabled: '#9D9D9D',
	disabledBackground: '#CFCFCF',
};

export const borderLight: PaletteOptions['border'] = {
	paper: '#E5E5E5',
	table: '#D8D8D8',
	divider: '#f2f2f2',
	inputOutline: '#C4C4C4',
	inputStandard: '#949494',
};

export const borderDark: PaletteOptions['border'] = {
	paper: '#1a1a1a',
	table: '#272727',
	divider: '#2C2C2C',
	inputOutline: '#3b3b3b',
	inputStandard: '#6b6b6b',
};

export const backgroundLight: TypeBackground = {
	default: '#FAFAFA',
	paper: '#ffffff',
	secondary: '#F2F2F2',
};

export const backgroundDark: TypeBackground = {
	default: '#121212',
	paper: '#1C1C1EFF',
	secondary: '#2C2C2C',
};

export const colors = {
	rentleGreen,
	tundra,
	pru,
	peach,
	statusError,
	statusWarning,
	statusSuccess,
	availabilityNone,
	availabilityLow,
	availabilityGood,
};

export const paletteRentleLight: PaletteOptions = {
	mode: 'light',
	primary: rentleGreen,
	secondary: tundra,
	info: rentleGreen,
	error: statusError,
	warning: statusWarning,
	success: statusSuccess,
	colors,
	text: textDark,
	divider: borderLight.divider,
	background: backgroundLight,
	border: borderLight,
	action,
};

export const paletteRentleDark: PaletteOptions = {
	mode: 'dark',
	primary: rentleGreen,
	secondary: tundra,
	info: rentleGreen,
	error: statusError,
	warning: statusWarning,
	success: statusSuccess,
	colors,
	divider: borderDark.divider,
	background: {
		paper: '#1C1C1EFF',
		default: '#121212',
	},
	border: borderDark,
	text: textLight,
};

export const paletteWhiteLabelLight: PaletteOptions = {
	mode: 'light',
	primary: {
		main: '#000000',
	},
	secondary: tundra,
	info: {
		main: '#000000',
	},
	error: statusError,
	warning: statusWarning,
	success: statusSuccess,
	colors,
	text: textDark,
	divider: borderLight.divider,
	action,
	border: borderLight,
	background: backgroundLight,
};

export const paletteWhiteLabelDark: PaletteOptions = {
	mode: 'dark',
	primary: {
		main: '#ffffff',
	},
	secondary: tundra,
	info: {
		main: '#ffffff',
	},
	error: statusError,
	warning: statusWarning,
	success: statusSuccess,
	colors,
	text: textLight,
	border: borderDark,
	background: backgroundDark,
};
