import React from 'react';

import { Box, ListItemText } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import AmountPicker from 'common/components/pickers/AmountPicker';
import ErrorText from 'components/ErrorText';

export interface AmountSelectorProps {
	name: string;
	value: number;
	onChange: (value: number) => void;
	error?: string | null;
}

const AmountSelector = React.memo((props: AmountSelectorProps) => {
	const classes = useStyles();
	const { value, onChange, name, error } = props;

	return (
		<Box>
			<Box className={classes.wrapper}>
				<ListItemText
					primary={`${value} x ${name}`}
					primaryTypographyProps={{ variant: 'body1' }}
				></ListItemText>
				<AmountPicker value={value} onChange={onChange} minValue={1} />
			</Box>
			{error && <ErrorText>{error}</ErrorText>}
		</Box>
	);
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
		},
	}),
);

export default AmountSelector;
