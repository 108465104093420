import React, { useCallback, useEffect, useRef, useState } from 'react';

import AdyenCheckout from '@adyen/adyen-web';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';

import { getPaymentComponentLocale } from 'common/locales/utils';
import { adyenCustomTranslations } from 'common/locales/utils/constants';
import {
	getPaymentMethodFromAdyenType,
	isAdyenPaymentMethod,
} from 'common/modules/payments/paymentMethods';
import { AdyenPaymentMethod } from 'common/modules/payments/types';
import { Languages, PaymentMethodData } from 'common/types';
import { isDevEnv } from 'common/utils/common';
import { getClientKey } from 'common/utils/frontUtils';

interface ContextInterface {
	adyenCheckout: AdyenCheckout | null;
	setSubmitCallback: (cb: (state: PaymentState) => any) => void;
	addAdditionalDetailsCallback: (cb: (state: AdditionalDetailsState) => any) => void;
	removeAdditionalDetailsCallback: (cb: (state: AdditionalDetailsState) => any) => void;
	setMethodSubmitAction: (method: AdyenPaymentMethod, action: () => void) => void;
	submitPayment: (method: AdyenPaymentMethod) => Promise<PaymentState>;
	paymentMethods: ICheckout.PaymentMethodsResponse | null;
}

export interface PaymentState {
	isValid: boolean;
	data: {
		paymentMethod: PaymentMethodData;
	};
}

export interface AdditionalDetailsState {
	data: {
		details: object;
	};
	paymentData: string;
}

const emptyCb = () => null;

const INITIAL_STATE: ContextInterface = {
	adyenCheckout: null,
	setSubmitCallback: () => emptyCb,
	addAdditionalDetailsCallback: () => emptyCb,
	removeAdditionalDetailsCallback: () => emptyCb,
	setMethodSubmitAction: () => emptyCb,
	submitPayment: () => {
		throw new Error('Empty');
	},
	paymentMethods: null,
};

export const AdyenContext = React.createContext<ContextInterface>(INITIAL_STATE);

export const AdyenProvider: React.FC<{
	paymentMethods: ICheckout.PaymentMethodsResponse | null;
	lang: Languages;
}> = ({ children, paymentMethods, lang }) => {
	const [adyenCheckout, setAdyenCheckout] = useState<null | AdyenCheckout>(null);
	const [onSubmitCallbacks, setOnSubmitCallbacks] = useState<Array<(state: PaymentState) => any>>(
		[],
	);
	const [onAdditionalDetailsCallbacks, setOnAdditionalDetailsCallbacks] = useState<
		Array<(state: AdditionalDetailsState) => any>
	>([]);
	const [methodSubmitActions, setMethodSubmitActions] = useState<{
		[method: string]: { submit: () => void };
	}>({});
	const submitCallback = useRef<(state: PaymentState) => any>(() => null);

	const onAdditionalDetailsCallbacksRef = useRef<typeof onAdditionalDetailsCallbacks>([]);

	useEffect(() => {
		onAdditionalDetailsCallbacksRef.current = onAdditionalDetailsCallbacks;
	});

	const handleOnAdditionalDetails = (state: AdditionalDetailsState) => {
		const callbacks = onAdditionalDetailsCallbacksRef.current;
		for (const cb of callbacks) {
			cb(state);
		}
	};

	useEffect(() => {
		const configureAdyenCheckout = (paymentMethods: ICheckout.PaymentMethodsResponse) => {
			const configuration: CoreOptions = {
				locale: getPaymentComponentLocale(lang),
				environment: isDevEnv() ? 'test' : 'live',
				clientKey: getClientKey(isDevEnv() ? 'development' : 'production'),
				paymentMethodsResponse: paymentMethods?.paymentMethods
					? (paymentMethods as any)
					: undefined,
				onAdditionalDetails: handleOnAdditionalDetails,
				showPayButton: false,
				onSubmit: onSubmit,
				translations: adyenCustomTranslations,
				// onChange: handleAdyenPaymentChange,
			};
			const checkout = new AdyenCheckout(configuration);
			setAdyenCheckout(checkout);
		};
		if (!!paymentMethods && !adyenCheckout) {
			configureAdyenCheckout(paymentMethods);
		}
	}, [paymentMethods, adyenCheckout, lang]);

	const setMethodSubmitAction = useCallback((method: AdyenPaymentMethod, action: () => void) => {
		setMethodSubmitActions((prev) => ({
			...prev,
			[method]: { submit: action },
		}));
	}, []);

	const submitPayment = (method: AdyenPaymentMethod): Promise<PaymentState> => {
		return new Promise<PaymentState>((resolve, reject) => {
			const submitResult = (data: PaymentState) => {
				resolve(data);
			};
			submitCallback.current = submitResult;
			if (!!methodSubmitActions[method]) {
				methodSubmitActions[method].submit();
			} else {
				reject('No submit method defined');
			}
		});
	};

	const setSubmitCallback = (cb: (state: PaymentState) => void) => {
		setOnSubmitCallbacks([...onSubmitCallbacks, cb]);
	};

	const addAdditionalDetailsCallback = (cb: (state: AdditionalDetailsState) => void) => {
		if (onAdditionalDetailsCallbacks.some((func) => func === cb)) return;
		setOnAdditionalDetailsCallbacks([...onAdditionalDetailsCallbacks, cb]);
	};

	const removeAdditionalDetailsCallback = (cb: (state: AdditionalDetailsState) => void) => {
		setOnAdditionalDetailsCallbacks(onAdditionalDetailsCallbacks.filter((func) => func !== cb));
	};

	const onSubmit = async (state: PaymentState) => {
		const adyenMethodType: string | undefined = state.data?.paymentMethod?.type;
		const selectedPaymentMethod = adyenMethodType && getPaymentMethodFromAdyenType(adyenMethodType);
		if (!selectedPaymentMethod || !isAdyenPaymentMethod(selectedPaymentMethod)) {
			submitCallback.current({
				isValid: false,
				data: {
					paymentMethod: {
						type: '',
					},
				},
			});
		}
		submitCallback.current(state);
	};

	return (
		<AdyenContext.Provider
			value={{
				adyenCheckout,
				setSubmitCallback,
				addAdditionalDetailsCallback,
				removeAdditionalDetailsCallback,
				setMethodSubmitAction,
				submitPayment,
				paymentMethods,
			}}
		>
			{children}
		</AdyenContext.Provider>
	);
};
