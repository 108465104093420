import React from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import * as ConfirmSelectors from 'redux/selectors/confirm';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import BookingTimeline from 'common/components/BookingTimeline';
import { isSalesProduct } from 'common/modules/orders';
import { isRunInIframe } from 'common/utils/browserUtils';
import { isCancellationPolicyDefined } from 'common/utils/cancellations';
import { StorageKeys, Storages, getFromStorage, removeFromStorage } from 'common/utils/frontUtils';
import { isKeycardProduct, isLiftTicketProduct } from 'common/utils/liftTicketUtils';
import BoxButton from 'components/BoxButton';
import CancellationPolicy from 'components/CancellationPolicy';
import CenteredContent from 'components/CenteredContent';
import { useTranslation } from 'services/localization/useTranslation';
import { useRoutes } from 'services/routing/useRoutes';

import BookingSummary from './components/BookingSummary';
import CustomCheckoutFields from './components/CustomCheckoutFields';
import CustomerInfo from './components/CustomerInfo';
import PaymentInfo from './components/PaymentInfo';
import SkidataInfo from './components/SkidataInfo';
import ThankYou from './components/ThankYou';

const Completed = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { replaceRoute } = useRoutes();

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	const shopDateFormat = useSelector(ShopSelectors.shopDateFormat);
	const shopTimezone = useSelector(ShopSelectors.shopTimezone);
	const shopData = useSelector(ShopSelectors.shopPublicInfoData);
	const order = useSelector(ConfirmSelectors.order);
	const lang = useSelector(ViewSelectors.language);
	const responsiblePersonDetails = useSelector(ConfirmSelectors.responsiblePersonDetails);

	if (!order || !responsiblePersonDetails || !shopData) return null;

	const { products } = order;
	const externalHomeRedirectUrl = getFromStorage(Storages.SESSION, StorageKeys.EXTERNAL_HOME_URL);
	const showExternalHomeBtn = !!externalHomeRedirectUrl && !isRunInIframe();

	const hasTicketsInOrder = products.some(isLiftTicketProduct);
	const isTicketsOnly = products.every((p) => isLiftTicketProduct(p) || isKeycardProduct(p)); // TODO: How should this affect what is shown?
	const isSalesOnly = products.every(isSalesProduct);
	const orderHasTimeline = !isTicketsOnly && !isSalesOnly;
	const shouldShowCancellationPolicy =
		isCancellationPolicyDefined(order.rentalInfo.cancellationPolicy) &&
		!!order.rentalInfo.cancellationPolicy?.length &&
		!isSalesOnly;

	const handleContinueShopping = () => {
		if (!!showExternalHomeBtn && externalHomeRedirectUrl) {
			removeFromStorage(Storages.SESSION, StorageKeys.EMBED_REDIRECT_PATH);
			window.location.href = externalHomeRedirectUrl;
			return;
		}

		replaceRoute('browse');
	};

	return (
		<>
			<CenteredContent innerClassName={classes.wrapper}>
				<Box className={classes.main}>
					<Box className={classes.section}>
						<ThankYou responsiblePerson={responsiblePersonDetails} />
					</Box>
					{hasTicketsInOrder && (
						<Box className={classes.section}>
							<Typography variant="h5">{t('common:tickets.tickets', 'Tickets')}</Typography>
							<Box mt={2} />
							<SkidataInfo order={order} />
						</Box>
					)}
					<Box className={classes.section}>
						<Typography variant="h5">
							{t('common:form.customerInformation', 'Customer information')}
						</Typography>
						<Box mt={2} />
						<CustomerInfo responsiblePerson={responsiblePersonDetails} />
						<Box mt={2} />
						<CustomCheckoutFields />
						<PaymentInfo order={order} />
					</Box>
					{(orderHasTimeline || isSmallScreen) && (
						<Box className={classes.section}>
							<Typography variant="h5">
								{t('common:booking.bookingDetails', 'Booking details')}
							</Typography>
							{orderHasTimeline && (
								<Box mt={3}>
									<BookingTimeline
										order={order}
										shopDateFormat={shopDateFormat}
										shopTimezone={shopTimezone}
										t={t}
										lang={lang}
										variant="black"
									/>
								</Box>
							)}
							{isSmallScreen && (
								<Box mt={3}>
									<BookingSummary order={order} collapsible />
								</Box>
							)}
						</Box>
					)}
					{shouldShowCancellationPolicy && (
						<Box className={classes.section}>
							<Typography variant="h5">{t('common:terms.termsUppercase', 'Terms')}</Typography>
							<CancellationPolicy
								startDate={order.rentalInfo.startDate}
								cancellationPolicy={order.rentalInfo.cancellationPolicy!}
							/>
						</Box>
					)}
					{!isSmallScreen && (
						<Box mt={3} display="flex" flexDirection="row" justifyContent="flex-end">
							<BoxButton
								variant="filled"
								text={t('common:actions.continueShopping', 'Continue shopping')}
								widthAuto
								padding="large"
								onClick={handleContinueShopping}
							/>
						</Box>
					)}
				</Box>
				{isSmallScreen ? (
					<Box mt={3} display="flex" flexDirection="row" justifyContent="flex-end">
						<BoxButton
							variant="filled"
							text={t('common:actions.continueShopping', 'Continue shopping')}
							widthAuto={!isSmallScreen}
							padding="large"
							onClick={handleContinueShopping}
						/>
					</Box>
				) : (
					<Box className={classes.aside}>
						<BookingSummary order={order} />
					</Box>
				)}
			</CenteredContent>
		</>
	);
};

const useStyles = makeStyles((theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(8),
			[theme.breakpoints.up('md')]: {
				flexDirection: 'row',
				justifyContent: 'space-between',
			},
		},
		main: {
			flex: 1,

			[theme.breakpoints.up('md')]: {
				maxWidth: 680,
				minWidth: 500,
				marginRight: theme.spacing(8),
			},
		},
		aside: {
			flex: 1,
			marginBottom: theme.spacing(3),
			[theme.breakpoints.up('md')]: {
				maxWidth: 400,
			},
		},
		section: {
			marginBottom: theme.spacing(4),
			paddingTop: theme.spacing(4),
			borderTop: '1px solid #e7e7e7',
			'&:first-child': {
				[theme.breakpoints.down('md')]: {
					borderTop: 'none',
				},
			},
			[theme.breakpoints.up('md')]: {
				border: '1px solid #dcdcdc',
				padding: theme.spacing(4),
				marginBottom: theme.spacing(1),
			},
		},
	}),
);

export default Completed;
