import React, { useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import {
	isProductForRent,
	isProductForSale,
	isProductForSubscription,
} from 'common/modules/products/utils';
import { doesProductSubscriptionSupportDelivery } from 'common/modules/subscriptions';
import { ProductApi, PurchaseType, PurchaseTypes } from 'common/types';
import CenteredContent from 'components/CenteredContent';
import MetaTags from 'components/MetaTags';
import ProductNav from 'components/ProductNav';
import { useTranslation } from 'services/localization';
import { getProductImage, getProductImages } from 'utils/products';

import ImageCarousel from './ImageCarousel';
import ProductDetails from './ProductDetails';
import ProductMedia from './ProductMedia';
import RentalProductForm from './RentalProductForm';
import SalesProductForm from './SalesProductForm';
import SubscriptionProductForm from './SubscriptionProductForm';
import ProductUnavailable from './components/ProductUnavailable';
import PurchaseTypeSelect from './components/PurchaseTypeSelect';

interface Props {
	product: ProductApi;
}

const Product = (props: Props) => {
	const isDeliveryBarVisible = useSelector(ShopSelectors.isDeliveryBarVisible);

	const classes = useStyles({ isDeliveryBarVisible });
	const { getTranslation } = useTranslation();

	const { product } = props;

	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const hasDepositPaymentMethodInUse = !!useSelector(ShopSelectors.shopDepositPaymentMethods)
		.length;
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);

	const isSubscriptionEnabled = useMemo(() => {
		return isProductForSubscription(product)
			? !!selectedDeliveryOption
				? doesProductSubscriptionSupportDelivery(product, selectedDeliveryOption)
				: true
			: false;
	}, [product, selectedDeliveryOption]);

	const [purchaseType, setPurchaseType] = useState<PurchaseType | null>(() =>
		isSubscriptionEnabled
			? PurchaseTypes.subscription
			: isProductForRent(product)
			? PurchaseTypes.rental
			: isProductForSale(product)
			? PurchaseTypes.sales
			: null,
	);

	const isInvalidDeliveryOption =
		!!selectedDeliveryOption && !product.deliveryOptionIds?.includes(selectedDeliveryOption?.id);

	const isDepositNotSupported = !!product.terms?.deposit && !hasDepositPaymentMethodInUse;

	const isProductUnavailable = isInvalidDeliveryOption || isDepositNotSupported;

	const images = getProductImages(product);
	const hasMultipleImages = images.length > 1;

	if (!activeLocation) return null;

	return (
		<>
			<MetaTags type="product" product={product} />
			<Box style={{ position: 'relative' }}>
				<ProductNav productName={getTranslation(product.name)} />
				<CenteredContent paddingBottom={12}>
					<Box className={classes.wrapper}>
						<Box className={classes.left}>
							<Box className={classes.stickyColumn}>
								{hasMultipleImages ? (
									<ImageCarousel images={images} alt={getTranslation(product.name)} />
								) : (
									<ProductMedia src={getProductImage(product)} alt={getTranslation(product.name)} />
								)}
							</Box>
						</Box>
						<Box p={3} />
						<Box className={classes.right}>
							<ProductDetails product={product} />
							<PurchaseTypeSelect
								product={product}
								value={purchaseType}
								onChange={setPurchaseType}
							/>
							<Box mt={2}>
								{isProductUnavailable ? (
									<ProductUnavailable />
								) : purchaseType === PurchaseTypes.subscription ? (
									<SubscriptionProductForm
										product={product}
										locationId={activeLocation.id}
										key={product.id}
									/>
								) : purchaseType === PurchaseTypes.rental ? (
									<RentalProductForm
										product={product}
										locationId={activeLocation.id}
										key={product.id}
									/>
								) : purchaseType === PurchaseTypes.sales ? (
									<SalesProductForm
										product={product}
										locationId={activeLocation.id}
										key={product.id}
									/>
								) : (
									<ProductUnavailable />
								)}
							</Box>
						</Box>
					</Box>
				</CenteredContent>
			</Box>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.up('md')]: {
				flexDirection: 'row',
			},
		},
		left: {
			flex: 1,
			maxWidth: '100%',
			[theme.breakpoints.up('md')]: {
				maxWidth: '50%',
			},
		},
		right: {
			flex: 1,
		},
		stickyColumn: {
			[theme.breakpoints.up('md')]: {
				position: 'sticky',
				top: ({ isDeliveryBarVisible }: { isDeliveryBarVisible: boolean }) =>
					isDeliveryBarVisible ? 100 : 24,
			},
		},
	}),
);

export default Product;
