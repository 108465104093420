import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
	content: string;
}

const InfoBlock = ({ content }: Props) => {
	const classes = useStyles();
	return (
		<Box className={classes.root}>
			<div className={classes.bar} />
			<Typography variant="body2">{content}</Typography>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			position: 'relative',
			padding: theme.spacing(2),
			backgroundColor: theme.palette.background.secondary,
		},
		bar: {
			position: 'absolute',
			height: '100%',
			top: 0,
			left: 0,
			width: 6,
			backgroundColor: theme.palette.primary.main,
		},
	}),
);

export default InfoBlock;
