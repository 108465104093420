import React from 'react';

import { Box, Link, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import moment from 'moment-timezone';

import AnchorMe from 'common/components/AnchorMe';
import Divider from 'common/components/Divider';
import { DayType, getDayTypeLabel } from 'common/modules/atoms/dates';
import {
	OpeningHours,
	getOpeningHoursConfigForDate,
	isExceptionRow,
	isRecurringRow,
} from 'common/modules/openingHours';
import { getTranslation } from 'common/modules/translations';
import { Languages, LocationWithContact } from 'common/types';
import { MomentDateFormat } from 'common/utils/dateUtils';

interface Props {
	location: LocationWithContact;
	openingHours: OpeningHours;
	t: TFunction;
	variant: 'location' | 'contact';
	localFormat: (
		dateTime: string | moment.Moment | undefined,
		dateFormat: MomentDateFormat,
	) => string;
	hideContact?: boolean;
	hideExceptions?: boolean;
	hideAdditionalInformation?: boolean;
	isLocationDeliveryOnly: boolean;
	language: Languages;
}

const LocationDetail = (props: Props) => {
	const classes = useStyles(props);

	const {
		openingHours,
		hideExceptions,
		localFormat,
		location,
		t,
		hideContact,
		hideAdditionalInformation,
		isLocationDeliveryOnly,
		language,
	} = props;

	const renderOpeningHours = (args: {
		openingHours: OpeningHours;
		localFormat: (
			dateTime: string | moment.Moment | undefined,
			dateFormat: MomentDateFormat,
		) => string;
		hideExceptions?: boolean;
	}) => {
		const { openingHours, hideExceptions, localFormat } = args;

		const currentOpeningHours = getOpeningHoursConfigForDate({
			openingHours,
			date: moment().format('YYYY-MM-DD'),
		});

		const recurringRows = currentOpeningHours.rows.filter(isRecurringRow);
		const exceptionRows = currentOpeningHours.rows.filter(isExceptionRow).filter((row) => {
			return moment(row.date).isSameOrAfter(moment(), 'date');
		});

		return (
			<>
				{recurringRows.map((item) => {
					const { dayType, times } = item;

					const showOtherDaysLabel = dayType === 'allDays' && recurringRows.length > 1;
					return (
						<Box key={dayType} className={classes.openingHoursRow}>
							<Typography variant="body2">
								{showOtherDaysLabel
									? t('common:dayTypes.otherDays', 'Other days')
									: getDayTypeLabel(dayType as DayType, t)}
							</Typography>
							{times.isClosed ? (
								<Typography variant="body2">{t('common:times.closed')}</Typography>
							) : (
								<Typography variant="body2">
									{`${localFormat(moment(times.openTime, 'HH:mm'), 'HH:mm')} - ${localFormat(
										moment(times.closeTime, 'HH:mm'),
										'HH:mm',
									)}`}
								</Typography>
							)}
						</Box>
					);
				})}
				{!hideExceptions && !!exceptionRows.length && (
					<>
						<Box my={1}>
							<Divider />
						</Box>
						{exceptionRows.map((item) => {
							const { date, times } = item;
							return (
								<Box key={item.date} className={classes.openingHoursRow}>
									<Typography variant="body2">
										{' '}
										{localFormat(moment(date), 'DD.MM.YYYY')}
									</Typography>
									{times.isClosed ? (
										<Typography variant="body2">{t('common:times.closed')}</Typography>
									) : (
										<Typography variant="body2">
											{`${localFormat(moment(times.openTime, 'HH:mm'), 'HH:mm')} - ${localFormat(
												moment(times.closeTime, 'HH:mm'),
												'HH:mm',
											)}`}
										</Typography>
									)}
								</Box>
							);
						})}
					</>
				)}
			</>
		);
	};

	return (
		<Box className={classes.wrapper}>
			<Typography variant="h6" className={classes.name}>
				{location.name}
			</Typography>
			<Box minHeight={24}>
				<Typography variant="body2">
					{location.city ? `${location.city}, ` : ''}
					{location.address}
				</Typography>
			</Box>
			{!hideAdditionalInformation && !!location.additionalInformation?.def && (
				<Box className={classes.additionalInformationWrapper}>
					<Typography variant="body2" className={classes.additionalInformation}>
						<AnchorMe>{getTranslation(location.additionalInformation, language)}</AnchorMe>
					</Typography>
				</Box>
			)}
			{!isLocationDeliveryOnly && (
				<Box
					className={classNames(
						classes.openingHours,
						!hideContact && classes.openingHoursBorderBottom,
					)}
				>
					{props.variant === 'contact' && (
						<Box mb={2}>
							<Typography variant="body1" className={classes.visitingLabel}>
								{t('common:times.visitingHours', 'Visiting hours')}
							</Typography>
						</Box>
					)}
					{renderOpeningHours({
						openingHours,
						hideExceptions,
						localFormat,
					})}
				</Box>
			)}
			{!hideContact && (
				<>
					<Box mb={2}>
						<Typography variant="body1" className={classes.visitingLabel}>
							{t('common:shopLocation.contactUs', 'Contact us')}
						</Typography>
					</Box>
					{!location.hideEmail && (
						<Link
							className={classes.contactLink}
							variant="body2"
							target="_blank"
							rel="noopener noreferrer"
							href={`mailto:${location.email}`}
						>
							{location.email}
						</Link>
					)}
					{!location.hidePhone && (
						<Link
							className={classes.contactLink}
							variant="body2"
							target="_blank"
							rel="noopener noreferrer"
							href={`tel:${location.phone}`}
						>
							{location.phone}
						</Link>
					)}
				</>
			)}

			<Box mt={3} />
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			minHeight: '100%',
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
		},
		name: {
			fontSize: '1.8rem',
			fontWeight: 600,
		},
		visitingLabel: {
			fontWeight: 500,
		},
		contactLink: {
			wordWrap: 'break-word',
		},
		openingHours: {
			margin: theme.spacing(2, 0),
			padding: theme.spacing(2, 0),
			borderTop: (props: Props) => (props.variant === 'contact' ? '1px solid #e5e5e5' : 'none'),
		},
		openingHoursBorderBottom: {
			borderBottom: (props: Props) => (props.variant === 'contact' ? '1px solid #e5e5e5' : 'none'),
		},
		openingHoursRow: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
		additionalInformationWrapper: {
			marginTop: theme.spacing(2),
			paddingTop: theme.spacing(2),
			paddingBottom: (props: Props) => (props.variant !== 'contact' ? theme.spacing(2) : 0),
			borderTop: '1px solid #e5e5e5',
			borderBottom: (props: Props) => (props.variant !== 'contact' ? '1px solid #e5e5e5' : 'none'),
		},
		additionalInformation: {
			whiteSpace: 'pre-line',
		},
	}),
);

export default LocationDetail;
