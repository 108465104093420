import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { CancellationObject, LocalFormatFunction } from 'common/types';

import { notNull } from './common';

const getNoCancellation = (cancellationPolicy: CancellationObject[]) => {
	const minCancellationPolicyItem: CancellationObject = cancellationPolicy.reduce((acc, item) => {
		if (item.refundPercentage > 0 && !!item.beforeHours) {
			acc = !isEmpty(acc) ? (item.beforeHours < acc.beforeHours ? item : acc) : item;
		}
		return acc;
	}, {} as CancellationObject);

	return !minCancellationPolicyItem?.beforeHours ? null : minCancellationPolicyItem;
};

const orderByBeforeHours = (c1: CancellationObject, c2: CancellationObject) => {
	return (c2.beforeHours || 0) - (c1.beforeHours || 0);
};

const getCancellationPolicy = (shopCancellationPolicy: CancellationObject[]) => {
	const sortedCancellationPolicy = [...shopCancellationPolicy].sort(orderByBeforeHours);
	const noCancellation = getNoCancellation(sortedCancellationPolicy);
	return { cancellationPolicy: sortedCancellationPolicy, noCancellation };
};

export const getCancellationPolicyToRender = (
	startDate: string,
	localFormat: LocalFormatFunction,
	shopCancellationPolicy: CancellationObject[],
) => {
	const { cancellationPolicy, noCancellation } = getCancellationPolicy(shopCancellationPolicy);
	const cancellationPolicyToRender = cancellationPolicy
		.map((cancellation) => {
			const { beforeHours, refundPercentage, displayAs } = cancellation;
			if (
				typeof refundPercentage !== 'number' ||
				typeof beforeHours !== 'number' ||
				refundPercentage === 0
			) {
				return null;
			}
			const cancelBefore = moment(startDate).subtract(beforeHours, 'hours');
			const cancelExpired = cancelBefore.isBefore(moment());
			return {
				beforeHours,
				refundPercentage,
				displayAs,
				cancelExpired,
				cancelBefore: localFormat(cancelBefore, 'ddd DD.MM. HH:mm'),
			};
		})
		.filter(notNull);
	return {
		cancellationPolicyToRender,
		noCancellation,
	};
};

export const isCancellationPolicyDefined = (
	shopCancellationPolicy: CancellationObject[] | null | undefined,
) =>
	!isEmpty(shopCancellationPolicy) &&
	!shopCancellationPolicy?.every((p) => !p.beforeHours) &&
	!shopCancellationPolicy?.every((p) => !p.refundPercentage);
