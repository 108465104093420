import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { a, config, useSpring, useSprings } from 'react-spring';
import { useDrag } from 'react-use-gesture';

import { useWindowSize } from 'common/hooks/useWindowSize';

interface ImageCarouselProps {
	images: string[];
	carouselWidth: number;
	alt: string;
}

interface RunSpringsProps {
	indexDiff: number;
	down: boolean;
	xDir: number;
	xMove: number;
	shouldScrollThumbnails: boolean;
}

const CarouselContainer = (props: { images: string[]; alt: string }) => {
	const classes = useStyles();
	const windowSize = useWindowSize();
	const [carouselWidth, setCarouselWidth] = useState(0);

	const carouselRef = useCallback(
		(node) => {
			if (node !== null) {
				const calculatedWidth = Math.ceil(node.getBoundingClientRect().width);
				setCarouselWidth(calculatedWidth);
			}
		},
		//eslint-disable-next-line
		[windowSize],
	);

	return (
		<div ref={carouselRef} className={classes.root}>
			{carouselWidth !== 0 ? <ImageCarousel {...props} carouselWidth={carouselWidth} /> : null}
		</div>
	);
};

export default CarouselContainer;

function ImageCarousel(props: ImageCarouselProps) {
	const { images, carouselWidth, alt } = props;

	const classes = useStyles();
	const [activeIdx, setActiveIdx] = useState(0);

	const prevIdx = useRef(0);
	const index = useRef(0);
	const thumbRef = useRef<HTMLDivElement>(null);
	const thumbsRefs = useRef<Array<HTMLDivElement | null>>([]);

	const getIdx = useCallback((x, l = images.length) => (x < 0 ? x + l : x) % l, [images]);

	useEffect(() => {
		thumbsRefs.current = thumbsRefs.current.slice(0, images.length);
	}, [images.length]);

	const [carouselSprings, springsApi] = useSprings(images.length, (i) => ({
		x: (i < images.length - 1 ? i : -1) * carouselWidth,
	}));

	const [_, animate] = useSpring(() => ({
		immediate: false,
		scrollLeft: thumbRef?.current?.scrollLeft ?? 0,
		onChange: (props: any) => {
			const changed = props.value.scrollLeft;
			if (!!thumbRef?.current) {
				thumbRef.current.scrollLeft = changed;
			}
		},
		config: config.default,
	}));

	const scrollToThumbnail = useCallback(
		(idx: number) => {
			const thumbnail = thumbsRefs.current[idx];

			if (!!thumbRef?.current && !!thumbnail) {
				const thumbOffset = thumbnail.offsetLeft;
				const thumbHalfWidth = thumbnail.getBoundingClientRect().width / 2;
				const scrollAreaWidth = thumbRef.current?.getBoundingClientRect().width / 2;

				const targetOffset = thumbOffset + thumbHalfWidth - scrollAreaWidth;

				animate.start({
					from: {
						scrollLeft: thumbRef.current.scrollLeft,
					},
					to: {
						scrollLeft: targetOffset,
					},
				});
			}
		},
		[animate],
	);

	const runSprings = useCallback(
		(props: RunSpringsProps) => {
			const { indexDiff, down, xDir, xMove, shouldScrollThumbnails } = props;
			const swipeThreshold = Math.abs(indexDiff) > 0.25;

			if (!down && swipeThreshold) {
				//Decides if to move to the next slide or back to the initial
				index.current += ((-xMove + (carouselWidth + xMove)) / carouselWidth) * (xDir > 0 ? -1 : 1);
			}

			const currentIndex = getIdx(index.current % images.length);
			const currentOffsetX = index.current * carouselWidth;

			setActiveIdx(currentIndex);
			if (shouldScrollThumbnails) {
				scrollToThumbnail(currentIndex);
			}

			springsApi((item) => {
				const position = getIdx(item - currentIndex + 1);
				const prevPosition = getIdx(item - prevIdx.current + 1);

				const rank =
					currentIndex -
					(currentOffsetX < 0 ? images.length : 0) +
					(currentOffsetX < 0 && currentIndex === 0 ? images.length : 0) +
					position -
					1;

				const targetX =
					(-currentOffsetX % (carouselWidth * images.length)) +
					carouselWidth * rank +
					(down ? xMove : 0);

				const shouldBeImmediate = (xMove === -0 && Math.abs(indexDiff) > 1) || images.length <= 2;

				return {
					x: targetX,
					immediate: shouldBeImmediate
						? true
						: indexDiff < 0
						? prevPosition > position
						: prevPosition < position,
				};
			});
			prevIdx.current = currentIndex;
		},
		[images.length, carouselWidth, getIdx, springsApi, scrollToThumbnail],
	);

	const bind = useDrag(
		({ vxvy: [vx], down, direction: [xDir], movement: [xMove] }) => {
			vx && runSprings({ indexDiff: -vx, down, xDir, xMove, shouldScrollThumbnails: true });
		},
		{ axis: 'x', preventScroll: true, preventScrollAxis: 'y' },
	);

	const buttons = (indexDiff: number) => {
		index.current += indexDiff;
		runSprings({ indexDiff, down: true, xDir: -0, xMove: -0, shouldScrollThumbnails: true });
	};

	const thumbnails = (idx: number) => {
		const indexDiff = idx - index.current;
		index.current += indexDiff;
		runSprings({ indexDiff, down: true, xDir: -0, xMove: -0, shouldScrollThumbnails: false });
	};

	const debounceTransition = debounce(buttons, 10);
	const handleThumbnailClick = debounce(thumbnails, 20);

	return (
		<Box className={classes.container}>
			<Box className={classes.carouselContainer}>
				<Box className={classNames(classes.carouselBtn, classes.btnLeft)}>
					<IconButton
						className={classes.transparentWhiteBg}
						onClick={() => debounceTransition(-1)}
						size="small"
					>
						<RiArrowLeftSLine />
					</IconButton>
				</Box>
				<div {...bind()} className={classes.carouselWrapper}>
					{carouselSprings.map(({ x }, i) => (
						<a.div
							key={i}
							className={classes.imageContainer}
							style={{
								width: carouselWidth,
								x,
							}}
						>
							<img className={classes.carouselImg} src={images[i]} alt={alt} />
						</a.div>
					))}
				</div>
				<Box className={classNames(classes.carouselBtn, classes.btnRight)}>
					<IconButton
						className={classes.transparentWhiteBg}
						onClick={() => debounceTransition(1)}
						size="small"
					>
						<RiArrowRightSLine />
					</IconButton>
				</Box>
			</Box>
			<Box style={{ maxWidth: carouselWidth }}>
				<a.div className={classes.thumbnailsWrapper} ref={thumbRef}>
					{images.map((_src, idx) => (
						<img
							ref={(el) => (thumbsRefs.current[idx] = el)}
							key={idx}
							onClick={() => handleThumbnailClick(idx)}
							className={classes.thumbnailImage}
							src={_src}
							style={{ border: activeIdx === idx ? '2px solid black' : 'none' }}
							alt={alt}
						/>
					))}
				</a.div>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			height: '110vw',
			[theme.breakpoints.between('md', 'xl')]: {
				marginRight: theme.spacing(2),
				maxHeight: 'min(44vw, 764px)',
			},
			[theme.breakpoints.up('xl')]: {
				marginRight: theme.spacing(2),
				maxHeight: 'min(37vw, 764px)',
			},
		},
		container: {
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
		},
		carouselContainer: {
			position: 'relative',
			height: '100%',
		},
		carouselBtn: {
			display: 'flex',
			margin: 'auto',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			zIndex: 99,
		},
		transparentWhiteBg: {
			backgroundColor: 'rgba(255,255,255,0.4)',
			'&:hover, &.Mui-focusVisible': {
				backgroundColor: 'rgba(255,255,255,0.5)',
			},
		},
		btnLeft: {
			left: theme.spacing(0.5),
		},
		btnRight: {
			right: theme.spacing(0.5),
		},
		carouselWrapper: {
			position: 'relative',
			height: '100%',
			overflow: 'hidden',
		},
		imageContainer: {
			position: 'absolute',
			willChange: 'transform',
			touchAction: 'pan-y pinch-zoom',
			height: '100%',
			overflowX: 'hidden',
			display: 'flex',
			justifyContent: 'center',
		},
		carouselImg: {
			height: '100%',
			width: '100%',
			objectFit: 'contain',
			objectPosition: 'center',
		},
		thumbnailsWrapper: {
			position: 'relative',
			width: '100%',
			marginTop: theme.spacing(2),
			paddingBottom: theme.spacing(0),
			display: 'flex',
			alignItems: 'center',
			overflowX: 'scroll',
			'-ms-overflow-style': 'none',
			scrollbarWidth: 'none',
			'&::-webkit-scrollbar': {
				display: 'none',
			},
			[theme.breakpoints.up('md')]: {
				marginTop: theme.spacing(4),
				paddingBottom: theme.spacing(2),
			},
		},
		thumbnailImage: {
			width: '65px',
			height: '65px',
			minWidth: '65px',
			border: '1px solid #C8C8C8',
			margin: theme.spacing(0, 1.5),
			cursor: 'pointer',
			marginBottom: theme.spacing(0),
			objectFit: 'contain',
			objectPosition: 'center',
			[theme.breakpoints.up('md')]: {
				width: '100px',
				height: '100px',
				minWidth: '100px',
				marginBottom: theme.spacing(1),
			},
		},
	}),
);
