import { createSelector } from '@reduxjs/toolkit';
import { maxBy, minBy } from 'lodash';
import { ReduxState } from 'redux/types';

import { isBookedOrderMissingDetails } from 'common/utils/confirmation';
import { getResponsiblePersonDetails } from 'common/utils/newRentalUtils';

export const order = (state: ReduxState) => state.confirm.order.data;

export const orderError = (state: ReduxState) => state.confirm.order.error;

export const orderLoading = (state: ReduxState) => state.confirm.order.loading;

export const orderData_FORCE = (state: ReduxState) => state.confirm.order.data!;

export const shopperIdsWithDTAImage = (state: ReduxState) => state.confirm.shopperIdsWithDTAImage;

export const responsiblePersonDetails = createSelector(order, (order) => {
	if (!order) return null;

	return getResponsiblePersonDetails(order.rentalInfo.responsiblePerson, order.shoppers);
});

export const customCheckoutFields = createSelector(order, (order) => {
	return order?.rentalInfo?.customCheckoutFields;
});

export const isMissingDetails = createSelector(order, (order) => {
	if (!order) return false;

	return isBookedOrderMissingDetails(order.products, order.shoppers);
});

export const startDate = createSelector(order, (order) => {
	return minBy(order?.products, (product) => product.startDate)?.startDate ?? null;
});

export const endDate = createSelector(order, (order) => {
	return maxBy(order?.products, (product) => product.endDate)?.endDate ?? null;
});
