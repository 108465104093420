import React from 'react';

import PlusIcon from '@mui/icons-material/Add';
import { Step, StepButton, StepLabel, Stepper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { addShopper } from 'redux/actions/confirm';
import * as ConfirmSelectors from 'redux/selectors/confirm';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import { Shopper } from 'common/types';
import { getEmptyShopper, newFirestoreId } from 'common/utils/newRentalUtils';
import { useTranslation } from 'services/localization/useTranslation';

import { onlyUnique } from './utils/shopperUtils';

const ShopperHeaderBar = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const shopperIndex = useSelector(ViewSelectors.activeShopperIndex);
	const shopId = useSelector(ShopSelectors.shopId);
	const order = useSelector(ConfirmSelectors.orderData_FORCE);
	const { rentalInfo, products, shoppers } = order;

	const productsCount = products.length;
	const shoppersCount = shoppers.length;

	const addNewShopper = () => {
		const shopperId = newFirestoreId();
		const newShopper = getEmptyShopper(shopperId, rentalInfo.id, shopId);
		dispatch(addShopper(newShopper));
	};

	const shopperHasNeededDetailsForProduct = (shopper: Shopper) => {
		const { productIds, userProperties } = shopper;
		const neededDetails = products
			.filter((p) => productIds.includes(p.id))
			.map((p) => p.userDetails)
			.flat()
			.filter((detail) => detail && detail.required)
			.map((detail) => detail!.name)
			.filter(onlyUnique)
			.sort();
		const userDetails = Object.keys(userProperties).sort();
		return productIds.length > 0 && isEqual(neededDetails, userDetails);
	};

	const renderShoppersSteps = (shoppers: Shopper[]) =>
		shoppers.map((shopper, index) => {
			const completed = shopperIndex !== index && shopperHasNeededDetailsForProduct(shopper);

			return (
				<Step key={index} completed={completed}>
					<StepLabel
						classes={{ label: classes.stepLabel }}
						StepIconProps={{
							classes: {
								root: classes.icon,
								completed: classes.completed,
							},
						}}
					>
						{shopper.firstName || t('common:person.person') + ' ' + (index + 1)}
					</StepLabel>
				</Step>
			);
		});

	const renderAddPersonStep = () => {
		return (
			<Step key={'add-person'} disabled={false}>
				<StepButton
					classes={{
						root: classes.disablePaddingMargin,
					}}
					onClick={addNewShopper}
					icon={<PlusIcon />}
				>
					<StepLabel classes={{ label: classes.stepLabel }}>{t('common:actions.add')}</StepLabel>
				</StepButton>
			</Step>
		);
	};

	return (
		<div
			className={classNames(classes.headerBar, shoppers.length > 1 ? classes.flexWrapForSmall : {})}
		>
			<div
				className={classNames(
					classes.stepperWrapper,
					shoppers.length > 1 ? classes.dropUsersForSmall : {},
				)}
			>
				<Stepper
					alternativeLabel
					nonLinear
					activeStep={shopperIndex || undefined}
					classes={{
						root: classes.stepper,
					}}
				>
					{renderShoppersSteps(shoppers)}
					{shoppersCount < productsCount && renderAddPersonStep()}
				</Stepper>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		headerBar: {
			position: 'relative',
			top: 0,
			left: 0,
			width: '100%',
			padding: '8px 16px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			backgroundColor: '#fff',
		},
		stepLabel: {
			margin: '8px auto !important',
			lineHeight: 1.2,
			width: 70,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		disablePaddingMargin: {
			padding: 0,
			margin: 0,
		},
		backBtn: {
			flex: '0 0 auto',
			marginLeft: -16,
			marginRight: -16,
		},
		exitBtn: {
			flex: '0 0 auto',
			color: theme.palette.error.main,
			marginLeft: -16,
			marginRight: -16,
		},
		stepper: {
			margin: `${theme.spacing(2)} 0`,
			backgroundColor: 'transparent',
			justifyContent: 'flex-start',
			padding: 0,
		},
		stepperWrapper: {
			margin: '0 16px',
			flex: '1 1 auto',
			overflowX: 'auto',
			textAlign: 'center',
			overflowY: 'hidden',
		},
		dropUsersForSmall: {
			[theme.breakpoints.down('sm')]: {
				flex: '1 1 100%',
				order: 2,
				paddingTop: 16,
			},
		},
		flexWrapForSmall: {
			[theme.breakpoints.down('sm')]: {
				flexWrap: 'wrap',
			},
		},
		arrowSpacing: {
			marginRight: 8,
		},
		icon: {
			color: '#716a80',
			'&$completed': {
				color: '#716a80',
			},
		},
		completed: {},
	}),
);

export default ShopperHeaderBar;
