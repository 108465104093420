import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Container, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { has } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateShopper } from 'redux/actions/confirm';
import { setActiveUnitSystem } from 'redux/actions/view';
import * as ConfirmSelectors from 'redux/selectors/confirm';
import * as NavSelectors from 'redux/selectors/nav';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import UnitSystemSelect from 'common/components/UnitSystemSelect';
import UserDetailField from 'common/components/UserDetailField';
import { UnitSystemType, UserDetailName, UserProperty } from 'common/types';
import { getCurrencyObjectFromCurrencyCode } from 'common/utils/currencyUtils';
import {
	filterExistingSkipassProducts,
	productsIncludeExistingSkipass,
} from 'common/utils/liftTicketUtils';
import {
	detailsIncludeMeasurementUnits,
	getSortedRequiredDetails,
	getUpdatedShopperFromDetailChange,
	getUpdatedShopperFromUnitSystemChange,
} from 'common/utils/userDetails';
import { useTranslation } from 'services/localization/useTranslation';

import ShopperHeaderBar from './ShopperNameStepper';
import KeycardDetails from './components/KeycardDetails';
import { measurementsBack, measurementsContinue } from './shopperInfoNavigation';

const ShopperMeasurements = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const activeShopperIndex = useSelector(ViewSelectors.activeShopperIndex);
	const order = useSelector(ConfirmSelectors.orderData_FORCE);
	const { rentalInfo, products, shoppers } = order;

	const shopName = useSelector(NavSelectors.shopName);
	const locationName = useSelector(NavSelectors.locationName);

	const shopUnitSystem = useSelector(ShopSelectors.shopUnitSystem);
	const activeUnitSystem = useSelector(ViewSelectors.unitSystem);

	const [isKeycardValid, setIsKeycardValid] = useState(true);
	const unitSystem = activeUnitSystem ?? shopUnitSystem;

	const activeShopper = shoppers.find((s, i) => i === activeShopperIndex)!;

	const shopperProducts = products.filter((p) => activeShopper.productIds.includes(p.id));
	const shouldAskKeycardNumber = productsIncludeExistingSkipass(shopperProducts);
	const liftTicketProduct = filterExistingSkipassProducts(shopperProducts)[0];
	const requiredDetails = getSortedRequiredDetails(shopperProducts);

	const handleContinueButton = useCallback(() => {
		measurementsContinue({ shopName, locationName, shoppers, products, activeShopperIndex });
	}, [products, shopName, shoppers, locationName, activeShopperIndex]);

	useEffect(() => {
		if (!requiredDetails.length && !shouldAskKeycardNumber) {
			handleContinueButton();
		}
	}, [
		handleContinueButton,
		products.length,
		shopName,
		shopperProducts,
		shouldAskKeycardNumber,
		requiredDetails,
	]);

	const isFormValid = useMemo(() => {
		return (
			requiredDetails.every((detailName) => has(activeShopper.userProperties, detailName)) &&
			isKeycardValid
		);
	}, [requiredDetails, isKeycardValid, activeShopper.userProperties]);

	const handleDetailChange = useCallback(
		(value: UserProperty | null, detail: UserDetailName) => {
			const updatedShopper = getUpdatedShopperFromDetailChange(activeShopper, detail, value);
			dispatch(updateShopper(updatedShopper));
		},
		[activeShopper, dispatch],
	);

	const handleUnitSystemChange = useCallback(
		(newUnitSystem: UnitSystemType) => {
			const updatedShopper = getUpdatedShopperFromUnitSystemChange(activeShopper, newUnitSystem);
			dispatch(updateShopper(updatedShopper));
			dispatch(setActiveUnitSystem(newUnitSystem !== shopUnitSystem ? newUnitSystem : null));
		},
		[shopUnitSystem, dispatch, activeShopper],
	);

	const handleBackButton = useCallback(() => measurementsBack(shopName, locationName), [
		shopName,
		locationName,
	]);

	const renderMeasurements = () => {
		return requiredDetails.length > 0 ? (
			<Box>
				{detailsIncludeMeasurementUnits(requiredDetails) && (
					<Box mb={1} textAlign="right">
						<UnitSystemSelect value={unitSystem} onChange={handleUnitSystemChange} t={t} />
					</Box>
				)}
				<Grid container spacing={2}>
					{requiredDetails.map((detail, index) => {
						return (
							<Grid item xs={12} sm={12} key={detail + index}>
								<UserDetailField
									detail={detail}
									unitSystem={unitSystem}
									userProperty={activeShopper.userProperties?.[detail]}
									onChange={(newValue) => handleDetailChange(newValue, detail)}
									t={t}
								/>
							</Grid>
						);
					})}
				</Grid>
			</Box>
		) : null;
	};

	return (
		<Container maxWidth="sm" className={classes.section}>
			<ShopperHeaderBar />
			<div>
				<Typography className={classes.smallSpacingBottom} variant={'h5'}>
					{activeShopper.firstName + ','}
				</Typography>
				<Typography className={classes.spacingBottom} variant={'h6'}>
					{t(
						'shopperInfo.weNeedTheFollowing',
						'We need the following details to prepare your order:',
					)}
				</Typography>
				{renderMeasurements()}
				{shouldAskKeycardNumber && (
					<KeycardDetails
						liftTicketProduct={liftTicketProduct}
						currency={getCurrencyObjectFromCurrencyCode(rentalInfo.pricing.currency)}
						setIsKeycardValid={setIsKeycardValid}
						liftTicketProductsWithExistingKeycard={filterExistingSkipassProducts(products)}
					/>
				)}
			</div>
			<Grid container className={classes.buttonContainer} spacing={2}>
				<Grid item xs={12} md>
					<Button
						variant="contained"
						color="primary"
						className={classes.btn}
						onClick={handleContinueButton}
						disabled={!isFormValid}
					>
						{t('common:actions.continue')}
					</Button>
				</Grid>
				{products.length > 1 && (
					<Grid item xs={12} md>
						<Button
							variant="outlined"
							color="primary"
							className={classes.btn}
							onClick={handleBackButton}
						>
							{t('common:actions.back')}
						</Button>
					</Grid>
				)}
			</Grid>
		</Container>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		section: {
			paddingBottom: theme.spacing(6),
		},
		buttonContainer: {
			marginTop: 32,
			marginBottom: 32,
			textAlign: 'center',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'row-reverse',
			},
		},
		btn: {
			paddingTop: 12,
			paddingBottom: 12,
			width: '100%',
			[theme.breakpoints.up('md')]: {
				margin: 0,
			},
		},
		textField: {
			width: '100%',
			marginBottom: theme.spacing(1),
		},
		menu: {
			width: 200,
		},
		spacingBottom: {
			marginBottom: theme.spacing(4),
		},
		smallSpacingBottom: {
			marginBottom: theme.spacing(1),
		},
	}),
);

export default ShopperMeasurements;
