import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import Spinner from 'common/components/Spinner';
import useExternalAuth from 'hooks/useExternalAuth';
import { ExternalAuthProvider } from 'hooks/useExternalAuth/authProviders';
import { useTranslation } from 'services/localization';

const ExternalLogin = () => {
	const { t } = useTranslation();
	const { oauthRedirect, authProfile, authProvider } = useExternalAuth();
	const profileLoading = authProfile.loading;

	const loginDescriptions: Record<ExternalAuthProvider, string> = {
		DECATHLON: t('decathlon.signInInfo', 'To continue, sign in with your Decathlon account'),
		MOCK: t('auth.signInToContinue', 'To continue, sign in with your account'),
	};

	const loginDescription = authProvider ? loginDescriptions[authProvider] ?? undefined : undefined;

	if (profileLoading) {
		return (
			<Box position="relative" height="80px">
				<Spinner text={t('common:actions.signingIn', 'Signing in')} padding={8} />
			</Box>
		);
	}

	return (
		<Box>
			{loginDescription && <Typography variant="body1">{loginDescription}.</Typography>}
			<Box mt={4} />
			<Button fullWidth variant="contained" color="primary" onClick={oauthRedirect}>
				{t('common:actions.signIn', 'Sign in')}
			</Button>
		</Box>
	);
};

export default ExternalLogin;
