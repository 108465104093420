import React from 'react';

import { Grid, Theme, Typography, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useStripe,
} from '@stripe/react-stripe-js';
import { TFunction } from 'i18next';

import { LocaleHelpers } from 'common/locales/helpers';

interface Props {
	t: TFunction;
}

const StripeCardElement = ({ t }: Props) => {
	const classes = useStyles();
	const stripe = useStripe();
	const theme = useTheme();
	const CARD_ELEMENT_OPTIONS = {
		style: {
			base: {
				fontFamily: theme.typography.fontFamily,
				fontSize: '16px',
				color: theme.palette.text.primary,
			},
			invalid: {
				color: theme.palette.error.main,
			},
		},
	};

	if (!stripe) {
		<Typography>{LocaleHelpers.common(t).errors.SOMETHING_WRONG}</Typography>;
	}

	return (
		<div>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography className={classes.inputLabel} variant="body2">
						{t('common:form.cardNumber', 'Card number')}
					</Typography>
					<CardNumberElement className={classes.wrapper} options={CARD_ELEMENT_OPTIONS} />
				</Grid>
				<Grid item container spacing={2}>
					<Grid item xs={6}>
						<Typography className={classes.inputLabel} variant="body2">
							{t('common:form.expiryDate', 'Expiry date')}
						</Typography>
						<CardExpiryElement className={classes.wrapper} options={CARD_ELEMENT_OPTIONS} />
					</Grid>
					<Grid item xs={6}>
						<Typography className={classes.inputLabel} variant="body2">
							CVC / CVV
						</Typography>
						<CardCvcElement className={classes.wrapper} options={CARD_ELEMENT_OPTIONS} />
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			border: `1px solid ${theme.palette.border.inputOutline}`,
			fontSize: '1.4rem',
			fontFamily: theme.typography.fontFamily,
			fontWeight: 400,
			padding: theme.spacing(2, 1),
		},
		inputLabel: {
			fontWeight: 400,
		},
	}),
);

export default StripeCardElement;
