import { ReduxThunkAction } from 'redux/types';

import { EcomEventName } from 'common/services/analytics';

import { EcomEventParams } from '../types';
import { logAddPaymentInfo } from './addPaymentInfo';
import { logAddToCart } from './addToCart';
import { logBeginCheckout } from './beginCheckout';
import { logPurchase } from './purchase';
import { logRemoveFromCart } from './removeFromCart';
import { logSelectItem } from './selectItem';
import { logViewCart } from './viewCart';
import { logViewItem } from './viewItem';
import { logViewItemList } from './viewItemList';

export const logEcomEvent = <T extends EcomEventName>(
	eventName: T,
	eventParams: EcomEventParams[T],
): ReduxThunkAction => (dispatch, _) => {
	switch (eventName) {
		case 'view_item_list':
			dispatch(logViewItemList(eventParams as EcomEventParams['view_item_list']));
			break;
		case 'select_item':
			dispatch(logSelectItem(eventParams as EcomEventParams['select_item']));
			break;
		case 'view_item':
			dispatch(logViewItem(eventParams as EcomEventParams['view_item']));
			break;
		case 'add_to_cart':
			dispatch(logAddToCart(eventParams as EcomEventParams['add_to_cart']));
			break;
		case 'view_cart':
			dispatch(logViewCart(eventParams as EcomEventParams['view_cart']));
			break;
		case 'remove_from_cart':
			dispatch(logRemoveFromCart(eventParams as EcomEventParams['remove_from_cart']));
			break;
		case 'begin_checkout':
			dispatch(logBeginCheckout(eventParams as EcomEventParams['begin_checkout']));
			break;
		case 'add_payment_info':
			dispatch(logAddPaymentInfo(eventParams as EcomEventParams['add_payment_info']));
			break;
		case 'purchase':
			dispatch(logPurchase(eventParams as EcomEventParams['purchase']));
			break;
		default:
			break;
	}
};
