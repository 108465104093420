import React from 'react';

import { Button, Container, Dialog, DialogTitle, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { Shopper } from 'common/types';
import { useTranslation } from 'services/localization/useTranslation';

interface Props {
	shopper: Shopper | null;
	open: boolean;
	closeDialog: () => void;
	deleteShopper: (shopperId: string) => void;
}

const RemoveShopperDialog = (props: Props) => {
	const classes = useStyles();
	const { closeDialog, open, shopper } = props;
	const { t } = useTranslation();

	const deleteShopper = () => {
		if (shopper) props.deleteShopper(shopper.id);
		closeDialog();
	};

	return (
		<Dialog onClose={closeDialog} aria-labelledby="simple-dialog-title" open={open}>
			{shopper && (
				<DialogTitle id="simple-dialog-title">
					{t('shopperInfo.deleteShopperTitle', 'Are you sure you want to delete')}{' '}
					{shopper.firstName}?
				</DialogTitle>
			)}
			<Container>
				<Typography variant="body2">
					{t('shopperInfo.deleteShopperText', 'You will not be able to undo this action.')}
				</Typography>
				<div className={classes.buttonContainer}>
					<Button
						variant="contained"
						color="primary"
						className={classes.btn}
						onClick={deleteShopper}
					>
						{t('common:actions.delete', 'Delete')}
					</Button>
					<Button
						variant="outlined"
						color="secondary"
						className={classes.btn}
						onClick={closeDialog}
					>
						{t('common:actions.cancel')}
					</Button>
				</div>
			</Container>
		</Dialog>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonContainer: {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(2),
			margin: 'auto',
			textAlign: 'center',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'row-reverse',
			},
		},
		btn: {
			margin: '8px auto',
			paddingTop: theme.spacing(1.5),
			paddingBottom: theme.spacing(1.5),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: '40%',
				margin: 0,
			},
		},
	}),
);

export default RemoveShopperDialog;
