import React, { FunctionComponent, useState } from 'react';

import {
	Backdrop,
	Box,
	Button,
	ButtonProps,
	CircularProgress,
	Collapse,
	Modal,
	Stack,
	Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { SxProps } from '@mui/system';
import classNames from 'classnames';
import { RiCloseLine } from 'react-icons/ri';

import Spinner from './Spinner';
import StripePattern from './StripePattern';

type DialogVariant = 'danger' | 'primary' | 'secondary';

export interface DialogAction {
	icon?: {
		icon: JSX.Element;
		position: 'left' | 'right';
	};
	label: string;
	callback?: () => void;
	closeAfterDone?: boolean;
	disabled?: boolean;
	loading?: boolean;
	disableAutoClose?: boolean;
	autoFocus?: boolean;
	sx?: SxProps<Theme>;
	variant?: NonNullable<ButtonProps['variant']>;
	color?: NonNullable<ButtonProps['color']>;
}
interface Props {
	isOpen: boolean;
	onClose: (action: 'click_away' | 'cancel' | 'confirm' | 'left-action') => void;
	label?: string | JSX.Element;
	header?: JSX.Element;
	body?: JSX.Element;
	leftActionBtn?: DialogAction;
	confirm?: DialogAction;
	cancel?: DialogAction;
	titleCloseButton?: boolean;
	buttonJustify?: 'center' | 'flex-end';
	buttonDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
	variant?: DialogVariant;
	width?: 'xs' | 'sm' | 'md' | 'lg';
	buttonDataQA?: string;
	labelDataQA?: string;
	zIndex?: number;
	buttonFullWidth?: boolean;
	buttonBoxShadow?: boolean;
	fullScreen?: boolean;
	fullScreenAutoHeight?: boolean;
	wideDialog?: boolean;
	position?: 'top' | 'center';
	backdropTimeout?: number;
	disableCloseOnClickAway?: boolean;
}

const BackdropDialog: FunctionComponent<Props> = (props) => {
	const classes = useStyles(props);
	const [loading, setLoading] = useState<boolean>(false);

	const stopPropagation = (e: React.MouseEvent) => {
		e.stopPropagation();
	};

	const handleModalClose = () => {
		if (loading || !!props.disableCloseOnClickAway) return;
		props.onClose('click_away');
	};

	const handleCancel = async () => {
		if (props?.cancel?.callback) {
			if (props.cancel.closeAfterDone) {
				setLoading(true);
				await props.cancel.callback();
			} else {
				props.cancel.callback();
			}
		}
		if (!props.cancel?.disableAutoClose) {
			props.onClose('cancel');
		}
		setLoading(false);
	};

	const handleConfirm = async () => {
		if (props?.confirm?.callback) {
			if (props.confirm.closeAfterDone) {
				setLoading(true);
				await props.confirm.callback();
			} else {
				props.confirm.callback();
			}
		}
		if (!props.confirm?.disableAutoClose) {
			props.onClose('confirm');
		}
		setLoading(false);
	};

	const handleLeftActionBtnClick = async () => {
		if (props?.leftActionBtn?.callback) {
			if (props.leftActionBtn.closeAfterDone) {
				setLoading(true);
				await props.leftActionBtn.callback();
			} else {
				props.leftActionBtn.callback();
			}
		}
		if (!props.leftActionBtn?.disableAutoClose) {
			props.onClose('left-action');
		}
		setLoading(false);
	};

	const getButtonColor = (dialogVariant: DialogVariant | undefined) => {
		const variantMap: Record<DialogVariant, NonNullable<ButtonProps['color']>> = {
			danger: 'error',
			primary: 'primary',
			secondary: 'secondary',
		};
		return !dialogVariant ? 'primary' : variantMap[dialogVariant] ?? 'primary';
	};

	const getButtonLabelWithIcon = (label: string, icon: JSX.Element, position: 'left' | 'right') => (
		<Box sx={{ display: 'flex' }}>
			{position === 'left' && icon}
			<Typography
				sx={{
					fontWeight: 'bold',
					ml: position === 'left' ? 1 : undefined,
					mr: position === 'right' ? 1 : undefined,
				}}
				variant="body1"
			>
				{label}
			</Typography>
			{position === 'right' && icon}
		</Box>
	);

	return (
		<Modal
			open={props.isOpen}
			onClose={handleModalClose}
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: props.backdropTimeout ?? 300,
				classes: {
					root: classes.blurred,
				},
			}}
			className={classes.modal}
			data-iframe-height
		>
			<Collapse
				in={props.isOpen && !loading}
				collapsedSize="0"
				className={classNames(classes.collapse, { [classes.wideDialog]: props.wideDialog })}
				classes={{
					wrapper: classes.collapseFlex,
					wrapperInner: classes.collapseFlex,
				}}
			>
				<div className={classes.dialog} role="dialog" onClick={stopPropagation}>
					{!!props.label && (
						<Box className={classes.dialogTitleWrapper}>
							<StripePattern color="dark" opacity={0.4} />
							{typeof props.label === 'string' ? (
								<Typography
									variant="h6"
									className={classes.dialogTitle}
									data-qa={props.labelDataQA}
								>
									{props.label}
								</Typography>
							) : (
								props.label
							)}
							{props.titleCloseButton && (
								<Stack
									justifyContent="center"
									sx={{ zIndex: 999, cursor: 'pointer' }}
									onClick={() => props.onClose('cancel')}
								>
									<RiCloseLine size={20} />
								</Stack>
							)}
						</Box>
					)}
					{!!props.header && <Box className={classes.dialogHeader}>{props.header}</Box>}
					<Box className={classes.dialogContent}>{props.body}</Box>
					<Box className={classes.dialogButtons}>
						{props.leftActionBtn && (
							<Box display="flex" flex="1">
								<Button
									onClick={handleLeftActionBtnClick}
									variant={props.leftActionBtn.variant ?? 'text'}
									color={props.leftActionBtn.color ?? 'secondary'}
									className={classes.cancelButton}
									autoFocus={props.leftActionBtn.autoFocus ?? true}
									disabled={!!props.leftActionBtn.disabled}
									sx={props.leftActionBtn.sx}
								>
									{props.leftActionBtn.loading ? (
										<CircularProgress size={20} color="inherit" />
									) : (
										props.leftActionBtn.label
									)}
								</Button>
							</Box>
						)}
						{props.cancel && (
							<Button
								onClick={handleCancel}
								variant={props.cancel.variant ?? 'text'}
								color={props.cancel.color ?? 'secondary'}
								className={classes.cancelButton}
								autoFocus={props.cancel.autoFocus ?? true}
								data-qa={props.buttonDataQA}
								disabled={!!props.cancel.disabled}
								fullWidth={props.buttonFullWidth}
								sx={props.cancel.sx}
							>
								{props.cancel.loading ? (
									<CircularProgress size={20} color="inherit" />
								) : props.cancel.icon ? (
									getButtonLabelWithIcon(
										props.cancel.label,
										props.cancel.icon.icon,
										props.cancel.icon.position,
									)
								) : (
									props.cancel.label
								)}
							</Button>
						)}
						{props.confirm && (
							<Button
								onClick={handleConfirm}
								variant={props.confirm.variant ?? 'contained'}
								color={props.confirm.color ?? getButtonColor(props.variant)}
								className={classes.confirmButton}
								disabled={!!props.confirm.disabled || !!props.confirm.loading}
								fullWidth={props.buttonFullWidth}
								sx={props.confirm.sx}
							>
								{props.confirm.loading ? (
									<CircularProgress size={20} color="inherit" />
								) : props.confirm.icon ? (
									getButtonLabelWithIcon(
										props.confirm.label,
										props.confirm.icon.icon,
										props.confirm.icon.position,
									)
								) : (
									props.confirm.label
								)}
							</Button>
						)}
					</Box>
				</div>
				{loading && (
					<div className={classes.loadingWrapper}>
						<Spinner color="white" />
					</div>
				)}
			</Collapse>
		</Modal>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		blurred: {
			backdropFilter: 'blur(3px)',
			backgroundColor: 'rgba(0, 0, 0, 0.8)',
		},
		modal: {
			flex: 1,
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: ({ position }: Props) => (position === 'top' ? 'flex-start' : 'center'),
			zIndex: ({ zIndex }: Props) => (zIndex ? zIndex : theme.zIndex.modal),
			paddingTop: ({ position, fullScreen = false }: Props) =>
				!fullScreen && position === 'top' ? theme.spacing(4) : 0,
		},
		collapse: {
			margin: theme.spacing(0, 2),
			width: ({ fullScreen = false }: Props) =>
				fullScreen ? '100%' : `calc(100% - ${theme.spacing(2 * 2)})`,
			maxWidth: ({ width = 'sm', fullScreen = false }: Props) =>
				fullScreen ? '100%' : width === 'xs' ? 450 : theme.breakpoints.values[width],
			maxHeight: ({ position, fullScreen = false }: Props) =>
				position === 'top' ? 'none' : fullScreen ? '100%' : '90%',
			height: ({ fullScreen = false, fullScreenAutoHeight = false }: Props) =>
				fullScreen && !fullScreenAutoHeight ? '100%' : 'auto',
			display: 'flex',
			'&:focus': {
				outline: 'none',
			},
			[theme.breakpoints.down('sm')]: {
				maxHeight: '100%',
				margin: 0,
			},
		},
		collapseFlex: {
			display: 'flex',
			flex: 1,
		},
		wideDialog: {
			maxWidth: ({ width = 'sm' }: Props) => (width === 'xs' ? 450 : 800),
		},
		loadingWrapper: {
			position: 'fixed',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
		},
		dialog: {
			pointerEvents: 'auto',
			position: 'relative',
			zIndex: 2,
			background: theme.palette.background.paper,
			width: '90%',
			margin: '0 auto',
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'stretch',
		},
		dialogTitleWrapper: {
			padding: theme.spacing(2),
			backgroundColor: (props: Props) => {
				switch (props.variant) {
					case 'danger':
						return theme.palette.error.main;
					case 'primary':
						return theme.palette.primary.dark;
					case 'secondary':
						return theme.palette.secondary.dark;
					default:
						return theme.palette.primary.dark;
				}
			},
			position: 'relative',
			overflow: 'hidden',
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			color: theme.palette.common.white,
		},
		dialogTitle: {
			position: 'relative',
			zIndex: 2,
			color: '#fff',
			padding: theme.spacing(0, 1),
			borderRadius: '30px',
		},
		dialogHeader: {
			background: '#fff',
			position: 'sticky',
			top: '0',
			zIndex: ({ zIndex }: Props) => (zIndex ? zIndex + 1 : theme.zIndex.modal + 1),
		},
		dialogContent: {
			background: '#fff',
			minHeight: ({ width }: Props) => (width === 'xs' ? 0 : 200),
			width: '100%',
			padding: ({ header }: Props) => (header ? theme.spacing(0, 4, 3, 4) : theme.spacing(4)),
			flex: 1,
			overflow: 'auto',
			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(2, 4),
			},
		},
		dialogButtons: {
			padding: ({ header }: Props) =>
				header ? theme.spacing(3, 4, 4, 4) : theme.spacing(2, 4, 4, 4),
			display: 'flex',
			flexDirection: (props: Props) => props.buttonDirection ?? 'row',
			alignItems: 'center',
			justifyContent: (props: Props) => props.buttonJustify ?? 'flex-end',
			flexWrap: 'wrap',
			boxShadow: (props: Props) =>
				props.buttonBoxShadow ? '0 -2px 16px 0 rgba(0, 0, 0, 0.11)' : undefined,
			zIndex: ({ zIndex }: Props) => (zIndex ? zIndex + 1 : theme.zIndex.modal + 1), // Important here to make sure Buttons are always higher than the content in order for shadow to be shown.
		},
		cancelButton: {
			marginTop: (props: Props) =>
				props.buttonDirection === 'column-reverse' ? theme.spacing(2) : 0,
		},
		confirmButton: {
			marginLeft: (props: Props) => (props.buttonFullWidth ? 0 : theme.spacing(1)),
		},
	}),
);

export default BackdropDialog;
