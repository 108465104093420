import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { RiTruckLine } from 'react-icons/ri';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';

import { timeslotToString } from 'common/modules/delivery/utils';
import { CartDelivery, DeliveryTimeSlot } from 'common/types';
import { getPricingString, notFalsey } from 'common/utils/common';
import useShopFormat from 'hooks/useShopFormat';
import { useTranslation } from 'services/localization/useTranslation';

interface Props {
	delivery: CartDelivery;
	innerPadding?: number;
	noIcon?: boolean;
	startDate?: string | null;
	endDate?: string | null;
	hideSelfReturn?: boolean;
}

const CartDeliveryRow = (props: Props) => {
	const { noIcon, delivery, startDate, endDate, innerPadding, hideSelfReturn } = props;
	const { getTranslation } = useTranslation();

	const deliveryTimeslot =
		!!startDate && !!delivery.to?.timeslot
			? { date: startDate, timeslot: delivery.to.timeslot }
			: undefined;

	const pickupTimeslot =
		!!endDate && !!delivery.from?.timeslot
			? { date: endDate, timeslot: delivery.from.timeslot }
			: undefined;

	const selfReturn =
		!hideSelfReturn && !!endDate && !!delivery.from?.disabled
			? {
					date: endDate,
			  }
			: undefined;

	return (
		<Box my={2}>
			<Row
				name={getTranslation(delivery.name)}
				deliveryTimeslot={deliveryTimeslot}
				pickupTimeslot={pickupTimeslot}
				selfReturn={selfReturn}
				price={delivery.pricing.subtotal}
				showIcon={!noIcon}
				innerPadding={innerPadding}
			/>
		</Box>
	);
};

const Row = (props: {
	name: string;
	deliveryTimeslot?: {
		date: string;
		timeslot: DeliveryTimeSlot;
	};
	pickupTimeslot?: {
		date: string;
		timeslot: DeliveryTimeSlot;
	};
	selfReturn?: {
		date: string;
	};
	price: number;
	showIcon?: boolean;
	flippedIcon?: boolean;
	marginTop?: boolean;
	innerPadding?: number;
}) => {
	const { t } = useTranslation();
	const { localFormat, shopDateFormat } = useShopFormat();
	const classes = useStyles({ innerPadding: props?.innerPadding });
	const currency = useSelector(ShopSelectors.shopCurrency);
	const shopTimezone = useSelector(ShopSelectors.shopTimezone);
	const {
		name,
		deliveryTimeslot,
		pickupTimeslot,
		selfReturn,
		price,
		showIcon,
		flippedIcon,
		marginTop,
	} = props;

	const hasMultipleRows =
		[!!deliveryTimeslot, !!pickupTimeslot, !!selfReturn].filter(notFalsey).length > 1;

	return (
		<Box className={classes.container} mt={marginTop ? 2 : 0}>
			<Box className={classes.wrapper}>
				{!!showIcon && (
					<RiTruckLine
						size={18}
						color="#484848"
						className={classNames(classes.orderDeliveryIcon, {
							[classes.flipped]: flippedIcon,
						})}
					/>
				)}
				<Box>
					<Typography variant="body2" display="inline" className={classes.deliveryName}>
						{name}
					</Typography>
					{!!deliveryTimeslot && (
						<Typography variant="body2" display="block" className={classes.deliveryDate}>
							{hasMultipleRows && `${t('delivery.delivery', 'Delivery')}: `}
							{timeslotToString({
								timeslot: deliveryTimeslot.timeslot,
								shopDateFormat,
								timezone: shopTimezone,
							})}
						</Typography>
					)}
					{!!pickupTimeslot ? (
						<Typography variant="body2" display="block" className={classes.deliveryDate}>
							{hasMultipleRows && `${t('delivery.pickup', 'Pickup')}: `}
							{timeslotToString({
								timeslot: pickupTimeslot.timeslot,
								shopDateFormat,
								timezone: shopTimezone,
							})}
						</Typography>
					) : !!selfReturn ? (
						<Typography variant="body2" display="block" className={classes.deliveryDate}>
							{hasMultipleRows && `${t('delivery.selfReturn', 'Self-return')}: `}
							{localFormat(moment(selfReturn.date), 'ddd. MMM D, HH:mm')}
						</Typography>
					) : null}
				</Box>
				<Box className={classes.price}>
					<Typography variant="body2" className={classes.subtotal}>
						{getPricingString(price, currency)}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: ({ innerPadding }: { innerPadding?: number }) =>
				innerPadding ? theme.spacing(0, innerPadding) : 0,
		},
		wrapper: {
			display: 'flex',
			flexDirection: 'row',
		},
		price: {
			margin: `0 0 auto auto`,
		},
		orderDeliveryIcon: {
			display: 'inline-block',
			marginRight: theme.spacing(1),
			marginTop: '2px',
		},
		subtotal: {
			fontWeight: 500,
		},
		flipped: {
			transform: 'scaleX(-1)',
		},
		deliveryName: {
			fontSize: '14px',
			fontWeight: 500,
		},
		deliveryDate: {
			fontSize: '12px',
			fontWeight: 400,
		},
		returnDetails: {
			flex: 0.7,
		},
	}),
);

export default CartDeliveryRow;
