import React from 'react';

import { Box, BoxProps, useTheme } from '@mui/material';

interface Props extends BoxProps {
	max?: 'sm' | 'md' | 'lg' | 'xl';
}

/** Similar to Container in other packages, but only for centering content on the page - does not have margin/padding etc. */

const CenteredBox: React.FC<Props> = (props) => {
	const { breakpoints } = useTheme();
	const style = {
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '100%',
		maxWidth: breakpoints.values[props.max ?? 'md'] ?? breakpoints.values.md,
	};

	return (
		<Box style={style} {...props}>
			{props.children}
		</Box>
	);
};

export default CenteredBox;
