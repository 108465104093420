import { useCallback } from 'react';

import { StringMap, TOptions } from 'i18next';
import { UseTranslationOptions, useTranslation as useTransalationHook } from 'react-i18next';
import { useSelector } from 'redux/hooks';
import * as ViewSelectors from 'redux/selectors/view';

import { CommonKey, OnlineKey } from 'common/locales/utils/TranslationKeys';
import { getTranslation as _getTranslation } from 'common/modules/translations';
import { LocaleField } from 'common/types';

export function useTranslation<TInterpolationMap extends object = StringMap>(
	options?: UseTranslationOptions,
) {
	const { t, i18n, ready } = useTransalationHook<string[]>(['online', 'common'], options);
	const language = useSelector(ViewSelectors.language);
	const getTranslation = useCallback((l: LocaleField) => _getTranslation(l, language), [language]);

	const _t = useCallback(
		(
			key: OnlineKey | CommonKey | (CommonKey | OnlineKey)[],
			options?: TOptions<TInterpolationMap> | string,
			/* i18next-extract-disable-next-line */
		) => t<string>(key, options),
		[t],
	);
	return {
		t: _t,
		getTranslation,
		i18n,
		ready,
		language,
	};
}
