import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
	RiAlertLine,
	RiCheckboxCircleLine,
	RiErrorWarningLine,
	RiInformationLine,
} from 'react-icons/ri';

interface Props {
	variant: 'info' | 'warning' | 'danger' | 'success';
	color?: 'primary' | 'secondary' | 'error';
	label: string;
	iconMarginRight?: 'normal' | 'large';
}
const Note = (props: Props) => {
	const classes = useStyles(props);
	const theme = useTheme();

	const renderIcon = () => {
		switch (props.variant) {
			case 'info':
				return <RiInformationLine color={theme.palette.info.main} />;
			case 'warning':
				return <RiAlertLine color={theme.palette.warning.main} />;
			case 'danger':
				return <RiErrorWarningLine color={theme.palette.error.main} />;
			case 'success':
				return <RiCheckboxCircleLine color={theme.palette.success.main} />;
			default:
				return null;
		}
	};

	return (
		<Box display="flex" alignItems="flex-start">
			<Box className={classes.iconWrapper}>{renderIcon()}</Box>
			<Typography variant="body2" color={props.color} className={classes.noteLabel}>
				{props.label}
			</Typography>
		</Box>
	);
};

const getMarginThemeValue = (marginText: 'normal' | 'large') => {
	switch (marginText) {
		case 'large':
			return 2;
		case 'normal':
		default:
			return 1;
	}
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		iconWrapper: {
			marginRight: ({ iconMarginRight = 'normal' }: Props) =>
				theme.spacing(getMarginThemeValue(iconMarginRight)),
			fontSize: 20,
			height: 20,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		noteLabel: {
			fontWeight: 400,
		},
	}),
);

export default Note;
