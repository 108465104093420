import React from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

import CenteredBox from 'common/components/CenteredBox';

interface Props {
	className?: string;
	innerClassName?: string;
	background?: string;
	paddingBottom?: number;
	paddingTop?: number;
	max?: 'sm' | 'md' | 'lg';
}
const CenteredContent: React.FC<Props> = (props) => {
	const classes = useStyles(props);

	return (
		<Box
			className={classNames(classes.wrapper, props.className)}
			pb={props.paddingBottom}
			pt={props.paddingTop}
		>
			<CenteredBox className={props.innerClassName} max={props.max ?? 'lg'}>
				{props.children}
			</CenteredBox>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
			background: (props: Props) => props.background ?? 'transparent',
			[theme.breakpoints.up('sm')]: {
				paddingLeft: theme.spacing(7),
				paddingRight: theme.spacing(7),
			},
		},
	}),
);

export default CenteredContent;
