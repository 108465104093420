import React from 'react';

import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

import { CurrencyObject } from 'common/types';
import { getPricingString } from 'common/utils/common';
import { useTranslation } from 'services/localization/useTranslation';

interface Props {
	dialogAction: (accepted: boolean) => void;
	isOpen: boolean;
	keycardPrice?: number;
	currency?: CurrencyObject;
}

const NoCardDialog = (props: Props) => {
	const classes = useStyles();
	const { keycardPrice, currency } = props;
	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
	const { t } = useTranslation();

	const handleContinue = () => {
		props.dialogAction(true);
	};

	const handleCancel = () => {
		props.dialogAction(false);
	};

	const getKeycardPriceString = () => {
		return !!keycardPrice && !!currency ? `(+${getPricingString(keycardPrice, currency)})` : '';
	};

	const getButtonJustification = (): 'center' | 'flex-start' => {
		return isSmallDevice ? 'center' : 'flex-start';
	};

	return (
		<Dialog open={props.isOpen}>
			<DialogTitle>
				{t('shopperInfo.proceedWithOutKeycardTitle', 'Continue without activating KeyCard?')}
			</DialogTitle>
			<DialogContent>
				<Typography
					variant="body2"
					className={classnames(classes.botSpacing, classes.dialogContent)}
				>
					{t('shopperInfo.noKeycardInfo', {
						keyCardPrice: getKeycardPriceString(),
						defaultValue:
							'Without filling the card number, you are not able to go straight to the lifts when you arrive to the location. No worries. You can do one of the following:',
					})}
				</Typography>

				<Typography
					variant="body2"
					className={classnames(classes.botSpacing, classes.indent, classes.dialogContent)}
				>
					{'1) '}
					{t(
						'shopperInfo.noKeycardOpt_1',
						'Once you have your KeyCard with you, you can come back via the link in your order confirmation email.',
					)}
				</Typography>
				<Typography variant="body2" className={classnames(classes.indent, classes.dialogContent)}>
					{'2) '}
					{t(
						'shopperInfo.noKeycardOpt_2',
						'You can leave the card number empty and visit a ticket booth upon your arrival. There you can activate a KeyCard or purchase a new one.',
					)}
				</Typography>
			</DialogContent>
			<DialogActions className={classes.actions}>
				<Grid
					container
					alignContent={getButtonJustification()}
					justifyContent={getButtonJustification()}
					alignItems={getButtonJustification()}
				>
					<Grid item container xs={12} justifyContent="center">
						<Button
							variant="contained"
							onClick={handleContinue}
							color="primary"
							className={classnames(classes.wideButton, classes.botSpacing)}
						>
							{t(
								'shopperInfo.noKeycardContinue',
								'I want to continue without activating my KeyCard',
							)}
						</Button>
					</Grid>
					<Grid item container xs={12} justifyContent="center">
						<Button variant="text" onClick={handleCancel} className={classes.wideButton}>
							{t('common:actions.cancel', 'Cancel')}
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		actions: {
			padding: theme.spacing(3),
		},
		largerFont: {
			fontSize: 16,
			fontWeight: 300,
		},
		bold: {
			fontWeight: 600,
		},
		wideButton: {
			width: '95%',
			textAlign: 'center',
		},
		botSpacing: {
			marginBottom: theme.spacing(2),
		},
		indent: {
			marginLeft: theme.spacing(2),
		},
		dialogContent: {
			fontFamily: '"Nunito Sans", "CircularStd", "Helvetica", "Arial", sans-serif',
		},
	}),
);

export default NoCardDialog;
