import React from 'react';

import { min } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateAdyenPaymentMethods } from 'redux/actions/checkout';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';

import {
	AdditionalDetailsUpdate,
	ConfirmAction,
} from 'common/components/payment/PaymentMethodSelector';
import { pricingToAmountObject } from 'common/modules/atoms/pricing';
import { sendEventToParentPage } from 'common/modules/embed';
import { getElementOffset, isRunInIframe } from 'common/utils/browserUtils';
import { useAnalytics } from 'hooks/useAnalytics';
import useCheckoutForm from 'hooks/useCheckoutForm';

import { AdyenPaymentMethodError } from './components/AdyenPaymentMethodError';
import { ConfirmBookingBtn } from './components/ConfirmBookingBtn';
import { NoPaymentMethods } from './components/NoPaymentMethods';
import PaymentSelectorWrapper from './components/PaymentSelectorWrapper';

interface Props {
	handleConfirmAction: (action: ConfirmAction) => void;
	handleAdditionalDetailsUpdate: (data: AdditionalDetailsUpdate) => void;
}

const PaymentSection = (props: Props) => {
	const { logEcomEvent, logEvent } = useAnalytics();
	const adyenPaymentMethods = useSelector(CheckoutSelectors.adyenPaymentMethods);
	const shopHasAdyenPayments = useSelector(ShopSelectors.shopHasAdyenPayments);
	const shopPaymentMethods = useSelector(ShopSelectors.shopPaymentMethods);
	const dispatch = useDispatch();
	const paymentLoading = useSelector(CheckoutSelectors.paymentLoading);
	const paymentError = useSelector(CheckoutSelectors.paymentError);
	const checkoutPricing = useSelector(CheckoutSelectors.checkoutPricing);

	const {
		actions: { validate },
	} = useCheckoutForm();

	const scrollToError = (errors: string[]) => {
		const offsets = errors
			.map((key) => {
				const element = document.getElementById(key);
				return element ? getElementOffset(element).top : null;
			})
			.filter((offset): offset is number => offset !== null);

		const firstErrorOffset = min(offsets);

		if (firstErrorOffset != null) {
			const offset = firstErrorOffset - 160;
			if (isRunInIframe()) {
				sendEventToParentPage({
					origin: 'rentle',
					type: 'trigger-scroll',
					payload: {
						offset,
					},
				});
			}
			window.scrollTo({ top: offset, behavior: 'smooth' });
		}
		return;
	};

	const handleConfirmAction = (action: ConfirmAction) => {
		const validationResult = validate();
		logPaymentButtonClick(validationResult);
		if (!validationResult.valid) {
			scrollToError(validationResult.errors);
			return;
		}
		logEcomEvent('add_payment_info', {
			paymentMethod: action.type === 'MANUAL' ? 'MANUAL' : action.paymentMethodName,
		});
		props.handleConfirmAction(action);
	};

	const handleAdditionalDetailsUpdate = (data: AdditionalDetailsUpdate) => {
		props.handleAdditionalDetailsUpdate(data);
	};

	const logPaymentButtonClick = (validationResult: ReturnType<typeof validate>) => {
		const valid = validationResult.valid;
		const errors = !validationResult.valid ? validationResult.errors : null;
		logEvent('payment_button_click', {
			valid,
			invalid_fields: errors ?? [],
			invalid_field: errors?.[0] ?? undefined,
		});
	};

	const fetchAdyenPaymentMethods = () => {
		dispatch(updateAdyenPaymentMethods(pricingToAmountObject(checkoutPricing).charge));
	};

	if (checkoutPricing.total === 0 && !checkoutPricing.deposit) {
		return (
			<ConfirmBookingBtn
				loading={paymentLoading}
				onClick={() => handleConfirmAction({ type: 'MANUAL', paymentMethod: 'PAY_STORE' })}
				error={paymentError?.methodId === 'PAY_STORE' ? paymentError.error : undefined}
			/>
		);
	}

	if (adyenPaymentMethods?.error && shopHasAdyenPayments) {
		return (
			<AdyenPaymentMethodError
				error={adyenPaymentMethods?.error}
				onClick={fetchAdyenPaymentMethods}
			/>
		);
	}

	if (!shopPaymentMethods.length) {
		return <NoPaymentMethods />;
	}

	return (
		<PaymentSelectorWrapper
			handleConfirmAction={handleConfirmAction}
			handleAdditionalDetailsUpdate={handleAdditionalDetailsUpdate}
		/>
	);
};

export default PaymentSection;
