import React from 'react';

import { Grid, IconButton } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiArrowLeftLine, RiEqualizerLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as ViewActions from 'redux/actions/view';

import CenteredContent from 'components/CenteredContent';
import AuthIcon from 'components/auth/AuthIcon';

import CartButton from '../Cart/CartButton';
import NavLocation from './NavLocation';

interface Props {
	showCart: boolean;
	backLink?: string | (() => void);
	isMobile: boolean;
	hideBackLink?: boolean;
	showLogin?: boolean;
}

const NavEmbedContent = (props: Props) => {
	const { isMobile, backLink, showCart, hideBackLink = false, showLogin = false } = props;
	const classes = useStyles(props);

	return (
		<CenteredContent className={classes.outer} paddingTop={0} paddingBottom={0}>
			<Grid container alignItems="center" justifyContent="space-between">
				<Grid
					item
					xs="auto"
					sm={3}
					sx={{
						display: 'inline-block',
						...(isMobile && { flex: '0 0 auto' }),
					}}
				>
					{!hideBackLink ? (
						<MobileNavAction backLink={backLink} size={24} />
					) : (
						<NavLocation variant="text" isMobile={true} />
					)}
				</Grid>
				<Grid
					item
					xs
					sm={6}
					sx={{
						...(isMobile && { flex: 1 }),
					}}
				>
					<NavLocation variant="link" isMobile={true} />
				</Grid>
				<Grid
					item
					container
					xs="auto"
					sm={3}
					minWidth={52}
					alignItems="center"
					justifyContent="flex-end"
					spacing={isMobile ? 3 : 4}
					wrap="nowrap"
					sx={{
						...(isMobile && { flex: '0 0 auto' }),
					}}
				>
					{showLogin && (
						<Grid item>
							<AuthIcon />
						</Grid>
					)}
					{showCart && (
						<Grid item>
							<CartButton edge="end" size={24} />
						</Grid>
					)}
				</Grid>
			</Grid>
		</CenteredContent>
	);
};

const MobileNavAction = ({
	backLink,
	size,
}: {
	backLink?: string | (() => void);
	size: number;
}) => {
	const dispatch = useDispatch();
	if (backLink) {
		if (typeof backLink === 'function') {
			return (
				<IconButton edge="start" size="large" onClick={backLink}>
					<RiArrowLeftLine size={size} color="black" />
				</IconButton>
			);
		} else {
			return (
				<Link to={backLink}>
					<IconButton edge="start" size="large">
						<RiArrowLeftLine size={size} color="black" />
					</IconButton>
				</Link>
			);
		}
	}
	return (
		<IconButton
			onClick={() => dispatch(ViewActions.setFiltersOpen(true))}
			edge="start"
			size="large"
		>
			<RiEqualizerLine size={size} color="black" />
		</IconButton>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		outer: {
			background: 'white',
			display: 'flex',
			alignItems: 'center',
			minHeight: ({ isMobile }: Props) => (!isMobile ? 72 : 56),
			borderBottom: `1px solid ${theme.palette.border.paper}`,
		},
		wrapper: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		center: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			flex: 1,
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
		},
	}),
);

export default NavEmbedContent;
