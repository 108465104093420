import React from 'react';

import { useSelector } from 'react-redux';
import * as StockSelectors from 'redux/selectors/stock';

import { useTranslation } from 'services/localization';
import { Routes } from 'services/routing/constants';

import BreadcrumbNav from './BreadcrumbNav';

export interface Props {}

const CategoryNav = (props: Props) => {
	const { getTranslation, t } = useTranslation();
	const filteredCategory = useSelector(StockSelectors.filteredCategory);

	return (
		<BreadcrumbNav
			breadCrumbs={[
				{
					name: t('common:actions.home'),
					link: Routes.browse,
				},
				{
					name: !!filteredCategory
						? getTranslation(filteredCategory.name)
						: t('Browse.allProducts'),
					link: null,
				},
			]}
			fontSize="small"
		/>
	);
};

export default CategoryNav;
