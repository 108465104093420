import { useCallback, useMemo } from 'react';

import { isEmpty } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthProfile } from 'redux/actions/auth';
import {
	updateContactPerson,
	updateCustomCheckoutCheckbox,
	updateCustomCheckoutField,
	updateRecurringPaymentsAcceptance,
	updateTermsAcceptance,
} from 'redux/actions/checkout';
import { setCheckoutFormErrors, setPhoneObject } from 'redux/actions/view';
import * as AuthSelectors from 'redux/selectors/auth';
import * as CartSelectors from 'redux/selectors/cart';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ViewSelectors from 'redux/selectors/view';
import {
	getAuthProfileFromCheckoutForm,
	getContactPersonFromCheckoutFrom,
} from 'views/Shop/Location/Checkout/CheckoutContainer/Details/utils';

import useDebouncedForm from 'common/hooks/useDebouncedForm';
import { Address } from 'common/modules/atoms/addresses';
import { validateAddress, validateAddressField } from 'common/modules/atoms/addresses/utils';
import {
	CustomCheckboxValue,
	CustomContentError,
	CustomTextfieldValue,
} from 'common/modules/customization';
import { Languages, PhoneObject, TermsAcceptance } from 'common/types';
import { AuthOrganisationType } from 'common/types/external/auth';
import { notUndefined } from 'common/utils/common';
import { isValidEmail } from 'common/utils/validation';
import { useTranslation } from 'services/localization';

import useExternalAuth from './useExternalAuth';

interface ShopperState {
	firstName: string;
	lastName: string;
	phone: PhoneObject;
	email: string;
	marketing: boolean;
	language: Languages;
}

export interface CheckoutFormState extends ShopperState {
	termsAcceptance: boolean;
	customTextfield?: CustomTextfieldValue[];
	customCheckbox?: CustomCheckboxValue[];
	deliveryAddress: Partial<Address>;
	pickupAddress: Partial<Address>;
	useSameAddress: boolean;
	recurringPaymentsAcceptance: boolean;
	dateOfBirth: Moment | undefined;
	businessAccount:
		| undefined
		| {
				organisationType: AuthOrganisationType | null;
				organisationName: string;
		  };
}

export interface CheckoutFormErrors {
	firstName?: string | null;
	lastName?: string | null;
	email?: string | null;
	phone?: string | null;
	terms?: string | null;
	commentField?: string | null;
	customTextfield?: CustomContentError[] | null;
	customCheckbox?: CustomContentError[] | null;
	deliveryAddress?:
		| {
				[key in keyof Address]?: string | null;
		  }
		| null;
	pickupAddress?:
		| {
				[key in keyof Address]?: string | null;
		  }
		| null;
	recurringPayments?: string | null;
	dateOfBirth?: string | null;
	businessAccount?: {
		organisationType?: string | null;
		organisationName?: string | null;
	};
}

interface CheckoutFormFunctions {
	setFirstName: (value: string) => void;
	setLastName: (value: string) => void;
	setPhone: (value: PhoneObject) => void;
	setEmail: (value: string) => void;
	setMarketing: (value: boolean) => void;
	setTermsAcceptance: (value: boolean) => void;
	setDeliveryAddress: (field: Partial<Address>) => void;
	setDeliveryAddressPart: (field: keyof Address, value: string) => void;
	setPickupAddress: (address: Partial<Address>) => void;
	setPickupAddressPart: (field: keyof Address, value: string) => void;
	setCustomTextfield: (args: CustomTextfieldValue[]) => void;
	setCustomCheckbox: (args: CustomCheckboxValue[]) => void;
	setUseSameAddress: (value: boolean) => void;
	setRecurringPaymentsAcceptance: (value: boolean) => void;
	setDateOfBirth: (value: Moment | null) => void;
	setBusinessAccount: (value: boolean) => void;
	setOrganisationType: (value: AuthOrganisationType) => void;
	setOrganisationName: (value: string) => void;
}

type Validation = { valid: true; value: CheckoutFormState } | { valid: false; errors: string[] };

interface CheckoutForm {
	values: CheckoutFormState;
	errors: CheckoutFormErrors;
	actions: CheckoutFormFunctions & {
		validate: () => Validation;
	};
}

const useCheckoutForm = (): CheckoutForm => {
	const { t, language } = useTranslation();
	const dispatch = useDispatch();
	const { isNewDecathlonRegistration } = useExternalAuth();
	const contactPerson = useSelector(CheckoutSelectors.contactPerson);
	const termsAcceptance = useSelector(CheckoutSelectors.bookingTermsAccepted);
	const recurringPaymentsAcceptance = useSelector(CheckoutSelectors.recurringPaymentsAccepted);
	const phoneObject = useSelector(ViewSelectors.phoneObject);
	const deliveryAddress = useSelector(CartSelectors.deliveryAddress);
	const pickupAddress = useSelector(CartSelectors.pickupAddress);
	const formErrors = useSelector(ViewSelectors.checkoutFormErrors);
	const pickupChecked = useSelector(ViewSelectors.pickupChecked);
	const delivery = useSelector(CartSelectors.delivery);
	const hasPickupSlotSeleceted = !!delivery?.from?.timeslot;
	const hasSubscriptionProducts = useSelector(CartSelectors.hasSomeSubscriptionProducts);
	const authProfile = useSelector(AuthSelectors.authProfile);

	const customCheckout = useSelector(CheckoutSelectors.customCheckout);

	const noPickup = !!delivery?.from?.disabled;

	const customTextfield = customCheckout?.customContent?.sections
		?.flatMap((section, sectionIndex) => {
			return section?.content?.flatMap((content, contentIndex) => {
				return {
					value: content?.type === 'textfield' ? content.value : null,
					sectionIndex,
					contentIndex,
					id: content?.type === 'textfield' ? content.id : '',
				};
			});
		})
		.filter(notUndefined);

	const customCheckbox = customCheckout?.customContent?.sections
		?.flatMap((section, sectionIndex) => {
			return section?.content?.flatMap((content, contentIndex) => {
				return {
					value: content?.type === 'checkbox' ? content.value : null,
					sectionIndex,
					contentIndex,
					id: content?.type === 'checkbox' ? content.id : '',
				};
			});
		})
		.filter(notUndefined);

	const formState: CheckoutFormState = useMemo(
		() => ({
			firstName: contactPerson.firstName,
			lastName: contactPerson.lastName,
			phone: phoneObject,
			email: contactPerson.email,
			marketing: contactPerson.marketing,
			language,
			termsAcceptance,
			recurringPaymentsAcceptance,
			deliveryAddress,
			pickupAddress,
			useSameAddress: true,
			dateOfBirth: authProfile.data?.dateOfBirth,
			businessAccount: authProfile.data?.businessAccount ?? undefined,
			customTextfield,
			customCheckbox,
		}),
		[
			contactPerson.firstName,
			contactPerson.lastName,
			contactPerson.email,
			contactPerson.marketing,
			phoneObject,
			language,
			termsAcceptance,
			recurringPaymentsAcceptance,
			deliveryAddress,
			pickupAddress,
			authProfile.data?.dateOfBirth,
			authProfile.data?.businessAccount,
			customTextfield,
			customCheckbox,
		],
	);

	const { state, editState } = useDebouncedForm<CheckoutFormState>({
		memoizedInitialState: useMemo(() => formState, [formState]),
		memoizedOnDebounce: useCallback(
			(changes, fullState) => {
				const fullFormState: CheckoutFormState = {
					...fullState,
					...changes,
				};

				if (changes.phone != null) {
					dispatch(setPhoneObject(changes.phone));
				}
				if (changes.termsAcceptance != null) {
					const acceptance: TermsAcceptance = changes.termsAcceptance
						? {
								accepted: true,
								timestamp: moment().toISOString(),
						  }
						: { accepted: false };
					dispatch(updateTermsAcceptance(acceptance));
				}
				if (changes.recurringPaymentsAcceptance != null) {
					const acceptance: boolean = changes.recurringPaymentsAcceptance;
					dispatch(updateRecurringPaymentsAcceptance(acceptance));
				}

				if (changes.customTextfield != null) {
					changes.customTextfield.forEach((customTextfield) => {
						dispatch(updateCustomCheckoutField(customTextfield));
					});
				}
				if (changes.customCheckbox != null) {
					changes.customCheckbox.forEach((customCheckbox) => {
						dispatch(updateCustomCheckoutCheckbox(customCheckbox));
					});
				}
				dispatch(updateContactPerson(getContactPersonFromCheckoutFrom(fullFormState)));
				if (isNewDecathlonRegistration) {
					const authProfile = getAuthProfileFromCheckoutForm(fullFormState);
					dispatch(setAuthProfile({ loading: false, data: authProfile, error: null }));
				}
			},
			[dispatch, isNewDecathlonRegistration],
		),
	});

	const setFormError = useCallback(
		(error: Partial<CheckoutFormErrors>) => {
			dispatch(
				setCheckoutFormErrors({
					...formErrors,
					...error,
				}),
			);
		},
		[dispatch, formErrors],
	);

	const validateFirstName = useCallback(
		(firstName: CheckoutFormState['firstName']): string | null => {
			if (!firstName) {
				return t('common:mandatory');
			}
			return null;
		},
		[t],
	);

	const setFirstName = useCallback(
		(firstName: string) => {
			editState({ firstName });
			if (formErrors.firstName) {
				setFormError({ firstName: validateFirstName(firstName) });
			}
		},
		[editState, formErrors.firstName, setFormError, validateFirstName],
	);

	const validateLastName = useCallback(
		(lastname: CheckoutFormState['lastName']): string | null => {
			if (!lastname) {
				return t('common:mandatory');
			}
			return null;
		},
		[t],
	);

	const setLastName = useCallback(
		(lastName: string) => {
			editState({ lastName });
			if (formErrors.lastName) {
				setFormError({ lastName: validateLastName(lastName) });
			}
		},
		[editState, formErrors.lastName, setFormError, validateLastName],
	);

	const validatePhoneObject = useCallback(
		(phoneObject: PhoneObject): string | null => {
			if (!phoneObject.numberWithoutCode) {
				return t('common:mandatory');
			}
			return null;
		},
		[t],
	);

	const validatePhone = useCallback(
		(phone: string, phoneObject: PhoneObject): string | null => {
			const phoneObjectError = validatePhoneObject(phoneObject);
			if (phoneObjectError) return phoneObjectError;
			if (!phone) {
				return t('common:mandatory');
			}
			return null;
		},
		[t, validatePhoneObject],
	);

	const setPhone = useCallback(
		(phone: PhoneObject) => {
			editState({ phone });
			if (formErrors.phone) {
				setFormError({ phone: validatePhoneObject(phone) });
			}
		},
		[editState, formErrors.phone, setFormError, validatePhoneObject],
	);

	const validateEmail = useCallback(
		(email: CheckoutFormState['email']): string | null => {
			if (!email) {
				return t('common:mandatory');
			}
			if (!isValidEmail(email)) {
				return t('common:validation.isNotValidEmail');
			}
			return null;
		},
		[t],
	);

	const setEmail = useCallback(
		(email: string) => {
			editState({ email });
			if (formErrors.email) {
				setFormError({ email: validateEmail(email) });
			}
		},
		[editState, formErrors.email, setFormError, validateEmail],
	);

	const setMarketing = useCallback(
		(marketing: boolean) => {
			editState({ marketing });
		},
		[editState],
	);

	const validateTermsAcceptance = useCallback(
		(terms: CheckoutFormState['termsAcceptance']): string | null => {
			if (!terms) {
				return t('common:mandatory');
			}
			return null;
		},
		[t],
	);

	const setTermsAcceptance = useCallback(
		(checked: boolean) => {
			editState({ termsAcceptance: checked });
			if (formErrors.terms) {
				setFormError({ terms: validateTermsAcceptance(checked) });
			}
		},
		[editState, formErrors.terms, setFormError, validateTermsAcceptance],
	);

	const validateRecurringPaymentsAcceptance = useCallback(
		(acceptance: CheckoutFormState['recurringPaymentsAcceptance']): string | null => {
			if (!acceptance && hasSubscriptionProducts) {
				return t('common:mandatory');
			}
			return null;
		},
		[hasSubscriptionProducts, t],
	);

	const setRecurringPaymentsAcceptance = useCallback(
		(checked: boolean) => {
			editState({ recurringPaymentsAcceptance: checked });
			if (formErrors.recurringPayments) {
				setFormError({
					recurringPayments: validateRecurringPaymentsAcceptance(checked),
				});
			}
		},
		[editState, formErrors.recurringPayments, setFormError, validateRecurringPaymentsAcceptance],
	);

	const validateAddressPart = useCallback(
		(field: keyof Address, value: string) => {
			return validateAddressField(field, t)(value);
		},
		[t],
	);

	const setDeliveryAddressPart = useCallback(
		(field: keyof Address, value: string) => {
			editState({
				deliveryAddress: {
					...state.deliveryAddress,
					[field]: value,
				},
				...(!!state.useSameAddress && {
					pickupAddress: {
						...state.pickupAddress,
						[field]: value,
					},
				}),
			});

			if (!!formErrors.deliveryAddress?.[field]) {
				setFormError({
					deliveryAddress: {
						...formErrors.deliveryAddress,
						[field]: validateAddressPart(field, value),
					},
					...(!!state.useSameAddress && {
						pickupAddress: {
							...state.pickupAddress,
							[field]: value,
						},
					}),
				});
			}
		},
		[
			editState,
			state.deliveryAddress,
			state.useSameAddress,
			state.pickupAddress,
			formErrors.deliveryAddress,
			setFormError,
			validateAddressPart,
		],
	);

	const setPickupAddressPart = useCallback(
		(field: keyof Address, value: string) => {
			editState({
				pickupAddress: {
					...state.pickupAddress,
					[field]: value,
				},
			});

			if (!!formErrors.pickupAddress?.[field]) {
				setFormError({
					pickupAddress: {
						...formErrors.pickupAddress,
						[field]: validateAddressPart(field, value),
					},
				});
			}
		},
		[editState, state.pickupAddress, formErrors.pickupAddress, setFormError, validateAddressPart],
	);

	const validateDeliveryAddress = useCallback(
		(
			address: CheckoutFormState['deliveryAddress'],
		): CheckoutFormErrors['deliveryAddress'] | null => {
			if (!delivery) return null;
			return validateAddress(address, t);
		},
		[t, delivery],
	);

	const setDeliveryAddress = useCallback(
		(address: Partial<Address>) => {
			editState({
				deliveryAddress: address,
			});
			setFormError({
				deliveryAddress: validateDeliveryAddress(address),
			});
		},
		[editState, setFormError, validateDeliveryAddress],
	);

	const validatePickupAddress = useCallback(
		(address: CheckoutFormState['pickupAddress']): CheckoutFormErrors['pickupAddress'] | null => {
			if (!pickupChecked || !delivery || noPickup || !hasPickupSlotSeleceted) return null;
			return validateAddress(address, t);
		},
		[pickupChecked, delivery, noPickup, hasPickupSlotSeleceted, t],
	);

	const setPickupAddress = useCallback(
		(address: Partial<Address>) => {
			editState({
				pickupAddress: address,
			});
			setFormError({
				pickupAddress: validatePickupAddress(address),
			});
		},
		[editState, setFormError, validatePickupAddress],
	);

	const validateCustomTextfields = useCallback(
		(fields: CheckoutFormState['customTextfield']): { error: string; id: string }[] | null => {
			const errors: CustomContentError[] = [];

			fields?.forEach(({ value, sectionIndex, contentIndex, id }) => {
				const section = customCheckout?.customContent?.sections?.[sectionIndex];
				const content = section?.content?.[contentIndex];
				const isRequired = content?.type === 'textfield' && content?.required;
				const isExistingError = !!errors.find((error) => error.id === id);
				if (isRequired && !value) {
					const id = content?.id;
					errors.push({
						id,
						error: t('common:mandatory'),
					});
				} else if (!!value?.length && isExistingError) {
					errors.splice(
						errors.findIndex((error) => error.id === id),
						1,
					);
				}

				return errors;
			});
			return !!errors.length ? errors : null;
		},
		[customCheckout, t],
	);

	const setCustomTextfield = useCallback(
		(
			args: {
				value: string | null;
				sectionIndex: number;
				contentIndex: number;
				id: string;
			}[],
		) => {
			const updatedFields = args.reduce(
				(acc, field) => {
					const { value, sectionIndex, contentIndex, id } = field;
					const fieldObjectToUpdate = acc.find((o) => o.id === id);
					if (fieldObjectToUpdate) {
						fieldObjectToUpdate.value = value;
					} else {
						acc.push({
							id,
							value: null,
							sectionIndex,
							contentIndex,
						});
					}
					return acc;
				},
				[...(state.customTextfield ?? [])],
			);

			editState({
				customTextfield: updatedFields,
			});
			if (!!formErrors.customTextfield) {
				setFormError({
					customTextfield: validateCustomTextfields(updatedFields),
				});
			}
		},
		[
			state.customTextfield,
			editState,
			formErrors.customTextfield,
			setFormError,
			validateCustomTextfields,
		],
	);

	const validateCustomCheckbox = useCallback(
		(fields: CheckoutFormState['customCheckbox']): { error: string; id: string }[] | null => {
			const errors: CustomContentError[] = [];

			fields?.forEach(({ value, sectionIndex, contentIndex, id }) => {
				const section = customCheckout?.customContent?.sections?.[sectionIndex];
				const content = section?.content?.[contentIndex];
				const isRequired = content?.type === 'checkbox' && content?.required;
				const isExistingError = !!errors.find((error) => error.id === id);
				if (isRequired && !value) {
					const id = content?.id;
					errors.push({
						id,
						error: t('common:mandatory'),
					});
				} else if (!!value && isExistingError) {
					errors.splice(
						errors.findIndex((error) => error.id === id),
						1,
					);
				}

				return errors;
			});
			return !!errors.length ? errors : null;
		},
		[customCheckout, t],
	);

	const setCustomCheckbox = useCallback(
		(
			args: {
				value: boolean | null;
				sectionIndex: number;
				contentIndex: number;
				id: string;
			}[],
		) => {
			const updatedFields = args.reduce(
				(acc, field) => {
					const { value, sectionIndex, contentIndex, id } = field;
					const fieldObjectToUpdate = acc.find((o) => o.id === id);
					if (fieldObjectToUpdate) {
						fieldObjectToUpdate.value = value;
					} else {
						acc.push({
							id,
							value: null,
							sectionIndex,
							contentIndex,
						});
					}
					return acc;
				},
				[...(state.customCheckbox ?? [])],
			);

			editState({
				customCheckbox: updatedFields,
			});
			if (!!formErrors.customCheckbox) {
				setFormError({
					customCheckbox: validateCustomCheckbox(updatedFields),
				});
			}
		},
		[
			state.customCheckbox,
			editState,
			formErrors.customCheckbox,
			setFormError,
			validateCustomCheckbox,
		],
	);

	const setUseSameAddress = useCallback(
		(useSameAddress: boolean) => {
			editState({ useSameAddress });
		},
		[editState],
	);

	const validateDateOfBirth = useCallback(
		(dateOfBirth: Moment | null): string | null => {
			if (!!dateOfBirth && !moment(dateOfBirth).isValid()) {
				return t('common:validation.isNotValidValue', 'Not a valid value');
			}
			return null;
		},
		[t],
	);

	const setDateOfBirth = useCallback(
		(dateOfBirth: Moment | null) => {
			editState({ dateOfBirth: dateOfBirth ?? undefined });
			if (formErrors.dateOfBirth) {
				setFormError({ dateOfBirth: validateDateOfBirth(dateOfBirth) });
			}
		},
		[editState, formErrors.dateOfBirth, setFormError, validateDateOfBirth],
	);

	type BusinessAccountErrors = Partial<
		{ [k in keyof Required<NonNullable<CheckoutFormState['businessAccount']>>]: string }
	>;

	const validateBusinessAccount = useCallback(
		(businessAccount: CheckoutFormState['businessAccount']): BusinessAccountErrors | null => {
			if (!businessAccount) return null;
			const errors: BusinessAccountErrors = {};
			if (!businessAccount.organisationType) {
				errors.organisationType = t('common:mandatory');
			}
			if (!businessAccount.organisationName) {
				errors.organisationName = t('common:mandatory');
			}
			return isEmpty(errors) ? null : errors;
		},
		[t],
	);

	const setBusinessAccount = useCallback(
		(isBusinessAccount: boolean) => {
			const businessAccount: CheckoutFormState['businessAccount'] = isBusinessAccount
				? { organisationType: null, organisationName: '' }
				: undefined;
			editState({
				businessAccount,
			});
			if (formErrors.businessAccount) {
				setFormError({ businessAccount: validateBusinessAccount(businessAccount) ?? undefined });
			}
		},
		[editState, formErrors.businessAccount, setFormError, validateBusinessAccount],
	);

	const setOrganisationType = useCallback(
		(organisationType: AuthOrganisationType) => {
			const businessAccount: CheckoutFormState['businessAccount'] = {
				organisationType,
				organisationName: state.businessAccount?.organisationName ?? '',
			};
			editState({ businessAccount });
			if (formErrors.businessAccount) {
				setFormError({ businessAccount: validateBusinessAccount(businessAccount) ?? undefined });
			}
		},
		[
			editState,
			formErrors.businessAccount,
			setFormError,
			state.businessAccount?.organisationName,
			validateBusinessAccount,
		],
	);

	const setOrganisationName = useCallback(
		(organisationName: string) => {
			const businessAccount: CheckoutFormState['businessAccount'] = {
				organisationType: state.businessAccount?.organisationType ?? null,
				organisationName,
			};
			editState({
				businessAccount,
			});
			if (formErrors.businessAccount) {
				setFormError({ businessAccount: validateBusinessAccount(businessAccount) ?? undefined });
			}
		},
		[
			editState,
			formErrors.businessAccount,
			setFormError,
			state.businessAccount?.organisationType,
			validateBusinessAccount,
		],
	);

	const validate = useCallback((): Validation => {
		const errors: CheckoutFormErrors = {};
		const {
			firstName,
			lastName,
			email,
			termsAcceptance,
			customTextfield,
			deliveryAddress,
			pickupAddress,
			recurringPaymentsAcceptance,
			dateOfBirth,
			businessAccount,
		} = formState;
		const firstNameError = validateFirstName(firstName);
		if (firstNameError) {
			errors.firstName = firstNameError;
		}

		const lastNameError = validateLastName(lastName);
		if (lastNameError) {
			errors.lastName = lastNameError;
		}

		const phoneError = validatePhone(contactPerson.phone, phoneObject);
		if (phoneError) {
			errors.phone = phoneError;
		}

		const emailError = validateEmail(email);
		if (emailError) {
			errors.email = emailError;
		}

		const termsError = validateTermsAcceptance(termsAcceptance);
		if (termsError) {
			errors.terms = termsError;
		}

		const recurringPaymentsError = validateRecurringPaymentsAcceptance(recurringPaymentsAcceptance);
		if (recurringPaymentsError) {
			errors.recurringPayments = recurringPaymentsError;
		}

		const customTextfieldError = validateCustomTextfields(customTextfield);
		if (!!customTextfieldError) {
			errors.customTextfield = customTextfieldError;
		}

		const customCheckboxError = validateCustomCheckbox(customCheckbox);
		if (!!customCheckboxError) {
			errors.customCheckbox = customCheckboxError;
		}

		const deliveryAddressError = validateDeliveryAddress(deliveryAddress);
		if (deliveryAddressError) {
			errors.deliveryAddress = deliveryAddressError;
		}

		const pickupAddressError = validatePickupAddress(pickupAddress);
		if (pickupAddressError) {
			errors.pickupAddress = pickupAddressError;
		}
		const dateOfBirthError = validateDateOfBirth(dateOfBirth ?? null);
		if (dateOfBirthError) {
			errors.dateOfBirth = dateOfBirthError;
		}

		const businessAccountErrors = validateBusinessAccount(businessAccount);
		if (businessAccountErrors) {
			errors.businessAccount = {};
			Object.entries(businessAccountErrors).forEach(([key, error]) => {
				errors.businessAccount![key] = error;
			});
		}

		setFormError(errors);

		if (isEmpty(errors)) {
			return {
				valid: true,
				value: state,
			};
		} else {
			return {
				valid: false,
				errors: Object.keys(errors),
			};
		}
	}, [
		formState,
		validateFirstName,
		validateLastName,
		validatePhone,
		contactPerson.phone,
		phoneObject,
		validateEmail,
		validateTermsAcceptance,
		validateRecurringPaymentsAcceptance,
		validateCustomTextfields,
		validateCustomCheckbox,
		customCheckbox,
		validateDeliveryAddress,
		validatePickupAddress,
		validateDateOfBirth,
		validateBusinessAccount,
		setFormError,
		state,
	]);

	return {
		values: state,
		actions: {
			setFirstName,
			setLastName,
			setPhone,
			setEmail,
			setMarketing,
			setTermsAcceptance,
			setRecurringPaymentsAcceptance,
			validate,
			setDeliveryAddress,
			setDeliveryAddressPart,
			setPickupAddress,
			setPickupAddressPart,
			setCustomTextfield,
			setCustomCheckbox,
			setUseSameAddress,
			setDateOfBirth,
			setBusinessAccount,
			setOrganisationName,
			setOrganisationType,
		},
		errors: formErrors,
	};
};

export default useCheckoutForm;
