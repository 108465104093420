import * as React from 'react';

import { Stack, Typography, TypographyProps } from '@mui/material';

import { CurrencyObject } from 'common/types';
import { getPricingString, switchUnreachable } from 'common/utils/common';

interface Props {
	label: string;
	value: number;
	formatValue?: (value: number, currency: CurrencyObject | undefined) => string;
	currency: CurrencyObject | undefined;
	variant?: 'bold' | 'default' | 'caption';
	className?: string;
}

export const PricingRow = (props: Props) => {
	const { label, value, currency, variant = 'default', formatValue, className } = props;

	const typographyProps: Partial<TypographyProps> = (() => {
		switch (variant) {
			case 'bold':
				return {
					fontWeight: 500,
					variant: 'body1',
					color: 'textPrimary',
				};
			case 'caption':
				return {
					fontWeight: 400,
					variant: 'caption',
					color: 'textSecondary',
				};
			case 'default':
				return {
					fontWeight: 400,
					variant: 'body2',
					color: 'textPrimary',
				};
			default:
				return switchUnreachable(variant);
		}
	})();
	return (
		<Stack direction="row" spacing={2} justifyContent="space-between">
			<Typography {...typographyProps} className={className}>
				{label}
			</Typography>
			<Typography {...typographyProps} className={className} align="right">
				{!!formatValue ? formatValue(value, currency) : getPricingString(value, currency)}
			</Typography>
		</Stack>
	);
};
