import React from 'react';

import { Box, BoxProps } from '@mui/system';

import Banner from 'common/components/states/Banner';
import { UserDetail } from 'common/types';
import { getUserDetailTranslation } from 'common/utils/translations';
import { useTranslation } from 'services/localization';

type Props = BoxProps & { details: UserDetail[] };
const UserInfoBanner = (props: Props) => {
	const { details, ...boxProps } = props;
	const { t } = useTranslation();

	if (details.length === 0) return null;

	return (
		<Box {...boxProps}>
			<Banner
				variant="info"
				description={t('Checkout.userDetails.description', {
					details: details.map((d) => getUserDetailTranslation(d.name, t)).join(', '),
					defaultValue:
						'The following details will be asked from each participant after the booking has been confirmed: {{details}}',
				})}
			/>
		</Box>
	);
};

export default UserInfoBanner;
