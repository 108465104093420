import React, { useMemo } from 'react';

import { Box, FormControl, RadioGroup, Stack } from '@mui/material';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import {
	isProductForRent,
	isProductForSale,
	isProductForSubscription,
} from 'common/modules/products/utils';
import { doesProductSubscriptionSupportDelivery } from 'common/modules/subscriptions';
import { ProductApi, PurchaseType } from 'common/types';
import {
	getFromRentalPriceString,
	getFromSalesPriceString,
	getFromSubscriptionPriceString,
} from 'common/utils/pricing';
import { useTranslation } from 'services/localization';

import PurchaseTypeLabel from './PurchaseTypeLabel';

export interface Props {
	product: ProductApi;
	value: PurchaseType | null;
	onChange: (value: PurchaseType) => void;
}

const PurchaseTypeSelect = (props: Props) => {
	const { t } = useTranslation();
	const { product } = props;

	const pricingTables = useSelector(ShopSelectors.shopPricingTables);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const timezone = useSelector(ShopSelectors.shopTimezone);
	const currency = useSelector(ShopSelectors.shopCurrency);
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);

	const fromSalesPrice = getFromSalesPriceString({
		product: props.product,
		currency,
		t,
		kind: 'from',
	});

	const fromRentalPrice = getFromRentalPriceString({
		product,
		pricingTables,
		openingHours,
		currency,
		channel: 'ONLINE',
		kind: 'from',
		timezone,
		t,
	});

	const fromSubscriptionPrice = getFromSubscriptionPriceString({
		product,
		currency,
		t,
		kind: 'subscribe-from',
	});

	const isSubscriptionEnabled = useMemo(() => {
		return isProductForSubscription(product)
			? !!selectedDeliveryOption
				? doesProductSubscriptionSupportDelivery(product, selectedDeliveryOption)
				: true
			: false;
	}, [product, selectedDeliveryOption]);

	return (
		<Box my={4}>
			<FormControl sx={{ width: '100%' }}>
				<RadioGroup
					value={props.value}
					onChange={(e) => props.onChange(e.target.value as PurchaseType)}
				>
					<Stack spacing={0.5} sx={{ width: '100%' }}>
						{isProductForRent(product) && (
							<PurchaseTypeLabel
								value={'rental'}
								label={t('common:actions.book', 'Book')}
								priceString={fromRentalPrice}
								disabled={!isProductForRent(product)}
							/>
						)}
						{isProductForSubscription(product) && (
							<PurchaseTypeLabel
								value={'subscription'}
								label={t('common:actions.subscribe', 'Subscribe')}
								priceString={fromSubscriptionPrice}
								disabled={!isSubscriptionEnabled}
							/>
						)}
						{isProductForSale(product) && (
							<PurchaseTypeLabel
								value={'sales'}
								label={t('common:actions.buy', 'Buy')}
								priceString={fromSalesPrice}
								disabled={!isProductForSale(product)}
							/>
						)}
					</Stack>
				</RadioGroup>
			</FormControl>
		</Box>
	);
};

export default PurchaseTypeSelect;
