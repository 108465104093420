import React from 'react';

import { Box, Dialog, DialogActions, DialogContent, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TFunction } from 'i18next';

import Spinner from 'common/components/Spinner';
import {
	AdditionalAdyenPaymentAction,
	AdditionalPaymentAction,
	AdditionalStripePaymentAction,
} from 'common/types';

import { AdditionalDetailsUpdate } from './PaymentMethodSelector';
import AdyenAdditionalAction from './elements/AdyenAdditionalAction';
import StripeAdditionalAction from './elements/StripeAdditionalAction';

interface Props {
	paymentInProgress: boolean;
	paymentLoading: boolean;
	additionalPaymentAction: AdditionalPaymentAction | undefined;
	t: TFunction;
	paymentError?: string;
	isVerification?: boolean;
	onAdditionalPaymentDetailsChange: (args: AdditionalDetailsUpdate) => void;
}

const PaymentProcessDialog = (props: Props) => {
	const classes = useStyles();
	const {
		additionalPaymentAction,
		paymentInProgress,
		paymentLoading,
		t,
		paymentError,
		isVerification,
		onAdditionalPaymentDetailsChange,
	} = props;

	const isAdyenAdditionalAction = additionalPaymentAction?.provider === 'ADYEN';
	const isStripeAdditionalAction = additionalPaymentAction?.provider === 'STRIPE';

	return (
		<div>
			<Dialog
				disableEscapeKeyDown
				maxWidth="sm"
				aria-labelledby="payment-dialog-title"
				open={!!additionalPaymentAction || paymentInProgress}
				classes={{
					paper: classes.paymentProcessDialog,
				}}
			>
				<DialogContent
					classes={{
						root: classes.dialogContent,
					}}
				>
					{paymentLoading && (
						<Box className={classes.spinnerWrapper}>
							<Spinner
								text={
									!!isVerification
										? t('common:payment.verifyingCard', 'Verifying card')
										: t('common:payment.processingPayment', 'Processing payment')
								}
							/>
						</Box>
					)}
					{isAdyenAdditionalAction && (
						<AdyenAdditionalAction
							additionalPaymentAction={additionalPaymentAction as AdditionalAdyenPaymentAction}
							onAdditionalPaymentDetailsChange={onAdditionalPaymentDetailsChange}
						/>
					)}
					{isStripeAdditionalAction && (
						<StripeAdditionalAction
							additionalPaymentAction={additionalPaymentAction as AdditionalStripePaymentAction}
							onAdditionalPaymentDetailsChange={onAdditionalPaymentDetailsChange}
						/>
					)}
				</DialogContent>
				<DialogActions
					classes={{
						root: classes.dialogAction,
					}}
				>
					<Typography
						variant="caption"
						sx={{ lineHeight: 1.2 }}
						color="textSecondary"
						align="center"
					>
						{!!isVerification
							? t(
									'common:payment.verifyingInfo',
									`Please don't close the window to ensure that the verification is processed successfully.`,
							  )
							: t(
									'common:payment.processingInfo',
									`Please don't close the window to ensure that the payment is processed successfully.`,
							  )}
					</Typography>
				</DialogActions>
			</Dialog>
			{paymentError ? (
				<Typography color="error" align="center">
					{t('common:payment.paymentError', 'Payment error')} ({props.paymentError})
				</Typography>
			) : null}
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paymentProcessDialog: {
			width: `calc(100% - ${theme.spacing(4 * 2)})`,
			height: `calc(100% - ${theme.spacing(4 * 2)})`,
			maxHeight: 800,
			position: 'relative',
			margin: theme.spacing(4),
			[theme.breakpoints.down('md')]: {
				width: `calc(100% - ${theme.spacing(2 * 2)})`,
				height: `calc(100% - ${theme.spacing(2 * 2)})`,
				margin: theme.spacing(2),
			},
		},
		dialogContent: {
			position: 'relative',
			display: 'flex',
			padding: theme.spacing(3),
			[theme.breakpoints.down('md')]: {
				padding: theme.spacing(2, 1),
			},
		},
		dialogAction: {
			justifyContent: 'center',
			borderTop: `1px solid ${theme.palette.divider}`,
		},
		spinnerWrapper: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			top: 0,
			right: 0,
			bottom: 0,
		},
	}),
);

export default PaymentProcessDialog;
