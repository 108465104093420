import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box } from '@mui/material';
import { useSelector } from 'redux/hooks';
import * as StockSelectors from 'redux/selectors/stock';

import Divider from 'common/components/Divider';
import { getProductsInPackage } from 'common/modules/products/utils';
import {
	hasMultipleVariants,
	isVariantAvailableForPurchaseType,
} from 'common/modules/products/variants';
import { ByVariant, LoadingData, ProductApi, PurchaseType } from 'common/types';
import VariantSelect from 'components/VariantSelect';
import { PartialProductSelection, ProductSelection } from 'services/types';
import * as CartUtils from 'utils/cart';

import { getVariantQuantity } from '../utils';

const validateSelection = (
	selection: PartialProductSelection,
	packageProducts: ProductApi[],
): selection is ProductSelection => {
	if (!selection.variantId) return false;

	const variantPackageProducts = packageProducts.filter((p) => hasMultipleVariants(p));

	const hasUnselectedVariant = variantPackageProducts.some((product) => {
		const childSelection = selection.children?.find((child) => child.productId === product.id);

		return !childSelection?.variantId;
	});

	if (hasUnselectedVariant) {
		return false;
	}

	return true;
};
export interface VariantSelectorProps {
	product: ProductApi;
	value: ProductSelection | null;
	onChange: (value: ProductSelection | null) => void;
	showError?: boolean;
	variantQuantities: LoadingData<ByVariant<number | null>>;
	purchaseType: PurchaseType;
}

const VariantSelector = React.memo((props: VariantSelectorProps) => {
	const { product, value, onChange, showError, variantQuantities, purchaseType } = props;

	const productsById = useSelector(StockSelectors.productsById);
	const packageProducts = useMemo(() => getProductsInPackage(product, productsById), [
		product,
		productsById,
	]);

	const hasBaseVariants = hasMultipleVariants(product);
	const allVariants = product.variants.options;

	const disabledVariantIds = useMemo(() => {
		return allVariants
			.filter((variant) => {
				const isAvailableForPurchase = isVariantAvailableForPurchaseType(variant, purchaseType);
				const quantity = getVariantQuantity(variant.id, variantQuantities);

				return !isAvailableForPurchase || quantity === 0;
			})
			.map((v) => v.id);
	}, [allVariants, purchaseType, variantQuantities]);

	const [state, setState] = useState<PartialProductSelection>(
		value ?? CartUtils.getInitialProductSelection(product, packageProducts),
	);

	const effectiveState = validateSelection(state, packageProducts) ? state : null;

	useEffect(() => {
		onChange(effectiveState);
	}, [effectiveState, onChange]);

	const variantPackageProducts =
		packageProducts?.filter((product): product is ProductApi => hasMultipleVariants(product)) ?? [];

	const hasChildVariants = variantPackageProducts.length > 0;

	const handleChange = useCallback(
		(productId: string, variantId: string | null) => {
			if (productId === product.id) {
				setState((prev) => ({
					...prev,
					variantId,
				}));
			} else {
				setState((prev) => {
					const children = prev.children ?? [];
					if (!!children.find((child) => child.productId === productId)) {
						return {
							...prev,
							children: children.map((child) => {
								if (child.productId === productId) {
									return {
										productId,
										variantId,
									};
								}
								return child;
							}),
						};
					} else {
						return {
							...prev,
							children: children.concat({
								productId,
								variantId,
							}),
						};
					}
				});
			}
		},
		[product.id],
	);

	return (
		<Box>
			<Box mb={3} />
			{hasBaseVariants && (
				<VariantSelect
					product={product}
					value={state.variantId}
					onChange={handleChange}
					showError={showError && !state.variantId}
					hideLabel
					disabledVariants={disabledVariantIds}
				/>
			)}

			{hasChildVariants
				? variantPackageProducts.map((product) => {
						const selectedVariantId = state.children?.find(
							(child) => child.productId === product.id,
						)?.variantId;

						return (
							<Box key={product.id}>
								<Box pt={2} pb={3}>
									<Divider />
								</Box>
								<VariantSelect
									key={product.id}
									product={product}
									value={selectedVariantId ?? null}
									onChange={handleChange}
									showError={showError && !selectedVariantId}
									disabledVariants={disabledVariantIds}
								/>
							</Box>
						);
				  })
				: null}
		</Box>
	);
});

export default VariantSelector;
