import React from 'react';

import { Box, Hidden } from '@mui/material';
import { removeReservation } from 'redux/actions/cart';
import { setOrder } from 'redux/actions/confirm';
import { useDispatch, useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';

import CheckoutStep from 'common/components/customizer/CheckoutStep';
import { OrderObject } from 'common/types';
import { useAnalytics } from 'hooks/useAnalytics';
import useExternalAuth from 'hooks/useExternalAuth';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';

import Discount from '../components/CheckoutCart/Discount';
import DeliveryStep from '../components/DeliveryStep';
import UserInfoBanner from '../components/UserInfoBanner';
import Details from './Details';
import ExternalLogin from './Login/ExternalLogin';
import Payment from './Payment';
import Terms from './Terms';
import TermsAcceptanceCheckbox from './Terms/TermsAcceptanceCheckbox';

const CheckoutContainer = () => {
	const { t } = useTranslation();
	const { logEcomEvent } = useAnalytics();
	const { pushRoute, Routes } = useRoutes();
	const dispatch = useDispatch();
	const shopHasCancellationPolicy = useSelector(ShopSelectors.shopHasCancellationPolicy);
	const transactionId = useSelector(CheckoutSelectors.transactionId);
	const orderHasDelivery = useSelector(CartSelectors.hasCartDelivery);
	const requiredUserDetails = useSelector(CartSelectors.requiredUserDetails);
	const checkoutPricing = useSelector(CheckoutSelectors.checkoutPricing);
	const { isAuthInUse, isLoggedIn, updateRegisteredAuthProfile } = useExternalAuth();
	const pendingLogin = isAuthInUse && !isLoggedIn;
	const shopHasActiveDiscountCodes = useSelector(ShopSelectors.shopHasActiveDiscountCodes);
	const hasOnlySalesProducts = useSelector(CartSelectors.hasOnlySalesProducts);
	const shouldShowCancellationPolicy = shopHasCancellationPolicy && !hasOnlySalesProducts;

	const handleOrderConfirmed = (order: OrderObject) => {
		dispatch(setOrder(order));
		dispatch(removeReservation());
		logEcomEvent('purchase', {
			transactionId,
		});
		updateRegisteredAuthProfile();
		pushRoute(Routes.confirm, { query: { orderId: order.rentalInfo.id } });
	};

	return (
		<Box>
			<UserInfoBanner details={requiredUserDetails} mb={4} />
			{isAuthInUse && (
				<CheckoutStep
					collapsed={!pendingLogin}
					completed={!pendingLogin}
					header={t('common:actions.signIn', 'Sign in')}
					hidden={!pendingLogin}
				>
					<ExternalLogin />
				</CheckoutStep>
			)}
			{orderHasDelivery && (
				<CheckoutStep collapsed={pendingLogin} header={t('delivery.delivery')}>
					<DeliveryStep autoFocus={orderHasDelivery} />
				</CheckoutStep>
			)}
			<CheckoutStep
				collapsed={pendingLogin}
				header={t('Checkout.contactDetails', 'Contact details')}
			>
				<>
					<Details autoFocus={!orderHasDelivery} />
					{!shouldShowCancellationPolicy && <TermsAcceptanceWrapper />}
				</>
			</CheckoutStep>
			{shouldShowCancellationPolicy && (
				<CheckoutStep collapsed={pendingLogin} header={t('Checkout.terms', 'Terms')}>
					<Terms />
					<TermsAcceptanceWrapper />
				</CheckoutStep>
			)}
			<PaymentStep
				collapsed={pendingLogin}
				showHeader={!!(checkoutPricing.total || checkoutPricing.deposit)}
			>
				{shopHasActiveDiscountCodes && (
					<Hidden mdUp>
						<Box mb={4}>
							<Discount />
						</Box>
					</Hidden>
				)}
				<Payment onOrderConfirmed={handleOrderConfirmed} />
			</PaymentStep>
		</Box>
	);
};

const PaymentStep: React.FC<
	Partial<React.ComponentProps<typeof CheckoutStep>> & { showHeader: boolean }
> = ({ showHeader, children, ...otherProps }) => {
	const { t } = useTranslation();
	if (showHeader) {
		return (
			<CheckoutStep {...otherProps} header={t('common:payment.payment')} fullWidthContent>
				{children}
			</CheckoutStep>
		);
	}
	return <>{children}</>;
};

const TermsAcceptanceWrapper = () => (
	<Box mt={3}>
		<TermsAcceptanceCheckbox />
	</Box>
);

export default CheckoutContainer;
