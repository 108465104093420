import BusinessUser from './BusinessUser';
import DateOfBirth from './DateOfBirth';
import OrganizationName from './OrganizationName';
import OrganizationType from './OrganizationType';

const DecathlonRegistration = {
	DateOfBirth,
	BusinessUser,
	OrganizationType,
	OrganizationName,
};

export default DecathlonRegistration;
