import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Button,
	Dialog,
	Grid,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { ReactComponent as QuestionMarkIcon } from 'common/assets/icons/icon-question-mark-outline.svg';

/**
 * InfoButton - an info button which shows a customisable popup when clicked.
 *
 * @param title: The title of the dialog
 * @param content: The content of the dialog
 * @param buttonText: The text of the confirmation button
 */

interface Props {
	title?: string;
	content: string | JSX.Element | JSX.Element[];
	buttonText?: string;
	useIcon?: boolean;
	infoText?: string;
	size?: number;
	closeIcon?: boolean;
	iconsButtonSize?: number;
	renderCustomButton?: (props: { onClick: () => void }) => JSX.Element;
	tabIndex?: number;
}

const InfoButton = (props: Props) => {
	const classes = useStyles(props);
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
	const {
		title,
		content,
		buttonText,
		useIcon,
		infoText,
		size,
		closeIcon,
		renderCustomButton,
	} = props;

	const renderButton = (icon: boolean, text?: string) => {
		if (renderCustomButton) {
			return renderCustomButton({ onClick: () => setDialogOpen(true) });
		}

		return !icon && !!text ? (
			<Button
				aria-label="info"
				onClick={() => setDialogOpen(true)}
				color="primary"
				variant="text"
				classes={{
					root: classes.noTransform,
				}}
				tabIndex={props.tabIndex}
			>
				{text}
			</Button>
		) : (
			<IconButton
				aria-label="info"
				className={classes.iconButton}
				onClick={() => setDialogOpen(true)}
				size="small"
				style={size ? { width: size, height: size } : undefined}
				tabIndex={props.tabIndex}
			>
				<QuestionMarkIcon className={classes.icon} />
			</IconButton>
		);
	};

	return (
		<>
			{renderButton(!!useIcon, infoText)}
			<Dialog
				fullWidth={true}
				fullScreen={isSmallDevice}
				maxWidth={'xs'}
				onClose={() => setDialogOpen(false)}
				aria-labelledby="info-dialog-title"
				open={dialogOpen}
			>
				{closeIcon && (
					<Box mt={2} mr={2} className={classes.closeIcon}>
						<IconButton size="small" onClick={() => setDialogOpen(false)}>
							<CloseIcon fontSize="large" />
						</IconButton>
					</Box>
				)}
				<Grid className={classes.container} container>
					{title && (
						<Typography id="info-dialog-title" className={classes.title} align="left" variant="h6">
							{title}
						</Typography>
					)}
					<Box mb={3} width="100%">
						{typeof content === 'string' ? (
							<Typography variant="body1">{content}</Typography>
						) : (
							content
						)}
					</Box>
					{buttonText && (
						<Grid container justifyContent="center">
							<Button
								variant="contained"
								color="secondary"
								className={classes.closeButton}
								onClick={() => setDialogOpen(false)}
							>
								{buttonText}
							</Button>
						</Grid>
					)}
				</Grid>
			</Dialog>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: theme.spacing(4),
		},
		title: {
			marginBottom: theme.spacing(3),
			fontWeight: 400,
		},
		closeButton: {
			width: 190,
			borderRadius: 50,
			marginTop: theme.spacing(2),
		},
		icon: {
			width: '100%',
			height: '100%',
		},
		iconButton: {
			color: theme.palette.text.primary,
			width: ({ iconsButtonSize }: Props) => theme.spacing(iconsButtonSize ?? 4),
			height: ({ iconsButtonSize }: Props) => theme.spacing(iconsButtonSize ?? 4),
			verticalAlign: 'sub',
		},
		noTransform: {
			textTransform: 'none',
		},
		closeIcon: {
			textAlign: 'right',
		},
	}),
);

export default InfoButton;
