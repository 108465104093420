import { useEffect } from 'react';

import { useStripe } from '@stripe/react-stripe-js';

import { AdditionalStripePaymentAction } from 'common/types';

import { AdditionalDetailsUpdate } from '../PaymentMethodSelector';

interface Props {
	additionalPaymentAction: AdditionalStripePaymentAction;
	onAdditionalPaymentDetailsChange: (args: AdditionalDetailsUpdate) => void;
}

const StripeAdditionalAction = (props: Props) => {
	const stripe = useStripe();
	const { additionalPaymentAction, onAdditionalPaymentDetailsChange } = props;

	useEffect(() => {
		const handleAdditionalStripeAction = async (secret: string) => {
			const result = await stripe?.handleCardAction(secret);
			onAdditionalPaymentDetailsChange({ provider: 'STRIPE', data: result });
		};

		if (additionalPaymentAction && additionalPaymentAction.clientSecret) {
			handleAdditionalStripeAction(additionalPaymentAction.clientSecret);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [additionalPaymentAction]);

	return null;
};

export default StripeAdditionalAction;
