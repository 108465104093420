import { createSelector } from '@reduxjs/toolkit';
import { uniq, uniqBy } from 'lodash';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';
import { ReduxState } from 'redux/types';

import { isPackageProduct } from 'common/modules/products/utils';
import { Category, ProductApi } from 'common/types';
import { hashByUniqueField } from 'common/utils/arrays';
import {
	isKeycardProduct,
	isLiftTicketCategory,
	isLiftTicketProduct,
	isNormalLiftTicketProduct,
} from 'common/utils/liftTicketUtils';
import {
	filterBySelectedDeliveryOption,
	getLocationStockProducts,
} from 'common/utils/productVisibilityFilters';
import { t } from 'services/localization';
import {
	filterTicketProductsWithNoVisibleSegments,
	getVisibleTicketSegments,
} from 'utils/liftTicket';
import { sortByOrderIndex } from 'utils/sorting';

const OTHER_CATEGORY_ID = 'others';

export const stockProducts = (state: ReduxState) => state.stock.products;
export const stockProductsLoading = (state: ReduxState) => state.stock.products.loading;
export const stockProductsData = (state: ReduxState) => stockProducts(state).data ?? [];

export const products = createSelector(stockProductsData, (stockProducts) => {
	return stockProducts.map((p) => {
		const product: ProductApi = {
			...p,
			categoryIds: p.categoryIds?.length ? p.categoryIds : [OTHER_CATEGORY_ID],
		};
		return product;
	});
});

export const onlineProductsInLocation = createSelector(
	products,
	ShopSelectors.activeLocation,
	ViewSelectors.selectedDeliveryOption,
	(products, location, selectedDeliveryOption) => {
		return getLocationStockProducts(products, location?.id ?? '', 'ONLINE').filter(
			filterBySelectedDeliveryOption(selectedDeliveryOption),
		);
	},
);

export const onlineProductsInLocationById = createSelector(
	onlineProductsInLocation,
	(onlineProducts) => {
		return hashByUniqueField(onlineProducts, 'id');
	},
);

export const productsById = createSelector(products, (products) => {
	return hashByUniqueField(products, 'id');
});

export const stockCategories = (state: ReduxState) => state.stock.categories;
export const stockCategoriesData = (state: ReduxState) => stockCategories(state).data ?? [];

export const categories = createSelector(
	stockCategories,
	ShopSelectors.shopId,
	ViewSelectors.language,
	(stockCategories, shopId, lang) => {
		const categoriesLoaded = !stockCategories.loading;
		const categories = stockCategories.data ?? [];
		const maxOrderIndex = Math.max(...categories.map((c) => c.orderIndex ?? 0));
		const uncategorizedCategory: Category = {
			id: OTHER_CATEGORY_ID,
			shopId,
			name: {
				def: t('common:categories.others', { lng: lang }), // Need to override language to fix issue with delay in rendering language change
			},
			imageUrl: null,
			terms: null,
			orderIndex: maxOrderIndex + 1,
		};
		// Return categories with the "Others" category only if categories have been loaded
		return categoriesLoaded ? [...categories, uncategorizedCategory] : [];
	},
);

export const categoriesById = createSelector(categories, (categories) => {
	return hashByUniqueField(categories, 'id');
});

export const categoryIds = createSelector(categoriesById, (c) => Object.keys(c));

export const liftTicketCategory = createSelector(categories, (categories) =>
	categories.find(isLiftTicketCategory),
);

export const visibleProducts = createSelector(onlineProductsInLocation, (products) =>
	products.filter((p) => !p.additionalProduct),
);

export const visibleCategories = createSelector(
	visibleProducts,
	categoriesById,
	(products, categoriesById) => {
		const categoryIds = uniq(
			products.flatMap((p) => (p.categoryIds ? p.categoryIds : OTHER_CATEGORY_ID)),
		).filter((id): id is string => !!id);
		return categoryIds.map((id) => categoriesById[id]).filter((c): c is Category => !!c);
	},
);

export const visibleCategoryIds = createSelector(visibleCategories, (visibleCategories) =>
	visibleCategories.map((c) => c.id),
);

export const visibleCategoriesSorted = createSelector(visibleCategories, (categories) =>
	sortByOrderIndex(categories),
);

export const visibleProductsById = createSelector(visibleProducts, (products) =>
	hashByUniqueField(products, 'id'),
);

export const filteredCategory = createSelector(
	ViewSelectors.filtersCategory,
	categoriesById,
	(categoryId, categoriesById) => {
		return categoryId ? categoriesById[categoryId] ?? null : null;
	},
);

export const selectedCategories = createSelector(
	filteredCategory,
	visibleCategoriesSorted,
	(filteredCategory, sortedCategories) => {
		return !!filteredCategory ? [filteredCategory] : sortedCategories;
	},
);

// Filter all lift ticket products out of browse page and use 1 virtual lift ticket product
// to represent for lift ticket category
export const productsFiltered = createSelector(
	visibleProducts,
	ViewSelectors.filters,
	(products, filters) => {
		return products.filter((product) => {
			const { category, productType } = filters;
			const hasCorrectCategory = !category || product.categoryIds?.includes(category);
			const hasCorrectProductType =
				!productType ||
				(productType === 'package'
					? isPackageProduct(product)
					: productType === 'single'
					? !isPackageProduct(product)
					: true);
			return !isNormalLiftTicketProduct(product) && hasCorrectCategory && hasCorrectProductType;
		});
	},
);

// Sort products based on orderIndex taking into account the original ordering of the categories
export const sortedProducts = createSelector(
	productsFiltered,
	selectedCategories,
	(products, selectedCategories) => {
		// Take sorted categories, sort products within a category, flat
		return uniqBy(
			selectedCategories
				.map((c) => c.id)
				.map((id) => products.filter((p) => p.categoryIds?.includes(id)))
				.map(sortByOrderIndex)
				.flat(),
			'id',
		);
	},
);

export const allSkidataProducts = createSelector(
	visibleProducts,
	liftTicketCategory,
	(products, liftTicketCategory) =>
		products.filter((product) => !!liftTicketCategory && isLiftTicketProduct(product)),
);

export const liftTicketProductsForPackages = createSelector(
	products,
	liftTicketCategory,
	(products, liftTicketCategory) =>
		products.filter((product) => !!liftTicketCategory && isLiftTicketProduct(product)),
);

//All possible segments is different from what segments actually exits in products (= there might be segments with no assigned products)
const allSegmentsFromLiftTickets = createSelector(allSkidataProducts, (liftTicketProducts) =>
	uniq(liftTicketProducts.flatMap((p) => p.segmentPricings?.flatMap((s) => s.segments))),
);

export const allLiftTicketSegments = createSelector(
	liftTicketCategory,
	(liftTicketCategory) => liftTicketCategory?.segmentMapping ?? [],
);

export const visibleLiftTicketSegments = createSelector(
	liftTicketCategory,
	allSegmentsFromLiftTickets,
	(liftTicketCategory, segmentIds) =>
		getVisibleTicketSegments(liftTicketCategory?.segmentMapping ?? []).filter((segment) =>
			segmentIds.includes(segment.externalSegmentId),
		),
);

export const liftTicketProducts = createSelector(
	allSkidataProducts,
	visibleLiftTicketSegments,
	(products, visibleLiftTicketSegments) =>
		products.filter((product) =>
			filterTicketProductsWithNoVisibleSegments(visibleLiftTicketSegments)(product),
		),
);

export const normalLiftTicketProducts = createSelector(liftTicketProducts, (p) =>
	p.filter(isNormalLiftTicketProduct),
);

export const liftTicketPackageProducts = createSelector(allSkidataProducts, (products) =>
	products.filter((p) => p.set),
);

export const keycardProduct = createSelector(products, (products) =>
	products.find(isKeycardProduct),
);

export const productsFilteredCount = createSelector(
	productsFiltered,
	filteredCategory,
	liftTicketCategory,
	(items, filteredCategory, liftTicketCategory) => {
		// If there is lift ticket category, and lift ticket category or "All" selected, and it isn't filtered out
		const hasLiftTicketCategoryInSelection =
			liftTicketCategory && (!filteredCategory || isLiftTicketCategory(filteredCategory));
		if (hasLiftTicketCategoryInSelection) {
			return items.length + 1;
		}
		// Other cases:
		// - no lift ticket category
		// - there's lift ticket category but selecting another category
		// -> show the real items length
		return items.length;
	},
);
