import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
	title: string;
	description?: string;
	cta?: JSX.Element;
}
const EmptyState = (props: Props) => {
	const classes = useStyles();
	const { title, description, cta } = props;

	return (
		<Box className={classes.wrapper}>
			<Typography variant="h6" gutterBottom>
				{title}
			</Typography>
			<Typography variant="body1">{description}</Typography>
			<Box mt={2} />
			{cta}
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			minHeight: 200,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			padding: theme.spacing(2),
			textAlign: 'center',
		},
	}),
);

export default EmptyState;
