import React, { useEffect, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { isEqual } from 'lodash';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

import TimeChip from 'common/components/TimeChip';
import { chunks } from 'common/utils/arrays';

export type TimeChipSelectorItem = {
	value: string;
	label: string;
	disabled?: boolean;
};

export interface Props {
	value: string | null;
	onChange: (value: string) => void;
	options: TimeChipSelectorItem[];
	pageSize?: number;
	disablePagination?: boolean;
	chipsMaxWidth?: number;
	align?: 'left' | 'center' | 'right';
	margin?: 'default' | 'dense';
}
const TimeChipSelector = (props: Props) => {
	const {
		value,
		onChange,
		options,
		align = 'center',
		margin = 'default',
		disablePagination = false,
		chipsMaxWidth,
	} = props;

	const [currentPage, setCurrentPage] = useState<number>(0);

	const pageSize = !disablePagination ? props.pageSize ?? 8 : Infinity;
	const pageCount = Math.ceil(options.length / pageSize);
	const lastPage = Math.max(pageCount - 1, 0);
	const isFirstPage = currentPage === 0;
	const isLastPage = currentPage === lastPage;
	const hasMultiplePages = pageCount > 1;

	useEffect(() => {
		if (currentPage > lastPage) {
			setCurrentPage(lastPage);
		}
	}, [lastPage, currentPage]);

	const renderItem = (item: TimeChipSelectorItem) => {
		const selected = isEqual(value, item.value);

		return (
			<TimeChip
				key={`${item.label}_${item.value}`}
				disabled={item.disabled}
				selected={selected}
				label={item.label}
				onClick={() => onChange(item.value)}
			/>
		);
	};

	const renderItems = () => {
		const itemsPerPage = chunks(options, pageSize);
		const pageItems = itemsPerPage[currentPage] ?? [];

		return pageItems.map((item) => renderItem(item));
	};

	const xAlign = align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center';
	const yMargin = margin === 'default' ? 3 : 1;
	const xMargin = align === 'center' ? 'auto' : 0;

	return (
		<Box display="flex" flexDirection="row" alignItems={xAlign} justifyContent="space-between">
			{hasMultiplePages && (
				<IconButton
					disabled={isFirstPage}
					onClick={() => setCurrentPage((prev) => prev - 1)}
					size="large"
					sx={{
						opacity: isFirstPage ? 0 : 1,
					}}
				>
					<RiArrowLeftSLine size={32} />
				</IconButton>
			)}

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: xAlign,
					flexWrap: 'wrap',
					flex: 1,
					margin: (theme) => theme.spacing(yMargin, xMargin),
					maxWidth: chipsMaxWidth,
				}}
			>
				{renderItems()}
			</Box>
			{hasMultiplePages && (
				<IconButton
					disabled={isLastPage}
					sx={{
						opacity: isLastPage ? 0 : 1,
					}}
					onClick={() => setCurrentPage((prev) => prev + 1)}
					size="large"
				>
					<RiArrowRightSLine size={32} />
				</IconButton>
			)}
		</Box>
	);
};

export default TimeChipSelector;
