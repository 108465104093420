import { countries } from 'countries-list';

import { getTranslation } from 'common/modules/translations';
import { Category, Languages, ProductApi, ShopPublicInfo } from 'common/types';
import { notUndefined } from 'common/utils/common';
import { getProductImageSrcs } from 'common/utils/productUtils';
import { getShopLocationById } from 'common/utils/shopUtils';

type LangCountryCode = `${Languages}_${Uppercase<string>}`;

const getFbLocaleLangCodes = (languages: Languages[]): LangCountryCode[] => {
	const languageCountryList = Object.entries(countries).reduce((tot, [country, countryData]) => {
		const languages = countryData.languages;
		languages.forEach((lang) => {
			tot[lang] = [...(tot[lang] ?? []), country];
		});
		return tot;
	}, {} as { [lang: string]: string[] });
	// Override some specific languages to support only generic country codes, otherwise these would have multiple (5+) FB meta tag rows
	languageCountryList.en = ['US'];
	languageCountryList.es = ['ES', 'LA'];
	languageCountryList.fr = ['FR'];
	languageCountryList.ar = ['AR'];
	languageCountryList.ru = ['RU'];
	languageCountryList.pt = ['PT'];
	languageCountryList.de = ['DE'];
	languageCountryList.nl = ['NL'];
	languageCountryList.it = ['IT'];
	return languages
		.flatMap((lang) => {
			const langCountries = languageCountryList[lang];
			if (!langCountries) return undefined;
			return langCountries.map(
				(country) =>
					`${lang.toLocaleLowerCase()}_${country.toLocaleUpperCase()}` as LangCountryCode,
			);
		})
		.filter(notUndefined);
};

export const getOnlineStoreNoShopFoundMetaTags = (args: { canonicalUrl: string | undefined }) => {
	const { canonicalUrl } = args;
	const title = 'Start your rental business with Rentle';
	const description =
		'Create an online store for your rental business with a few clicks. Sell and rent anything, anywhere.';
	const url =
		'https://www.rentle.io/hubfs/System%20%28do%20not%20remove%29/featured-topo-front.jpg#keepProtocol'; // same image url as for rentle.io
	const alt = 'Rentle logo';
	const allMetaTags = [
		`<title>${title}</title>`,
		`<meta name="description" content="${description}" />`,
		`<meta property="twitter:card" content="summary" />`,
		`<meta property="og:title" content="${title}" />`,
		`<meta property="twitter:title" content="${title}" />`,
		`<meta property="og:description" content="${description}" />`,
		`<meta property="twitter:description" content="${description}" />`,
		`<meta property="og:image" content="${url}" />`,
		`<meta property="og:image:alt" content="${alt}" />`,
		`<meta property="twitter:image" content="${url}" />`,
		`<meta property="twitter:image:alt" content="${alt}" />`,
		canonicalUrl ? `<link rel="canonical" href="${canonicalUrl}" />` : undefined,
		canonicalUrl ? `<meta property="og:url" content="${canonicalUrl}" />` : undefined,
	].filter(notUndefined);
	return allMetaTags.map((tag) => tag.replace('/>', 'data-react-helmet="true" />'));
};

export const getOnlineStoreShopMetaTags = (
	shop: ShopPublicInfo,
	options: {
		locationId: string | undefined;
		defaultLanguageUrl: string;
		language: Languages | null;
		fbLocaleString: string | null;
		canonicalUrl: string | undefined;
	},
) => {
	const { locationId, defaultLanguageUrl, language, fbLocaleString, canonicalUrl } = options;
	const activeLocation = locationId ? getShopLocationById(locationId, shop) : undefined;
	const allLanguages = shop.languages;
	const locationName = activeLocation?.name ?? shop.name;
	const shopLogo = shop.logoImgUrl;
	const metaTagProperties: MetaTagProperties = {
		siteName: locationName,
		title: locationName,
		description: activeLocation?.description
			? getMetaDescription(getTranslation(activeLocation.description, language ?? 'def'))
			: undefined,
		defaultLanguageUrl,
		images: !!shopLogo ? [{ url: shopLogo, alt: shop.name }] : [],
		twitterCardType: 'summary',
		allLanguages,
		fbLocaleString,
		canonicalUrl,
	};
	return getMetaTagsFromProperties(metaTagProperties);
};

export const getOnlineStoreProductMetaTags = (
	product: ProductApi,
	options: {
		defaultLanguageUrl: string;
		shopName: string;
		language: Languages | null;
		shopLanguages: Languages[];
		fbLocaleString: string | null;
		canonicalUrl: string | undefined;
	},
) => {
	const {
		shopName,
		defaultLanguageUrl,
		shopLanguages,
		language,
		fbLocaleString,
		canonicalUrl,
	} = options;
	const productImages = getProductImageSrcs(product);
	const metaTagProperties: MetaTagProperties = {
		siteName: shopName,
		title: getTranslation(product.name, language ?? 'def'),
		description: product.description
			? getMetaDescription(getTranslation(product.description, language ?? 'def'))
			: undefined,
		defaultLanguageUrl,
		images: !!productImages?.length
			? productImages.map((img) => ({
					url: img,
					alt: getTranslation(product.name, language ?? 'def'),
			  }))
			: [],
		twitterCardType: !!productImages?.length ? 'summary_large_image' : 'summary',
		allLanguages: shopLanguages,
		fbLocaleString,
		canonicalUrl,
	};
	return getMetaTagsFromProperties(metaTagProperties);
};

export const getOnlineStoreCategoryMetaTags = (
	category: Category,
	options: {
		defaultLanguageUrl: string;
		shopName: string;
		language: Languages | null;
		shopLanguages: Languages[];
		fbLocaleString: string | null;
		canonicalUrl: string | undefined;
	},
) => {
	const {
		shopName,
		defaultLanguageUrl,
		shopLanguages,
		language,
		fbLocaleString,
		canonicalUrl,
	} = options;
	const metaTagProperties: MetaTagProperties = {
		siteName: shopName,
		title: getTranslation(category.name, language ?? 'def'),
		description: category.description
			? getMetaDescription(getTranslation(category.description, language ?? 'def'))
			: undefined,
		defaultLanguageUrl,
		images: category.imageUrl
			? [
					{
						url: category.imageUrl,
						alt: getTranslation(category.name, language ?? 'def'),
					},
			  ]
			: [],
		twitterCardType: category.imageUrl ? 'summary_large_image' : 'summary',
		allLanguages: shopLanguages,
		fbLocaleString,
		canonicalUrl,
	};
	return getMetaTagsFromProperties(metaTagProperties);
};

const getMetaDescription = (sentence: string) => {
	const MAX_LENGTH = 155;
	return sentence.length > MAX_LENGTH ? `${sentence.substring(0, MAX_LENGTH)}...` : sentence;
};

interface MetaTagProperties {
	siteName: string;
	title: string;
	description?: string;
	defaultLanguageUrl: string;
	canonicalUrl: string | undefined;
	images: {
		url: string;
		alt?: string;
	}[];
	twitterCardType?: 'summary' | 'summary_large_image';
	fbLocaleString: string | null;
	allLanguages: Languages[];
}

const getMetaTagsFromProperties = (properties: MetaTagProperties) => {
	const {
		siteName,
		title,
		description = '',
		defaultLanguageUrl,
		images: _images,
		twitterCardType = 'summary',
		allLanguages,
		fbLocaleString,
		canonicalUrl,
	} = properties;
	const images = _images.length ? _images : [{ url: '', alt: '' }];
	const languageMetaTags = getLanguageMetaTags(allLanguages, defaultLanguageUrl);
	const fbLocaleCodes = getFbLocaleLangCodes(allLanguages);
	const fbMainLocaleTag = `<meta property="og:locale" content="${fbLocaleString ?? ''}" />`;
	const fbAlternateLocaleMetaTags = fbLocaleCodes.map((localeCode) => {
		return `<meta property="og:locale:alternate" content="${localeCode}" />`;
	});
	const imageMetaTags = images.flatMap(({ url, alt = '' }) => {
		return [
			`<meta property="og:image" content="${url}" />`,
			`<meta property="og:image:alt" content="${alt}" />`,
			`<meta property="twitter:image" content="${url}" />`,
			`<meta property="twitter:image:alt" content="${alt}" />`,
		];
	});
	const canonicalUrlTag = !!canonicalUrl
		? `<link rel="canonical" href="${canonicalUrl}" />`
		: undefined;
	const allMetaTags = [
		canonicalUrlTag,
		// Language tags should only be added if no canonical tag is used
		...(!canonicalUrlTag ? languageMetaTags : []),
		`<meta property="og:type" content="website" />`,
		`<meta property="og:url" content="${defaultLanguageUrl}" />`,
		`<meta property="og:site_name" content="${siteName}" />`,
		`<meta property="twitter:card" content="${twitterCardType}" />`,
		`<title>${title}</title>`,
		`<meta property="og:title" content="${title}" />`,
		`<meta property="twitter:title" content="${title}" />`,
		`<meta name="description" content="${description}" />`,
		`<meta property="og:description" content="${description}" />`,
		`<meta property="twitter:description" content="${description}" />`,
		...imageMetaTags,
		fbMainLocaleTag,
		...fbAlternateLocaleMetaTags,
	].filter(notUndefined);
	const metaTagsWithReactHelmetDataAttribute = allMetaTags.map((tag) =>
		tag.replace('/>', 'data-react-helmet="true" />'),
	);
	return metaTagsWithReactHelmetDataAttribute;
};

const getLanguageMetaTags = (_languages: Languages[], defaultLanguageUrl: string) => {
	const languages: (Languages | 'def')[] = [..._languages, 'def'];
	const queryStringSeparator = defaultLanguageUrl.includes('?') ? '&' : '?';
	const languageMetaTags = languages.map((lang) => {
		const hreflang = lang === 'def' ? 'x-default' : lang;
		const langUrl =
			lang === 'def'
				? defaultLanguageUrl
				: `${defaultLanguageUrl}${queryStringSeparator}lang=${lang}`;
		return `<link rel="alternate" hreflang="${hreflang}" href="${langUrl}" />`;
	});
	return languageMetaTags;
};
