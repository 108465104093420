import React from 'react';

import { Grid, useMediaQuery, useTheme } from '@mui/material';
import Sticky from 'hocs/Sticky';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import FeaturedImage from 'common/components/customizer/FeaturedImage';
import { isRunInIframe } from 'common/utils/browserUtils';
import CenteredContent from 'components/CenteredContent';
import GridItem from 'components/GridItem';
import DeliveryBar from 'components/NavBar/DeliveryBar';
import { useTranslation } from 'services/localization';
import { Routes } from 'services/routing/constants';
import { useRoutes } from 'services/routing/useRoutes';
import { getCategoryImage } from 'utils/products';

const CategoriesPage = () => {
	const theme = useTheme();
	const { getTranslation } = useTranslation();
	const { getPath } = useRoutes();
	const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
	const visibleCategories = useSelector(StockSelectors.visibleCategoriesSorted);
	const categoryGridColumnsDesktop = useSelector(ShopSelectors.categoryGridColumnsDesktop);
	const featuredImage = useSelector(ShopSelectors.onlineLayout)?.featuredImage;
	const language = useSelector(ViewSelectors.language);
	const isEmbedded = isRunInIframe();
	const isDeliveryBarVisible = useSelector(ShopSelectors.isDeliveryBarVisible);

	return (
		<>
			{isDeliveryBarVisible && (
				<>
					{!isEmbedded ? (
						<Sticky>
							{({ isScrolledDown }) => <DeliveryBar isScrolledDown={isScrolledDown} />}
						</Sticky>
					) : (
						<DeliveryBar />
					)}
				</>
			)}
			<CenteredContent paddingBottom={isMobile ? 3 : 4} paddingTop={isMobile ? 3 : 4}>
				{!!featuredImage?.src && (
					<FeaturedImage
						featuredImage={featuredImage.src}
						alignment={featuredImage?.alignment ?? 'center'}
						heading={featuredImage?.heading}
						description={featuredImage?.description}
						button={featuredImage?.button ?? null}
						language={language}
					/>
				)}
				<Grid container spacing={2}>
					{visibleCategories.map((category) => (
						<Grid key={category.id} item xs={6} sm={categoryGridColumnsDesktop}>
							<GridItem
								title={getTranslation(category.name)}
								imgUrl={getCategoryImage(category)}
								linkUrl={getPath(Routes.browse, { query: { category: category.id } })}
								imageResize="cover"
							/>
						</Grid>
					))}
				</Grid>
			</CenteredContent>
		</>
	);
};

export default CategoriesPage;
