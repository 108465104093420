import React, { useMemo, useState } from 'react';

import { Box, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';

import BookingCalendar from 'common/components/calendars/BookingCalendar';
import { formatTime } from 'common/modules/atoms/times';
import { getOpeningTimesForDate } from 'common/modules/openingHours';
import { ISOString } from 'common/types';
import { momentToDateInShopTimezone } from 'common/utils/dateUtils';
import ErrorText from 'components/ErrorText';
import useShopFormat from 'hooks/useShopFormat';
import { Trans, useTranslation } from 'services/localization';
import { getIsClosedCalendarDates } from 'utils/availability';

interface Props {
	value: ISOString | null;
	onChange: (value: ISOString | null) => void;
	disabled?: boolean;
	error?: string | null;
	minDate?: ISOString;
	maxDate?: ISOString;
	validWeekdays?: string[];
}

const StartDateSelector = React.memo((props: Props) => {
	const { value, onChange, error, disabled, maxDate, validWeekdays } = props;
	const classes = useStyles();
	const { localFormat, shopDateFormat } = useShopFormat();
	const { t, language } = useTranslation();

	const today = moment().startOf('day');
	const minDate =
		!props.minDate || today.isAfter(moment(props.minDate)) ? today.toISOString() : props.minDate;

	const shopStartDay = useSelector(ShopSelectors.shopStartDay);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);

	const startDateFromCart = useSelector(CartSelectors.startDate);

	const [calendarDate, setCalendarDate] = useState<ISOString>(value ?? moment().toISOString());

	const disabledDates = useMemo(
		() => getIsClosedCalendarDates(calendarDate, openingHours, validWeekdays),
		[calendarDate, openingHours, validWeekdays],
	);

	const handleDateSelect = (date: ISOString) => {
		onChange?.(moment(date).startOf('date').add(3, 'hours').toISOString());
	};

	const renderOpeningTimes = () => {
		if (!value) return null;
		const openingTimes = getOpeningTimesForDate({
			openingHours,
			date: moment(value).format('YYYY-MM-DD'),
		});

		if (openingTimes.isClosed) return null;
		const openTime = formatTime(openingTimes.openTime, shopDateFormat);
		const closeTime = formatTime(openingTimes.closeTime, shopDateFormat);

		return (
			<Typography variant="caption" color="textSecondary">
				{t('common:times.openingHoursFromTo', {
					openTime,
					closeTime,
					defaultValue: 'Opening hours {{openTime}} - {{closeTime}}',
				})}
			</Typography>
		);
	};

	return (
		<Box>
			<Box mt={3} pt={3} className={classes.divider}>
				<Typography variant="body1" className={classes.bookingDetails}>
					{t('common:booking.bookingDetails')}
				</Typography>
			</Box>
			{error && <ErrorText>{error}</ErrorText>}
			<Box mt={2} />
			{startDateFromCart ? (
				<Box mb={1} textAlign="center">
					<Typography variant="caption" align="center">
						<Trans
							i18nKey="common:booking.bookingStartDescription"
							defaults="Your booking will start <start>{{startDate}}</start>"
							values={{
								startDate: localFormat(moment(value), 'ddd DD.MM.'),
							}}
							components={{
								start: <strong />,
							}}
						/>
					</Typography>
				</Box>
			) : (
				<Box mb={3}>
					<BookingCalendar
						startDate={!!value ? momentToDateInShopTimezone(moment(value)) : null}
						durationInSeconds={0}
						onStartDateChange={(date) => handleDateSelect(date.toISOString())}
						calendarDate={momentToDateInShopTimezone(moment(calendarDate))}
						onCalendarDateChange={(date) => setCalendarDate(date.toISOString())}
						disabled={disabled}
						minDate={!!minDate ? momentToDateInShopTimezone(moment(minDate)) : undefined}
						maxDate={!!maxDate ? momentToDateInShopTimezone(moment(maxDate)) : undefined}
						weekStartDay={shopStartDay}
						locale={language}
						disabledDates={disabledDates}
					/>
				</Box>
			)}
			<Box textAlign="center">{renderOpeningTimes()}</Box>
		</Box>
	);
});

const useStyles = makeStyles((theme) =>
	createStyles({
		bookingDetails: {
			fontWeight: 500,
		},
		divider: {
			borderTop: `1px solid #e7e7e7`,
		},
	}),
);

export default StartDateSelector;
