import { useCallback } from 'react';

import { useLocation } from 'react-router';

const useSearchParams = () => {
	const location = useLocation();

	const paramsWith = useCallback(
		(args: { [K: string]: string | null }) => {
			const params = new URLSearchParams(location.search);
			Object.entries(args).forEach(([key, value]) =>
				value ? params.set(key, value) : params.delete(key),
			);
			return params;
		},
		[location.search],
	);

	const paramsWithout = useCallback(
		(...keys: string[]) => {
			const params = new URLSearchParams(location.search);
			keys.forEach((key) => params.delete(key));
			return params;
		},
		[location.search],
	);

	return {
		value: new URLSearchParams(location.search),
		with: paramsWith,
		without: paramsWithout,
	};
};

export default useSearchParams;
