import React from 'react';

import { Box, BoxProps, Grid, TextField, TextFieldProps } from '@mui/material';

import AutoCompleteCountryPicker from 'common/components/pickers/AutoCompleteCountryPicker';
import { Address } from 'common/modules/atoms/addresses';
import { useTranslation } from 'services/localization';

export interface AddressFormProps extends Omit<BoxProps, 'value' | 'onChange'> {
	value: Partial<Address>;
	onChange: (args: { field: keyof Address; value: string; full: Partial<Address> }) => void;
	autoFocus?: boolean;
	errors?: { [key in keyof Address]?: string | null } | null;
	id?: string;
}
const AddressForm = (props: AddressFormProps) => {
	const { t } = useTranslation();
	const { value, onChange, errors, autoFocus, ...boxProps } = props;

	const handleAddressFieldChange = (field: keyof Address) => (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		onChange({
			field,
			value: e.target.value,
			full: {
				...value,
				[field]: e.target.value,
			},
		});
	};

	const handleAddressCountryChange = (newCountry: string) => {
		onChange({
			field: 'country',
			value: newCountry,
			full: {
				...value,
				country: newCountry,
			},
		});
	};

	const getFieldProps = (field: keyof Address): Partial<TextFieldProps> => {
		return {
			value: value[field] ?? '',
			onChange: handleAddressFieldChange(field),
			error: !!errors?.[field],
			helperText: errors?.[field],
		};
	};

	return (
		<Box style={{ border: 'none', padding: 0 }} {...boxProps} component="fieldset">
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<AddressFormTextField
						label={t('common:address.address', 'Address')}
						{...getFieldProps('address')}
						autoFocus={autoFocus}
						required
					/>
				</Grid>
				<Grid item xs={6}>
					<AddressFormTextField
						label={t('common:address.zipPostalCode', 'ZIP / Postal code')}
						{...getFieldProps('zipCode')}
						required
					/>
				</Grid>
				<Grid item xs={6}>
					<AddressFormTextField
						label={t('common:address.city', 'City')}
						{...getFieldProps('city')}
						required
					/>
				</Grid>
				<Grid item xs={6}>
					<AddressFormTextField
						label={t('common:address.stateProvince', 'State / Province')}
						{...getFieldProps('state')}
					/>
				</Grid>
				<Grid item xs={6}>
					<AutoCompleteCountryPicker
						label={t('common:address.country', 'Country')}
						value={value.country}
						onChange={handleAddressCountryChange}
						error={errors?.country ?? undefined}
						variant="outlined"
						required
					/>
				</Grid>
				<Grid item xs={12}>
					<AddressFormTextField
						label={`${t('common:address.additionalInformation', 'Additional information')} (${t(
							'common:states.optional',
							'Optional',
						).toLowerCase()})`}
						{...getFieldProps('details')}
						multiline
						rows={4}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

const AddressFormTextField = (props: TextFieldProps) => (
	<TextField fullWidth variant="outlined" {...props} />
);

export default AddressForm;
