import React from 'react';

import { Stack, TextField } from '@mui/material';

import {
	CheckoutSettings,
	CustomContentError,
	CustomTextfieldValue,
} from 'common/modules/customization';
import { Languages, getTranslation } from 'common/modules/translations';

export interface Props {
	checkout: CheckoutSettings;
	onChange: (args: CustomTextfieldValue) => void;
	errors?: CustomContentError[] | null;
	disabled?: boolean;
	language: Languages;
}
const CustomTextfield = (props: Props) => {
	const { checkout, onChange, errors, disabled, language } = props;

	const getErrorById = (id: string) => {
		return errors?.find((error) => error.id === id);
	};

	return (
		<Stack spacing={2}>
			{checkout.customContent?.sections?.map((section, sectionIndex) => (
				<Stack
					spacing={2}
					direction={{ xs: 'column', md: 'row' }}
					key={sectionIndex}
					sx={{ width: '100%' }}
				>
					{section?.content?.map((content, contentIndex) => {
						return (
							content?.type === 'textfield' && (
								<TextField
									key={contentIndex}
									value={content.value}
									label={getTranslation(content.label, language)}
									required={content.required}
									onChange={(e) =>
										onChange({ value: e.target.value, sectionIndex, contentIndex, id: content.id })
									}
									id={content.id}
									fullWidth
									variant="outlined"
									multiline
									error={!!getErrorById(content.id)?.error}
									helperText={getErrorById(content.id)?.error ?? undefined}
									disabled={disabled}
									InputLabelProps={{
										sx: {
											'&.Mui-disabled': {
												color: (theme) => theme.palette.text.secondary,
											},
										},
									}}
								/>
							)
						);
					})}
				</Stack>
			))}
		</Stack>
	);
};

export default CustomTextfield;
