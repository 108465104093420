import React from 'react';

import { Box, IconButton, Typography } from '@mui/material';
import { RiCloseLine, RiShoppingBagLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import * as ViewActions from 'redux/actions/view';

import { t } from 'services/localization';

const CartHeader = () => {
	const dispatch = useDispatch();

	return (
		<Box px={3} py={2}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				<RiShoppingBagLine size={32} color="black" />
				<Typography variant="h6" id="cart-modal-title" sx={{ flex: 1, pl: 2 }}>
					{t('Cart.title', 'Your bag')}
				</Typography>
				<IconButton
					onClick={() => dispatch(ViewActions.setCartOpen(false))}
					edge="end"
					size="large"
				>
					<RiCloseLine size={32} color="black" />
				</IconButton>
			</Box>
		</Box>
	);
};

export default CartHeader;
