import React, { useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import AnchorMe from 'common/components/AnchorMe';
import {
	isProductForRent,
	isProductForSale,
	productHasMultiplePurchaseTypes,
} from 'common/modules/products/utils';
import { Category, ProductApi } from 'common/types';
import { getFromPriceString } from 'common/utils/pricing/common';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'services/localization';
import { getLiftTicketsFromPriceString } from 'utils/liftTicket';

type Props =
	| {
			product: ProductApi;
	  }
	| {
			liftTicketCategory: Category;
	  };

export const isLiftTicket = (params: Props): params is { liftTicketCategory: Category } => {
	return !!(params as { liftTicketCategory: Category }).liftTicketCategory;
};

const ProductDetails = (props: Props) => {
	const classes = useStyles();
	const { logEcomEvent } = useAnalytics();
	const { t, getTranslation } = useTranslation();

	const pricingTables = useSelector(ShopSelectors.shopPricingTables);
	const currency = useSelector(ShopSelectors.shopCurrency);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const timezone = useSelector(ShopSelectors.shopTimezone);
	const liftTicketProducts = useSelector(StockSelectors.liftTicketProducts);

	const getFromPrice = () => {
		if (isLiftTicket(props)) {
			return getLiftTicketsFromPriceString(liftTicketProducts, currency);
		}

		const kind = productHasMultiplePurchaseTypes(props.product)
			? 'from'
			: isProductForSale(props.product)
			? 'buy-from'
			: isProductForRent(props.product)
			? 'book-from'
			: null;

		if (!kind) return '';

		return getFromPriceString(
			{
				product: props.product,
				pricingTables,
				openingHours,
				currency,
				startDate: null,
				channel: 'ONLINE',
				timezone,
				kind,
			},
			t,
		);
	};

	useEffect(() => {
		if (isLiftTicket(props)) {
			logEcomEvent('view_item', { isLiftTicket: true });
		} else {
			logEcomEvent('view_item', { productId: props.product.id });
		}
	}, [logEcomEvent, props]);

	return (
		<Box>
			<Box>
				<Typography variant="h1" className={classes.name}>
					{isLiftTicket(props)
						? getTranslation(props.liftTicketCategory.name)
						: getTranslation(props.product.name)}
				</Typography>
				<Typography variant="body1" className={classes.price}>
					{getFromPrice()}
				</Typography>
			</Box>
			<Box mt={4}>
				<Typography variant="body1" className={classes.description}>
					<AnchorMe>
						{isLiftTicket(props)
							? getTranslation(props.liftTicketCategory.description ?? { def: '' })
							: getTranslation(props.product.description ?? { def: '' })}
					</AnchorMe>
				</Typography>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		name: {
			fontSize: '3.2rem',
			[theme.breakpoints.up('md')]: {
				fontSize: '4.8rem',
			},
		},
		price: {
			marginTop: theme.spacing(1),
			fontWeight: 500,
		},
		description: {
			color: theme.palette.text.secondary,
			fontSize: '1.6rem',
			whiteSpace: 'pre-line',
		},
	}),
);

export default ProductDetails;
