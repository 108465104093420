import { ReduxState } from 'redux/types';

import { getLatestPossiblePickupslot } from 'utils/delivery';

export const language = (state: ReduxState) => state.view.language;
export const cartOpen = (state: ReduxState) => state.view.cartOpen;

export const filters = (state: ReduxState) => state.view.filters;
export const filtersCategory = (state: ReduxState) => state.view.filters.category;
export const filtersProductType = (state: ReduxState) => state.view.filters.productType;
export const filtersOpen = (state: ReduxState) => state.view.filtersOpen;

export const sidebarOpen = (state: ReduxState) => state.view.sidebarOpen;

export const activeShopperIndex = (state: ReduxState) => state.view.activeShopperIndex;

export const unitSystem = (state: ReduxState) => state.view.activeUnitSystem;

export const checkoutFormErrors = (state: ReduxState) => state.view.checkoutFormErrors;

export const phoneObject = (state: ReduxState) => state.view.phoneObject;

export const showReservationExpiredBanner = (state: ReduxState) =>
	state.view.showReservationExpiredBanner;

export const selectedDeliveryOption = (state: ReduxState) => state.view.selectedDeliveryOption;

export const deliverySelected = (state: ReduxState) => !!state.view.selectedDeliveryOption;

export const availablePickupSlots = (state: ReduxState) => state.view.availablePickupSlots;

export const latestPossiblePickupSlot = (state: ReduxState) => {
	const pickupslots = availablePickupSlots(state);
	return !!pickupslots ? getLatestPossiblePickupslot(pickupslots) : null;
};

export const pickupChecked = (state: ReduxState) => state.view.pickupChecked;
