export const getLoginPopupParams = () => {
	// Fixes dual-screen position                             Most browsers      Firefox
	const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
	const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

	const width = window.innerWidth
		? window.innerWidth
		: document.documentElement.clientWidth
		? document.documentElement.clientWidth
		: window.screen.width;
	const height = window.innerHeight
		? window.innerHeight
		: document.documentElement.clientHeight
		? document.documentElement.clientHeight
		: window.screen.height;

	const maxWidth = 600;
	const maxHeight = 1000;

	const popupWidth = Math.min(width * 0.8, maxWidth);
	const popupHeight = Math.min(height * 0.8, maxHeight);

	const left = (width - popupWidth) / 2 + dualScreenLeft;
	const top = (height - popupHeight) / 2 + dualScreenTop;
	return `toolbar=no,menubar=no,width=${popupWidth},height=${popupHeight},top=${top},left=${left}`;
};

export const getRedirectUrl = ({ origin, path }: { origin: string; path: string }) => {
	return `${origin}${path}`;
};
