import * as React from 'react';

import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			padding: '32px 16px 56px 16px',
			width: '100%',
			flex: '1 1 auto',
			[theme.breakpoints.up('sm')]: {
				padding: '48px 32px 64px 32px',
			},
			[theme.breakpoints.up('md')]: {
				padding: '64px 64px 80px 64px',
			},
			[theme.breakpoints.up(1408)]: {
				maxWidth: '1280px',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
		},
		paddingBottom: {
			/* To be 32px padding to bottom btn */
			paddingBottom: 'calc(99px + 100vh*0.05)',
		},
		fluid: {
			[theme.breakpoints.up(1408)]: {
				maxWidth: 'unset',
				padding: '64px 64px 80px 64px',
			},
		},
		densePaddingTop: {
			paddingTop: 16,
		},
		noVerticalPadding: {
			paddingBottom: '0 !important',
			paddingTop: '0 !important',
		},
	});

interface ContainerProps {
	className?: any;
	style?: any;
	children?: any;
	id?: string;
	paddingBottom?: boolean;
	fluid?: boolean;
	densePaddingTop?: boolean;
	noVerticalPadding?: boolean;
}

type Props = ContainerProps & WithStyles<typeof styles>;

const Container = ({
	id,
	className,
	style,
	classes,
	children,
	paddingBottom,
	densePaddingTop,
	noVerticalPadding,
	fluid,
}: Props) => (
	<div
		id={id}
		className={classNames(
			classes.root,
			className,
			paddingBottom ? classes.paddingBottom : {},
			densePaddingTop ? classes.densePaddingTop : {},
			fluid ? classes.fluid : {},
			noVerticalPadding ? classes.noVerticalPadding : {},
		)}
		style={style}
	>
		{children}
	</div>
);

export default withStyles(styles)(Container);
