import countryList from 'common/services/countryCodes.json';
import { CurrencyObject } from 'common/types';
import {
	currencies,
	getCurrencyObjectFromCurrencyCode,
	getDefaultCurrencyObject,
} from 'common/utils/currencyUtils';

export type CountryType = {
	code: string;
	name: string;
	dial_code: string;
	flag: string;
};

export enum CountryCode {
	Afghanistan = 'AF',
	AlandIslands = 'AX',
	Albania = 'AL',
	Algeria = 'DZ',
	AmericanSamoa = 'AS',
	Andorra = 'AD',
	Angola = 'AO',
	Anguilla = 'AI',
	Antarctica = 'AQ',
	AntiguaAndBarbuda = 'AG',
	Argentina = 'AR',
	Armenia = 'AM',
	Aruba = 'AW',
	Australia = 'AU',
	Austria = 'AT',
	Azerbaijan = 'AZ',
	Bahamas = 'BS',
	Bahrain = 'BH',
	Bangladesh = 'BD',
	Barbados = 'BB',
	Belarus = 'BY',
	Belgium = 'BE',
	Belize = 'BZ',
	Benin = 'BJ',
	Bermuda = 'BM',
	Bhutan = 'BT',
	Bolivia = 'BO',
	BonaireSintEustatiusSaba = 'BQ',
	BosniaAndHerzegovina = 'BA',
	Botswana = 'BW',
	BouvetIsland = 'BV',
	Brazil = 'BR',
	BritishIndianOceanTerritory = 'IO',
	BruneiDarussalam = 'BN',
	Bulgaria = 'BG',
	BurkinaFaso = 'BF',
	Burundi = 'BI',
	Cambodia = 'KH',
	Cameroon = 'CM',
	Canada = 'CA',
	CapeVerde = 'CV',
	CaymanIslands = 'KY',
	CentralAfricanRepublic = 'CF',
	Chad = 'TD',
	Chile = 'CL',
	China = 'CN',
	ChristmasIsland = 'CX',
	CocosKeelingIslands = 'CC',
	Colombia = 'CO',
	Comoros = 'KM',
	Congo = 'CG',
	CongoDemocraticRepublic = 'CD',
	CookIslands = 'CK',
	CostaRica = 'CR',
	CoteDIvoire = 'CI',
	Croatia = 'HR',
	Cuba = 'CU',
	Curaçao = 'CW',
	Cyprus = 'CY',
	CzechRepublic = 'CZ',
	Denmark = 'DK',
	Djibouti = 'DJ',
	Dominica = 'DM',
	DominicanRepublic = 'DO',
	Ecuador = 'EC',
	Egypt = 'EG',
	ElSalvador = 'SV',
	EquatorialGuinea = 'GQ',
	Eritrea = 'ER',
	Estonia = 'EE',
	Ethiopia = 'ET',
	FalklandIslands = 'FK',
	FaroeIslands = 'FO',
	Fiji = 'FJ',
	Finland = 'FI',
	France = 'FR',
	FrenchGuiana = 'GF',
	FrenchPolynesia = 'PF',
	FrenchSouthernTerritories = 'TF',
	Gabon = 'GA',
	Gambia = 'GM',
	Georgia = 'GE',
	Germany = 'DE',
	Ghana = 'GH',
	Gibraltar = 'GI',
	Greece = 'GR',
	Greenland = 'GL',
	Grenada = 'GD',
	Guadeloupe = 'GP',
	Guam = 'GU',
	Guatemala = 'GT',
	Guernsey = 'GG',
	Guinea = 'GN',
	GuineaBissau = 'GW',
	Guyana = 'GY',
	Haiti = 'HT',
	HeardIslandMcdonaldIslands = 'HM',
	HolySeeVaticanCityState = 'VA',
	Honduras = 'HN',
	HongKong = 'HK',
	Hungary = 'HU',
	Iceland = 'IS',
	India = 'IN',
	Indonesia = 'ID',
	Iran = 'IR',
	Iraq = 'IQ',
	Ireland = 'IE',
	IsleOfMan = 'IM',
	Israel = 'IL',
	Italy = 'IT',
	Jamaica = 'JM',
	Japan = 'JP',
	Jersey = 'JE',
	Jordan = 'JO',
	Kazakhstan = 'KZ',
	Kenya = 'KE',
	Kiribati = 'KI',
	Korea = 'KR',
	KoreaDemocraticPeoplesRepublic = 'KP',
	Kuwait = 'KW',
	Kyrgyzstan = 'KG',
	LaoPeoplesDemocraticRepublic = 'LA',
	Latvia = 'LV',
	Lebanon = 'LB',
	Lesotho = 'LS',
	Liberia = 'LR',
	LibyanArabJamahiriya = 'LY',
	Liechtenstein = 'LI',
	Lithuania = 'LT',
	Luxembourg = 'LU',
	Macao = 'MO',
	Macedonia = 'MK',
	Madagascar = 'MG',
	Malawi = 'MW',
	Malaysia = 'MY',
	Maldives = 'MV',
	Mali = 'ML',
	Malta = 'MT',
	MarshallIslands = 'MH',
	Martinique = 'MQ',
	Mauritania = 'MR',
	Mauritius = 'MU',
	Mayotte = 'YT',
	Mexico = 'MX',
	Micronesia = 'FM',
	Moldova = 'MD',
	Monaco = 'MC',
	Mongolia = 'MN',
	Montenegro = 'ME',
	Montserrat = 'MS',
	Morocco = 'MA',
	Mozambique = 'MZ',
	Myanmar = 'MM',
	Namibia = 'NA',
	Nauru = 'NR',
	Nepal = 'NP',
	Netherlands = 'NL',
	NewCaledonia = 'NC',
	NewZealand = 'NZ',
	Nicaragua = 'NI',
	Niger = 'NE',
	Nigeria = 'NG',
	Niue = 'NU',
	NorfolkIsland = 'NF',
	NorthernMarianaIslands = 'MP',
	Norway = 'NO',
	Oman = 'OM',
	Pakistan = 'PK',
	Palau = 'PW',
	PalestinianTerritory = 'PS',
	Panama = 'PA',
	PapuaNewGuinea = 'PG',
	Paraguay = 'PY',
	Peru = 'PE',
	Philippines = 'PH',
	Pitcairn = 'PN',
	Poland = 'PL',
	Portugal = 'PT',
	PuertoRico = 'PR',
	Qatar = 'QA',
	Reunion = 'RE',
	Romania = 'RO',
	RussianFederation = 'RU',
	Rwanda = 'RW',
	SaintBarthelemy = 'BL',
	SaintHelena = 'SH',
	SaintKittsAndNevis = 'KN',
	SaintLucia = 'LC',
	SaintMartin = 'MF',
	SaintPierreAndMiquelon = 'PM',
	SaintVincentAndGrenadines = 'VC',
	Samoa = 'WS',
	SanMarino = 'SM',
	SaoTomeAndPrincipe = 'ST',
	SaudiArabia = 'SA',
	Senegal = 'SN',
	Serbia = 'RS',
	Seychelles = 'SC',
	SierraLeone = 'SL',
	Singapore = 'SG',
	SintMaarten = 'SX',
	Slovakia = 'SK',
	Slovenia = 'SI',
	SolomonIslands = 'SB',
	Somalia = 'SO',
	SouthAfrica = 'ZA',
	SouthGeorgiaAndSandwichIsl = 'GS',
	SouthSudan = 'SS',
	Spain = 'ES',
	SriLanka = 'LK',
	Sudan = 'SD',
	Suriname = 'SR',
	SvalbardAndJanMayen = 'SJ',
	Swaziland = 'SZ',
	Sweden = 'SE',
	Switzerland = 'CH',
	SyrianArabRepublic = 'SY',
	Taiwan = 'TW',
	Tajikistan = 'TJ',
	Tanzania = 'TZ',
	Thailand = 'TH',
	TimorLeste = 'TL',
	Togo = 'TG',
	Tokelau = 'TK',
	Tonga = 'TO',
	TrinidadAndTobago = 'TT',
	Tunisia = 'TN',
	Turkey = 'TR',
	Turkmenistan = 'TM',
	TurksAndCaicosIslands = 'TC',
	Tuvalu = 'TV',
	Uganda = 'UG',
	Ukraine = 'UA',
	UnitedArabEmirates = 'AE',
	UnitedKingdom = 'GB',
	UnitedStates = 'US',
	UnitedStatesOutlyingIslands = 'UM',
	Uruguay = 'UY',
	Uzbekistan = 'UZ',
	Vanuatu = 'VU',
	Venezuela = 'VE',
	Vietnam = 'VN',
	VirginIslandsBritish = 'VG',
	VirginIslandsUS = 'VI',
	WallisAndFutuna = 'WF',
	WesternSahara = 'EH',
	Yemen = 'YE',
	Zambia = 'ZM',
	Zimbabwe = 'ZW',
}

// List of member states: https://europa.eu/european-union/about-eu/countries_en
export const EUMemberStates: CountryCode[] = [
	CountryCode.Austria,
	CountryCode.Belgium,
	CountryCode.Bulgaria,
	CountryCode.Croatia,
	CountryCode.Cyprus,
	CountryCode.CzechRepublic,
	CountryCode.Denmark,
	CountryCode.Estonia,
	CountryCode.Finland,
	CountryCode.France,
	CountryCode.Germany,
	CountryCode.Greece,
	CountryCode.Hungary,
	CountryCode.Ireland,
	CountryCode.Italy,
	CountryCode.Latvia,
	CountryCode.Lithuania,
	CountryCode.Luxembourg,
	CountryCode.Malta,
	CountryCode.Netherlands,
	CountryCode.Poland,
	CountryCode.Portugal,
	CountryCode.Romania,
	CountryCode.Slovakia,
	CountryCode.Slovenia,
	CountryCode.Spain,
	CountryCode.Sweden,
];

export const isEUMemberState = (countryCode: CountryCode): boolean => {
	return EUMemberStates.includes(countryCode);
};

export const isCountryCode = (value: string): value is CountryCode => {
	return Object.values(CountryCode).includes(value as CountryCode);
};

export const getCountryNameFromCountryCode = (countryCode: CountryCode): string => {
	return (
		countryList.find((countryObject) => countryObject.code === countryCode)?.name ?? countryCode
	);
};

/**
 * These countries are blocked from signing up to Rentle.
 */
export const BLACK_LISTED_COUNTRIES = ['AS', 'FJ', 'GU', 'PW', 'PA', 'WS', 'TT', 'VI', 'VU'];

export const getDateFormatByCountryCode = (
	countryCode: CountryCode,
): 'MM/DD/YYYY' | 'DD.MM.YYYY' | 'YYYY-MM-DD' => {
	switch (countryCode) {
		case 'CA':
		case 'KY':
		case 'GU':
		case 'FM':
		case 'PA':
		case 'PH':
		case 'PR':
		case 'WS':
		case 'US':
		case 'VI':
			return 'MM/DD/YYYY';
		case 'AU':
		case 'CN':
		case 'HU':
		case 'JP':
		case 'LT':
		case 'MN':
		case 'KR':
		case 'TW':
			return 'YYYY-MM-DD';
		default:
			return 'DD.MM.YYYY';
	}
};

export const getTimeFormatByCountryCode = (countryCode: CountryCode): 'hh:mm A' | 'HH:mm' => {
	switch (countryCode) {
		case 'AF':
		case 'AL':
		case 'AM':
		case 'BH':
		case 'BD':
		case 'BT':
		case 'KH':
		case 'CO':
		case 'CR':
		case 'DO':
		case 'EG':
		case 'SV':
		case 'ET':
		case 'GH':
		case 'GT':
		case 'HN':
		case 'IQ':
		case 'JO':
		case 'KW':
		case 'LB':
		case 'LR':
		case 'LY':
		case 'MY':
		case 'MR':
		case 'MA':
		case 'MM':
		case 'NA':
		case 'NP':
		case 'NI':
		case 'KP':
		case 'OM':
		case 'PA':
		case 'PH':
		case 'PR':
		case 'QA':
		case 'SA':
		case 'SL':
		case 'SO':
		case 'SS':
		case 'SD':
		case 'SY':
		case 'TO':
		case 'TN':
		case 'AE':
		case 'US':
		case 'VU':
		case 'VE':
		case 'YE':
			return 'hh:mm A';
		default:
			return 'HH:mm';
	}
};

export const getStartDayByCountryCode = (
	countryCode: CountryCode,
): 'saturday' | 'sunday' | 'monday' => {
	switch (countryCode) {
		case 'AR':
		case 'BZ':
		case 'BO':
		case 'BR':
		case 'CA':
		case 'CL':
		case 'CN':
		case 'CO':
		case 'DO':
		case 'EC':
		case 'SV':
		case 'GT':
		case 'HN':
		case 'HK':
		case 'IL':
		case 'JM':
		case 'JP':
		case 'KE':
		case 'MO':
		case 'MX':
		case 'NI':
		case 'PA':
		case 'PE':
		case 'PH':
		case 'PR':
		case 'ZA':
		case 'KR':
		case 'TW':
		case 'US':
		case 'VE':
		case 'ZW':
			return 'sunday';
		case 'AF':
		case 'DZ':
		case 'BH':
		case 'EG':
		case 'IR':
		case 'IQ':
		case 'JO':
		case 'KW':
		case 'LY':
		case 'OM':
		case 'QA':
		case 'SA':
		case 'SY':
		case 'AE':
		case 'YE':
			return 'saturday';
		default:
			return 'monday';
	}
};

export const getInitialUnitSystemFromCountry = (
	countryCode: CountryCode,
): 'US' | 'METRIC' | 'UK' => {
	switch (countryCode) {
		case 'US':
		case 'LR':
		case 'MM':
			return 'US';
		case 'GB':
			return 'UK';
		default:
			return 'METRIC';
	}
};

export const getTaxExcludedFromCountry = (countryCode: CountryCode): boolean => {
	switch (countryCode) {
		case 'US':
		case 'CA':
			return true;
		default:
			return false;
	}
};

export const getDefaultCurrencyObjectForCountry = (countryCode: string): CurrencyObject => {
	const matchingCurrency = currencies.find(
		(value) => !value.complementary && value.countries.some((country) => country === countryCode),
	);

	return !!matchingCurrency
		? getCurrencyObjectFromCurrencyCode(matchingCurrency.code) ?? getDefaultCurrencyObject()
		: getDefaultCurrencyObject();
};
