import React from 'react';

import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface Props {
	light?: boolean;
	top?: number;
	bottom?: number;
}

const Divider = (props: Props) => {
	const classes = useStyles(props);

	return <div className={classes.divider} />;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		divider: {
			width: '100%',
			height: '1px',
			marginTop: (props: Props) => theme.spacing(props.top ?? 0),
			marginBottom: (props: Props) => theme.spacing(props.bottom ?? 0),
			background: (props: Props) => (props.light ? '#f2f2f2' : '#e7e7e7'),
		},
	}),
);

export default Divider;
