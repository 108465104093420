import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import Select, { SelectOption } from 'common/components/pickers/Select';
import { ISOString } from 'common/types';
import { doesTicketHaveSegment } from 'common/utils/segments';
import ErrorText from 'components/ErrorText';
import { useTranslation } from 'services/localization/useTranslation';
import { getTicketSegmentPrice } from 'utils/liftTicket';

export interface TicketTypeSelectorProps {
	value: string | null;
	selectedSegmentId: string | null;
	onChange: (value: string | null) => void;
	startDate: ISOString | null;
	error?: string | null;
	loading?: boolean;
}

const TicketTypeSelector = React.memo((props: TicketTypeSelectorProps) => {
	const { value, onChange, error, selectedSegmentId, startDate, loading } = props;
	const { t, getTranslation } = useTranslation();

	const normalLiftTicketProducts = useSelector(StockSelectors.normalLiftTicketProducts);
	const currency = useSelector(ShopSelectors.shopCurrency);

	const ticketTypeOptions: SelectOption[] = useMemo(
		() =>
			normalLiftTicketProducts.filter(doesTicketHaveSegment(selectedSegmentId)).reduce(
				(options: SelectOption[], product) =>
					product.segmentPricings?.length
						? [
								...options,
								{
									id: product.id,
									label: getTranslation(product.name),
									rightContent: getTicketSegmentPrice(product, selectedSegmentId, currency),
									rightContentLoading: loading,
								},
						  ]
						: options,
				[],
			),
		[normalLiftTicketProducts, selectedSegmentId, getTranslation, currency, loading],
	);

	const handleTicketTypeChange = (productId?: string) => {
		onChange(productId ?? null);
	};

	return (
		<Box>
			<Select.Single
				label=""
				emptyLabel={t('tickets.chooseTicketType', 'Choose ticket type')}
				value={value}
				hideEmptyOption
				indicators="none"
				onChange={handleTicketTypeChange}
				options={ticketTypeOptions}
				disabled={!selectedSegmentId || !startDate}
			/>
			{error && (
				<Box mt={0.5}>
					<ErrorText>{error}</ErrorText>
				</Box>
			)}
		</Box>
	);
});

export default TicketTypeSelector;
