import React, { useState } from 'react';

import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as ViewActions from 'redux/actions/view';
import { useDispatch, useSelector } from 'redux/hooks';
import * as StockSelectors from 'redux/selectors/stock';

import useSearchParams from 'hooks/useSearchParams';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';

import LinksFilter from './LinksFilter';
import RadioGroupFilter, { NONE_VALUE } from './RadioGroupFilter';

export const ALL_CATEGORIES_ID = 'all';

const Sidebar = () => {
	const dispatch = useDispatch();
	const { getPath, pushRoute, Routes } = useRoutes();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const { getTranslation } = useTranslation();

	const searchParams = useSearchParams();
	const categories = useSelector(StockSelectors.visibleCategoriesSorted);

	const categoryId = searchParams.value.get('category');
	const productType = searchParams.value.get('productType');

	const [pendingCategoryId] = useState(categoryId ?? NONE_VALUE);

	const handleCategorySelect = (categoryId: string) => {
		pushRoute(Routes.browse, {
			query: { category: categoryId !== NONE_VALUE ? categoryId : ALL_CATEGORIES_ID },
		});
		dispatch(ViewActions.setFiltersOpen(false));
	};

	const renderLinksFilters = () => (
		<Box mb={4}>
			<LinksFilter
				value={categoryId === ALL_CATEGORIES_ID ? null : categoryId}
				options={categories.map((category) => ({
					value: category.id,
					label: getTranslation(category.name),
					path: getPath(Routes.browse, {
						query: { category: category.id, productType: productType ?? undefined },
					}),
				}))}
				selectAllPath={getPath(Routes.browse, {
					query: { category: ALL_CATEGORIES_ID, productType: productType ?? undefined },
				})}
				onSelectAllClick={() => dispatch(ViewActions.setFilterCategory(null))}
			/>
		</Box>
	);

	const renderRadioGroupFilters = () => (
		<Box>
			<RadioGroupFilter
				value={pendingCategoryId}
				options={categories.map((category) => ({
					value: category.id,
					label: getTranslation(category.name),
				}))}
				onChange={handleCategorySelect}
			/>
		</Box>
	);

	return isSmallScreen ? renderRadioGroupFilters() : renderLinksFilters();
};

export default Sidebar;
