import React, { useState } from 'react';

import { Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { showReservationExpiredBanner } from 'redux/actions/view';
import * as CartSelectors from 'redux/selectors/cart';
import * as CheckoutSelectors from 'redux/selectors/checkout';

import { useInterval } from 'common/hooks/useInterval';
import { getReservationEndTime, isReservationExpired } from 'common/modules/reservations';
import { useTranslation } from 'services/localization/useTranslation';
import { useRoutes } from 'services/routing/useRoutes';

const ReservationTimer = () => {
	const dispatch = useDispatch();
	const { pushRoute, Routes } = useRoutes();
	const reservationCreated = useSelector(CartSelectors.reservationCreated);
	const paymentInProgress = useSelector(CheckoutSelectors.paymentInProgress);
	const handlePaymentRedirect = useSelector(CheckoutSelectors.handlePaymentRedirect);
	const processingPayment = paymentInProgress || handlePaymentRedirect;

	const [timeLeftDuration, setTimeLeftDuration] = useState<moment.Duration | null>(null);
	const { t } = useTranslation();
	const classes = useStyles();
	const reservationEndTime = reservationCreated
		? getReservationEndTime(reservationCreated)
		: undefined;
	const formattedTimeLeft = !timeLeftDuration
		? null
		: timeLeftDuration.asMinutes() < 1
		? moment.utc(timeLeftDuration.asMilliseconds()).format('mm:ss')
		: timeLeftDuration.asMinutes() <= 10
		? `${moment.utc(timeLeftDuration.asMilliseconds()).format('m')} min`
		: null;

	const updateReservationTimer = () => {
		if (!reservationEndTime) {
			clearInterval();
			return;
		}
		if (isReservationExpired(reservationEndTime)) {
			clearInterval();
			setTimeLeftDuration(null);
			if (!processingPayment) {
				dispatch(showReservationExpiredBanner(true));
				pushRoute(Routes.browse);
			}
			return;
		}
		const timeLeftAsDuration = moment.duration(reservationEndTime.diff(moment()));
		setTimeLeftDuration(timeLeftAsDuration);
	};

	const { clearInterval } = useInterval(updateReservationTimer, 1000);

	return !!formattedTimeLeft ? (
		<div className={classes.root}>
			<Typography className={classes.timer} variant="body2" color="textPrimary" align="center">
				{`${t('expires', 'Booking expires in')} ${formattedTimeLeft}`}
			</Typography>
		</div>
	) : null;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: '#f2f2f2',
			height: 35,
			width: '100%',
			display: 'flex',
		},
		timer: {
			width: '100%',
			alignSelf: 'center',
		},
	}),
);

export default ReservationTimer;
