import { isEqual, maxBy } from 'lodash';
import { TFunction } from 'react-i18next';

/** Swap the places of two elements in an array. Returns a new array with the indexes at @index1 and @index2 swapped.
 */
export const swapIndexes = <T>(array: T[], index1: number, index2: number) => {
	const lastIndex = array.length - 1;

	if (index1 < 0 || index1 > lastIndex) return array.slice();
	if (index2 < 0 || index2 > lastIndex) return array.slice();

	const arr = array.slice();
	[arr[index1], arr[index2]] = [arr[index2], arr[index1]];
	return arr;
};

/** Move an element at @elementIndex one index to the right, if possible. Returns a new array with the element moved.
 */
export const moveElementRight = <T>(array: T[], elementIndex: number) => {
	const lastIndex = array.length - 1;
	if (elementIndex >= lastIndex) {
		return array.slice();
	} else {
		const nextIndex = elementIndex + 1;
		return swapIndexes(array, elementIndex, nextIndex);
	}
};

/** Move an element at @elementIndex one index to the left, if possible. Returns a new array with the element moved.
 */
export const moveElementLeft = <T>(array: T[], elementIndex: number) => {
	if (elementIndex <= 0) {
		return array.slice();
	} else {
		const prevIndex = elementIndex - 1;
		return swapIndexes(array, prevIndex, elementIndex);
	}
};

interface OrderedItem {
	orderIndex: number | null | undefined;
}
/** Given an array of orderable items, returns a new array where:
 *
 * - Each item has an orderIndex from 1 to n
 * - Each item has an orderIndex greater than the item before it
 *
 * @param array An array of items with the orderIndex field
 */
export const getUpdatedOrderIndexes = <T extends OrderedItem>(array: T[]) => {
	const orderIndexes = array.map((item) => item.orderIndex);
	const sortedOrderIndexes = orderIndexes.sort((a, b) => {
		return !a ? 1 : !b ? -1 : a - b;
	});

	const maxOrderIndex = maxBy(orderIndexes, 'orderIndex') ?? 1;
	const positiveOrderIndexes = sortedOrderIndexes.map((orderIndex) => {
		return !orderIndex ? maxOrderIndex : orderIndex <= 0 ? 1 : orderIndex;
	});

	const fixedOrderIndexes = positiveOrderIndexes.reduce((result, orderIndex, index) => {
		if (index === 0) {
			return [...result, orderIndex];
		} else {
			const lastIndex = result[result.length - 1]!;
			return orderIndex <= lastIndex ? [...result, lastIndex + 1] : [...result, orderIndex];
		}
	}, [] as number[]);

	return array.map((item, index) => {
		return {
			...item,
			orderIndex: fixedOrderIndexes[index],
		};
	});
};

/** Given an array of orderable items, returns all items where the order index needs to be updated
 *
 * @param array An array of items with the orderIndex field
 */
export const getOrderIndexesToUpdate = <T extends OrderedItem>(array: T[]) => {
	const updatedItems = getUpdatedOrderIndexes(array);

	return updatedItems.filter((updatedItem, index) => {
		const originalItem = array[index];
		if (updatedItem.orderIndex !== originalItem.orderIndex) {
			return true;
		}
		return false;
	});
};

/** Move an item in an array from a specified index to another index. Preserves the relative order of all other items in the array.
 *
 * @param array An array of items
 * @param fromIndex The index from which to move an item
 * @param toIndex The index to which the item should be moved
 * @param placement Whether to insert the item before or after the target index
 * @returns A new array with the specified item moved
 */

export const moveItem = <T>(
	array: T[],
	indexToMove: number,
	targetIndex: number,
	placement: 'before' | 'after' = 'before',
): T[] => {
	if (indexToMove === targetIndex) return array.slice();

	const itemToMove = array[indexToMove];
	const targetItem = array[targetIndex];

	if (!itemToMove || !targetItem) return array.slice();

	const result = array.slice();

	// Remove the item at it's original position
	result.splice(indexToMove, 1);

	// THEN find the index of the target element
	const newTargetIndex = result.indexOf(targetItem) + (placement === 'after' ? 1 : 0);

	// and insert the item at the correct index
	result.splice(newTargetIndex, 0, itemToMove);

	return result;
};

export const chunks = <T>(array: T[], chunkSize: number): T[][] => {
	if (chunkSize <= 0 || array.length === 0) return [array];

	const result = [];
	for (let idx = 0; idx < array.length; idx++) {
		if (idx % chunkSize === 0) {
			result.push(array.slice(idx, idx + chunkSize));
		}
	}

	return result;
};

export type Hash<T> = {
	[key: string]: T;
};

/** Produces an object, where each key corresponds to exactly one item with the provided key. If the field is not unique,
 * later items in the array will overwrite earlier ones with the same key.
 *
 * @param array The array to convert
 *  @param field The unique field to key the resulting object by, or a function that creates the key
 */
export const hashByUniqueField = <T extends object>(
	array: T[],
	field: string | ((item: T) => string),
): Hash<T> => {
	const result = {};
	array.forEach((item) => {
		const key = typeof field === 'function' ? field(item) : item[field];
		result[key] = item;
	});
	return result;
};

/** Produces an object, where each key corresponds to an array of items with @field equal to the key.
 *
 * @param array The array to convert
 * @param field The unique field to key the resulting object by, or a function that creates the key
 */
export const hashByField = <T extends object>(
	array: T[],
	field: string | ((item: T) => string),
): Hash<T[]> => {
	const result = {};
	array.forEach((item) => {
		const key = typeof field === 'function' ? field(item) : item[field];

		if (result[key]) {
			result[key].push(item);
		} else {
			result[key] = [item];
		}
	});
	return result;
};

/** Produces an object, where each key corresponds to exactly one item with the provided key. If the field is not unique,
 * later items in the array will overwrite earlier ones with the same key.
 *
 * @param array The array to convert
 * @param field The unique field to key the resulting object by, or a function that creates the key
 */
export const createKeyMap = (array: string[]): Hash<true> => {
	const result = {};
	array.forEach((key) => {
		result[key] = true;
	});
	return result;
};

export const hasSameItems = (a: any[], b: any[]) => {
	const sortedA = a.slice().sort();
	const sortedB = b.slice().sort();
	return isEqual(sortedA, sortedB);
};

export const sumItems = (array: number[]) => array.reduce((a, b) => a + b, 0);

export const arrayIncludes = <T extends U, U>(array: T[], el: U): el is T => {
	return array.includes(el as T);
};

export const arrayIncludesSome = <T>(array: T[] | undefined, els: any[]): boolean => {
	return els.some((el) => array?.includes(el as T));
};

export const arrayIncludesAll = <T>(array: T[] | undefined, els: any[]): boolean => {
	return els.every((el) => array?.includes(el as T));
};

/** Removes the elements from one array using the elements of another array
 *
 * @param array The array to remove elements from
 * @param elementsToRemove Elements to remove
 * @returns A new array without the elements of the other array
 */
export const removeElementsFromArray = <T>(array: T[], elementsToRemove: T[]) => {
	const result = [...array];

	elementsToRemove.forEach((item) => {
		const index = result.indexOf(item);
		if (index > -1) {
			result.splice(index, 1);
		}
	});

	return result;
};

export const arrayDiff = <T>({
	newArray,
	oldArray,
}: {
	newArray: T[];
	oldArray: T[];
}): { added: T[]; removed: T[] } => {
	const added = newArray.filter((x) => !oldArray.includes(x));
	const removed = oldArray.filter((x) => !newArray.includes(x));
	return { added, removed };
};

export const assignOrderIndexes = <T extends OrderedItem>(array: T[]): T[] =>
	array.reduce((acc, v, idx) => acc.concat({ ...v, orderIndex: idx + 1 }), [] as T[]);

export const getRandomElement = (array: any[]) => {
	return array[Math.floor(Math.random() * array.length)];
};

export const ellipsizeArray = (array: string[], maxItems: number, t: TFunction): string => {
	if (array.length <= maxItems) return array.join(', ');

	const visibleItems = array.slice(0, maxItems);
	const hiddenItems = array.slice(maxItems);

	return `${visibleItems.join(', ')} ${t('common:amounts.andNMore', {
		amount: hiddenItems.length,
		defaultValue: 'and {{amount}} more',
	})}`;
};
