import { StartTimesConfig, StartTimesConfigRow, StartTimesDefinition } from './types';

export const DEFAULT_START_TIMES_DEFINITION: StartTimesDefinition = {
	type: 'interval',
	value: {
		intervalMinutes: 60,
	},
};

export const DEFAULT_START_TIMES_ROW: StartTimesConfigRow = {
	type: 'weekly',
	value: {
		weekDays: [1, 2, 3, 4, 5, 6, 7],
		startTimes: DEFAULT_START_TIMES_DEFINITION,
	},
};

export const DEFAULT_START_TIMES_CONFIG: StartTimesConfig = {
	type: 'dateAndTime',
	value: [DEFAULT_START_TIMES_ROW],
	maxOrdersPerStartTime: null,
};

/** Configs for Admin order creation */

export const ADMIN_ORDER_DEFAULT_START_TIMES_CONFIG: StartTimesConfig = {
	type: 'dateAndTime',
	value: [
		{
			type: 'weekly',
			value: {
				weekDays: [1, 2, 3, 4, 5, 6, 7],
				startTimes: {
					type: 'interval',
					value: {
						intervalMinutes: 15,
					},
				},
			},
		},
	],
	maxOrdersPerStartTime: null,
};
