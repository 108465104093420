import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { isUndefined, times, uniq } from 'lodash';
import moment from 'moment-timezone';
import { showReservationExpiredBanner } from 'redux/actions/view';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';
import { ReduxDispatch, ReduxState } from 'redux/types';

import { api } from 'common/frontend/api';
import { isRentalProduct, isSalesProduct, isSubscriptionProduct } from 'common/modules/orders';
import { ProductSubscription } from 'common/modules/subscriptions';
import {
	CartDelivery,
	CartProduct,
	DeliveryTimeSlot,
	Duration,
	ISOString,
	PurchaseType,
	Reservation,
	SalesReservation,
} from 'common/types';
import { getTicketVariant } from 'common/utils/liftTicketUtils';
import { getSkidataSegmentById } from 'common/utils/segments';
import { ValidatedPackageLiftTicketFormState } from 'hooks/usePackageLiftTicketForm';
import { CartRow, ProductSelection } from 'services/types';
import * as CartUtils from 'utils/cart';
import { removeDeliveryUnavailabilityFromCartProducts } from 'utils/cart';

import { logEcomEvent } from './analytics';

export const addProductsToCart = createAction<CartProduct[]>('Cart/ADD_PRODUCTS_TO_CART');
export const addSelectionToCart = createAsyncThunk<
	void,
	{
		selection: ProductSelection;
		startDate: ISOString | null;
		startLocationId: string;
		duration: Duration;
		endLocationId: string;
		quantity: number;
		segment: string | null;
		selectedAsAdditional?: boolean;
		purchaseType: PurchaseType;
		subscription?: ProductSubscription;
	},
	{
		dispatch: ReduxDispatch;
		state: ReduxState;
	}
>('Cart/ADD_SELECTION_TO_CART', async (props, thunkAPI) => {
	const state = thunkAPI.getState();
	const productsById = StockSelectors.productsById(state);
	const shopCurrency = ShopSelectors.shopCurrency(state);
	const taxExcluded = ShopSelectors.taxExcluded(state);
	const shopPricingTables = ShopSelectors.shopPricingTables(state);
	const shopTimezone = ShopSelectors.shopTimezone(state);
	const openingHours = ShopSelectors.activeStoreOpeningHours(state);
	const deliveryOption = ViewSelectors.selectedDeliveryOption(state);
	const cartDelivery = CartSelectors.delivery(state);

	const cartProducts = CartUtils.getCartProductsFromProps({
		selection: CartUtils.getSelectionWithProducts(props.selection, productsById),
		startDate: props.startDate,
		startLocationId: props.startLocationId,
		endLocationId: props.endLocationId,
		duration: props.duration,
		quantity: props.quantity,
		shopCurrency,
		shopPricingTables,
		selectedAsAdditional: props.selectedAsAdditional,
		openingHours,
		shopTimezone,
		segment: props.segment,
		deliveryOption,
		cartDelivery,
		taxExcluded,
		purchaseType: props.purchaseType,
		subscription: props.subscription,
	});

	thunkAPI.dispatch(
		logEcomEvent('add_to_cart', {
			cartProducts,
		}),
	);
	thunkAPI.dispatch(addProductsToCart(cartProducts));
	thunkAPI.dispatch(resetReservationCreated());
});

export const addPackageTicketProductToCart = createAsyncThunk<
	void,
	{
		formState: ValidatedPackageLiftTicketFormState;
		selectedAsAdditional: boolean;
	},
	{
		dispatch: ReduxDispatch;
		state: ReduxState;
	}
>('Cart/ADD_PACKAGE_TICKET_PRODUCT_TO_CART', async (props, thunkAPI) => {
	const state = thunkAPI.getState();
	const packageId = api()._utils.newFirestoreId();

	const { formState, selectedAsAdditional } = props;

	const shopPublicInfo = ShopSelectors.shopPublicInfoData(state);
	const taxExcluded = ShopSelectors.taxExcluded(state);
	const currency = ShopSelectors.shopCurrency(state);
	const activeLocation = ShopSelectors.activeLocation(state);
	const allLiftTicketSegments = StockSelectors.allLiftTicketSegments(state);
	const liftTicketProducts = StockSelectors.liftTicketProductsForPackages(state);
	const allPackageProducts = StockSelectors.liftTicketPackageProducts(state);
	const packageProduct = allPackageProducts.find((product) => {
		return product.externalId === formState.externalPackageId;
	});
	const keycardProduct = StockSelectors.keycardProduct(state);

	if (!activeLocation || !shopPublicInfo || !keycardProduct) return;

	const products = formState.packageItems.flatMap((item) => {
		const segment = getSkidataSegmentById(item.externalSegmentId, allLiftTicketSegments);
		const selectedTicket = liftTicketProducts.find((p) => p.externalId === item.externalId);
		const quantity = item.quantity.selected;
		if (!selectedTicket || !quantity || !segment) return [];
		const ticketVariant = getTicketVariant(selectedTicket, formState.withNewKeycard);
		if (!ticketVariant) return [];

		if (isUndefined(formState.pricingInfo.priceBySegment[item.externalSegmentId])) return [];
		const price = formState.pricingInfo.priceBySegment[item.externalSegmentId].bestPrice;

		return times(quantity, () => {
			return CartUtils.getCartProductsForLiftTickets({
				startDate: formState.startDate,
				shopCurrency: currency,
				startLocationId: activeLocation.id,
				endLocationId: activeLocation.id,
				shopId: shopPublicInfo.shopId,
				quantity: 1,
				externalSegment: segment,
				selectedTicket: selectedTicket,
				variant: ticketVariant,
				withNewKeycard: formState.withNewKeycard,
				keycardProduct,
				selectedAsAdditional,
				taxExcluded,
				externalPackageId: formState.externalPackageId,
				packageId,
				packageCategoryIds: packageProduct?.categoryIds,
				stockPackageId: packageProduct?.id,
				price,
			});
		}).flat();
	});

	thunkAPI.dispatch(addProductsToCart(products));
	thunkAPI.dispatch(resetReservationCreated());
});

export const clearCartItemsForCheckoutCartReplace = createAction('Cart/CLEAR_CART_ITEMS_CHECKOUT');

export const resetCart = createAction('Cart/RESET_CART');

export const increaseItemQuantity = createAction<CartProduct>('Cart/INCREASE_ITEM_QUANTITY');
export const decreaseItemQuantity = createAction<CartProduct>('Cart/DECREASE_ITEM_QUANTITY');
export const removePackageTicketProductFromCart = createAction<string>(
	'Cart/REMOVE_PACKAGE_TICKET_PRODUCT_FROM_CART',
);

export const verifyPersistCart = createAsyncThunk<
	void,
	{ shopId: string },
	{
		dispatch: ReduxDispatch;
		state: ReduxState;
	}
>('Cart/VERIFY_PERSIST_CART', async ({ shopId }, { getState, dispatch }) => {
	const state = getState();
	const cartProducts = CartSelectors.cartProducts(state);
	const cartStartDate = CartSelectors.startDate(state);

	const cartHasProductsFromOtherShop = cartProducts.some((product) => product.shopId !== shopId);
	const cartIsExpired = cartStartDate && moment(cartStartDate).isBefore(moment(), 'day');
	if (cartHasProductsFromOtherShop || cartIsExpired) {
		dispatch(resetCart());
	}
});

export const updateCartItems = createAction<CartRow[]>('Cart/UPDATE_CART_ITEMS');

export const createReservation = createAsyncThunk<
	boolean,
	{ cartItems: CartRow[] },
	{
		dispatch: ReduxDispatch;
		state: ReduxState;
	}
>('Cart/CREATE_RESERVATION', async (args, thunkAPI) => {
	const state = thunkAPI.getState();
	const { cartItems } = args;
	const cartSessionId = CartSelectors.sessionId(state);
	const singleCartProduct = cartItems?.[0]?.products?.[0]?.product;
	if (!singleCartProduct) {
		thunkAPI.rejectWithValue('No items in cart');
	}
	const { startLocationId, endLocationId } = singleCartProduct;

	const cartProducts = cartItems.flatMap((p) => p.products).map((p) => p.product);

	const cartRentalProducts = cartProducts.filter(
		(c) => isRentalProduct(c) || isSubscriptionProduct(c),
	);
	const cartSalesProducts = cartProducts.filter(isSalesProduct);

	if (!!cartRentalProducts.length) {
		const allVariantIds = uniq(cartRentalProducts.flatMap((p) => p.summary.variantIds));
		const allSkuIds = uniq(cartRentalProducts.flatMap((p) => p.summary.skuIds));
		const reservation: Reservation = {
			id: cartSessionId,
			created: moment().toISOString(),
			startLocationId,
			endLocationId,
			channel: 'ONLINE',
			includedVariantIds: allVariantIds,
			skuIds: allSkuIds,
			itemsPerPeriod: cartItems.map((item) => {
				const reservationItems = item.products
					.filter((p) => isRentalProduct(p.product) || isSubscriptionProduct(p.product))
					.map((productWithQuantity) => {
						const { quantity, product } = productWithQuantity;
						const productApiId = product.productApiId;
						const variantIds = product.summary.variantIds;
						const skuIds = product.summary.skuIds;
						const unavailable = product.unavailable;
						return {
							quantity,
							variantIds,
							unavailable,
							skuIds,
							productId: productApiId,
						};
					});
				return {
					startDate: item.startDate,
					endDate: item.endDate,
					items: reservationItems,
				};
			}),
		};
		api().reservations.doc(reservation.id).set(reservation);
	}

	if (!!cartSalesProducts.length) {
		const allVariantIds = uniq(cartSalesProducts.flatMap((p) => p.summary.variantIds));
		const allSkuIds = uniq(cartSalesProducts.flatMap((p) => p.summary.skuIds));
		const reservation: SalesReservation = {
			id: cartSessionId,
			created: moment().toISOString(),
			startLocationId,
			endLocationId,
			channel: 'ONLINE',
			includedVariantIds: allVariantIds,
			skuIds: allSkuIds,
			items: cartItems.flatMap((item) =>
				item.products
					.filter((p) => isSalesProduct(p.product))
					.map((productWithQuantity) => {
						const { quantity, product } = productWithQuantity;
						const productApiId = product.productApiId;
						const variantIds = product.summary.variantIds;
						const skuIds = product.summary.skuIds;
						return {
							quantity,
							variantIds,
							skuIds,
							productId: productApiId,
						};
					}),
			),
		};
		api().reservations.sales.doc(reservation.id).set(reservation);
	}

	return true;
});

export const removeReservation = createAsyncThunk<
	boolean,
	void,
	{
		dispatch: ReduxDispatch;
		state: ReduxState;
	}
>('Cart/REMOVE_RESERVATION', async (_, thunkAPI) => {
	const state = thunkAPI.getState();
	const cartSessionId = CartSelectors.sessionId(state);
	thunkAPI.dispatch(showReservationExpiredBanner(false));
	await api().reservations.doc(cartSessionId).delete();
	await api().reservations.sales.doc(cartSessionId).delete();
	return true;
});

export const clearDeliveryUnavailabilities = createAsyncThunk(
	'Cart/CLEAR_DELIVERY_UNAVAILABILITIES',
	async (_, thunkAPI) => {
		const state = thunkAPI.getState() as ReduxState;
		const items = CartSelectors.items(state);

		const newItems = removeDeliveryUnavailabilityFromCartProducts(items);
		thunkAPI.dispatch(updateCartItems(newItems));
	},
);

export const resetReservationCreated = createAction('Cart/REMOVE_RESERVATION_CREATED');

export const setDelivery = createAction<CartDelivery>('Cart/SET_DELIVERY');
export const removeDelivery = createAction('Cart/REMOVE_DELIVERY');
export const setDeliveryTimeslot = createAction<DeliveryTimeSlot | null>(
	'Cart/SET_DELIVERY_TIMESLOT',
);
export const setPickupTimeslot = createAction<DeliveryTimeSlot>('Cart/SET_PICKUP_TIMESLOT');
