import { useEffect } from 'react';

import { getIsRentleHostname } from 'common/utils/urls';
import { POST_MESSAGE_EVENTS } from 'hooks/useExternalAuth/constants';

import { createPostMessage } from './utils';

/**
 * Sometimes the external auth window that is opened redirects back to rentle.store root domain (usually after an error or back button press). In that case, the window should be closed.
 * This component handles that flow by checking if window was opened by another window, and it's not a custom domain. In that case we send a request to close this window
 */
const ExternalAuthWindowRootHandler = () => {
	const hostname = window.location.hostname;
	const isCustomDomain = !getIsRentleHostname(hostname);

	useEffect(() => {
		if (!!window.opener && !isCustomDomain) {
			window.opener.postMessage(
				createPostMessage({
					event: POST_MESSAGE_EVENTS.WINDOW_CLOSE,
					value: true,
				}),
				window.location.origin,
			);
		}
	}, [isCustomDomain]);

	return null;
};

export default ExternalAuthWindowRootHandler;
