import React from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { CalendarType } from 'react-calendar';

import { StartDay } from 'common/utils/dateUtils';

import CalendarWithStyles, { calendarDefaultStyles } from './CalendarWithStyles';

export interface BookingCalendarProps {
	startDate: Date | null;
	onStartDateChange: (date: Date) => void;
	durationInSeconds: number;
	calendarDate: Date;
	onCalendarDateChange: (date: Date) => void;
	weekStartDay?: StartDay;

	minDate?: Date;
	maxDate?: Date;
	disabled?: boolean;
	loading?: boolean;
	hideRangeSelection?: boolean;

	lowDates?: string[];
	unavailableDates?: string[];
	highlightedDates?: string[];
	disabledDates?: string[];
	locale: string;
}

const startDayToCalendarType: { [K in StartDay]: CalendarType } = {
	monday: 'ISO 8601',
	saturday: 'Arabic',
	sunday: 'US',
};

const BookingCalendar = (props: BookingCalendarProps) => {
	const classes = useStyles(props);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const {
		startDate,
		onStartDateChange,
		durationInSeconds,
		calendarDate,
		onCalendarDateChange,
		minDate,
		maxDate,
		weekStartDay = 'monday',
		loading = false,
		locale,
		hideRangeSelection,
	} = props;

	const monthNameFormatter = new Intl.DateTimeFormat(locale, { month: 'long', year: 'numeric' });

	const getRange = (startDate: Date, durationInSeconds: number): Date | [Date, Date] => {
		const endDate = new Date(startDate.getTime() + durationInSeconds * 1000);

		const startDateDate = `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`;
		const endDateDate = `${endDate.getFullYear()}-${endDate.getMonth()}-${endDate.getDate()}`;

		return startDateDate === endDateDate ? startDate : [startDate, endDate];
	};

	const value = startDate
		? !!hideRangeSelection
			? startDate
			: getRange(startDate, durationInSeconds)
		: null;

	const currentMonth = calendarDate;
	const nextMonthStart = new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1, 1);
	const nextMonthEnd = new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1, 27);
	const prevMonthEnd = new Date(calendarDate.getFullYear(), calendarDate.getMonth(), 0);

	const canSetNextMonth = maxDate ? nextMonthStart.getTime() < maxDate.getTime() : true;
	const canSetPrevMonth = minDate ? prevMonthEnd.getTime() > minDate.getTime() : true;

	const setNextMonth = () => {
		if (canSetNextMonth) {
			onCalendarDateChange(nextMonthEnd);
		}
	};

	const setPrevMonth = () => {
		if (canSetPrevMonth) {
			onCalendarDateChange(prevMonthEnd);
		}
	};

	const handleDateChange = (value: Date | Date[]) => {
		onStartDateChange(Array.isArray(value) ? value[0] : value);
	};

	return (
		<Box className={classNames(classes.wrapper, { [classes.loading]: props.disabled })}>
			<Box className={classes.navigation}>
				<IconButton
					className={classes.navigationButton}
					disabled={props.disabled || !canSetPrevMonth}
					onClick={setPrevMonth}
					size="large"
				>
					<ChevronLeftIcon />
				</IconButton>
				<Box className={classes.navigationContent}>
					<Typography variant="body1" className={classes.navigationMonthOne}>
						{monthNameFormatter.format(currentMonth)}
					</Typography>
					{!isMobile && (
						<Typography variant="body1" className={classes.navigationMonthTwo}>
							{monthNameFormatter.format(nextMonthStart)}
						</Typography>
					)}
				</Box>
				<IconButton
					className={classes.navigationButton}
					disabled={props.disabled || !canSetNextMonth}
					onClick={setNextMonth}
					size="large"
				>
					<ChevronRightIcon />
				</IconButton>
			</Box>
			<CalendarWithStyles
				value={value}
				onChange={handleDateChange}
				returnValue="start"
				activeStartDate={calendarDate}
				view="month"
				minDetail="month"
				showDoubleView={!isMobile}
				showNavigation={false}
				minDate={minDate}
				maxDate={maxDate}
				styles={{
					...calendarDefaultStyles,
					tileBase: {
						...calendarDefaultStyles.tileHighlighted,
					},
				}}
				tileDisabled={props.disabled || loading ? () => true : undefined}
				disableUnavailableDates
				highlightedDates={props.highlightedDates}
				lowDates={props.lowDates}
				unavailableDates={props.unavailableDates}
				disabledDates={props.disabledDates}
				calendarType={startDayToCalendarType[weekStartDay]}
				className={classes.calendarWrapper}
				locale={locale}
			/>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			transition: 'opacity 0.3s',
			opacity: 1,
			pointerEvents: ({ disabled }: BookingCalendarProps) => (disabled ? 'none' : 'auto'),
		},
		calendarWrapper: {
			pointerEvents: ({ loading }: BookingCalendarProps) => (loading ? 'none' : 'auto'),
		},
		loading: {
			opacity: 0.3,
		},
		navigation: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			width: '100%',
			padding: theme.spacing(2, 0),
		},
		navigationButton: {
			padding: theme.spacing(1),
		},
		navigationContent: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center',
			fontSize: '1.4rem',
			[theme.breakpoints.up('sm')]: {
				justifyContent: 'flex-start',
				flexDirection: 'row',
			},
		},
		navigationMonthOne: {
			flex: 1,
			fontWeight: 500,
			[theme.breakpoints.up('sm')]: {
				paddingRight: '40px',
			},
			textTransform: 'capitalize',
		},
		navigationMonthTwo: {
			flex: 1,
			fontWeight: 500,
			[theme.breakpoints.up('sm')]: {
				paddingLeft: '40px',
			},
			textTransform: 'capitalize',
		},
	}),
);

export default BookingCalendar;
