import React from 'react';

import { Box, Typography } from '@mui/material';

import { ResponsiblePersonDetails } from 'common/types';
import { useTranslation } from 'services/localization';

interface Props {
	responsiblePerson: ResponsiblePersonDetails;
}
const CustomerInfo = (props: Props) => {
	const { responsiblePerson } = props;
	const { t } = useTranslation();

	return (
		<Box>
			<Typography variant="body2" gutterBottom>
				{t('common:form.contactInformation', 'Contact information')}
			</Typography>
			<Typography variant="subtitle2">
				{responsiblePerson?.firstName} {responsiblePerson?.lastName}
			</Typography>
			<Typography variant="subtitle2">{responsiblePerson?.email}</Typography>
			<Typography variant="subtitle2">{responsiblePerson?.phone}</Typography>
		</Box>
	);
};

export default CustomerInfo;
