import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'redux/hooks';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import { getTranslation } from 'common/modules/translations';
import { isNormalLiftTicketProduct } from 'common/utils/liftTicketUtils';
import { hasProductType } from 'utils/products';
import { sortByOrderIndex } from 'utils/sorting';

import ProductsGrid from './ProductsGrid';

interface Props {
	categoryId: string;
	hideLabel?: boolean;
}

const Category = (props: Props) => {
	const { categoryId, hideLabel } = props;
	const classes = useStyles();
	const language = useSelector(ViewSelectors.language);
	const visibleProducts = useSelector(StockSelectors.visibleProducts);
	const categoriesById = useSelector(StockSelectors.categoriesById);
	const productType = useSelector(ViewSelectors.filtersProductType);

	const category = categoriesById[categoryId];
	const categoryProducts = visibleProducts.filter(
		(p) =>
			!isNormalLiftTicketProduct(p) &&
			p.categoryIds?.includes(categoryId) &&
			hasProductType(p, productType),
	);
	const sortedProducts = sortByOrderIndex(categoryProducts);

	if (!category) {
		return null;
	}

	return (
		<Box mb={3}>
			{!hideLabel && (
				<Typography variant="body2" className={classes.label}>
					{getTranslation(category.name, language)}
				</Typography>
			)}
			<ProductsGrid products={sortedProducts} categoryId={categoryId} />
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			fontWeight: 500,
			marginBottom: theme.spacing(1),
			textTransform: 'uppercase',
		},
	}),
);

export default Category;
