import admin from './admin.json';
import common from './common.json';
import consumer from './consumer.json';
import inStore from './in-store.json';
import online from './online.json';
import rentallink from './rentallink.json';
import server from './server.json';

export const rentalLinkLocales = {
	rentallink,
	common,
};

export const inStoreLocales = {
	'in-store': inStore,
	common,
};

export const onlineLocales = {
	online,
	common,
};

export const consumerLocales = {
	consumer,
	common,
};

export const serverLocales = {
	server,
	common,
};

export const adminLocales = {
	admin,
	common,
};

export const internalAdminLocales = {
	common,
};
