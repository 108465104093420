import { OrderProduct, Shopper } from 'common/types';
import { notNullish, notUndefined } from 'common/utils/common';
import { isLiftTicketProduct } from 'common/utils/liftTicketUtils';

import { getUnchosenProducts } from './productUtils';

export const getUniqueSegments = (segments: string[]) =>
	segments.filter((v, i, a) => a.indexOf(v) === i);

export const getProductSegments = (products: OrderProduct[]): string[] =>
	products.map((a) => a.segment).filter(notNullish);

export const getUnchosenProductSegments = (shoppers: Shopper[], products: OrderProduct[]) => {
	const unchosenProducts = getUnchosenProducts(shoppers, products).filter((s) => !!s.segment);
	const productSegments = getProductSegments(unchosenProducts);
	return getUniqueSegments(productSegments);
};

export const getShopperSegmentFromProducts = (
	shopper: Shopper,
	products: OrderProduct[],
): string | null => {
	const foundSegment = products
		.filter((p) => p.shopperId === shopper.id && !!p.segment && !isLiftTicketProduct(p))
		.map((p) => p.segment)[0];
	return foundSegment === undefined ? null : foundSegment;
};

export const getSkidataSegmentFromShopperProducts = (
	shopper: Shopper,
	products: OrderProduct[],
): string | null => {
	const foundSkidataSegment = products
		.filter((p) => p.shopperId === shopper.id && !!p.segment && isLiftTicketProduct(p))
		.map((p) => p.externalSegment?.externalSegmentId)
		.filter(notUndefined)[0];
	return foundSkidataSegment === undefined ? null : foundSkidataSegment;
};

export const segmentsByCount = (segments: string[]) =>
	segments.reduce((acc, segment) => {
		acc[segment] = !!acc[segment] ? acc[segment].concat(segment) : [segment];
		return acc;
	}, {});

export const areProductsForSegmentAvailable = (
	productSegments: string[],
	chosenUserSegments: string[],
	segment: string,
) => {
	const productSegmentsByCount = segmentsByCount(productSegments);
	const userSegmentsByCount = segmentsByCount(chosenUserSegments);

	return (
		productSegmentsByCount[segment] &&
		userSegmentsByCount[segment] &&
		productSegmentsByCount[segment].length <= userSegmentsByCount[segment].length
	);
};
