import React, { useEffect, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

import Container from 'common/components/Container';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { stringQueryParam } from 'common/utils/browserUtils';
import { isDevEnv } from 'common/utils/common';
import { POST_MESSAGE_EVENTS } from 'hooks/useExternalAuth/constants';
import { useTranslation } from 'services/localization';

import { createPostMessage, isLogOutPath } from './utils';

const WAIT_FOR_AUTO_CLOSE_MS = 2000;

const ExternalAuthWindowHandler = () => {
	const { t } = useTranslation();
	const { search, pathname } = useLocation();
	const queryString = new URLSearchParams(search);
	const oauthCode = stringQueryParam(queryString.get('code'));
	const oauthState = stringQueryParam(queryString.get('state'));
	const error = stringQueryParam(queryString.get('error'));
	const logoutPath = isLogOutPath(pathname);
	const [showClosingInstructions, setShowClosingInstructions] = useState<boolean>(false);

	const renderManualWindowClose = showClosingInstructions && !!window.opener;

	// window.postMessage needs a targetOrigin as param, which can be * to indicate any, or a specific URL
	// In DEV environment, we want to allow any origin, as testing some auth window handlers requires this
	const postMessageTargetOrigin = isDevEnv() ? '*' : window.location.origin;

	const closeWindow = () => {
		const hostWindow = window.opener;
		window.focus();
		window.close();
		if (!!hostWindow) {
			// Some iOS versions return error when trying to focus host window
			try {
				hostWindow.focus();
			} catch (e) {}
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowClosingInstructions(true);
		}, WAIT_FOR_AUTO_CLOSE_MS);
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		if (logoutPath && window.opener) {
			window.opener.postMessage(
				createPostMessage({ event: POST_MESSAGE_EVENTS.LOGOUT, value: true, state: oauthState }),
				postMessageTargetOrigin,
			);
		}
	}, [logoutPath, oauthState, postMessageTargetOrigin]);

	useEffect(() => {
		const handleOauthLogin = async (code: string) => {
			try {
				window.opener.postMessage(
					createPostMessage({
						event: POST_MESSAGE_EVENTS.AUTH_CODE,
						value: code,
						state: oauthState,
					}),
					postMessageTargetOrigin,
				);
			} catch (e) {
				errorHandler.report(e);
				handleOauthError();
			}
		};

		const handleOauthError = () => {
			window.opener.postMessage(
				createPostMessage({
					event: POST_MESSAGE_EVENTS.AUTH_CODE_ERROR,
					value: 'Error signing in',
					state: oauthState,
				}),
				postMessageTargetOrigin,
			);
		};
		if (!window.opener) return;
		if (oauthCode && !error) {
			handleOauthLogin(oauthCode);
		} else if (error) {
			handleOauthError();
		}
	}, [error, oauthCode, oauthState, postMessageTargetOrigin]);

	/**
	 * This window should always close automatically after a successful auth postMessage is sent (this is handled in useExternalAuth hook)
	 * In case of an unexpected error, we render instructions for the user to close this window manually - there is no reason this window should stay rendered in normal scenario
	 */
	return !renderManualWindowClose ? null : (
		<Container>
			<Box
				mt={8}
				display="flex"
				alignItems="center"
				justifyContent="center"
				textAlign="center"
				flexDirection="column"
			>
				<Typography variant="h6">{t('auth.authCompleted', 'Authentication completed')}</Typography>
				<Typography sx={{ mt: 5 }}>
					{t(
						'auth.canCloseWindow',
						'You can close this window by pressing the button below or closing the browser tab',
					)}
					.
				</Typography>
				<Button sx={{ mt: 4 }} variant="outlined" onClick={closeWindow}>
					{t('common:actions.close')}
				</Button>
			</Box>
		</Container>
	);
};

export default ExternalAuthWindowHandler;
