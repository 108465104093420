import React, { useEffect } from 'react';

import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { sendEventToParentPage } from 'common/modules/embed';
import { isRunInIframe } from 'common/utils/browserUtils';

const isEmbedded = isRunInIframe();

interface Props {
	open: boolean;
	onClose: () => void;
}
const KeycardInfoDialog = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { open, onClose } = props;

	useEffect(() => {
		if (open && isEmbedded) {
			sendEventToParentPage({
				origin: 'rentle',
				type: 'trigger-scroll',
				payload: {
					offset: 0,
				},
			});
		}
	}, [open]);

	return (
		<Dialog
			open={open}
			onClose={onClose}
			classes={{
				container: isEmbedded ? classes.alignTop : '',
			}}
		>
			<DialogTitle>
				<Typography variant="h6" gutterBottom>
					{t('tickets.whatIsKeycardTitle', 'What is a Skidata keycard?')}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body2" gutterBottom>
					{t(
						'tickets.whatIsKeycardContent',
						'Keycard is a reloadable card on which, you can load lift tickets. Keycard is recognizable based on the ID-number on the backside of the card. Keycard is valid in all resorts, which have Skidata system.',
					)}
				</Typography>
				<br />
				<Typography variant="h6" gutterBottom>
					{t('tickets.whenStartsTitle', 'When will the lift ticket start to work?')}
				</Typography>
				<Typography variant="body2" gutterBottom>
					{t(
						'tickets.whenStartsContent1',
						'Lift tickets are uploaded automatically to existing keycard on the chosen start day at the lift gate on your first ride. Lift tickets work at the gate 20 minutes after your purchase.',
					)}
				</Typography>
				<Typography variant="body2" gutterBottom>
					{t(
						'tickets.whenStartsContent2',
						'A new keycard can be collected 20 minutes after the purchase from the ticket booth.',
					)}
				</Typography>
			</DialogContent>
		</Dialog>
	);
};

const useStyles = makeStyles((theme) => ({
	alignTop: {
		alignItems: 'flex-start',
	},
}));

export default KeycardInfoDialog;
