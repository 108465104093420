import React from 'react';

import { useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';

import { isCancellationPolicyDefined } from 'common/utils/cancellations';
import CancellationPolicy from 'components/CancellationPolicy';

const Terms = () => {
	const shopInfo = useSelector(ShopSelectors.shopPublicInfo).data;
	const startDate = useSelector(CartSelectors.startDate);
	const shopCancellationPolicy = shopInfo?.cancellationPolicy;

	if (!startDate || !shopCancellationPolicy || !isCancellationPolicyDefined(shopCancellationPolicy))
		return null;

	return <CancellationPolicy startDate={startDate} cancellationPolicy={shopCancellationPolicy} />;
};

export default Terms;
