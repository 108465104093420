import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme, darken, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
	RiAlertLine,
	RiCheckboxCircleLine,
	RiErrorWarningLine,
	RiInformationLine,
} from 'react-icons/ri';

export interface BannerProps {
	variant: 'info' | 'warning' | 'danger' | 'success';
	title?: string;
	description?: string | JSX.Element;
	noIcon?: boolean;
	secondary?: boolean;
}
const Banner = (props: BannerProps) => {
	const classes = useStyles(props);
	const theme = useTheme();

	const renderIcon = () => {
		switch (props.variant) {
			case 'info':
				return <RiInformationLine color={theme.palette.info.main} />;
			case 'warning':
				return <RiAlertLine color={theme.palette.warning.main} />;
			case 'danger':
				return <RiErrorWarningLine color={theme.palette.error.main} />;
			case 'success':
				return <RiCheckboxCircleLine color={theme.palette.success.main} />;
			default:
				return null;
		}
	};

	const getBorderColor = () => {
		switch (props.variant) {
			case 'info':
				return theme.palette.info.main;
			case 'warning':
				return theme.palette.warning.main;
			case 'danger':
				return theme.palette.error.main;
			case 'success':
				return theme.palette.success.main;
			default:
				return undefined;
		}
	};

	return (
		<Box className={classes.leftBorder} style={{ borderLeftColor: getBorderColor() }}>
			<Box className={classes.wrapper}>
				{!props.noIcon ? <Box className={classes.iconWrapper}>{renderIcon()}</Box> : <Box mr={2} />}
				<Box className={classes.textWrapper}>
					{!!props.title && (
						<Typography className={classes.title} gutterBottom={!!props.description}>
							{props.title}
						</Typography>
					)}
					{!!props.description &&
						(typeof props.description === 'string' ? (
							<Typography className={classes.description}>{props.description}</Typography>
						) : (
							props.description
						))}
				</Box>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		leftBorder: {
			borderLeft: (props: BannerProps) => (props.secondary ? 'none' : 'solid 4px #ececec'),
		},
		wrapper: {
			display: 'flex',
			flexDirection: 'row',
			backgroundColor: (props: BannerProps) => (props.secondary ? 'transparent' : 'white'),
			border: (props: BannerProps) => (props.secondary ? 'none' : 'solid 1px #ececec'),
		},
		iconWrapper: {
			padding: theme.spacing(2),
			paddingTop: theme.spacing(2.2),
			fontSize: '26px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			color: darken('#ebe8f3', 0.1),
		},
		textWrapper: {
			alignSelf: 'center',
			padding: theme.spacing(2, 2, 2, 0),
		},
		title: {
			color: theme.palette.text.primary,
			fontSize: (props: BannerProps) => (props.noIcon ? '1.4rem' : '1.6rem'),
			fontWeight: 500,
		},
		description: {
			color: theme.palette.text.secondary,
			fontSize: '1.4rem',
		},
	}),
);

export default Banner;
