import React from 'react';

import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import AboutContainer from 'common/components/AboutContainer';
import CustomContentContainer from 'common/components/customizer/CustomContentContainer';
import CenteredContent from 'components/CenteredContent';
import LocationDetailContainer from 'components/LocationDetailContainer';
import { useTranslation } from 'services/localization';

const AboutPage = () => {
	const { t } = useTranslation();
	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const layout = useSelector(ShopSelectors.onlineLayout);
	const language = useSelector(ViewSelectors.language);
	const about = layout?.about;
	const customContent = about?.customContent;

	if (!activeLocation) return null;

	return (
		<CenteredContent>
			<Box py={7}>
				<AboutContainer about={about} language={language} t={t}>
					<LocationDetailContainer location={activeLocation} variant="contact" />
				</AboutContainer>
			</Box>
			{!!customContent && (
				<Box>
					<CustomContentContainer customContent={customContent} language={language} />
				</Box>
			)}
		</CenteredContent>
	);
};

export default AboutPage;
