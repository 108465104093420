import { initAnalytics } from './analytics';
import { connectAuthEmulator } from './auth';
import { connectFirestoreEmulator } from './firestore';
import { connectFunctionsEmulator } from './functions';
import { initPerformance } from './perf';
import { connectStorageEmulator } from './storage';

initPerformance();
initAnalytics();
/**
 * Do not use Firestore persistence, does not bring value to us currently,
 * local queries are too slow
 * */
// setDbPersistence();

const useEmulator = process.env.REACT_APP_USE_EMULATOR === 'true';
const useLocalSkidata = process.env.REACT_APP_USE_SKIDATA_LOCAL === 'true';
export const useFunctionsEmulator =
	process.env.REACT_APP_USE_FUNCTIONS_EMULATOR === 'true' ||
	process.env.REACT_APP_USE_LOCAL_SERVER === 'true';

if (useEmulator || useLocalSkidata || useFunctionsEmulator) {
	connectFunctionsEmulator();
}

if (useEmulator) {
	connectFirestoreEmulator();
}

// Comment out in case auth is emulated also
if (useEmulator) {
	connectAuthEmulator();
}

if (useEmulator) {
	connectStorageEmulator();
}
