import React from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RiArrowRightLine } from 'react-icons/ri';

import Spinner from 'common/components/Spinner';

interface Props {
	onClick?: () => void;
	disabled?: boolean;
	loading?: boolean;
}
const TopCheckoutButton = (props: Props) => {
	const { t } = useTranslation();
	const { disabled, loading, onClick } = props;

	return (
		<Button
			variant="outlined"
			color="primary"
			onClick={onClick}
			disabled={disabled || loading}
			endIcon={loading ? null : <RiArrowRightLine />}
			fullWidth
			sx={{ padding: (theme) => theme.spacing(1.5, 2) }}
		>
			{loading ? <Spinner padding={12} /> : t('common:actions.checkout', 'Checkout')}
		</Button>
	);
};

export default TopCheckoutButton;
