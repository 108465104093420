import React from 'react';

import { Box, Button, Theme, Typography, darken } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

import Spinner from 'common/components/Spinner';

interface Props {
	onClick: () => void;
	loading: boolean;
	error?: string;
	label: string;
	Icon?: JSX.Element;
	disabled?: boolean;
}

const PaymentButton = ({ onClick, loading, error, label, Icon, disabled }: Props) => {
	const classes = useStyles();
	return (
		<>
			<div className={classes.buttonContainer}>
				{!!error && (
					<Box mb={2}>
						<Typography variant="body1" color="error" align="center">
							{error || ''}
						</Typography>
					</Box>
				)}
				<Button
					variant="contained"
					color="primary"
					className={classNames(classes.btn, classes.successColor)}
					onClick={onClick}
					disabled={loading || disabled}
					startIcon={!!Icon ? Icon : undefined}
				>
					{loading ? (
						<Spinner relative size={32} />
					) : (
						<Typography variant="body1" className={classes.weight500}>
							{label}
						</Typography>
					)}
				</Button>
			</div>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		buttonContainer: {
			width: '100%',
			bottom: 0,
			left: 0,
			margin: theme.spacing(4, 0),
			backgroundColor: '#fff',
			textAlign: 'center',
		},
		btn: {
			width: '100%',
			minWidth: 200,
			height: 50,
			borderRadius: 0,
			paddingTop: 12,
			paddingBottom: 12,
			textTransform: 'capitalize',
		},
		successColor: {
			backgroundColor: '#008609',
			color: theme.palette.success.contrastText,
			'&:hover': {
				backgroundColor: darken('#008609', 0.15),
			},
		},
		weight500: {
			fontWeight: 500,
		},
	}),
);

export default PaymentButton;
