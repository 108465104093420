import React, { useEffect, useMemo, useState } from 'react';

import { ListItemText, MenuItem, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { TFunction } from 'i18next';

import {
	Measurement,
	Option,
	SizeChart,
	SizeChartType,
	UnitSystemType,
	UnitSystems,
	UserDetailName,
	UserDetailNames,
	UserProperty,
} from 'common/types';
import { getUserDetailTranslation } from 'common/utils/translations';
import {
	getConvertedUserDetails,
	getDetailOptionDescription,
	getDetailOptionLabel,
	getLocalInputFromMeasurement,
	getMetricInputFromUserProperty,
	getUserDetailOptions,
	isMeasurementDetail,
	justifySizeChartByUnitSystem,
} from 'common/utils/userDetails';

interface Props {
	detail: UserDetailName;
	unitSystem: UnitSystemType;
	userProperty?: UserProperty;
	onChange: (userProperty: UserProperty | null) => void;
	helperText?: string;
	error?: boolean;
	t: TFunction;
}

const UserDetailField = (props: Props) => {
	const { detail, unitSystem, userProperty, onChange, t, helperText, error } = props;
	const classes = useStyles();

	const metricMeasurement = userProperty && getMetricInputFromUserProperty(userProperty);
	const convertedValue = useMemo(
		() => getConvertedUserDetails(detail, metricMeasurement, unitSystem),
		[detail, metricMeasurement, unitSystem],
	);
	const inputSizeChart = justifySizeChartByUnitSystem(detail, convertedValue, unitSystem);

	const [sizeChart, setSizeChart] = useState<SizeChartType | undefined>(inputSizeChart);

	const options = useMemo(() => getUserDetailOptions(detail, { unitSystem, sizeChart }), [
		detail,
		unitSystem,
		sizeChart,
	]);
	const showSizeChart =
		detail === UserDetailNames.shoeSize &&
		((unitSystem === UnitSystems.US && sizeChart && sizeChart !== SizeChart.ADULTS) ||
			(unitSystem === UnitSystems.UK &&
				(sizeChart === SizeChart.KIDS || sizeChart === SizeChart.ADULTS)));

	const sizeChartOptions: Option<SizeChartType>[] =
		unitSystem === UnitSystems.US
			? [
					{ value: SizeChart.MEN, label: t('common:userDetails.men', 'Men') },
					{ value: SizeChart.WOMEN, label: t('common:userDetails.women', 'Women') },
					{ value: SizeChart.KIDS, label: t('common:userDetails.kids', 'Kids') },
			  ]
			: [
					{ value: SizeChart.ADULTS, label: t('common:userDetails.adults', 'Adults') },
					{ value: SizeChart.KIDS, label: t('common:userDetails.kids', 'Kids') },
			  ];

	useEffect(() => {
		if (unitSystem === UnitSystems.US || unitSystem === UnitSystems.UK) {
			setSizeChart(inputSizeChart);
		}
	}, [unitSystem, inputSizeChart]);

	const handleDetailChange = (value: string) => {
		if (!value || !options) {
			return;
		}
		const input: Measurement = { value, unit: options.unit, sizeChart };
		const metricDetail = getConvertedUserDetails(detail, input, UnitSystems.METRIC);
		const localInput = getLocalInputFromMeasurement(input);
		const newUserProperty: UserProperty = {
			value: metricDetail.value,
			unit: metricDetail.unit,
			...(isMeasurementDetail(detail) && { localInput }),
		};
		onChange(newUserProperty);
	};

	const handleSizeChartChange = async (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	) => {
		const value = e.target.value as SizeChartType;
		if (convertedValue.value || convertedValue.value === '') {
			await onChange(null);
		}
		setSizeChart(value);
	};

	const renderSizeCharts = () => (
		<TextField
			select
			value={sizeChart}
			variant="standard"
			onChange={handleSizeChartChange}
			className={classes.sizeChart}
			InputProps={{
				disableUnderline: true,
			}}
		>
			{sizeChartOptions.map((option) => (
				<MenuItem value={option.value} key={option.value}>
					<Typography variant="body2">{option.label}</Typography>
				</MenuItem>
			))}
		</TextField>
	);

	if (!options) return null;

	return (
		<TextField
			id={detail}
			label={getUserDetailTranslation(detail, t)}
			select
			fullWidth
			required
			value={convertedValue.value}
			onChange={(e) => handleDetailChange(e.target.value)}
			SelectProps={{
				classes: {
					outlined: showSizeChart ? classes.shoeSizeSelect : undefined,
				},
				MenuProps: {
					className: classes.menu,
				},
				inputProps: {
					required: true,
				},
				renderValue: (value) =>
					getDetailOptionLabel(
						detail,
						{ value: value as string, unit: options.unit, sizeChart },
						t,
					),
			}}
			helperText={helperText}
			error={error}
			type="text"
			variant="outlined"
			InputProps={{
				classes: {
					adornedEnd: classes.adornedEnd,
				},
				startAdornment: showSizeChart ? renderSizeCharts() : null,
			}}
		>
			{options.values.map((option: string) => (
				<MenuItem key={option} value={option}>
					<ListItemText
						primary={getDetailOptionLabel(
							detail,
							{ value: option, unit: options.unit, sizeChart },
							t,
						)}
						secondary={getDetailOptionDescription(
							detail,
							{ value: option, unit: options.unit, sizeChart },
							t,
						)}
					/>
				</MenuItem>
			))}
		</TextField>
	);
};

const useStyles = makeStyles((theme) => ({
	menu: {
		width: 200,
	},
	adornedEnd: {
		paddingRight: theme.spacing(0.875),
	},
	sizeChart: {
		display: 'contents',
	},
	shoeSizeSelect: {
		paddingLeft: theme.spacing(1.75),
		marginLeft: theme.spacing(0.5),
		borderLeft: `1px solid rgba(0,0,0,0.23)`,
		borderRadius: 0,
	},
}));

export default UserDetailField;
