import React, { useMemo, useState } from 'react';

import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	IconButton,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { RiQuestionLine } from 'react-icons/ri';
import { useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import { RadioGroupOption } from 'common/components/RadioGroup';
import Banner from 'common/components/states/Banner';
import { SkidataKeycardType } from 'common/modules/skidata';
import { getPricingString } from 'common/utils/common';
import ErrorText from 'components/ErrorText';
import KeycardInfoDialog from 'components/KeycardInfoDialog';
import { useTranslation } from 'services/localization/useTranslation';

export interface KeycardSelectorProps {
	value: boolean;
	onChange: (value: boolean) => void;
	keycardCount?: number;
	error?: string | null;
}

const KeycardSelector = React.memo((props: KeycardSelectorProps) => {
	const { value, onChange, error, keycardCount } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const [keycardDialogOpen, setKeycardDialogOpen] = useState<boolean>(false);

	const currency = useSelector(ShopSelectors.shopCurrency);
	const keycardProduct = useSelector(StockSelectors.keycardProduct);
	const keycardTypeFromCart = useSelector(CartSelectors.keycardTypeFromCart);

	const keycardPrice = keycardProduct?.rentals?.basePrice ?? 0;

	const keycardOptions: RadioGroupOption[] = useMemo(() => {
		const hasMultipleKeycards = !!keycardCount && keycardCount > 1;
		return [
			{
				value: SkidataKeycardType.NEW,
				disabled: keycardTypeFromCart === SkidataKeycardType.EXISTING,
				label: hasMultipleKeycards
					? `${t('tickets.needNewKeycardMany', {
							price: getPricingString(keycardPrice * (keycardCount ?? 1), currency),
							defaultValue: 'I need new KeyCards (+{{price}})',
					  })}`
					: `${t('tickets.needNewKeycard', 'I need a new keycard')} (+${getPricingString(
							keycardPrice,
							currency,
					  )})`,
			},
			{
				value: SkidataKeycardType.EXISTING,
				disabled: keycardTypeFromCart === SkidataKeycardType.NEW,
				label: hasMultipleKeycards
					? t('tickets.haveKeycardMany', {
							keycardCount,
							defaultValue: 'I have {{keycardCount}} KeyCards',
					  })
					: t('tickets.haveKeycard', 'I have a keycard'),
				description: hasMultipleKeycards
					? t(
							'tickets.keycardInsertAfterPaymentMultiple',
							"You'll insert the keycard numbers after payment",
					  )
					: t(
							'tickets.keycardInsertAfterPayment',
							'You’ll insert the keycard number after payment',
					  ),
			},
		];
	}, [currency, keycardPrice, keycardTypeFromCart, keycardCount, t]);

	const handleHasKeycardChange = (value: string) => {
		if (!!keycardTypeFromCart && keycardTypeFromCart !== value) return;

		const updatedWithNewKeycard = value === SkidataKeycardType.NEW;
		onChange(updatedWithNewKeycard);
	};

	const renderInfoButton = () => (
		<>
			<IconButton onClick={() => setKeycardDialogOpen(true)}>
				<RiQuestionLine />
			</IconButton>
			<KeycardInfoDialog open={keycardDialogOpen} onClose={() => setKeycardDialogOpen(false)} />
		</>
	);

	const renderKeycardAlert = () => (
		<Box mt={1}>
			<Banner
				variant="info"
				title={
					keycardTypeFromCart === SkidataKeycardType.NEW
						? t(
								'tickets.keycardTypeWarningExisting',
								'If you want to add lift ticket with an existing Keycard, please, create a separate booking.',
						  )
						: t(
								'tickets.keycardTypeWarningNew',
								'If you want to add lift ticket with a new Keycard, please, create a separate booking.',
						  )
				}
			></Banner>
		</Box>
	);

	const renderKeycardRadioGroup = () => (
		<FormControl fullWidth>
			<Box mt={2} className={classes.keycardBox}>
				<FormLabel className={classes.fieldLabel}>
					{t('tickets.keycardBySkidata', 'Keycard by Skidata')}
				</FormLabel>
				{renderInfoButton()}
			</Box>
			{error && <ErrorText>{error}</ErrorText>}
			<Box mt={1} />
			<RadioGroup
				value={value ? SkidataKeycardType.NEW : SkidataKeycardType.EXISTING}
				onChange={(_, newValue) => handleHasKeycardChange(newValue)}
			>
				{keycardOptions.map((option) => (
					<FormControlLabel
						className={classNames(classes.radioLabel, {
							[classes.radioLabelActive]:
								option.value === (value ? SkidataKeycardType.NEW : SkidataKeycardType.EXISTING),
						})}
						key={option.value}
						value={option.value}
						control={<Radio color="default" className={classes.radio} />}
						disabled={option.disabled}
						label={
							<>
								<Typography variant="body2">{option.label}</Typography>
								{option.description && (
									<Typography variant="body2" color={option.disabled ? 'inherit' : 'secondary'}>
										{option.description}
									</Typography>
								)}
							</>
						}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);

	return (
		<Box>
			{renderKeycardRadioGroup()}
			{!!keycardTypeFromCart && renderKeycardAlert()}
		</Box>
	);
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		infoTitle: {
			lineHeight: 'normal',
			marginBottom: theme.spacing(2),
		},
		infoContent: {
			fontFamily: '"Nunito Sans", "CircularStd", "Helvetica", "Arial", sans-serif',
			lineHeight: 1.71,
			marginBottom: theme.spacing(2),
		},
		radio: {
			color: theme.palette.common.black,
		},
		radioLabel: {
			border: `2px solid #dcdcdc`,
			margin: theme.spacing(1, 0),
			padding: theme.spacing(1, 0),
		},
		radioLabelActive: {
			border: `2px solid ${theme.palette.common.black}`,
		},
		keycardBox: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		fieldLabel: {
			color: theme.palette.text.primary,
			fontWeight: 500,
		},
		alignTop: {
			alignItems: 'flex-start',
		},
	}),
);

export default KeycardSelector;
