import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { Languages, OrderProduct } from 'common/types';
import { isLiftTicketProduct } from 'common/utils/liftTicketUtils';
import { getProductNameWithVariant } from 'common/utils/productUtils';
import { getSkidataSegmentTranslation } from 'common/utils/segments';
import { getSegmentTranslation } from 'common/utils/translations';
import { useTranslation } from 'services/localization/useTranslation';

interface Props {
	shoppersWithoutProducts: string[];
	unchosenProducts: OrderProduct[];
	lang: Languages;
	addShopperAndGoToDetails: () => void;
	hasSubmitError: boolean;
}

const ErrorBanner = (props: Props) => {
	const classes = useStyles();
	const {
		shoppersWithoutProducts,
		unchosenProducts,
		lang,
		addShopperAndGoToDetails,
		hasSubmitError,
	} = props;
	const notEnoughProductsForShoppers = shoppersWithoutProducts.length > 0;
	const someProductsNotAssigned = unchosenProducts.length > 0;
	const { t, language } = useTranslation();

	const renderNoProductsLeft = (shoppers: string[]) => (
		<Typography variant="body2">
			{t('shopperInfo.errorBannerNoProductsLeft', 'Whoops. You do not have products left for')}{' '}
			{shoppers.map((name, idx) => (
				<React.Fragment key={idx}>
					{name}
					{idx === shoppers.length - 1 ? '.' : ', '}
				</React.Fragment>
			))}
		</Typography>
	);

	const renderErrorProducts = (products: OrderProduct[]) => (
		<ul>
			{products.map((p) => {
				if (p.ticketId) {
					return null;
				}
				const productNameWithVariant = getProductNameWithVariant(p, lang);

				const segmentText = !!p.segment
					? ` (${getSegmentTranslation(p.segment, t)})`
					: isLiftTicketProduct(p)
					? ` (${getSkidataSegmentTranslation(p.externalSegment, language)})`
					: null;

				const productName = !!segmentText
					? `${productNameWithVariant}${segmentText}`
					: `${productNameWithVariant}`;
				return (
					<li key={p.id}>
						<Typography variant="body2">{productName}</Typography>
					</li>
				);
			})}
		</ul>
	);

	const renderUnassignedProducts = (products: OrderProduct[]) => (
		<>
			<Typography className={classes.unassignedProductsText} variant="body2">
				{t(
					'shopperInfo.errorBannerUnassignedProducts',
					'The following products still need to be assigned to someone:',
				)}
			</Typography>
			{renderErrorProducts(products)}
			<div className={classes.errorBannerButtonContainer}>
				<Button
					variant="outlined"
					color="primary"
					size="small"
					className={classes.errorBannerButton}
					onClick={addShopperAndGoToDetails}
					startIcon={<AddIcon />}
				>
					{t('shopperInfo.errorBannerAddPerson', 'Add person')}
				</Button>
			</div>
		</>
	);

	const renderSubmitErrorText = () => (
		<Typography variant="body2">
			{t('shopperInfo.errorBannerSubmitError', 'Something went wrong, please try again.')}
		</Typography>
	);

	return (
		<div className={classes.errorBannerContainer}>
			<div className={classes.errorBannerLine} />
			<div className={classes.errorBannerContent}>
				{hasSubmitError
					? renderSubmitErrorText()
					: someProductsNotAssigned
					? renderUnassignedProducts(unchosenProducts)
					: notEnoughProductsForShoppers
					? renderNoProductsLeft(shoppersWithoutProducts)
					: null}
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		errorBannerContainer: {
			position: 'relative',
			width: '100%',
			minHeight: 70,
			backgroundColor: theme.palette.background.secondary,
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(3),
		},
		errorBannerContent: {
			padding: theme.spacing(2),
		},
		errorBannerButtonContainer: {
			display: 'flex',
			width: '100%',
		},
		errorBannerButton: {
			marginTop: theme.spacing(2),
			marginLeft: 'auto',
			marginRight: 'auto',
		},
		errorBannerLine: {
			position: 'absolute',
			height: '100%',
			width: 4,
			backgroundColor: theme.palette.primary.main,
		},
		errorBannerText: {
			marginTop: 'auto',
			marginBottom: 'auto',
			paddingRight: theme.spacing(3),
			paddingLeft: theme.spacing(3),
		},
		unassignedProductsText: {
			marginBottom: theme.spacing(1),
		},
	}),
);

export default ErrorBanner;
