import React from 'react';

import { useTranslation } from 'react-i18next';

import Banner from 'common/components/states/Banner';

export const NoPaymentMethods = () => {
	const { t } = useTranslation();
	return (
		<Banner
			variant="info"
			title={t('Checkout.noPaymentOptions', 'No payment options available')}
			description={t(
				'Checkout.noPaymentOptionsDescription',
				'The store has not added any payment options yet',
			)}
		/>
	);
};
