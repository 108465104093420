import { chain, intersectionWith, isEqual, isUndefined, transform } from 'lodash';
import moment from 'moment-timezone';

import {
	AvailabilityRange,
	getMinAvailabilityFromRange,
} from 'common/api/frontend/inventory_new/availabilityRange';
import { handleQuantityAndAvailabilityFetch } from 'common/api/frontend/inventory_new/getQuantitiesAndAvailabilities';
import { Callable } from 'common/frontend/callable';
import { doesDateRangeOverlap } from 'common/modules/atoms/dates';
import {
	getDeliveryInventoryBlockersAfter,
	getDeliveryTimeslotsForDate,
} from 'common/modules/delivery/utils';
import { OpeningHours, isStoreClosedForDay } from 'common/modules/openingHours';
import { isRentalPurchaseType } from 'common/modules/orders';
import {
	ByVariant,
	CartDelivery,
	CartProduct,
	Channel,
	DeliveryOption,
	DeliveryTimeSlot,
	ISOString,
	LocationWithContact,
	ProductApi,
} from 'common/types';
import { notNull } from 'common/utils/common';
import { isValidWeekday } from 'common/utils/dateUtils';
import {
	getEndDateWithInventoryBlockers,
	getOrderProductMaintenanceMinutes,
	getStartDateWithInventoryBlockers,
} from 'common/utils/productUtils';
import { CartRow } from 'services/types';

import { getVariantAvailabilityWithCart } from './cart';

export const getAvailableUnitsForRange = (args: {
	startTime: moment.Moment;
	endTime: moment.Moment;
	availabilityRanges: AvailabilityRange[];
}): number => {
	const { startTime, endTime } = args;
	const start = startTime.toISOString();
	const end = endTime.toISOString();
	const unitsOfOverlappingRanges = args.availabilityRanges
		.filter((range) => doesDateRangeOverlap(start, end, range.start, range.end))
		.map((range) => range.units ?? Infinity);
	if (unitsOfOverlappingRanges.length) {
		return Math.min(...unitsOfOverlappingRanges);
	}
	return 0;
};

export type InvalidStartTimeReason =
	| 'expired'
	| 'availability'
	| 'opening_hours'
	| 'order_limit'
	| 'booking_buffer';

export type AvailabilityWithReason =
	| { units: number; valid: true }
	| { units: number; valid: false; reason: InvalidStartTimeReason };
export interface AvailabilityByStartTime {
	// Format YYYY-MM-DD
	[date: string]: {
		// Format hh-mm
		[time: string]: AvailabilityWithReason;
	};
}

export interface CartAvailabilityOpts {
	shopLocations: LocationWithContact[];
	stockProducts: ProductApi[];
	shopId: string;
	reservationId?: string;
	salesChannel: Channel;
	selectedDeliveryOption: DeliveryOption | null;
	cartDelivery?: CartDelivery;
	openingHours: OpeningHours;
	timezone: string;
}
interface CartAvailabilitiesProps {
	cartRows: CartRow[];
	locationId: string;
	opts: CartAvailabilityOpts;
}

const getCartProductAvailabilityRangesByVariant = async (
	startDate: string,
	endDate: string,
	locationId: string,
	cartProduct: CartProduct,
	opts: CartAvailabilityOpts,
): Promise<ByVariant<AvailabilityRange[]>> => {
	const { shopLocations, stockProducts, shopId, reservationId, salesChannel } = opts;
	const stockProduct = stockProducts.find((s) => s.id === cartProduct.productApiId)!;
	const variantIds = cartProduct.summary.variantIds;
	const availabilityFetcher = getAvailabilityFetcherFunction(shopId);
	const { availabilityRangesByVariant, variantQuantities } = await availabilityFetcher({
		product: stockProduct,
		stockProducts,
		startDate,
		endDate,
		shopId,
		startLocationId: locationId,
		shopLocations,
		reservationId,
		salesChannel,
		purchaseType: cartProduct.purchaseType,
	});

	return Object.keys(availabilityRangesByVariant)
		.filter((variantId) => variantIds.includes(variantId))
		.reduce(
			(obj, variantId) => ({
				...obj,
				[variantId]: isRentalPurchaseType(cartProduct.purchaseType)
					? availabilityRangesByVariant[variantId]
					: getAvailableVariantUnitsFromQuantity(
							variantId,
							availabilityRangesByVariant,
							variantQuantities,
					  ),
			}),
			{},
		);
};

const getAvailableVariantUnitsFromQuantity = (
	variantId: string,
	availabilityRangesByVariant: ByVariant<AvailabilityRange[]>,
	variantQuantities: ByVariant<number | null>,
) =>
	availabilityRangesByVariant[variantId].map((a) => ({
		...a,
		units: variantQuantities[variantId],
	}));

export const getCartProductAvailabilities = async (
	props: CartAvailabilitiesProps,
): Promise<(ByVariant<AvailabilityRange[]>[] | null)[]> => {
	const { cartRows, locationId, opts } = props;
	const cartProductAvailabilities: (ByVariant<AvailabilityRange[]>[] | null)[] = await Promise.all(
		cartRows.map(async ({ products: cartProducts, startDate, endDate, purchaseType }) => {
			if (isRentalPurchaseType(purchaseType) && (!startDate || !endDate)) return null; // In case of lift tickets
			const cartProductAvailabilities = await Promise.all(
				cartProducts.map(async (p) => {
					const { product: cartProduct } = p;
					const { stockProducts, selectedDeliveryOption, cartDelivery } = opts;
					const stockProduct = stockProducts.find((s) => s.id === cartProduct.productApiId)!;

					const startDateWithInventoryBlockers = getStartDateWithInventoryBlockers({
						startDate,
						deliveryProps:
							!!selectedDeliveryOption && !!cartDelivery
								? {
										deliveryOption: selectedDeliveryOption,
										cartDelivery,
										timezone: opts.timezone,
										openingHours: opts.openingHours,
								  }
								: undefined,
					});
					const endDateWithInventoryBlockers = getEndDateWithInventoryBlockers(
						endDate,
						stockProduct,
						stockProducts,
					);

					const availabilityRangesByVariant = await getCartProductAvailabilityRangesByVariant(
						startDateWithInventoryBlockers,
						endDateWithInventoryBlockers,
						locationId,
						cartProduct,
						opts,
					);
					return availabilityRangesByVariant;
				}),
			);
			return cartProductAvailabilities;
		}),
	);
	return cartProductAvailabilities;
};

export const getCartProductsMaxAvailabilityCount = (args: {
	cartRows: CartRow[];
	cartProductAvailabilities: (ByVariant<AvailabilityRange[]>[] | null)[];
	stockProducts: ProductApi[];
}): number[][] => {
	const { cartRows, cartProductAvailabilities, stockProducts } = args;
	return cartRows.map(({ products: cartProducts, purchaseType }, rowIndex) => {
		const rowAvailabilitiesByVariant = cartProductAvailabilities[rowIndex];
		if (!rowAvailabilitiesByVariant) return [Infinity];
		return cartProducts.map((p, productIndex) => {
			const productAvailabilitesByVariant = rowAvailabilitiesByVariant[productIndex];
			const otherCartItems = cartRows
				.map((row, _rowIndex) => {
					return _rowIndex !== rowIndex
						? row
						: {
								...row,
								products: row.products.filter((p, _productIndex) => productIndex !== _productIndex),
						  };
				})
				.filter((o) => o.purchaseType === purchaseType);

			const availabilityRangesWithCurrentCart = getVariantAvailabilityWithCart(
				productAvailabilitesByVariant,
				otherCartItems,
				stockProducts,
				purchaseType,
			);
			const maxAvailability = Object.values(availabilityRangesWithCurrentCart).map((range) =>
				getMinAvailabilityFromRange(range),
			);
			const nonNullMaxAvailabilities = maxAvailability.filter(notNull);
			return nonNullMaxAvailabilities.length ? Math.min(...nonNullMaxAvailabilities) : Infinity;
		});
	});
};

const filterAvailablePickupSlots = (args: {
	availabilityRange: AvailabilityRange;
	date: string;
	deliveryOption: DeliveryOption;
	timezone: string;
}) => {
	const { availabilityRange, date, deliveryOption, timezone } = args;
	const rangeStartDate = moment(availabilityRange.start);
	const rangeEndDate = moment(availabilityRange.end);

	return getDeliveryTimeslotsForDate({
		date,
		deliveryOption,
		timezone,
	})?.filter((slot) => {
		return (
			moment(slot.startDate).isSameOrAfter(rangeStartDate) &&
			moment(slot.endDate).isSameOrBefore(rangeEndDate)
		);
	});
};

const isTimeslotAfterDeliveryTimeslot = (args: {
	timeslot: DeliveryTimeSlot;
	cartDelivery: CartDelivery | undefined;
}) => {
	const { timeslot, cartDelivery } = args;
	if (!cartDelivery || !cartDelivery.to?.timeslot?.endDate) return true;

	return moment(timeslot.endDate).isAfter(cartDelivery.to.timeslot.endDate);
};

export const combineAvailabilityRangesByMinQuantity = (
	availabilityRangesByVariant: ByVariant<AvailabilityRange[]>,
	variantIds: string[],
	quantity: number,
) => {
	const selectedVariantRanges = chain(availabilityRangesByVariant)
		.pick(variantIds)
		.values()
		.value()
		.flat();

	const key = 'rangeKey';
	return transform(
		selectedVariantRanges,
		(acc, range, idx) => {
			const { units } = range;
			if (units === null && !acc[key]) {
				acc[key] = range;
				return acc;
			}
			if (!units || units < quantity) return false;
			acc[key] =
				idx === 0
					? range
					: {
							start: acc[key].start ?? range.start,
							end: range.end,
							units: acc[key].units == null ? units : Math.min(units, acc[key].units),
					  };
			return acc;
		},
		{},
	)[key] as AvailabilityRange | undefined;
};

/*
 * Finds potential pickup slots for cart products by finding availabilities by:
 * A) If start date === end date => availability range from endDate time (- maitenance time) till the end of endDate day
 * B) If start date !== end date => availability range from start of endDate date day till the end of endDate day
 *
 * The Option B above to offer pickup slots that are before rental end time
 *
 * This function could be potentially combined with getCartAvailabilities
 */

export const getPotentialPickupSlotsFromCartProducts = async (args: {
	cartRows: CartRow[];
	locationId: string;
	selectedDeliveryOption: DeliveryOption;
	opts: CartAvailabilityOpts;
	timezone: string;
	cartDelivery?: CartDelivery;
}): Promise<DeliveryTimeSlot[] | undefined> => {
	const { cartRows, locationId, opts, selectedDeliveryOption, cartDelivery, timezone } = args;
	const cartPickupSlotPromises = cartRows.map(async ({ products: rowProducts }) => {
		const rowProductPickupSlots = rowProducts.map(async (p) => {
			const { product: cartProduct, quantity } = p;

			const isSameDayBooking = moment(cartProduct.startDate).isSame(
				moment(cartProduct.endDate),
				'date',
			);
			const isWithinOpeningHours = cartProduct.durationType === 'opening_hours';

			const maintenanceMinutes = getOrderProductMaintenanceMinutes(cartProduct, opts.stockProducts);

			const rangeStartDate = isSameDayBooking
				? isWithinOpeningHours
					? // Within opening hours duration should still show delivery times for the current day - so we get all the delivery slots after the start time of the rental
					  moment(cartProduct.startDate).add(1, 'ms').toISOString()
					: moment(cartProduct.endDate).toISOString() //Remove maintenance minutes as they would come after pickup
				: moment(cartProduct.endDate).startOf('day').toISOString();

			const bookingEndDateEndOfDay = moment(cartProduct.endDate!)
				.endOf('day') //Remove this line if we want to offer pickup slots only within rental duration
				.toISOString();

			const handlingMinutesAfter =
				!!selectedDeliveryOption && !!cartDelivery
					? getDeliveryInventoryBlockersAfter({
							deliveryOption: selectedDeliveryOption,
							pickupSlotOrEndDate: bookingEndDateEndOfDay,
							openingHours: opts.openingHours,
							timezone,
					  }).deliveryHandlingAfter ?? 0
					: 0;
			const rangeEndDate = moment(bookingEndDateEndOfDay)
				.add(handlingMinutesAfter, 'minutes')
				.add(maintenanceMinutes)
				.toISOString();

			const availabilityRangesByVariant = await getCartProductAvailabilityRangesByVariant(
				rangeStartDate,
				rangeEndDate,
				locationId,
				cartProduct,
				opts,
			);

			const combinedAvailabilityRange = combineAvailabilityRangesByMinQuantity(
				availabilityRangesByVariant,
				cartProduct.summary.variantIds,
				quantity,
			);

			if (!combinedAvailabilityRange) return undefined;

			const availablePickupSlots = filterAvailablePickupSlots({
				availabilityRange: combinedAvailabilityRange,
				date: bookingEndDateEndOfDay,
				deliveryOption: selectedDeliveryOption,
				timezone,
			})?.filter(
				(pickupslot) =>
					!isSameDayBooking ||
					isTimeslotAfterDeliveryTimeslot({
						timeslot: pickupslot,
						cartDelivery,
					}), //For same day bookings use only timeslots which are after delivery timeslots
			);
			if (!availablePickupSlots || availablePickupSlots.length === 0) return undefined;

			return availablePickupSlots;
		});
		return await Promise.all(rowProductPickupSlots);
	});

	const [cartPickupSlots] = await Promise.all(cartPickupSlotPromises);

	if (cartPickupSlots.some(isUndefined)) return undefined;

	const uniqueCartPickupSlots = intersectionWith(...cartPickupSlots, isEqual);

	return !!uniqueCartPickupSlots.length ? uniqueCartPickupSlots : undefined;
};

export const getIsClosedCalendarDates = (
	startDate: ISOString,
	openingHours: OpeningHours,
	validWeekdays?: string[],
): string[] => {
	const rangeStart = moment(startDate).startOf('month');
	const rangeEnd = rangeStart.clone().add(3, 'months');

	const disabledDates = [];

	while (rangeStart.isBefore(rangeEnd)) {
		const date = rangeStart.format('YYYY-MM-DD');
		if (
			!isValidWeekday(validWeekdays, rangeStart.isoWeekday()) ||
			isStoreClosedForDay({ openingHours, date })
		) {
			disabledDates.push(date);
		}
		rangeStart.add(1, 'day');
	}

	return disabledDates;
};

export const getAvailabilityFetcherFunction = (
	shopId: string,
): typeof handleQuantityAndAvailabilityFetch => {
	const availabilityFetcherFunction = isUsingServerAvailabilityFetch(shopId)
		? Callable.availability.getQuantitiesAndAvailabilities
		: handleQuantityAndAvailabilityFetch;
	return availabilityFetcherFunction;
};

export const isUsingServerAvailabilityFetch = (shopId: string) => {
	const rukaShopId = '7qBP2TyP5ttAtXI6GV81';
	const rentskiShopId = 'A6MoM6w08CMvjBUXFR0V';
	const skiweeksShopId = '8gjcuq78q6IMgSbO79u0';
	return shopId === rukaShopId || shopId === rentskiShopId || shopId === skiweeksShopId;
};
