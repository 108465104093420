import moment from 'moment-timezone';

import { AvailabilityRange } from 'common/api/frontend/inventory_new/availabilityRange';
import { isWithinBookingBuffer } from 'common/modules/atoms/bookingBuffer/utils';
import { doesDateRangeOverlap } from 'common/modules/atoms/dates';
import { hhMm } from 'common/modules/atoms/times';
import { OPEN_ENDED_BLOCKER_YEARS } from 'common/modules/inventoryBlockers';
import { OpeningHours } from 'common/modules/openingHours';
import { StartTimesConfig } from 'common/modules/startTimes';
import {
	DateStartTime,
	getBestStartTimeRowForDate,
	getStartTimeIntervalMinutes,
	getStartTimesForDate,
} from 'common/modules/startTimes/utils';
import {
	getSubscriptionOptionDuration,
	isAutoRenewProductSubscription,
} from 'common/modules/subscriptions';
import { BookingBuffer, Duration, ISOString, StartTimeCount, YYYY_MM_DD } from 'common/types';
import { fillWithLeadingZeros, switchUnreachable } from 'common/utils/common';
import { getNewEndTime } from 'common/utils/dateCalculations';
import { yearsToMinutes } from 'common/utils/dateUtils';

import {
	StartTimeSlotType,
	TimeSlotType,
	TimeSlotVariant,
	ValidSlotsByTime,
	ValidityWithReason,
	isStartTimeSlotType,
} from './types';

export const getTimeSlotsForDateRange = (args: {
	startDate: YYYY_MM_DD;
	endDate: YYYY_MM_DD;
	startTimesConfig: StartTimesConfig;
	openingHours: OpeningHours;
	excludeEmpty?: boolean;
	bookingBuffer?: BookingBuffer;
	variant: TimeSlotVariant;
	timeSlotType: TimeSlotType['type'];
	timezone: string;
}): ValidSlotsByTime => {
	const {
		startDate,
		endDate,
		startTimesConfig,
		openingHours,
		bookingBuffer,
		variant,
		timeSlotType,
		timezone,
	} = args;
	const rangeStart = moment(startDate);
	const rangeEnd = moment(endDate).endOf('day');

	const timeSlotsByDate: ValidSlotsByTime = {};

	while (rangeStart.isBefore(rangeEnd)) {
		const startTimes = getTimeSlotsForDate({
			date: rangeStart.format('YYYY-MM-DD'),
			startTimesConfig,
			openingHours,
			bookingBuffer,
			variant,
			timeSlotType,
			timezone,
		});
		if (!args.excludeEmpty || !!Object.keys(startTimes).length) {
			timeSlotsByDate[rangeStart.format('YYYY-MM-DD')] = startTimes;
		}
		rangeStart.add(1, 'day');
	}

	return timeSlotsByDate;
};

const getTimeSlotsForDate = (args: {
	date: YYYY_MM_DD;
	startTimesConfig: StartTimesConfig;
	openingHours: OpeningHours;
	bookingBuffer?: BookingBuffer;
	variant: TimeSlotVariant;
	timeSlotType: TimeSlotType['type'];
	timezone: string;
}): { [time: string]: ValidityWithReason } => {
	const {
		bookingBuffer,
		openingHours,
		date,
		startTimesConfig,
		timeSlotType,
		variant,
		timezone,
	} = args;

	const timeSlotsForDate: DateStartTime[] = getStartTimesForDate({
		date,
		startTimesConfig,
		openingHours,
		timezone,
		includeClosingTime: timeSlotType === 'endTime',
	});

	const validTimeSlots = timeSlotsForDate.reduce((tot, timeSlot) => {
		const { startTime, ignoreOpeningHours } = timeSlot;
		const timeString = hhMm(startTime, timezone);
		const [startHour, startMinute] = timeString.split(':').map((value) => Number(value));
		const timeMoment = moment
			.tz(date, timezone)
			.hour(startHour)
			.minute(startMinute)
			.startOf('minute');
		const isExpired = moment().isAfter(timeMoment);
		const withinBookingBuffer = bookingBuffer
			? isWithinBookingBuffer({ date: timeMoment, bookingBuffer })
			: true;

		const timeStringValidity: ValidityWithReason = isExpired
			? { valid: false, reason: 'expired' }
			: !withinBookingBuffer
			? { valid: false, reason: 'booking_buffer' }
			: { valid: true, ignoreOpeningHours };

		if (variant === 'all_times' || timeStringValidity.valid) {
			return {
				...tot,
				[timeString]: timeStringValidity,
			};
		}
		if (variant === 'valid_with_invalid_today_times') {
			const bookingBufferNotCoveringWholeDay = bookingBuffer
				? isWithinBookingBuffer({ date: timeMoment, bookingBuffer, precision: 'date' })
				: true;
			if (bookingBufferNotCoveringWholeDay) {
				return {
					...tot,
					[timeString]: timeStringValidity,
				};
			}
		}
		return tot;
	}, {} as { [time: string]: ValidityWithReason });

	switch (variant) {
		case 'valid_times':
		case 'valid_with_invalid_today_times':
			return validTimeSlots;
		case 'all_times':
			return getTimeslotsForAllTimes({ validTimeSlots, startTimesConfig, date, timezone });
		default:
			return switchUnreachable(variant);
	}
};

const getTimeslotsForAllTimes = (args: {
	date: YYYY_MM_DD;
	validTimeSlots: { [time: string]: ValidityWithReason };
	startTimesConfig: StartTimesConfig;
	timezone: string;
}) => {
	const { date, validTimeSlots, startTimesConfig, timezone } = args;

	const startTime = getBestStartTimeRowForDate({ rows: startTimesConfig.value, date });
	const startTimeInterval = getStartTimeIntervalMinutes(startTime) ?? 60;

	// If we want to get all times, we generate a start time list for all possible times and fill it with the valid times
	const allStartTimesForDay = Array.apply(null, Array(24 * (60 / startTimeInterval))).map(
		(_, index) => {
			const totalMinutes = index * startTimeInterval;
			const minutes = totalMinutes % 60;
			const hours = Math.floor(totalMinutes / 60);
			return `${fillWithLeadingZeros(hours.toString(), 2)}:${fillWithLeadingZeros(
				minutes.toString(),
				2,
			)}`;
		},
	);

	const allTimes = allStartTimesForDay.reduce((tot, timeString) => {
		const [startHour, startMinute] = timeString.split(':').map((value) => Number(value));
		const timeMoment = moment
			.tz(date, timezone)
			.hour(startHour)
			.minute(startMinute)
			.startOf('minute');
		const isExpired = moment().isAfter(timeMoment);
		const timeStringValidity: ValidityWithReason =
			validTimeSlots[timeString] ??
			(isExpired
				? { valid: false, reason: 'expired' }
				: {
						valid: false,
						reason: 'opening_hours',
				  });

		return {
			...tot,
			[timeString]: timeStringValidity,
		};
	}, {} as { [time: string]: ValidityWithReason });

	return allTimes;
};

export const getOverlappingAvailabilityRanges = (args: {
	startTime: string;
	endTime: string;
	availabilityRanges: AvailabilityRange[];
}): AvailabilityRange[] | undefined => {
	const { startTime, endTime } = args;
	return args.availabilityRanges.filter((range) =>
		doesDateRangeOverlap(startTime, endTime, range.start, range.end),
	);
};

export const isStartTimeOverOrderLimit = (
	startTime: ISOString,
	orderLimit: number,
	startTimeCounts: StartTimeCount,
) => {
	const ordersForStartTime: number = startTimeCounts[startTime] ?? 0;
	return ordersForStartTime >= orderLimit;
};

export const getStartEndRangeFromTimeSlots = (
	timeSlots: ValidSlotsByTime,
	timeSlotType: TimeSlotType,
	openingHours: OpeningHours,
	timezone: string,
): { start: ISOString | null; end: ISOString | null } => {
	const firstValidSlot = Object.entries(timeSlots).find(([, times]) => {
		return Object.keys(times).length;
	});
	const lastValidSlot = Object.entries(timeSlots)
		.slice()
		.reverse()
		.find(([, times]) => {
			return Object.keys(times).length;
		});
	if (!firstValidSlot || !lastValidSlot)
		return {
			start: null,
			end: null,
		};
	const firstValidDate = firstValidSlot[0];
	const firstValidTime = Object.keys(firstValidSlot[1])[0];

	const lastValidDate = lastValidSlot[0];
	const lastValidTime = Object.keys(lastValidSlot[1])[Object.keys(lastValidSlot[1]).length - 1];

	const rangeStart = moment.tz(`${firstValidDate}T${firstValidTime}`, timezone).toISOString();
	const rangeEnd = moment.tz(`${lastValidDate}T${lastValidTime}`, timezone).toISOString();

	if (isStartTimeSlotType(timeSlotType)) {
		const duration = getDurationFromStartTimeSlotType(timeSlotType, rangeStart);

		return {
			start: rangeStart,
			end: getNewEndTime(rangeEnd, duration, openingHours, timezone),
		};
	} else {
		return {
			start: timeSlotType.startTime,
			end: rangeEnd,
		};
	}
};

export const getDurationFromStartTimeSlotType = (
	timeSlotType: StartTimeSlotType,
	startDate: ISOString,
): Duration => {
	switch (timeSlotType.duration.type) {
		case 'booking': {
			const { value } = timeSlotType.duration;
			return {
				durationInSeconds: value?.durationInSeconds ?? 0,
				durationType: value?.durationType ?? '24h',
				durationName: null,
			};
		}
		case 'subscription': {
			const { value } = timeSlotType.duration;
			const duration = getSubscriptionOptionDuration(value, startDate);
			return isAutoRenewProductSubscription(value)
				? {
						...duration,
						durationInSeconds: moment
							.duration(duration.durationInSeconds, 'seconds')
							.add(yearsToMinutes(OPEN_ENDED_BLOCKER_YEARS), 'minutes')
							.asSeconds(),
				  }
				: duration;
		}
		default: {
			return switchUnreachable(timeSlotType.duration);
		}
	}
};

export const shouldIgnoreOpeningHoursForTimeSlot = (timeslotValidity: ValidityWithReason) => {
	return !!(timeslotValidity.valid && !!timeslotValidity.ignoreOpeningHours);
};
