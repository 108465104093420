import React from 'react';

import { Box, Checkbox, Collapse, FormControlLabel, Typography } from '@mui/material';
import { isEmpty, omit } from 'lodash';
import moment from 'moment-timezone';
import * as CartActions from 'redux/actions/cart';
import * as CheckoutActions from 'redux/actions/checkout';
import * as ViewActions from 'redux/actions/view';
import { useDispatch, useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import Banner from 'common/components/states/Banner';
import { Address } from 'common/modules/atoms/addresses';
import { getItemPricingFromListPrice, getTotalItemPricing } from 'common/modules/atoms/pricing';
import { getTaxRateFromVatPercent } from 'common/modules/atoms/taxes';
import {
	deliveryOptionSupportsPickup,
	getDeliveryInventoryBlockersAfter,
	timeslotToString,
} from 'common/modules/delivery/utils';
import { CartDelivery, DeliveryOption, DeliveryTimeSlot } from 'common/types';
import { getPricingString } from 'common/utils/common';
import useCheckoutForm from 'hooks/useCheckoutForm';
import useShopFormat from 'hooks/useShopFormat';
import { useTranslation } from 'services/localization';
import { productSupportsPickup } from 'utils/delivery';
import { assignDeliveryOrderUnavailabilities } from 'utils/products';

import AddressForm from './AddressForm';
import DeliveryTimeslots from './DeliveryTimeslots';

interface Props {
	autoFocus: boolean;
}

const DeliveryStep = (props: Props) => {
	const { autoFocus } = props;
	const { t, getTranslation } = useTranslation();
	const dispatch = useDispatch();
	const { localFormat, shopDateFormat } = useShopFormat();

	const { values, actions, errors } = useCheckoutForm();

	const delivery = useSelector(CartSelectors.delivery)!;

	const currency = useSelector(CheckoutSelectors.currency);
	const shopTimezone = useSelector(ShopSelectors.shopTimezone);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const cartDelivery = useSelector(CartSelectors.delivery);
	const availablePickupSlots = useSelector(ViewSelectors.availablePickupSlots);
	const pickupChecked = useSelector(ViewSelectors.pickupChecked);
	const latestPossiblePickupSlot = useSelector(ViewSelectors.latestPossiblePickupSlot);
	const orderProducts = useSelector(CheckoutSelectors.orderProducts);
	const taxExcluded = useSelector(CheckoutSelectors.taxExcluded);
	const startDate = useSelector(CheckoutSelectors.startDate);
	const endDate = useSelector(CheckoutSelectors.endDate);
	const hasProductsToReturn = useSelector(CartSelectors.hasProductsToReturnInCart);

	const rentalProductEndDates = orderProducts.filter(productSupportsPickup).map((p) => p.endDate);
	const allOrderProductsHaveSameEndDate = rentalProductEndDates.every((o, i, arr) => o === arr[0]);

	const handleUpdateCartDelivery = (updatedCartDelivery: CartDelivery) => {
		dispatch(CartActions.setDelivery(updatedCartDelivery));
	};

	const updateOrderProductsUnavailabilities = (cartDelivery: CartDelivery) => {
		if (!!selectedDeliveryOption) {
			const updatedOrderProducts = assignDeliveryOrderUnavailabilities({
				products: orderProducts,
				deliveryOption: selectedDeliveryOption,
				cartDelivery,
				openingHours,
				timezone: shopTimezone,
			});
			dispatch(CheckoutActions.setOrderProducts(updatedOrderProducts));
		}
	};

	const handlePickupEnabledChange = (checked: boolean) => {
		if (!selectedDeliveryOption) return;
		const deliveryOption = selectedDeliveryOption;
		const pickupPricing = getItemPricingFromListPrice(deliveryOption.price.pickup, {
			taxRate: getTaxRateFromVatPercent(deliveryOption.vatPercent),
			taxExcluded,
			currency: currency.code,
		});
		const deliveryToPricing =
			delivery.to?.pricing ??
			getItemPricingFromListPrice(deliveryOption.price.delivery, {
				taxRate: getTaxRateFromVatPercent(deliveryOption.vatPercent),
				taxExcluded,
				currency: currency.code,
			});
		const updatedDeliveryOption: CartDelivery = checked
			? {
					...delivery,
					pricing: getTotalItemPricing(
						[deliveryToPricing, pickupPricing],
						deliveryToPricing.currency,
					),
					from: {
						...delivery.from,
						location: {
							address: {
								address: '',
								...values.deliveryAddress,
							},
						},
						pricing: pickupPricing,
						timeslot: latestPossiblePickupSlot ?? null,
						handlingTimeMinutes:
							getDeliveryInventoryBlockersAfter({
								deliveryOption,
								pickupSlotOrEndDate: latestPossiblePickupSlot ?? null,
								openingHours,
								timezone: shopTimezone,
							}).deliveryHandlingAfter ?? 0,
					},
			  }
			: omit({ ...delivery, pricing: deliveryToPricing }, ['from']);

		dispatch(ViewActions.setPickupChecked(checked));
		handleUpdateCartDelivery(updatedDeliveryOption);
		updateOrderProductsUnavailabilities(updatedDeliveryOption);

		if (checked) {
			actions.setPickupAddress(values.deliveryAddress);
		} else {
			actions.setPickupAddress({});
		}
	};

	const handleDeliveryAddressChange = (args: { field: keyof Address; value: string }) => {
		if (!delivery.to) return;
		const { field, value } = args;
		actions.setDeliveryAddressPart(field, value);
		const updatedDeliveryAddress = {
			...values.deliveryAddress,
			[field]: value,
		};

		handleUpdateCartDelivery({
			...delivery,
			to: {
				...delivery.to,
				location: {
					address: {
						address: '',
						...updatedDeliveryAddress,
					},
				},
			},
			...(!!values.useSameAddress &&
				!!delivery.from && {
					from: {
						...delivery.from,
						location: {
							address: {
								address: '',
								...updatedDeliveryAddress,
							},
						},
					},
				}),
		});
	};

	const handlePickupAddressChange = (args: { field: keyof Address; value: string }) => {
		if (!delivery.from || !!delivery.from?.disabled) return;
		const { field, value } = args;
		actions.setPickupAddressPart(field, value);
		const updatedPickupAddress = {
			...values.pickupAddress,
			[field]: value,
		};

		handleUpdateCartDelivery({
			...delivery,
			from: {
				...delivery.from,
				location: {
					address: {
						address: '',
						...updatedPickupAddress,
					},
				},
			},
		});
	};

	const handleUseSameAddressChange = (_event: any, useSameAddress: boolean) => {
		actions.setUseSameAddress(useSameAddress);
		if (!!delivery.from && useSameAddress) {
			actions.setPickupAddress(values.deliveryAddress);
			handleUpdateCartDelivery({
				...delivery,
				from: {
					...delivery.from,
					location: {
						...(delivery.to?.location ?? { details: null }),
						address: {
							address: '',
							...values.deliveryAddress,
						},
					},
				},
			});
		}
	};

	const handleTimeslotChange = (type: 'to' | 'from', timeslot: DeliveryTimeSlot) => {
		const updatedDeliveryOption = {
			...delivery,
			[type]: {
				...delivery[type],
				timeslot,
			},
		};
		handleUpdateCartDelivery(updatedDeliveryOption);
		updateOrderProductsUnavailabilities(updatedDeliveryOption);
	};

	const renderDeliverySection = (deliveryOption: DeliveryOption) => {
		const timeslot =
			timeslotToString({
				timeslot: cartDelivery?.to?.timeslot ?? null,
				shopDateFormat,
				timezone: shopTimezone,
			}) || localFormat(moment(startDate), 'ddd. MMM D');

		return (
			<Box>
				<Typography variant="h6" fontSize="1.8rem">
					{getTranslation(deliveryOption.name)}
				</Typography>
				<Typography variant="body1" fontSize="1.4rem" gutterBottom>
					{t('delivery.deliveryTime', 'Delivery time')}
					{': '}
					{timeslot}
				</Typography>
				<Typography variant="body1" fontSize="1.4rem" color="secondary">
					{getTranslation(deliveryOption.description)}
				</Typography>
				<AddressForm
					value={values.deliveryAddress}
					onChange={handleDeliveryAddressChange}
					errors={errors.deliveryAddress}
					autoFocus={autoFocus}
					id="deliveryAddress"
					sx={{ mt: 2 }}
				/>
			</Box>
		);
	};

	const renderReturnInstructions = (deliveryOption: DeliveryOption) => {
		const { returnInstructions } = deliveryOption;
		if (deliveryOption.type !== 'DELIVERY_ONLY') return null;
		if (!hasProductsToReturn || !returnInstructions) return null;

		return (
			<Box mt={2}>
				<Typography variant="h6" fontSize="1.8rem">
					{t('delivery.returnInstructions', 'Return instructions')}
				</Typography>
				{!!endDate && (
					<Typography variant="body1" fontSize="1.4rem" gutterBottom>
						{t('delivery.orderEnds', 'Order ends')}
						{': '}
						{localFormat(moment(endDate), 'ddd. MMM D, HH:mm')}
					</Typography>
				)}
				<Typography variant="body1" fontSize="1.4rem" color="secondary">
					{getTranslation(returnInstructions)}
				</Typography>
			</Box>
		);
	};

	const renderPickupSection = (deliveryOption: DeliveryOption) => {
		if (!deliveryOptionSupportsPickup(deliveryOption)) return null;

		const isPickupAvailable =
			hasProductsToReturn && allOrderProductsHaveSameEndDate && !isEmpty(availablePickupSlots);

		const isPickupOptional = selectedDeliveryOption?.type === 'DELIVERY_AND_OPTIONAL_PICKUP';
		const isPickupEnabled = isPickupAvailable && !!delivery.from && !delivery.from.disabled;

		if (!isPickupAvailable) {
			return (
				<Box mt={2}>
					<Banner
						variant="info"
						title={t('delivery.pickupCannotBeAdded', 'Pickup cannot be added to this order')}
					/>
				</Box>
			);
		}

		return (
			<>
				{isPickupOptional && (
					<FormControlLabel
						sx={{
							mt: 2,
							mb: 0,
						}}
						control={
							<Checkbox
								checked={isPickupAvailable && pickupChecked}
								onChange={(e, checked) => handlePickupEnabledChange(checked)}
								inputProps={{ 'aria-label': 'primary checkbox' }}
								disabled={!isPickupAvailable}
								color="primary"
								sx={{
									color: (theme) => theme.palette.common.black,
									'&$checked': {
										color: (theme) => theme.palette.common.black,
									},
								}}
							/>
						}
						label={`${t('delivery.requestPickup', 'Add pickup after rental')} (+${getPricingString(
							selectedDeliveryOption?.price?.pickup ?? 0,
							currency,
						)})`}
					/>
				)}
				{isPickupEnabled && (
					<>
						<Typography variant="h6" fontSize="1.8rem" sx={{ mt: 3 }}>
							{t('delivery.pickup', 'Pickup')}
						</Typography>
						<DeliveryTimeslots
							type="from"
							date={rentalProductEndDates[0]}
							handleSelect={handleTimeslotChange}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={values.useSameAddress}
									onChange={handleUseSameAddressChange}
									color="primary"
									sx={{
										color: (theme) => theme.palette.common.black,
										'&$checked': {
											color: (theme) => theme.palette.common.black,
										},
									}}
								/>
							}
							label={t('delivery.sameAsDeliveryAddress', 'Same as delivery address')}
						/>
						<Collapse in={!values.useSameAddress}>
							<AddressForm
								value={values.useSameAddress ? values.deliveryAddress : values.pickupAddress}
								onChange={handlePickupAddressChange}
								errors={values.useSameAddress ? {} : errors.pickupAddress}
								id="pickupAddress"
								sx={{ mt: 2 }}
							/>
						</Collapse>
					</>
				)}
			</>
		);
	};

	return !!selectedDeliveryOption ? (
		<>
			{renderDeliverySection(selectedDeliveryOption)}
			{renderReturnInstructions(selectedDeliveryOption)}
			{renderPickupSection(selectedDeliveryOption)}
		</>
	) : null;
};

export default DeliveryStep;
