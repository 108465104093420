import React, { useCallback } from 'react';

import AdyenCheckout from '@adyen/adyen-web';
import { TFunction } from 'i18next';

import { useAdyen } from 'common/hooks/useAdyen';
import { OnlineMethods } from 'common/modules/payments/constants';
import { getAdyenPaymentMethodType } from 'common/modules/payments/paymentMethods';
import { AdyenPaymentMethod } from 'common/modules/payments/types';
import { switchUnreachable } from 'common/utils/common';

import { PaymentMethodError } from '../types';
import AdyenPaymentMethodElement from './AdyenPaymentMethodElement';

interface Props {
	method: Exclude<AdyenPaymentMethod, 'APPLEPAY' | 'GOOGLEPAY'>;
	adyenCheckout: AdyenCheckout;
	loading?: boolean;
	t: TFunction;
	paymentError?: PaymentMethodError;
	disabled?: boolean;
}

const AdyenPaymentElement = (props: Props) => {
	const { setMethodSubmitAction } = useAdyen();
	const { method, adyenCheckout, paymentError, t, disabled } = props;

	const handlePaymentSubmitAction = useCallback(
		(action: () => void) => {
			setMethodSubmitAction(method, action);
		},
		[method, setMethodSubmitAction],
	);

	switch (method) {
		case OnlineMethods.CARD_ONLINE:
		case OnlineMethods.TRUSTLY:
		case OnlineMethods.MOBILEPAY:
		case OnlineMethods.PAYTRAIL: {
			const paymentMethodType = getAdyenPaymentMethodType(method);
			if (!paymentMethodType) return null;
			return (
				<AdyenPaymentMethodElement
					paymentMethodType={paymentMethodType}
					adyenCheckout={adyenCheckout}
					paymentError={paymentError?.methodId === method ? paymentError.error : undefined}
					t={t}
					disabled={disabled}
					setPaymentSubmitAction={handlePaymentSubmitAction}
				/>
			);
		}
		default: {
			return switchUnreachable(method);
		}
	}
};

export default AdyenPaymentElement;
