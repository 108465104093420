import React from 'react';

import { Box, Typography } from '@mui/material';

import CenteredContent from 'components/CenteredContent';
import { useTranslation } from 'services/localization';

import LocationsGrid from './LocationsGrid';

const LocationsPage = () => {
	const { t } = useTranslation();
	return (
		<CenteredContent>
			<Box py={7}>
				<Box mb={7}>
					<Typography variant="h5">{t('common:shopLocation.stores', 'Stores')}</Typography>
				</Box>
				<LocationsGrid />
			</Box>
		</CenteredContent>
	);
};

export default LocationsPage;
