import { chain, partition } from 'lodash';
import moment from 'moment-timezone';
import { TFunction } from 'react-i18next';

import { getISOStringFromDateAndTime } from 'common/modules/atoms/dates';
import { yyyyMmDd } from 'common/modules/atoms/times';
import {
	OpeningHours,
	getOpeningTimesForDate,
	isDateTimeWithinOpeningHours,
} from 'common/modules/openingHours';
import { HH_MM, ISOString, ISOWeekday, YYYY_MM_DD } from 'common/types';
import { notNull, switchUnreachable } from 'common/utils/common';

import {
	FixedStartTimesDefinitionValue,
	IntervalStartTimesDefinitionValue,
	StartTimesConfig,
	StartTimesConfigRow,
} from './types';

export interface GetStartTimesForDateArgs {
	date: YYYY_MM_DD;
	startTimesConfig: StartTimesConfig;
	openingHours: OpeningHours;
	timezone: string;
	includeClosingTime?: boolean;
}

export interface DateStartTime {
	startTime: ISOString;
	ignoreOpeningHours?: boolean;
}

export const getStartTimesForDate = (args: GetStartTimesForDateArgs): DateStartTime[] => {
	const { date, startTimesConfig, openingHours, timezone, includeClosingTime } = args;

	const dateStartTimes = startTimesConfig.value.filter((row) =>
		isStartTimeRowForValidForDate({ row, date }),
	);
	const exceptionStartTime = dateStartTimes.find((r) => r.type === 'exceptional'); //For now we try to find the first exceptional row

	return !!exceptionStartTime
		? getStartTimes({
				row: exceptionStartTime,
				date,
				timezone,
				openingHours,
				includeClosingTime,
		  })
		: chain(dateStartTimes.filter((d) => d.type !== 'exceptional'))
				.flatMap((row) =>
					getStartTimes({
						row,
						date,
						timezone,
						openingHours,
						includeClosingTime,
					}),
				)
				.orderBy('ignoreOpeningHours', ['desc'])
				.uniqBy('startTime')
				.sortBy('startTime')
				.value();
};

export const getStartTimes = (args: {
	row: StartTimesConfigRow;
	timezone: string;
	date: YYYY_MM_DD;
	openingHours: OpeningHours;
	includeClosingTime?: boolean;
}): DateStartTime[] => {
	const { row, date, timezone, openingHours, includeClosingTime } = args;

	if (!row?.value.startTimes) return [];

	switch (row.value.startTimes.type) {
		case 'interval':
			return getIntervalStartTimesForDate({
				config: row.value.startTimes.value,
				date,
				timezone,
				openingHours,
				includeClosingTime,
			});
		case 'fixed':
			return getFixedStartTimesForDate({
				config: row.value.startTimes.value,
				date,
				timezone,
				openingHours,
			});
		default: {
			return switchUnreachable(row.value.startTimes);
		}
	}
};

export const getIntervalStartTimesForDate = (args: {
	config: IntervalStartTimesDefinitionValue;
	timezone: string;
	date: YYYY_MM_DD;
	openingHours: OpeningHours;
	includeClosingTime?: boolean;
}): DateStartTime[] => {
	const { config, date, timezone, openingHours, includeClosingTime } = args;
	const openingTimes = getOpeningTimesForDate({ openingHours, date });

	if (!openingTimes || openingTimes.isClosed) {
		return [];
	}

	return getIntervalStartTimes({
		config,
		from: openingTimes.openTime,
		to: openingTimes.closeTime,
		includeClosingTime,
	}).map((time) => {
		return {
			startTime: getISOStringFromDateAndTime({ date, time }, timezone),
		};
	});
};

export const getIntervalStartTimes = (args: {
	config: IntervalStartTimesDefinitionValue;
	from: HH_MM;
	to: HH_MM;
	includeClosingTime?: boolean;
}): HH_MM[] => {
	const { config, from, to, includeClosingTime } = args;
	const { intervalMinutes } = config;

	const date = moment().format('YYYY-MM-DD');
	const startMoment = moment.tz(
		getISOStringFromDateAndTime(
			{
				date,
				time: from,
			},
			'UTC',
		),
		'UTC',
	);
	const endMoment = moment.tz(
		getISOStringFromDateAndTime(
			{
				date,
				time: to,
			},
			'UTC',
		),
		'UTC',
	);
	const startTimes: HH_MM[] = [];
	while (startMoment.isBefore(endMoment)) {
		startTimes.push(startMoment.format('HH:mm'));
		startMoment.add(intervalMinutes, 'minutes');
	}
	if (!!includeClosingTime) startTimes.push(startMoment.format('HH:mm'));
	return startTimes;
};

export const getFixedStartTimesForDate = (args: {
	config: FixedStartTimesDefinitionValue;
	openingHours: OpeningHours;
	date: YYYY_MM_DD;
	timezone: string;
}): DateStartTime[] => {
	const { config, openingHours, date, timezone } = args;
	const { times, ignoreOpeningHours } = config;

	const openingTimes = getOpeningTimesForDate({ openingHours, date });

	if (!ignoreOpeningHours && (!openingTimes || openingTimes.isClosed)) {
		return [];
	}

	return chain(times)
		.map((time) => {
			const dateTime = getISOStringFromDateAndTime({ date, time }, timezone);
			if (
				!ignoreOpeningHours &&
				!isDateTimeWithinOpeningHours({
					dateTime,
					openingHours,
					timezone,
					inclusive: { start: true, end: false },
				})
			) {
				return null;
			}
			return {
				startTime: getISOStringFromDateAndTime({ date, time }, timezone),
				ignoreOpeningHours: config.ignoreOpeningHours,
			};
		})
		.filter(notNull)
		.sort()
		.value();
};

/**
 * Get the best matching start time row for a given date.
 *
 * - If start times have been defined for that specific date, use that
 * - If start times have been defined for that weekday, use that
 * - If neither, return null
 */
export const getBestStartTimeRowForDate = (args: {
	rows: StartTimesConfigRow[];
	date: YYYY_MM_DD;
}): StartTimesConfigRow | null => {
	const { rows, date } = args;

	const [exceptionRows, weeklyRows] = partition(rows, (row) => row.type === 'exceptional');

	return (
		exceptionRows.find((row) => isStartTimeRowForValidForDate({ row, date })) ||
		weeklyRows.find((row) => isStartTimeRowForValidForDate({ row, date })) ||
		null
	);
};

export const getStartTimeIntervalMinutes = (
	startTimesRow: StartTimesConfigRow | null,
): number | null => {
	return startTimesRow?.value.startTimes?.type === 'interval'
		? startTimesRow?.value.startTimes.value.intervalMinutes ?? null
		: null;
};

/**
 * Check if a start time row is valid for a given date.
 *
 * - If the row type is 'exceptional', check that the dates array includes the specified date
 * - If the row type is 'weekly', check that the weekDays array includes the weekday of the specified date
 *
 */
export const isStartTimeRowForValidForDate = (args: {
	row: StartTimesConfigRow;
	date: YYYY_MM_DD;
}): boolean => {
	const { row, date } = args;
	switch (row.type) {
		case 'exceptional':
			return row.value.dates.map((date) => yyyyMmDd(date)).includes(date);
		case 'weekly':
			const isoWeekday = moment(date).isoWeekday() as ISOWeekday;
			return row.value.weekDays.includes(isoWeekday);
		default:
			return switchUnreachable(row);
	}
};

/**
 * Gets the example string repesentation of how interval start times would look like
 */

export const getIntervalStartTimesExampleString = (intervalMinutes: number, t: TFunction) => {
	const { hour: openHour, minute: openMinute } = { hour: 9, minute: 0 };
	const { hour: closeHour, minute: closeMinute } = { hour: 21, minute: 0 };
	const totalOpenMinutes = (closeHour - openHour) * 60 + (closeMinute - openMinute);
	const timeSlots = [0, 1, 2].reduce((slots: string[], i) => {
		if (intervalMinutes * i < totalOpenMinutes) {
			slots.push(
				moment()
					.hour(openHour)
					.minute(openMinute + intervalMinutes * i)
					.format('HH:mm'),
			);
		}
		return slots;
	}, []);
	return timeSlots.length > 1
		? t('common:startTimeSlotsMAny', {
				timeSlot: timeSlots.join(', ') + '...',
				defaultValue: 'E.g. with opening hours 9-21, the start time slots will be: {{timeSlot}}.',
		  })
		: t('common:startTimeSlotsOne', {
				timeSlot: timeSlots[0],
				defaultValue:
					'E.g. Start time slot would be {{timeSlot}} for a store with 9-21 opening hours.',
		  });
};

/**
 * Gets the example string repesentation of how fixed start times would look like
 */

export const getFixedStartTimesExampleString = (
	args: { times: HH_MM[]; ignoreOpeningHours: boolean },
	t: TFunction,
) => {
	const { times, ignoreOpeningHours } = args;

	if (ignoreOpeningHours) {
		return t('common:fixedStartTimesIgnoreOpeningHours', {
			timeSlot: times.slice(0, 3).join(', '),
			defaultValue: 'E.g. Start time slots would be {{timeSlot}} with opening hours ignored.',
		});
	}

	const openTime = moment('09:00', 'HH:mm').utc();
	const closeTime = moment('21:00', 'HH:mm').utc();

	const timesFiltered = times
		.filter((time) => {
			return moment
				.tz(
					getISOStringFromDateAndTime(
						{
							date: moment().format('YYYY-MM-DD'),
							time,
						},
						'UTC',
					),
					'UTC',
				)
				.isBetween(openTime, closeTime, undefined, '[]');
		})
		.sort();

	return timesFiltered.length === 0
		? t('common:noFixedStartTimes', 'E.g. with opening hours 9-21, no timeslots would be visible.')
		: timesFiltered.length > 1
		? t('common:startTimeSlotsMAny', {
				timeSlot: timesFiltered.join(', '),
				defaultValue: 'E.g. with opening hours 9-21, the start time slots will be: {{timeSlot}}.',
		  })
		: t('common:startTimeSlotsOne', {
				timeSlot: timesFiltered[0],
				defaultValue:
					'E.g. Start time slot would be {{timeSlot}} for a store with 9-21 opening hours.',
		  });
};
