import React from 'react';

import { Box } from '@mui/material';

import Select from 'common/components/pickers/Select';
import { SegmentOption } from 'common/utils/segments';
import ErrorText from 'components/ErrorText';
import { useTranslation } from 'services/localization';

interface Props {
	options: SegmentOption[];
	value: string | null;
	onChange: (value: string) => void;
	error?: string | null;
	disabled?: boolean;
}

const SegmentSelector = React.memo((props: Props) => {
	const { options, value, onChange, error, disabled } = props;
	const { t } = useTranslation();

	return (
		<Box>
			<Select.Single
				label=""
				emptyLabel={t('common:prompts.chooseAgeGroup', 'Choose age group')}
				value={value}
				hideEmptyOption
				indicators="none"
				onChange={(value) => value && onChange(value)}
				disabled={disabled}
				options={options.map(({ value, label, description = '' }) => ({
					id: value,
					label,
					description,
				}))}
			/>
			{error && (
				<Box mt={1}>
					<ErrorText>{error}</ErrorText>
				</Box>
			)}
		</Box>
	);
});

export default SegmentSelector;
