import '@adyen/adyen-web/dist/adyen.css';
import React, { useEffect, useRef } from 'react';

import AdyenCheckout from '@adyen/adyen-web';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TFunction } from 'i18next';

import { AdyenMethodType } from 'common/modules/payments/types';
import errorHandler from 'common/services/errorHandling/errorHandler';

interface Props {
	adyenCheckout: AdyenCheckout;
	paymentError?: string;
	t: TFunction;
	paymentMethodType: AdyenMethodType;
	disabled?: boolean;
	showPayButton?: boolean;
	setPaymentSubmitAction: (action: () => void) => void;
}

const AdyenPaymentMethodElement = (props: Props) => {
	const classes = useStyles();
	const { adyenCheckout, paymentMethodType, setPaymentSubmitAction } = props;
	const paymentElementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		try {
			const createPaymentForm = () => {
				const paymentMethod = adyenCheckout
					.create(paymentMethodType)
					.mount(paymentElementRef.current);
				setPaymentSubmitAction(paymentMethod.submit);
			};
			createPaymentForm();
		} catch (e) {
			errorHandler.report(e);
		}
	}, [adyenCheckout, paymentMethodType, setPaymentSubmitAction]);

	return (
		<div>
			<div className={classes.wrapper}>
				<div ref={paymentElementRef}></div>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			position: 'relative',
			fontSize: '1.4rem',
			fontFamily: theme.typography.fontFamily,
			fontWeight: 400,
		},
		'@global': {
			'.adyen-checkout__label__text': {
				fontSize: '1.4rem',
			},
			'.adyen-checkout__label__text:not(.adyen-checkout__label__text--error)': {
				color: theme.palette.text.primary,
			},
			'.adyen-checkout__error-text': {
				fontSize: '1.2rem',
			},
			'.adyen-checkout__input': {
				borderRadius: 0,
				height: 50,
			},
			'.adyen-checkout__input:not(.adyen-checkout__input--error)': {
				border: `1px solid ${theme.palette.border.inputOutline}`,
			},
		},
	}),
);

export default AdyenPaymentMethodElement;
