import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as NavSelectors from 'redux/selectors/nav';
import * as ShopSelectors from 'redux/selectors/shop';

import Divider from 'common/components/Divider';
import LoadingState from 'common/components/states/LoadingState';
import { isRunInIframe } from 'common/utils/browserUtils';
import CenteredContent from 'components/CenteredContent';
import LanguageSelector from 'components/LanguageSelector/Desktop';
import LocationCard from 'components/LocationCard';
import { useTranslation } from 'services/localization';
import { getPath } from 'services/routing/utils';

const Locations = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	const shopInfo = useSelector(ShopSelectors.shopPublicInfoData);
	const shopLogo = useSelector(ShopSelectors.shopLogo);
	const locations = useSelector(ShopSelectors.onlineLocations);
	const mainLocation = useSelector(ShopSelectors.shopMainLocation);
	const onlineUrlsByLocationId = useSelector(ShopSelectors.onlineUrlsByLocationId);
	const shopName = useSelector(NavSelectors.shopName);
	const shopFeatures = useSelector(ShopSelectors.shopFeaturesData);
	const shopFeaturesLoading = useSelector(ShopSelectors.shopFeaturesLoading);

	if (locations.length === 1) {
		const location = locations[0];
		const isMainLocation = location.id === mainLocation?.id;
		const locationName = !isMainLocation ? onlineUrlsByLocationId[location.id] : undefined;

		return <Redirect to={getPath({ shopName, locationName })('browse')} />;
	}

	if (shopFeaturesLoading) {
		return <LoadingState />;
	}

	if (shopFeatures.includes('DISABLE_ONLINE_LOCATIONS_PAGE')) {
		return <Redirect to={getPath({ shopName })('browse')} />;
	}

	return (
		<Box>
			<CenteredContent paddingBottom={12}>
				<Box className={classes.languageWrapper}>
					<LanguageSelector />
				</Box>
				{!isRunInIframe() && (
					<>
						<Box className={classes.logoWrapper}>
							{!!shopLogo ? (
								<img src={shopLogo} className={classes.logo} alt="" />
							) : (
								<Typography variant="h5">{shopInfo?.name}</Typography>
							)}
						</Box>
						<Divider />
					</>
				)}
				<Box className={classes.mainContent}>
					<Box mt={4} />
					<Typography variant="h5">{t('locations.chooseStore', 'Choose store')}</Typography>
					<Box mt={4} />
					<Grid container spacing={3}>
						{locations.map((location) => (
							<Grid key={location.id} item xs={12} md={6} lg={4}>
								<LocationCard
									key={location.id}
									location={location}
									hideActiveLocation
									hideExceptions
									hideContactInfo
									hideAdditionalInformation
								/>
							</Grid>
						))}
					</Grid>
				</Box>
			</CenteredContent>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		languageWrapper: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			padding: theme.spacing(2, 0),
		},
		logoWrapper: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			paddingBottom: theme.spacing(4),
		},
		logo: {
			width: '100%',
			maxWidth: 300,
			height: 100,
			objectFit: 'contain',
		},
		mainContent: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
	}),
);

export default Locations;
