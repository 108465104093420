import { USER_DIDNT_INPUT_KEYCARD } from 'common/constants/skidataErrors';

const MAX_KEYCARD_SERIAL_LENGTH_UNFORMATTED = 25;
const MAX_KEYCARD_SERIAL_LENGTH_FORMATTED = 30;
const FIRST_DASH_POS = 2;
const LAST_DASH_POS = 23;

const shouldAddDashes = (serialNumber: string) => {
	return (
		serialNumber.length === FIRST_DASH_POS ||
		serialNumber.length === LAST_DASH_POS ||
		[FIRST_DASH_POS, LAST_DASH_POS].some((p) => !!serialNumber[p] && serialNumber[p] !== '-')
	);
};

const addDashes = (serialNumber: string) => {
	const withoutDashes = serialNumber.replace(/-/g, '');
	const start = withoutDashes.substring(0, FIRST_DASH_POS);
	const mid = withoutDashes.substring(FIRST_DASH_POS, LAST_DASH_POS - 1);
	const end = withoutDashes.substring(LAST_DASH_POS - 1, LAST_DASH_POS);
	return withoutDashes.length < FIRST_DASH_POS
		? start
		: withoutDashes.length + 1 < LAST_DASH_POS
		? `${start}-${mid}`
		: `${start}-${mid}-${end}`;
};

const hasOnlyNumbersSpacesOrDashes = (serialNumber: string) =>
	/^$|^[-0-9 ]+$|\./.test(serialNumber);

const removeWhitespaceAndCapLength = (serialNumber: string) =>
	serialNumber.replace(/ /g, '').slice(0, MAX_KEYCARD_SERIAL_LENGTH_UNFORMATTED);

const shouldValueChange = (serialNumber: string) => {
	return (
		serialNumber.length < MAX_KEYCARD_SERIAL_LENGTH_FORMATTED &&
		hasOnlyNumbersSpacesOrDashes(serialNumber)
	);
};

const prevSerialIsShorter = (before: string | undefined, after: string) =>
	!before || before.length < after.length;

const formatToDB = (serialNumber: string, prevSerial: string | undefined) => {
	const noWhitespace = removeWhitespaceAndCapLength(serialNumber);
	const serialNumberWithDashes =
		serialNumber !== USER_DIDNT_INPUT_KEYCARD &&
		shouldAddDashes(noWhitespace) &&
		prevSerialIsShorter(prevSerial, noWhitespace)
			? addDashes(noWhitespace)
			: serialNumber;
	return removeWhitespaceAndCapLength(serialNumberWithDashes);
};

const formatToUI = (serial: string) => {
	const noWhitespace = removeWhitespaceAndCapLength(serial);
	const [a = '', b = '', c = '', d = '', e = ''] = noWhitespace.substring(7).match(/.{1,4}/g) || [];
	const start = noWhitespace.substring(0, 7);
	const end = e.substring(0, 2) || '';
	const formattedSerial = `${start} ${a} ${b} ${c} ${d}${end}`;

	return formattedSerial.trim();
};

export const SerialNumberUtils = {
	shouldValueChange,
	formatToDB,
	formatToUI,
};
