import { isEmpty, reduce, sortBy, uniq } from 'lodash';
import moment from 'moment-timezone';

import { OrderProductAvailability } from 'common/api/firestore';
import { calculateAvailabilityForTimePeriod } from 'common/api/frontend/inventory_new/availabilityRange';
import { doesDateRangeOverlap } from 'common/modules/atoms/dates';
import {
	getOrderProductSkuId,
	getOrderProductWithSetProducts,
	getProductDuration,
	getVariantIdsWithSku,
} from 'common/modules/orders';
import { getVariantSkuId } from 'common/modules/products/variants';
import { ById, Duration, OrderProduct, ProductApi } from 'common/types';
import { isSameDurationOption } from 'common/utils/duration';

import { getSelectedSkusCount } from './getAvailabilities';
import { ProductTotalAvailability, VariantAvailabilityObject } from './types';

export const isLiftTicket = (product: ProductApi) => product.external === 'LIFT_TICKET';

export interface OrderProductWithShopperName extends OrderProduct {
	shopperNames: string[];
}

export const dateIsInAvailabilityRange = (
	date: moment.Moment,
	rangeStart: moment.Moment,
	rangeEnd: moment.Moment,
) => {
	return date.isBetween(rangeStart, rangeEnd, undefined, '[)');
};

export const getSetProductsVariantsInfo = (
	product: ProductApi,
	setStockProducts: ProductApi[],
): VariantAvailabilityObject[] =>
	[product, ...setStockProducts.flat()].flatMap((p) => {
		return p.variants.options.map((v) => {
			const skuId = getVariantSkuId(v);
			return {
				variantId: v.id,
				skuId,
			};
		});
	});

export const getProductVariantsInfo = (product: ProductApi): VariantAvailabilityObject[] =>
	product.variants.options.map((v) => {
		const skuId = getVariantSkuId(v);
		return {
			variantId: v.id,
			skuId,
		};
	});

export const reduceToObject = <T>(
	arrayOfObjects: Array<{ [key: string]: T }>,
): { [key: string]: T } =>
	reduce(
		arrayOfObjects,
		(obj, item) => {
			if (!isEmpty(item)) {
				const [[key, val]] = Object.entries(item);
				obj[key] = val;
			}
			return obj;
		},
		{},
	);

interface SelectedVariantAmountsWithTimes {
	id: string;
	productId: string;
	endDate: string | null;
	duration: Duration;
	startDate: string | null;
	amount: number;
}

const createNewSelectedSku = (
	skuId: string,
	orderProduct: OrderProduct,
	startDate?: string | null,
): SelectedVariantAmountsWithTimes => {
	return {
		id: skuId,
		endDate: orderProduct.endDate,
		startDate: startDate ?? orderProduct.startDate,
		duration: {
			durationInSeconds: orderProduct.rentalDurationInSeconds,
			durationType: orderProduct.durationType,
			durationName: orderProduct.durationName,
		},
		amount: 1,
		productId: orderProduct.productApiId,
	};
};

export const getSelectedSkuAmountsWithTimes = (products: OrderProduct[]) => {
	const productsOrdered = sortBy(products, ['startDate', 'endDate'], ['asc']);
	const selectedSkusWithTimes = productsOrdered.reduce((current, orderProduct) => {
		const productStartDate = orderProduct.startDate;
		const productEndDate = orderProduct.endDate;
		orderProduct.summary.skuIds.forEach((skuId) => {
			if (!productStartDate || !productEndDate) {
				const newSku = createNewSelectedSku(skuId, orderProduct);
				current.push(newSku);
				return;
			}
			const existingSkus = current.filter(
				(selectedSku) =>
					selectedSku.id === skuId &&
					selectedSku.startDate &&
					selectedSku.endDate &&
					doesDateRangeOverlap(
						productStartDate,
						productEndDate,
						selectedSku.startDate,
						selectedSku.endDate,
					),
			);
			if (!!existingSkus.length) {
				current.forEach((sku, index) => {
					if (sku.id === skuId) {
						const updatedSku = {
							...sku,
							amount: sku.amount + 1,
						};
						current[index] = updatedSku;
						if (index === current.length - 1 && orderProduct.endDate! > sku.endDate!) {
							const newSku = createNewSelectedSku(skuId, orderProduct, sku.endDate);
							current.push(newSku);
						}
					}
				});
			} else {
				const newSku = createNewSelectedSku(skuId, orderProduct);
				current.push(newSku);
			}
		});
		return current;
	}, [] as SelectedVariantAmountsWithTimes[]);
	return selectedSkusWithTimes;
};

export const getSelectedVariantAmountsWithTimes = (products: OrderProduct[]) => {
	const productsOrdered = sortBy(products, ['startDate', 'endDate'], ['asc']);
	const allProductsWithSetProducts = productsOrdered.flatMap(getOrderProductWithSetProducts);
	const skuIdVariantIdsMap = allProductsWithSetProducts.reduce((tot, product) => {
		const variantId = product.variant?.id;
		const skuId = getOrderProductSkuId(product);
		if (!skuId || !variantId) return tot;
		return {
			...tot,
			[skuId]: uniq([...(tot[skuId] ?? []), variantId]),
		};
	}, {} as { [skuId: string]: string[] });
	const selectedVariantCounts: SelectedVariantAmountsWithTimes[] = productsOrdered.reduce(
		(tot: SelectedVariantAmountsWithTimes[], product) => {
			const selectedSkuIds = product.summary.skuIds;
			const affectedVariantIds = selectedSkuIds.flatMap((skuId) => skuIdVariantIdsMap[skuId]);
			const { startDate, endDate } = product;
			const allVariantsWithTimes: SelectedVariantAmountsWithTimes[] = affectedVariantIds.map(
				(variantId) => {
					return {
						id: variantId,
						productId: product.productApiId,
						endDate,
						duration: getProductDuration(product),
						startDate,
						amount: 1,
					};
				},
			);
			return [...tot, ...allVariantsWithTimes];
		},
		[],
	);
	const combinedSelectedVariantCounts = selectedVariantCounts.reduce(
		(tot: SelectedVariantAmountsWithTimes[], variantWithTime) => {
			let foundMatch = false;
			const updatedExistingVariantObjects = tot.map((v) => {
				if (foundMatch) return v;
				const isMatch =
					v.productId === variantWithTime.productId &&
					v.id === variantWithTime.id &&
					v.startDate === variantWithTime.startDate &&
					v.endDate === variantWithTime.endDate &&
					isSameDurationOption(v.duration, variantWithTime.duration);
				if (isMatch) {
					foundMatch = true;
					return {
						...v,
						amount: v.amount + variantWithTime.amount,
					};
				}
				return v;
			});
			return foundMatch
				? updatedExistingVariantObjects
				: [...updatedExistingVariantObjects, variantWithTime];
		},
		[],
	);
	return combinedSelectedVariantCounts;
};

export const getOverbookedVariantIdsFromProductTotalAvailabilitiesWithTimes = (
	productTotalAvailability: ProductTotalAvailability[],
	products: OrderProduct[],
	fromTo?: {
		startDate: string;
		endDate: string;
	},
) => {
	const variantIds: string[] = [];
	const productsToCheck = fromTo
		? products.map((p) => {
				const { startDate, endDate } = fromTo;
				return {
					...p,
					startDate,
					endDate,
				};
		  })
		: products;
	const selectedSkuAmountsWithTimes = getSelectedSkuAmountsWithTimes(productsToCheck);
	productTotalAvailability.forEach((p) => {
		selectedSkuAmountsWithTimes.forEach((skuAmountWithTimes) => {
			const skuAvailability = p.availabilityRangesBySku[skuAmountWithTimes.id];
			if (!skuAvailability) return;

			const minAvailabilityDurinTimePeriod = calculateAvailabilityForTimePeriod(
				skuAmountWithTimes.startDate || '',
				skuAmountWithTimes.endDate || '',
				skuAvailability,
			);
			const skuAvailable = isSkuAvailable(
				minAvailabilityDurinTimePeriod,
				skuAmountWithTimes.amount,
			);
			if (!skuAvailable) {
				const variantIdsWithSku = getVariantIdsWithSku(skuAmountWithTimes.id, products);
				if (variantIdsWithSku.length) {
					variantIds.push(...variantIdsWithSku);
				}
			}
		});
	});
	return variantIds;
};

export const getOverbookedVariantIds = (
	productAvailabilities: ById<ProductTotalAvailability>,
	products: OrderProduct[],
): string[] => {
	const productsAvailability = Object.values(productAvailabilities).filter((p) =>
		products.some((product) => product.productApiIds.includes(p.productId)),
	);

	const variantIds = getOverbookedVariantIdsFromProductTotalAvailabilities(
		productsAvailability,
		products,
	);

	return uniq(variantIds);
};

export const isSkuAvailable = (availabilityUnits: number | null, selectedSkuCount: number) => {
	return (
		availabilityUnits === null ||
		(availabilityUnits !== null && availabilityUnits >= selectedSkuCount)
	);
};

export const getOverbookedVariantIdsFromProductTotalAvailabilities = (
	productTotalAvailability: ProductTotalAvailability[],
	products: OrderProduct[],
): string[] => {
	const variantIds: string[] = [];
	const selectedSkuCounts = getSelectedSkusCount(products);
	productTotalAvailability.forEach((p) => {
		Object.entries(p.availabilityRangesBySku).forEach(([skuId, availabilityRanges]) => {
			const selectedSkuCount = selectedSkuCounts[skuId];
			if (selectedSkuCount) {
				availabilityRanges.forEach((availabilityRange) => {
					const skuAvailable = isSkuAvailable(availabilityRange.units, selectedSkuCount);
					if (!skuAvailable) {
						const variantIdsWithSku = getVariantIdsWithSku(skuId, products);
						if (variantIdsWithSku.length) {
							variantIds.push(...variantIdsWithSku);
						}
					}
				});
			}
		});
	});
	return uniq(variantIds);
};

export const orderProductToAvailability = (orderProduct: OrderProduct) => ({
	id: orderProduct.id,
	unavailable: orderProduct.unavailable,
	cancelled: orderProduct.cancelled,
});

export const isRelevantAvailability = (args: {
	startDate: string;
	endDate: string;
	ignoredOrderProductIds?: string[];
}) => (productAvailability: OrderProductAvailability) => {
	const { startDate, endDate, ignoredOrderProductIds } = args;
	if (isCancelledOrIgnoredAvailability(productAvailability, ignoredOrderProductIds)) {
		return false;
	}

	const { from, until } = productAvailability.unavailable;
	if (from && until) {
		return doesDateRangeOverlap(startDate, endDate, from, until);
	}
	return false;
};

export const isCancelledOrIgnoredAvailability = (
	productAvailability: OrderProductAvailability,
	ignoredOrderProductIds?: string[],
) => {
	return (
		!!productAvailability.cancelled ||
		(!!ignoredOrderProductIds && ignoredOrderProductIds.includes(productAvailability.id))
	);
};
