import React from 'react';

import { Box, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { setCartOpen } from 'redux/actions/view';
import { ALL_CATEGORIES_ID } from 'views/Shop/Location/Browse/components/Sidebar';

import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';

interface Props {
	innerPadding?: number;
}

export const EditBag = ({ innerPadding }: Props) => {
	const classes = useStyles();
	const { pushRoute, Routes } = useRoutes();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleEditBagClick = () => {
		pushRoute(Routes.browse, {
			query: { category: ALL_CATEGORIES_ID },
		});
		dispatch(setCartOpen(true));
	};

	return (
		<Box className={classes.editCart}>
			<Box px={innerPadding ?? 2}>
				<Typography variant="caption" className={classes.editCartBtn} onClick={handleEditBagClick}>
					{t('common:actions.editBag', 'Edit bag')}
				</Typography>
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		editCart: {
			borderTop: `1px solid ${theme.palette.border.paper}`,
		},
		editCartBtn: {
			color: '#003bab',
			cursor: 'pointer',
			fontWeight: 400,
			'&:hover': {
				textDecoration: 'underline',
			},
			padding: theme.spacing(1, 0),
			display: 'inline-block',
		},
	}),
);
