import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import { getTranslation } from 'common/modules/translations';
import { Category } from 'common/types';
import { useRoutes } from 'services/routing/useRoutes';
import { getLiftTicketsFromPriceString } from 'utils/liftTicket';
import { getLiftTicketCategoryImageSrc } from 'utils/products';

interface Props {
	onClick?: (ticketCategory: Category) => void;
}

const TicketsGridItem = React.memo((props: Props) => {
	const classes = useStyles();
	const ticketCategory = useSelector(StockSelectors.liftTicketCategory);
	const ticketProducts = useSelector(StockSelectors.normalLiftTicketProducts);
	const { getPath } = useRoutes();

	const currency = useSelector(ShopSelectors.shopCurrency);
	const lang = useSelector(ViewSelectors.language);

	if (!ticketCategory) return null;

	return (
		<Link
			className={classes.wrapper}
			to={getPath('tickets')}
			onClick={() => props.onClick?.(ticketCategory)}
		>
			<Box className={classes.imageWrapper}>
				<Box className={classes.imageInnerWrapper}>
					<img
						src={getLiftTicketCategoryImageSrc(ticketCategory)}
						className={classes.image}
						alt=""
					/>
				</Box>
			</Box>
			<Box py={2}>
				<Typography variant="body1" gutterBottom className={classes.productName}>
					{getTranslation(ticketCategory.name, lang)}
				</Typography>
				<Typography variant="body2" color="textSecondary">
					{getLiftTicketsFromPriceString(ticketProducts, currency)}
				</Typography>
			</Box>
		</Link>
	);
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'block',
			[theme.breakpoints.up('md')]: {
				marginBottom: theme.spacing(2),
			},
			'&:hover *': {
				textDecoration: 'underline',
			},
			'&:hover img': {
				opacity: 0.85,
			},
			'&:focus img': {
				opacity: 0.85,
			},
		},
		imageWrapper: {
			width: '100%',
			padding: theme.spacing(2),
			border: '1px solid #f8f8f8',
		},
		imageInnerWrapper: {
			width: '100%',
			paddingTop: '100%',
			position: 'relative',
		},
		image: {
			width: '100%',
			height: '100%',
			position: 'absolute',
			top: 0,
			left: 0,
			objectPosition: 'center',
			objectFit: 'contain',
		},
		productName: {
			fontWeight: 500,
		},
	}),
);

export default TicketsGridItem;
