import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import * as CartActions from 'redux/actions/cart';

import {
	hasMatchingSubscriptionCommittedDate,
	isAutoRenewSubscriptionOrderProduct,
} from 'common/modules/subscriptions';
import { CartDelivery, ISOString } from 'common/types';
import { newFirestoreId } from 'common/utils/newRentalUtils';
import { CartRow } from 'services/types';
import { getProductIndexInCartItem } from 'utils/cart';

type CartState = {
	sessionId: string;
	items: CartRow[];
	reservationCreated?: ISOString;
	delivery?: CartDelivery;
};

const INITIAL_STATE: CartState = {
	sessionId: newFirestoreId(),
	items: [],
	delivery: undefined,
};

const CartReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(CartActions.addProductsToCart, (state, action) => {
		const products = action.payload;
		products.forEach((product) => {
			const index = state.items.findIndex(
				(item) =>
					item.startDate === product.startDate &&
					item.endDate === product.endDate &&
					item.purchaseType === product.purchaseType &&
					(isAutoRenewSubscriptionOrderProduct(product)
						? hasMatchingSubscriptionCommittedDate(
								product,
								item.products.map((p) => p.product),
						  )
						: true),
			);
			if (index === -1) {
				state.items.push({
					startDate: product.startDate,
					endDate: product.endDate,
					products: [
						{
							product,
							quantity: 1,
						},
					],
					purchaseType: product.purchaseType,
				});
			} else {
				const productIndex = getProductIndexInCartItem(state.items[index].products, product);
				if (productIndex === -1) {
					state.items[index].products.push({
						product,
						quantity: 1,
					});
				} else {
					state.items[index].products[productIndex].quantity += 1;
				}
			}
		});
	});

	builder.addCase(CartActions.decreaseItemQuantity, (state, action) => {
		const product = action.payload;

		const index = state.items.findIndex(
			(item) => item.startDate === product.startDate && item.endDate === product.endDate,
		);
		if (index !== -1) {
			const productIndex = getProductIndexInCartItem(state.items[index].products, product);
			const cartProduct = state.items[index].products[productIndex];
			if (productIndex !== -1) {
				const newQuantity = cartProduct.quantity - 1;

				if (newQuantity < 1) {
					if (state.items[index].products.length > 1) {
						state.items[index].products.splice(productIndex, 1);
					} else {
						state.items.splice(index, 1);
					}
				} else {
					cartProduct.quantity = newQuantity;
				}
			}
		}
	});

	builder.addCase(CartActions.increaseItemQuantity, (state, action) => {
		const product = action.payload;
		const index = state.items.findIndex(
			(item) => item.startDate === product.startDate && item.endDate === product.endDate,
		);
		if (index !== -1) {
			const productIndex = getProductIndexInCartItem(state.items[index].products, product);
			if (productIndex !== -1) {
				state.items[index].products[productIndex].quantity += 1;
			}
		}
	});

	builder.addCase(CartActions.removePackageTicketProductFromCart, (state, action) => {
		state.items.forEach((item) => {
			item.products = item.products.filter((p) => p.product.packageId !== action.payload);
		});
		state.items = state.items.filter((item) => item.products.length > 0);
	});

	builder.addCase(CartActions.updateCartItems, (state, action) => {
		state.items = action.payload;
	});

	builder.addCase(CartActions.createReservation.fulfilled, (state) => {
		state.reservationCreated = moment().toISOString();
	});

	builder.addCase(CartActions.removeReservation.fulfilled, (state) => {
		state.reservationCreated = undefined;
	});

	builder.addCase(CartActions.resetReservationCreated, (state) => {
		state.reservationCreated = undefined;
	});

	// NOTE! This is used in Checkout to replace cart with fetched order items. Should not reset reservation
	builder.addCase(CartActions.clearCartItemsForCheckoutCartReplace, (state, action) => {
		state.items = [];
	});

	builder.addCase(CartActions.resetCart, (state, action) => {
		state.items = [];
		state.delivery = undefined;
		state.sessionId = newFirestoreId();
		state.reservationCreated = undefined;
	});

	builder.addCase(CartActions.setDelivery, (state, action) => {
		state.delivery = action.payload;
	});

	builder.addCase(CartActions.removeDelivery, (state, action) => {
		state.delivery = undefined;
	});

	builder.addCase(CartActions.setDeliveryTimeslot, (state, action) => {
		if (state.delivery && state.delivery.to) {
			state.delivery = {
				...state.delivery,
				to: {
					...state.delivery.to,
					timeslot: action.payload,
				},
			};
		}
	});

	builder.addCase(CartActions.setPickupTimeslot, (state, action) => {
		if (state.delivery && state.delivery.from) {
			state.delivery = {
				...state.delivery,
				from: {
					...state.delivery.from,
					timeslot: action.payload,
				},
			};
		}
	});
});

export default CartReducer;
