import { SliderValue } from 'common/modules/customization';

export const gridColumnsAmountToValueDesktop = (grids: SliderValue | undefined) => {
	const DEFAULT_GRID_COLUMNS = 3;

	const gridValue = grids ?? DEFAULT_GRID_COLUMNS;

	const ITEMS_PER_ROW_TO_COLUMN_WIDTH = 12 / gridValue;
	return ITEMS_PER_ROW_TO_COLUMN_WIDTH;
};
