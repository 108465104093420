import React, { useEffect } from 'react';

import { Box, StyledEngineProvider, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import moment from 'moment-timezone';
import { useParams } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import * as NavActions from 'redux/actions/nav';
import * as ShopActions from 'redux/actions/shop';
import * as ViewActions from 'redux/actions/view';
import { setLanguage as setLanguageAction } from 'redux/actions/view';
import { useDispatch, useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';
import { getIsStringUsedInRoutes } from 'shared-server/routes';
import { getShopUrlOrDomain } from 'shared-server/url';
import LocationPage from 'views/Shop/Location';
import LocationsPage from 'views/Shop/Locations';

import EmptyState from 'common/components/states/EmptyState';
import LoadingState from 'common/components/states/LoadingState';
import { setGaUserProperties } from 'common/services/analytics';
import { isRunInIframe } from 'common/utils/browserUtils';
import { getIsRentleHostname } from 'common/utils/urls';
import Footer from 'components/Footer';
import MetaTags from 'components/MetaTags';
import Nav from 'components/NavBar';
import ShopNotFound from 'components/ShopNotFound';
import ShopSidebar from 'components/ShopSidebar';
import { StripeElementWrapper } from 'components/StripeElementWrapper';
import { useCookieConsent } from 'hooks/useCookieConsent';
import useExternalAuth from 'hooks/useExternalAuth';
import { useShopGoogleAnalytics } from 'hooks/useShopGoogleAnalytics';
import { setLanguage, useTranslation } from 'services/localization';
import { getRootRoute } from 'services/routing/utils';

const isEmbedded = isRunInIframe();

const Shop = () => {
	useShopGoogleAnalytics();
	useCookieConsent();
	const { isAuthInUse, initExternalAuth } = useExternalAuth();
	const dispatch = useDispatch();
	const { t, language } = useTranslation();
	const hostname = window.location.hostname;
	const { shopName: _shopName } = useParams<{ shopName?: string }>();
	const isCustomDomain = !getIsRentleHostname(hostname);
	const shopNamePath =
		!_shopName || isCustomDomain || getIsStringUsedInRoutes(_shopName) ? undefined : _shopName;

	const shopInfo = useSelector(ShopSelectors.shopPublicInfoData);
	const shopTimezone = useSelector(ShopSelectors.shopTimezone);
	const shopUrls = useSelector(ShopSelectors.shopUrlsData);
	const shopTheme = useSelector(ShopSelectors.shopTheme);

	const shopLanguages = useSelector(ShopSelectors.shopLanguages);
	const shopId = useSelector(ShopSelectors.shopId);

	const shopLoading = useSelector(ShopSelectors.shopLoading);
	const shopUrlsLoading = useSelector(ShopSelectors.shopUrlsLoading);
	const shopError = useSelector(ShopSelectors.shopError);
	const shopUrlsError = useSelector(ShopSelectors.shopUrlsError);
	const shopInfoLoading = useSelector(ShopSelectors.shopPublicInfoLoading);

	const allOnlineLocationsHidden = useSelector(ShopSelectors.allOnlineLocationsHidden);

	const sidebarOpen = useSelector(ViewSelectors.sidebarOpen);

	useEffect(() => {
		dispatch(ShopActions.getShopStripeAccountId(shopId));
	}, [dispatch, shopId]);

	useEffect(() => {
		if (isAuthInUse) {
			initExternalAuth();
		}
	}, [isAuthInUse, initExternalAuth]);

	useEffect(() => {
		dispatch(NavActions.setShopName(shopNamePath ?? null));
		dispatch(NavActions.setIsCustomStoreDomain(isCustomDomain));
		const shopDomainOrUrl = getShopUrlOrDomain({ hostname, shopNamePath });
		dispatch(ShopActions.updateShopListeners(shopDomainOrUrl));
	}, [shopNamePath, dispatch, hostname, isCustomDomain]);

	useEffect(() => {
		if (shopTimezone) {
			moment.tz.setDefault(shopTimezone);
		}
	}, [shopTimezone]);

	// This ensures that initial language that shop does not support gets changed to language shop does support
	useEffect(() => {
		if (shopLanguages?.length && !shopLanguages.includes(language)) {
			const hasEnglish = shopLanguages.includes('en');
			const languageToChange = hasEnglish ? 'en' : shopLanguages[0];
			dispatch(setLanguageAction(languageToChange));
			setLanguage(languageToChange);
		}
	}, [dispatch, language, shopLanguages]);

	useEffect(() => {
		if (!!shopId) {
			setGaUserProperties(
				{
					shop_id: shopId,
				},
				{ sendToAllTags: false },
			);
		}
	}, [shopId]);

	if (shopError || shopUrlsError) {
		return <ShopNotFound />;
	}

	if (shopLoading || shopUrlsLoading || shopInfoLoading) {
		return <LoadingState minHeight={400} />;
	}

	if (!shopUrls || !shopInfo) {
		return <ShopNotFound />;
	}

	if (allOnlineLocationsHidden) {
		return (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={shopTheme}>
					<EmptyState
						title={shopInfo.name}
						description={t(
							'Shop.allLocationsHidden',
							'We are currently closed - please come back later.',
						)}
					/>
				</ThemeProvider>
			</StyledEngineProvider>
		);
	}

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={shopTheme}>
				<CssBaseline />
				<StripeElementWrapper>
					<MetaTags type="shop" />
					<Box
						sx={{
							minHeight: isEmbedded ? '800px' : '100vh',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Box flex="0">
							<Switch>
								<Route
									exact
									path={getRootRoute({
										withShopPath: !isCustomDomain,
										withLocation: false,
									})}
									component={() => null}
								/>
								<Route
									component={() => (
										<>
											<Nav />
											<ShopSidebar
												open={sidebarOpen}
												onClose={() => dispatch(ViewActions.setSidebarOpen(false))}
											/>
										</>
									)}
								/>
							</Switch>
						</Box>
						<Box
							flex="1"
							style={{
								paddingBottom: isRunInIframe()
									? 440
									: 0 /* To prevent issues with overscroll bounce when reaching bottom */,
							}}
							data-iframe-height
						>
							<Switch>
								<Route
									exact
									path={getRootRoute({
										withShopPath: !isCustomDomain,
										withLocation: false,
									})}
									component={LocationsPage}
								/>
								<Route
									path={getRootRoute({
										withShopPath: !isCustomDomain,
										withLocation: true,
									})}
									component={LocationPage}
								/>
								<Route
									path={getRootRoute({
										withShopPath: !isCustomDomain,
										withLocation: false,
									})}
									component={LocationPage}
								/>
							</Switch>
						</Box>
						<Switch>
							<Route component={Footer} />
						</Switch>
					</Box>
				</StripeElementWrapper>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default Shop;
