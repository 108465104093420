import React from 'react';

import { Box, Typography } from '@mui/material';

import BackdropDialog from 'common/components/BackdropDialog';
import { isRunInIframe } from 'common/utils/browserUtils';
import { useTranslation } from 'services/localization';

interface Props {
	isOpen: boolean;
	confirm: () => void;
	cancel: () => void;
}

const DeliveryOptionChangeGuard = (props: Props) => {
	const { isOpen, confirm, cancel } = props;
	const { t } = useTranslation();

	return (
		<BackdropDialog
			isOpen={isOpen}
			onClose={() => cancel()}
			label={t('delivery.changeToStorePickup', 'Change to store pickup')}
			position={isRunInIframe() ? 'top' : 'center'}
			body={
				<>
					<Typography variant="body2">
						{t(
							'delivery.changeFromDelivery',
							'You are about to change from exploring products available for delivery to browse all products',
						)}
						.
					</Typography>
					<Box mt={2} />
					<Typography variant="body2">
						{t('Cart.resetCart', 'Your shopping bag will be reset')}.
					</Typography>
				</>
			}
			variant="danger"
			confirm={{
				label: t('delivery.changeToStorePickup', 'Change to store pickup'),
				callback: () => {
					confirm();
				},
				closeAfterDone: true,
			}}
			cancel={{
				label: t('delivery.keepShoppingWithDelivery', 'Keep shopping with delivery'),
				callback: () => {
					cancel();
				},
				closeAfterDone: true,
			}}
			buttonDirection="column-reverse"
			width="xs"
			buttonFullWidth
		/>
	);
};

export default DeliveryOptionChangeGuard;
