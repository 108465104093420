import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import rentleLogo from 'common/assets/rentle-logo/rentle-logo-twocolor.svg';
import { useTranslation } from 'services/localization';

import MetaTags from './MetaTags';

const ShopNotFound = () => {
	const { t } = useTranslation();
	return (
		<>
			<MetaTags type="no-shop-found" />
			<Box pt={8} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<Box maxWidth={410} px={4}>
					<Box>
						<img
							src={rentleLogo}
							alt="Rentle logo"
							style={{ objectFit: 'contain', height: 38, width: '100%', margin: '0 auto' }}
						/>
					</Box>
					<Box mt={6}>
						<Typography variant="h5" component="h1" align="center">
							{`${t(
								'shopNotFound.createYourOnlineStoreInMinutesWithRentle',
								'Create your online store in minutes with Rentle',
							)}.`}
						</Typography>
					</Box>
					<Box mt={4}>
						<Typography variant="body1" textAlign="center">
							<a style={{ color: '#FF533B' }} href="https://rentle.io">
								{'Rentle'}
							</a>{' '}
							{`${t(
								'shopNotFound.platformToSellAndRent',
								'is the platform to sell and rent anything, anywhere',
							)}.`}
						</Typography>
					</Box>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						mt={6}
					>
						<Button
							variant="contained"
							href="https://admin.rentle.io/register"
							sx={{
								backgroundColor: (theme) => theme.palette.colors.rentleGreen.dark,
								borderRadius: 100,
								padding: '8px 24px',
							}}
						>
							{t('shopNotFound.createFreeStore', 'Create a free Store')}
						</Button>
						<Box mt={1} />
						<Button
							variant="outlined"
							href="https://rentle.io"
							sx={{
								minWidth: 204,
								borderColor: (theme) => theme.palette.colors.rentleGreen.dark,
								borderRadius: 100,
								color: (theme) => theme.palette.colors.rentleGreen.dark,
							}}
						>
							{t('common:actions.learnMore', 'Learn more')}
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default ShopNotFound;
