import React from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PaymentButton from 'common/components/payment/PaymentButton';

interface Props {
	onClick: () => void;
	loading: boolean;
	error?: string;
}

export const ConfirmBookingBtn = (props: Props) => {
	const { t } = useTranslation();
	return (
		<Box maxWidth="500" margin="auto">
			<PaymentButton
				onClick={props.onClick}
				loading={props.loading}
				error={props.error}
				label={t('confirmation.confirmBtn', 'Confirm booking')}
			/>
		</Box>
	);
};
