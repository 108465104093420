import { useEffect, useRef, useState } from 'react';

const useHeight = (): [React.RefObject<HTMLDivElement>, number] => {
	const ref = useRef<HTMLDivElement>(null);
	const [height, setHeight] = useState(0);

	// We specifically want this to run on every render
	//
	// eslint-disable-next-line
	useEffect(() => {
		setHeight((currHeight) => {
			if (ref.current && currHeight !== ref.current.clientHeight) {
				return ref.current.clientHeight;
			}
			return currHeight;
		});
	});

	return [ref, height];
};

export default useHeight;
