import { ItemListSelection } from 'redux/reducers/analytics';

import { Item, NO_CATEGORY } from 'common/services/analytics';
import {
	AmountObject,
	ById,
	CartProduct,
	Category,
	Duration,
	Location,
	OrderProduct,
	ProductApi,
} from 'common/types';
import { centsToMajorUnits, notFalsey } from 'common/utils/common';
import { getDurationString } from 'common/utils/dateCalculations';
import {
	isKeycardProduct,
	isLiftTicketCategory,
	isLiftTicketProduct,
} from 'common/utils/liftTicketUtils';
import {
	getOrderProductsWithUnitsFromOrderProductList,
	getVariantName,
} from 'common/utils/productUtils';
import { t } from 'services/localization';
import { CartRow } from 'services/types';

export const getItemListInfo = (
	categoryId: string | null,
	categoriesById: ById<Category>,
): { item_list_name: string; item_list_id: string } => {
	if (!categoryId) {
		return {
			item_list_name: 'All',
			item_list_id: 'all',
		};
	}
	return {
		item_list_name: categoriesById[categoryId]?.name?.def ?? 'Unknown',
		item_list_id: categoryId,
	};
};
interface ItemFromStockProductsParams {
	categoriesById: ById<Category>;
	location?: Location;
	priceInMajorUnits: AmountObject;
	itemListSelectionById: ById<ItemListSelection | undefined>;
}

export const getAnalyticsItemFromStockProduct = (
	product: ProductApi,
	params: ItemFromStockProductsParams,
): Item => {
	const { categoriesById, location, priceInMajorUnits, itemListSelectionById } = params;
	const { id, name, categoryIds } = product;
	const { item_list_name, item_list_id, index } = getItemListSelection(
		product.id,
		itemListSelectionById,
	);
	const locationId = location?.id;
	const locationName = location?.name;
	const itemCategories = getItemCategoryFields(categoryIds, categoriesById);
	return {
		item_id: id,
		item_name: name.def,
		item_list_name,
		item_list_id,
		index,
		price: priceInMajorUnits.value,
		currency: priceInMajorUnits.currency,
		quantity: 1,
		affiliation: locationName,
		location_id: locationId,
		...itemCategories,
	};
};

export const getAnalyticsItemFromLiftTicket = (
	liftTicketCategory: Category,
	params: Omit<ItemFromStockProductsParams, 'categoriesById'>,
): Item => {
	const { location, priceInMajorUnits, itemListSelectionById } = params;
	const { item_list_name, item_list_id, index } = getItemListSelection(
		liftTicketCategory.id,
		itemListSelectionById,
	);
	const { id, name } = liftTicketCategory;
	const locationId = location?.id;
	const locationName = location?.name;
	return {
		item_id: id,
		item_name: name.def,
		item_list_name,
		item_list_id,
		index,
		price: priceInMajorUnits.value,
		currency: priceInMajorUnits.currency,
		quantity: 1,
		affiliation: locationName,
		location_id: locationId,
		item_category: name.def,
	};
};

export const getAnalyticsItemFromCartProduct = (
	product: CartProduct,
	quantity: number,
	params: Omit<ItemFromStockProductsParams, 'priceInMajorUnits'>,
): Item => {
	const { categoriesById, location, itemListSelectionById } = params;
	const { item_id, item_name, item_variant } = getAnalyticsItemInfo(product, categoriesById);
	const { item_list_name, item_list_id, index } = getItemListSelection(
		item_id,
		itemListSelectionById,
	);
	const itemCategoryFields = getItemCategoryFields(product.categoryIds, categoriesById);
	const locationId = location?.id;
	const locationName = location?.name;
	const discountCode: string | undefined =
		Object.keys(product.pricing.discountCodes ?? {})[0] ?? undefined;
	return {
		item_id,
		item_name,
		item_list_name,
		item_list_id,
		index,
		quantity,
		price: centsToMajorUnits(product.pricing.listPrice),
		discount: centsToMajorUnits(product.pricing.totalDiscounts),
		coupon: discountCode,
		affiliation: locationName,
		location_id: locationId,
		currency: product.pricing.currency,
		item_variant,
		...itemCategoryFields,
	};
};

export const getAnalyticsItemsFromCartItem = (
	cartItem: CartRow,
	params: Omit<ItemFromStockProductsParams, 'priceInMajorUnits'>,
): Item[] => {
	const { products } = cartItem;
	return products.map((p) => {
		const { quantity, product } = p;
		return getAnalyticsItemFromCartProduct(product, quantity, params);
	});
};

export const getAnalyticsItemsFromOrderProducts = (
	orderProducts: OrderProduct[],
	params: Omit<ItemFromStockProductsParams, 'priceInMajorUnits'>,
): Item[] => {
	const orderProductsWithUnits = getOrderProductsWithUnitsFromOrderProductList(orderProducts);
	const { categoriesById, location, itemListSelectionById } = params;
	return orderProductsWithUnits.map((product) => {
		const { categoryIds, units } = product;
		const { item_id, item_name, item_variant } = getAnalyticsItemInfo(product, categoriesById);
		const { item_list_name, item_list_id, index } = getItemListSelection(
			item_id,
			itemListSelectionById,
		);
		const itemCategoryFields = getItemCategoryFields(categoryIds, categoriesById);
		const locationId = location?.id;
		const locationName = location?.name;
		const discountCode: string | undefined =
			Object.keys(product.pricing.discountCodes ?? {})[0] ?? undefined;
		return {
			item_name,
			item_id,
			item_list_name,
			item_list_id,
			index,
			price: centsToMajorUnits(Math.round(product.pricing.listPrice / units)),
			currency: product.pricing.currency,
			quantity: units,
			...(!!product.pricing.totalDiscounts && {
				discount: centsToMajorUnits(product.pricing.totalDiscounts),
			}),
			coupon: discountCode,
			affiliation: locationName,
			location_id: locationId,
			item_variant,
			...itemCategoryFields,
		};
	});
};

const getAnalyticsItemInfo = (
	product: CartProduct | OrderProduct,
	categoriesById: ById<Category>,
) => {
	const liftTicketProduct = isLiftTicketProduct(product);
	const keyCardProduct = isKeycardProduct(product);
	const categories = product.categoryIds?.map((id) => categoriesById[id]) ?? [];
	const liftTicketCategory = categories.find(isLiftTicketCategory);
	const liftTicketInAnalytics = liftTicketProduct && !keyCardProduct && !!liftTicketCategory;
	const item_id = liftTicketInAnalytics ? liftTicketCategory!.id : product.productApiId;
	const item_name = liftTicketInAnalytics ? liftTicketCategory!.name.def : product.name.def;
	const item_variant = getAnalyticsItemVariant(product, categoriesById);
	return { item_id, item_name, item_variant };
};

const getAnalyticsItemVariant = (
	product: CartProduct | OrderProduct,
	categoriesById: ById<Category>,
) => {
	const liftTicketProduct = isLiftTicketProduct(product);
	const keyCardProduct = isKeycardProduct(product);
	const categories = product.categoryIds?.map((id) => categoriesById[id]) ?? [];
	const liftTicketCategory = categories.find(isLiftTicketCategory);
	const liftTicketInAnalytics = liftTicketProduct && !keyCardProduct && !!liftTicketCategory;
	if (liftTicketInAnalytics && !!liftTicketCategory) {
		const ticketName = product.name.def;
		const segmentName = product.externalSegment?.name?.def;
		return [ticketName, segmentName].filter(notFalsey).join(', ');
	}
	const selectedVariant = product.variant;
	const duration: Duration = {
		durationInSeconds: product.rentalDurationInSeconds,
		durationType: product.durationType,
		durationName: product.durationName,
	};
	const durationString = getDurationString(duration, 'short', 'en', t) || undefined;
	const variantName = selectedVariant ? getVariantName(selectedVariant, 'def') : undefined;
	const item_variant =
		!!durationString || !!variantName
			? [durationString, variantName].filter(notFalsey).join(', ')
			: undefined;
	return item_variant;
};

// Returns item categories in Ecom analytics supported format: item_category, item_category2, ...
const getItemCategoryFields = (
	categoryIds: string[] | undefined,
	categoriesById: ById<Category>,
) => {
	if (!categoryIds?.length) {
		return { item_category: NO_CATEGORY };
	}
	return categoryIds?.reduce((acc, curr, index) => {
		if (index > 4) return acc;
		const categoryId = curr;
		const categoryName = categoriesById[categoryId]?.name?.def ?? NO_CATEGORY;
		const categoryKey = index === 0 ? 'item_category' : `item_category${index + 1}`;
		return {
			...acc,
			[categoryKey]: categoryName,
		};
	}, {} as { [key: string]: string });
};

const getItemListSelection = (
	productApiId: string,
	itemListSelectionById?: ById<ItemListSelection | undefined>,
) => {
	const item_list_name = itemListSelectionById?.[productApiId]?.name;
	const item_list_id = itemListSelectionById?.[productApiId]?.id;
	const index = itemListSelectionById?.[productApiId]?.index;
	return { item_list_name, item_list_id, index };
};

export const getRecommendedItemListInfo = () => ({
	item_list_id: 'recommended_products',
	item_list_name: 'Recommended products',
});
