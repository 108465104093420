import React from 'react';

import { Box } from '@mui/material';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';

import Select from 'common/components/pickers/Select';
import { getDurationLabel } from 'common/constants/timePeriods';
import { SubscriptionAutoRenewOptions } from 'common/modules/subscriptions/constants';
import { getPricingString, getPricingStringPerDuration } from 'common/utils/common';
import { multiplyPrice } from 'common/utils/pricing';
import ErrorText from 'components/ErrorText';
import { SubscriptionOptionWithId } from 'hooks/useSubscriptionOptions';
import { useTranslation } from 'services/localization';

interface Props {
	options: SubscriptionOptionWithId[];
	value: string | null;
	onChange: (value: string) => void;
	quantity: number;
	error?: string | null;
	disabled?: boolean;
}

const SubscriptionOptionSelector = React.memo((props: Props) => {
	const { options, value, onChange, error, disabled, quantity } = props;
	const { t } = useTranslation();
	const currency = useSelector(ShopSelectors.shopCurrency);

	const getSubscriptionPrice = (option: SubscriptionOptionWithId) => {
		const priceWithQuantity = multiplyPrice(option.option.pricePerCycle, quantity);
		return getPricingStringPerDuration(
			getPricingString(priceWithQuantity.value, currency),
			'month',
			t,
		);
	};

	return (
		<Box>
			<Select.Single
				label=""
				emptyLabel={t('common:prompts.chooseDuration', 'Choose duration')}
				value={value}
				hideEmptyOption
				indicators="none"
				onChange={(value) => value && onChange(value)}
				disabled={disabled}
				options={options.map((option) => ({
					id: option.id,
					label: getDurationLabel(
						option.option.minCommitment.unit,
						option.option.minCommitment.value,
						t,
						option.option?.autoRenew === SubscriptionAutoRenewOptions.cycle,
					),
					description: '',
					rightContent: getSubscriptionPrice(option),
				}))}
			/>
			{error && (
				<Box mt={1}>
					<ErrorText>{error}</ErrorText>
				</Box>
			)}
		</Box>
	);
});

export default SubscriptionOptionSelector;
