import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import { AvailabilityRange } from 'common/api/frontend/inventory_new/availabilityRange';
import useAvailability from 'common/hooks/useAvailability';
import { ByVariant, LoadingData, PurchaseType } from 'common/types';
import { isUsingServerAvailabilityFetch } from 'utils/availability';
import { getVariantAvailabilityWithCart } from 'utils/cart';

interface AvailabilityOptions {
	startDate: string;
	endDate: string;
	locationId: string;
	purchaseType: PurchaseType;
}

const useOnlineAvailability = (
	productId: string,
	options: AvailabilityOptions,
): ReturnType<typeof useAvailability> => {
	const stockProducts = useSelector(StockSelectors.stockProductsData);
	const shopId = useSelector(ShopSelectors.shopId);
	const onlineLocations = useSelector(ShopSelectors.onlineLocations);
	const reservationId = useSelector(CartSelectors.sessionId);
	const cartItems = useSelector(CartSelectors.items);
	const salesChannel = 'ONLINE';
	const { startDate, endDate, locationId, purchaseType } = options;

	const productStockAvailability = useAvailability(productId, {
		startDate,
		endDate,
		locationId,
		shopLocations: onlineLocations,
		stockProducts,
		shopId,
		reservationId,
		salesChannel,
		useServerFetching: isUsingServerAvailabilityFetch(shopId),
		purchaseType,
	});

	const availabilityWithCart: LoadingData<ByVariant<AvailabilityRange[]>> = useMemo(() => {
		const stockAvailability = productStockAvailability.availability;
		if (!stockAvailability.data) return stockAvailability;
		return {
			loading: false,
			error: null,
			data: getVariantAvailabilityWithCart(
				stockAvailability.data,
				cartItems,
				stockProducts,
				purchaseType,
			),
		};
	}, [cartItems, stockProducts, productStockAvailability.availability, purchaseType]);

	return {
		...productStockAvailability,
		availability: availabilityWithCart,
	};
};

export default useOnlineAvailability;
