import React from 'react';

import { Typography } from '@mui/material';

import Container from 'common/components/Container';
import LinkButton from 'common/components/LinkButton';
import { useTranslation } from 'services/localization/useTranslation';
import { useRoutes } from 'services/routing/useRoutes';

const OrderFetchError = () => {
	const { t } = useTranslation();
	const { getPath, Routes } = useRoutes();

	return (
		<Container style={{ textAlign: 'center' }}>
			<Typography variant="h5">
				{t('payment.errorGettingOrder', 'Error getting your order')}
			</Typography>
			<LinkButton
				style={{ marginTop: 32 }}
				variant="contained"
				color="primary"
				to={getPath(Routes.browse)}
			>
				{t('payment.backToHome', 'Back to home page')}
			</LinkButton>
		</Container>
	);
};

export default OrderFetchError;
