import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { ByVariant, ISOString, LoadingData, StartTimeCount } from 'common/types';
import { AvailabilityByTimeslot } from 'utils/delivery';

import { AvailabilityByStartTime, InvalidStartTimeReason } from './availability';

export const MAX_CALENDAR_RANGE: [number, moment.DurationInputArg2] = [1.5, 'years'];

export interface StartTimesWithValidity {
	hours: number;
	minutes: number;
	value: string;
	units: number;
	disabled: boolean;
	reason: InvalidStartTimeReason | null;
}

export const getStartTimesWithValidityForSelectedDate = (
	availabilityByStartTime: AvailabilityByStartTime | null,
	selectedQuantity: number,
	dayString: string | null,
): StartTimesWithValidity[] => {
	if (!dayString) return [];
	const availabilityForSelectedDate = availabilityByStartTime?.[dayString] ?? {};

	return Object.entries(availabilityForSelectedDate).map(([startTime, availability]) => {
		const [hours, minutes] = startTime.split(':').map(Number);
		return {
			hours,
			minutes,
			value: moment(dayString).hour(hours).minute(minutes).toISOString(),
			units: availability.units,
			disabled: !availability.valid || availability.units < selectedQuantity,
			reason: !availability.valid ? availability.reason : null,
		};
	});
};

export const getEarliestAvailableDateForDayString = (
	availabilityByStartTime: AvailabilityByStartTime | AvailabilityByTimeslot | null,
	selectedQuantity: number,
) => (dayString: string) => {
	if (!availabilityByStartTime) return undefined;
	const dayAvailableTimes = availabilityByStartTime[dayString] ?? {};
	if (!isEmpty(dayAvailableTimes)) {
		const earliestTime = Object.keys(dayAvailableTimes).find((time) => {
			const availability = dayAvailableTimes[time];
			return availability.valid && availability.units && availability.units >= selectedQuantity;
		});
		if (earliestTime) {
			return moment(`${dayString}T${earliestTime}`).toISOString();
		}
	}
	return undefined;
};

export const getSelectedVariantStartTimes = (
	variantId: string | undefined,
	startTimeCounts: LoadingData<ByVariant<StartTimeCount>>,
) => {
	if (!variantId || !startTimeCounts.data || isEmpty(startTimeCounts.data)) return null;
	return startTimeCounts.data[variantId] ?? {};
};

export const getHasAvailabilityByStartTime = (
	availabilityByStartTime: AvailabilityByStartTime,
	selectedQuantity: number,
) => {
	if (!Object.keys(availabilityByStartTime).length) return false;
	for (const date of Object.keys(availabilityByStartTime)) {
		const availabilities = Object.values(availabilityByStartTime[date]);
		const hasValidStartTime = availabilities.some(({ units }) => units >= selectedQuantity);
		if (hasValidStartTime) {
			return true;
		}
	}
	return false;
};

export const isStartTimeAvailableForDateTime = (
	_date: ISOString,
	availabilityByStartTime: AvailabilityByStartTime | null,
	selectedQuantity: number,
) => {
	if (!availabilityByStartTime) return true;
	const [date, time] = moment(_date).format('YYYY-MM-DD HH:mm').split(' ');
	const startTimeWithValidity = availabilityByStartTime[date]?.[time];
	const availableUnits = availabilityByStartTime[date]?.[time]?.units ?? 0;
	return (
		!!startTimeWithValidity && startTimeWithValidity.valid && availableUnits >= selectedQuantity
	);
};

export const isStartTimeAvailable = (
	startTime: ISOString,
	availabilityByStartTime: AvailabilityByStartTime | null,
	selectedQuantity: number,
) => {
	if (!availabilityByStartTime) return true;
	const date = moment(startTime).format('YYYY-MM-DD');
	const time = moment(startTime).format('HH:mm');
	const availability = availabilityByStartTime[date]?.[time];

	return availability?.valid && (availability.units ?? 0) >= selectedQuantity;
};

export const isStartTimeAvailableForDay = (
	startDate: ISOString,
	availabilityByStartTime: AvailabilityByStartTime,
	selectedQuantity: number,
) => {
	if (!availabilityByStartTime) return true;
	const date = moment(startDate).format('YYYY-MM-DD');
	const availabilities = availabilityByStartTime[date]
		? Object.values(availabilityByStartTime[date])
		: undefined;
	if (!availabilities?.length) {
		return false;
	}
	return availabilities.every(({ units }) => units >= selectedQuantity);
};

export const getUnloadedStartTimesRange = (
	calendarMonth: ISOString,
	isMobile: boolean,
	prevAvailabilityByStartTime: AvailabilityByStartTime | null,
) => {
	const _rangeStart = moment(calendarMonth).startOf('month');
	const rangeStart = moment().isAfter(_rangeStart) ? moment() : _rangeStart;
	const rangeEnd = moment(calendarMonth)
		.add(isMobile ? 1 : 2, 'month')
		.endOf('month');
	const { start, end } = getUnloadedAvailabilityByStartTimeRange(
		rangeStart,
		rangeEnd,
		prevAvailabilityByStartTime,
	);
	return { start, end };
};

const getUnloadedAvailabilityByStartTimeRange = (
	rangeStart: moment.Moment,
	rangeEnd: moment.Moment,
	availabilityByStartTime: AvailabilityByStartTime | null,
) => {
	const _rangeStart = moment(rangeStart);
	let start: moment.Moment | null = null;
	let end: moment.Moment | null = null;
	if (!availabilityByStartTime) return { start: rangeStart, end: rangeEnd };
	while (_rangeStart.isBefore(rangeEnd)) {
		if (!availabilityByStartTime?.[moment(_rangeStart).format('YYYY-MM-DD')]) {
			if (!start) {
				start = moment().isAfter(_rangeStart) ? moment() : moment(_rangeStart).startOf('month');
			}
			end = moment(_rangeStart).endOf('month');
		}
		_rangeStart.add(1, 'month');
	}
	return { start, end };
};

export const getMaxAvailabilityRangeYears = (durationInYears: number | null): number => {
	const [MAX_CALENDAR_YEARS] = MAX_CALENDAR_RANGE;
	return Math.max(MAX_CALENDAR_YEARS, durationInYears ?? 0);
};
