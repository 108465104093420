import { DeliveryOption, ProductApi } from 'common/types';
import { getAllProductsToFetchAvailabilityFor } from 'common/utils/newRentalUtils';

export const filterByLocation = (product: ProductApi, locationId: string) => {
	return product.visibleInLocations !== undefined
		? product.visibleInLocations.includes(locationId) || product.visibleInLocations.includes('ALL')
		: true;
};

export const filterByChannel = (product: ProductApi, channel: string) =>
	!product.hiddenFromChannels || !product.hiddenFromChannels[channel];

export const filterBySegment = (product: ProductApi, segment?: string) =>
	!segment ||
	!product.segmentFilters ||
	product.segmentFilters.length === 0 ||
	product.segmentFilters!.includes(segment!);

export const getLocationStockProducts = (
	allStockProducts: ProductApi[],
	locationId: string,
	channel: string,
) => {
	return allStockProducts
		? allStockProducts.filter(
				(product) => filterByLocation(product, locationId) && filterByChannel(product, channel),
		  )
		: [];
};

export const getStockProductsWithSetProducts = (
	stockProducts: ProductApi[],
	allStockProducts: ProductApi[],
) => {
	return getAllProductsToFetchAvailabilityFor(stockProducts, allStockProducts);
};

export const filterByDelivery = (
	product: ProductApi,
	deliveryProductIds: string[],
	deliveryFilter: 'all' | 'pick-up' | 'delivery',
) => {
	const isDeliveryProduct = deliveryProductIds.includes(product.id);
	switch (deliveryFilter) {
		case 'all':
			return true;
		case 'pick-up':
			return !isDeliveryProduct;
		case 'delivery':
			return isDeliveryProduct;
		default:
			return true;
	}
};

export const filterBySelectedDeliveryOption = (selectedDeliveryOption: DeliveryOption | null) => (
	product: ProductApi,
) => {
	return !selectedDeliveryOption || product.deliveryOptionIds?.includes(selectedDeliveryOption.id);
};
