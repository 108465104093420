import React, { useState } from 'react';

import { Box, Button, Grid, IconButton, TextField, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiCloseLine, RiPriceTag3Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { addDiscountCode, clearDiscountCodes, removeDiscountCode } from 'redux/actions/checkout';
import * as CartSelectors from 'redux/selectors/cart';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';

import Spinner from 'common/components/Spinner';
import { Callable } from 'common/frontend/callable';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { getPricingString } from 'common/utils/common';
import { useTranslation } from 'services/localization';

export const Discount = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const shopId = useSelector(ShopSelectors.shopId);
	const currency = useSelector(CheckoutSelectors.currency);
	const [discountCode, setDiscountCode] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const appliedDiscountCodes = useSelector(CheckoutSelectors.appliedDiscountCodes);
	const discountProductIds = useSelector(CheckoutSelectors.discountProductIds);
	const discountCategoryIds = useSelector(CheckoutSelectors.discountCategoryIds);
	const bookingStartDate = useSelector(CartSelectors.startDate);

	const handleApplyDiscountCode = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const code = discountCode;
		setLoading(true);
		try {
			const response = await Callable.discountCodes.validate({
				shopId,
				code,
				productIds: discountProductIds,
				categoryIds: discountCategoryIds,
				bookingStartDate,
			});
			if (!response.valid) {
				setLoading(false);
				setError(t('discountCodes.notValid', 'Code is not valid'));
				return;
			}
			const discountCode = response.code;
			// Currently only 1 discount code can be applied, clear all existing discount codes
			dispatch(clearDiscountCodes());
			dispatch(addDiscountCode(discountCode));
			setDiscountCode('');
			setError('');
			setLoading(false);
		} catch (e) {
			errorHandler.report(e);
			setError(t('common:errors.somethingWentWrong', 'Something went wrong - please try again.'));
			setLoading(false);
		}
	};

	const handleRemoveDiscountCode = (code: string) => {
		dispatch(removeDiscountCode(code));
	};

	const renderAppliedDiscountCodes = () => {
		if (!appliedDiscountCodes || !Object.keys(appliedDiscountCodes).length) {
			return null;
		}
		return Object.entries(appliedDiscountCodes).map(([code, discount]) => {
			return (
				<Grid
					container
					key={code}
					spacing={2}
					justifyContent="space-between"
					alignItems="center"
					wrap="nowrap"
				>
					<Grid item xs={false}>
						<Box className={classes.discountChip}>
							<Box display="flex" mr={1}>
								<RiPriceTag3Line className={classes.discountIcon} />
							</Box>
							<Typography variant="body2" color="textSecondary">
								{code}
							</Typography>
							<Box ml={1}>
								<IconButton
									color="inherit"
									size="small"
									onClick={() => handleRemoveDiscountCode(code)}
								>
									<RiCloseLine className={classes.discountIcon} />
								</IconButton>
							</Box>
						</Box>
					</Grid>
					<Grid item className={classes.flexTakeSpace}>
						<Typography variant="body2" align="right">
							-{getPricingString(discount.totalDiscountValue, currency)}
						</Typography>
					</Grid>
				</Grid>
			);
		});
	};

	return (
		<form onSubmit={handleApplyDiscountCode}>
			<Grid container spacing={2} wrap="nowrap" justifyContent="space-between">
				<Grid item xs>
					<TextField
						id="discount-code"
						placeholder={t('common:form.discountCode', 'Discount code')}
						variant="outlined"
						value={discountCode}
						onChange={(e) => setDiscountCode(e.target.value)}
						error={Boolean(error)}
						helperText={error}
						InputProps={{
							classes: {
								root: classes.input,
							},
						}}
						fullWidth
					/>
				</Grid>
				<Grid item>
					<Button
						className={classes.discountBtn}
						variant="contained"
						color="primary"
						disabled={!discountCode || loading}
						type="submit"
					>
						{loading ? (
							<Spinner color="white" relative size={20} />
						) : (
							t('discountCodes.apply', 'Apply')
						)}
					</Button>
				</Grid>
			</Grid>
			<Box mt={1}>{renderAppliedDiscountCodes()}</Box>
		</form>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		input: {
			height: 40,
			borderRadius: 0,
		},
		discountBtn: {
			height: 40,
		},
		flexTakeSpace: {
			flex: '1 0 auto',
		},
		flexGiveSpace: {
			flex: '1 1 0',
		},
		discountIcon: {
			color: theme.palette.secondary.main,
			fontSize: '1.6rem',
			width: 16,
			height: 16,
		},
		discountChip: {
			borderRadius: 2,
			backgroundColor: '#f2f2f2',
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(0.5),
		},
	}),
);

export default Discount;
