import { Api } from 'common/db/api/paths';
import { getSkuIds, getVariantIds } from 'common/modules/orders';
import {
	getProductsInPackage,
	isPackageProduct,
	isProductInPackage,
	variantIdToSkuId,
} from 'common/modules/products/utils';
import {
	getProductVariants,
	getVariantSkuId,
	getVariantSkuIds,
} from 'common/modules/products/variants';
import {
	BySku,
	ByVariant,
	OrderProduct,
	ProductApi,
	PurchaseType,
	PurchaseTypes,
	StartTimeCount,
} from 'common/types';
import { notNull, switchUnreachable } from 'common/utils/common';
import { productHasStartTimeLimits, productHasUnlimitedStock } from 'common/utils/productUtils';

import {
	AvailabilityRange,
	GetAvailabilityProps,
	getAvailabilityRangesAndStartTimes,
	getInitialAvailabilityRange,
	getMinAvailabilityFromRange,
	getSalesAvailabilityRanges,
} from './availabilityRange';
import { AvailabilityDataSources, ProductTotalAvailability } from './types';
import { getProductVariantsInfo, getSetProductsVariantsInfo } from './utils';

export interface HandleAvailabilityFetchProps {
	product: ProductApi;
	stockProducts: ProductApi[];
	startDate: string;
	endDate: string;
	startLocationId: string;
	quantities: { [variantId: string]: number | null };
	reservationId?: string;
	ignoredOrderProductIds?: string[];
	dataSources?: AvailabilityDataSources;
	api: Api;
	purchaseType: PurchaseType;
}

export const handleAvailabilityFetch = async (props: HandleAvailabilityFetchProps) => {
	const { product, purchaseType } = props;
	const hasUnlimitedAvailability =
		productHasUnlimitedStock(product) && !productHasStartTimeLimits(product, purchaseType);
	return hasUnlimitedAvailability
		? getUnlimitedAvailabilityObject(product, props.startDate, props.endDate)
		: getProductAvailabilities(props);
};

const getUnlimitedAvailabilityObject = (
	product: ProductApi,
	startDate: string,
	endDate: string,
): ProductAvailability => {
	const availabilityRangesBySku: ByVariant<AvailabilityRange[]> = product.variants.options.reduce(
		(acc, variant) => {
			const skuId = getVariantSkuId(variant);
			if (!skuId) return acc;
			const fullAvailabilityRange = getInitialAvailabilityRange(null, startDate, endDate);
			acc[skuId] = [fullAvailabilityRange];
			return acc;
		},
		{},
	);

	const availabilityRangesByVariant: ByVariant<
		AvailabilityRange[]
	> = product.variants.options.reduce((acc, variant) => {
		const fullAvailabilityRange = getInitialAvailabilityRange(null, startDate, endDate);
		acc[variant.id] = [fullAvailabilityRange];
		return acc;
	}, {});

	const startTimeCountsByVariant: ByVariant<StartTimeCount> = product.variants.options.reduce(
		(acc, variant) => {
			acc[variant.id] = {};
			return acc;
		},
		{},
	);

	const minAvailabilitiesByVariant = Object.keys(availabilityRangesBySku).reduce(
		(tot, skuId) => ({ ...tot, [skuId]: null }),
		{},
	);

	return {
		id: product.id,
		productAvailability: null,
		productAvailabilityWithOrderProducts: undefined,
		availabilityRangesBySku,
		availabilityRangesByVariant,
		startTimeCountsByVariant,
		minAvailabilitiesByVariant,
	};
};

export interface ProductAvailability {
	id: string;
	productAvailability: number | null;
	productAvailabilityWithOrderProducts: number | null | undefined;
	availabilityRangesByVariant: ByVariant<AvailabilityRange[]>;
	availabilityRangesBySku: BySku<AvailabilityRange[]>;
	minAvailabilitiesByVariant: ByVariant<number | null>;
	startTimeCountsByVariant: ByVariant<StartTimeCount>;
}

const getProductAvailabilities = async (
	props: HandleAvailabilityFetchProps,
): Promise<ProductAvailability> => {
	const {
		product,
		stockProducts,
		startDate,
		endDate,
		reservationId,
		startLocationId,
		quantities,
		ignoredOrderProductIds,
		dataSources,
		purchaseType,
		api,
	} = props;

	const isSetProduct = isPackageProduct(product);

	const setStockProducts = stockProducts.filter((p) => isProductInPackage(p.id, product));

	const productVariantsInfo = isSetProduct
		? getSetProductsVariantsInfo(product, setStockProducts)
		: getProductVariantsInfo(product);

	const startTimesByVariant: ByVariant<StartTimeCount> = {};
	const availabilityRangesBySku: BySku<AvailabilityRange[]> = {};
	const availabilityRangesByVariant: ByVariant<AvailabilityRange[]> = {};
	await Promise.all(
		productVariantsInfo.map(async (variantObject) => {
			const { variantId, skuId } = variantObject;

			const getAvailabilityProps: GetAvailabilityProps = {
				totalQuantity: quantities[variantId] ?? null,
				variantObject,
				startDate,
				endDate,
				ownReservationId: reservationId,
				startLocationId,
				ignoredOrderProductIds,
				dataSources,
				hasStartTimeLimit: productHasStartTimeLimits(product, purchaseType),
				productId: product.id,
				api,
			};

			switch (purchaseType) {
				case PurchaseTypes.subscription:
				case PurchaseTypes.rental: {
					const availabilities = await getAvailabilityRangesAndStartTimes(getAvailabilityProps);
					startTimesByVariant[variantId] = availabilities.startTimeCounts;
					availabilityRangesByVariant[variantId] = availabilities.availabilityRanges;
					if (skuId) {
						availabilityRangesBySku[skuId] = availabilities.availabilityRanges;
					}
					break;
				}
				case PurchaseTypes.sales: {
					const availabilities = await getSalesAvailabilityRanges(getAvailabilityProps);
					availabilityRangesByVariant[variantId] = availabilities.availabilityRanges;
					if (skuId) {
						availabilityRangesBySku[skuId] = availabilities.availabilityRanges;
					}
					break;
				}
				default: {
					switchUnreachable(purchaseType);
				}
			}
		}),
	);

	return getProductAvailabilityFromAvailabilityDetails({
		productId: product.id,
		startTimesByVariant,
		availabilityRangesBySku,
		availabilityRangesByVariant,
		isSetProduct,
		setStockProducts,
	});
};

const getProductAvailabilityFromAvailabilityDetails = (args: {
	productId: string;
	startTimesByVariant: ByVariant<StartTimeCount>;
	availabilityRangesBySku: BySku<AvailabilityRange[]>;
	availabilityRangesByVariant: ByVariant<AvailabilityRange[]>;
	isSetProduct: boolean;
	setStockProducts: ProductApi[];
}): ProductAvailability => {
	const {
		productId,
		startTimesByVariant,
		availabilityRangesBySku,
		availabilityRangesByVariant,
		isSetProduct,
		setStockProducts,
	} = args;

	const minProductAvailability = isSetProduct
		? calculateMinTotalAvailabilityFromSetProductAvailabilities(
				availabilityRangesBySku,
				setStockProducts,
				null,
		  )
		: getTotAvailabilityFromSkuAvailabilities(availabilityRangesBySku, null);

	const minAvailabilitiesByVariant = Object.keys(availabilityRangesByVariant).reduce(
		(tot, variantId) => ({
			...tot,
			[variantId]: getMinAvailabilityFromRange(availabilityRangesByVariant[variantId]),
		}),
		{},
	);

	const productAvailability = {
		id: productId,
		productAvailability: minProductAvailability,
		productAvailabilityWithOrderProducts: undefined,
		availabilityRangesBySku,
		availabilityRangesByVariant,
		startTimeCountsByVariant: startTimesByVariant,
		minAvailabilitiesByVariant,
	};

	return productAvailability;
};

const calculateMinTotalAvailabilityFromSetProductAvailabilities = (
	availabilitiesBySku: BySku<AvailabilityRange[]>,
	setStockProducts: ProductApi[],
	alreadySelectedSkus: BySku<number> | null,
) => {
	const setProductSkuGroups = setStockProducts.map((p) => {
		const productVariants = getProductVariants(p);
		return getVariantSkuIds(productVariants);
	});
	const setProductTotalSkuAvailabilities = setProductSkuGroups.map((skuIds) => {
		const setProductSkuAvailabilities: BySku<AvailabilityRange[]> = skuIds.reduce((acc, skuId) => {
			if (availabilitiesBySku[skuId] !== undefined) {
				acc[skuId] = availabilitiesBySku[skuId];
			}
			return acc;
		}, {});
		return getTotAvailabilityFromSkuAvailabilities(
			setProductSkuAvailabilities,
			alreadySelectedSkus,
		);
	});
	const setProductsWithLimitedAvailability = setProductTotalSkuAvailabilities.filter(notNull);

	return setProductsWithLimitedAvailability.length
		? Math.min(...setProductsWithLimitedAvailability)
		: null;
};

const getTotAvailabilityFromSkuAvailabilities = (
	availabilities: BySku<AvailabilityRange[]>,
	alreadySelectedSkus: BySku<number> | null,
) => {
	let totAvailabilityForSkus = null;
	for (const skuId of Object.keys(availabilities)) {
		const minAvailabilityFromRange = getMinAvailabilityFromRange(availabilities[skuId]);
		if (minAvailabilityFromRange === null) return null;
		const alreadySelectedSkuCount = alreadySelectedSkus?.[skuId];
		const minAvailabilityForSku = minAvailabilityFromRange - (alreadySelectedSkuCount ?? 0);
		totAvailabilityForSkus = (totAvailabilityForSkus ?? 0) + minAvailabilityForSku;
	}
	return totAvailabilityForSkus;
};

export const getSelectedSkusCount = (products: OrderProduct[]): BySku<number> => {
	const selectedSkuIds = getSkuIds(products);
	return selectedSkuIds.reduce(
		(tot, skuId) => ({ ...tot, [skuId]: (tot[skuId] ?? 0) + 1 }),
		{} as BySku<number>,
	);
};

export const getSelectedVariantsCount = (products: OrderProduct[]): BySku<number> => {
	const selectedVariantIds = getVariantIds(products);
	return selectedVariantIds.reduce(
		(tot, variantId) => ({ ...tot, [variantId]: (tot[variantId] ?? 0) + 1 }),
		{} as BySku<number>,
	);
};

export const getProductCurrentAvailabilityCount = (
	product: ProductApi,
	availabilities: ProductTotalAvailability,
	stockProducts: ProductApi[],
	alreadySelectedVariants: ByVariant<number>,
) => {
	const alreadySelectedSkus = Object.entries(alreadySelectedVariants).reduce(
		(tot, [variantId, count]) => {
			const skuId = variantIdToSkuId(variantId, stockProducts);
			if (!skuId) return tot;
			return {
				...tot,
				[skuId]: (tot[skuId] ?? 0) + count,
			};
		},
		{} as BySku<number>,
	);
	if (isPackageProduct(product)) {
		const productsInPackage = getProductsInPackage(product, stockProducts);
		return calculateMinTotalAvailabilityFromSetProductAvailabilities(
			availabilities.availabilityRangesBySku,
			productsInPackage,
			alreadySelectedSkus,
		);
	}
	return getTotAvailabilityFromSkuAvailabilities(
		availabilities.availabilityRangesBySku,
		alreadySelectedSkus,
	);
};
