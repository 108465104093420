import moment from 'moment-timezone';

import { BookingBuffer, Category, ProductApi } from 'common/types';
import { getLatestBookingTime } from 'common/utils/bookings';
import { notUndefined } from 'common/utils/common';

export const getBookingBufferForProduct = (
	product: ProductApi,
	categories: Category[],
): BookingBuffer | undefined => {
	if (!!product.bookingBuffer) return product.bookingBuffer;
	const categoryBuffers = product.categoryIds
		?.map((id) => categories.find((category) => category.id === id)?.bookingBuffer)
		.filter(notUndefined);
	return categoryBuffers ? getMostRestrictiveBookingBuffer(categoryBuffers) : undefined;
};

export const getMostRestrictiveBookingBuffer = (
	buffers: BookingBuffer[],
): BookingBuffer | undefined => {
	if (!buffers.length) return undefined;
	return buffers.reduce((prev: BookingBuffer, curr) => {
		if (curr.daysBefore < prev.daysBefore) return prev;
		if (curr.daysBefore > prev.daysBefore) return curr;
		if ((curr.latestTime ?? Infinity) < (prev.latestTime ?? Infinity)) {
			return curr;
		}
		if ((curr.minutesBefore ?? 0) > (prev.minutesBefore ?? 0)) {
			return curr;
		}
		return prev;
	});
};

export const isWithinBookingBuffer = (args: {
	date: moment.Moment;
	bookingBuffer: BookingBuffer;
	precision?: 'date' | 'ms';
}) => {
	const { date, bookingBuffer, precision } = args;
	const currentDate = moment();
	const latestBookingTime = getLatestBookingTime(date, bookingBuffer);
	if (currentDate.isAfter(latestBookingTime, precision === 'date' ? 'date' : undefined)) {
		return false;
	}
	return true;
};
