import React from 'react';

import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiMapPinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';

import FlexBox from 'common/components/FlexBox';
import { useRoutes } from 'services/routing/useRoutes';

interface Props {
	isLink?: boolean;
	showCity: boolean;
	showIcon: boolean;
	classes?: {
		locationName?: string;
	};
	textVariant?: 'body1' | 'body2' | 'caption';
	justify?: 'center' | 'flex-start';
}

const LocationName = ({
	isLink,
	showCity,
	showIcon,
	classes: propClasses,
	textVariant,
	justify,
}: Props) => {
	const classes = useStyles();
	const { getPath, Routes } = useRoutes();
	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const locationNameString = [activeLocation?.name, showCity ? activeLocation?.city : undefined]
		.filter(Boolean)
		.join(', ');
	const showLocationIcon = showIcon !== undefined ? showIcon : true;

	const locationElement = (
		<Box
			display="flex"
			flexDirection="row"
			alignItems="flex-start"
			justifyContent={justify ? justify : 'flex-start'}
		>
			{showLocationIcon && (
				<Box mr={textVariant === 'body1' ? 1 : 0.5} display="flex">
					<RiMapPinLine size={textVariant === 'body1' ? 20 : 18} />
				</Box>
			)}
			<Typography
				variant={textVariant ? textVariant : 'body1'}
				className={propClasses?.locationName}
			>
				{locationNameString}
			</Typography>
		</Box>
	);

	if (!activeLocation) return null;

	if (isLink) {
		return (
			<FlexBox justifyContent="center" py={1}>
				<Link to={getPath(Routes.locations)} className={classes.linkWrapper}>
					{locationElement}
				</Link>
			</FlexBox>
		);
	} else {
		return locationElement;
	}
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		linkWrapper: {
			display: 'inline-block',
			textAlign: 'center',
		},
		btn: {
			cursor: 'pointer',
		},
	}),
);

export default LocationName;
