import React from 'react';

import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Moment } from 'moment-timezone';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';

import { localFormatString } from 'common/utils/dateUtils';
import { useTranslation } from 'services/localization';

interface Props {
	value: Moment | undefined;
	onChange: (newValue: Moment | null) => void;
	error?: string;
}

const DateOfBirth = (props: Props) => {
	const { t } = useTranslation();
	const shopDateFormat = useSelector(ShopSelectors.shopDateFormat);
	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<DatePicker
				label={t('common:form.dateOfBirth', 'Date of birth')}
				views={['year', 'month', 'day']}
				disableFuture
				inputFormat={localFormatString('DD/MM/YYYY', shopDateFormat)}
				value={props.value != null ? props.value : null}
				onChange={(newValue) => {
					props.onChange(newValue != null ? newValue : null);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						sx={{ width: '100%' }}
						error={!!props.error || (props.value && !props.value.isValid())}
						helperText={props.error ?? undefined}
					/>
				)}
			/>
		</LocalizationProvider>
	);
};

export default DateOfBirth;
