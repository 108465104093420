import React from 'react';

import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as StockSelectors from 'redux/selectors/stock';

import EmptyState from 'common/components/states/EmptyState';
import LoadingState from 'common/components/states/LoadingState';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';

import Tickets from './Tickets';

const TicketsPage = () => {
	const { t } = useTranslation();
	const { getPath, Routes } = useRoutes();

	const productsLoading = useSelector(StockSelectors.stockProducts).loading;
	const productsError = useSelector(StockSelectors.stockProducts).error;
	const categoriesLoading = useSelector(StockSelectors.stockCategories).loading;
	const categoriesError = useSelector(StockSelectors.stockCategories).error;
	const liftTicketCategory = useSelector(StockSelectors.liftTicketCategory);
	const liftTicketProducts = useSelector(StockSelectors.normalLiftTicketProducts);

	if (productsLoading || categoriesLoading) {
		return <LoadingState />;
	}

	if (productsError || categoriesError || !liftTicketCategory || !liftTicketProducts.length) {
		return (
			<EmptyState
				title={t('common:errors.somethingWentWrongShort')}
				description={t('common:errors.productNotFoundDescription')}
				cta={
					<Link to={getPath(Routes.browse)}>
						<Button variant="contained" color="primary">
							{t('common:actions.backToShop', 'Back to shop')}
						</Button>
					</Link>
				}
			/>
		);
	}

	return <Tickets />;
};

export default TicketsPage;
