import React from 'react';

import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import countryCodes from 'common/services/countryCodes.json';
import { PhoneObject } from 'common/types';
import { useTranslation } from 'services/localization';

interface Props {
	value: PhoneObject;
	onChange: (value: PhoneObject) => void;
	onBlur?: () => void;
	error?: string;
	id?: string;
	variant?: TextFieldProps['variant'];
}

const PhoneNumberInput = (props: Props) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { value, onChange, onBlur, error, id, variant } = props;

	const handleAreaCodeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const [countryCode, areaCode] = (e.target.value as string).split('_');
		onChange({
			...value,
			countryCode,
			areaCode,
		});
	};

	const handleNumberChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const numberWithoutCode = e.target.value as string;
		const trimmedNumber = numberWithoutCode.replace('+', '').trim();
		onChange({
			...value,
			numberWithoutCode: trimmedNumber,
		});
	};

	const selectedAreaCode = `${value.countryCode}_${value.areaCode}`;

	return (
		<TextField
			id={id}
			label={t('common:form.phone')}
			variant={variant ?? 'standard'}
			required
			value={value.numberWithoutCode}
			onChange={handleNumberChange}
			onBlur={onBlur}
			type="tel"
			fullWidth
			error={!!error}
			helperText={error ?? undefined}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start" classes={{ root: classes.areaCodeRoot }}>
						<TextField
							select
							value={selectedAreaCode}
							onChange={handleAreaCodeChange}
							onBlur={onBlur}
							SelectProps={{
								native: true,
							}}
							InputProps={{
								disableUnderline: true,
								fullWidth: true,
							}}
							variant="standard"
							fullWidth
						>
							{countryCodes.map((countryCode) => {
								const key = `${countryCode.code}_${countryCode.dial_code}`;

								return (
									<option key={key} value={key}>
										{key === selectedAreaCode
											? `${countryCode.flag} ${countryCode.dial_code}`
											: `${countryCode.flag} ${countryCode.name} (${countryCode.dial_code})`}
									</option>
								);
							})}
						</TextField>
					</InputAdornment>
				),
			}}
		/>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		areaCodeRoot: {
			borderRight: '1px solid rgba(0, 0, 0, 0.23)',
			padding: theme.spacing(3, 1, 3, 0),
			flex: '0 0 110px',
		},
	}),
);

export default PhoneNumberInput;
