import React from 'react';

import { Grid } from '@mui/material';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';

import { Location } from 'common/types';
import { notUndefined } from 'common/utils/common';
import LocationCard from 'components/LocationCard';

interface Props {
	onLocationSelect?: () => void;
	fullWidthCards?: boolean;
}

const LocationsGrid = ({ onLocationSelect, fullWidthCards }: Props) => {
	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const onlineLocations = useSelector(ShopSelectors.onlineLocations);

	const locationsActiveFirst: Location[] = [
		activeLocation,
		...onlineLocations.filter((l) => l.id !== activeLocation?.id),
	].filter(notUndefined);

	return (
		<Grid container spacing={4}>
			{locationsActiveFirst.map((location) => (
				<Grid key={location.id} item xs={12} md={fullWidthCards ? 12 : 4}>
					<LocationCard location={location} onSelect={onLocationSelect} />
				</Grid>
			))}
		</Grid>
	);
};

export default LocationsGrid;
