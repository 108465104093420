import { TFunction } from 'i18next';
import { chain, clamp, isEmpty, sum } from 'lodash';

import { Api } from 'common/db/api/paths';
import { hasLowerAccess } from 'common/modules/users/utils';
import { ById, Location, OrderProduct, PurchaseType, Role } from 'common/types';
import { sumItems } from 'common/utils/arrays';
import { notUndefined, switchUnreachable } from 'common/utils/common';

import { InventoryStatuses } from './constants';
import {
	AllAllocationTypes,
	AllocationType,
	BaseInventoryItem,
	BulkInventoryItem,
	InventoryField,
	InventoryFields,
	InventoryItem,
	InventoryItemAllocationBoolean,
	InventoryItemAllocationQuantity,
	InventoryStatus,
	InventoryStatusWithQuantity,
	NewBulkInventoryItemProps,
	NewSingleInventoryItemProps,
	NewSkuItemProps,
	SingleInventoryItem,
	SkuItem,
} from './types';

export const isSingleItem = (
	item: SingleInventoryItem | BulkInventoryItem,
): item is SingleInventoryItem => {
	return (item as SingleInventoryItem).type === 'single' || !!(item as SingleInventoryItem).status;
};

export const isBulkItem = (
	item: SingleInventoryItem | BulkInventoryItem,
): item is BulkInventoryItem => {
	return (item as BulkInventoryItem).type === 'bulk';
};

export const isInventoryItemAllocationBoolean = (
	allocation: InventoryItemAllocationBoolean | InventoryItemAllocationQuantity | undefined,
): allocation is InventoryItemAllocationBoolean => {
	return typeof allocation?.rental === 'boolean' || typeof allocation?.sales === 'boolean';
};
export const isInventoryItemAllocationQuantity = (
	allocation: InventoryItemAllocationBoolean | InventoryItemAllocationQuantity | undefined,
): allocation is InventoryItemAllocationQuantity => {
	return typeof allocation?.rental === 'number' || typeof allocation?.sales === 'number';
};

export const isInventoryAllocationOption = (value: string | undefined): value is AllocationType => {
	return !!value && AllAllocationTypes.includes(value as AllocationType);
};

export const isInventoryStatus = (value: string | undefined): value is InventoryStatus => {
	return !!value && (Object.values(InventoryStatuses) as string[]).includes(value);
};

export const getTotalQuantityFromStatuses = (statuses: InventoryStatusWithQuantity) =>
	Object.values(statuses).reduce((tot: number, curr) => tot + (curr ?? 0), 0);

export const getTotalQuantityFromStatusesForLocationIds = (
	inventoryStatus: ById<InventoryStatusWithQuantity>,
	locationIds: string[],
) => {
	return sum(
		locationIds.map((locationId) =>
			getTotalQuantityFromStatuses(inventoryStatus[locationId] ?? {}),
		),
	);
};

export const getAvailableQuantityFromAllocations = (
	skuItem: SkuItem | undefined,
	locationId: string,
	purchaseType: PurchaseType,
) => {
	switch (purchaseType) {
		case 'rental':
		case 'subscription': {
			return skuItem?.allocationsByLocation?.[locationId]?.rental ?? 0;
		}
		case 'sales':
			return skuItem?.allocationsByLocation?.[locationId]?.sales ?? 0;
		default: {
			return switchUnreachable(purchaseType);
		}
	}
};

export const getSkuItemTotalQuantity = (skuItems: SkuItem[], locationId: string | null) => {
	return (skuItems ?? []).reduce((sum, skuItem) => {
		if (locationId) {
			const skuItemLocationQuantity = sumItems(
				Object.values(skuItem.statusesByLocation[locationId]).filter(notUndefined),
			);
			return sum + skuItemLocationQuantity;
		}
		return sum + skuItem.quantity;
	}, 0);
};

export const getItemQuantity = (item: InventoryItem) => (isSingleItem(item) ? 1 : item.quantity);

export const getItemStatusWithQuantity = (item: InventoryItem): InventoryStatusWithQuantity => {
	return isSingleItem(item)
		? { [item.status]: 1 }
		: Object.entries(item.statuses).reduce((tot, [_status, qty]) => {
				if (qty === undefined) return tot;
				const status: InventoryStatus = _status as InventoryStatus;
				return {
					...tot,
					[status]: qty,
				};
		  }, {} as InventoryStatusWithQuantity);
};

export const emptyInventoryFields = (): InventoryFields => ({ order: [], values: {} });

export const getInventoryFieldsArray = (inventoryFields: InventoryFields): InventoryField[] => {
	return inventoryFields.order
		.map((fieldId) => inventoryFields.values[fieldId])
		.filter(notUndefined);
};

export const getItemAllocationsWithQuantity = (
	item: InventoryItem,
): InventoryItemAllocationQuantity => {
	if (isSingleItem(item)) {
		const fixedAllocation = fixInventoryItemAllocationBoolean(item.allocation);
		const allocations = Object.entries(fixedAllocation).reduce(
			(a, [k, v]) => ({ ...a, [k]: item.status === 'IN_USE' && !!v ? 1 : 0 }),
			{} as InventoryItemAllocationQuantity,
		);
		return allocations;
	} else {
		return fixInventoryItemAllocationQuantity(item.allocations, item.statuses.IN_USE);
	}
};

export const getItemTotalReservedCount = (item: InventoryItem): number => {
	return sum(Object.values(item.reservedCountsByOrderId ?? {}).map((count) => Math.max(0, count)));
};

export const sumAllocationQuantities = (
	q1: InventoryItemAllocationQuantity | undefined,
	q2: InventoryItemAllocationQuantity,
): InventoryItemAllocationQuantity =>
	!!q1
		? Object.entries(q1).reduce((a, [key, value]) => ({ ...a, [key]: (a[key] ?? 0) + value }), q2)
		: q2;

export const getItemAllocationsWithQuantityDiff = ({
	before,
	after,
}: {
	before: InventoryItemAllocationQuantity;
	after: InventoryItemAllocationQuantity;
}): InventoryItemAllocationQuantity => {
	return Object.entries(before).reduce((result, [allocationType, quantityBefore]) => {
		result[allocationType] = (result[allocationType] ?? 0) - quantityBefore;
		return result;
	}, after);
};

export const changeBulkInventoryItemStatus = (args: {
	api: Api;
	itemId: string;
	amount: number;
	from: InventoryStatus;
	to: InventoryStatus;
}) => ({
	[`statuses.${args.from}` as `statuses.IN_USE`]: args.api.FieldValues.increment(args.amount * -1),
	[`statuses.${args.to}` as `statuses.SOLD`]: args.api.FieldValues.increment(args.amount),
});

export const changeBulkInventoryItemAllocations = (args: {
	api: Api;
	allocationToChange: AllocationType;
	amount: number;
}) =>
	args.amount === 0
		? undefined
		: {
				[`allocations.${args.allocationToChange}` as `allocations.sales`]: args.api.FieldValues.increment(
					args.amount,
				),
		  };

export const getBulkItemsSkuIdPairsFromOrderProducts = (products: OrderProduct[]) =>
	chain(products)
		.flatMap((p) => Object.values(p.stock).filter((s) => s.type === 'bulk'))
		.groupBy('skuId')
		.toPairs()
		.value();

export const getAllocationsByLocationFromStatusesByLocation = (
	item: SkuItem,
): {
	[locationId: string]: InventoryItemAllocationQuantity;
} => {
	return chain(item.statusesByLocation)
		.toPairs()
		.reduce(
			(acc, [locationId, statusWithQuantity]) => ({
				...acc,
				[locationId]: { rental: statusWithQuantity['IN_USE'] ?? 0 },
			}),
			{},
		)
		.value();
};

export const ensureAllocationsExistForSkuItem = (item: SkuItem | undefined) => {
	if (!item) return undefined;
	return !!item.allocationsByLocation
		? item
		: { ...item, allocationsByLocation: getAllocationsByLocationFromStatusesByLocation(item) };
};

export const getInventoryStatusLabel = (status: InventoryStatus, t: TFunction): string => {
	switch (status) {
		case 'IN_USE':
			return t('common:states.inUse', 'In use');
		case 'OUT_OF_USE':
			return t('common:states.outOfUse', 'Out of use');
		case 'LOST':
			return t('common:states.lost', 'Lost');
		case 'SOLD':
			return t('common:states.sold', 'Sold');
		default:
			return switchUnreachable(status);
	}
};

export const fixInventoryItemAllocationBoolean = (
	allocation: InventoryItemAllocationBoolean,
): InventoryItemAllocationBoolean => {
	if (isEmpty(allocation)) {
		return {
			rental: true,
		};
	}

	// Both true or both false
	if (allocation.rental === allocation.sales) {
		return {
			rental: true,
		};
	}

	return allocation;
};

export const fixInventoryItemAllocationQuantity = (
	allocations: InventoryItemAllocationQuantity | undefined,
	_quantityInUse: number | undefined,
): BulkInventoryItem['allocations'] => {
	const quantityInUse = Math.max(_quantityInUse ?? 0, 0);
	if (isEmpty(allocations)) {
		return {
			rental: quantityInUse,
			sales: 0,
		};
	}

	const normalizedAllocations = {
		rental: clamp(allocations?.rental ?? 0, 0, quantityInUse),
		sales: clamp(allocations?.sales ?? 0, 0, quantityInUse),
	};

	const sumOfAllocations = sum(Object.values(normalizedAllocations));

	if (sumOfAllocations !== quantityInUse) {
		return {
			sales: normalizedAllocations.sales,
			rental: quantityInUse - normalizedAllocations.sales,
		};
	}

	return normalizedAllocations;
};

export const getNewSkuItem = <T extends SkuItem['itemType']>(
	props: NewSkuItemProps<T>,
): SkuItem => {
	const { id, shopId, name, skuCode, itemType, fromTemplate } = props;
	const skuItem: SkuItem = {
		id,
		createdAt: new Date().toISOString(),
		shopId,
		skuName: name,
		skuCode,
		quantity: 0,
		usageCount: 0,
		usageSeconds: 0,
		lastUsed: null,
		statusesByLocation: {},
		allocationsByLocation: {},
		itemType,
		...(fromTemplate && { fromTemplate }),
	};
	return skuItem;
};

export function getNewInventoryItem(props: NewSingleInventoryItemProps): SingleInventoryItem;
export function getNewInventoryItem(props: NewBulkInventoryItemProps): BulkInventoryItem;
export function getNewInventoryItem(
	props: NewSingleInventoryItemProps | NewBulkInventoryItemProps,
): SingleInventoryItem | BulkInventoryItem {
	const {
		id,
		shopId,
		identifier,
		locationId,
		skuId,
		skuCode,
		fieldValues,
		skuName,
		fixedFieldValues,
		fromTemplate,
	} = props;
	const baseInventoryItem: BaseInventoryItem = {
		id,
		skuName,
		createdAt: new Date().toISOString(),
		shopId,
		identifiers: !!identifier ? identifier : [],
		usageCount: 0,
		usageSeconds: 0,
		lastUsed: null,
		locationId,
		skuId,
		skuCode,
		fieldValues,
		activeOrderIds: [],
		...(fixedFieldValues && { fixedFieldValues }),
		...(fromTemplate && { fromTemplate }),
	};
	if (props.type === 'single') {
		return {
			...baseInventoryItem,
			type: 'single',
			status: props.status,
			allocation: props.allocation,
		};
	}
	return {
		...baseInventoryItem,
		type: 'bulk',
		quantity: props.quantity,
		statuses: props.statuses,
		allocations: props.allocations,
	};
}

export const nameToSkuCode = (name: string, existingSkus: string[]): string => {
	const trimmedUppercaseName = name.trim().toUpperCase();
	// Replace all non-alphanumeric characters with a single space
	const formattedName = trimmedUppercaseName.replace(/[\W_]+/g, ' ');
	const nameStrings = formattedName.split(' ').slice(0, 3);
	const charsToTake = nameStrings.length === 1 ? 3 : 2;
	const idPrefix = nameStrings.map((string) => string.substring(0, charsToTake)).join('-');
	let numberSuffix = 0;
	let sku = '';
	do {
		if (numberSuffix === 0) {
			sku = idPrefix;
		} else {
			sku = `${idPrefix}-${numberSuffix}`;
		}
		numberSuffix++;
	} while (existingSkus.includes(sku));
	return sku;
};

export const sanitizeSkuCode = (skuCode: string): string => {
	return skuCode
		.toUpperCase()
		.replace(' ', '-')
		.replace(/[^A-Z0-9-]/g, '');
};

export const isItemEditableByUser = (args: {
	item: InventoryItem;
	userLocations: Location[];
	userRoles: Role[];
}): boolean => {
	const { item, userLocations, userRoles } = args;
	if (hasLowerAccess(userRoles, ['editor'])) return false;

	const userLocationIds = userLocations.map((loc) => loc.id);

	return userLocationIds.includes(item.locationId);
};

export const isSkuEditableByUser = (args: {
	skuItem: SkuItem;
	userLocations: Location[];
	userRoles: Role[];
}): boolean => {
	const { skuItem, userLocations, userRoles } = args;
	if (hasLowerAccess(userRoles, ['editor'])) return false;

	const userLocationIds = userLocations.map((loc) => loc.id);
	return Object.keys(skuItem.statusesByLocation).every((locationId) => {
		return userLocationIds.includes(locationId);
	});
};
