import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { hot } from 'react-hot-loader/root';
import { Route, Switch } from 'react-router-dom';

import { sendEventToParentPage } from 'common/modules/embed';
import { isRunInIframe } from 'common/utils/browserUtils';

import ExternalAuthWindowHandler from './AuthHandler/ExternalAuthWindowHandler';
import ExternalAuthWindowRootHandler from './AuthHandler/ExternalAuthWindowRootHandler';
import Main from './Main';

const Online: React.FC = () => {
	useEffect(() => {
		/**
		 * Conditionally load iframe-resizer script if the page is embedded
		 */
		const loadIframeResizer = async () => {
			//@ts-ignore
			await import('iframe-resizer/js/iframeResizer.contentWindow.min.js');
			setTimeout(() => {
				sendEventToParentPage({
					origin: 'rentle',
					type: 'on-load',
					payload: {
						pathname: window.location.pathname,
					},
				});
			}, 500);
		};
		if (isRunInIframe()) {
			loadIframeResizer();
		}
	}, []);

	return (
		<Box display="flex" flex="1" flexDirection="column" width="100%">
			<Route exact path="/" component={ExternalAuthWindowRootHandler} />
			<Switch>
				<Route path="/_auth" component={ExternalAuthWindowHandler} />
				<Route path="/" component={Main} />
			</Switch>
		</Box>
	);
};

export default hot(Online);
