import React from 'react';

import { groupBy, sortBy } from 'lodash';

import { PurchaseTypes } from 'common/types';
import { CartRow } from 'services/types';

import CartProductsRow from './CartProductsRow';

export interface Props {
	rows: CartRow[];
	readOnly?: boolean;
	innerPadding?: number;
	hideStartDateExpired?: boolean;
}

const CartProductsRows = (props: Props) => {
	const { rows, ...rest } = props;
	const sortedRows = sortBy(rows, ({ endDate }) => endDate);

	const groupedItems = groupBy(sortedRows, ({ purchaseType }) => purchaseType);

	return (
		<>
			{groupedItems[PurchaseTypes.rental]?.map(({ products, startDate, endDate }) => (
				<CartProductsRow
					key={`${startDate}_${endDate}`}
					products={products}
					purchaseType="rental"
					{...rest}
				/>
			))}
			{groupedItems[PurchaseTypes.subscription]?.map(({ products, purchaseType }, idx) => (
				<CartProductsRow
					key={`${purchaseType}_ ${idx}`}
					products={products}
					purchaseType="subscription"
					{...rest}
				/>
			))}
			{groupedItems[PurchaseTypes.sales]?.map(({ products, purchaseType }, idx) => (
				<CartProductsRow
					key={`${purchaseType}_ ${idx}`}
					products={products}
					purchaseType="sales"
					hideHeader={idx !== 0}
					{...rest}
				/>
			))}
		</>
	);
};

export default CartProductsRows;
