import React, { useEffect, useRef } from 'react';

import { IconButton, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { RiCloseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthError } from 'redux/actions/auth';
import * as AuthSelectors from 'redux/selectors/auth';

import CenteredContent from 'components/CenteredContent';
import { useTranslation } from 'services/localization/useTranslation';

const AuthErrorBanner = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();
	const showAuthError = useSelector(AuthSelectors.showAuthError);
	const timeout = useRef<NodeJS.Timeout>();

	useEffect(() => {
		if (showAuthError) {
			timeout.current = setTimeout(() => {
				dispatch(setAuthError(null));
			}, 5000);
		}
	}, [showAuthError, dispatch]);

	const handleClose = () => {
		if (timeout.current) {
			clearTimeout(timeout.current);
		}
		dispatch(setAuthError(null));
	};

	return !showAuthError ? null : (
		<CenteredContent className={classes.root} innerClassName={classes.content}>
			<Typography className={classes.expired} variant="body2" color="textPrimary" align="center">
				{t('common:errors.signInFailed', 'Sign-in failed, please try again')}
			</Typography>
			<IconButton className={classes.closeBtn} onClick={handleClose}>
				<RiCloseLine />
			</IconButton>
		</CenteredContent>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: '#f2f2f2',
			height: 35,
		},
		content: {
			display: 'flex',
			position: 'relative',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100%',
		},
		closeBtn: {
			position: 'absolute',
			right: 0,
			padding: 4,
			marginRight: -4,
		},
		expired: {
			color: theme.palette.error.main,
		},
	}),
);

export default AuthErrorBanner;
