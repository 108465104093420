import React, { useCallback } from 'react';

import { FormControl, Icon, MenuItem, Select, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiArrowDownSLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage as setLanguageAction } from 'redux/actions/view';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import { getLanguageNativeName } from 'common/locales/utils';
import { Languages } from 'common/types';
import { onlineLanguages, setLanguage } from 'services/localization/utils';

const LanguageSelector = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const language = useSelector(ViewSelectors.language);
	const shopLanguages = useSelector(ShopSelectors.shopLanguages) ?? ['en'];
	const visibleLanguages = shopLanguages.filter((lang) =>
		onlineLanguages.some((onlineLang) => onlineLang === lang),
	);

	const handleLanguageChange = useCallback(
		(lang: Languages) => {
			dispatch(setLanguageAction(lang));
			setLanguage(lang);
		},
		[dispatch],
	);

	if (visibleLanguages.length <= 1) {
		return null;
	}

	const DropdownIcon = () => (
		<Icon className={classes.dropdownIcon}>
			<RiArrowDownSLine size={20} />
		</Icon>
	);

	return (
		<FormControl>
			<Select
				variant="standard"
				value={language}
				renderValue={(language) => (
					<Typography variant="body2">{(language as string).toLocaleUpperCase()}</Typography>
				)}
				IconComponent={DropdownIcon}
				onChange={(e) => handleLanguageChange(e.target.value as Languages)}
				disableUnderline
				classes={{
					select: classes.select,
				}}
			>
				{visibleLanguages.map((language) => {
					return (
						<MenuItem value={language} className={classes.dropMenu} key={language}>
							{getLanguageNativeName(language)}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			maxWidth: 800,
			margin: '0 auto',
			paddingTop: 0,
			paddingBottom: 0,
		},
		select: {
			display: 'flex',
			alignItems: 'center',
		},
		capitalize: {
			textTransform: 'capitalize',
		},
		marginLeft: {
			marginLeft: 8,
		},
		dropdownIcon: {
			fontSize: '2rem',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			pointerEvents: 'none',
			position: 'absolute',
			right: 0,
		},
		dropMenu: {
			textTransform: 'capitalize',
		},
	}),
);

export default LanguageSelector;
