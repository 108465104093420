import React from 'react';

import AdyenCheckout from '@adyen/adyen-web';
import { Box } from '@mui/material';
import { TFunction } from 'i18next';

import {
	isAdyenPaymentMethod,
	isDepositPaymentMethod,
	isStripePaymentMethod,
} from 'common/modules/payments/paymentMethods';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { AmountObject, Languages } from 'common/types';

import DepositInfoBlock from '../DepositInfoBlock';
import AdyenPaymentElement from '../elements/AdyenPaymentElement';
import StripeCardElement from '../elements/StripeCardElement';
import { PaymentMethodError } from '../types';
import PaymentMethodDescription from './PaymentMethodDescription';

export interface PaymentMethodContentProps {
	method: ShopOnlinePaymentMethodObject;
	deposit?: {
		amount: AmountObject;
		reservationType: 'NOW' | 'LATER';
	};
	adyenCheckoutObject: null | AdyenCheckout;
	error?: PaymentMethodError;
	t: TFunction;
	lang: Languages;
}

const PaymentMethodContent = (props: PaymentMethodContentProps) => {
	const { method, t, deposit, error, adyenCheckoutObject, lang } = props;
	const hasDeposit = deposit && !!deposit.amount.value;

	return (
		<>
			<PaymentMethodDescription paymentMethod={method} t={t} lang={lang} />
			{deposit && hasDeposit && isDepositPaymentMethod(method.id) && (
				<>
					<DepositInfoBlock
						method={method.id}
						t={t}
						amount={deposit.amount}
						depositReservationType={deposit.reservationType}
					/>
					<Box mt={2} />
				</>
			)}

			{!!adyenCheckoutObject && isAdyenPaymentMethod(method.id) && (
				<AdyenPaymentElement
					method={method.id}
					adyenCheckout={adyenCheckoutObject}
					t={t}
					paymentError={error}
				/>
			)}
			{isStripePaymentMethod(method.id) && <StripeCardElement t={t} />}
		</>
	);
};

export default PaymentMethodContent;
