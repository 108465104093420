import { useCallback, useContext, useEffect } from 'react';

import { AdyenPaymentMethod } from 'common/modules/payments/types';

import { AdditionalDetailsState, AdyenContext } from './AdyenContext';

export const useAdyen = (onAdditionalDetails?: (state: AdditionalDetailsState) => any) => {
	const {
		adyenCheckout,
		addAdditionalDetailsCallback,
		removeAdditionalDetailsCallback,
		setMethodSubmitAction: _setMethodSubmitAction,
		submitPayment,
		paymentMethods,
	} = useContext(AdyenContext);

	const setMethodSubmitAction = useCallback(
		(method: AdyenPaymentMethod, action: () => void) => {
			_setMethodSubmitAction(method, action);
		},
		[_setMethodSubmitAction],
	);

	useEffect(() => {
		const additionalDetailsCb = onAdditionalDetails;
		if (additionalDetailsCb) {
			addAdditionalDetailsCallback(additionalDetailsCb);
		}
		return () => {
			if (additionalDetailsCb) {
				removeAdditionalDetailsCallback(additionalDetailsCb);
			}
		};
	}, []);

	const submit = (method: AdyenPaymentMethod) => {
		return submitPayment(method);
	};

	return {
		adyenCheckout,
		setMethodSubmitAction,
		submit,
		paymentMethods,
	};
};
