import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as CartActions from 'redux/actions/cart';
import * as StockActions from 'redux/actions/stock';
import * as ViewActions from 'redux/actions/view';
import * as ShopSelectors from 'redux/selectors/shop';
import { ReduxDispatch } from 'redux/types';
import { createThunk } from 'redux/utils';
import { getShopUrlDoc } from 'shared-server/url';

import { fetchIsLiteShop, shopHasFeature } from 'common/api/db/functions';
import { Callable } from 'common/frontend/callable';
import { PLAIN_THEME_ID } from 'common/modules/customization';
import { CustomizerTheme } from 'common/modules/customization/types';
import { OpeningHoursDoc } from 'common/modules/openingHours';
import { isStripePaymentMethod } from 'common/modules/payments/paymentMethods';
import { RestrictedFeature } from 'common/modules/plans';
import { DeliveryOption, ShopPublicInfo } from 'common/types';
import { notNull } from 'common/utils/common';
import { ListenerManager } from 'services/ListenerManager';
import { api } from 'services/api';
import { ShopUrlApiWithStoreUrl } from 'services/types';

const shopListeners = new ListenerManager<
	| 'deliveryOptions'
	| 'onlineTheme'
	| 'openingHours'
	| 'rentleOnlineTheme'
	| 'shopExceptionPeriods'
	| 'shopPublicInfo'
	| 'shopUrls'
>();

export const updateShopListeners = createAsyncThunk<
	void,
	{ shopUrl: string | undefined } | { shopDomain: string },
	{ rejectValue: string; dispatch: ReduxDispatch }
>('Shop/UPDATE_SHOP_LISTENERS', async (args, thunkAPI) => {
	const shopUrlDoc = await getShopUrlDoc(api, args);
	if (!shopUrlDoc || !shopUrlDoc.data) {
		throw new Error('Shop not found');
	}
	const { docId: storeUrl, data } = shopUrlDoc;
	const shopId = data.shopId;
	thunkAPI.dispatch(setShopUrls({ ...data, storeUrl }));
	thunkAPI.dispatch(updateIsLiteShop(shopId));
	thunkAPI.dispatch(updateShopPublicInfoListener({ shopId }));
	thunkAPI.dispatch(updateShopFeatures(shopId));
	thunkAPI.dispatch(updateOnlineThemeListener({ shopId }));
	thunkAPI.dispatch(updateRentleCustomThemeListener({ shopId }));
	thunkAPI.dispatch(updateDeliveryOptionsListener({ shopId }));
	thunkAPI.dispatch(updateOpeningHoursListener({ shopId }));
	thunkAPI.dispatch(updateShopHasActiveDiscountCodes(shopId));
	thunkAPI.dispatch(StockActions.updateStockProductsListener(shopId));
	thunkAPI.dispatch(StockActions.updateStockCategoriesListener(shopId));
	thunkAPI.dispatch(CartActions.verifyPersistCart({ shopId }));
});

export const updateShopPublicInfoListener = createThunk<void, { shopId: string }>(
	'Shop/UPDATE_PUBLIC_INFO_LISTENER',
	({ shopId }, thunkAPI) => {
		shopListeners.update({
			id: 'shopPublicInfo',
			listener: api.shops.doc(shopId).public.listen(
				(data) => {
					thunkAPI.dispatch(setShopPublicInfo(data));
				},
				(error) => {
					thunkAPI.dispatch(setShopPublicInfoError(error.message));
				},
			),
			key: shopId,
		});
	},
);

export const setShopPublicInfo = createAction<ShopPublicInfo | undefined>('Shop/SET_PUBLIC_INFO');
export const setShopPublicInfoError = createAction<string>('Shop/SET_PUBLIC_INFO_ERROR');

export const updateDeliveryOptionsListener = createAsyncThunk<void, { shopId: string }>(
	'Shop/UPDATE_DELIVERY_OPTIONS',
	async ({ shopId }, thunkAPI) => {
		shopListeners.update({
			id: 'deliveryOptions',
			listener: api.deliveryOptions.byShopId(shopId).onSnapshot(
				(deliveryOptions) => {
					const visibleDeliveryOptions = deliveryOptions.filter((o) => !o?.hidden);
					if (visibleDeliveryOptions.length === 0) {
						thunkAPI.dispatch(ViewActions.setDeliveryOption(null));
						thunkAPI.dispatch(CartActions.removeDelivery());
						thunkAPI.dispatch(CartActions.clearDeliveryUnavailabilities());
					}
					thunkAPI.dispatch(setDeliveryOptions(visibleDeliveryOptions));
				},
				(error) => {
					thunkAPI.dispatch(setDeliveryOptionsError(error.message));
				},
			),
			key: shopId,
		});
	},
);

export const setDeliveryOptions = createAction<DeliveryOption[]>('Shop/SET_DELIVERY_OPTIONS');
export const setDeliveryOptionsError = createAction<string>('Shop/SET_DELIVERY_OPTIONS_ERROR');

export const updateOpeningHoursListener = createAsyncThunk<void, { shopId: string }>(
	'Shop/UPDATE_OPENING_HOURS',
	({ shopId }, thunkAPI) => {
		shopListeners.update({
			id: 'openingHours',
			listener: api.openingHours.byMerchantId(shopId).onSnapshot(
				(openingHours) => {
					thunkAPI.dispatch(setOpeningHours(openingHours));
				},
				(error) => {
					thunkAPI.dispatch(setOpeningHoursError(error.message));
				},
			),
			key: shopId,
		});
	},
);
export const setOpeningHours = createAction<OpeningHoursDoc[]>('Shop/SET_OPENING_HOURS');
export const setOpeningHoursError = createAction<string>('Shop/SET_OPENING_HOURS_ERROR');

export const updateIsLiteShop = createAsyncThunk(
	'Shop/UPDATE_IS_LITE_SHOP',
	(shopId: string, thunkAPI) => {
		return fetchIsLiteShop({ shopId });
	},
);

export const updateShopHasActiveDiscountCodes = createAsyncThunk(
	'Shop/UPDATE_HAS_ACTIVE_DISCOUNT_CODES',
	(shopId: string) => {
		return Callable.discountCodes.exists({ shopId });
	},
);

export const getShopStripeAccountId = createThunk(
	'Shop/GET_SHOP_STRIPE_ACCOUNT',
	(shopId: string, thunkAPI) => {
		const shopPaymentMethods = ShopSelectors.shopPaymentMethods(thunkAPI.getState()).map(
			(m) => m.id,
		);
		const hasStripePaymentMethod = shopPaymentMethods.some((m) => isStripePaymentMethod(m));
		if (!hasStripePaymentMethod) {
			return null;
		}
		return Callable.paymentAccounts.stripe.getAccountId({ shopId });
	},
);

export const updateShopFeatures = createAsyncThunk(
	'Shop/UPDATE_FEATURES',
	async (shopId: string) => {
		const features: RestrictedFeature[] = [
			'DISABLE_ONLINE_LOCATIONS_PAGE',
			'BLOCK_ONLINE_PAYMENTS',
			'DELIVERY_SERVICE',
			'SEGMENTS',
			'DECATHLON_LOGIN',
			'MOCK_LOGIN_INTERNAL',
		];
		const enabledFeatures = (
			await Promise.all(
				features.map(async (feature) => {
					const hasFeature = await shopHasFeature({ shopId, feature });
					return hasFeature ? feature : null;
				}),
			)
		).filter(notNull);
		return enabledFeatures;
	},
);

export const setShopUrls = createAction<ShopUrlApiWithStoreUrl | undefined>('Shop/SET_URLS');
export const setShopUrlsError = createAction<string>('Shop/SET_URLS_ERROR');

export const updateOnlineThemeListener = createThunk<void, { shopId: string }>(
	'Shop/UPDATE_ONLINE_THEME_LISTENER',
	async ({ shopId }, thunkAPI) => {
		shopListeners.clear('onlineTheme');
		shopListeners.update({
			id: 'onlineTheme',
			listener: api.customThemes.get.where('merchantId', '==', shopId).onSnapshot(
				(templates) => {
					thunkAPI.dispatch(setOnlineThemes(templates));
				},
				(error) => {
					thunkAPI.dispatch(setOnlineThemeError(error.message));
				},
			),
			key: shopId,
		});
	},
);

export const setOnlineThemes = createAction<CustomizerTheme[]>('Shop/SET_ONLINE_THEMES');
export const setOnlineThemeError = createAction<string>('Shop/SET_ONLINE_THEMES_ERROR');

export const updateRentleCustomThemeListener = createThunk<void, { shopId: string }>(
	'Shop/UPDATE_RENTLE_CUSTOM_THEME',
	async ({ shopId }, thunkAPI) => {
		shopListeners.clear('rentleOnlineTheme');
		shopListeners.update({
			id: 'rentleOnlineTheme',
			listener: api.customThemes.doc(PLAIN_THEME_ID).listen(
				(template) => {
					thunkAPI.dispatch(setRentleCustomTheme(template));
				},
				(error) => {
					thunkAPI.dispatch(setRentleCustomThemeError(error.message));
				},
			),
			key: shopId,
		});
	},
);

export const setRentleCustomTheme = createAction<CustomizerTheme | undefined>(
	'Shop/SET_RENTLE_CUSTOM_THEME',
);
export const setRentleCustomThemeError = createAction<string>('Shop/SET_RENTLE_CUSTOM_THEME_ERROR');
