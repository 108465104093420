import { i18n as i18nInterface } from 'i18next';
import { capitalize } from 'lodash';

import { getTranslation } from 'common/modules/translations';
import { Languages, Option, ProductApi, SegmentMap, SegmentPricing } from 'common/types';

interface DataField<FieldValue> {
	value: FieldValue;
	name: string;
	info?: string;
}

export const getSegments = (
	shopName: string,
	locationName: string,
	i18n: i18nInterface,
): Array<DataField<string>> => {
	if (shopName === 'skimac') {
		return [
			{ value: 'adult', name: i18n.t('common:ageGroups.adult', 'Adult') },
			{
				value: 'kid',
				name: i18n.t('common:ageGroups.kid', 'Kid'),
				info:
					locationName === 'skimacmessila'
						? i18n.t(
								'common:ageGroups.kidsSkimacMessilaInfo',
								`Kid's equipment is suitable only for kids aged 0-11`,
						  )
						: i18n.t(
								'common:ageGroups.kidSkiMacInfo',
								`Kid's equipment is suitable only for kids under 150cm and 50kg`,
						  ),
			},
		];
	}
	if (shopName === 'yllas') {
		return [
			{ value: 'adult', name: i18n.t('common:ageGroups.adult', 'Adult') },
			{
				value: 'kid',
				name: i18n.t('common:ageGroups.kid', 'Kid'),
				info: `${i18n.t('common:ageGroups.under', 'under')} 12`,
			},
		];
	}
	if (shopName === 'himos') {
		return [
			{ value: 'adult', name: i18n.t('common:ageGroups.adult', 'Adult') },
			{
				value: 'kid',
				name: i18n.t('common:ageGroups.kid', 'Kid'),
				info: `${i18n.t('common:ageGroups.under', 'under')} 12`,
			},
		];
	}
	if (shopName === 'laajis') {
		return [
			{ value: 'adult', name: i18n.t('common:ageGroups.adult', 'Adult') },
			{ value: 'teen', name: i18n.t('common:ageGroups.teen', 'Teen'), info: '13-17' },
			{
				value: 'kid',
				name: i18n.t('common:ageGroups.kid', 'Kid'),
				info: `${i18n.t('common:ageGroups.under', 'under')} 13`,
			},
		];
	}
	return [
		{ value: 'adult', name: i18n.t('common:ageGroups.adult', 'Adult') },
		{ value: 'teen', name: i18n.t('common:ageGroups.teen', 'Teen'), info: '12-17' },
		{
			value: 'senior',
			name: i18n.t('common:ageGroups.senior', 'Senior'),
			info: `${i18n.t('common:ageGroups.over', 'over')} 65`,
		},
		{
			value: 'kid',
			name: i18n.t('common:ageGroups.kid', 'Kid'),
			info: `${i18n.t('common:ageGroups.under', 'under')} 12`,
		},
	];
};

export type RentleSegment = 'senior' | 'adult' | 'teen' | 'kid';

export const getRentleSegments = (): (RentleSegment | string)[] => [
	'kid',
	'teen',
	'adult',
	'senior',
];

export const getSkidataSegmentById = (segmentId: string | null, segmentMapping: SegmentMap[]) =>
	segmentMapping.find((s) => s.externalSegmentId === segmentId);

export const getSkidataSegmentNameById = (segmentId: string, segmentMapping: SegmentMap[]) =>
	getSkidataSegmentById(segmentId, segmentMapping)?.externalSegmentName ?? '';

export const getSkidataSegmentTranslation = (segment: SegmentMap | undefined, lang: Languages) => {
	return segment
		? !!segment.name && !!segment.name[lang]
			? getTranslation(segment.name, lang)
			: segment.externalSegmentName
		: '';
};

export const getSkidataSegmentTranslationById = (
	segmentId: string | null,
	segmentMapping: SegmentMap[],
	lang: Languages,
) => getSkidataSegmentTranslation(getSkidataSegmentById(segmentId, segmentMapping), lang);

export const doesTicketHaveSegment = (segmentId: string | null) => (ticket: ProductApi) =>
	!!getTicketSegmentPricing(segmentId)(ticket);

export const getTicketSegmentPricing = (segmentId: string | null) => (ticket: ProductApi) =>
	ticket.segmentPricings?.find(({ segments }) => segments[0] === segmentId);

export const REGULAR_SEGMENT = 'regular';

export interface SegmentOption extends Option<string> {
	description?: string;
}

export const getSegmentOptions = (
	segmentPricings: SegmentPricing[],
	shopName: string,
	locationName: string,
	i18n: i18nInterface,
): SegmentOption[] => {
	if (!segmentPricings.length) {
		return [];
	}
	const rentleSegments = getRentleSegments();
	const segmentInfo = getSegments(shopName, locationName, i18n);
	const defaultOption = {
		value: REGULAR_SEGMENT,
		label: i18n.t('common:product.regular', 'Regular'),
	};
	const segmentOptions =
		segmentPricings?.flatMap((pricing) =>
			pricing.segments
				.filter((segment) => rentleSegments.includes(segment))
				.filter((segment) => !!segmentInfo.find((s) => s.value === segment))
				.map((segment) => {
					const { name, info } = segmentInfo.find((s) => s.value === segment)!;
					return { value: segment, label: name, description: info ? capitalize(info) : undefined };
				}),
		) ?? [];
	return [defaultOption, ...segmentOptions];
};

export const toRentleSegment = (segment: string | null) => {
	return segment && segment !== REGULAR_SEGMENT ? segment : undefined;
};
