import React from 'react';

import { Box, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiArrowRightLine } from 'react-icons/ri';
import { useSelector } from 'redux/hooks';
import * as ConfirmSelectors from 'redux/selectors/confirm';

import LinkButton from 'common/components/LinkButton';
import CenteredContent from 'components/CenteredContent';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';

const ShopperDetailsStart = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { getPath } = useRoutes();

	const responsiblePerson = useSelector(ConfirmSelectors.responsiblePersonDetails);

	return (
		<CenteredContent>
			<Box mb={3} mt={8}>
				<Typography variant="h5">
					{t('completed.header', {
						firstName: responsiblePerson?.firstName ?? '',
						defaultValue: 'Thank you {{firstName}}!',
					})}
				</Typography>
				<Box mt={4}>
					<Typography variant="h6">
						{t('completed.almostReadyToGo', 'You are almost ready to go')}
					</Typography>
				</Box>
				<Box mt={1}>
					<Typography variant="body2">
						{t(
							'completed.yourEquipmentIsWaitingForYou',
							'Your order has been confirmed, but we need a few more details from you to complete the order.',
						)}
					</Typography>
				</Box>
				<Box mt={3}>
					<LinkButton
						variant="contained"
						color="primary"
						className={classes.btn}
						to={getPath('confirmDetails')}
					>
						{t('completed.gotoShopperDetails', 'Add details')}
						<Box pl={1.5} display="flex" alignItems="center" justifyContent="center">
							<RiArrowRightLine size={20} />
						</Box>
					</LinkButton>
				</Box>
			</Box>
		</CenteredContent>
	);
};

const useStyles = makeStyles((theme) =>
	createStyles({
		btn: {
			width: '100%',
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			[theme.breakpoints.up('sm')]: {
				maxWidth: 260,
			},
		},
	}),
);

export default ShopperDetailsStart;
