import React from 'react';

import { ButtonBase, Typography } from '@mui/material';
import { Theme, darken, lighten } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';

import Spinner from 'common/components/Spinner';
import { Branding } from 'common/types';

interface Props {
	text: string;
	variant: 'filled' | 'outlined';
	disabled?: boolean;
	onClick?: () => void;
	size?: 'normal' | 'small';
	padding?: 'normal' | 'large';
	isLoading?: boolean;
	blurOnClick?: boolean;
	widthAuto?: boolean;
}

type ThemeProps = Props & Branding;

const BoxButton = (props: Props) => {
	const {
		text,
		variant,
		disabled,
		onClick,
		size = 'normal',
		padding = 'normal',
		isLoading = false,
		blurOnClick,
	} = props;
	const shopBranding = useSelector(ShopSelectors.shopBranding);
	const classes = useStyles({ ...props, ...shopBranding });

	return (
		<ButtonBase
			onClick={onClick}
			focusRipple
			disabled={disabled ?? false}
			onMouseDown={(e) => blurOnClick && e.preventDefault()}
			className={classNames(classes.root, {
				[classes.rootOutlined]: variant === 'outlined',
				[classes.rootFilled]: variant === 'filled',
				[classes.rootDisabled]: disabled === true,
				[classes.largePadding]: padding === 'large',
				[classes.sizeSmall]: size === 'small',
			})}
		>
			{!isLoading ? (
				<Typography
					variant="button"
					className={classNames({ [classes.textSmall]: size === 'small' })}
				>
					{text}
				</Typography>
			) : (
				<Spinner color="white" padding={12} />
			)}
		</ButtonBase>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: (props: ThemeProps) => (props.widthAuto ? 'auto' : '100%'),
			minHeight: 50,
			padding: theme.spacing(1, 2),
			borderRadius: (props: ThemeProps) => props.buttonRadius ?? 0,
		},
		rootFilled: {
			background: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
		rootOutlined: {
			background: theme.palette.common.white,
			color: theme.palette.common.black,
			border: `1px ${theme.palette.common.black} solid`,
		},
		rootDisabled: {
			background: lighten(theme.palette.common.black, 0.7),
			color: darken(theme.palette.common.white, 0.1),
		},
		largePadding: {
			padding: theme.spacing(1.5, 3),
		},
		sizeSmall: {
			minHeight: 40,
			padding: theme.spacing(0.5, 2),
		},
		textSmall: {
			fontSize: '1.2rem',
		},
	}),
);

export default BoxButton;
