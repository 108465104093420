import { chain, entries, max } from 'lodash';

import { AccessClaims } from 'common/modules/users/types';
import { hasAccessToAllLocations, hasAccessToLocation, isMember } from 'common/modules/users/utils';
import { ById, Category, Languages, ProductApi, PurchaseType, PurchaseTypes } from 'common/types';
import { Hash } from 'common/utils/arrays';
import { notUndefined } from 'common/utils/common';

import { BaseProductVariant } from '../inventory';
import { getTranslation } from '../translations';
import { AllPurchaseTypes } from './../../types/common';
import {
	getProductVariants,
	getVariantSkuId,
	getVariantSkuIds,
	isVariantForRent,
	isVariantForSale,
} from './variants';

export const isProductVisibleInAllLocations = (product: ProductApi): boolean => {
	return product.visibleInLocations === undefined || product.visibleInLocations.includes('ALL');
};

export const isProductVisibleInMultipleLocations = (
	product: ProductApi,
	locationCount: number,
): boolean => {
	return (
		locationCount > 1 &&
		(isProductVisibleInAllLocations(product) ||
			(!!product.visibleInLocations && product.visibleInLocations.length > 1))
	);
};

export const isProductVisibleInLocation = (product: ProductApi, locationId: string): boolean => {
	return (
		isProductVisibleInAllLocations(product) ||
		(product.visibleInLocations?.includes(locationId) ?? false)
	);
};

export const isProductAvailableForPurchaseType = (
	product: ProductApi,
	purchaseType: PurchaseType,
): boolean => {
	switch (purchaseType) {
		case PurchaseTypes.rental:
			return isProductForRent(product);
		case PurchaseTypes.sales:
			return isProductForSale(product);
		default:
			return false;
	}
};

export const isProductForSale = (product: ProductApi): boolean => {
	return (product.sales?.enabled ?? false) && isAnyVariantForSale(product.variants.options);
};

export const isProductForSubscription = (product: ProductApi): boolean => {
	return product.subscriptions?.enabled ?? false;
};

export const isAnyVariantForSale = (variants: BaseProductVariant[]): boolean => {
	return variants.some((variant) => isVariantForSale(variant));
};

export const isProductForRent = (product: ProductApi): boolean => {
	return (product.rentals?.enabled ?? false) && isAnyVariantForRent(product.variants.options);
};

export const isAnyVariantForRent = (variants: BaseProductVariant[]): boolean => {
	return variants.some((variant) => isVariantForRent(variant));
};

export const getProductPurchaseTypes = (product: ProductApi): PurchaseType[] => {
	return AllPurchaseTypes.filter((type) => isProductAvailableForPurchaseType(product, type));
};

export const productHasMultiplePurchaseTypes = (product: ProductApi): boolean => {
	return getProductPurchaseTypes(product).length > 1;
};

export const productHasAnyPurchaseTypes = (product: ProductApi): boolean => {
	return getProductPurchaseTypes(product).length > 0;
};

export const isProductEditableByUser = (
	product: ProductApi,
	userAccess: AccessClaims | null,
): boolean => {
	if (!userAccess) return false;
	if (isMember(userAccess.roles)) return false;
	if (hasAccessToAllLocations(userAccess)) return true;
	if (isProductVisibleInAllLocations(product)) return false;

	return product.visibleInLocations!.every((locationId) =>
		hasAccessToLocation(userAccess, locationId),
	);
};

export const getProductsByCategory = (products: ProductApi[]): Hash<ProductApi[]> => {
	return products.reduce((map, product) => {
		product.categoryIds?.forEach((categoryId) => {
			map[categoryId] = map[categoryId] ? [...map[categoryId], product] : [product];
		});
		return map;
	}, {} as Hash<ProductApi[]>);
};

export const getProductsWithoutCategory = (products: ProductApi[]): ProductApi[] => {
	return products.filter((p) => !p.categoryIds || p.categoryIds.length === 0);
};

export const getProductsMatchingSearch = (
	searchValue: string,
	args: {
		products: ProductApi[];
		categoriesById?: ById<Category>;
		lang?: Languages;
	},
): ProductApi[] => {
	const { products, categoriesById = {}, lang = 'en' } = args;
	if (!searchValue) {
		return products;
	}

	const categoryNamesById = entries(categoriesById).reduce((result, [id, category]) => {
		result[id] = getTranslation(category.name, lang).toLowerCase().trim();
		return result;
	}, {} as ById<string>);

	return products.filter((product) => {
		const name = getTranslation(product.name, lang).toLowerCase().trim();
		const categoryNames =
			product.categoryIds?.map((id) => categoryNamesById[id]).filter(notUndefined) ?? [];
		const valuesToSearch = [name, ...categoryNames];
		const search = searchValue.toLowerCase().trim();
		return valuesToSearch.some((value) => value.indexOf(search) !== -1);
	});
};

export const getProductsVisibleInLocation = (
	products: ProductApi[],
	locationId: string,
): ProductApi[] => {
	return products.filter((product) => isProductVisibleInLocation(product, locationId));
};

export const isPackageProduct = (product: ProductApi): boolean => {
	return !!product.set;
};

export const getProductIdsInPackage = (product: ProductApi): string[] => {
	if (!isPackageProduct(product)) return [];
	return chain(product.setIncludes)
		.entries()
		.sortBy(([, { index }]) => {
			return index;
		})
		.map(([id]) => id)
		.value();
};

export const getProductIdsInPackageWithUnits = (
	product: ProductApi,
): { id: string; units: number }[] => {
	if (!isPackageProduct(product)) return [];
	return chain(product.setIncludes)
		.entries()
		.sortBy(([, { index }]) => index)
		.map(([id, { units }]) => {
			return {
				id,
				units,
			};
		})
		.value();
};

export const getProductsInPackage = (
	product: ProductApi,
	stockProducts: ProductApi[] | Hash<ProductApi>,
): ProductApi[] => {
	const packageProductIds = getProductIdsInPackage(product);
	return packageProductIds
		.map((id) => {
			if (Array.isArray(stockProducts)) {
				return stockProducts.find((p) => p.id === id);
			} else {
				return stockProducts[id];
			}
		})
		.filter(notUndefined);
};

export type ProductWithUnits = { product: ProductApi; units: number };

export const getProductsInPackageWithUnits = (
	product: ProductApi,
	stockProducts: ProductApi[] | Hash<ProductApi>,
): ProductWithUnits[] => {
	const packageProductIdsWithUnits = getProductIdsInPackageWithUnits(product);

	return packageProductIdsWithUnits
		.flatMap(({ id, units }) => {
			const product = (() => {
				if (Array.isArray(stockProducts)) {
					return stockProducts.find((p) => p.id === id);
				} else {
					return stockProducts[id];
				}
			})();

			return {
				product,
				units,
			};
		})
		.filter((item): item is ProductWithUnits => !!item.product);
};

export const getStockProductMaintenanceMinutes = (
	stockProduct: ProductApi,
	stockProducts: ProductApi[] | Hash<ProductApi>,
): number =>
	isPackageProduct(stockProduct)
		? getPackageProductMaintenanceMinutes(stockProduct, stockProducts)
		: stockProduct.maintenanceTimeMinutes ?? 0;

export const getPackageProductMaintenanceMinutes = (
	packageProduct: ProductApi,
	stockProducts: ProductApi[] | Hash<ProductApi>,
): number => {
	const productsInPackage = getProductsInPackage(packageProduct, stockProducts);
	return max(productsInPackage.map((p) => p.maintenanceTimeMinutes ?? 0)) ?? 0;
};

export const isProductInPackage = (productId: string, packageProduct: ProductApi): boolean => {
	return isPackageProduct(packageProduct) && !!packageProduct.setIncludes?.[productId];
};

export const getPackagesThatProductBelongsTo = (productId: string, stockProducts: ProductApi[]) => {
	return stockProducts.filter((stockProduct) => isProductInPackage(productId, stockProduct));
};

export const areProductsInCategory = (product: ProductApi[], categoryId: string) =>
	!!product.find((p) => p?.categoryIds?.includes(categoryId));

export const getProductsBySkuId = (products: ProductApi[], skuId: string) => {
	return products.filter((p) => {
		const variantSkuIds = getVariantSkuIds(p.variants.options);
		return variantSkuIds.includes(skuId);
	});
};

export const getProductVariantsBySkuId = (products: ProductApi[], skuId: string) => {
	return products.flatMap(getProductVariants).filter((v) => getVariantSkuId(v) === skuId);
};

export const variantIdToSkuId = (variantId: string, products: ProductApi[]) => {
	const productVariants = products.flatMap((p) => p.variants.options);
	const productVariant = productVariants.find((v) => v.id === variantId);
	return !!productVariant ? getVariantSkuId(productVariant) : null;
};
