import React, { useEffect } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import * as ViewActions from 'redux/actions/view';
import { useDispatch, useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as NavSelectors from 'redux/selectors/nav';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import { ProductApi } from 'common/types';
import { useAnalytics } from 'hooks/useAnalytics';
import { useTranslation } from 'services/localization';
import { BrowseItemProps, getBrowseLiftTicketProps, getBrowseProductProps } from 'utils/products';

const RecommendedProduct = ({
	name,
	imageSrc,
	fromPriceString,
	linkTo,
	onClick,
}: BrowseItemProps) => {
	const classes = useStyles();

	return (
		<Link to={linkTo + '?ref=recommended'} onClick={onClick} className={classes.wrapper}>
			<Box className={classes.imageWrapper}>
				<Box className={classes.imageInnerWrapper}>
					<img src={imageSrc} className={classes.image} alt="" />
				</Box>
			</Box>
			<Box mt={1}>
				<Typography variant="body1" className={classes.productName}>
					{name}
				</Typography>
			</Box>
			<Typography variant="body2" color="textSecondary">
				{fromPriceString}
			</Typography>
		</Link>
	);
};

const CartRecommendedProducts = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { logEcomEvent } = useAnalytics();
	const dispatch = useDispatch();
	const shopName = useSelector(NavSelectors.shopName);
	const locationName = useSelector(NavSelectors.locationName);
	const pricingTables = useSelector(ShopSelectors.shopPricingTables);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const timezone = useSelector(ShopSelectors.shopTimezone);
	const currency = useSelector(ShopSelectors.shopCurrency);
	const lang = useSelector(ViewSelectors.language);
	const recommendedProducts = useSelector(CartSelectors.recommendedProducts);
	const recommendLiftTicket = useSelector(CartSelectors.recommendLiftTicket);
	const liftTicketCategory = useSelector(StockSelectors.liftTicketCategory);
	const liftTicketProducts = useSelector(StockSelectors.liftTicketProducts);

	useEffect(() => {
		logEcomEvent('view_item_list', { isRecommendedList: true, categoryId: null });
	}, [logEcomEvent]);

	const onProductClick = (product: 'lift_ticket' | ProductApi) => {
		dispatch(ViewActions.setCartOpen(false));
		if (product === 'lift_ticket') {
			logEcomEvent('select_item', {
				isRecommendedList: true,
				isLiftTicket: true,
				categoryId: null,
			});
		} else {
			logEcomEvent('select_item', {
				isRecommendedList: true,
				productId: product.id,
				categoryId: null,
			});
		}
	};

	return (
		<Box className={classes.recommended}>
			<Box className={classes.recommendedHeader}>
				<Typography variant="h5">{t('Cart.recommendedForYou', 'Recommended for you')}</Typography>
			</Box>
			<Grid container spacing={2} className={classes.recommendedProducts}>
				{recommendLiftTicket && liftTicketCategory && (
					<Grid item xs={6} sm={4}>
						<RecommendedProduct
							{...getBrowseLiftTicketProps({
								liftTicketCategory,
								liftTicketProducts,
								currency,
								shopName,
								locationName,
								lang,
							})}
							onClick={() => onProductClick('lift_ticket')}
						/>
					</Grid>
				)}
				{recommendedProducts.map((product) => (
					<Grid item xs={6} sm={4} key={product.id}>
						<RecommendedProduct
							{...getBrowseProductProps({
								product,
								pricingTables,
								openingHours,
								currency,
								shopName,
								locationName,
								lang,
								timezone,
							})}
							onClick={() => onProductClick(product)}
						/>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		recommended: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			[theme.breakpoints.up('md')]: {
				paddingBottom: 0,
			},
		},
		recommendedHeader: {
			padding: theme.spacing(3),
			flex: 0,
			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(4),
			},
		},
		recommendedProducts: {
			flex: 1,
			padding: theme.spacing(0, 2, 2),
			width: '100%',
			margin: 0,
			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(0, 3, 3),
				overflowY: 'auto',
			},
		},
		wrapper: {
			display: 'block',
			[theme.breakpoints.up('md')]: {
				marginBottom: theme.spacing(2),
			},
			'&:hover *': {
				textDecoration: 'underline',
			},
			'&:hover img': {
				opacity: 0.85,
			},
			'&:focus img': {
				opacity: 0.85,
			},
		},
		imageWrapper: {
			width: '100%',
			position: 'relative',
			border: '1px solid #eee',
			padding: theme.spacing(1),
			[theme.breakpoints.up('md')]: {
				backgroundColor: theme.palette.common.white,
				border: 'none',
			},
		},
		imageInnerWrapper: {
			width: '100%',
			paddingTop: '100%',
			position: 'relative',
		},
		image: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			top: 0,
			left: 0,
			objectFit: 'contain',
			objectPosition: 'center',
		},
		productName: {
			fontWeight: 500,
			fontSize: '1.5rem',
			lineHeight: 'normal',
			marginBottom: '2px',
		},
	}),
);

export default CartRecommendedProducts;
