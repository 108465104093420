import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { TFunction } from 'react-i18next';
import { RiArrowLeftLine, RiMapPinLine, RiTruckLine } from 'react-icons/ri';

import { getFormattedAddress } from 'common/modules/atoms/addresses';
import { timeslotToString } from 'common/modules/delivery/utils';
import { getTranslation } from 'common/modules/translations';
import { DateFormatObject, Languages, OrderDeliveryService, OrderObject } from 'common/types';
import { getLatestProductEndTime } from 'common/utils/dateCalculations';
import { localFormat } from 'common/utils/dateUtils';

interface TimelineItemType {
	date: string;
	location: string;
	label: string;
	icon: JSX.Element;
	type: 'booking' | 'delivery';
}

type TimelineVariant = 'black' | 'white';

interface Props {
	order: OrderObject;
	shopDateFormat: DateFormatObject;
	shopTimezone: string;
	t: TFunction;
	lang: Languages;
	variant: TimelineVariant;
}

const BookingTimeline = (props: Props) => {
	const { shopDateFormat, shopTimezone, t, lang, variant } = props;
	const classes = useStyles({ variant });
	const { rentalInfo, products, orderDelivery } = props.order;
	const { startLocation, endLocation, startDate } = rentalInfo;

	const endDate = getLatestProductEndTime(products);

	const bookingEndDate =
		!!endDate &&
		!!orderDelivery?.from?.timeslot?.startDate &&
		moment(orderDelivery.from.timeslot.startDate).isSameOrBefore(endDate)
			? orderDelivery.from.timeslot.startDate
			: endDate;

	const toDeliveryItem = (args: {
		service: OrderDeliveryService | undefined;
		type: 'to' | 'from';
		date: string | null;
	}): TimelineItemType[] => {
		const { service, type, date } = args;
		const details = service?.[type];
		const selfReturn = service?.from?.disabled && type === 'from';
		return !!details
			? [
					{
						date:
							(selfReturn
								? !!date
									? localFormat(moment(date), 'ddd. MMM D, HH:mm', shopDateFormat)
									: t('common:delivery.endUndetermined', 'Undetermined')
								: timeslotToString({
										timeslot: details.timeslot,
										timezone: shopTimezone,
										shopDateFormat,
								  })) || localFormat(moment(date), 'ddd. MMM D', shopDateFormat),
						location: selfReturn
							? getTranslation(service?.returnInstructions ?? { def: '' }, lang)
							: getFormattedAddress(details?.location?.address),
						label:
							type === 'to'
								? t('common:delivery.deliveryStart', 'Delivery start')
								: t('common:delivery.deliveryEnd', 'Delivery end'),
						icon: selfReturn ? (
							<RiArrowLeftLine size={20} />
						) : (
							<RiTruckLine size={20} className={type === 'from' ? classes.reverse : undefined} />
						),
						type: 'delivery',
					},
			  ]
			: [];
	};

	const toBookingItem = (args: {
		date: string | null;
		name: string;
		isStart: boolean;
	}): TimelineItemType[] =>
		!!args.date
			? [
					{
						date: localFormat(moment(args.date), 'ddd. MMM D, HH:mm', shopDateFormat),
						location: args.name,
						label: args.isStart
							? t('common:booking.bookingStart', 'Booking start')
							: t('common:booking.bookingEnd', 'Booking end'),
						icon: <RiMapPinLine size={20} />,
						type: 'booking',
					},
			  ]
			: [];

	const timelineItems = [
		...toDeliveryItem({ service: orderDelivery, type: 'to', date: startDate }),
		...toBookingItem({
			date: startDate,
			name: startLocation.name,
			isStart: true,
		}),
		...toBookingItem({
			date: bookingEndDate,
			name: endLocation.name,
			isStart: false,
		}),
		...toDeliveryItem({ service: orderDelivery, type: 'from', date: endDate }),
	];

	return (
		<Box>
			{timelineItems.map((item, idx) => (
				<TimelineItem
					key={idx}
					date={item.date}
					icon={idx === 0 || idx === timelineItems.length - 1 ? item.icon : undefined}
					label={idx === 0 || idx === timelineItems.length - 1 ? item.location : item.label}
					withLine={idx !== timelineItems.length - 1}
					bubbleOutlined={idx !== 0}
					variant={variant}
				/>
			))}
		</Box>
	);
};

interface TimelineItemProps {
	date: string;
	label: string;
	variant: TimelineVariant;
	icon?: JSX.Element;
	withLine?: boolean;
	bubbleOutlined?: boolean;
}

const TimelineItem = (props: TimelineItemProps) => {
	const { date, icon, label, withLine, bubbleOutlined, variant } = props;
	const classes = useStyles({ variant });

	return (
		<Box className={classes.item}>
			<Box className={classes.itemLeft}>
				<div className={classNames(classes.bubble, { [classes.bubbleOutlined]: bubbleOutlined })} />
				{withLine && <div className={classes.line} />}
			</Box>

			<Box>
				<Typography variant="body1">{date}</Typography>
				<Box className={classes.location}>
					{!!icon && <span className={classes.locationIcon}>{icon}</span>}
					<Typography variant="caption" className={icon ? classes.locationLabel : undefined}>
						{label}
					</Typography>
				</Box>
				{withLine && <Box pt={4} />}
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		item: {
			display: 'flex',
			flexDirection: 'row',
		},
		itemLeft: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginRight: theme.spacing(3),
			marginBottom: theme.spacing(1),
			position: 'relative',
			top: theme.spacing(1),
		},
		bubble: {
			minWidth: '10px',
			minHeight: '10px',
			maxWidth: '10px',
			maxHeight: '10px',
			borderRadius: '50%',
			background: ({ variant }: { variant: TimelineVariant }) =>
				variant === 'black' ? theme.palette.common.black : theme.palette.primary.main,
		},
		bubbleOutlined: {
			background: ({ variant }: { variant: TimelineVariant }) => 'rgba(0,0,0,0)',
			border: ({ variant }: { variant: TimelineVariant }) =>
				`1px solid ${
					variant === 'black' ? theme.palette.common.black : theme.palette.common.white
				}`,
		},
		line: {
			width: 1,
			height: '100%',
			borderLeft: ({ variant }: { variant: TimelineVariant }) =>
				variant === 'black' ? `1px dashed black` : `1px dashed ${theme.palette.primary.main}`,
			marginTop: theme.spacing(1),
			opacity: ({ variant }: { variant: TimelineVariant }) => (variant === 'black' ? 1 : 0.3),
		},
		location: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		locationIcon: {
			paddingRight: 4,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: ({ variant }: { variant: TimelineVariant }) =>
				variant === 'black' ? theme.palette.colors.tundra.main : 'inherit',
		},
		reverse: {
			transform: 'scaleX(-1)',
		},
		locationLabel: {
			marginLeft: theme.spacing(0.5),
			marginTop: theme.spacing(0.25),
		},
	}),
);

export default BookingTimeline;
