import { createAction } from '@reduxjs/toolkit';
import { ClientAuthProfile } from 'redux/reducers/auth';

import { LoadingData } from 'common/types';

export const setAuthToken = createAction<string | null>('Auth/SET_AUTH_TOKEN');

export const setAuthProfile = createAction<LoadingData<ClientAuthProfile | null>>(
	'Auth/SET_AUTH_PROFILE',
);

export const setAuthError = createAction<string | null>('Auth/SET_AUTH_ERROR');

export const setIsNewRegistration = createAction<boolean>('Auth/SET_IS_NEW_REGISTRATION');
