import React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Props {
	onClick: () => void;
	error?: string;
}

export const AdyenPaymentMethodError = (props: Props) => {
	const { t } = useTranslation();
	return (
		<Box textAlign="center">
			<Typography color="textSecondary">
				{props.error ||
					t(
						'common:payment.loadingError',
						'Error getting available payment methods. Please try again',
					)}
			</Typography>
			<Box mt={2} />
			<Button variant="outlined" color="primary" onClick={props.onClick}>
				{t('common:actions.tryAgain', 'Try again')}
			</Button>
		</Box>
	);
};
