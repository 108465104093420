import React, { useMemo, useState } from 'react';

import { Box, Divider, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { lowerCase } from 'lodash';
import moment from 'moment-timezone';
import { RiInformationLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as CartActions from 'redux/actions/cart';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import useScrollToError from 'common/hooks/useScrollToError';
import { deliveryOptionToCartDelivery } from 'common/modules/delivery/utils';
import { sendEventToParentPage } from 'common/modules/embed';
import {
	getLongestSubscriptionOptionAsYears,
	getSubscriptionOptionDuration,
	hasAutoRenewProductApiSubscription,
} from 'common/modules/subscriptions';
import { ProductApi, PurchaseTypes } from 'common/types';
import { isRunInIframe } from 'common/utils/browserUtils';
import BoxButton from 'components/BoxButton';
import useOnlineAvailability from 'hooks/useOnlineAvailability';
import useProductForm from 'hooks/useProductForm';
import useScrollToCart from 'hooks/useScrollToCart';
import { useTranslation } from 'services/localization';
import { isProductInStock, isSetProductInStock } from 'utils/products';
import { getMaxAvailabilityRangeYears } from 'utils/startTimes';

import AmountSelector from './components/AmountSelector';
import SelectionSummary from './components/SelectionSummary';
import StartDateTimeSelector from './components/StartDateTimeSelector';
import SubscriptionOptionSelector from './components/SubscriptionDurationSelector';
import VariantSelector from './components/VariantSelector';
import { getTotalQuantityForSelection } from './utils';

export interface Props {
	product: ProductApi;
	locationId: string;
}
const SubscriptionProductForm = (props: Props) => {
	const { product, locationId } = props;
	const [initialProductSelectionIsAvailable, setInitialProductSelectionIsAvailable] = useState(
		false,
	);
	const { t, getTranslation } = useTranslation();
	const urlLocation = useLocation();
	const [openCart, isCartOpen] = useScrollToCart();
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const hasDelivery = useSelector(ViewSelectors.deliverySelected);
	const taxExcluded = useSelector(ShopSelectors.taxExcluded);
	const currency = useSelector(ShopSelectors.shopCurrency);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const timezone = useSelector(ShopSelectors.shopTimezone);
	const itemsInCart = useSelector(CartSelectors.cartProductsCount);
	const hasAutoRenew = hasAutoRenewProductApiSubscription(product);

	const dispatch = useDispatch();

	const form = useProductForm({
		productId: product.id,
		purchaseType: PurchaseTypes.subscription,
		locationId,
	});
	const stockProducts = useSelector(StockSelectors.stockProductsData);

	const { scrollToFirstError } = useScrollToError({
		rootId: 'product-form',
		scrollOffset: -120,
		onScroll: (offset) => {
			if (isRunInIframe()) {
				sendEventToParentPage({
					origin: 'rentle',
					type: 'trigger-scroll',
					payload: {
						offset,
					},
				});
			}
		},
	});

	const variantId = form.values.selection?.variantId;
	const setProductsVariantIds = useMemo(
		() => (product.set ? form.values.selection?.children?.map((c) => c.variantId) : null),
		[form.values.selection?.children, product.set],
	);
	const subscriptionOptions = form.meta.subscriptionOptions.map((o) => o.option);

	const { quantity, availability, startTimeCounts } = useOnlineAvailability(product.id, {
		locationId,
		startDate: moment().toISOString(),
		endDate: moment()
			.add(
				getMaxAvailabilityRangeYears(getLongestSubscriptionOptionAsYears(subscriptionOptions)),
				'years',
			)
			.toISOString(),
		purchaseType: PurchaseTypes.subscription,
	});

	const selectionTotalQuantity = useMemo(
		() =>
			getTotalQuantityForSelection({
				quantity,
				selection: product.set
					? { set: true, variantIds: setProductsVariantIds ?? [] }
					: { set: false, variantId },
			}),
		[product.set, quantity, setProductsVariantIds, variantId],
	);

	const handleAddToBag = async () => {
		const { valid, value } = form.actions.validate();

		if (!valid) {
			scrollToFirstError();
			return;
		}

		if (
			value &&
			value.purchaseType === PurchaseTypes.subscription &&
			!!form.meta.selectedSubscription?.option
		) {
			if (itemsInCart === 0 && !!selectedDeliveryOption && !!value.deliveryTimeslot) {
				const delivery = deliveryOptionToCartDelivery(selectedDeliveryOption, {
					taxExcluded,
					currency: currency.code,
					deliveryTimeslot: value.deliveryTimeslot,
					openingHours,
					timezone,
					getTranslation,
					t,
				});

				dispatch(CartActions.setDelivery(delivery));
			}
			const selectedAsAdditional =
				new URLSearchParams(urlLocation.search).get('ref') === 'recommended';

			dispatch(
				CartActions.addSelectionToCart({
					selection: value.selection,
					startDate: value.startDate,
					startLocationId: value.locationId,
					duration: getSubscriptionOptionDuration(
						form.meta.selectedSubscription.option,
						value.startDate,
					),
					endLocationId: value.locationId,
					quantity: form.values.quantity,
					selectedAsAdditional,
					segment: value.segment,
					purchaseType: PurchaseTypes.subscription,
					subscription: form.meta.selectedSubscription.option,
				}),
			);
			openCart();
		}
	};

	const inStock = product.set
		? isSetProductInStock(product, stockProducts, quantity)
		: isProductInStock(product, quantity);

	const hasDurationOptions = !!form.meta.subscriptionOptions.length;
	const quantitiesLoading = quantity.loading;
	const availabilitiesLoading =
		quantitiesLoading || availability.loading || startTimeCounts.loading;

	const showSummary = !!form.values.startDate;
	const bottomDisabled =
		!form.values.selection || selectionTotalQuantity === 0 || !hasDurationOptions;
	const durationSelectorIsDisabled = quantitiesLoading || !form.values.selection || bottomDisabled;

	return (
		<Stack spacing={2}>
			<VariantSelector
				product={product}
				value={form.values.selection}
				onChange={form.actions.setSelection}
				showError={!!form.errors.selection}
				variantQuantities={quantity}
				purchaseType={PurchaseTypes.subscription}
			/>
			<AmountSelector
				product={product}
				selection={form.values.selection}
				quantity={selectionTotalQuantity}
				loading={quantitiesLoading}
				value={form.values.quantity}
				onChange={form.actions.setQuantity}
				error={form.errors.quantity}
				outOfStock={!inStock}
				isDeliveryDisabled={hasDelivery && !hasDurationOptions}
			/>

			<Divider />
			<Box display="flex" justifyContent="space-between">
				<Typography
					variant="body1"
					sx={{ fontWeight: 500, opacity: durationSelectorIsDisabled ? 0.5 : 1 }}
				>
					{t('ProductForm.subscriptionPeriod', 'Subscription period')}
				</Typography>
				<Tooltip
					title={
						hasAutoRenew
							? t(
									'ProductForm.hasAutoRenewalTooltip',
									'At the end of your minimum subscription period, you can keep on subscribing for the same price or cancel your subscription by contacting us. Switching to a longer subscription plan to lower your monthly payments is also possible at any time.',
							  )
							: t(
									'ProductForm.noAutoRenewalTooltip',
									'Your subscription will not automatically renew at the end of the subscription period',
							  )
					}
					placement="bottom-start"
				>
					<Stack
						display="flex"
						direction="row"
						alignItems="center"
						spacing={0.5}
						sx={{ color: (theme) => theme.palette.text.secondary }}
					>
						<RiInformationLine size={16} color="inherit" />
					</Stack>
				</Tooltip>
			</Box>
			<SubscriptionOptionSelector
				options={form.meta.subscriptionOptions}
				value={form.values.subscriptionId}
				onChange={form.actions.setSubscriptionId}
				quantity={form.values.quantity}
				error={form.errors.subscriptionId ?? undefined}
				disabled={durationSelectorIsDisabled}
			/>

			<Typography variant="body2" color="secondary" sx={{ fontWeight: 400 }}>
				{`${t('ProductForm.autoRenewal', 'Auto renewal:')}: ${lowerCase(
					hasAutoRenew ? t('common:actions.yes', 'Yes') : t('common:actions.no', 'No'),
				)}`}
			</Typography>

			{!isCartOpen && (
				<StartDateTimeSelector
					product={product}
					availabilitiesLoading={availabilitiesLoading}
					form={form}
					setInitialProductSelectionIsAvailable={setInitialProductSelectionIsAvailable}
					availability={availability}
					setProductsVariantIds={setProductsVariantIds}
					startTimeCounts={startTimeCounts}
					purchaseType={PurchaseTypes.subscription}
				/>
			)}
			{showSummary && (
				<Stack spacing={2}>
					{inStock && initialProductSelectionIsAvailable && (
						<SelectionSummary
							selection={form.values.selection}
							purchaseType={PurchaseTypes.subscription}
							quantity={form.values.quantity}
							charge={form.meta.price ?? 0}
							startDate={form.values.startDate ?? null}
							duration={form.meta.selectedDuration?.option ?? null}
							subscriptionOption={form.meta.selectedSubscription?.option}
						/>
					)}

					<BoxButton
						text={t('common:actions.addToBag', 'Add to bag')}
						variant="filled"
						padding={isSmallScreen ? 'large' : 'normal'}
						onClick={handleAddToBag}
						disabled={
							availabilitiesLoading ||
							!inStock ||
							!initialProductSelectionIsAvailable ||
							(!!hasDelivery && !hasDurationOptions) ||
							selectionTotalQuantity === 0
						}
						blurOnClick
					/>
				</Stack>
			)}
		</Stack>
	);
};

export default SubscriptionProductForm;
