import { ById, EnumKeys, EnumValues, LocaleField, SkidataKeycardType } from 'common/types';
import { stringEnum } from 'common/utils/objects';

import { FixedFieldValueOptions, InventoryStatuses, ItemStates } from './constants';

export interface BaseProductVariant {
	id: string;
	internalId?: SkidataKeycardType;
	values: {
		[propertyId: string]: LocaleField;
	};
	sales: {
		enabled: boolean;
		priceOverride: number | null;
	} | null;
	rentals: {
		enabled: boolean;
		priceIncrease: number | null;
	} | null;
	/**
	 * Array of stock sources that fulfill the order,
	 * Can support cases like:
	 * - 1x e-bike AND 2x helmet
	 * - 1x e-bike OR mtn-bike AND 1x helmet
	 *
	 * Structure is dynamic object with {key: number of item, value: array of fulfillable SKUs}
	 * - Keys of the object are the AND statements to add multiple stock units to the product.
	 * It defines the number of different stock units that are needed to fulfill the order.
	 * 	- E.g. Array of length 2 means that the product needs 2 different units from the inventory, example 1x e-bike AND 1x helmet
	 * - Items of the nested array are the OR statements and provide all the alternatives that can fulfill that one sku slot.
	 * 	- E.g. Array of [{ skuId: 'SKU1', units: 1}, { skuId: 'SKU2', units: 1 }] means that product needs either SKU1 OR SKU2 from the inventory
	 *
	 */
	stockSources: null | VariantStockSource;
}

export type FixedFieldValueOption = EnumKeys<typeof FixedFieldValueOptions>;
export interface ProductVariant extends BaseProductVariant {
	// Not yet implemented
	availabilityLimits?: any[];
}

export interface VariantStockSource {
	[key: number]: StockSourceOption[];
}

export interface StockSourceOption {
	skuId: string;
	type: 'item' | 'bulk';
	// Added later, format of ID_value;ID_value;ID_value. List would define the OR conditions
	// tagString?: string[];
	// Added later. List would define the OR conditions
	// itemCode?: string[];
	units: number;
}

export type InventoryStatus = EnumKeys<typeof InventoryStatuses>;
export type ItemState = EnumKeys<typeof ItemStates>;

export type InventoryStatusWithQuantity = {
	[status in InventoryStatus]?: number;
};
export interface SkuItem extends InventoryBaseDoc {
	quantity: number;
	itemType: 'single' | 'bulk';
	statusesByLocation: {
		[locationId: string]: InventoryStatusWithQuantity;
	};
	allocationsByLocation?: {
		[locationId: string]: InventoryItemAllocationQuantity;
	};
	reservedOrderCountsByLocation?: {
		[locationId: string]: number;
	};
}

export type FixedFieldValues = {
	[field in FixedFieldValueOption]?: string;
};

export interface BaseInventoryItem extends Omit<InventoryBaseDoc, 'skuCode' | 'skuName'> {
	locationId: string;
	skuId: string | null;
	skuName: string | null;
	skuCode: string | null;
	identifiers: string[];
	fixedFieldValues?: FixedFieldValues;
	activeOrderIds?: string[];
	reservedCountsByOrderId?: ById<number>;
}

export const AllocationTypes = stringEnum(['rental', 'sales']);
export type AllocationType = EnumValues<typeof AllocationTypes>;
export const AllAllocationTypes: AllocationType[] = Object.values(AllocationTypes);

export type InventoryItemAllocationBoolean = Partial<Record<AllocationType, boolean>>;
export type InventoryItemAllocationQuantity = Partial<Record<AllocationType, number>>;

export interface SingleInventoryItem extends BaseInventoryItem {
	status: InventoryStatus;
	type: 'single';
	allocation: InventoryItemAllocationBoolean;
}

export interface BulkInventoryItem extends BaseInventoryItem {
	statuses: {
		[status in InventoryStatus]?: number;
	};
	allocations: InventoryItemAllocationQuantity;
	quantity: number;
	type: 'bulk';
}

export type InventoryItem = SingleInventoryItem | BulkInventoryItem;

export type ByFieldId = ById<string | number>;

interface InventoryBaseDoc {
	id: string;
	skuName: string;
	skuCode: string;
	shopId: string;
	barCode?: string;
	usageCount: number;
	usageSeconds: number;
	lastUsed: string | null;
	createdAt: string;
	fieldValues?: ByFieldId;
	fromTemplate?: boolean;
}

export interface InventoryFields {
	order: string[];
	values: ById<InventoryField>;
}

export interface InventoryField {
	id: string;
	label: string;
	type: 'text' | 'number';
	values: string[] | number[];
}

export interface InventoryConfiguration {
	// Equals to shopId
	id: string;
	shopId: string;
	fields: InventoryFields;
}

export interface VariantProperty {
	id: string;
	name: LocaleField;
}
export interface ItemHistory {
	id: string;
	startDate: string;
	endDate: string;
	usageSeconds: number;
	order: {
		orderId: string;
		orderNumber: number | null;
		shopperId: string;
		orderProductId: string;
		setIndex: number | null;
	};
	stock: {
		productId: string;
		variantId: string | null;
		skuId: string;
		itemId: string;
		itemCode: string | null;
	};
}

export interface NewSkuItemProps<T extends SkuItem['itemType']> {
	id: string;
	name: string;
	skuCode: string;
	shopId: string;
	itemType: T;
	fromTemplate?: boolean;
}

interface NewBaseInventoryItemProps {
	id: string;
	skuName: string;
	skuCode: string;
	skuId: string;
	shopId: string;
	locationId: string;
	identifier: string[] | null;
	fieldValues: {
		[fieldId: string]: string | number;
	};
	fixedFieldValues: FixedFieldValues | undefined;
	fromTemplate?: boolean;
}

export interface NewSingleInventoryItemProps extends NewBaseInventoryItemProps {
	type: 'single';
	status: SingleInventoryItem['status'];
	allocation: SingleInventoryItem['allocation'];
}

export interface NewBulkInventoryItemProps extends NewBaseInventoryItemProps {
	type: 'bulk';
	quantity: number;
	statuses: BulkInventoryItem['statuses'];
	allocations: BulkInventoryItem['allocations'];
}
