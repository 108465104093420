import React from 'react';

import {
	Box,
	IconButton,
	ListSubheader,
	Menu,
	MenuItem,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { RiUserFollowFill, RiUserLine } from 'react-icons/ri';

import Spinner from 'common/components/Spinner';
import useExternalAuth from 'hooks/useExternalAuth';
import { useTranslation } from 'services/localization';

const AuthIcon = () => {
	const { t } = useTranslation();
	const classes = useStyles();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const {
		isAuthInUse,
		oauthRedirect,
		isLoggedIn,
		loginLoading,
		authProfile,
		logout,
	} = useExternalAuth();
	const firstName = authProfile.data?.firstName;
	const lastName = authProfile.data?.lastName;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleAuthMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleAuthMenuClose = () => {
		setAnchorEl(null);
	};

	const handleLogoutClick = () => {
		logout();
		handleAuthMenuClose();
	};

	if (!isAuthInUse) return null;

	if (loginLoading)
		return (
			<Box position="relative">
				<Spinner />
			</Box>
		);

	if (isLoggedIn) {
		return (
			<>
				<Box
					display="flex"
					alignItems="center"
					className={classes.logOutBtn}
					onClick={handleAuthMenuOpen}
				>
					{!isMobile && firstName && (
						<Box mr={1} whiteSpace="nowrap">
							<Typography variant="body2">
								{t('common:greetings.hi')}, {firstName}
							</Typography>
						</Box>
					)}
					<RiUserFollowFill color="black" size={22} />
				</Box>
				<Menu
					id="auth-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleAuthMenuClose}
				>
					{firstName && (
						<ListSubheader component="div" classes={{ root: classes.listHeader }}>
							{firstName} {lastName ?? ''}
						</ListSubheader>
					)}
					<MenuItem className={classes.menuItem} onClick={handleLogoutClick}>
						{t('common:actions.logout')}
					</MenuItem>
				</Menu>
			</>
		);
	}

	return (
		<IconButton className={classes.removeIconBtnPadding} onClick={oauthRedirect}>
			<RiUserLine color="black" />
		</IconButton>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		removeIconBtnPadding: {
			marginRight: -12,
			marginLeft: -12,
		},
		logOutBtn: {
			cursor: 'pointer',
		},
		listHeader: {
			fontWeight: 500,
			fontSize: '1.6rem',
		},
		menuItem: {
			fontWeight: 500,
			minHeight: 40,
		},
	}),
);

export default AuthIcon;
