import type { HttpsCallableOptions } from 'common/frontend/firebase/functions';

import { admin } from './admin';
import { availability } from './availability';
import { billing } from './billing';
import { customDomains } from './customDomains';
import { discountCodes } from './discountCodes';
import { generators } from './generators';
import * as integrations from './integrations';
import { internal } from './internal';
import * as orders from './orders';
import * as paymentAccounts from './paymentAccounts';
import { payments } from './payments';
import { plans } from './plans';
import { reports } from './reports';
import { skidata } from './skidata';
import { users } from './users';

export interface Callable<In, Out> {
	(arg: In, callableOptions?: HttpsCallableOptions): Promise<Out>;
}

export type CallableCreator = <In, Out>(
	name: string,
	callableOptions?: HttpsCallableOptions,
) => Callable<In, Out>;

export const callableGenerator = (createCallable: CallableCreator) =>
	({
		admin: admin(createCallable),
		availability: availability(createCallable),
		billing: billing(createCallable),
		customDomains: customDomains(createCallable),
		discountCodes: discountCodes(createCallable),
		generators: generators(createCallable),
		integrations: {
			solteq: integrations.solteq(createCallable),
		},
		internal: internal(createCallable),
		orders: {
			online: orders.online(createCallable),
			pending: orders.pending(createCallable),
			modifications: orders.modifications(createCallable),
			subscriptions: orders.subscriptions(createCallable),
		},
		payments: payments(createCallable),
		paymentAccounts: {
			stripe: paymentAccounts.stripe(createCallable),
		},
		plans: plans(createCallable),
		reports: reports(createCallable),
		skidata: skidata(createCallable),
		users: users(createCallable),
	} as const);
