import { useCallback, useMemo, useState } from 'react';

import { TFunction } from 'i18next';
import { useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as NavSelectors from 'redux/selectors/nav';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import { ProductVariant } from 'common/modules/inventory';
import { isRentalPurchaseType, isSubscriptionPurchaseType } from 'common/modules/orders';
import { getProductVariants } from 'common/modules/products/variants';
import {
	getProductSubscriptionDurationWithPrice,
	getSubscriptionOptionDuration,
} from 'common/modules/subscriptions';
import {
	DeliveryTimeSlot,
	Duration,
	ISOString,
	ProductApi,
	PurchaseType,
	PurchaseTypes,
} from 'common/types';
import { isSameDurationOption } from 'common/utils/duration';
import {
	getDurationFromDurationWithPriceOption,
	getRentalPrice,
	getSalesPrice,
	getSubscriptionPrice,
} from 'common/utils/pricing';
import { SegmentOption, getSegmentOptions } from 'common/utils/segments';
import { useTranslation } from 'services/localization';
import { ProductSelection, ProductSelectionWithProducts } from 'services/types';
import * as CartUtils from 'utils/cart';

import useDurationOptions, { DurationOptionWithId } from './useDurationOptions';
import useSubscriptionOptions, { SubscriptionOptionWithId } from './useSubscriptionOptions';

type ProductFormState = {
	purchaseType: PurchaseType;
	selection: ProductSelection | null;
	startDate: ISOString | null;
	durationId: string | null;
	subscriptionId: string | null;
	locationId: string;
	quantity: number;
	segment: string | null;
	deliveryTimeslot: DeliveryTimeSlot | null;
};

type ValidatedFormState = {
	selection: ProductSelection;
	locationId: string;
	quantity: number;
	segment: string | null;
	deliveryTimeslot: DeliveryTimeSlot | null;
} & (
	| {
			purchaseType: typeof PurchaseTypes.rental;
			startDate: ISOString;
			durationId: string;
	  }
	| {
			purchaseType: typeof PurchaseTypes.subscription;
			startDate: ISOString;
			subscriptionId: string;
	  }
	| {
			purchaseType: typeof PurchaseTypes.sales;
	  }
);

type ProductFormErrors = {
	selection?: string | null;
	startDate?: string | null;
	durationId?: string | null;
	subscriptionId?: string | null;
	locationId?: string | null;
	quantity?: string | null;
	segment?: string | null;
	deliveryTimeslot?: string | null;
};

export interface ProductForm {
	values: ProductFormState;
	errors: ProductFormErrors;
	actions: {
		setSelection: (value: ProductSelection | null) => void;
		setQuantity: (value: number) => void;
		setSegment: (value: string | null) => void;
		setDurationId: (value: string) => void;
		setSubscriptionId: (value: string) => void;
		setStartDate: (value: ISOString | null) => void;
		setLocationId: (value: string) => void;
		setDeliveryTimeslot: (value: DeliveryTimeSlot | null) => void;
		reset: () => void;
		validate: () => { valid: boolean; value?: ValidatedFormState; errors?: ProductFormErrors };
	};
	meta: {
		durationOptions: DurationOptionWithId[];
		subscriptionOptions: SubscriptionOptionWithId[];
		segmentOptions: SegmentOption[];
		selectedDuration: DurationOptionWithId | null;
		selectedSubscription: SubscriptionOptionWithId | null;
		selectedSubscriptionDuration: DurationOptionWithId | null;
		selectedVariant: ProductVariant | null;
		selectionWithProducts: ProductSelectionWithProducts | null;
		forcedStartDate: ISOString | null;
		forcedStartDateAndTime: ISOString | null;
		price: number | null;
	};
}

const validateStartDate = (
	startDate: ProductFormState['startDate'],
	t: TFunction,
): string | null => {
	if (!startDate) {
		return t('ProductForm.pleaseChooseStartTime', 'Please choose a start time');
	}

	if (CartUtils.isStartDateExpired(startDate)) {
		return t(
			'ProductForm.startTimeInPast',
			'The start time you have selected is in the past - please choose a new start time',
		);
	}

	return null;
};

const validateSegment = (
	product: ProductApi,
	segment: ProductFormState['segment'],
	hasSegmentsFeature: boolean,
	t: TFunction,
): string | null => {
	if (hasSegmentsFeature && product.segmentPricings?.length && !segment) {
		return t('ProductForm.pleaseChooseSegment', 'Please choose age group');
	}

	return null;
};

const validateDuration = (
	durationId: ProductFormState['durationId'],
	t: TFunction,
): string | null => {
	if (!durationId) {
		return t('ProductForm.pleaseChooseDuration', 'Please choose a duration');
	}

	return null;
};

const validateSubscription = (
	subscriptionId: ProductFormState['subscriptionId'],
	t: TFunction,
): string | null => {
	if (!subscriptionId) {
		return t('ProductForm.pleaseChooseSubscription', 'Please choose a subscription');
	}

	return null;
};

const validateSelection = (
	selection: ProductFormState['selection'],
	t: TFunction,
): string | null => {
	if (!selection) {
		return t('ProductForm.pleaseCompleteSelection', 'Please complete your selection');
	}

	return null;
};

const validateDeliveryTimeslot = (
	deliveryTimeslot: ProductFormState['deliveryTimeslot'],
	t: TFunction,
): string | null => {
	if (!deliveryTimeslot) {
		return t('ProductForm.pleaseChooseDeliveryTimeslot', 'Please choose a delivery timeslot');
	}

	return null;
};

const findMatchingDurationOption = (duration: Duration | null, options: DurationOptionWithId[]) => {
	if (options.length === 1 && options[0].option.timePeriod === 'fixed') {
		return options[0];
	}
	if (!duration) return null;
	return (
		options.find((o) =>
			isSameDurationOption(getDurationFromDurationWithPriceOption(o.option), duration),
		) ?? null
	);
};

const findMatchingSubscriptionOption = (
	duration: Duration | null,
	startDate: ISOString | null,
	options: SubscriptionOptionWithId[],
) => {
	if (!duration || !startDate) return null;
	return (
		options.find((o) =>
			isSameDurationOption(getSubscriptionOptionDuration(o.option, startDate), duration),
		) ?? null
	);
};

const useProductForm = (args: {
	productId: string;
	purchaseType: PurchaseType;
	locationId: string;
}): ProductForm => {
	const { locationId, productId, purchaseType } = args;
	const { t, i18n } = useTranslation();

	const locationName = locationId;

	const productsById = useSelector(StockSelectors.productsById);
	const product = productsById[productId];
	const durationFromCart = useSelector(CartSelectors.shortestCartRentalDuration);
	const delivery = useSelector(CartSelectors.delivery);
	const hasDelivery = useSelector(ViewSelectors.deliverySelected);
	const hasSegments =
		useSelector(ShopSelectors.hasSegmentsFeature) && purchaseType === PurchaseTypes.rental;
	const shopName = useSelector(NavSelectors.shopName);

	const forcedStartDate = useSelector(CartSelectors.forcedStartDate);
	const forcedStartDateAndTime = useSelector(CartSelectors.forcedStartDateAndTime);

	const segmentOptions = hasSegments
		? getSegmentOptions(product.segmentPricings ?? [], shopName, locationName, i18n)
		: [];
	const deliveryTimeslotFromCart = delivery?.to?.timeslot ?? null;

	const { getDurationOptions } = useDurationOptions(productId);
	const { getSubscriptionOptions } = useSubscriptionOptions(productId);

	const initialFormState = useMemo((): ProductFormState => {
		const getInitialDurationId = () => {
			const durationOptions = getDurationOptions();
			const matchingDurationId = findMatchingDurationOption(durationFromCart, durationOptions)?.id;
			return matchingDurationId ?? (durationOptions.length === 1 ? durationOptions[0].id : null);
		};

		const getInitialSubscriptionId = () => {
			const subscriptionOptions = getSubscriptionOptions();
			const matchingSubscriptionId = findMatchingSubscriptionOption(
				durationFromCart,
				forcedStartDateAndTime,
				subscriptionOptions,
			)?.id;

			return matchingSubscriptionId ?? subscriptionOptions.length === 1
				? subscriptionOptions[0].id
				: null;
		};

		return {
			purchaseType,
			selection: null,
			startDate: forcedStartDateAndTime ?? null,
			durationId: getInitialDurationId(),
			subscriptionId: getInitialSubscriptionId(),
			locationId,
			quantity: 1,
			segment: null,
			deliveryTimeslot: deliveryTimeslotFromCart,
		};
	}, [
		purchaseType,
		forcedStartDateAndTime,
		locationId,
		deliveryTimeslotFromCart,
		getDurationOptions,
		durationFromCart,
		getSubscriptionOptions,
	]);

	const [formState, setFormState] = useState<ProductFormState>(initialFormState);
	const [formErrors, setFormErrors] = useState<ProductFormErrors>({});

	const durationOptions = useMemo(() => {
		if (purchaseType !== PurchaseTypes.rental) return [];
		return getDurationOptions(formState.segment);
	}, [getDurationOptions, purchaseType, formState.segment]);

	const selectedDuration = useMemo(() => {
		return durationOptions.find((o) => o.id === formState.durationId) ?? null;
	}, [durationOptions, formState.durationId]);

	const subscriptionOptions = useMemo(() => {
		if (purchaseType !== PurchaseTypes.subscription) return [];
		return getSubscriptionOptions();
	}, [getSubscriptionOptions, purchaseType]);

	const selectedSubscription = useMemo(() => {
		return subscriptionOptions.find((o) => o.id === formState.subscriptionId) ?? null;
	}, [formState.subscriptionId, subscriptionOptions]);

	const selectedSubscriptionDuration = useMemo(() => {
		if (!selectedSubscription) {
			return null;
		}
		return {
			id: selectedSubscription.id,
			option: getProductSubscriptionDurationWithPrice(selectedSubscription.option),
		};
	}, [selectedSubscription]);

	const selectionWithProducts: ProductSelectionWithProducts | null = useMemo(() => {
		if (!formState.selection) return null;
		return CartUtils.getSelectionWithProducts(formState.selection, productsById);
	}, [formState.selection, productsById]);

	const selectedVariant = useMemo(() => {
		const variantId = formState.selection?.variantId;
		return getProductVariants(product)?.find((v) => v.id === variantId) ?? null;
	}, [product, formState.selection]);

	const price = useMemo(() => {
		switch (purchaseType) {
			case PurchaseTypes.sales:
				return getSalesPrice(product, selectedVariant);
			case PurchaseTypes.rental:
				return getRentalPrice(selectedDuration?.option.price, selectedVariant);
			case PurchaseTypes.subscription:
				return getSubscriptionPrice(selectedSubscription?.option);
			default:
				return null;
		}
	}, [
		product,
		purchaseType,
		selectedDuration?.option.price,
		selectedSubscription?.option,
		selectedVariant,
	]);

	const setSelection = useCallback(
		(selection: ProductSelection | null) => {
			setFormErrors((prev) => {
				if (prev.selection) {
					return {
						...prev,
						selection: validateSelection(selection, t),
					};
				}
				return prev;
			});
			setFormState((prev) => ({
				...prev,
				selection,
			}));
		},
		[t],
	);

	const setQuantity = useCallback((quantity: number) => {
		setFormState((prev) => ({
			...prev,
			quantity,
		}));
	}, []);

	const setSegment = useCallback(
		(segment: string | null) => {
			setFormErrors((prev) =>
				prev.segment
					? { ...prev, segment: validateSegment(product, segment, hasSegments, t) }
					: prev,
			);
			const newDurationOptions = getDurationOptions(segment);
			const matchingSelectedDuration = selectedDuration?.option
				? findMatchingDurationOption(
						getDurationFromDurationWithPriceOption(selectedDuration.option),
						newDurationOptions,
				  )
				: null;
			const matchingDuration =
				matchingSelectedDuration ??
				findMatchingDurationOption(durationFromCart, newDurationOptions);
			setFormState((prev) => ({ ...prev, segment, durationId: matchingDuration?.id ?? null }));
		},
		[t, getDurationOptions, selectedDuration, durationFromCart, product, hasSegments],
	);

	const setDurationId = useCallback(
		(durationId: string) => {
			setFormErrors((prev) => {
				if (prev.durationId) {
					return {
						...prev,
						durationId: validateDuration(durationId, t),
					};
				}

				return prev;
			});
			setFormState((prev) => ({
				...prev,
				durationId,
			}));
		},
		[t],
	);

	const setSubscriptionId = useCallback(
		(subscriptionId: string) => {
			setFormErrors((prev) => {
				if (prev.subscriptionId) {
					return {
						...prev,
						subscriptionId: validateSubscription(subscriptionId, t),
					};
				}

				return prev;
			});
			setFormState((prev) => ({
				...prev,
				subscriptionId,
			}));
		},
		[t],
	);

	const setLocationId = useCallback((locationId: string) => {
		setFormState((prev) => ({
			...prev,
			locationId,
		}));
	}, []);

	const setStartDate = useCallback(
		(startDate: ISOString | null) => {
			setFormErrors((prev) => {
				if (prev.startDate) {
					return {
						...prev,
						startDate: validateStartDate(startDate, t),
					};
				}
				return prev;
			});

			setFormState((prev) => ({
				...prev,
				startDate,
			}));
		},
		[t],
	);

	const setDeliveryTimeslot = useCallback(
		(deliveryTimeslot: DeliveryTimeSlot | null) => {
			if (!!deliveryTimeslot) {
				setStartDate(deliveryTimeslot.endDate);
			}
			setFormErrors((prev) => {
				if (prev.deliveryTimeslot) {
					return {
						...prev,
						deliveryTimeslot: validateDeliveryTimeslot(deliveryTimeslot, t),
					};
				}
				return prev;
			});

			setFormState((prev) => ({
				...prev,
				deliveryTimeslot,
			}));
		},
		[t, setStartDate],
	);

	const validate = useCallback(() => {
		const selectionError = validateSelection(formState.selection, t);
		if (selectionError) {
			const errors = {
				selection: selectionError,
			};
			setFormErrors(errors);
			return {
				valid: false,
				errors,
			};
		}

		const segmentError = validateSegment(product, formState.segment, hasSegments, t);
		if (segmentError) {
			const errors = { segment: segmentError };
			setFormErrors(errors);
			return { valid: false, errors };
		}

		const startDateError = isRentalPurchaseType(purchaseType)
			? validateStartDate(formState.startDate, t)
			: null;
		if (startDateError) {
			const errors = {
				startDate: startDateError,
			};
			setFormErrors(errors);
			return {
				valid: false,
				errors,
			};
		}

		const durationError = isRentalPurchaseType(purchaseType)
			? validateDuration(formState.durationId, t)
			: null;
		if (durationError) {
			const errors = {
				durationId: durationError,
			};
			setFormErrors(errors);
			return {
				valid: false,
				errors,
			};
		}

		const subscriptionError = isSubscriptionPurchaseType(purchaseType)
			? validateSubscription(formState.subscriptionId, t)
			: null;
		if (subscriptionError) {
			const errors = {
				subscriptionId: subscriptionError,
			};
			setFormErrors(errors);
			return {
				valid: false,
				errors,
			};
		}

		const deliveryTimeslotError = validateDeliveryTimeslot(formState.deliveryTimeslot, t);
		if (hasDelivery && deliveryTimeslotError) {
			const errors = {
				deliveryTimeslot: deliveryTimeslotError,
			};
			setFormErrors(errors);
			return {
				valid: false,
				errors,
			};
		}

		return {
			valid: true,
			value: formState as ValidatedFormState,
		};
	}, [formState, hasDelivery, hasSegments, product, purchaseType, t]);

	const reset = useCallback(() => {
		setFormState(initialFormState);
		setFormErrors({});
	}, [initialFormState]);

	return {
		values: formState,
		errors: formErrors,
		actions: {
			setSelection,
			setQuantity,
			setSegment,
			setDurationId,
			setSubscriptionId,
			setLocationId,
			setStartDate,
			setDeliveryTimeslot,
			reset,
			validate,
		},
		meta: {
			durationOptions,
			subscriptionOptions,
			segmentOptions,
			selectedDuration,
			selectedSubscription,
			selectedSubscriptionDuration,
			selectedVariant,
			selectionWithProducts,
			forcedStartDate,
			forcedStartDateAndTime,
			price,
		},
	};
};

export default useProductForm;
