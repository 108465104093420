import { useCallback } from 'react';

import { useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import * as ViewSelectors from 'redux/selectors/view';

import {
	deliveryOptionRequiresPickup,
	isDurationMatchingWithCartDuration,
	isDurationSupportedByDeliveryOption,
} from 'common/modules/delivery/utils';
import { DurationWithPrice } from 'common/types';
import { getDurationOptionsForStockProducts } from 'common/utils/pricing/common';

export interface DurationOptionWithId {
	id: string;
	option: DurationWithPrice;
}

export interface UseDurationOptions {
	getDurationOptions: (segment?: string | null) => DurationOptionWithId[];
}

const useDurationOptions = (productId: string): UseDurationOptions => {
	const productsById = useSelector(StockSelectors.productsById);
	const pricingTables = useSelector(ShopSelectors.shopPricingTables);
	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const timezone = useSelector(ShopSelectors.shopTimezone);
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const shortestCartRentalDuration = useSelector(CartSelectors.shortestCartRentalDuration);

	const getDurationOptions = useCallback(
		(segment?: string | null) => {
			const products = [productId].map((id) => productsById[id]).filter((p) => !!p);

			const options = getDurationOptionsForStockProducts({
				stockProducts: products,
				savedPricingTables: pricingTables,
				openingHours,
				startDate: null,
				channel: 'ONLINE',
				timezone,
				segment: segment ?? undefined,
			});

			const filteredOptions = options.filter((option) => {
				if (!!selectedDeliveryOption) {
					if (!isDurationSupportedByDeliveryOption(option, selectedDeliveryOption)) return false;

					if (
						deliveryOptionRequiresPickup(selectedDeliveryOption) &&
						!isDurationMatchingWithCartDuration(option, shortestCartRentalDuration)
					) {
						return false;
					}
				}

				return true;
			});

			return filteredOptions.map((option) => {
				return {
					id: `${option.timePeriod}_${option.timeValue}_${option.price}_${
						option.label ?? 'default'
					}`,
					option,
				};
			});
		},
		[
			openingHours,
			pricingTables,
			productId,
			productsById,
			selectedDeliveryOption,
			shortestCartRentalDuration,
			timezone,
		],
	);

	return { getDurationOptions };
};

export default useDurationOptions;
