import { minBy } from 'lodash';

import { sleep } from 'common/utils/async';

interface UseScrollToErrorOpts {
	rootId?: string;
	scrollOffset?: number;
	onScroll?: (offset: number) => void;
	scrollType?: 'window' | 'scrollIntoView';
	delayMs?: number;
}

const useScrollToError = (opts: UseScrollToErrorOpts) => {
	const getErrorElements = (): HTMLElement[] => {
		const container =
			(opts.rootId ? document.querySelector(`#${opts.rootId}`) : null) ?? document.body;
		const elements = container.querySelectorAll("[data-error='true']");
		return Array.from(elements, (el) => el as HTMLElement);
	};

	const scrollToFirstError = async () => {
		await sleep(1 + (opts.delayMs ?? 0));
		const errorElements = getErrorElements();
		const firstErrorElement = minBy(errorElements, (el) => el.offsetTop);
		const firstErrorOffset = firstErrorElement?.offsetTop ?? 0;
		const offset = firstErrorOffset + (opts.scrollOffset ?? 0);

		opts.onScroll?.(offset);

		switch (opts.scrollType) {
			case 'scrollIntoView':
				if (!!firstErrorElement) {
					firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
				}
				break;

			case 'window':
			default:
				window.scrollTo({ left: 0, top: offset, behavior: 'smooth' });
				break;
		}
	};

	return {
		scrollToFirstError,
	};
};

export default useScrollToError;
