import React, { useCallback, useEffect } from 'react';

import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import {
	setAdditionalPaymentAction,
	setHandlePaymentRedirect,
	setPaymentError,
	setPaymentInProgress,
	setPaymentLoading,
	setTransactionId,
} from 'redux/actions/checkout';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as ShopSelectors from 'redux/selectors/shop';

import { getFunctionsBaseUrl } from 'common/api/configs';
import {
	AdditionalDetailsUpdate,
	ConfirmAction,
} from 'common/components/payment/PaymentMethodSelector';
import { Callable } from 'common/frontend/callable';
import { useAdyen } from 'common/hooks/useAdyen';
import { getPaymentMethodId } from 'common/modules/payments/paymentMethods';
import {
	FixedManualPaymentMethod,
	OnlinePaymentMethod,
	ShopCustomOnlinePaymentMethodObject,
} from 'common/modules/payments/types';
import errorHandler from 'common/services/errorHandling/errorHandler';
import {
	AdditionalDetailRedirectRequest,
	AdditionalDetailRequest,
	AdditionalPaymentAction,
	Environment,
	OnlineOrderRequest,
	OrderObject,
	StripeAdditionalDetailRequest,
} from 'common/types';
import { switchUnreachable } from 'common/utils/common';
import { StorageKeys, Storages, getFromStorage } from 'common/utils/frontUtils';
import { newFirestoreId } from 'common/utils/newRentalUtils';
import { useTranslation } from 'services/localization';

import { getRedirectUrl } from '../../utils';
import PaymentSection from './PaymentSection';
import { AdyenPaymentResult, adyenPayment, adyenPaymentWithAdditionalDetails } from './utils/adyen';
import {
	StripePaymentResult,
	stripePayment,
	stripePaymentWithAdditionalDetails,
} from './utils/stripe';

interface Props {
	onOrderConfirmed: (order: OrderObject) => void;
}

const functionsBaseUrl = getFunctionsBaseUrl(process.env.REACT_APP_ENV as Environment);

const Payment = ({ onOrderConfirmed }: Props) => {
	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();
	const { t, language } = useTranslation();
	const transactionId = useSelector(CheckoutSelectors.transactionId);
	const orderId = useSelector(CheckoutSelectors.orderId);
	const adyen = useAdyen();

	const shopStripeAccountId = useSelector(ShopSelectors.shopStripeAccountId);
	const onlineOrderRequest = useSelector(CheckoutSelectors.buildOnlineOrderRequest);
	const handlePaymentRedirect = useSelector(CheckoutSelectors.handlePaymentRedirect);

	const handleResult = useCallback(
		(
			data:
				| { provider: 'ADYEN'; result: AdyenPaymentResult }
				| { provider: 'STRIPE'; result: StripePaymentResult },
		) => {
			const { result } = data;
			// If we need to do additional payment actions
			if (data.result.success && !!data.result.action) {
				const additionalPaymentAction: AdditionalPaymentAction =
					data.provider === 'STRIPE'
						? {
								provider: 'STRIPE',
								action: data.result.action,
								clientSecret: data.result.intent?.clientSecret ?? null,
						  }
						: { provider: 'ADYEN', action: data.result.action };
				dispatch(setAdditionalPaymentAction(additionalPaymentAction));
				return;
			}
			dispatch(setPaymentInProgress(false));
			dispatch(setAdditionalPaymentAction(undefined));
			if (result.success) {
				onOrderConfirmed(result.order ?? onlineOrderRequest.order);
				return;
			}
			dispatch(setTransactionId(newFirestoreId()));
			dispatch(setPaymentError(result.error));
		},
		[dispatch, onOrderConfirmed, onlineOrderRequest.order],
	);

	useEffect(() => {
		const handlePaymentRedirectTrigger = async (transactionId: string, orderId: string) => {
			dispatch(setPaymentLoading(true));
			const data: AdditionalDetailRedirectRequest = {
				redirect: true,
				returnUrl: `${functionsBaseUrl}/api/v1/transaction-redirect/${transactionId}?redirectUrl=${encodeURIComponent(
					getRedirectUrl({ orderId, transactionId }),
				)}`,
				origin: window.location.origin,
				transactionId,
				orderId,
				userLanguage: language,
			};
			const result = await adyenPaymentWithAdditionalDetails({
				data,
				t,
			});
			dispatch(setPaymentLoading(false));
			handleResult({ provider: 'ADYEN', result });
		};

		if (handlePaymentRedirect) {
			dispatch(setHandlePaymentRedirect(false));
			dispatch(setPaymentInProgress(true));
			handlePaymentRedirectTrigger(transactionId, orderId);
		}
		//eslint-disable-next-line
	}, []); // This should always run only once

	const handleAdditionalDetailsUpdate = useCallback(
		async (additionalDetailsData: AdditionalDetailsUpdate) => {
			const sessionPaymentMethod = getFromStorage(
				Storages.SESSION,
				StorageKeys.PAYMENT_METHOD,
			) as OnlinePaymentMethod | null;
			dispatch(setPaymentLoading(true));
			if (additionalDetailsData.provider === 'ADYEN') {
				const data: AdditionalDetailRequest = {
					additionalDetails: additionalDetailsData.data,
					transactionId,
					orderId,
					returnUrl: `${functionsBaseUrl}/api/v1/transaction-redirect/${transactionId}?redirectUrl=${encodeURIComponent(
						getRedirectUrl({ orderId, transactionId }),
					)}`,
					origin: window.location.origin,
					userLanguage: language,
				};
				const adyenResult = await adyenPaymentWithAdditionalDetails({
					data,
					t,
				});
				dispatch(setPaymentLoading(false));
				handleResult({ provider: 'ADYEN', result: adyenResult });
				return;
			} else if (additionalDetailsData.provider === 'STRIPE') {
				if (
					!additionalDetailsData.data ||
					additionalDetailsData.data.error ||
					!additionalDetailsData.data.paymentIntent ||
					!shopStripeAccountId.data
				) {
					dispatch(setPaymentLoading(false));
					handleResult({
						provider: 'STRIPE',
						result: {
							success: false,
							error: {
								methodId: sessionPaymentMethod,
								error: t('common:errors.paymentError', 'Error processing payment'),
							},
						},
					});
					return;
				}
				const data: StripeAdditionalDetailRequest = {
					intentId: additionalDetailsData.data.paymentIntent.id,
					stripeAccountId: shopStripeAccountId.data,
					transactionId,
					orderId,
					returnUrl: `${functionsBaseUrl}/api/v1/transaction-redirect/${transactionId}?redirectUrl=${encodeURIComponent(
						getRedirectUrl({ orderId, transactionId }),
					)}`,
					origin: window.location.origin,
					userLanguage: language,
				};
				const stripeResult = await stripePaymentWithAdditionalDetails({
					data,
					t,
				});
				dispatch(setPaymentLoading(false));
				handleResult({ provider: 'STRIPE', result: stripeResult });
			}
		},
		[dispatch, handleResult, language, orderId, shopStripeAccountId.data, t, transactionId],
	);

	const handleConfirmAction = async (action: ConfirmAction) => {
		dispatch(setPaymentError(undefined));
		switch (action.type) {
			case 'ADYEN_PAYMENT':
				// We need to first submit adyen payment and then set payment loading
				// Fixes possible issues with wallet payments failures not leading to in progress-state
				const paymentResult = await adyen.submit(action.paymentMethodName);
				dispatch(setPaymentLoading(true));
				dispatch(setPaymentInProgress(true));
				const adyenResult = await adyenPayment({
					paymentMethodData: paymentResult.data.paymentMethod,
					paymentMethodName: action.paymentMethodName,
					orderId,
					transactionId,
					userLanguage: language,
					onlineOrderRequest,
					t,
				});
				handleResult({ provider: 'ADYEN', result: adyenResult });
				break;
			case 'STRIPE_PAYMENT':
				if (!stripe || !elements) {
					dispatch(
						setPaymentError({
							methodId: 'CARD_ONLINE_STRIPE',
							error: t('common:errors.somethingWentWrong'),
						}),
					);
					errorHandler.report('Stripe not loaded');
					break;
				}
				const cardNumberElement = elements.getElement(CardNumberElement);
				if (!cardNumberElement) {
					dispatch(
						setPaymentError({
							methodId: 'CARD_ONLINE_STRIPE',
							error: t('common:errors.somethingWentWrong'),
						}),
					);
					errorHandler.report('Cannot find CardNumberElement');
					break;
				}

				dispatch(setPaymentLoading(true));
				const result = await stripe.createPaymentMethod({
					type: 'card',
					card: cardNumberElement,
				});
				if (result.error || !result.paymentMethod) {
					dispatch(setTransactionId(newFirestoreId()));
					dispatch(
						setPaymentError({
							methodId: 'CARD_ONLINE_STRIPE',
							error: result.error?.message ?? t('common:errors.somethingWentWrong'),
						}),
					);
					break;
				}
				dispatch(setPaymentInProgress(true));
				const stripeResult = await stripePayment({
					paymentMethodId: result.paymentMethod.id,
					paymentMethodName: action.paymentMethodName,
					order: onlineOrderRequest.order,
					shopId: onlineOrderRequest.shopId,
					transactionId,
					userLanguage: language,
					t,
				});
				handleResult({ provider: 'STRIPE', result: stripeResult });
				break;
			case 'MANUAL': {
				dispatch(setPaymentLoading(true));
				await confirmRental(onlineOrderRequest, action.paymentMethod);
				break;
			}
			default:
				switchUnreachable(action);
		}
		dispatch(setPaymentLoading(false));
	};

	const confirmRental = async (
		onlineOrderRequest: OnlineOrderRequest,
		paymentMethod: ShopCustomOnlinePaymentMethodObject | FixedManualPaymentMethod,
	) => {
		const method = paymentMethod;
		try {
			const result = await Callable.orders.online.create({
				order: onlineOrderRequest.order,
				shopId: onlineOrderRequest.shopId,
				clientPaymentData: {
					provider: 'MANUAL',
					method,
				},
				userLanguage: language,
			});

			if (result.status === 'SUCCESS') {
				onOrderConfirmed(result.order);
			} else {
				dispatch(
					setPaymentError({
						methodId: typeof method === 'string' ? method : getPaymentMethodId(method),
						error: t('confirmation.confirmationError', 'Confirmation failed. Please try again.'),
					}),
				);
			}
		} catch (e) {
			errorHandler.report(e);
			dispatch(
				setPaymentError({
					methodId: typeof method === 'string' ? method : getPaymentMethodId(method),
					error: t('confirmation.confirmationError', 'Confirmation failed. Please try again.'),
				}),
			);
		}
	};

	return (
		<PaymentSection
			handleConfirmAction={handleConfirmAction}
			handleAdditionalDetailsUpdate={handleAdditionalDetailsUpdate}
		/>
	);
};

export default Payment;
