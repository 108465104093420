import { EcomEvent, EcomEventName, EcomEvents, Item } from './types';

export const mapToUaEvent = <T extends EcomEventName>(
	event: EcomEvent<T>,
): { name: string; params?: object } | null => {
	const { name } = event;
	switch (name) {
		case 'view_item_list': {
			const params: EcomEvents['view_item_list'] | undefined = event.params;
			return {
				name: 'view_item_list',
				params: {
					items: params?.items?.map(ga4ItemToUaItem),
				},
			};
		}
		case 'select_item': {
			const params: EcomEvents['select_item'] | undefined = event.params;
			return {
				name: 'select_content',
				params: {
					content_type: 'product',
					items: params?.items?.map(ga4ItemToUaItem),
				},
			};
		}
		case 'view_item': {
			const params: EcomEvents['view_item'] | undefined = event.params;
			return {
				name: 'view_item',
				params: {
					items: params?.items?.map(ga4ItemToUaItem),
				},
			};
		}
		case 'add_to_cart': {
			const params: EcomEvents['add_to_cart'] | undefined = event.params;
			return {
				name: 'add_to_cart',
				params: {
					items: event.params?.items?.map(ga4ItemToUaItem),
					currency: params?.currency,
					value: params?.value,
				},
			};
		}
		case 'remove_from_cart': {
			const params: EcomEvents['remove_from_cart'] | undefined = event.params;
			return {
				name: 'remove_from_cart',
				params: {
					items: params?.items?.map(ga4ItemToUaItem),
					currency: params?.currency,
					value: params?.value,
				},
			};
		}
		case 'begin_checkout': {
			const params: EcomEvents['begin_checkout'] | undefined = event.params;
			return {
				name: 'begin_checkout',
				params: {
					items: params?.items?.map(ga4ItemToUaItem),
					currency: params?.currency,
					value: params?.value,
					coupon: params?.coupon,
				},
			};
		}
		case 'add_payment_info': {
			const params: EcomEvents['add_payment_info'] | undefined = event.params;
			return {
				name: 'checkout_progress',
				params: {
					value: params?.value,
					currency: params?.currency,
					items: params?.items?.map(ga4ItemToUaItem),
					checkout_option: params?.payment_type,
					coupon: params?.coupon,
				},
			};
		}
		case 'purchase': {
			const params: EcomEvents['purchase'] | undefined = event.params as
				| EcomEvents['purchase']
				| undefined;
			return {
				name: 'purchase',
				params: {
					affiliation: params?.affiliation,
					value: params?.value,
					currency: params?.currency,
					items: params?.items?.map(ga4ItemToUaItem),
					coupon: params?.coupon,
					tax: params?.tax,
					transaction_id: params?.transaction_id,
				},
			};
		}
		case 'add_to_wishlist':
		case 'add_shipping_info':
		case 'view_cart':
		case 'refund':
		case 'select_promotion':
		case 'view_promotion':
		default:
			return null;
	}
};

interface UaItem {
	id: string;
	name: string;
	brand?: string;
	category?: string;
	variant?: string;
	price?: number;
	quantity?: number;
	list_position?: number;
	coupon?: string;
	list_name?: string;
}

const ga4ItemToUaItem = (item: Item): UaItem => {
	return {
		id: item.item_id,
		name: item.item_name,
		brand: item.item_brand,
		category: item.item_category,
		variant: item.item_variant,
		price: item.price,
		quantity: item.quantity,
		list_position: item.index,
		coupon: item.coupon,
		list_name: item.item_list_name,
	};
};
