import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';

import { useRoutes } from 'services/routing/useRoutes';

interface Props {
	size?: number;
	isMobile?: boolean;
}

const NavLogo = (props: Props) => {
	const classes = useStyles(props);
	const { getPath, Routes } = useRoutes();

	const shopInfo = useSelector(ShopSelectors.shopPublicInfoData);
	const shopLogo = useSelector(ShopSelectors.shopLogo);

	return (
		<Link to={getPath(Routes.browse)}>
			{shopLogo ? (
				<Box className={classes.logoWrapper}>
					<img className={classes.logo} src={shopLogo} alt="Logo" />
				</Box>
			) : (
				<Typography variant="h6" className={classes.textLogo}>
					{shopInfo?.name}
				</Typography>
			)}
		</Link>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		logoWrapper: {
			display: 'flex',
			justifyContent: 'center',
			maxWidth: 300,
			[theme.breakpoints.up('md')]: {
				width: ({ size }: Props) => size,
				height: 'auto',
			},
			[theme.breakpoints.down('md')]: {
				height: 70,
				width: 'auto',
			},
			[theme.breakpoints.down('sm')]: {
				height: 40,
				width: 'auto',
			},
		},
		logo: {
			maxWidth: '100%',
			height: '100%',
			objectFit: 'contain',
			objectPosition: 'center center',
		},
		textLogo: {
			textAlign: ({ isMobile }: Props) => (isMobile ? 'center' : undefined),
			maxWidth: '100%',
		},
	}),
);

export default NavLogo;
