import { SelectChangeEvent } from '@mui/material';
import { omit } from 'lodash';
import moment from 'moment-timezone';

import { OrderInfo, OrderProduct, Shopper } from 'common/types';

export const updateShopperProperty = (
	e: SelectChangeEvent | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
	shopper: Shopper,
	propertyName: string,
) => {
	const { value } = e.target;

	if (propertyName === 'segment' && value === 'regular') return omit(shopper, ['segment']);

	const updatedShopper = {
		...shopper,
		[propertyName]: value,
	};
	return updatedShopper;
};

export const getShopperName = (shopper: Shopper) => {
	return `${shopper.firstName} ${shopper.lastName}`;
};

export const getShoppersWithoutProducts = (shoppers: Shopper[], products: OrderProduct[]) =>
	shoppers
		.filter((shopper) => !products.some((p) => shopper.productIds.includes(p.id)))
		.map((shopper) => shopper.firstName);

export const removeShopperFromRentalInfo = (rentalInfo: OrderInfo, shopperId: string) => ({
	...rentalInfo,
	shopperIdsWithProducts: {
		...omit(rentalInfo.shopperIdsWithProducts, [shopperId]),
	},
});

export const removeShopperIdFromProducts = (
	shopperId: string,
	products: OrderProduct[],
	responsiblePersonId: string,
) =>
	products.map((p) => {
		if (p.shopperId === shopperId) {
			p.shopperId = responsiblePersonId;
		}
		return p;
	});

export const clearProductsFromShopper = (shopper: Shopper): Shopper => ({
	...shopper,
	productIds: [],
});

export const onlyUnique = (value: string, index: number, self: any[]) =>
	self.indexOf(value) === index;

export const lengthIsGreaterOrEqual = (arr1: any[], arr2: any[]) => arr1.length >= arr2.length;

export const sortShoppersByCreationDate = (a: Shopper, b: Shopper) => {
	const dateA = moment(a.created).valueOf();
	const dateB = moment(b.created).valueOf();
	return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
};

export const indexOfLastShopperWithProducts = (shoppers: Shopper[]) =>
	shoppers.length - 1 - [...shoppers].reverse().findIndex((s) => s.productIds.length > 0);
