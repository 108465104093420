import React from 'react';

import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import { TFunction } from 'i18next';
import { Link as RRLink } from 'react-router-dom';

import { Footer as FooterType } from 'common/modules/customization';
import { getOnlineProviderPaymentMethods } from 'common/modules/payments/paymentMethods';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import { getTranslation } from 'common/modules/translations';
import { CookieScriptService } from 'common/services/cookieConsent';
import { Languages } from 'common/types';
import { isRunInIframe } from 'common/utils/browserUtils';
import { notNull } from 'common/utils/common';

import PoweredByRentle from '../PoweredByRentle';
import PaymentMethodIcons from '../payment/PaymentMethodIcons';

export interface Props {
	footerInfo?: FooterType | null;
	routes?: { home: string; about: string; stores: string };
	paymentMethods: ShopOnlinePaymentMethodObject[] | undefined;
	shopInfoString: string | null;
	isLiteShop?: boolean;
	fullShopInfo?: {
		show: true;
		locationId: string;
	};
	t: TFunction;
	language: Languages;
	showManageCookies?: boolean;
}

declare var CookieScript: CookieScriptService | undefined;

const Footer = (props: Props) => {
	const {
		footerInfo,
		routes,
		paymentMethods,
		shopInfoString,
		isLiteShop,
		fullShopInfo,
		t,
		language,
		showManageCookies,
	} = props;

	const isEmbedded = isRunInIframe();

	const disableLinks = !routes;

	const onlineProviderPaymentMethods = getOnlineProviderPaymentMethods();

	const onlinePaymentMethods = paymentMethods?.filter((method) =>
		onlineProviderPaymentMethods.some((m) => m === method.id),
	);

	const hasPaymentMethodsEnabled = !!onlinePaymentMethods?.length;

	const hasCardOnlineAndStripeEnabled =
		!!onlinePaymentMethods?.find((method) => method.id === 'CARD_ONLINE') &&
		onlinePaymentMethods?.find((method) => method.id === 'CARD_ONLINE_STRIPE');

	const filteredPaymentMethods = hasCardOnlineAndStripeEnabled
		? onlinePaymentMethods?.filter((method) => method.id !== 'CARD_ONLINE_STRIPE')
		: onlinePaymentMethods;

	const fullYear = new Date().getFullYear();

	const getShopInfoWithPoweredBy = () => {
		const fullInfo = fullShopInfo;
		const showPoweredBy = isLiteShop;
		return (
			<>
				<Box display="inline-block">
					<Typography variant="body2" component="span" color="secondary">
						&copy; {fullYear} {shopInfoString}
						{showPoweredBy && !fullInfo && (
							<>
								,{' '}
								<Box component="span" display="inline-block">
									<PoweredByRentle />
								</Box>
							</>
						)}
					</Typography>
				</Box>
				{showPoweredBy && fullInfo && (
					<Box pt={0.5}>
						<PoweredByRentle />
					</Box>
				)}
			</>
		);
	};

	const renderAbout = () => {
		if (
			(!footerInfo?.about?.heading && !footerInfo?.about?.description) ||
			footerInfo?.about?.hidden
		)
			return null;

		return (
			<Stack sx={{ maxWidth: '50%' }} spacing={1}>
				<Typography variant="body2">
					{getTranslation(footerInfo?.about?.heading, language)}
				</Typography>
				<Typography sx={{ fontWeight: 400 }} variant="body2" color="secondary">
					{getTranslation(footerInfo?.about?.description, language)}
				</Typography>
			</Stack>
		);
	};

	const renderMenu = () => {
		if ((!footerInfo?.menu?.heading && !footerInfo?.menu?.toggleMenu) || footerInfo?.menu?.hidden)
			return null;

		const displayMenuItems = footerInfo.menu.toggleMenu;

		return (
			<Stack spacing={1}>
				<Typography variant="body2">
					{getTranslation(footerInfo?.menu?.heading, language)}
				</Typography>
				{displayMenuItems && (
					<Stack spacing={0.25} sx={{ pointerEvents: disableLinks ? 'none' : undefined }}>
						<RRLink to={routes?.home ?? '#'}>
							<Typography variant="body2" color="secondary" sx={{ fontWeight: 400 }}>
								{t('common:actions.home', 'Home')}
							</Typography>{' '}
						</RRLink>
						<RRLink to={routes?.about ?? '#'}>
							<Typography variant="body2" color="secondary" sx={{ fontWeight: 400 }}>
								{t('common:actions.about', 'About')}
							</Typography>
						</RRLink>
						<RRLink to={routes?.stores ?? '#'}>
							<Typography variant="body2" color="secondary" sx={{ fontWeight: 400 }}>
								{t('common:shopLocation.stores', 'Stores')}
							</Typography>
						</RRLink>
					</Stack>
				)}
			</Stack>
		);
	};

	const renderLinks = () => {
		if (
			(!footerInfo?.links?.heading && !footerInfo?.links?.linkObjects?.length) ||
			footerInfo?.links?.hidden
		)
			return null;

		return (
			<Stack spacing={1}>
				<Typography variant="body2">
					{getTranslation(footerInfo?.links?.heading, language)}
				</Typography>
				<Stack
					sx={{ pointerEvents: disableLinks ? 'none' : undefined }}
					spacing={1}
					direction="row"
				>
					<Stack spacing={0.25}>
						{footerInfo?.links?.linkObjects?.map(({ label, url }) => (
							<Link
								key={`${url}_${label?.def}`}
								target="_blank"
								rel="noopener noreferrer"
								href={url}
								color="secondary"
								sx={{ textDecoration: 'none' }}
							>
								<Typography variant="body2" color="secondary" sx={{ fontWeight: 400 }}>
									{getTranslation(label, language)}
								</Typography>
							</Link>
						))}
					</Stack>
				</Stack>
			</Stack>
		);
	};

	const amountOfFooterElements = Object.values([renderAbout(), renderMenu(), renderLinks()]).filter(
		notNull,
	).length;

	const hasDefaultFooterAligment = amountOfFooterElements === 0 && !hasPaymentMethodsEnabled;

	return (
		<Stack
			sx={{
				mt: 4,
				background: (theme) =>
					amountOfFooterElements === 0
						? theme.palette.common.white
						: theme.palette.background.secondary,
				px: { xs: 3, sm: 7, lg: 7, xl: 7 },
				py: 6,
			}}
		>
			<Box sx={{ mx: 'auto', width: '100%', maxWidth: '1280px' }}>
				{!!footerInfo && (
					<Stack
						spacing={2}
						direction={{ xs: 'column', md: 'row' }}
						justifyContent="space-between"
						sx={{
							'&:empty': { display: 'none' },
						}}
					>
						{renderAbout()}
						{renderMenu()}
						{renderLinks()}
					</Stack>
				)}

				<Stack sx={{ mt: 5 }}>
					<Divider sx={{ mb: 2, background: '#cfcfcf', height: '2px' }} />
					<Stack
						spacing={2}
						direction={{ xs: 'column', md: 'row' }}
						alignItems="center"
						justifyContent={hasDefaultFooterAligment ? 'center' : 'space-between'}
					>
						<Stack direction="row" spacing={1} justifyContent="flex-start">
							{filteredPaymentMethods?.map((paymentMethod, i) => (
								<PaymentMethodIcons key={i} paymentMethod={paymentMethod} isInFooter={true} />
							))}
						</Stack>
						<Stack spacing={1} direction="row" alignItems="center">
							{!isEmbedded && getShopInfoWithPoweredBy()}
							{showManageCookies && (
								<Box>
									<Link
										component="button"
										color="secondary"
										onClick={() => CookieScript?.instance.show()}
										sx={{ textDecoration: 'none' }}
									>
										<Typography color="secondary" variant="body2">
											{' | ' + t('common:cookies.manageCookies', 'Manage cookies')}
										</Typography>
									</Link>
								</Box>
							)}
						</Stack>
					</Stack>
				</Stack>
			</Box>
		</Stack>
	);
};

export default Footer;
