import React from 'react';

import { Box, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';

import CenteredContent from 'components/CenteredContent';
import LanguageSelector from 'components/LanguageSelector/Desktop';
import LocationName from 'components/LocationName';
import AuthIcon from 'components/auth/AuthIcon';

import CartButton from '../Cart/CartButton';
import NavAction from './NavAction';
import NavLinks from './NavLinks';
import NavLogo from './NavLogo';

interface Props {
	containsCart: boolean;
	paddingTop?: number;
	paddingBottom?: number;
	isMobile?: boolean;
	mobileBackTo?: string;
	hideLocation?: boolean;
	showLogin?: boolean;
}

const NavMainContent = React.memo((props: Props) => {
	const { containsCart, paddingTop, paddingBottom, isMobile, mobileBackTo, showLogin } = props;
	const onlineLocations = useSelector(ShopSelectors.onlineLocations);
	const headerLayout = useSelector(ShopSelectors.headerLayout);
	const classes = useStyles(headerLayout?.logo.width);
	const showLocationSelector = onlineLocations.length > 1;

	return (
		<Box className={classes.outer}>
			<CenteredContent>
				<Box className={classes.wrapper} pt={paddingTop ?? 0} pb={paddingBottom ?? 0}>
					<Box
						className={classNames(classes.left, {
							[classes.flexOne]: isMobile || headerLayout?.logo.placement === 'centered',
						})}
					>
						{isMobile ? (
							<NavAction backTo={mobileBackTo} edge="start" />
						) : headerLayout?.logo.placement !== 'centered' ? (
							<NavLogo size={headerLayout?.logo.width} />
						) : (
							<Box />
						)}
					</Box>
					<Box
						className={classNames(classes.center, {
							[classes.justifyLeft]: !isMobile && headerLayout?.logo.placement !== 'centered',
							[classes.stackLogo]: headerLayout?.logo.placement === 'centered',
						})}
					>
						{!isMobile && headerLayout?.logo.placement === 'centered' && (
							<Box className={classNames(classes.center, classes.marginedLogo)}>
								<NavLogo size={headerLayout.logo.width} />
							</Box>
						)}
						{isMobile ? (
							<NavLogo size={headerLayout?.logo.width} isMobile={isMobile} />
						) : (
							<NavLinks />
						)}
					</Box>
					<Grid
						container
						alignItems="center"
						justifyContent="flex-end"
						spacing={!isMobile ? 4 : 0}
						className={classes.right}
					>
						{!isMobile && (
							<>
								{showLocationSelector && (
									<Grid item>
										<LocationName
											isLink
											textVariant="body2"
											showCity
											showIcon={true}
											justify="flex-start"
										/>
									</Grid>
								)}
								<Grid item>
									<LanguageSelector />
								</Grid>
							</>
						)}
						{!!showLogin && (
							<Grid item>
								<AuthIcon />
							</Grid>
						)}
						{containsCart && (
							<Grid item>
								<CartButton edge="end" size={!isMobile ? 24 : 32} />
							</Grid>
						)}
					</Grid>
				</Box>
			</CenteredContent>
		</Box>
	);
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		outer: {
			background: 'white',
			borderBottom: `1px solid ${theme.palette.border.paper}`,
		},
		wrapper: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		left: {
			width: 55,
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			[theme.breakpoints.up('md')]: {
				width: (w) => w,
			},
		},
		right: {
			flex: 1,
		},
		center: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			flex: 1,
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
		},
		justifyLeft: {
			justifyContent: 'left',
			marginLeft: theme.spacing(5),
		},
		flex: {
			display: 'flex',
			alignItems: 'center',
		},
		icon: {
			marginRight: theme.spacing(1),
		},
		flexOne: {
			flex: 1,
		},
		languageSelector: {
			marginLeft: theme.spacing(4),
		},
		locationAndLanguageBox: {
			marginRight: theme.spacing(4),
		},
		stackLogo: {
			flexDirection: 'column',
		},
		marginedLogo: {
			marginBottom: theme.spacing(2),
		},
	}),
);

export default NavMainContent;
