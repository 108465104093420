import React from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import ticketPlaceholder from 'common/assets/ticket-placeholder.png';
import { SkidataPackageProductApi } from 'common/types';
import { getProductImageSrc } from 'common/utils/productUtils';
import CenteredContent from 'components/CenteredContent';
import MetaTags from 'components/MetaTags';
import ProductNav from 'components/ProductNav';
import { useTranslation } from 'services/localization';
import { getProductImages } from 'utils/products';

import ImageCarousel from '../Product/ImageCarousel';
import ProductDetails from '../Product/ProductDetails';
import ProductMedia from '../Product/ProductMedia';
import PackageTicketsForm from './PackageTicketsForm';

interface Props {
	productId: string;
}

const PackageTickets = (props: Props) => {
	const classes = useStyles();
	const { getTranslation } = useTranslation();

	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const liftTicketCategory = useSelector(StockSelectors.liftTicketCategory);
	const allTickets = useSelector(StockSelectors.allSkidataProducts);
	const packageProduct = allTickets.find(
		(t) => t.id === props.productId,
	) as SkidataPackageProductApi;
	const liftTicketName = getTranslation(packageProduct.name);

	if (!activeLocation || !liftTicketCategory || !packageProduct) return null;

	const images = getProductImages(packageProduct);
	const hasMultipleImages = images.length > 1;

	return (
		<>
			<MetaTags type="product" product={packageProduct} />
			<Box style={{ position: 'relative' }}>
				<ProductNav productName={liftTicketName} />
				<CenteredContent paddingBottom={12}>
					<Box className={classes.wrapper}>
						<Box className={classes.left}>
							<Box className={classes.stickyColumn}>
								{hasMultipleImages ? (
									<ImageCarousel images={images} alt={liftTicketName ?? ''} />
								) : (
									<ProductMedia
										src={getProductImageSrc(packageProduct) ?? ticketPlaceholder}
										alt={liftTicketName ?? ''}
									/>
								)}
							</Box>
						</Box>
						<Box p={4} />
						<Box className={classes.right}>
							<ProductDetails product={packageProduct} />
							<PackageTicketsForm packageProduct={packageProduct} />
						</Box>
					</Box>
				</CenteredContent>
			</Box>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			paddingTop: theme.spacing(7),
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.up('md')]: {
				flexDirection: 'row',
			},
		},
		left: {
			flex: 1,
		},
		right: {
			flex: 1,
		},
		stickyColumn: {
			[theme.breakpoints.up('md')]: {
				position: 'sticky',
				top: 100,
			},
		},
	}),
);

export default PackageTickets;
