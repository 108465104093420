import * as AnalyticsSelectors from 'redux/selectors/analytics';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import { ReduxThunkAction } from 'redux/types';

import { logEcomEvent } from 'common/services/analytics';
import { removeNonNumeric } from 'common/utils/math';
import { getFromPriceString } from 'common/utils/pricing';
import { t } from 'services/localization';
import { getAnalyticsItemFromLiftTicket, getAnalyticsItemFromStockProduct } from 'utils/analytics';
import { getLiftTicketsFromPriceString } from 'utils/liftTicket';

import { EcomEventParams } from '..';
import { EcomEvents } from '../constants';

const eventName = EcomEvents.view_item;

export const logViewItem = (params: EcomEventParams[typeof eventName]): ReduxThunkAction => (
	_,
	getState,
) => {
	const state = getState();
	const shopGoogleAnalytics = ShopSelectors.shopGoogleAnalytics(state);
	const categoriesById = StockSelectors.categoriesById(state);
	const productsById = StockSelectors.productsById(state);
	const currency = ShopSelectors.shopCurrency(state);
	const pricingTables = ShopSelectors.shopPricingTables(state);
	const openingHours = ShopSelectors.activeStoreOpeningHours(state);
	const timezone = ShopSelectors.shopTimezone(state);
	const activeLocation = ShopSelectors.activeLocation(state);
	const liftTicketCategory = StockSelectors.liftTicketCategory(state);
	const liftTicketProducts = StockSelectors.liftTicketProducts(state);
	const itemListSelectionById = AnalyticsSelectors.itemListSelectionById(state);
	const { productId, isLiftTicket } = params;
	const product = productId ? productsById[productId] : undefined;
	const fromPrice = product
		? getFromPriceString(
				{
					product,
					pricingTables,
					openingHours,
					currency,
					startDate: null,
					channel: 'ONLINE',
					timezone,
				},
				t,
		  )
		: liftTicketCategory
		? getLiftTicketsFromPriceString(liftTicketProducts, currency)
		: '';
	const price = removeNonNumeric(fromPrice);
	const item = product
		? getAnalyticsItemFromStockProduct(product, {
				priceInMajorUnits: { value: price, currency: currency.code },
				categoriesById,
				location: activeLocation,
				itemListSelectionById,
		  })
		: isLiftTicket && liftTicketCategory
		? getAnalyticsItemFromLiftTicket(liftTicketCategory, {
				priceInMajorUnits: { value: price, currency: currency.code },
				location: activeLocation,
				itemListSelectionById,
		  })
		: undefined;
	if (!item) return;
	logEcomEvent(
		{
			name: eventName,
			params: {
				currency: currency.code,
				items: [item],
				value: removeNonNumeric(fromPrice),
			},
		},
		shopGoogleAnalytics,
	);
};
