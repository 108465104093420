import React, { useEffect, useState } from 'react';

import {
	Box,
	FormControl,
	ListItemText,
	Radio,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import { useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as ViewSelectors from 'redux/selectors/view';

import BackdropDialog from 'common/components/BackdropDialog';
import Banner from 'common/components/states/Banner';
import { getDeliveryPricingString } from 'common/modules/delivery/utils';
import { isRunInIframe } from 'common/utils/browserUtils';
import { useTranslation } from 'services/localization';

interface Props {
	isOpen: boolean;
	confirm: (id?: string) => void;
	cancel: () => void;
}

const DeliveryOptionModal = (props: Props) => {
	const { isOpen, confirm, cancel } = props;
	const classes = useStyles(props);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const deliveryOptions = useSelector(ShopSelectors.activeLocationDeliveryOptions)!;
	const selectedDeliveryOption = useSelector(ViewSelectors.selectedDeliveryOption);
	const cartProductsCount = useSelector(CartSelectors.cartProductsCount);
	const currency = useSelector(ShopSelectors.shopCurrency);
	const { t, getTranslation } = useTranslation();
	const isEmbedded = isRunInIframe();
	const sortedDeliveryOptions = sortBy(deliveryOptions, (o) => o.orderIndex);

	const [selectedDeliveryOptionId, setSelectedDeliveryOptionId] = useState<string | undefined>(
		selectedDeliveryOption?.id ?? undefined,
	);

	useEffect(() => {
		if (!!selectedDeliveryOption) {
			setSelectedDeliveryOptionId(selectedDeliveryOption.id);
		}
	}, [selectedDeliveryOption]);

	const showChangeWarning =
		cartProductsCount > 0 &&
		(!selectedDeliveryOption ||
			(!!selectedDeliveryOption && selectedDeliveryOption.id !== selectedDeliveryOptionId));

	return (
		<BackdropDialog
			isOpen={isOpen}
			onClose={() => {
				setSelectedDeliveryOptionId(selectedDeliveryOption?.id);
				cancel();
			}}
			fullScreen={isMobile}
			fullScreenAutoHeight={isEmbedded}
			position={isEmbedded ? 'top' : 'center'}
			header={
				<Box className={classes.headerSpace}>
					<Typography
						variant={isMobile ? 'h6' : 'h5'}
						align={'center'}
						className={
							isMobile
								? classNames(classes.mobileHeader, { [classes.bottomSpacing]: showChangeWarning })
								: classNames({ [classes.bottomSpacing]: showChangeWarning })
						}
					>
						{t('delivery.chooseDeliveryOption', 'Choose delivery option')}
					</Typography>
					{showChangeWarning && (
						<Banner
							variant="danger"
							title={t(
								'delivery.changingDeliveryOptionResetsBag',
								'Changing delivery option resets your bag.',
							)}
						/>
					)}
				</Box>
			}
			body={
				<Box p={0}>
					<FormControl component="fieldset" className={classes.deliveryOptionsForm}>
						<Box className={classes.radioGroup}>
							{sortedDeliveryOptions.map((o) => (
								<div
									className={classNames(classes.optionContainer, {
										[classes.selectedBox]: selectedDeliveryOptionId === o.id,
									})}
									key={o.id}
									onClick={(e) => setSelectedDeliveryOptionId(o.id)}
								>
									<Box display="flex" flexDirection="row" alignItems="center" ml={-1}>
										<Box ml={-0.5}>
											<Radio
												checked={selectedDeliveryOptionId === o.id}
												classes={{
													root: classes.radio,
													checked: classes.checkedRadio,
												}}
											/>
										</Box>
										<ListItemText
											primary={getTranslation(o.name)}
											secondary={getDeliveryPricingString(o, currency)}
										/>
									</Box>

									<Typography variant="body2" className={classes.optionDescription}>
										{getTranslation(o.description)}
									</Typography>
								</div>
							))}
						</Box>
					</FormControl>
				</Box>
			}
			confirm={{
				label: showChangeWarning
					? t('delivery.changeDeliveryOption', 'Change delivery option')
					: t('common:actions.select'),
				callback: () => {
					!!selectedDeliveryOptionId && confirm(selectedDeliveryOptionId);
				},
				disabled:
					!selectedDeliveryOptionId || selectedDeliveryOptionId === selectedDeliveryOption?.id,

				sx: {
					boxShadow: !showChangeWarning ? 'none' : undefined,
					'&:hover': { boxShadow: !showChangeWarning ? 'none' : undefined },
				},
			}}
			cancel={{
				label: t('common:actions.cancel'),
				callback: () => {
					cancel();
				},
				closeAfterDone: true,
				autoFocus: false,
			}}
			variant={showChangeWarning ? 'danger' : undefined}
			buttonDirection="column-reverse"
			width="sm"
			buttonFullWidth
			buttonBoxShadow
		/>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		deliveryOptionsForm: {
			display: 'flex',
		},
		radioGroup: {
			'& > :first-child': {
				marginTop: 0,
			},
		},
		optionContainer: {
			border: '1px solid #eee',
			borderRadius: theme.spacing(1),
			marginTop: theme.spacing(3),
			padding: theme.spacing(2),
			cursor: 'pointer',
		},
		optionLabel: {
			flexGrow: 1,
			fontWeight: '500 !important' as any,
		},
		optionPriceMobile: {
			fontWeight: 500,
			margin: theme.spacing(-1, 0, 2, 4),
		},
		optionDescription: {
			marginTop: theme.spacing(1),
			marginLeft: theme.spacing(4),
			marginRight: theme.spacing(6),
			color: theme.palette.colors.tundra.main,
			fontWeight: 'normal',
		},
		selectedBox: {
			border: '1px solid #0050ff',
			backgroundColor: '#FAFCFF',
		},
		radio: {
			color: theme.palette.colors.tundra.main,
			'&$checked': {
				color: '#0050ff !important',
			},
		},
		checkedRadio: {
			color: '#0050ff !important',
		},
		mobileHeader: {
			fontSize: '2.4rem',
		},
		headerSpace: {
			padding: theme.spacing(5, 4),
		},
		bottomSpacing: {
			marginBottom: theme.spacing(4),
		},
	}),
);

export default DeliveryOptionModal;
