import { getTotalQuantityForSku } from 'common/api/frontend/inventory_new';
import { Api } from 'common/db/api/paths';
import { getProductsInPackage, isPackageProduct } from 'common/modules/products/utils';
import { getVariantIds } from 'common/modules/products/variants';
import { ByVariant, Channel, Location, ProductApi, PurchaseType } from 'common/types';
import { notNull } from 'common/utils/common';
import { productHasUnlimitedStock } from 'common/utils/productUtils';

import { AvailabilityDataSources, VariantAvailabilityObject } from './types';
import { getProductVariantsInfo, reduceToObject } from './utils';

export interface ProductQuantity {
	productId: string;
	totalQuantity: number | null;
	variantQuantities: ByVariant<number | null>;
}

type ItemAmounts = { [key: string]: number | null };

export interface HandleQuantityFetchProps {
	product: ProductApi;
	stockProducts: ProductApi[];
	startLocationId: string;
	shopLocations: Location[];
	salesChannel?: Channel;
	dataSources?: AvailabilityDataSources;
	api: Api;
	purchaseType: PurchaseType;
}

export const handleQuantityFetch = async (
	props: HandleQuantityFetchProps,
): Promise<ProductQuantity> => {
	const { product } = props;

	return productHasUnlimitedStock(product)
		? getUnlimitedQuantityObject(product)
		: await getProductQuantities(props);
};

export const getUnlimitedQuantityObject = (product: ProductApi): ProductQuantity => {
	const totalQuantitiesByVariant: ByVariant<number | null> = product.variants.options.reduce(
		(acc, variant) => {
			acc[variant.id] = null;
			return acc;
		},
		{},
	);
	return {
		productId: product.id,
		totalQuantity: null,
		variantQuantities: totalQuantitiesByVariant,
	};
};

const getProductQuantities = async (props: HandleQuantityFetchProps): Promise<ProductQuantity> => {
	const {
		product,
		stockProducts,
		salesChannel,
		startLocationId,
		dataSources,
		api,
		purchaseType,
	} = props;

	const isSetProduct = isPackageProduct(product);
	const productsInPackage = getProductsInPackage(product, stockProducts);

	const productsToCheck = isSetProduct ? [product, ...productsInPackage] : [product];

	const quantitiesArray = await Promise.all(
		productsToCheck.map((product) => {
			const onlineLimit = product.onlineLimit ?? null;
			const productVariants = getProductVariantsInfo(product);
			return fetchQuantities({
				productVariants,
				startLocationId,
				salesChannel,
				onlineLimit,
				dataSources,
				api,
				purchaseType,
			});
		}),
	).then((arr) => arr.flat());

	return getProductQuantityFromQuantitiesArray({
		quantitiesArray,
		product,
		isSetProduct,
		setStockProducts: productsInPackage,
	});
};

export const getProductQuantityFromQuantitiesArray = (args: {
	quantitiesArray: {
		[key: string]: number | null;
	}[];
	product: ProductApi;
	isSetProduct: boolean;
	setStockProducts: ProductApi[];
}): ProductQuantity => {
	const { quantitiesArray, product, isSetProduct, setStockProducts } = args;

	const quantities = reduceToObject(quantitiesArray);

	const mainProductVariantIds = getVariantIds(product);

	const variantQuantities = isSetProduct
		? getSetTotalQuantities(quantities, setStockProducts, mainProductVariantIds)
		: quantities;

	const totQuantityFromVariants = isSetProduct
		? getSetTotalQuantityFromVariants(variantQuantities, mainProductVariantIds)
		: getTotQuantityFromVariants(variantQuantities, mainProductVariantIds);

	const productQuantities: ProductQuantity = {
		productId: product.id,
		totalQuantity: totQuantityFromVariants,
		variantQuantities,
	};

	return productQuantities;
};

interface FetchQuantitiesProps {
	productVariants: VariantAvailabilityObject[];
	startLocationId: string;
	salesChannel?: Channel;
	onlineLimit: number | null;
	dataSources?: AvailabilityDataSources;
	api: Api;
	purchaseType: PurchaseType;
}

const fetchQuantities = async (
	props: FetchQuantitiesProps,
): Promise<{ [variantId: string]: number | null }[]> => {
	const {
		productVariants,
		startLocationId,
		salesChannel,
		onlineLimit,
		dataSources,
		api,
		purchaseType,
	} = props;

	return Promise.all(
		productVariants.map(async (variant) => {
			const { variantId, skuId } = variant;
			const skuQuantity = !skuId
				? null
				: await getTotalQuantityForSku({
						skuId,
						startLocationId,
						dataSources,
						api,
						purchaseType,
				  });
			const useOnlineLimit =
				salesChannel === 'ONLINE' && onlineLimit != null && skuQuantity != null ? true : false;

			const quantity =
				useOnlineLimit && onlineLimit != null && skuQuantity != null
					? Math.ceil(skuQuantity * onlineLimit)
					: skuQuantity;
			return { [variantId]: quantity };
		}),
	);
};

export const getSetTotalQuantities = (
	quantities: ItemAmounts,
	setStockProducts: ProductApi[],
	productVariantIds: string[],
) => {
	const minSetQuantities = calculateMinQuantityFromSetProductsQuantities(
		quantities,
		setStockProducts,
		productVariantIds,
	);
	return {
		...quantities,
		...minSetQuantities,
	};
};

const calculateMinQuantityFromSetProductsQuantities = (
	quantities: ItemAmounts,
	setStockProducts: ProductApi[],
	mainProductVariantIds: string[],
) => {
	const setProductVariantGroups = setStockProducts.map(getVariantIds);

	const limitedQuantityProducts = setProductVariantGroups.filter((grp) =>
		grp.every((variantId) => quantities[variantId] != null),
	);

	const cumulatedVariantQuantities = limitedQuantityProducts.map((s) =>
		s.reduce((acc, variantId) => acc + (quantities[variantId] ?? 0), 0),
	);

	const smallestQuantity = cumulatedVariantQuantities.length
		? Math.min(...cumulatedVariantQuantities)
		: null;

	return mainProductVariantIds.reduce((acc, val) => {
		acc[val] = smallestQuantity;
		return acc;
	}, {} as ItemAmounts);
};

export const getSetTotalQuantityFromVariants = (
	quantities: ItemAmounts,
	mainProductVariantIds: string[],
) => {
	const productVariantQuantities = mainProductVariantIds.map((id) => quantities[id]);
	return productVariantQuantities.some((qty) => qty === null)
		? null
		: Math.max(...productVariantQuantities.filter(notNull));
};

export const getTotQuantityFromVariants = (quantities: ItemAmounts, variants: string[]) => {
	let totQuantityForVariants: number | null = null;
	for (const variantId of variants) {
		if (quantities[variantId] === null) return null;
		totQuantityForVariants = (totQuantityForVariants ?? 0) + (quantities[variantId] ?? 0);
	}
	return totQuantityForVariants;
};
