import React, { useState } from 'react';

import {
	Box,
	ButtonBase,
	Drawer,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { RiArrowLeftLine, RiArrowRightSLine } from 'react-icons/ri';
import * as ViewActions from 'redux/actions/view';
import { useDispatch, useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import LocationsGrid from 'views/Shop/Location/Locations/LocationsGrid';

import AboutContainer from 'common/components/AboutContainer';
import CustomContentContainer from 'common/components/customizer/CustomContentContainer';
import { getLanguageNativeName } from 'common/locales/utils';
import { Languages } from 'common/types';
import useExternalAuth from 'hooks/useExternalAuth';
import { useTranslation } from 'services/localization';
import { onlineLanguages, setLanguage } from 'services/localization/utils';

import BoxButton from './BoxButton';
import CenteredContent from './CenteredContent';
import LocationDetailContainer from './LocationDetailContainer';
import LocationName from './LocationName';

interface Props {
	open: boolean;
	onClose: () => void;
}
const ShopSidebar = (props: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t, language } = useTranslation();
	const {
		isAuthInUse,
		isLoggedIn,
		loginLoading,
		oauthRedirect,
		logout,
		authProfile,
	} = useExternalAuth();

	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const onlineLocations = useSelector(ShopSelectors.onlineLocations);
	const shopInfo = useSelector(ShopSelectors.shopPublicInfoData);
	const shopLanguages = useSelector(ShopSelectors.shopLanguages) ?? ['en'];
	const visibleLanguages = shopLanguages.filter((lang) =>
		onlineLanguages.some((onlineLang) => onlineLang === lang),
	);
	const layout = useSelector(ShopSelectors.onlineLayout);
	const about = layout?.about;
	const customContent = about?.customContent;

	const [expanded, setExpanded] = useState<boolean>(false);
	const [expandedContent, setExpandedContent] = useState<
		'about' | 'contact' | 'language' | 'location' | 'account' | null
	>(null);

	const handleLanguageChange = (lang: Languages) => {
		dispatch(ViewActions.setLanguage(lang));
		setLanguage(lang);
		props.onClose();
	};

	if (!activeLocation) return null;

	const handleAuthSidebarItemClick = () => {
		if (isLoggedIn) {
			openAccountContent();
		} else {
			handleOauthRedirect();
		}
	};

	const openAccountContent = () => {
		setExpandedContent('account');
		setExpanded(true);
	};

	const handleLogout = async () => {
		logout();
		props.onClose();
	};

	const handleOauthRedirect = async () => {
		await oauthRedirect();
		props.onClose();
	};

	const renderMainContent = () => {
		return (
			<>
				<Box className={classes.top}>
					<Box className={classes.logoWrapper}>
						<img src={shopInfo?.logoImgUrl} className={classes.logo} alt={shopInfo?.name} />
					</Box>
					<Box mt={3} />
					<Typography variant="body1" className={classes.textBold}>
						{shopInfo?.name}
					</Typography>
					<Box mt={1} />
					<LocationName textVariant="body2" showCity showIcon={false} justify="center" />
					<Box mt={3}>
						<BoxButton
							variant="outlined"
							text={t('common:shopLocation.contactDetails', 'Contact details')}
							size="small"
							onClick={() => {
								setExpandedContent('contact');
								setExpanded(true);
							}}
						/>
					</Box>
				</Box>
				{isAuthInUse && (
					<ButtonBase
						onClick={handleAuthSidebarItemClick}
						className={classes.link}
						disabled={loginLoading}
					>
						<Typography variant="body1" className={classes.itemName}>
							{loginLoading
								? t('common:actions.signingIn', 'Signing in...')
								: isLoggedIn
								? t('common:auth.account', 'Account')
								: t('common:actions.signIn', 'Sign in')}
						</Typography>
						{!loginLoading && (
							<Box ml={1}>
								<RiArrowRightSLine color="black" size={32} />
							</Box>
						)}
					</ButtonBase>
				)}
				<ButtonBase
					onClick={() => {
						setExpandedContent('about');
						setExpanded(true);
					}}
					className={classes.link}
				>
					<Typography variant="body1" className={classes.itemName}>
						{t('common:actions.about', 'About')}
					</Typography>
					<Box ml={1}>
						<RiArrowRightSLine color="black" size={32} />
					</Box>
				</ButtonBase>
				{visibleLanguages.length > 1 && (
					<ButtonBase
						onClick={() => {
							setExpandedContent('language');
							setExpanded(true);
						}}
						className={classes.link}
					>
						<Typography variant="body1" className={classes.itemName}>
							{t('common:actions.changeLanguage', 'Change language')}
						</Typography>
						<Box ml={1}>
							<RiArrowRightSLine color="black" size={32} />
						</Box>
					</ButtonBase>
				)}
				{onlineLocations.length > 1 && (
					<ButtonBase
						onClick={() => {
							setExpandedContent('location');
							setExpanded(true);
						}}
						className={classes.link}
					>
						<Typography variant="body1" className={classes.itemName}>
							{t('common:shopLocation.stores', 'Stores')}
						</Typography>
						<Box ml={1}>
							<RiArrowRightSLine color="black" size={32} />
						</Box>
					</ButtonBase>
				)}
			</>
		);
	};

	const renderExpandedContent = () => {
		switch (expandedContent) {
			case 'about': {
				return (
					<CenteredContent>
						<AboutContainer about={about} language={language} t={t}>
							<LocationDetailContainer location={activeLocation} variant="contact" />
						</AboutContainer>
						{!!customContent && (
							<Box>
								<CustomContentContainer customContent={customContent} language={language} />
							</Box>
						)}
					</CenteredContent>
				);
			}
			case 'contact': {
				return <LocationDetailContainer location={activeLocation} variant="contact" />;
			}
			case 'language': {
				return (
					<Box flex={1} display="flex" flexDirection="column">
						<Box flex={1}>
							<Typography variant="h6">
								{t('common:actions.changeLanguage', 'Change language')}
							</Typography>
							<Box mt={3} />
							<RadioGroup
								className={classes.radioGroup}
								value={language}
								onChange={(_, value) => handleLanguageChange(value as Languages)}
							>
								{visibleLanguages.map((lang) => {
									return (
										<FormControlLabel
											className={classes.radioFormLabel}
											key={lang}
											value={lang}
											control={
												<Radio
													color="default"
													classes={{
														root: classes.radio,
													}}
												/>
											}
											label={getLanguageNativeName(lang)}
										/>
									);
								})}
							</RadioGroup>
						</Box>
					</Box>
				);
			}
			case 'location': {
				return (
					<Box>
						<Typography variant="h6">{t('common:shopLocation.stores', 'Stores')}</Typography>
						<Box mt={4}>
							<LocationsGrid onLocationSelect={() => props.onClose()} fullWidthCards />
						</Box>
					</Box>
				);
			}
			case 'account': {
				const firstName = authProfile?.data?.firstName;
				const lastName = authProfile?.data?.lastName;
				const name = `${firstName} ${lastName}`;
				const header = firstName ? name : t('common:auth.account', 'Account');
				return (
					<Box>
						<Typography variant="h6">{header}</Typography>
						<Box mt={4} color="black">
							<ButtonBase onClick={handleLogout}>
								<Typography variant="body1" style={{ fontWeight: 500 }}>
									{t('common:actions.logout')}
								</Typography>
							</ButtonBase>
						</Box>
					</Box>
				);
			}
			default: {
				return null;
			}
		}
	};

	return (
		<Drawer
			anchor="left"
			open={props.open}
			onClose={() => {
				props.onClose();
			}}
			transitionDuration={{
				enter: 225,
				exit: 325,
			}}
			SlideProps={{
				onExited: () => {
					setExpanded(false);
					setExpandedContent(null);
				},
			}}
			ModalProps={{
				/**
				 * Keeping the sidebar mounted when not visible messes with the page resizing when embedded. `keepMounted` is currently false by default,
				 * but this is just to make it explicit that we do not want to keep the sidebar mounted.
				 */
				keepMounted: false,
			}}
		>
			<Box className={classNames(classes.content, { [classes.contentExpanded]: expanded })}>
				<Box className={classNames(classes.mainContent, { [classes.mainContentHidden]: expanded })}>
					{renderMainContent()}
				</Box>
				<Box className={classNames(classes.expanded, { [classes.expandedVisible]: expanded })}>
					<ButtonBase className={classes.expandedHeader} onClick={() => setExpanded(false)}>
						<RiArrowLeftLine color="black" size={32} />
					</ButtonBase>
					<Box className={classes.expandedContent}>{renderExpandedContent()}</Box>
				</Box>
			</Box>
		</Drawer>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			position: 'relative',
			height: '100%',
			width: '85vw',
			maxWidth: 320,
			transition: 'width 225ms, max-width 225ms',
			overflowX: 'hidden',
			overflowY: 'auto',
		},
		contentExpanded: {
			width: '100vw',
			maxWidth: 'none',
			[theme.breakpoints.up('sm')]: {
				maxWidth: 400,
			},
		},
		mainContent: {
			position: 'absolute',
			top: 0,
			left: 0,
			maxWidth: 320,
			width: '100%',
			height: '100%',
			zIndex: 2,
			display: 'flex',
			flexDirection: 'column',
			overflow: 'auto',
			transform: 'translate3d(0,0,0)',
			transition: 'transform 225ms',
			background: '#ffffff',
		},
		mainContentHidden: {
			transform: 'translate3d(-100%, 0, 0)',
		},
		expanded: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			maxWidth: '100vw',
			height: '100%',
			zIndex: 1,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'stretch',
			transform: 'translate3d(100%, 0, 0)',
			transition: 'transform 225ms',
			background: '#ffffff',
		},
		expandedVisible: {
			transform: 'translate3d(0, 0, 0)',
			zIndex: 3,
		},
		expandedContent: {
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			overflowY: 'auto',
			padding: theme.spacing(0.5, 2.5, 2.5, 2.5),
		},
		expandedHeader: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			alignItems: 'center',
			padding: theme.spacing(2),
		},
		top: {
			width: '100%',
			padding: theme.spacing(4),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			textAlign: 'center',
			flex: 1,
		},
		logoWrapper: {
			width: '50%',
			height: 'auto',
		},
		logo: {
			maxHeight: '100%',
			maxWidth: '100%',
		},
		itemName: {
			fontWeight: 600,
			lineHeight: '32px',
		},
		textBold: {
			fontWeight: 600,
		},
		closeButton: {
			padding: theme.spacing(2),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		link: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: theme.spacing(2, 2, 2, 3),
			borderTop: '1px solid #dedede',
		},
		poweredBy: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			padding: theme.spacing(0, 2),
			borderTop: '1px solid #dedede',
		},
		cartHeaderClose: {
			cursor: 'pointer',
		},
		radioFormLabel: {
			position: 'relative',
			padding: theme.spacing(0.5, 0),
			marginRight: 0,
			textTransform: 'capitalize',
			'&::after': {
				content: '""',
				borderBottom: `1px solid #dedede`,
				marginLeft: 11,
				position: 'absolute',
				bottom: 0,
				right: 0,
				left: 0,
			},
		},
		radio: {
			color: theme.palette.common.black,
		},
		radioGroup: {
			paddingBottom: 80,
		},
	}),
);

export default ShopSidebar;
