import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as ShopSelectors from 'redux/selectors/shop';
import { ReduxDispatch, ReduxState } from 'redux/types';

import { getAdyenPaymentMethods } from 'common/api/db/functions/callable';
import { PaymentMethodError } from 'common/components/payment/types';
import { CheckoutSettings } from 'common/modules/customization';
import {
	AdditionalPaymentAction,
	AmountObject,
	AppliedDiscountCodes,
	GetPaymentMethods,
	OrderProduct,
	PublicDiscountCode,
	ResponsiblePersonDetails,
	TermsAcceptance,
} from 'common/types';

export const updateAdyenPaymentMethods = createAsyncThunk<
	{ data: ICheckout.PaymentMethodsResponse | null },
	AmountObject,
	{
		dispatch: ReduxDispatch;
		state: ReduxState;
	}
>('Checkout/UPDATE_ADYEN_PAYMENT_METHODS', (amount, thunkAPI) => {
	const state = thunkAPI.getState();
	const shopId = ShopSelectors.shopId(state);
	const shopCountry = ShopSelectors.shopCountry(state);
	const requestData: GetPaymentMethods = {
		shopId,
		amount,
		...(shopCountry && { countryCode: shopCountry.toUpperCase() }),
	};
	return getAdyenPaymentMethods(requestData);
});

export const setPaymentError = createAction<undefined | PaymentMethodError>(
	'Checkout/SET_PAYMENT_ERROR',
);

export const setPaymentLoading = createAction<boolean>('Checkout/SET_PAYMENT_LOADING');

export const setPaymentInProgress = createAction<boolean>('Checkout/SET_PAYMENT_IN_PROGRESS');

export const setAdditionalPaymentAction = createAction<AdditionalPaymentAction>(
	'Checkout/SET_ADDITIONAL_PAYMENT_ACTION',
);

export const setTransactionId = createAction<string>('Checkout/SET_TRANSACTION_ID');

export const setOrderId = createAction<string>('Checkout/SET_ORDER_ID');

export const updateContactPerson = createAction<Partial<ResponsiblePersonDetails>>(
	'Checkout/UPDATE_CONTACT_PERSON',
);

export const updateTermsAcceptance = createAction<TermsAcceptance>(
	'Checkout/UPDATE_TERMS_ACCEPTANCE',
);

export const updateRecurringPaymentsAcceptance = createAction<boolean>(
	'Checkout/UPDATE_RECURRING_PAYMENTS_ACCEPTANCE',
);

export const updateCustomCheckoutField = createAction<{
	value: string | null;
	sectionIndex: number;
	contentIndex: number;
}>('Checkout/UPDATE_CUSTOM_CHECKOUT');

export const updateCustomCheckoutCheckbox = createAction<{
	value: boolean | null;
	sectionIndex: number;
	contentIndex: number;
}>('Checkout/UPDATE_CUSTOM_CHECKOUT_CHECKBOX');

export const initialiseCustomCheckoutFields = createAction<CheckoutSettings>(
	'Checkout/INITIALISE_CUSTOM_CHECKOUT',
);

export const setOrderProducts = createAction<OrderProduct[]>('Checkout/SET_ORDER_PRODUCTS');

export const setHandlePaymentRedirect = createAction<boolean>(
	'Checkout/SET_HANDLE_PAYMENT_REDIRECT',
);

export const replaceDiscountCodesInCartOnly = createAction<AppliedDiscountCodes>(
	'Checkout/REPLACE_DISCOUNT_CODES_IN_CART',
);

export const addDiscountCode = createAction<PublicDiscountCode>('Checkout/ADD_DISCOUNT_CODE');

export const removeDiscountCode = createAction<string>('Checkout/REMOVE_DISCOUNT_CODE');

export const clearDiscountCodes = createAction('Checkout/CLEAR_DISCOUNT_CODES');
