import React, { useCallback, useState } from 'react';

import {
	Box,
	Button,
	Container,
	Divider,
	Grid,
	Icon,
	IconButton,
	Theme,
	Typography,
	useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import {
	addShopper,
	removeShopper,
	setShoppers,
	updateOrderInfo,
	updateProducts,
} from 'redux/actions/confirm';
import { setActiveShopperIndex } from 'redux/actions/view';
import * as ConfirmSelectors from 'redux/selectors/confirm';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import { updateOrderObject } from 'common/api/db/functions';
import { ReactComponent as PersonIcon } from 'common/assets/icons/icon-person-solid.svg';
import Spinner from 'common/components/Spinner';
import { Callable } from 'common/frontend/callable';
import {
	DTAOrderStatuses,
	ShopperIdWithPhotoUploaded,
	USER_DIDNT_INPUT_PHOTO,
	getDataURLFromDTAContactImageData,
	hasReservedDTAOrderStatus,
	productsHasPhotoRequired,
	updateDTAOrderStatus,
} from 'common/modules/skidata';
import errorHandler from 'common/services/errorHandling/errorHandler';
import { OrderProduct, Shopper, UserDetailName, UserProperties } from 'common/types';
import { getOnlineOrderRequest, isBookedOrderMissingDetails } from 'common/utils/confirmation';
import { getDurationString } from 'common/utils/dateCalculations';
import { getClientEnv } from 'common/utils/frontUtils';
import { SerialNumberUtils } from 'common/utils/keycardSerialNumberUtils';
import {
	filterExistingSkipassProducts,
	findProductKeycard,
	hasLiftTicketProducts,
	hasMissingKeycardNumbers,
	isKeycardProduct,
	isLiftTicketProduct,
	isValidKeycardNumber,
	productsIncludeExistingSkipass,
} from 'common/utils/liftTicketUtils';
import {
	createCompleteOrderObject,
	getEmptyShopper,
	newFirestoreId,
} from 'common/utils/newRentalUtils';
import { getProductNameWithVariant } from 'common/utils/productUtils';
import { getSkidataSegmentTranslation } from 'common/utils/segments';
import { getSegmentTranslation } from 'common/utils/translations';
import { getDetailDisplayLabel, getLocalInputFromUserProperty } from 'common/utils/userDetails';
import useShopFormat from 'hooks/useShopFormat';
import { useTranslation } from 'services/localization/useTranslation';
import { useRoutes } from 'services/routing/useRoutes';

import ErrorBanner from './components/ErrorBanner';
import RemoveShopperDialog from './components/RemoveShopperDialog';
import { getUnchosenProducts } from './utils/productUtils';
import { getUnchosenProductSegments } from './utils/segmentUtils';
import {
	getShopperName,
	getShoppersWithoutProducts,
	indexOfLastShopperWithProducts,
	removeShopperFromRentalInfo,
	removeShopperIdFromProducts,
	sortShoppersByCreationDate,
} from './utils/shopperUtils';

const ShopperSummary = () => {
	const { t, getTranslation, language } = useTranslation();
	const { pushRoute, Routes } = useRoutes();
	const { localFormat } = useShopFormat();
	const dispatch = useDispatch();
	const classes = useStyles();
	const theme = useTheme();
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const [shopperToRemove, setShopperToRemove] = useState<Shopper | null>(null);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);

	const shopInfo = useSelector(ShopSelectors.shopPublicInfoData);
	const categoriesData = useSelector(StockSelectors.stockCategoriesData);
	const shopperIdsWithDTAImage = useSelector(ConfirmSelectors.shopperIdsWithDTAImage);

	const order = useSelector(ConfirmSelectors.orderData_FORCE);
	const { rentalInfo, products, shoppers, orderDelivery } = order;
	const { responsiblePerson } = rentalInfo;
	const responsiblePersonId = responsiblePerson.external
		? responsiblePerson.person.id
		: responsiblePerson.shopperId;
	const shopUrlsData = useSelector(ShopSelectors.shopUrlsData)!;

	const unchosenProducts = getUnchosenProducts(shoppers, products);
	const shoppersWithoutProducts = getShoppersWithoutProducts(shoppers, products);
	const showErrorBanner =
		unchosenProducts.length > 0 || shoppersWithoutProducts.length > 0 || hasSubmitError;

	const goToShopperDetails = (index: number) => {
		dispatch(setActiveShopperIndex(index));
		pushRoute(Routes.confirmDetailsInfo);
	};

	const openRemoveShopperDialog = (shopper: Shopper) => {
		setShopperToRemove(shopper);
		setDialogOpen(true);
	};

	const closeDialog = () => {
		setDialogOpen(false);
	};

	const deleteShopper = useCallback(
		(shopperId: string) => {
			const updatedProducts = removeShopperIdFromProducts(shopperId, products, responsiblePersonId);
			const updatedRentalInfo = removeShopperFromRentalInfo(rentalInfo, shopperId);
			dispatch(updateOrderInfo(updatedRentalInfo));
			dispatch(updateProducts(updatedProducts));
			dispatch(removeShopper(shopperId));
			closeDialog();
		},
		[products, rentalInfo, responsiblePersonId, dispatch],
	);

	const addShopperAndGoToDetails = () => {
		const shopperId = newFirestoreId();
		const unchosenProductSegments = getUnchosenProductSegments(shoppers, products);
		const newShopper = getEmptyShopper(
			shopperId,
			rentalInfo.id,
			rentalInfo.shopId,
			unchosenProductSegments[0],
		);
		dispatch(addShopper(newShopper));
		goToShopperDetails(shoppers.length);
	};

	const updateShoppersWithPhotos = (
		shoppers: Shopper[],
		shopperIdsWithPhotoStatus: ShopperIdWithPhotoUploaded[],
	) => {
		const updatedShoppers = shoppers.map((s) => {
			const shopper = shopperIdsWithPhotoStatus.find(({ shopperId }) => shopperId === s.id);
			return !!shopper ? { ...s, skidataProperties: { photoUploaded: shopper.photoUploaded } } : s;
		});
		dispatch(setShoppers(updatedShoppers));
	};

	if (!shopInfo) {
		return null;
	}

	const submitOrderDetails = async () => {
		setIsSubmitting(true);
		setHasSubmitError(false);
		const updatedOrder = createCompleteOrderObject({
			rentalInfo,
			shoppers,
			products,
			orderDelivery,
		});
		const orderRequest = getOnlineOrderRequest({
			order: updatedOrder,
			shopInfo,
			categories: categoriesData,
			lang: language,
			shopUrlDoc: shopUrlsData,
			locationId: updatedOrder.rentalInfo.startLocation.id,
			isMissingDetails: isBookedOrderMissingDetails(updatedOrder.products, updatedOrder.shoppers),
			localFormat,
			t,
			env: getClientEnv(),
		});
		try {
			await updateOrderObject(orderRequest);

			const shouldUpdateSkidataOrder =
				hasLiftTicketProducts(updatedOrder.products) &&
				((productsIncludeExistingSkipass(updatedOrder.products) &&
					!hasMissingKeycardNumbers(updatedOrder.products)) ||
					productsHasPhotoRequired(products)) &&
				hasReservedDTAOrderStatus(updatedOrder.rentalInfo?.skidataProps?.DTAOrderStatus);

			if (shouldUpdateSkidataOrder) {
				const result = await Callable.skidata.orders.update({
					order: updatedOrder,
					shopperIdsWithDTAImage,
				});

				const skidataOrderStatus = result?.orderStatus;
				const skidataShoppersWithPhotos = result?.shopperIdsWithPhotoStatus;

				if (!!skidataOrderStatus) {
					const orderWithUpdatedDTAStatus = updateDTAOrderStatus(
						updatedOrder.rentalInfo,
						skidataOrderStatus,
					);
					dispatch(updateOrderInfo(orderWithUpdatedDTAStatus));

					if (skidataOrderStatus === DTAOrderStatuses.BOOKED_AND_TRANSFERRED) {
						await Callable.skidata.orders.email(orderRequest);
					}
				}

				if (!!skidataShoppersWithPhotos) {
					updateShoppersWithPhotos(updatedOrder.shoppers, skidataShoppersWithPhotos);
				}
			}
			pushRoute(Routes.confirm, { query: { orderId: rentalInfo.id } });
		} catch (e) {
			errorHandler.report(e);
			setIsSubmitting(false);
			setHasSubmitError(true);
		}
	};

	const goBack = () => {
		const lastShopperWithProductsIndex = indexOfLastShopperWithProducts(shoppers);
		dispatch(setActiveShopperIndex(lastShopperWithProductsIndex));
		pushRoute(Routes.confirmDetailsProducts);
	};

	const renderShopperProperties = (userProperties: UserProperties) =>
		Object.keys(userProperties).map((detailName) => {
			const localInput = getLocalInputFromUserProperty(
				detailName as UserDetailName,
				userProperties[detailName],
			);
			return (
				<Typography
					key={detailName}
					component="span"
					variant="body2"
					color="textSecondary"
					display="inline"
					classes={{ root: classes.inline }}
					className={classes.shopperProperties}
				>
					{getDetailDisplayLabel(detailName as UserDetailName, localInput, t)}
				</Typography>
			);
		});

	const renderSegment = (shopper: Shopper) => {
		return shopper.segment ? (
			<Typography
				component="span"
				variant="body2"
				color="textSecondary"
				display="inline"
				classes={{ root: classes.inline }}
				className={classes.shopperProperties}
			>
				{getSegmentTranslation(shopper.segment, t)}
			</Typography>
		) : null;
	};

	const renderNameAndButtonsRow = (shopper: Shopper, idx: number) => {
		const name = getShopperName(shopper);
		const shopperDTAImage = shopperIdsWithDTAImage?.[shopper.id];
		return (
			<div className={classes.nameRow}>
				{shopperDTAImage ? (
					shopperDTAImage !== USER_DIDNT_INPUT_PHOTO ? (
						<Box
							style={{
								backgroundImage: `url(${getDataURLFromDTAContactImageData(shopperDTAImage)})`,
							}}
							className={classes.shopperPhoto}
						/>
					) : (
						<Icon className={classes.imageWrapper}>
							<PersonIcon width={40} color="#d8d8d8" />
						</Icon>
					)
				) : null}
				<Typography variant="body1" display="inline" className={classes.nameText}>
					{name}
				</Typography>
				<IconButton
					className={classNames(classes.nameRowIcon, classes.trashIcon)}
					onClick={() => openRemoveShopperDialog(shopper)}
					disabled={responsiblePersonId === shopper.id || isSubmitting}
					size="large"
				>
					<RiDeleteBinLine
						color={
							responsiblePersonId === shopper.id
								? theme.palette.action.disabled
								: theme.palette.primary.main
						}
					/>
				</IconButton>
				<IconButton
					className={classes.nameRowIcon}
					onClick={() => goToShopperDetails(idx)}
					disabled={isSubmitting}
					size="large"
				>
					<RiPencilLine />
				</IconButton>
			</div>
		);
	};

	const getLiftTicketName = (ticket: OrderProduct) => {
		const ticketName = getTranslation(ticket.name);
		const keycard = findProductKeycard(products, ticket.id);
		const keycardName = keycard ? ` + ${getTranslation(keycard.name)}` : '';
		return `${ticketName} ${t(
			'tickets.liftTicket',
			'lift ticket',
		)}${keycardName} (${getSkidataSegmentTranslation(ticket.externalSegment, language)})`;
	};

	const renderShopperProducts = (shopperProducts: OrderProduct[]) => {
		const productsWithoutKeycard = shopperProducts.filter((p) => !isKeycardProduct(p));
		return productsWithoutKeycard.map((p) => {
			const { rentalDurationInSeconds, durationType, durationName } = p;
			const duration = { durationInSeconds: rentalDurationInSeconds, durationType, durationName };
			const durationString = getDurationString(duration, 'long', language, t);
			const productNameWithVariant = isLiftTicketProduct(p)
				? getLiftTicketName(p)
				: getProductNameWithVariant(p, language);
			return (
				<div key={p.id} className={classes.shopperProductRow}>
					<Typography variant="body2" display="inline">
						{productNameWithVariant}
					</Typography>
					{!isLiftTicketProduct(p) ? (
						<Typography variant="body2" display="inline">
							{`, ${durationString}`}
						</Typography>
					) : null}
				</div>
			);
		});
	};

	const renderNoProducts = () => (
		<Typography variant="body2" display="inline" className={classes.noProductsText}>
			{t('shopperInfo.shopperHasNoProducts', 'No products selected')}
		</Typography>
	);

	const renderShopperSummary = (shopper: Shopper, idx: number) => {
		const { userProperties } = shopper;
		const shopperProducts = products.filter((p) => shopper.productIds.includes(p.id));
		const liftTicketProduct = filterExistingSkipassProducts(shopperProducts)[0];
		const { liftKeycardNumber } = liftTicketProduct || {};
		const shopperDTAImage = shopperIdsWithDTAImage?.[shopper.id];
		return (
			<div key={idx} className={classes.shopperContainer}>
				{renderNameAndButtonsRow(shopper, idx)}
				{shopperDTAImage === USER_DIDNT_INPUT_PHOTO && (
					<div className={classes.shopperInfoRow}>
						<Typography variant="body2" color="textSecondary">
							{t('shopperInfo.noPhotoProvided', 'No photo provided')}
						</Typography>
					</div>
				)}
				<div className={classes.shopperInfoRow}>
					{renderSegment(shopper)}
					{userProperties && renderShopperProperties(userProperties)}
				</div>
				{liftTicketProduct && (
					<div className={classes.shopperInfoRow}>
						<Typography variant="body2">
							{isValidKeycardNumber(liftTicketProduct)
								? `${t('shopperInfo.keycardId', 'Keycard ID')}: ${SerialNumberUtils.formatToUI(
										liftKeycardNumber!,
								  )}`
								: t('shopperInfo.noKeycardId', 'No keycard ID provided')}
						</Typography>
					</div>
				)}
				{shopperProducts.length > 0 ? renderShopperProducts(shopperProducts) : renderNoProducts()}
				<Divider className={classes.shopperDivider} />
			</div>
		);
	};

	return (
		<Container className={classes.section}>
			<Typography variant="h5">
				{t('shopperInfo.summaryHeader', 'Please, confirm your details.')}
			</Typography>
			{showErrorBanner && (
				<ErrorBanner
					shoppersWithoutProducts={shoppersWithoutProducts}
					unchosenProducts={unchosenProducts}
					lang={language}
					addShopperAndGoToDetails={addShopperAndGoToDetails}
					hasSubmitError={hasSubmitError}
				/>
			)}
			{shoppers
				.slice()
				.sort(sortShoppersByCreationDate)
				.map((shopper, idx) => renderShopperSummary(shopper, idx))}
			<Grid container className={classes.buttonContainer} spacing={2}>
				<Grid item xs={12} md>
					<Button
						variant="contained"
						color="primary"
						className={classes.btn}
						onClick={submitOrderDetails}
						disabled={(showErrorBanner && !hasSubmitError) || isSubmitting}
					>
						{isSubmitting ? (
							<Spinner relative size={32} color="#fff" />
						) : (
							t('common:actions.confirm', 'Confirm')
						)}
					</Button>
				</Grid>
				<Grid item xs={12} md>
					<Button
						variant="outlined"
						color="primary"
						className={classes.btn}
						onClick={goBack}
						disabled={isSubmitting}
					>
						{t('common:actions.back')}
					</Button>
				</Grid>
			</Grid>
			<RemoveShopperDialog
				shopper={shopperToRemove}
				open={dialogOpen}
				closeDialog={closeDialog}
				deleteShopper={deleteShopper}
			/>
		</Container>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		section: {
			paddingTop: theme.spacing(3),
			paddingBottom: theme.spacing(6),
			maxWidth: 600,
		},
		inline: {
			display: 'inline',
		},
		shopperContainer: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
		},
		nameRow: {
			display: 'flex',
		},
		nameRowIcon: {
			padding: 8,
			margin: 'auto 0',
		},
		nameText: {
			fontWeight: 500,
		},
		trashIcon: {
			marginLeft: 'auto',
		},
		shopperInfoRow: {
			marginBottom: theme.spacing(2),
			marginTop: theme.spacing(1),
			color: theme.palette.colors.tundra.main,
		},
		shopperProperties: {
			'&:not(:last-child)::after': {
				content: '"·"',
				marginRight: theme.spacing(0.5),
				marginLeft: theme.spacing(0.5),
				color: theme.palette.text.secondary,
			},
		},
		shopperDivider: {
			marginTop: theme.spacing(4),
		},
		shopperProductRow: {
			marginBottom: theme.spacing(1),
		},
		noProductsText: {
			color: theme.palette.error.main,
		},
		buttonContainer: {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(2),
			textAlign: 'center',
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				justifyContent: 'space-between',
				flexDirection: 'row-reverse',
			},
		},
		btn: {
			paddingTop: theme.spacing(1.5),
			paddingBottom: theme.spacing(1.5),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				margin: 0,
			},
		},
		shopperPhoto: {
			width: 56,
			height: 56,
			backgroundColor: theme.palette.background.secondary,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			marginRight: theme.spacing(1.5),
		},
		keycardId: {
			marginLeft: theme.spacing(2),
			padding: theme.spacing(0.75, 1),
			backgroundColor: theme.palette.background.secondary,
			borderRadius: 3,
		},
		imageWrapper: {
			display: 'flex',
			justifyContent: 'center',
			width: 56,
			height: 56,
			backgroundColor: theme.palette.background.secondary,
			marginRight: theme.spacing(1.5),
		},
	}),
);

export default ShopperSummary;
