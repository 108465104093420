import React from 'react';

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';

interface Props {
	title: string;
	description?: string;
	imgUrl: string;
	linkUrl: string;
	onClick?: () => void;
	imageResize: 'contain' | 'cover';
}

const GridItem = React.memo((props: Props) => {
	const classes = useStyles(props);
	const { title, description, imgUrl, linkUrl, onClick } = props;

	const gridColumns = useSelector(ShopSelectors.onlineLayout)?.productGridColumns?.desktop;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const customGridFontSizeDesktop = gridColumns === 6 && !isMobile;

	return (
		<Link className={classes.wrapper} to={linkUrl} onClick={onClick}>
			<Box className={classes.imageWrapper}>
				<Box className={classes.imageInnerWrapper}>
					<img src={imgUrl} className={classes.image} alt="" />
				</Box>
			</Box>
			<Box py={2}>
				<Typography
					variant={customGridFontSizeDesktop ? 'h6' : 'body1'}
					gutterBottom
					className={classes.productName}
				>
					{title}
				</Typography>
				{description ?? null}
			</Box>
		</Link>
	);
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'block',
			[theme.breakpoints.up('md')]: {
				marginBottom: theme.spacing(2),
			},
			'&:hover *': {
				textDecoration: 'underline',
			},
			'&:hover img': {
				opacity: 0.85,
			},
			'&:focus img': {
				opacity: 0.85,
			},
		},
		imageWrapper: {
			width: '100%',
			padding: (props: Props) => (props.imageResize === 'contain' ? theme.spacing(2) : undefined),
			border: (props: Props) => (props.imageResize === 'contain' ? '1px solid #f8f8f8' : undefined),
		},
		imageInnerWrapper: {
			width: '100%',
			paddingTop: '100%',
			position: 'relative',
		},
		image: {
			width: '100%',
			height: '100%',
			position: 'absolute',
			top: 0,
			left: 0,
			objectPosition: 'center',
			objectFit: (props: Props) => props.imageResize,
		},
		productName: {
			fontWeight: 500,
		},
	}),
);

export default GridItem;
