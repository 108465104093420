import React from 'react';

import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import ticketPlaceholder from 'common/assets/ticket-placeholder.png';
import CenteredContent from 'components/CenteredContent';
import MetaTags from 'components/MetaTags';
import ProductNav from 'components/ProductNav';
import { useTranslation } from 'services/localization';

import ProductDetails from '../Product/ProductDetails';
import ProductMedia from '../Product/ProductMedia';
import TicketsForm from './TicketsForm';

const Tickets = () => {
	const classes = useStyles();
	const { getTranslation } = useTranslation();

	const activeLocation = useSelector(ShopSelectors.activeLocation);
	const liftTicketCategory = useSelector(StockSelectors.liftTicketCategory);

	if (!activeLocation || !liftTicketCategory) return null;

	return (
		<>
			<MetaTags type="category" category={liftTicketCategory} />
			<Box style={{ position: 'relative' }}>
				<ProductNav productName={getTranslation(liftTicketCategory.name)} />
				<CenteredContent paddingBottom={12}>
					<Box className={classes.wrapper}>
						<Box className={classes.left}>
							<Box className={classes.stickyColumn}>
								<ProductMedia
									src={liftTicketCategory.imageUrl ?? ticketPlaceholder}
									alt={getTranslation(liftTicketCategory.name)}
								/>
							</Box>
						</Box>
						<Box p={4} />
						<Box className={classes.right}>
							<ProductDetails liftTicketCategory={liftTicketCategory} />
							<TicketsForm />
						</Box>
					</Box>
				</CenteredContent>
			</Box>
		</>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			paddingTop: theme.spacing(7),
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.up('md')]: {
				flexDirection: 'row',
			},
		},
		left: {
			flex: 1,
		},
		right: {
			flex: 1,
		},
		stickyColumn: {
			[theme.breakpoints.up('md')]: {
				position: 'sticky',
				top: 100,
			},
		},
	}),
);

export default Tickets;
