import { StorageKeys, Storages, getFromStorage, saveToStorage } from 'common/utils/frontUtils';

import { CookieScriptState, RentleCookieConsent } from './types';

export const getRentleCookieConsentForShop = (shopName: string): null | RentleCookieConsent => {
	const consents = getRentleCookieConsents();
	return consents[shopName];
};

const getRentleCookieConsents = () => {
	const oldConsentObjString = getFromStorage(Storages.LOCAL, StorageKeys.COOKIE_CONSENTS);
	return oldConsentObjString ? JSON.parse(oldConsentObjString) ?? {} : {};
};

export const updateRentleCookieConsents = (
	shopName: string,
	consent: CookieScriptState,
	type: 'banner' | 'parameter',
	timestamp: string,
) => {
	const oldConsentObj = getRentleCookieConsents();
	const newConsentObj = {
		...oldConsentObj,
		[shopName]: {
			...consent,
			type,
			timestamp,
		},
	};
	saveToStorage(Storages.LOCAL, StorageKeys.COOKIE_CONSENTS, JSON.stringify(newConsentObj));
};
