import { createReducer } from '@reduxjs/toolkit';
import { Moment } from 'moment-timezone';
import * as AuthActions from 'redux/actions/auth';

import { LoadingData } from 'common/types';
import { AuthOrganisationType, AuthProfile } from 'common/types/external/auth';

export type ClientAuthProfile = Omit<AuthProfile, 'dateOfBirth' | 'businessAccount'> & {
	dateOfBirth?: Moment;
	businessAccount?:
		| undefined
		| { organisationType: AuthOrganisationType | null; organisationName: string };
};

export type AuthState = {
	authToken: string | null;
	authProfile: LoadingData<ClientAuthProfile | null>;
	authError: string | null;
	isNewRegistration: boolean;
};

const INITIAL_STATE: AuthState = {
	authToken: null,
	authProfile: { loading: false, data: null, error: null },
	authError: null,
	isNewRegistration: false,
};

const AuthReducer = createReducer(INITIAL_STATE, (builder) => {
	builder.addCase(AuthActions.setAuthToken, (state, action) => {
		state.authToken = action.payload;
	});
	builder.addCase(AuthActions.setAuthProfile, (state, action) => {
		state.authProfile = action.payload;
	});
	builder.addCase(AuthActions.setAuthError, (state, action) => {
		state.authError = action.payload;
	});
	builder.addCase(AuthActions.setIsNewRegistration, (state, action) => {
		state.isNewRegistration = action.payload;
	});
});

export default AuthReducer;
