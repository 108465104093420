export const RoutePaths = {
	baseRoot: '/',
	shopPathRoot: '/:shopName',
	locationRoot: '/l/:locationName',
	shopPathLocationRoot: '/:shopName/l/:locationName',
	browse: '/shop',
	product: '/product/:productId',
	category: '/categories/:categoryId',
	tickets: '/tickets',
	packageTicket: '/packageticket/:productId',
	locations: '/locations',
	about: '/about',
	checkout: '/checkout',
	confirm: '/confirm',
	confirmDetails: '/confirm/details',
	confirmDetailsInfo: '/confirm/details/info',
	confirmDetailsProducts: '/confirm/details/products',
	confirmDetailsDetails: '/confirm/details/details',
	confirmDetailsPhotoUpload: '/confirm/details/photo',
	confirmDetailsSummary: '/confirm/details/summary',
} as const;

export const getIsStringUsedInRoutes = (text: string) => {
	const fixedRoutePathStrings = Object.values(RoutePaths).flatMap((path) =>
		path.split('/').filter((pathString) => !pathString.startsWith(':')),
	);
	return fixedRoutePathStrings.includes(text);
};
