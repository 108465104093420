import React, { useEffect } from 'react';

import { Box, Divider, Typography } from '@mui/material';
import { countBy } from 'lodash';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import BackdropDialog from 'common/components/BackdropDialog';
import { sendEventToParentPage } from 'common/modules/embed';
import {
	isAutomaticDepositPaymentMethod,
	isDepositPaymentMethod,
} from 'common/modules/payments/paymentMethods';
import { isRunInIframe } from 'common/utils/browserUtils';
import { getPricingString } from 'common/utils/common';
import { useTranslation } from 'services/localization';

interface Props {
	productIds?: string[];
	depositAmount: number;
	isOpen: boolean;
	onClose: () => void;
}
const DepositInfoModal = (props: Props) => {
	const { isOpen, onClose, productIds, depositAmount } = props;

	const { t, getTranslation } = useTranslation();

	const currency = useSelector(ShopSelectors.shopCurrency);
	const productsById = useSelector(StockSelectors.productsById);
	const shopPaymentMethods = useSelector(ShopSelectors.shopPaymentMethods);
	const shopDepositMethods = shopPaymentMethods.map((m) => m.id).filter(isDepositPaymentMethod);
	const onlyAutomaticDepositMethod = shopDepositMethods.every(isAutomaticDepositPaymentMethod);

	useEffect(() => {
		if (!!isOpen && isRunInIframe()) {
			sendEventToParentPage({
				origin: 'rentle',
				type: 'trigger-scroll',
				payload: {
					offset: 0,
				},
			});
		}
	}, [isOpen]);

	const renderDepositInfo = () => {
		if (!productIds?.length) return null;
		const counts = countBy(productIds, (id) => id);

		let total = 0;

		const rows = Object.keys(counts).map((productId) => {
			const count = counts[productId];
			const product = productsById[productId];
			const deposit = product?.terms?.deposit || 0;

			const sum = count * deposit;
			total += sum;

			if (deposit > 0) {
				return (
					<tr key={productId}>
						<td>
							<Typography variant="body2">{getTranslation(product.name)}</Typography>
						</td>
						<td align="right">
							<Typography variant="body2">
								{count} x {getPricingString(deposit, currency)}
							</Typography>
						</td>
					</tr>
				);
			}

			return null;
		});

		return (
			<Box mt={3}>
				<Typography variant="body2">
					{t('deposit.productsWithDeposit', 'Products in bag with deposit:')}
				</Typography>
				<Box mt={2} />
				<Divider />
				<table style={{ borderSpacing: '8px', width: '100%' }}>
					<tbody>{rows}</tbody>
				</table>
				<Divider />
				<table style={{ borderSpacing: '8px', width: '100%' }}>
					<tbody>
						<tr>
							<td></td>
							<td align="right">
								<Typography variant="body2">= {getPricingString(total, currency)}</Typography>
							</td>
						</tr>
					</tbody>
				</table>
			</Box>
		);
	};

	const getBodyText = () => {
		return onlyAutomaticDepositMethod
			? t('common:deposit.depositInfoPayment', {
					deposit: getPricingString(depositAmount, currency),
					time: t('common:deposit.24hBeforeRental', '24 hours before the rental starts'),
					defaultValue:
						'To secure the rental equipment, a security deposit ({{deposit}}) will automatically be reserved as a cover reservation from your payment card latest {{time}}, and released after the rental has ended.',
			  })
			: t('common:deposit.depositInfoReservation', {
					pricingString: getPricingString(depositAmount, currency),
					defaultValue:
						'To secure the rental equipment, we will also reserve a security deposit ({{pricingString}}) for the duration of the rental. It will be released after the rental has ended.',
			  });
	};

	return (
		<BackdropDialog
			variant="primary"
			isOpen={isOpen}
			onClose={onClose}
			position={isRunInIframe() ? 'top' : 'center'}
			label={t('summary.securityDeposit', 'Security deposit')}
			confirm={{
				label: t('common:actions.close'),
			}}
			body={
				<Box>
					<Typography>{getBodyText()}</Typography>
					{renderDepositInfo()}
				</Box>
			}
		/>
	);
};

export default DepositInfoModal;
