import { TFunction } from 'i18next';
import { intersection } from 'lodash';
import moment from 'moment-timezone';

import { DateAndTime, DateFormatObject } from 'common/types';
import { notFalsey, switchUnreachable } from 'common/utils/common';
import { MomentDateFormat, localFormat } from 'common/utils/dateUtils';

import { ALL_WEEKDAYS } from './constants';
import { DayType, ISOString, ISOWeekday, ISOWeekdays, YYYY_MM_DD } from './types';

export const isDateDayType = (date: YYYY_MM_DD, dayType: DayType): boolean => {
	const isoWeekday = moment(date).isoWeekday();
	return DAY_TYPES_TO_NUMBERS[dayType].includes(isoWeekday as ISOWeekday);
};

export const isOverlappingDayType = (dayType: DayType, otherDayType: DayType): boolean => {
	return intersection(DAY_TYPES_TO_NUMBERS[dayType], DAY_TYPES_TO_NUMBERS[otherDayType]).length > 0;
};

// Some dependencies are using a non-standard weekday format (0-6), so for those we need to convert Sundays from 7 to 0.
export const toZeroBasedWeekday = (weekday: ISOWeekday): 0 | 1 | 2 | 3 | 4 | 5 | 6 => {
	return weekday === 7 ? 0 : weekday;
};

/*
 * rotates weekdays such that the first day of the week is the first element in the array
 * e.g. [1, 2, 3, 4, 5, 6, 7] => [1, 2, 3, 4, 5, 6, 7] if firstDayOfWeek is 1
 * e.g. [1, 2, 3, 4, 5, 6, 7] => [2, 3, 4, 5, 6, 7, 1] if firstDayOfWeek is 2
 */
export const localizeWeekdays = (
	weekdays: ISOWeekday[],
	firstDayOfWeek: ISOWeekday,
): ISOWeekday[] => {
	const firstDayIdx = weekdays.findIndex((day) => day === firstDayOfWeek);
	const sortedWeekdays = weekdays.slice().sort();
	return firstDayIdx > 0
		? sortedWeekdays.concat(weekdays).slice(firstDayIdx, firstDayIdx + weekdays.length)
		: sortedWeekdays;
};

export const DAY_TYPES_TO_NUMBERS: Record<DayType, ISOWeekday[]> = {
	monday: [ISOWeekdays.Monday],
	tuesday: [ISOWeekdays.Tuesday],
	wednesday: [ISOWeekdays.Wednesday],
	thursday: [ISOWeekdays.Thursday],
	friday: [ISOWeekdays.Friday],
	saturday: [ISOWeekdays.Saturday],
	sunday: [ISOWeekdays.Sunday],
	weekDays: [
		ISOWeekdays.Monday,
		ISOWeekdays.Tuesday,
		ISOWeekdays.Wednesday,
		ISOWeekdays.Thursday,
		ISOWeekdays.Friday,
	],
	weekends: [ISOWeekdays.Saturday, ISOWeekdays.Sunday],
	allDays: ALL_WEEKDAYS,
};

export const getDayTypeSpecificity = (dayType: DayType): number => {
	switch (dayType) {
		case 'allDays':
			return 0;
		case 'weekends':
		case 'weekDays':
			return 1;
		case 'monday':
		case 'tuesday':
		case 'wednesday':
		case 'thursday':
		case 'friday':
		case 'saturday':
		case 'sunday':
			return 2;
		default:
			return switchUnreachable(dayType);
	}
};

export const getDayTypeLabel = (dayType: DayType, t: TFunction) => {
	switch (dayType) {
		case 'allDays':
			return t('common:dayTypes.allDays', 'All days');
		case 'weekDays':
			return t('common:dayTypes.weekdays', 'Weekdays');
		case 'weekends':
			return t('common:dayTypes.weekend', 'Weekends');
		case 'monday':
			return t('common:dayTypes.monday', 'Monday');
		case 'tuesday':
			return t('common:dayTypes.tuesday', 'Tuesday');
		case 'wednesday':
			return t('common:dayTypes.wednesday', 'Wednesday');
		case 'thursday':
			return t('common:dayTypes.thursday', 'Thursday');
		case 'friday':
			return t('common:dayTypes.friday', 'Friday');
		case 'saturday':
			return t('common:dayTypes.saturday', 'Saturday');
		case 'sunday':
			return t('common:dayTypes.sunday', 'Sunday');
		default:
			try {
				switchUnreachable(dayType);
			} catch (err) {}
			return '';
	}
};

export function clampDate(
	date: ISOString,
	min: ISOString | undefined | null,
	max: ISOString | undefined | null,
): ISOString;
export function clampDate(
	date: YYYY_MM_DD,
	min: YYYY_MM_DD | undefined | null,
	max: YYYY_MM_DD | undefined | null,
): YYYY_MM_DD;
export function clampDate(
	date: ISOString | YYYY_MM_DD,
	min: ISOString | YYYY_MM_DD | undefined | null,
	max: ISOString | YYYY_MM_DD | undefined | null,
): ISOString | YYYY_MM_DD {
	if (!!min && date <= min) return min;
	if (!!max && date >= max) return max;
	return date;
}

export const getDateAndTimeFromISOString = (
	isoString: ISOString,
	timezone: string,
): DateAndTime => {
	const date = moment.tz(isoString, timezone).format('YYYY-MM-DD');
	const time = moment.tz(isoString, timezone).format('HH:mm');
	return { date, time };
};

export const getISOStringFromDateAndTime = (value: DateAndTime, timezone: string): ISOString => {
	const { date, time } = value;
	const [hours, minutes] = time.split(':').map((val) => Number(val));
	return moment.tz(date, timezone).startOf('day').set({ hours, minutes }).toISOString();
};

export const formatDateRange = (
	startDate: ISOString | null,
	endDate: ISOString | null,
	opts: {
		dateFormatObject: DateFormatObject;
	},
) => {
	const { dateFormatObject } = opts;

	const getDateFormat = (date: ISOString): MomentDateFormat => {
		if (moment(date).isSame(moment(), 'year')) {
			return 'MMM DD, HH:mm';
		}

		return 'MMM DD, YYYY HH:mm';
	};

	if (!!startDate && !!endDate) {
		if (moment(startDate).isSame(endDate, 'day')) {
			return `${localFormat(
				moment(startDate),
				getDateFormat(startDate),
				dateFormatObject,
			)} - ${localFormat(moment(endDate), 'HH:mm', dateFormatObject)}`;
		}

		return `${localFormat(
			moment(startDate),
			getDateFormat(startDate),
			dateFormatObject,
		)} - ${localFormat(moment(endDate), getDateFormat(endDate), dateFormatObject)}`;
	}

	if (!!startDate) {
		return localFormat(moment(startDate), getDateFormat(startDate), dateFormatObject);
	}

	if (!!endDate) {
		return localFormat(moment(endDate), getDateFormat(endDate), dateFormatObject);
	}

	return '';
};

/**
 * Format an amount of months to a more human string like "1 year 2 months" or "2 months"
 *
 * @param months The amount of months
 * @param t A t function
 * @returns The formatted string
 */
export const formatMonths = (months: number, t: TFunction): string => {
	if (months === 0) return `0 ${t('common:times.months', 'months')}`;
	const monthsRemainder = months % 12;
	const yearsRemainder = Math.floor(months / 12);
	const monthsString = (() => {
		if (monthsRemainder === 0) return '';
		if (monthsRemainder === 1) return `${monthsRemainder} ${t('common:times.month', 'month')}`;
		return `${monthsRemainder} ${t('common:times.months', 'months')}`;
	})();

	const yearsString = (() => {
		if (yearsRemainder === 0) return '';
		if (yearsRemainder === 1) return `${yearsRemainder} ${t('common:times.year', 'year')}`;
		return `${yearsRemainder} ${t('common:times.years', 'years')}`;
	})();

	return [yearsString, monthsString].filter(notFalsey).join(' ');
};

/**
 * Check whether two date ranges overlap
 *
 * @param aStart The start of the first date range
 * @param aEnd The end of the first date range
 * @param bStart The start of the second date range
 * @param bEnd The end of the second date range
 * @returns A boolean indicating whether the two date ranges overlap
 */
export const doesDateRangeOverlap = (
	aStart: string,
	aEnd: string,
	bStart: string,
	bEnd: string,
): boolean => {
	const aStartsAfterB = aStart >= bEnd && bStart < aStart;
	const aEndsBeforeB = aEnd <= bStart && aStart < bStart;
	return !aStartsAfterB && !aEndsBeforeB;
};
