import React from 'react';

import { Divider, Stack } from '@mui/material';
import { TFunction } from 'i18next';
import { without } from 'lodash';
import moment from 'moment-timezone';

import { PricingRow } from 'common/components/molecules/PricingRow';
import { PaymentAmounts } from 'common/modules/atoms/paymentAmounts';
import {
	ItemPricing,
	ItemPricingWithoutCurrency,
	OrderPricing,
} from 'common/modules/atoms/pricing';
import { getCombinedTaxLines, getDefaultTaxLabel } from 'common/modules/atoms/taxes';
import { getTranslation } from 'common/modules/translations';
import { CurrencyObject, EnumKeys, Languages, YYYY_MM_DD } from 'common/types';
import { getPricingString } from 'common/utils/common';
import { getCurrencyObjectFromCurrencyCode } from 'common/utils/currencyUtils';
import { stringEnum } from 'common/utils/objects';

const PriceRows = stringEnum([
	'manualDiscount',
	'discountCodes',
	'subtotal',
	'taxes',
	'total',
	'deposit',
	'paid',
	'includedTax',
	'nextInstalment',
	'recurring',
]);

type PriceRow = EnumKeys<typeof PriceRows>;

export const AllPriceRows: PriceRow[] = Object.values(PriceRows);
export const DefaultPriceRows: PriceRow[] = without(AllPriceRows, 'paid', 'includedTax');

interface Props {
	pricing: OrderPricing | ItemPricing | ItemPricingWithoutCurrency;
	recurring?: ItemPricing | ItemPricingWithoutCurrency | null;
	nextInstalment?: { date: YYYY_MM_DD; amount: ItemPricing } | null;
	shopCurrency: CurrencyObject;
	payment?: PaymentAmounts;
	classes?: {
		textDefault?: string;
		textBold?: string;
		textCaption?: string;
	};
	labels?: {
		total?: string;
	};
	options?: {
		/**
		 * If true, different tax lines will be shown as separate lines
		 */
		showSeparateTaxLines?: boolean;
		/**
		 * If true, dividers between the price breakdown sections will not be rendered
		 */
		noDividers?: boolean;
	};
	/**
	 * If provided, only these rows will be shown instead of DefaultPriceRows.
	 */
	rows?: PriceRow[];
	t: TFunction;
	lang: Languages;
}

const getManualDiscount = (
	pricing: OrderPricing | ItemPricing | ItemPricingWithoutCurrency,
): number => {
	if (typeof pricing.manualDiscount === 'number') {
		return pricing.manualDiscount;
	}
	return pricing.manualDiscount?.amount || 0;
};

const getDeposit = (pricing: OrderPricing | ItemPricing | ItemPricingWithoutCurrency): number => {
	if (typeof pricing.deposit === 'number') {
		return pricing.deposit;
	}
	return pricing.deposit?.value || 0;
};

export const PriceBreakdown = (props: Props) => {
	const {
		pricing,
		recurring,
		nextInstalment,
		shopCurrency,
		t,
		options = {},
		payment,
		lang,
		classes,
		labels,
	} = props;

	const rows = (() => {
		if (!!props.rows) return props.rows;
		if (!!payment) return [...DefaultPriceRows, 'paid'];
		return DefaultPriceRows;
	})();

	const { total, subtotal, taxLines: _taxLines, discountCodes, taxExcluded } = pricing;
	const { showSeparateTaxLines = false, noDividers = false } = options;
	const manualDiscount = getManualDiscount(pricing);
	const deposit = getDeposit(pricing);
	const currency = pricing.currency
		? getCurrencyObjectFromCurrencyCode(pricing.currency, shopCurrency)
		: undefined;
	const taxLines = !showSeparateTaxLines ? getCombinedTaxLines(_taxLines) : _taxLines;

	const renderSubtotalSection = () => {
		const items = [];

		if (!!manualDiscount && rows.includes(PriceRows.manualDiscount)) {
			items.push(
				<PricingRow
					key="discount"
					label={t('common:discount', 'Discount')}
					value={-manualDiscount}
					currency={currency}
					variant="default"
					className={classes?.textDefault}
				/>,
			);
		}

		if (!!discountCodes && rows.includes(PriceRows.discountCodes)) {
			items.push(
				...Object.entries(discountCodes).map(([code, value]) =>
					value.totalDiscountValue ? (
						<PricingRow
							key={`discountCode_${code}`}
							label={code}
							value={-value.totalDiscountValue}
							currency={currency}
							variant="default"
							className={classes?.textDefault}
						/>
					) : null,
				),
			);
		}

		if ((!!taxExcluded && subtotal !== total) || !rows.includes(PriceRows.total)) {
			if (rows.includes(PriceRows.subtotal)) {
				items.push(
					<PricingRow
						key="subtotal"
						label={t('common:payment.subtotal', 'Subtotal')}
						value={subtotal}
						currency={currency}
						variant="default"
						className={classes?.textDefault}
					/>,
				);
			}

			if (rows.includes(PriceRows.taxes)) {
				items.push(
					...taxLines.map((taxLine) => (
						<PricingRow
							key={taxLine.label?.def ?? '' + taxLine.rate}
							label={
								taxLine.label
									? getTranslation(taxLine.label, lang)
									: getDefaultTaxLabel(taxLine.rate, t)
							}
							value={taxLine.price}
							currency={currency}
							variant="default"
							className={classes?.textDefault}
						/>
					)),
				);
			}
		}

		return items.length > 0 ? (
			<Stack direction="column" spacing={0.5}>
				{items}
			</Stack>
		) : null;
	};

	const renderTotalSection = () => {
		const items = [];
		if (!!rows.includes(PriceRows.total)) {
			items.push(
				<PricingRow
					key="total"
					label={labels?.total ?? t('common:payment.total', 'Total')}
					value={total}
					currency={currency}
					variant="bold"
					className={classes?.textBold}
				/>,
			);
		}

		if (!pricing.taxExcluded && !!rows.includes(PriceRows.includedTax) && !!pricing.totalTaxes) {
			items.push(
				<PricingRow
					key="includedTax"
					label={t('common:taxes.includingTaxes', 'Including tax')}
					value={pricing.totalTaxes}
					currency={currency}
					variant="caption"
					className={classes?.textCaption}
				/>,
			);
		}

		if (!!deposit && rows.includes(PriceRows.deposit)) {
			items.push(
				<PricingRow
					key="deposit"
					label={`+ ${t('common:payment.deposit', 'Deposit')}`}
					value={deposit}
					currency={currency}
					variant="caption"
					className={classes?.textCaption}
				/>,
			);
		}

		return items.length > 0 ? (
			<Stack direction="column" spacing={0.5}>
				{items}
			</Stack>
		) : null;
	};

	const renderPaidSection = () => {
		const items = [];

		if (!!rows.includes('paid') && !!payment?.paid) {
			items.push(
				<PricingRow
					key="paid"
					label={t('common:payment.paid', 'Paid')}
					value={payment.paid}
					currency={currency}
					variant="default"
					className={classes?.textDefault}
				/>,
			);
		}

		if (!!rows.includes('paid') && !!payment?.unpaid) {
			items.push(
				<PricingRow
					key="leftToPay"
					label={t('common:payment.leftToPay', 'Left to pay')}
					value={payment.unpaid}
					currency={currency}
					variant="default"
					className={classes?.textDefault}
				/>,
			);
		}

		if (!!rows.includes('nextInstalment') && !!nextInstalment) {
			items.push(
				<PricingRow
					key="nextInstalment"
					label={
						t('common:payment.nextInstalment', 'Next payment on') +
						' ' +
						moment(nextInstalment.date).format('Do MMMM YYYY')
					}
					value={nextInstalment.amount.total}
					currency={currency}
					formatValue={getPricingString}
					variant="caption"
					className={classes?.textCaption}
				/>,
			);
		}

		if (!!rows.includes('recurring') && !!recurring?.total) {
			items.push(
				<PricingRow
					key="recurring"
					label={t('common:payment.recurring', 'Recurring')}
					value={recurring.total}
					currency={currency}
					formatValue={(value, currency) => {
						return t('common:times.perMonth', {
							value: getPricingString(value, currency),
							defaultValue: '{{value}} /month',
						});
					}}
					variant="caption"
					className={classes?.textCaption}
				/>,
			);
		}

		return items.length > 0 ? (
			<Stack direction="column" spacing={0.5}>
				{items}
			</Stack>
		) : null;
	};

	return (
		<Stack direction="column" spacing={1} divider={!noDividers ? <Divider /> : null}>
			{renderSubtotalSection()}
			{renderTotalSection()}
			{renderPaidSection()}
		</Stack>
	);
};

export default PriceBreakdown;
