import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import * as CartSelectors from 'redux/selectors/cart';

import Divider from 'common/components/Divider';
import { useTranslation } from 'services/localization';

import CartDeliveryRow from './CartDeliveryRow';
import CartProductsRows from './CartProductsRows';

const CartProducts = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	const itemsWithoutKeycard = useSelector(CartSelectors.itemsWithoutKeycard);
	const cartDelivery = useSelector(CartSelectors.delivery);
	const earliestStartDate = useSelector(CartSelectors.startDate);
	const latestEndDate = useSelector(CartSelectors.latestEndDate);

	if (itemsWithoutKeycard.length === 0) {
		return (
			<Box className={classes.emptyWrapper}>
				<Typography variant="h6" align="center">
					{t('Cart.empty', 'Your bag is empty')}
				</Typography>
			</Box>
		);
	}

	return (
		<Box className={classes.wrapper}>
			<Box className={classes.products}>
				<CartProductsRows rows={itemsWithoutKeycard} />
			</Box>
			{!!cartDelivery && (
				<>
					<Divider />
					<CartDeliveryRow
						delivery={cartDelivery}
						startDate={earliestStartDate}
						endDate={latestEndDate}
						hideSelfReturn
					/>
				</>
			)}
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		emptyWrapper: {
			minHeight: 200,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		wrapper: {
			minHeight: 200,
			display: 'flex',
			flexDirection: 'column',
		},
		products: {
			flex: 1,
		},
		deposit: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: theme.spacing(1, 0),
		},
		total: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			padding: theme.spacing(1, 0),
		},
		totalText: {
			fontSize: '1.8rem',
		},
	}),
);

export default CartProducts;
