import { useCallback, useEffect, useMemo, useState } from 'react';

import { TFunction } from 'i18next';
import { chain, groupBy, isEmpty, sumBy } from 'lodash';
import { useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import useDebouncedEffect from 'common/hooks/useDebouncedEffect';
import { SkidataKeycardType, SkidataPackageProductApi } from 'common/modules/skidata';
import { ById, CurrencyObject, ISOString, LoadingData, ProductApi } from 'common/types';
import { hashByUniqueField } from 'common/utils/arrays';
import { notUndefined } from 'common/utils/common';
import { useTranslation } from 'services/localization';
import { useRoutes } from 'services/routing/useRoutes';
import { isLiftTicketStartDateExpired } from 'utils/cart';
import {
	fetchOrderPrice,
	getTicketValidityAsMinMaxDateAndValidWeekdays,
	isDateOutsideOfValidity,
} from 'utils/liftTicket';

export type PackageLiftTicketItem = {
	externalId: string;
	externalSegmentId: string;
	quantity: {
		min: number;
		max: number;
		selected?: number;
	};
};

export interface PackageLiftTicketPricingInfo {
	priceBySegment: ById<{ basePrice: number; bestPrice: number }>;
	pricePerKeycard: number;
	total: number;
}

type PackageLiftTicketFormState = {
	externalPackageId: string;
	externalId: string | null;
	startDate: ISOString | null;
	packageItems: PackageLiftTicketItem[];
	withNewKeycard: boolean;
};

export type ValidatedPackageLiftTicketFormState = {
	externalPackageId: string;
	withNewKeycard: boolean;
	startDate: ISOString;
	packageItems: PackageLiftTicketItem[];
	pricingInfo: PackageLiftTicketPricingInfo;
};

type PackageLiftTicketFormErrors = {
	withNewKeycard?: string | null;
	startDate?: string | null;
	externalId?: string | null;
	minItemQuantity?: string | null;
};

interface LiftTicketForm {
	values: PackageLiftTicketFormState;
	errors: PackageLiftTicketFormErrors;
	actions: {
		setExternalId: (externalId: string | null) => void;
		setPackageItemQuantity: (externalSegmentId: string, quantity: number) => void;
		setWithNewKeycard: (value: boolean) => void;
		setStartDate: (value: ISOString | null) => void;
		reset: () => void;
		validate: () => {
			valid: boolean;
			value?: ValidatedPackageLiftTicketFormState;
			errors?: string[];
		};
	};
	meta: {
		product: SkidataPackageProductApi | undefined;
		ticketTypeOptions: ProductApi[];
		totalTicketCount: number;
		pricingInfo: LoadingData<PackageLiftTicketPricingInfo | null>;
		currency: CurrencyObject;
		validity: { minDate: ISOString; maxDate: ISOString } | null;
	};
}

const validateStartDate = (
	startDate: PackageLiftTicketFormState['startDate'],
	t: TFunction,
	validity?: { minDate: ISOString; maxDate: ISOString } | null,
): string | null => {
	if (!startDate) {
		return t('LiftTicketForm.pleaseChooseStartTime', 'Please choose a start time');
	}

	if (isLiftTicketStartDateExpired(startDate)) {
		return t(
			'LiftTicketForm.startTimeInPast',
			'The start time you have selected is in the past - please choose a new start time',
		);
	}

	if (!!validity && isDateOutsideOfValidity(startDate, validity)) {
		return t(
			'LiftTicketForm.ticketOutsideOfValidity',
			'Not available for the selected date - please choose a new start time',
		);
	}

	return null;
};

const validateMinItemQuantity = (
	totalItemQuantity: number,
	minItemQuantity: number,
	t: TFunction,
): string | null => {
	if (totalItemQuantity < minItemQuantity) {
		return minItemQuantity === 1
			? t('LiftTicketForm.minPackageItemQuantity', {
					minItemQuantity,
					defaultValue: 'Select at least {{minItemQuantity}} ticket',
			  })
			: t('LiftTicketForm.minPackageItemQuantityPlural', {
					minItemQuantity,
					defaultValue: 'Select at least {{minItemQuantity}} tickets',
			  });
	}
	return null;
};

const getInitialPackageItemState = (item: PackageLiftTicketItem) => {
	return {
		...item,
		quantity: {
			...item.quantity,
			selected: item.quantity.min,
		},
	};
};

const usePackageLiftTicketForm = (args: {
	packageProduct: SkidataPackageProductApi;
}): LiftTicketForm => {
	const { replaceRoute, Routes } = useRoutes();
	const { t } = useTranslation();

	const { packageProduct } = args;

	const startDateFromCart = useSelector(CartSelectors.liftTicketsStartDate);
	const keycardProduct = useSelector(StockSelectors.keycardProduct);
	const keycardTypeFromCart = useSelector(CartSelectors.keycardTypeFromCart);
	const allTickets = useSelector(StockSelectors.liftTicketProductsForPackages);

	const currency = useSelector(ShopSelectors.shopCurrency);
	const shopId = useSelector(ShopSelectors.shopId);

	const keycardPrice = keycardProduct?.rentals?.basePrice ?? 0;

	const packageProductExternalId = packageProduct?.externalId;

	const validity = getTicketValidityAsMinMaxDateAndValidWeekdays(packageProduct);

	const packageItems = useMemo(() => {
		return packageProduct?.packageItems ?? [];
	}, [packageProduct]);

	const packageItemsByExternalId = useMemo(() => {
		return groupBy(packageItems, (i) => i.externalId);
	}, [packageItems]);

	const allTicketsById = useMemo(() => {
		return hashByUniqueField(allTickets, 'externalId');
	}, [allTickets]);

	const ticketTypeOptions = useMemo(() => {
		return chain(packageItems)
			.map((i) => allTicketsById[i.externalId])
			.filter(notUndefined)
			.uniqBy((i) => i.externalId)
			.value();
	}, [packageItems, allTicketsById]);

	const [priceBySegment, setPriceBySegment] = useState<
		LoadingData<ById<{ basePrice: number; bestPrice: number }> | null>
	>({
		loading: true,
		error: null,
		data: null,
	});

	const initialFormState: PackageLiftTicketFormState = useMemo(() => {
		const externalId = ticketTypeOptions[0]?.externalId ?? null;
		return {
			withNewKeycard: keycardTypeFromCart !== SkidataKeycardType.EXISTING,
			startDate: startDateFromCart,
			externalPackageId: packageProduct.externalId!,
			externalId,
			packageItems:
				packageItemsByExternalId[externalId ?? '']?.map(getInitialPackageItemState) ?? [],
		};
	}, [
		keycardTypeFromCart,
		packageItemsByExternalId,
		startDateFromCart,
		packageProduct.externalId,
		ticketTypeOptions,
	]);

	const [formState, setFormState] = useState<PackageLiftTicketFormState>(initialFormState);
	const [formErrors, setFormErrors] = useState<PackageLiftTicketFormErrors>({});

	const totalTicketCount = useMemo(() => {
		return sumBy(formState.packageItems, (item) =>
			Number(item.quantity.selected ?? item.quantity.min),
		);
	}, [formState.packageItems]);

	const totalItemsSelected = sumBy(
		formState.packageItems,
		(p) => p.quantity.selected ?? p.quantity.min,
	);
	const isEnoughItemsSelected = totalItemsSelected >= packageProduct.minItemQuantity;

	const pricingInfo: LoadingData<PackageLiftTicketPricingInfo | null> = useMemo(() => {
		if (priceBySegment.loading) {
			return {
				loading: true,
				error: null,
				data: null,
			};
		}

		if (priceBySegment.error) {
			return {
				loading: false,
				error: priceBySegment.error,
				data: null,
			};
		}

		if (priceBySegment.data === null) {
			return {
				loading: false,
				error: null,
				data: null,
			};
		}

		const hasAllSegmentPrices = formState.packageItems.every((item) =>
			notUndefined(priceBySegment.data?.[item.externalSegmentId]),
		);

		if (!hasAllSegmentPrices) {
			return {
				loading: false,
				error: 'No price for segment found',
				data: null,
			};
		}

		const total = formState.packageItems.reduce((result, item) => {
			const quantity = item.quantity.selected ?? 0;
			const ticketPrice = priceBySegment.data![item.externalSegmentId].bestPrice;
			const ticketPriceWithKeycard = formState.withNewKeycard
				? ticketPrice + keycardPrice
				: ticketPrice;
			return result + quantity * ticketPriceWithKeycard;
		}, 0);

		return {
			loading: false,
			error: null,
			data: {
				priceBySegment: priceBySegment.data ?? {},
				pricePerKeycard: keycardPrice,
				total,
			},
		};
	}, [priceBySegment, formState.packageItems, keycardPrice, formState.withNewKeycard]);

	const setExternalId = useCallback(
		(externalId: string | null) => {
			setFormState((prev) => ({
				...prev,
				externalId,
				packageItems:
					packageItemsByExternalId[externalId ?? '']?.map(getInitialPackageItemState) ?? [],
			}));
		},
		[packageItemsByExternalId],
	);

	const setWithNewKeycard = useCallback((withNewKeycard: boolean) => {
		setFormState((prev) => ({
			...prev,
			withNewKeycard,
		}));
	}, []);

	const setPackageItemQuantity = useCallback((externalSegmentId: string, quantity: number) => {
		setFormState((prev) => ({
			...prev,
			packageItems:
				prev.packageItems.map((i) => {
					if (i.externalSegmentId === externalSegmentId) {
						return {
							...i,
							quantity: {
								...i.quantity,
								selected: quantity,
							},
						};
					} else {
						return i;
					}
				}) ?? [],
		}));
	}, []);

	const setStartDate = useCallback(
		async (startDate: ISOString | null) => {
			if (formErrors.startDate) {
				setFormErrors((prev) => ({
					...prev,
					startDate: validateStartDate(startDate, t, validity),
				}));
			}
			setFormState((prev) => ({
				...prev,
				startDate,
			}));
		},
		[formErrors.startDate, t, validity],
	);

	const updatePriceBySegment = useCallback(async () => {
		setPriceBySegment({
			loading: true,
			error: null,
			data: null,
		});
		const orderItems = formState.packageItems.map((packageItem) => {
			return {
				externalId: packageItem.externalId,
				externalSegmentId: packageItem.externalSegmentId,
				quantity: Number(packageItem.quantity.selected ?? packageItem.quantity.min),
			};
		});

		const date = formState.startDate;

		if (!date || !orderItems?.length || !packageProductExternalId || !isEnoughItemsSelected) {
			setPriceBySegment({
				loading: false,
				error: null,
				data: null,
			});
			return;
		}

		try {
			const result = await fetchOrderPrice({
				shopId,
				currencyCode: currency.code,
				date,
				externalId: packageProductExternalId,
				orderItems,
			});

			const data = Object.entries(result.packageItemPrices).reduce(
				(result, [segmentId, prices]) => {
					result[segmentId] = {
						basePrice: Number(prices.basePrice) * 100,
						bestPrice: Number(prices.bestPrice) * 100,
					};
					return result;
				},
				{} as ById<{ basePrice: number; bestPrice: number }>,
			);

			setPriceBySegment({
				loading: false,
				error: null,
				data,
			});
		} catch (err) {
			setPriceBySegment({
				loading: false,
				error: err.message,
				data: null,
			});
		}
	}, [
		formState.packageItems,
		formState.startDate,
		packageProductExternalId,
		isEnoughItemsSelected,
		shopId,
		currency.code,
	]);

	useEffect(() => {
		setPriceBySegment({
			loading: true,
			error: null,
			data: null,
		});
	}, [updatePriceBySegment]);

	useDebouncedEffect(
		() => {
			updatePriceBySegment();
		},
		1000,
		[updatePriceBySegment],
	);

	const validate = useCallback(() => {
		if (!pricingInfo.data) {
			return {
				valid: false,
				errors: [],
			};
		}

		const startDateError = validateStartDate(formState.startDate, t, validity);

		const errors: PackageLiftTicketFormErrors = {
			...(startDateError && { startDate: startDateError }),
		};
		setFormErrors(errors);

		if (isEmpty(errors)) {
			return {
				valid: true,
				value: {
					externalPackageId: formState.externalPackageId,
					withNewKeycard: formState.withNewKeycard,
					startDate: formState.startDate!,
					packageItems: formState.packageItems,
					pricingInfo: pricingInfo.data,
				},
			};
		}

		return {
			valid: false,
			errors: Object.keys(errors),
		};
	}, [formState, pricingInfo, t, validity]);

	const reset = useCallback(() => {
		replaceRoute(Routes.packageTicket);
		setFormState(initialFormState);
		setFormErrors({});
	}, [initialFormState, Routes.packageTicket, replaceRoute]);

	const errors = {
		...formErrors,
		minItemQuantity: validateMinItemQuantity(totalItemsSelected, packageProduct.minItemQuantity, t),
	};

	return {
		values: formState,
		errors,
		actions: {
			setExternalId,
			setPackageItemQuantity,
			setWithNewKeycard,
			setStartDate,
			reset,
			validate,
		},
		meta: {
			product: packageProduct,
			ticketTypeOptions,
			totalTicketCount,
			pricingInfo: isEnoughItemsSelected
				? pricingInfo
				: { loading: false, error: null, data: null },
			currency,
			validity,
		},
	};
};

export default usePackageLiftTicketForm;
