import { ItemListSelection } from 'redux/reducers/analytics';
import * as CartSelectors from 'redux/selectors/cart';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';
import { ReduxThunkAction } from 'redux/types';

import { Item, logEcomEvent } from 'common/services/analytics';
import { centsToMajorUnits } from 'common/utils/common';
import { isLiftTicketCategory } from 'common/utils/liftTicketUtils';
import { removeNonNumeric } from 'common/utils/math';
import { getLowestPriceFromStockProduct } from 'common/utils/pricing';
import {
	getAnalyticsItemFromLiftTicket,
	getAnalyticsItemFromStockProduct,
	getItemListInfo,
	getRecommendedItemListInfo,
} from 'utils/analytics';
import { getLiftTicketsFromPriceString } from 'utils/liftTicket';

import { EcomEventParams } from '..';
import { EcomEvents } from '../constants';

const eventName = EcomEvents.view_item_list;

export const logViewItemList = (params: EcomEventParams[typeof eventName]): ReduxThunkAction => (
	_,
	getState,
) => {
	const state = getState();
	const shopGoogleAnalytics = ShopSelectors.shopGoogleAnalytics(state);
	const categoriesById = StockSelectors.categoriesById(state);
	const sortedGridProducts = StockSelectors.sortedProducts(state);
	const currency = ShopSelectors.shopCurrency(state);
	const pricingTables = ShopSelectors.shopPricingTables(state);
	const openingHours = ShopSelectors.activeStoreOpeningHours(state);
	const timezone = ShopSelectors.shopTimezone(state);
	const activeLocation = ShopSelectors.activeLocation(state);
	const selectedCategories = StockSelectors.selectedCategories(state);
	const liftTicketCategory = StockSelectors.liftTicketCategory(state);
	const liftTicketProducts = StockSelectors.liftTicketProducts(state);
	const recommendedProducts = CartSelectors.recommendedProducts(state);
	const recommendLiftTicket = CartSelectors.recommendLiftTicket(state);

	const getCategoryItemListParams = (categoryId: string | null) => {
		const { item_list_id, item_list_name } = getItemListInfo(categoryId, categoriesById);
		let productPosition = 0;
		const items: Item[] = selectedCategories.flatMap((category) => {
			if (isLiftTicketCategory(category)) {
				productPosition++;
				const liftTicketFromPrice = getLiftTicketsFromPriceString(liftTicketProducts, currency);
				const itemListSelection: ItemListSelection = {
					name: item_list_name,
					id: item_list_id,
					index: productPosition,
				};
				return [
					getAnalyticsItemFromLiftTicket(category, {
						priceInMajorUnits: {
							value: removeNonNumeric(liftTicketFromPrice),
							currency: currency.code,
						},
						location: activeLocation,
						itemListSelectionById: { [category.id]: itemListSelection },
					}),
				];
			} else {
				const categoryProducts = sortedGridProducts.filter((p) =>
					p.categoryIds?.includes(category.id),
				);
				return categoryProducts.map((product) => {
					productPosition++;
					const price = getLowestPriceFromStockProduct({
						product,
						pricingTables,
						openingHours,
						startDate: null,
						channel: 'ONLINE',
						timezone,
					});
					const itemListSelection: ItemListSelection = {
						name: item_list_name,
						id: item_list_id,
						index: productPosition,
					};
					return getAnalyticsItemFromStockProduct(product, {
						priceInMajorUnits: { value: centsToMajorUnits(price ?? 0), currency: currency.code },
						categoriesById,
						location: activeLocation,
						itemListSelectionById: { [product.id]: itemListSelection },
					});
				});
			}
		});
		return {
			item_list_name,
			item_list_id,
			items,
		};
	};

	const getRecommendedItemListParams = () => {
		const { item_list_id, item_list_name } = getRecommendedItemListInfo();
		let productPosition = 0;
		let items: Item[] = [];
		if (recommendLiftTicket && liftTicketCategory) {
			productPosition++;
			const liftTicketFromPrice = getLiftTicketsFromPriceString(liftTicketProducts, currency);
			const itemListSelection: ItemListSelection = {
				name: item_list_name,
				id: item_list_id,
				index: productPosition,
			};
			items.push(
				getAnalyticsItemFromLiftTicket(liftTicketCategory, {
					priceInMajorUnits: {
						value: removeNonNumeric(liftTicketFromPrice),
						currency: currency.code,
					},
					location: activeLocation,
					itemListSelectionById: { [liftTicketCategory.id]: itemListSelection },
				}),
			);
		}
		for (const product of recommendedProducts) {
			productPosition++;
			const price = getLowestPriceFromStockProduct({
				product,
				pricingTables,
				openingHours,
				startDate: null,
				channel: 'ONLINE',
				timezone,
			});
			const itemListSelection: ItemListSelection = {
				name: item_list_name,
				id: item_list_id,
				index: productPosition,
			};
			const item = getAnalyticsItemFromStockProduct(product, {
				priceInMajorUnits: { value: centsToMajorUnits(price ?? 0), currency: currency.code },
				categoriesById,
				location: activeLocation,
				itemListSelectionById: { [product.id]: itemListSelection },
			});
			items.push(item);
		}
		return {
			item_list_name,
			item_list_id,
			items,
		};
	};

	const { item_list_id, item_list_name, items } = !!params.isRecommendedList
		? getRecommendedItemListParams()
		: getCategoryItemListParams(params.categoryId);
	logEcomEvent(
		{ name: eventName, params: { item_list_id, item_list_name, items } },
		shopGoogleAnalytics,
	);
};
