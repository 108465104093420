import React from 'react';

import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import { useSelector } from 'redux/hooks';
import * as ShopSelectors from 'redux/selectors/shop';

import { DurationType } from 'common/types';
import { getNewEndTime } from 'common/utils/dateCalculations';
import useShopFormat from 'hooks/useShopFormat';
import { Trans, useTranslation } from 'services/localization';

interface Props {
	startDate: string | null;
	durationInSeconds: number;
	durationType: DurationType;
	showOnlyStartDate?: boolean;
	showPrompt?: boolean;
}

const BookingDatesSummary = React.memo((props: Props) => {
	const { startDate, durationInSeconds, durationType, showPrompt, showOnlyStartDate } = props;
	const { t } = useTranslation();
	const classes = useStyles();
	const { localFormat } = useShopFormat();

	const openingHours = useSelector(ShopSelectors.activeStoreOpeningHours);
	const shopTimezone = useSelector(ShopSelectors.shopTimezone);

	const endDate = startDate
		? getNewEndTime(
				startDate,
				{
					durationInSeconds,
					durationType,
					durationName: null,
				},
				openingHours,
				shopTimezone,
		  )
		: null;

	const currentYear = moment().year();
	const isEndDateCurrentYear = moment(endDate).year() === currentYear;

	return !startDate || !endDate || !durationInSeconds ? (
		showPrompt ? (
			<Box className={classes.timeDescription}>
				<Typography variant="caption">
					{t('common:prompts.selectStartTime', 'Select a start time')}
				</Typography>
			</Box>
		) : null
	) : (
		<Box className={classes.timeDescription}>
			<Typography variant="caption">
				{!!showOnlyStartDate ? (
					<Trans
						i18nKey="common:booking.bookingStartDateDescription"
						defaults="Your booking will start <start>{{startDate}}</start>"
						values={{
							startDate: localFormat(
								moment(startDate),
								isEndDateCurrentYear ? 'dddd DD.MM. HH:mm' : 'dddd DD.MM. HH:mm YYYY',
							),
						}}
						components={{
							start: <strong />,
						}}
					/>
				) : (
					<Trans
						i18nKey="common:booking.bookingDatesDescription"
						defaults="Your booking will start <start>{{startDate}}</start> and end <end>{{endDate}}</end>"
						values={{
							startDate: localFormat(
								moment(startDate),
								isEndDateCurrentYear ? 'dddd DD.MM. HH:mm' : 'dddd DD.MM. HH:mm YYYY',
							),
							endDate: localFormat(
								moment(endDate),
								isEndDateCurrentYear ? 'dddd DD.MM. HH:mm' : 'dddd DD.MM. HH:mm YYYY',
							),
						}}
						components={{
							start: <strong />,
							end: <strong />,
						}}
					/>
				)}
			</Typography>
		</Box>
	);
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		timeDescription: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			textAlign: 'center',
		},
	}),
);

export default BookingDatesSummary;
