import {
	countBy,
	difference,
	groupBy,
	isEmpty,
	isEqual,
	map,
	omit,
	range,
	sortBy,
	uniq,
} from 'lodash';
import moment from 'moment-timezone';

import {
	AvailabilityRange,
	mergeAvailabilityRangeToRanges,
} from 'common/api/frontend/inventory_new/availabilityRange';
import { SELF_RETURN_CARRIER } from 'common/modules/delivery/constants';
import { getDeliveryInventoryBlockersAfter } from 'common/modules/delivery/utils';
import { ProductVariant } from 'common/modules/inventory';
import { getInventoryBlocker } from 'common/modules/inventoryBlockers';
import { OpeningHours } from 'common/modules/openingHours';
import { isRentalPurchaseType, isSalesPurchaseType } from 'common/modules/orders';
import { getProductsInPackage } from 'common/modules/products/utils';
import {
	getProductVariants,
	getVariantsBySkuId,
	hasMultipleVariants,
} from 'common/modules/products/variants';
import {
	ProductSubscription,
	getSubscriptionCommittedDate,
	isAutoRenewSubscriptionOrderProduct,
} from 'common/modules/subscriptions';
import {
	ByVariant,
	CartDelivery,
	CartProduct,
	CurrencyObject,
	DeliveryOption,
	DeliveryTimeSlot,
	Duration,
	ISOString,
	OrderDelivery,
	OrderInfoDelivery,
	OrderProduct,
	PricingTableObject,
	ProductApi,
	PurchaseType,
	PurchaseTypes,
	SegmentMap,
} from 'common/types';
import { Hash } from 'common/utils/arrays';
import { getNewEndTime } from 'common/utils/dateCalculations';
import { isLiftTicketProduct } from 'common/utils/liftTicketUtils';
import {
	PartialOrderProductFields,
	createOrderProductFromProductApi,
	newFirestoreId,
} from 'common/utils/newRentalUtils';
import { getEndDateWithInventoryBlockers } from 'common/utils/productUtils';
import { toRentleSegment } from 'common/utils/segments';
import {
	CartRow,
	ProductSelection,
	ProductSelectionWithProducts,
	ProductWithQuantity,
} from 'services/types';

import { PartialProductSelection } from './../services/types';
import {
	getEmptyTicketCartProduct,
	keycardProductToCartProduct,
	ticketProductToCartProduct,
} from './liftTicket';

export const getInitialProductSelection = (
	product: ProductApi,
	packageProducts?: ProductApi[],
): PartialProductSelection => {
	const hasBaseVariants = hasMultipleVariants(product);

	return {
		productId: product.id,
		variantId: hasBaseVariants ? null : getProductVariants(product)[0].id,
		children:
			packageProducts?.map((packageProduct) => {
				const productHasMultipleVariants = hasMultipleVariants(packageProduct);
				return {
					productId: packageProduct.id,
					variantId: productHasMultipleVariants ? null : getProductVariants(packageProduct)[0].id,
				};
			}) ?? undefined,
	};
};

export const getSelectionWithProducts = (
	selection: ProductSelection,
	productsById: { [productId: string]: ProductApi },
): ProductSelectionWithProducts => {
	const product = productsById[selection.productId];
	const children: ProductSelectionWithProducts[] = [];

	selection.children?.forEach((child) => {
		const product = productsById[child.productId];
		if (product) {
			children.push({
				variantId: child.variantId,
				product,
			});
		}
	});

	return {
		product,
		variantId: selection.variantId,
		children,
	};
};

export const getCartProductsFromProps = (args: {
	selection: ProductSelectionWithProducts;
	startDate: ISOString | null;
	startLocationId: string;
	endLocationId: string;
	duration: Duration;
	quantity: number;
	shopCurrency: CurrencyObject;
	shopPricingTables: PricingTableObject;
	selectedAsAdditional?: boolean;
	openingHours: OpeningHours;
	shopTimezone: string;
	segment: string | null;
	deliveryOption: DeliveryOption | null;
	cartDelivery?: CartDelivery;
	taxExcluded: boolean;
	purchaseType: PurchaseType;
	subscription?: ProductSubscription;
}): CartProduct[] => {
	const { product, variantId } = args.selection;
	const { durationInSeconds, durationName, durationType } = args.duration;
	const { deliveryOption, cartDelivery, shopTimezone } = args;
	const childProducts = args.selection.children?.map((child) => child.product);
	const childVariantIds = args.selection.children?.map((child) => child.variantId) ?? [];

	const variant = getProductVariants(product).find((v) => v.id === variantId)!;

	return range(args.quantity).map(() => {
		const endDate = !!args.startDate
			? getNewEndTime(args.startDate, args.duration, args.openingHours, args.shopTimezone)
			: null;
		const partialOrderProductFields: PartialOrderProductFields = {
			id: newFirestoreId(),
			startDate: args.startDate ?? null,
			endDate,
			durationType,
			rentalDurationInSeconds: durationInSeconds,
			durationName,
			shopperId: '',
			rentalId: '',
			selectedAsAdditional: args.selectedAsAdditional ?? false,
			startLocationId: args.startLocationId,
			endLocationId: args.endLocationId,
		};

		const orderProduct = createOrderProductFromProductApi({
			stockProduct: product,
			stockProductListForSetProducts: childProducts ?? null,
			variantId: variant.id,
			variantIdsForSetProducts: childVariantIds,
			partialOrderProductFields,
			savedPricingTables: args.shopPricingTables,
			currencyCode: args.shopCurrency.code,
			segment: toRentleSegment(args.segment) ?? null,
			deliveryOption,
			cartDelivery,
			channel: 'ONLINE',
			taxExcluded: args.taxExcluded,
			purchaseType: args.purchaseType,
			timezone: shopTimezone,
			openingHours: args.openingHours,
			subscription: args.subscription,
		});
		const cartProduct: CartProduct = orderProductToCartProduct(orderProduct);
		return cartProduct;
	});
};

export const getCartProductsForLiftTickets = (args: {
	cartProductFields?: CartProduct;
	startDate: ISOString;
	startLocationId: string;
	endLocationId: string;
	quantity: number;
	shopCurrency: CurrencyObject;
	shopId: string;
	selectedTicket: ProductApi;
	variant: ProductVariant;
	externalSegment: SegmentMap;
	withNewKeycard: boolean;
	keycardProduct: ProductApi;
	selectedAsAdditional?: boolean;
	taxExcluded: boolean;
	externalPackageId?: string;
	packageId?: string;
	packageCategoryIds?: string[];
	stockPackageId?: string;
	price?: number;
}): CartProduct[] => {
	return range(args.quantity).flatMap(() => {
		const cartProductFields = args.cartProductFields
			? args.cartProductFields
			: getEmptyTicketCartProduct({
					startDate: args.startDate,
					currencyCode: args.shopCurrency.code,
					startLocationId: args.startLocationId,
					endLocationId: args.endLocationId,
					shopId: args.shopId,
					stockProduct: args.selectedTicket,
					taxExcluded: args.taxExcluded,
					price: args.price,
			  });
		const ticket = ticketProductToCartProduct({
			cartProductFields,
			externalSegment: args.externalSegment,
			selectedTicket: args.selectedTicket,
			variant: args.variant,
			selectedAsAdditional: args.selectedAsAdditional,
			externalPackageId: args.externalPackageId,
			packageId: args.packageId,
			packageCategoryIds: args.packageCategoryIds,
			stockPackageId: args.stockPackageId,
			price: args.price,
		});
		const cartProducts: CartProduct[] = args.withNewKeycard
			? [ticket, keycardProductToCartProduct(ticket, args.keycardProduct)]
			: [ticket];
		return cartProducts;
	});
};

export const orderProductToCartProduct = (orderProduct: OrderProduct): CartProduct => {
	return omit(orderProduct, ['id', 'shopperId', 'rentalId']);
};

export const orderProductsToCartRows = (orderProducts: OrderProduct[]): CartRow[] => {
	const cartProducts = orderProducts.map((p) => orderProductToCartProduct(p));
	const byDate = groupBy(cartProducts, (p) =>
		isAutoRenewSubscriptionOrderProduct(p) && !!p.subscription
			? `${p.startDate}_${getSubscriptionCommittedDate(p.subscription)}`
			: `${p.startDate}_${p.endDate}`,
	);

	return Object.entries(byDate).map(([, products]) => {
		const uniqueProducts = groupBy(
			products,
			(p) =>
				`${p.productApiId}_${p.summary.variantIds.join(',')}${
					!!p.externalSegment?.externalSegmentId ? `_${p.externalSegment.externalSegmentId}` : ''
				}${!!p.packageId ? `_${p.packageId}` : ''}`,
		);

		return {
			startDate: products[0].startDate,
			endDate: products[0].endDate,
			products: Object.entries(uniqueProducts).map(([, products]) => ({
				product: products[0],
				quantity: products.length,
			})),
			purchaseType: products[0].purchaseType,
		};
	});
};

export const getProductIndexInCartItem = (
	products: ProductWithQuantity<CartProduct>[],
	product: CartProduct,
) =>
	products.findIndex(
		(p) =>
			p.product.productApiId === product.productApiId &&
			p.product.packageId === product.packageId &&
			isEqual(p.product.summary.variantIds, product.summary.variantIds) &&
			p.product.segment === product.segment &&
			p.product.externalSegment?.externalSegmentId === product.externalSegment?.externalSegmentId &&
			p.product?.packageId === product?.packageId,
	);

export const getRecommendedProductsForCart = (
	cartProductIds: string[],
	validProductsById: Hash<ProductApi>,
): ProductApi[] => {
	const uniqueCartProductIds = uniq(cartProductIds);
	const uniqueCartProducts = uniqueCartProductIds
		.map((id) => validProductsById[id])
		.filter(Boolean);

	const recommendedProductIds = uniqueCartProducts.flatMap((stockProduct) => {
		const additionalProductIds = stockProduct?.additionalProductIds ?? [];
		const childAdditionalProductIds = getProductsInPackage(stockProduct, validProductsById).flatMap(
			(p) => p.additionalProductIds ?? [],
		);

		return [...additionalProductIds, ...childAdditionalProductIds];
	});

	const recommendedProductIdsNotInCart = difference(recommendedProductIds, uniqueCartProductIds);
	const recommendedProductIdsWithCount = map(
		countBy(recommendedProductIdsNotInCart, (id) => id),
		(count, id) => ({ count, id }),
	);
	const recommendedProductIdsSortedByCount = sortBy(
		recommendedProductIdsWithCount,
		({ count }) => -count,
	);

	return recommendedProductIdsSortedByCount.map(({ id }) => validProductsById[id]).filter(Boolean);
};

export const getCartProductsWithAvailabilities = (
	cartRows: CartRow[],
	cartRowAvailabilities: number[][],
): CartRow[] =>
	cartRows.map((cartRow, rowIdx) => {
		const rowAvailabilities = cartRowAvailabilities[rowIdx];
		const rowProducts = cartRow.products.map((productRow, productIdx) => ({
			...productRow,
			maxAvailable: isFinite(rowAvailabilities[productIdx])
				? rowAvailabilities[productIdx]
				: undefined,
		}));
		return { ...cartRow, products: rowProducts };
	});

export const getCartProductsWithPickupUnavailability = (args: {
	cartRows: CartRow[];
	pickupTimeslot: DeliveryTimeSlot;
	selectedDeliveryOption: DeliveryOption;
	openingHours: OpeningHours;
	timezone: string;
}): CartRow[] => {
	const { cartRows, pickupTimeslot, selectedDeliveryOption, openingHours, timezone } = args;
	return cartRows.map((cartRow) => {
		const rowProducts = cartRow.products.map((productRow) => {
			if (isSalesPurchaseType(productRow.product.purchaseType) || !productRow.product.endDate) {
				return productRow;
			}
			const product = {
				...productRow.product,
				unavailable: getInventoryBlocker(productRow.product.unavailable, {
					pickupSlot: pickupTimeslot,
					deliveryHandlingAfter: getDeliveryInventoryBlockersAfter({
						deliveryOption: selectedDeliveryOption,
						pickupSlotOrEndDate: pickupTimeslot,
						openingHours,
						timezone,
					}).deliveryHandlingAfter,
				}),
			};
			return { ...productRow, product };
		});
		return { ...cartRow, products: rowProducts };
	});
};

export const getCartProductsWithHandlingTimeAfter = (
	cartRows: CartRow[],
	handlingTimeAfter: number,
): CartRow[] =>
	cartRows.map((cartRow) => {
		const rowProducts = cartRow.products.map((productRow) => {
			if (isSalesPurchaseType(productRow.product.purchaseType)) {
				return productRow;
			}
			const product = {
				...productRow.product,
				unavailable: getInventoryBlocker(productRow.product.unavailable, {
					deliveryHandlingAfter: handlingTimeAfter,
				}),
			};

			return {
				...productRow,
				product,
			};
		});

		return { ...cartRow, products: rowProducts };
	});

export const removeDeliveryUnavailabilityFromCartProducts = (cartRows: CartRow[]) =>
	cartRows.map((cartRow) => {
		const rowProducts = cartRow.products.map((productRow) => {
			const product = {
				...productRow.product,
				unavailable: getInventoryBlocker(productRow.product.unavailable, {
					deliveryHandlingBefore: null,
					deliverySlot: null,
					pickupSlot: null,
					deliveryHandlingAfter: null,
				}),
			};
			return { ...productRow, product };
		});
		return { ...cartRow, products: rowProducts };
	});

export const isStartDateExpired = (startDate: string | null) =>
	!!startDate && moment(startDate).isBefore(moment());

export const isLiftTicketStartDateExpired = (startDate: string | null) =>
	!!startDate && moment(startDate).isBefore(moment(), 'date');

export const getIsCartValid = (cartRows: CartRow[]) => {
	const isCartDatesValid = cartRows.every(({ startDate, products }) =>
		isLiftTicketProduct(products[0].product)
			? !isLiftTicketStartDateExpired(startDate)
			: !isStartDateExpired(startDate),
	);

	const cartProductsAvailable = cartRows
		.flatMap((p) => p.products)
		.every(
			({ quantity, maxAvailable }) =>
				maxAvailable === undefined || (isFinite(maxAvailable) && quantity <= maxAvailable),
		);

	return {
		isCartValid: cartProductsAvailable && isCartDatesValid,
		cartProductsAvailable,
		isCartDatesValid,
	};
};

export const getCartAvailabilityRangesByVariant = (
	cartItems: CartRow[],
	stockProducts: ProductApi[],
) => {
	return cartItems
		.flatMap((item) => item.products)
		.reduce((total, itemProduct) => {
			return {
				...total,
				...cartProductToAvailability(itemProduct, stockProducts),
			};
		}, {} as ByVariant<AvailabilityRange[]>);
};

export const getCartQuantitiesByVariant = (cartItems: CartRow[], stockProducts: ProductApi[]) => {
	return cartItems
		.flatMap((item) => item.products)
		.reduce(
			(result, itemProduct) =>
				sumQuantitiesByVariants([
					result,
					getQuantitiesByVariantFromCartProduct(itemProduct, stockProducts),
				]),
			{} as ByVariant<number>,
		);
};

export const sumQuantitiesByVariants = (
	quantitiesByVariants: ByVariant<number>[],
): ByVariant<number> =>
	quantitiesByVariants.reduce((acc, qbv) => {
		Object.entries(qbv).forEach(([variantId, quantity]) => {
			if (!!acc[variantId]) {
				acc[variantId] += quantity ?? 0;
			} else {
				acc[variantId] = quantity ?? 0;
			}
		});
		return acc;
	}, {} as ByVariant<number>);

const getQuantitiesByVariantFromCartProduct = (
	productWithQuantity: ProductWithQuantity<CartProduct>,
	stockProducts: ProductApi[],
): ByVariant<number> => {
	const allProductVariants = stockProducts.flatMap(getProductVariants);
	const skuIds = productWithQuantity.product.summary.skuIds;
	const affectedVariantIds = skuIds.flatMap((skuId) =>
		getVariantsBySkuId(allProductVariants, skuId).map((v) => v.id),
	);
	return affectedVariantIds.reduce((total, variantId) => {
		return {
			...total,
			[variantId]: (total[variantId] ?? 0) + productWithQuantity.quantity,
		};
	}, {} as ByVariant<number>);
};

const cartProductToAvailability = (
	productWithQuantity: ProductWithQuantity<CartProduct>,
	stockProducts: ProductApi[],
): ByVariant<AvailabilityRange[]> => {
	const allProductVariants = stockProducts.flatMap(getProductVariants);
	const { product, quantity } = productWithQuantity;
	const { startDate, endDate } = product;
	if (!startDate || !endDate) return {};
	const stockProduct = stockProducts.find((stockP) => stockP.id === product.productApiId);
	const skuIds = product.summary.skuIds;
	const end = !stockProduct
		? endDate
		: getEndDateWithInventoryBlockers(endDate, stockProduct, stockProducts);
	const affectedVariantIds = skuIds.flatMap((skuId) =>
		getVariantsBySkuId(allProductVariants, skuId).map((v) => v.id),
	);
	return affectedVariantIds.reduce((total, variantId) => {
		return {
			...total,
			[variantId]: [
				...(total[variantId] ?? []),
				{
					start: startDate,
					end,
					units: quantity,
				},
			],
		};
	}, {} as ByVariant<AvailabilityRange[]>);
};

export const getVariantAvailabilityWithCart = (
	availabilityRangesByVariant: ByVariant<AvailabilityRange[]>,
	cartItems: CartRow[],
	stockProducts: ProductApi[],
	purchaseType: PurchaseType,
): ByVariant<AvailabilityRange[]> => {
	switch (purchaseType) {
		case PurchaseTypes.subscription: //TODO: Verify this is correct behaviour
		case PurchaseTypes.rental: {
			const cartAvailabilityByVariant = getCartAvailabilityRangesByVariant(
				cartItems.filter((c) => isRentalPurchaseType(c.purchaseType)),
				stockProducts,
			);

			if (isEmpty(cartAvailabilityByVariant)) return availabilityRangesByVariant;

			return Object.entries(availabilityRangesByVariant).reduce((result, variantAvailability) => {
				const [variantId, availabilityRange] = variantAvailability;
				const cartVariantAvailability = cartAvailabilityByVariant[variantId];
				return {
					...result,
					[variantId]: !cartVariantAvailability
						? availabilityRange
						: mergeCartAvailability(availabilityRange, cartVariantAvailability),
				};
			}, {});
		}
		case PurchaseTypes.sales: {
			const salesQuantitiesByVariant = getCartQuantitiesByVariant(
				cartItems.filter((c) => isSalesPurchaseType(c.purchaseType)),
				stockProducts,
			);

			if (isEmpty(salesQuantitiesByVariant)) return availabilityRangesByVariant;

			return Object.entries(availabilityRangesByVariant).reduce((result, variantAvailability) => {
				const [variantId, availabilityRanges] = variantAvailability;
				const quantityInCart = salesQuantitiesByVariant[variantId];
				return {
					...result,
					[variantId]: !quantityInCart
						? availabilityRanges
						: subtractFromAvailabilityRanges(availabilityRanges, quantityInCart),
				};
			}, {});
		}
		default:
			return {};
	}
};

const subtractFromAvailabilityRanges = (
	ranges: AvailabilityRange[],
	units: number,
): AvailabilityRange[] => {
	return ranges.map((range) => ({
		...range,
		units: range.units !== null ? range.units - units : range.units,
	}));
};

const mergeCartAvailability = (
	total: AvailabilityRange[],
	subtracts: AvailabilityRange[],
): AvailabilityRange[] => {
	const mergedUnitsCalculationFunc = (a: number, b: number) => a - b;
	return subtracts.reduce(
		(totalRange, subtract) =>
			mergeAvailabilityRangeToRanges(totalRange, subtract, mergedUnitsCalculationFunc),
		total,
	);
};

export const cartDeliveryToOrderDelivery = (args: {
	cartDelivery: CartDelivery;
	shopId: string;
	orderId: string;
	availablePickupSlots: DeliveryTimeSlot[] | null;
}): OrderDelivery => {
	const { cartDelivery, shopId, orderId, availablePickupSlots } = args;
	const createdAt = moment().toISOString();
	return {
		...cartDelivery,
		...(!!availablePickupSlots && { availablePickupSlots }),
		shopId,
		orderId,
		createdAt,
		charge: {
			paid: 0,
		},
	};
};

export const generateOrderInfoDelivery = (cartDelivery: CartDelivery): OrderInfoDelivery => {
	const deliveryTimeslotStartDate = cartDelivery.to?.timeslot?.startDate;
	const pickupTimeslotEndDate = cartDelivery.from?.timeslot?.endDate;

	const deliveryStartTime =
		!!deliveryTimeslotStartDate && !!cartDelivery.to
			? moment(deliveryTimeslotStartDate)
					.subtract(cartDelivery.to.handlingTimeMinutes, 'minutes')
					.toISOString()
			: undefined;

	const pickupEndTime =
		!!pickupTimeslotEndDate && cartDelivery.from
			? moment(pickupTimeslotEndDate)
					.add(cartDelivery.from.handlingTimeMinutes, 'minutes')
					.toISOString()
			: undefined;

	const noPickup = !!cartDelivery.from?.disabled;

	return {
		orderDeliveryId: cartDelivery.id,
		...(!!deliveryStartTime && {
			to: {
				startDate: deliveryStartTime,
			},
		}),
		...(pickupEndTime && {
			from: {
				endDate: pickupEndTime,
				...(noPickup && { carrierId: SELF_RETURN_CARRIER.id }),
			},
		}),
	};
};

export const cartRowsToCartProducts = (cartRows: CartRow[]): CartProduct[] =>
	cartRows.flatMap((c) => c.products.map((p) => p.product));
