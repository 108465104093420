import React from 'react';

import { StyledEngineProvider, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import * as CartSelectors from 'redux/selectors/cart';
import * as CheckoutSelectors from 'redux/selectors/checkout';
import * as NavSelectors from 'redux/selectors/nav';
import * as ShopSelectors from 'redux/selectors/shop';

import PaymentMethodSelector, {
	AdditionalDetailsUpdate,
	ConfirmAction,
} from 'common/components/payment/PaymentMethodSelector';
import { pricingToAmountObject } from 'common/modules/atoms/pricing';
import {
	getAdyenMethodTypes,
	getPaymentProviderPaymentMethodsWithoutWallets,
	getSortedPaymentMethods,
	getValidPaymentMethods,
} from 'common/modules/payments/paymentMethods';
import { ShopOnlinePaymentMethod } from 'common/modules/payments/types';
import * as Themes from 'common/styles/themes';
import { isRunInIframe } from 'common/utils/browserUtils';
import useCheckoutForm from 'hooks/useCheckoutForm';
import { useTranslation } from 'services/localization';

import { NoPaymentMethods } from './NoPaymentMethods';

interface Props {
	handleConfirmAction: (action: ConfirmAction) => void;
	handleAdditionalDetailsUpdate: (data: AdditionalDetailsUpdate) => void;
	additionalSelectorElement?: JSX.Element;
}

const PaymentSelectorWrapper = (props: Props) => {
	const { t, language } = useTranslation();
	const theme = useTheme();
	const adyenPaymentMethods = useSelector(CheckoutSelectors.adyenPaymentMethods);
	const paymentInProgress = useSelector(CheckoutSelectors.paymentInProgress);
	const orderPurchaseTypes = useSelector(CheckoutSelectors.orderPurchaseTypes);
	const paymentLoading = useSelector(CheckoutSelectors.paymentLoading);
	const paymentError = useSelector(CheckoutSelectors.paymentError);
	const additionalPaymentAction = useSelector(CheckoutSelectors.additionalPaymentAction);
	const shopPaymentMethods = useSelector(ShopSelectors.shopPaymentMethods);
	const shopCountry = useSelector(ShopSelectors.shopCountry);
	const shopName = useSelector(ShopSelectors.shopName);
	const currencyObject = useSelector(CheckoutSelectors.currency);
	const shopFeatures = useSelector(ShopSelectors.shopFeaturesData);
	const merchantId = useSelector(ShopSelectors.shopId);
	const isCustomStoreDomain = useSelector(NavSelectors.isCustomStoreDomain);

	const hasProductsWithOnlinePaymentOnly = useSelector(CartSelectors.hasLiftTickets);
	const checkoutPricing = useSelector(CheckoutSelectors.checkoutPricing);

	const orderStartDate = useSelector(CartSelectors.startDate);
	const orderHasDelivery = useSelector(CartSelectors.hasCartDelivery);
	const daysUntilOrderStart = moment(orderStartDate).diff(moment(), 'days', true);
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const selectorNegativeSpacing = isSmallScreen ? 3 : 0;

	const hasSubscription = orderPurchaseTypes.includes('subscription');

	const { values, actions, errors } = useCheckoutForm();

	const getInvalidPaymentMethods = () => {
		const disableOnlinePaymentMethods = !!shopFeatures.includes('BLOCK_ONLINE_PAYMENTS');
		let invalidPaymentMethods: ShopOnlinePaymentMethod[] =
			hasProductsWithOnlinePaymentOnly || orderHasDelivery ? ['PAY_STORE'] : [];
		if (disableOnlinePaymentMethods) {
			const onlineProviderPaymentMethods = getPaymentProviderPaymentMethodsWithoutWallets();
			invalidPaymentMethods = [...invalidPaymentMethods, ...onlineProviderPaymentMethods];
		}
		return invalidPaymentMethods;
	};

	const activePaymentMethods = getSortedPaymentMethods(
		getValidPaymentMethods({
			shopMethods: shopPaymentMethods,
			adyenMethodTypes: adyenPaymentMethods?.loading
				? { loading: true }
				: { loading: false, data: getAdyenMethodTypes(adyenPaymentMethods?.data ?? null) },
			invalidMethodNames: getInvalidPaymentMethods(),
			hasDeposit: !!checkoutPricing.deposit,
			country: shopCountry,
			currency: checkoutPricing.currency,
			paymentIsInIframe: isRunInIframe(),
			isCustomDomain: isCustomStoreDomain,
			hasSubscription: hasSubscription,
		}),
	);

	const paymentMethodsLoading = !!adyenPaymentMethods?.loading;

	const _handleConfirmAction = (action: ConfirmAction) => {
		props.handleConfirmAction(action);
	};

	const _handleAdditionalDetailsUpdate = (data: AdditionalDetailsUpdate) => {
		props.handleAdditionalDetailsUpdate(data);
	};

	if (!activePaymentMethods.length && !paymentMethodsLoading) {
		return <NoPaymentMethods />;
	}

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={Themes.WhiteLabelLight}>
				<PaymentMethodSelector
					activePaymentMethods={activePaymentMethods}
					paymentMethodsLoading={!!adyenPaymentMethods?.loading}
					amount={pricingToAmountObject(checkoutPricing).charge}
					currencyObject={currencyObject}
					deposit={{
						amount: pricingToAmountObject(checkoutPricing).deposit,
						reservationType: daysUntilOrderStart > 1 ? 'LATER' : 'NOW',
					}}
					handleSubmit={_handleConfirmAction}
					handleAdditionalDetailsUpdate={_handleAdditionalDetailsUpdate}
					confirmLoading={paymentLoading}
					confirmError={paymentError}
					paymentInProgress={paymentInProgress}
					additionalPaymentAction={additionalPaymentAction}
					lang={language}
					t={t}
					country={shopCountry}
					merchantName={shopName}
					selectorNegativeSpacing={selectorNegativeSpacing}
					merchantId={merchantId}
					recurringPaymentsAcceptance={{
						hasSubscription,
						isAccepted: values.recurringPaymentsAcceptance,
						setAcceptance: (value) => actions.setRecurringPaymentsAcceptance(value),
						error: errors.recurringPayments,
					}}
				/>
			</ThemeProvider>
		</StyledEngineProvider>
	);
};

export default PaymentSelectorWrapper;
