import { useEffect, useRef, useState } from 'react';

type Direction = 'UP' | 'DOWN';

const useScrollDirection = (initial?: Direction) => {
	const lastScrollPos = useRef<number>(document.body.getBoundingClientRect().top);
	const [direction, setDirection] = useState<Direction | null>(initial ?? null);

	useEffect(() => {
		const onScroll = () => {
			const currentPos = -1 * document.body.getBoundingClientRect().top;
			const lastPos = lastScrollPos.current;
			const changedToUp = currentPos === 0 || currentPos < lastPos - 20;
			if (changedToUp) {
				setDirection('UP');
				lastScrollPos.current = currentPos;
			}
			const changedToDown = currentPos > lastPos + 20;
			if (changedToDown) {
				setDirection('DOWN');
				lastScrollPos.current = currentPos;
			}
		};
		window.addEventListener('scroll', onScroll);
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	return direction;
};

export default useScrollDirection;
