import React from 'react';

import { FormControlLabel, Radio, Stack, Typography } from '@mui/material';

import { PurchaseType } from 'common/types';

export interface Props {
	value: PurchaseType;
	label: string;
	priceString: string;
	disabled: boolean;
}

const PurchaseTypeLabel = (props: Props) => {
	const { value, label, priceString, disabled } = props;
	return (
		<FormControlLabel
			sx={{
				backgroundColor: (theme) => theme.palette.background.default,
				pl: 2,
				'&:first-child': {
					mx: 0,
					borderRadius: '6px 6px 0 0',
				},
				'&:last-child': {
					borderRadius: '0 0 6px 6px',
				},
			}}
			value={value}
			control={<Radio />}
			label={
				<Stack sx={{ py: 1.5, pl: 0, pr: 4 }}>
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						<Typography variant="body1">{label}</Typography>
						<Typography variant="body1" color="textSecondary">
							{priceString}
						</Typography>
					</Stack>
				</Stack>
			}
			disabled={disabled}
			componentsProps={{
				typography: {
					width: '100%',
				},
			}}
		/>
	);
};

export default PurchaseTypeLabel;
