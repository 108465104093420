import React from 'react';

import { IconButton, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiCloseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { removeReservation } from 'redux/actions/cart';
import * as CartSelectors from 'redux/selectors/cart';
import * as ViewSelectors from 'redux/selectors/view';

import { getReservationEndTime, isReservationExpired } from 'common/modules/reservations';
import CenteredContent from 'components/CenteredContent';
import { useTranslation } from 'services/localization/useTranslation';

const ReservationExpireBanner = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const classes = useStyles();
	const showReservationExpiredBanner = useSelector(ViewSelectors.showReservationExpiredBanner);
	const reservationCreated = useSelector(CartSelectors.reservationCreated);
	const reservationEndTime = reservationCreated
		? getReservationEndTime(reservationCreated)
		: undefined;
	const reservationExpired = reservationEndTime ? isReservationExpired(reservationEndTime) : false;

	if (!reservationCreated || !reservationExpired || !showReservationExpiredBanner) return null;

	const clearReservation = () => {
		dispatch(removeReservation());
	};

	return (
		<CenteredContent className={classes.root} innerClassName={classes.content}>
			<Typography className={classes.expired} variant="body2" color="textPrimary" align="center">
				{t('hasExpired', 'Booking has expired')}
			</Typography>
			<IconButton className={classes.closeBtn} onClick={clearReservation} size="large">
				<RiCloseLine />
			</IconButton>
		</CenteredContent>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: '#f2f2f2',
			height: 35,
		},
		content: {
			display: 'flex',
			position: 'relative',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100%',
		},
		closeBtn: {
			position: 'absolute',
			right: 0,
			padding: 4,
			marginRight: -4,
		},
		expired: {
			color: theme.palette.error.main,
		},
	}),
);

export default ReservationExpireBanner;
