import React from 'react';

import { Badge, IconButton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RiShoppingBagLine } from 'react-icons/ri';
import * as ViewActions from 'redux/actions/view';
import { useDispatch, useSelector } from 'redux/hooks';
import * as CartSelectors from 'redux/selectors/cart';

interface Props {
	edge?: 'start' | 'end';
	size?: number;
}

const CartButton = ({ edge, size }: Props) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const count = useSelector(CartSelectors.cartProductsCount);

	return (
		<IconButton
			sx={{
				marginRight: '-12px',
				marginLeft: '-12px',
			}}
			onClick={() => dispatch(ViewActions.toggleCartOpen())}
			edge={edge}
			size="large"
		>
			<Badge
				badgeContent={count}
				showZero={false}
				color="error"
				overlap="circular"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				classes={{
					badge: classes.badge,
				}}
			>
				<RiShoppingBagLine size={size ?? 32} color="black" />
			</Badge>
		</IconButton>
	);
};

const useStyles = makeStyles((theme) =>
	createStyles({
		badge: {
			backgroundColor: '#ce0000',
		},
	}),
);

export default CartButton;
