import { TFunction } from 'i18next';

import { ONLINE_DEFAULT_FEATURED_IMAGE_URL } from 'common/constants/defaultImages';
import { OnlineLayoutSettings } from 'common/modules/customization/types';
import { Languages, createLocaleField } from 'common/modules/translations';

import { isValidUrl } from '../atoms/validation';
import { PLAIN_THEME_ID, RENTLE_STANDARD_THEME_ID, RENTLE_STANDARD_THEME_NAME } from './constants';
import { CustomizerTheme, FeaturedImageButton, HeaderLogoPlacement, SliderValue } from './types';

export const getDefaultOnlineLayout = (
	t: TFunction,
	defaultLanguage: Languages,
	onlineStoreUrl?: string,
): OnlineLayoutSettings => ({
	defaultLayout: 'categories',
	header: {
		logo: {
			placement: 'left' as HeaderLogoPlacement,
			width: 120 as SliderValue,
		},
	},
	productGridColumns: {
		desktop: 3 as SliderValue,
	},
	featuredImage: {
		src: ONLINE_DEFAULT_FEATURED_IMAGE_URL,
		heading: createLocaleField(
			t('common:defaultOnlineLayout.featuredImage.header', 'Image banner'),
			defaultLanguage,
		),
		description: createLocaleField(
			t(
				'common:defaultOnlineLayout.featuredImage.description',
				'Add catchy title and details to customers about your offering or products.',
			),
			defaultLanguage,
		),
		alignment: 'center',
		button: {
			label: createLocaleField(
				t('common:defaultOnlineLayout.featuredImage.buttonLabel', 'Shop now'),
				defaultLanguage,
			),
			src: onlineStoreUrl ?? 'https://rentle.io',
		},
	},
	footer: {
		about: {
			hidden: false,
			heading: createLocaleField(
				t('common:defaultOnlineLayout.footer.heading', 'Company'),
				defaultLanguage,
			),
			description: createLocaleField(
				t(
					'common:defaultOnlineLayout.footer.description',
					'Share business details, and brand content with your customers.',
				),
				defaultLanguage,
			),
		},
		menu: {
			hidden: false,
			heading: createLocaleField(
				t('common:defaultOnlineLayout.menu.heading', 'Menu'),
				defaultLanguage,
			),
			toggleMenu: true,
		},
		links: {
			hidden: false,
			heading: createLocaleField(
				t('common:defaultOnlineLayout.links.heading', 'Quick links'),
				defaultLanguage,
			),
			linkObjects: [
				{
					label: createLocaleField(
						t('common:defaultOnlineLayout.links.label1', 'Terms & Conditions'),
						defaultLanguage,
					),
					url: onlineStoreUrl ?? 'https://rentle.io',
				},
				{
					label: createLocaleField(
						t('common:defaultOnlineLayout.links.label2', 'Privacy Policy'),
						defaultLanguage,
					),
					url: onlineStoreUrl ?? 'https://rentle.io',
				},
				{
					label: createLocaleField(
						t('common:defaultOnlineLayout.links.label3', 'Instagram'),
						defaultLanguage,
					),
					url: onlineStoreUrl ?? 'https://rentle.io',
				},
			],
		},
	},
	about: {
		hidden: false,
	},
});

export const isValidButton = (
	button: FeaturedImageButton | null,
	t: TFunction,
): { valid: boolean; message?: string } => {
	if (!button?.label?.def && !button?.src) return { valid: true };
	const hasBothFields = !!button?.label?.def && !!button.src;
	const isValidLink = isValidUrl(button?.src ?? '');

	if (hasBothFields && isValidLink) {
		return { valid: true };
	} else if (!hasBothFields) {
		return {
			valid: false,
			message: t('common:validation.mustContainBothFields', 'Must contain both fields'),
		};
	} else if (!isValidLink) {
		return {
			valid: false,
			message: t('common:validation.mustContainURLProtocol', 'The url must begin with "https://"'),
		};
	}
	return { valid: true };
};

export const getRentleDefaultTheme = (
	t: TFunction,
	defaultLanguage: Languages,
	onlineStoreUrl?: string,
): CustomizerTheme => {
	return {
		id: RENTLE_STANDARD_THEME_ID,
		name: RENTLE_STANDARD_THEME_NAME,
		merchantId: '',
		createdAt: '',
		updatedAt: '',
		live: getDefaultOnlineLayout(t, defaultLanguage, onlineStoreUrl),
		type: 'online',
	};
};

export const isRentleDefaultThemeId = (themeId: string) => themeId === RENTLE_STANDARD_THEME_ID;

export const isPlainThemeId = (themeId: string) => themeId === PLAIN_THEME_ID;

export const getCustomCheckoutContentValue = (
	value: string | boolean,
	t: TFunction,
): string | null => {
	if (typeof value === 'boolean' && !!value) {
		return t('common:actions.confirmed', 'Confirmed');
	}
	if (typeof value === 'string') {
		return value;
	}
	return null;
};
