import React, { useMemo } from 'react';

import './calendar.css';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import moment from 'moment-timezone';
import Calendar, { CalendarProps, CalendarTileProperties } from 'react-calendar';

import { WhiteLabelLight as theme } from 'common/styles/themes';

// react-calendar always uses local timezone
const localMoment = (inp?: moment.MomentInput, strict?: boolean | undefined): moment.Moment => {
	return moment(inp, strict).local();
};

const localDateToDate = (date: Date): Date => {
	return moment(localMoment(date).format('YYYY-MM-DD')).toDate();
};

interface TileStyles {
	color?: string;
	backgroundColor?: string;
}

interface Styles {
	tileBase?: TileStyles;
	tileToday?: TileStyles;
	tileActive?: TileStyles;
	tileRangeStart?: TileStyles;
	tileRangeEnd?: TileStyles;
	tileRange?: TileStyles;
	tileWeekend?: TileStyles;
	tileUnavailable?: TileStyles;
	tileLow?: TileStyles;
	tileOutOfRange?: TileStyles;
	tileHighlighted?: TileStyles;
	tileDisabled?: TileStyles;
	hideDuplicateVerticalWeekdays?: boolean;
}

interface Props extends CalendarProps {
	onChange: (value: Date | Date[]) => void;
	styles?: Styles;
	unavailableDates?: string[];
	highlightedDates?: string[];
	lowDates?: string[];
	disabledDates?: string[];
	disableUnavailableDates?: boolean;
	className?: string;
}

const CalendarWithStyles: React.FC<Props> = (props) => {
	const {
		styles = {},
		unavailableDates,
		highlightedDates,
		lowDates,
		disabledDates,
		disableUnavailableDates,
		onChange: _onChange,
		...calendarProps
	} = props;
	const classes = useStyles(styles);

	const lowMap = useMemo(() => {
		const map = new Map<string, null>();
		lowDates?.forEach((date) => {
			map.set(date, null);
		});
		return map;
	}, [lowDates]);

	const highlightedMap = useMemo(() => {
		const map = new Map<string, null>();
		highlightedDates?.forEach((date) => {
			map.set(date, null);
		});
		return map;
	}, [highlightedDates]);

	const unavailableMap = useMemo(() => {
		const map = new Map<string, null>();
		unavailableDates?.forEach((date) => {
			map.set(date, null);
		});
		return map;
	}, [unavailableDates]);

	const disabledMap = useMemo(() => {
		const map = new Map<string, null>();
		disabledDates?.forEach((date) => {
			map.set(date, null);
		});
		return map;
	}, [disabledDates]);

	const tileClassName = (args: CalendarTileProperties) => {
		const baseClasses =
			typeof props.tileClassName === 'function' ? props.tileClassName(args) : props.tileClassName;
		const extraClasses = [];

		const dateString = localMoment(args.date).format('YYYY-MM-DD');

		if (args.date.getDay() === 0) {
			extraClasses.push('last-day-of-week');
		}

		const lastDateOfMonth = new Date(
			args.date.getFullYear(),
			args.date.getMonth() + 1,
			0,
		).getDate();
		if (args.date.getDate() + 7 > lastDateOfMonth) {
			extraClasses.push('last-7-days-of-month');
		}
		if (args.date.getDate() === lastDateOfMonth) {
			extraClasses.push('last-day-of-month');
		}

		if (highlightedMap.has(dateString)) {
			extraClasses.push('custom__tile--highlighted');
		}

		if (lowMap.has(dateString)) {
			extraClasses.push('custom__tile--low');
		}

		if (unavailableMap.has(dateString)) {
			extraClasses.push('custom__tile--unavailable');
		}

		if (
			(calendarProps.minDate &&
				localMoment(calendarProps.minDate).startOf('day').isAfter(args.date)) ||
			(calendarProps.maxDate &&
				localMoment(calendarProps.maxDate).endOf('day').isBefore(args.date)) ||
			disabledMap.has(dateString)
		) {
			extraClasses.push('custom__tile--out-of-range');
		}

		return classNames(baseClasses, extraClasses);
	};

	const tileDisabled = (args: CalendarTileProperties) => {
		const dateString = localMoment(args.date).format('YYYY-MM-DD');

		if (disabledMap.has(dateString)) {
			return true;
		}

		if (disableUnavailableDates && unavailableMap.has(dateString)) {
			return true;
		}

		return calendarProps.tileDisabled?.(args) ?? false;
	};

	const tileContent = (args: CalendarTileProperties) => {
		return <span className="react-calendar__tile-day">{args.date.getDate()}</span>;
	};

	const onChange = (value: Date | Date[]) => {
		const correctValue = Array.isArray(value) ? value.map(localDateToDate) : localDateToDate(value);
		_onChange?.(correctValue);
	};

	return (
		<ScopedCssBaseline className={props.className} classes={{ root: classes.root }}>
			<Calendar
				{...calendarProps}
				tileClassName={tileClassName}
				tileDisabled={tileDisabled}
				tileContent={tileContent}
				onChange={onChange}
			/>
		</ScopedCssBaseline>
	);
};

export const calendarDefaultStyles: Styles = {
	tileBase: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.text.primary,
	},
	tileWeekend: {},
	tileActive: {},
	tileToday: {},
	tileRange: {},
	tileRangeStart: {},
	tileRangeEnd: {},
	tileDisabled: {
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		color: theme.palette.text.disabled,
	},
	tileOutOfRange: {
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		color: theme.palette.text.disabled,
	},
	tileUnavailable: {
		backgroundColor: theme.palette.colors.availabilityNone.light,
		color: theme.palette.colors.availabilityNone.contrastText,
	},
	tileLow: {
		backgroundColor: theme.palette.colors.availabilityLow.light,
		color: theme.palette.colors.availabilityLow.contrastText,
	},
	tileHighlighted: {
		backgroundColor: theme.palette.colors.availabilityGood.light,
		color: theme.palette.colors.availabilityGood.contrastText,
	},
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: 'transparent',
			'@global': {
				'.react-calendar': {
					border: 'none',
					width: '100%',
				},
				'.react-calendar__viewContainer': {
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'stretch',
					[theme.breakpoints.up('sm')]: {
						flexDirection: 'row',
						alignItems: 'flex-start',
					},
				},
				'.react-calendar__month-view': {
					flex: 1,
					width: 'auto',

					'& .react-calendar__month-view__days__day--neighboringMonth': {
						visibility: 'hidden',
						pointerEvents: 'none',
						userSelect: 'none',
						padding: 0,
					},
				},
				'.react-calendar__month-view__days__day': {
					color: theme.palette.text.primary,
				},
				'.react-calendar__month-view__days__day--weekend': {
					color: theme.palette.text.primary,
					'& > abbr': (styles: Styles) => styles.tileWeekend,
				},
				'.react-calendar__month-view__weekdays__weekday': {
					'& > abbr': {
						textDecoration: 'none',
						textTransform: 'capitalize',
						fontSize: '11px',
						fontWeight: 'normal',
					},
				},
				'.react-calendar__tile': (styles: Styles) => ({
					background: theme.palette.common.white,
					color: theme.palette.text.primary,
					...styles.tileBase,
					position: 'relative',
					width: '14.2857%',
					padding: '14.2857% 0 0 0',
					overflow: 'visible !important',
					fontSize: '12px',

					'& > .react-calendar__tile-day': {
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: 3,
						width: '100%',
						height: '100%',
						color: 'inherit',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: 'inherit',
					},

					'& abbr': {
						position: 'absolute',
						zIndex: 1,
						top: 0,
						left: 0,
						bottom: -1,
						right: 0,
						borderTop: '1px solid #e5e7e7',
						borderLeft: '1px solid #e5e7e7',
						borderBottom: '1px solid transparent',
						borderRight: '1px solid transparent',
						transition: 'box-shadow 0.2s',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						fontSize: '12px',
						color: 'transparent !important',
					},

					'&.last-day-of-month abbr': {
						borderRight: '1px solid #e5e7e7',
						right: -1,
					},

					'&.last-day-of-week abbr': {
						borderRight: '1px solid #e5e7e7',
						right: -1,
					},

					'&.last-7-days-of-month': {},
					'&.last-7-days-of-month abbr': {
						borderBottom: '1px solid #e5e7e7',
					},

					'&.react-calendar__tile--range::after': {
						background: 'rgba(0,0,0,0.2)',
					},
					'&.react-calendar__tile--rangeStart::after': {
						background: 'rgba(0,0,0,0.2)',
					},
					'&.react-calendar__tile--rangeEnd::after': {
						background: 'rgba(0,0,0,0.2)',
					},
					'& abbr::after': {
						content: '""',
						position: 'absolute',
						borderRadius: '50%',
						top: 'calc((100% + 1px) * 0.15 - 3px)',
						bottom: 'calc((100% + 1px) * 0.15 - 2px)',
						left: 'calc((100% + 2px) * 0.15 - 3px)',
						right: 'calc((100% + 2px) * 0.15 - 3px)',
						transition: 'all 0.2s linear',
					},
					'&:hover > abbr::after': {
						background: 'rgba(0,0,0,0.2)',
					},
					'&.react-calendar__tile--range:hover > abbr::after': {
						background: 'transparent',
						border: '2px solid black',
					},
					'&:disabled': {
						pointerEvents: 'none',
					},
				}),

				'.custom__tile--highlighted': (styles: Styles) => ({
					...styles.tileHighlighted,
				}),
				'.custom__tile--low': (styles: Styles) => ({
					...styles.tileLow,
				}),
				'.custom__tile--unavailable ': (styles: Styles) => ({
					...styles.tileUnavailable,
				}),
				'.custom__tile--out-of-range': (styles: Styles) => ({
					...styles.tileOutOfRange,
				}),
				'.react-calendar__tile--now': (styles: Styles) => ({
					...styles.tileToday,
				}),
				'.react-calendar__tile--active': (styles: Styles) => ({
					...styles.tileActive,
				}),
				'.react-calendar__tile--range': (styles: Styles) => ({
					...styles.tileRange,
				}),
				'.react-calendar__tile--rangeStart': (styles: Styles) => ({
					...styles.tileRangeStart,
				}),
				'.react-calendar__tile--rangeEnd': (styles: Styles) => ({
					...styles.tileRangeEnd,
				}),
			},
		},
	}),
);

export default CalendarWithStyles;
