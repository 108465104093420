import { TFunction } from 'i18next';
import { has, keyBy } from 'lodash';
import moment from 'moment-timezone';

import { getOnlineStoreOrderConfirmLink } from 'common/modules/urls';
import {
	CancellationPolicyEmailData,
	Category,
	Environment,
	Languages,
	LocalFormatFunction,
	OnlineOrderRequest,
	OrderObject,
	OrderProduct,
	SetProduct,
	ShopPublicInfo,
	ShopUrlApi,
	Shopper,
	UserDetail,
} from 'common/types';
import { isCancellationPolicyDefined } from 'common/utils/cancellations';
import { cancellationHoursAsString } from 'common/utils/dateUtils';

import { getCancellationPolicyToRender } from './cancellations';
import { getLatestProductEndTime } from './dateCalculations';
import {
	filterExistingSkipassProducts,
	isAcceptableKeycardNumber,
	isLiftTicketProduct,
	productsHasPhotoRequired,
	productsIncludeExistingSkipass,
	shoppersPhotoUploadIncomplete,
} from './liftTicketUtils';
import { getCategoriesWithTermsFromShoppers } from './rentals';

const getFormattedTimeForEmail = (date: string, timeZone: string) =>
	moment.tz(date, timeZone).format('YYYY-MM-DD HH:mm');

const assignShopperIdToSetProduct = (
	setProducts: SetProduct[],
	shopperId: string,
): Array<SetProduct & { shopperId: string }> =>
	setProducts.map((setProduct) => ({ ...setProduct, shopperId }));

const allProductsHaveNeededUserDetails = (products: OrderProduct[], shoppers: Shopper[]) => {
	const shoppersById = keyBy(shoppers, 'id');
	const productsThatNeedDetails = products
		.map((p) => [p, ...(p.set ? assignShopperIdToSetProduct(p.setProducts, p.shopperId) : [])])
		.flat()
		.filter((p) => !!p.userDetails);

	return productsThatNeedDetails.every((p) =>
		(p.userDetails ?? [])
			.filter((detail: UserDetail) => detail.required)
			.every((detail: UserDetail) => has(shoppersById[p.shopperId].userProperties, detail.name)),
	);
};

const segmentedProductsHaveAssignedShoppers = (products: OrderProduct[], shoppers: Shopper[]) => {
	const segmentedShoppers = Object.assign(
		{},
		...shoppers.filter((s: any) => !!s.segment).map((s: any) => ({ [s.id]: s.segment })),
	);
	const doesProductsHaveSegmentedShoppers = products.every((p: any) => {
		const shopperSegment = segmentedShoppers[p.shopperId];
		const { segment } = p;
		return !segment || shopperSegment === segment || isLiftTicketProduct(p);
	});

	return doesProductsHaveSegmentedShoppers;
};

const shoppersWithExistingKeycardHaveAssignedCardNumber = (products: OrderProduct[]) =>
	filterExistingSkipassProducts(products).every(isAcceptableKeycardNumber);

export const isBookedOrderMissingDetails = (products: OrderProduct[], shoppers: Shopper[]) => {
	const isMissingUserDetails = !allProductsHaveNeededUserDetails(products, shoppers);
	const isMissingAssignedSegments = !segmentedProductsHaveAssignedShoppers(products, shoppers);
	const isMissingKeycardNumbers =
		productsIncludeExistingSkipass(products) &&
		!shoppersWithExistingKeycardHaveAssignedCardNumber(products);
	const isMissingPhotos =
		productsHasPhotoRequired(products) && shoppersPhotoUploadIncomplete(shoppers, products);

	return (
		isMissingUserDetails || isMissingAssignedSegments || isMissingKeycardNumbers || isMissingPhotos
	);
};

export interface OnlineOrderRequestParams {
	order: OrderObject;
	shopInfo: ShopPublicInfo;
	categories: Category[];
	lang: Languages;
	shopUrlDoc: ShopUrlApi;
	locationId: string | null;
	isMissingDetails: boolean;
	localFormat: LocalFormatFunction;
	t: TFunction;
	env: Environment;
}

export const getOnlineOrderRequest = ({
	order,
	shopInfo,
	categories,
	lang,
	shopUrlDoc,
	locationId,
	isMissingDetails,
	localFormat,
	t,
	env,
}: OnlineOrderRequestParams): OnlineOrderRequest => {
	const { rentalInfo, products, shoppers } = order;
	const categoriesWithTerms = getCategoriesWithTermsFromShoppers(
		shoppers,
		categories,
		shopInfo?.termsOfService ?? null,
		lang,
		t,
		!!shopInfo.legacySettings?.useCategoryTermsOverwrite,
	);
	const localStartDate = getFormattedTimeForEmail(rentalInfo.startDate, shopInfo.timeZone);
	const localEndDate = getFormattedTimeForEmail(
		getLatestProductEndTime(products) ?? '',
		shopInfo.timeZone,
	);
	const multiLocation = Object.keys(shopUrlDoc.locationUrls ?? {}).length > 1;
	const orderConfirmLink = getOnlineStoreOrderConfirmLink({
		env,
		shopUrlDoc,
		locationId: multiLocation ? locationId : null,
		orderId: rentalInfo.id,
	});
	let cancellationPolicy: CancellationPolicyEmailData | undefined;
	const isCancellable = !products.every(isLiftTicketProduct);
	if (
		!!shopInfo.cancellationPolicy?.length &&
		isCancellable &&
		isCancellationPolicyDefined(shopInfo.cancellationPolicy)
	) {
		const { cancellationPolicyToRender, noCancellation } = getCancellationPolicyToRender(
			rentalInfo.startDate,
			localFormat,
			shopInfo.cancellationPolicy,
		);
		cancellationPolicy = {
			cancellations: cancellationPolicyToRender.map((cancellation) => ({
				...cancellation,
				beforeHours: t('common:times.prior', {
					beforeHours: `${cancellationHoursAsString(cancellation, t)}`,
					defaultValue: '{{beforeHours}} prior',
				}),
				refundPercentage: Math.round(cancellation.refundPercentage * 100),
			})),
			noCancellationHours: !!noCancellation?.beforeHours
				? t('common:times.lessThan', {
						noCancellationHours: `${cancellationHoursAsString(noCancellation, t)}`,
						defaultValue: 'Less than {{noCancellationHours}}',
				  })
				: null,
		};
	}
	return {
		shopId: shopInfo.shopId,
		lang: lang ?? 'en',
		shopPublicInfo: shopInfo,
		order,
		localStartDate,
		localEndDate,
		categoriesWithTerms,
		isMissingDetails,
		orderLink: orderConfirmLink,
		...(cancellationPolicy && { cancellationPolicy }),
	};
};
