import React from 'react';

import { ButtonBase, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

interface Props {
	text: string;
	selected?: boolean;
	onSelect?: () => void;
	onDeselect?: () => void;
	disabled?: boolean;
}

const BoxRadioButton = (props: Props) => {
	const classes = useStyles();

	const { text, onSelect, onDeselect, selected = false, disabled } = props;

	return (
		<ButtonBase
			focusRipple
			onClick={selected ? onDeselect : onSelect}
			disabled={disabled}
			className={classNames(classes.button, {
				[classes.buttonSelected]: selected,
			})}
		>
			<Typography variant="body1">{text}</Typography>
		</ButtonBase>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			padding: theme.spacing(2, 3),
			overflow: 'hidden',
			position: 'relative',
			'&:disabled': {
				opacity: 0.4,
				background: '#ececec',
			},
			'&:before': {
				content: '""',
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				border: '1px solid #cbcbcb',
			},
			'&:hover:before': {
				borderColor: '#000000',
			},
		},
		buttonSelected: {
			'&:before': {
				content: '""',
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				border: '2px solid black',
			},
		},
	}),
);

export default BoxRadioButton;
