import React from 'react';

import { Box } from '@mui/material';
import { isUndefined, sumBy } from 'lodash';
import { useSelector } from 'react-redux';
import * as ShopSelectors from 'redux/selectors/shop';
import * as StockSelectors from 'redux/selectors/stock';

import Divider from 'common/components/Divider';
import Banner from 'common/components/states/Banner';
import { LoadingData, SegmentMap } from 'common/types';
import { notUndefined } from 'common/utils/common';
import { getSkidataSegmentTranslationById } from 'common/utils/segments';
import PriceSummary from 'components/PriceSummary';
import {
	PackageLiftTicketItem,
	PackageLiftTicketPricingInfo,
} from 'hooks/usePackageLiftTicketForm';
import { t, useTranslation } from 'services/localization';

interface Props {
	items: PackageLiftTicketItem[];
	pricing: LoadingData<PackageLiftTicketPricingInfo | null>;
	externalId: string;
	segments: SegmentMap[];
	withNewKeycards?: boolean;
}
const PackageTicketsPricingSummary = (props: Props) => {
	const { getTranslation, language } = useTranslation();

	const shopCurrency = useSelector(ShopSelectors.shopCurrency);
	const liftTicketProducts = useSelector(StockSelectors.liftTicketProductsForPackages);
	const taxExcluded = useSelector(ShopSelectors.taxExcluded);

	const keycardProduct = useSelector(StockSelectors.keycardProduct);
	const packageProductName = liftTicketProducts.find((p) => p.externalId === props.externalId)
		?.name;

	const getRows = () => {
		const rows = props.items.map((item) => {
			const quantity = Number(item.quantity.selected);
			const ticketName = packageProductName ? getTranslation(packageProductName) : '';
			const segmentName = getSkidataSegmentTranslationById(
				item.externalSegmentId,
				props.segments,
				language,
			);

			const prices = props.pricing.data?.priceBySegment[item.externalSegmentId];

			return {
				key: item.externalSegmentId,
				quantity,
				label: `${ticketName}, ${segmentName}`,
				unitPrice: prices?.bestPrice ?? 0,
				unitPriceBase: prices?.basePrice ?? 0,
				loading: isUndefined(prices),
			};
		});

		if (props.withNewKeycards) {
			const keycardQuantity = sumBy(props.items, (item) => Number(item.quantity.selected));
			const keycardName = keycardProduct ? getTranslation(keycardProduct.name) : '';
			const keycardPrice = props.pricing.data?.pricePerKeycard;

			rows.push({
				key: 'keycards',
				quantity: keycardQuantity,
				label: keycardName,
				unitPrice: notUndefined(keycardPrice) ? keycardPrice : 0,
				unitPriceBase: 0,
				loading: isUndefined(keycardPrice),
			});
		}

		return rows;
	};

	if (!props.pricing.data && props.pricing.loading === false && !props.pricing.error) return null;

	if (!!props.pricing.error) {
		return (
			<Box mb={2}>
				<Banner
					variant="danger"
					title={t(
						'tickets.errorLoadingPrices',
						'Error loading ticket prices. Please reload the page to try again.',
					)}
				/>
			</Box>
		);
	}

	return (
		<Box>
			<Divider />
			<Box mt={2} />
			<PriceSummary
				heading={t('common:booking.yourBooking', 'Your booking')}
				currency={shopCurrency}
				taxExcluded={taxExcluded}
				rows={getRows()}
			/>
		</Box>
	);
};

export default PackageTicketsPricingSummary;
